export function Dots({ number }: { number: number }) {
  let dots = "";

  for (let i = 0; i < number; i++) {
    dots += "•";
  }
  if (number > 5) {
    dots = String(number);
  }
  return (
    <span style={{ fontSize: "11px", letterSpacing: "1px" }}>{dots}</span>
  );
}
