import prettyDate from "../../../functions/prettyDate";

export function row(row?: any): any[] {
  return [
    {
      name: "ID",
      value: row?.id,
      className: "id",
    },
    {
      name: "Token",
      value: <div style={{ lineBreak: "anywhere" }}>{row?.token}</div>,
      style: { fontSize: "9px", width: "140px" },
    },
    {
      name: "Nazwa",
      value: row?.in_trash ? <>(kosz){row?.name}</> : (
        <a target="_blank" href={"https://happ.agit.com.pl/uploads/files/"+row?.path} style={{ lineBreak: "anywhere" }}>
          {row?.name}
        </a>
      ),
      style: { width: "310px" },
    },
    {
      name: "Opis",
      value: row?.description,
    },
    {
      name: "Klasyfikacja",
      value: (
        <>
          <span title="module">{row?.module}</span>/
          <span title="sub_module">{row?.sub_module}</span>/
          <span title="category">{row?.category}</span>/
          <span title="sub_category">{row?.sub_category}</span>
        </>
      ),
    },
    {
      name: "Rozmiar",
      value: Math.round((Number(row?.size) / 1024 / 1024) * 100) / 100 + " MB",
    },
    {
      name: "Format",
      value: row?.format?.split("/")?.[1]?.toUpperCase(),
    },
    {
      name: "Dodany",
      value: row?.time_created,
    },
  ];
}
