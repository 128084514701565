import useGeneric from "../dataFetch/useGeneric";
import dateIcon from "../../images/icons/date.png";
import Loading from "../common/Loading";
import { colorShade } from "./definitions/functions";

export default function AgendaMultiView({ show }: { show: Function }) {
  const date = new Date().toISOString().slice(0, 10);
  const dateRangeEnd = new Date(date);
  dateRangeEnd.setDate(dateRangeEnd.getDate() + 7);

  // console.log(selectedDate);
  const fetchEventList = useGeneric({
    name: "event-calendar-simple",
    limit: 150,

    reload: true,
    secondsToReload: 120,
  });

  const eventList = !fetchEventList?.loading && fetchEventList?.rows;

  return (
    <div className="standardWrap">
      <div className="back-to-agenda" onClick={() => show(false)}>
        <img
          style={{
            width: "3vw",
            padding: "4px 12px",
            marginRight: "1vw",
            background: "rgb(230,230,230)",
            cursor: "pointer",
          }}
          src={dateIcon}
        />
      </div>
      <div>
        {fetchEventList?.loading ? (
          <Loading />
        ) : (
          <div className="calendar-simple-multi-view">
            {eventList?.map((event: any, index: any, arr: any) => {
              const nextDate = event.date === arr?.[index - 1]?.date;
              return (
                <div
                  style={{
                    marginTop: index === 0 || nextDate ? 0 : "3vw",
                  }}
                  className="item"
                >
                  <div className="date">
                    {!nextDate ? (
                      new Date(event.date)?.toLocaleDateString("pl-PL", {
                        day: "numeric",
                        month: "numeric",
                        weekday: "long",
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="more">
                    <div className="item-main">
                      <div className="name">
                        {event.is_company
                          ? event.company_name
                          : event.first_name + " " + event.last_name}
                      </div>
                      <div>
                        <div style={{ fontSize: "70%", color: "grey" }}>
                          DOROŚLI
                        </div>

                        {event.guest_number_adult}
                      </div>
                      
                      <div>
                        <div style={{ fontSize: "70%", color: "grey" }}>
                          DZIECI 0-3
                        </div>
                        {event.guest_number_child_0_3}
                      </div>
                      <div>
                        <div style={{ fontSize: "70%", color: "grey" }}>
                          DZIECI 4-12
                        </div>

                        {event.guest_number_child_4_12}
                      </div>
                      <div>
                        <div style={{ fontSize: "70%", color: "grey" }}>
                          w tym diety
                        </div>
                        {event.guest_number_diets}
                      </div>
                    </div>
                    <div className="more-details" style={{
                            background: colorShade(event?.type_color, 140),
                          }}>
                      <div className="type-and-venues">
                        <div
                          className="event-type"
                          style={{
                            background: colorShade(event?.type_color, 80),
                          }}
                        >
                          {event.type_string}
                        </div>
                        <div className="venues">{event.venues}</div>
                      </div>
                      <div className="stand-text">{event.stand_text}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
