import { useContext } from "react";
import icon from "../../images/icons/location.svg";
import { ViewContext } from "../contexts/ViewContext";
import prettyDate from "../functions/prettyDate";
import { voucherStatus } from "./voucherStatus";
import insertGeneric from "../dataFetch/insertGeneric";
import { sqlCurrentDate } from "../functions/tools";
import { voucherRegister } from "./VoucherList";

export default function VoucherGroupOptions({
  items,
  setUpdate,
}: {
  items: any;
  setUpdate: Function;
}) {
  const { setModal } = useContext(ViewContext);

  const allItemsPrinted = items
    ?.map((i: voucherRegister) => i?.status === 1)
    ?.reduce((p1: boolean, p2: boolean) => p1 && p2, true);
  const allItemsAvailable = items
    ?.map((i: voucherRegister) => i?.status === 2)
    ?.reduce((p1: boolean, p2: boolean) => p1 && p2, true);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() =>
        setModal({
          content: (
            <div style={{ padding: "10px" }}>
              {items && items?.length ? (
                <>
                  <div className="grid-2">
                    <div>Vouchery</div>{" "}
                    <div>
                      od <strong>{items.at(-1).number_text}</strong> do{" "}
                      <strong>{items.at(0).number_text}</strong>
                    </div>
                  </div>
                  <div className="grid-2">
                    <div>Zamówił(a):</div>
                    <strong>{items.at(0)?.request_user}</strong>
                  </div>
                  <div className="grid-2">
                    <div>Ważne do:</div>

                    <strong>
                      {prettyDate(items.at(0)?.valid_until, "Dziś,", true)}
                    </strong>
                  </div>
                  <div className="grid-2">
                    <div>Status:</div>{" "}
                    <strong style={{ color: "#5c500f" }}>
                      {allItemsPrinted || allItemsAvailable ? (
                        voucherStatus?.[items.at(0)?.status]
                      ) : (
                        <>różne</>
                      )}
                    </strong>
                  </div>
                  {allItemsPrinted ? (
                    <div>
                      <button
                        className="normal-button"
                        style={{
                          width: "100%",
                          padding: "12px 12px",
                          marginBlock: "15px",
                        }}
                        onClick={async () => {
                          insertGeneric("set-voucher-status", {
                            action_date: sqlCurrentDate(),
                            status: 2,
                            group_id: items?.at(0)?.group_id,
                          });
                          setUpdate((prev: number) => prev + 1);
                          setModal((prev: any) => ({ ...prev, show: false }));
                          //ZMIEŃ W LSI!
                          await insertGeneric("update-lsi-voucher-number", {
                            vouchers: [
                              { ...items?.at(0), copies: items?.length },
                            ],
                          });
                        }}
                      >
                        Zmień status na <strong>PRZYJĘTE W HOTELU</strong>
                      </button>
                    </div>
                  ) : allItemsAvailable ? (
                    <button
                      className="normal-button"
                      style={{
                        width: "100%",
                        padding: "12px 12px",
                        marginBlock: "15px",
                      }}
                      onClick={async () => {
                        insertGeneric("set-voucher-status", {
                          action_date: sqlCurrentDate(),
                          status: 1,
                          group_id: items?.at(0)?.group_id,
                        });
                        setUpdate((prev: number) => prev + 1);
                        setModal((prev: any) => ({ ...prev, show: false }));
                        //ZMIEŃ W LSI!
                        await insertGeneric("update-lsi-voucher-number", {
                          vouchers: [
                            { ...items?.at(0), copies: items?.length },
                          ],
                          remove: true
                        });
                      }}
                    >
                      Przywróć status <strong>WYDRUKOWANE (nieprzyjęte)</strong>
                    </button>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          ),
          heading: <>Pakiet voucherów</>,
          show: true,
        })
      }
    >
      <img src={icon} className="clickable-icon" />
      {items?.at(0)?.status === 2 ? (
        <>OPCJE</>
      ) : items?.at(0)?.status === 1 ? (
        <>OZNACZ JAKO PRZYJĘTE</>
      ) : (
        <></>
      )}
    </div>
  );
}
