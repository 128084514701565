import { createContext, useEffect, useRef, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";

export const NameContext = createContext({ names: [], loading: true });

export default function FormatPeople({
  children,
  loaded,
}: {
  children: any;
  loaded: Boolean;
}) {
  const childRef = useRef<any>();
  const [names, setNames] = useState<any>();
  const [results, setResults] = useState<any>();

  const { loading, rows } = useGeneric({
    name: names?.length && "check-names",
    query: {
      names: names?.map((n: any) => n?.input?.toUpperCase()),
    },
    update: names?.length,
  });

  useEffect(() => {
    if (!loading) {
      setResults(rows);
    }
  }, [loading]);

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        const nameNodes =
          childRef?.current?.querySelectorAll(".person-converted");
        if (nameNodes) {
          const nameStrings = Array.from(nameNodes).map(
            (h: any) => h.dataset.name
          );
          setNames(
            nameStrings?.map((s: any) => ({
              input: s, //s?.split(" ")?.join(",")?.replace(" ", ""),
              output: s,
            }))
          );
        }
      }, 5);
    }
  }, [loaded]);
  return (
    <div ref={childRef}>
      <NameContext.Provider value={{ names: results, loading: loading }}>
        {children}
      </NameContext.Provider>
    </div>
  );
}
