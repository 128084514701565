import coffeeIcon from "./../../images/icons/coffee.svg";
import snacksIcon from "./../../images/icons/sandwich.svg";
import {
  compareTimeStrings,
  objectOrJSONparse,
  timeStringToNumber,
} from "../functions/tools";
import idToName, {
  dish_type,
  getPropByDate,
  idToJSX,
  meal_type,
  music_type,
  schedule_item_type,
} from "./definitions/common";

export default function useEventViewItems(
  e: any,
  date: Date,
  potential?: Boolean
) {
  if (potential) {
    return;
  }
  const comments = JSON.parse(e.comments);
  date.setHours(12);

  const dates = e.dates && JSON.parse(e.dates);
  const schedules = e.schedules && JSON.parse(e.schedules);
  const meals = e.meals && JSON.parse(e.meals);

  const whichDay =
    dates && dates.length > 1
      ? dates.map((d: any) => d.date).indexOf(date.toISOString().slice(0, 10)) +
        1
      : undefined;

  const dateData =
    date &&
    dates &&
    dates.length &&
    dates.find((item: any) => item.date === date.toISOString().slice(0, 10));

  const scheduleData =
    schedules &&
    schedules.length &&
    schedules
      .filter((item: any) => item.date === date.toISOString().slice(0, 10))
      // .sort(
      //   (a: any, b: any) =>
      //     a.next_day || compareTimeStrings(a.time_from, b.time_from)
      // )
      .map((schedule: any) => {
        const currentMeals = meals.filter(
          (meal: any) => meal.schedule_id === schedule.id
        );
        return { ...schedule, meals: currentMeals };
      });
  const mealsData = meals
    .filter((meal: any) => {
      const id = scheduleData?.[0]?.id;
      return meal.schedule_id === id;
    })
    .map((meal: any) => ({
      ...meal,
      category_parent: scheduleData.find((i: any) => meal.schedule_id)
        ?.category,
      category_parent_string: idToName(
        schedule_item_type,
        scheduleData.find((i: any) => meal.schedule_id)?.category
      ),
      category_string: idToName(meal_type, meal.category),
    }));
  const stretchesToNextDay = scheduleData
    ? scheduleData?.at(-1)?.next_day
    : false;

  const timeStart = scheduleData ? scheduleData[0]?.time_from.slice(0, 5) : "";
  const timeEnd = dateData ? scheduleData?.at(-1)?.time_to.slice(0, 5) : "";

  const depositPaid =
    e?.payments &&
    objectOrJSONparse(e.payments)?.filter((p: any) => Number(p.gross) > 490)
      ?.length;

  // console.log(depositPaid)

  const venues = scheduleData.map((s: any) => s.venue_string);

  const music = scheduleData.map((s: any) => s.music);

  const children_0_3 = getPropByDate(
    e,
    "_guest_number_child_0_3",
    dateData?.date
  );
  const children_4_12 = getPropByDate(
    e,
    "_guest_number_child_4_12",
    dateData?.date
  );

  const values = {
    client:
      e.is_company === 2
        ? "Hotel Agit Congress & SPA"
        : e.is_company === 1
        ? e.company_name
        : e.first_name + " " + e.last_name,
    dates: dates,
    personInvolved:
      e?.edited_string == e?.created_string
        ? e?.created_string
            ?.split(" ")
            ?.map((a: string) => a?.[0])
            ?.join("")
        : e?.created_string
            ?.split(" ")
            ?.map((a: string) => a?.[0])
            ?.join("") +
          (e?.edited_string
            ? "," +
              e?.edited_string
                ?.split(" ")
                ?.map((a: string) => a?.[0])
                ?.join("")
            : ""),
    payments: objectOrJSONparse(e.payments),
    depositPaid: depositPaid || undefined,
    dayNumeral: whichDay,
    daysTotal: dates.length,
    company: e.is_company === 1 ? e.company_name : false,
    personOrContact: `${e.first_name ? e.first_name?.replace(/ $/, "") : ""} ${
      e.last_name ? e.last_name?.replace(/ $/, "") : ""
    }`,
    waiters: getPropByDate(e, "_waiters", dateData?.date),
    chefs: getPropByDate(e, "_chefs", dateData?.date),
    dishwashers: getPropByDate(e, "_dishwashers", dateData?.date),
    timeStart: timeStart,
    timeEnd: timeEnd,
    standText: getPropByDate(e, "_stand_text", dateData?.date),
    venues: venues,
    venuesShort: venues?.map((v: any) => v?.replace(/sala /, "")),
    music: music,
    musicMax: Math.max(...music),
    meals: mealsData,
    hasBreakfast: mealsData.find((i: any) => i.category_parent == 2)
      ? true
      : false,
    sumMeals: () => {
      const hasCoffeeBreak = mealsData.find(
        (i: any) =>
          i.category_parent == 7 ||
          i?.parent_category == 7 ||
          i?.category === 101
      );
      const hasSoup = mealsData.find((i: any) => i.category == 2);
      const hasStarter = mealsData.find((i: any) => i.category == 1);
      const hasMainCourse = mealsData.find((i: any) => i.category == 3);
      const hasSnacks = mealsData.find(
        (i: any) => i.parent_field_name === "snacks"
      );

      const dishType =
        hasStarter && hasMainCourse
          ? 3
          : hasMainCourse
          ? 2
          : hasSoup
          ? 11
          : hasSnacks
          ? 12
          : 0;
      // const coffee = hasCoffeeBreak;
      return {
        dishNumber: dishType,
        addedString: hasCoffeeBreak ? "przerwa kawowa" : false,
        coffee: hasCoffeeBreak ? (
          <img src={coffeeIcon} width={18} />
        ) : (
          false
        ),
        snacks: hasSnacks ? (
          <img src={snacksIcon} width={15} />
        ) : (
          false
        ),
      };
    },
    // sumMeals: () => {
    // const category_parent = mealsData.map((m: any) => m.category_parent);
    //const parent = mealsData.map((m: any) => m.category);
    //   if (
    //     mealsData.find(
    //       (i: any) => i.category_parent == 3 || i.category_parent == 4
    //     )
    //   ) {
    //     const hasSoup = mealsData.find((i:any) => i.category == 2);
    //     const hasStarter = mealsData.find((i: any) => i.category == 1);
    //     const hasMainCourse = mealsData.find((i: any) => i.category == 3);

    //     return hasStarter && hasMainCourse ? 3 : 2;
    //     // 3 z przystawkami
    //   } else if (mealsData.find((i: any) => i.category_parent == 7)) {
    //     return 1;
    //   } else {
    //     return 0;
    //   }
    // },
    comments: comments,
    guest_number_adult: getPropByDate(e, "_guest_number_adult", dateData?.date),
    guest_number_child_0_3: children_0_3,
    guest_number_child_4_12: children_4_12,
    guest_number_child: Number(children_0_3) + Number(children_4_12),
    guest_number_diets: getPropByDate(e, "_guest_number_diets", dateData?.date),
  };

  return {
    values: values,
    jsx: {
      client:
        e.is_company === 2 ? (
          "Hotel Agit Congress & SPA"
        ) : e.is_company === 2 ? (
          <div>Hotel AGIT Congress & SPA</div>
        ) : (
          <div className={e.is_company === 1 ? "company-client" : ""}>
            <div
              className="nowrap"
              style={{
                maxWidth: "290px",
              }}
            >
              {e.is_company === 1 ? e.company_name : <></>}
            </div>
            <div className={e.is_company === 1 ? "contact-person" : ""}>
              {e.first_name} {e.last_name}
            </div>
          </div>
        ),
      music: idToJSX(music_type, values?.musicMax),
      get_dish_type: () => {
        const main = idToName(dish_type, values.sumMeals()?.dishNumber);
        const coffee = values.sumMeals()?.coffee; //addedString;
        const snacks = values.sumMeals()?.snacks; //addedString;
        return values.sumMeals()?.dishNumber || coffee || snacks ? (
          <div className="meals-short">
            {values.sumMeals()?.dishNumber ? <>{main}</> : <></>}
            {snacks ? <>{snacks}</> : <></>}
            {coffee ? <>{coffee}</> : <></>}
          </div>
        ) : (
          false
        );
      },
      times: (
        <>
          {values.timeStart}-{timeEnd}
          {stretchesToNextDay ? (
            <span
              style={{
                display: "inline-block",
                marginLeft: "3px",
                fontSize: "55%",
                translate: "0 -3px",
                filter: "grayscale(.8) contrast(1.4)",
              }}
            >
              🌙
            </span>
          ) : (
            <></>
          )}
        </>
      ),
      guest_number: values.guest_number_adult + values.guest_number_child,
      schedule: scheduleData.map((item: any) => (
        <div className="event-view-schedule">
          <div>
            {item?.time_from?.slice(0, 5)} - {item?.time_to?.slice(0, 5)}
          </div>
          <div>{item?.venue_string?.toUpperCase()}</div>
        </div>
      )),
      mealsList: mealsData
        .sort((a: any, b: any) => {
          const fieldIndex: any = {
            meals: 0,
            drinks: 1,
            snacks: 2,
          };

          return (
            fieldIndex?.[a?.parent_field_name] -
            fieldIndex?.[b?.parent_field_name]
          );
        })
        ?.sort((a: any, b: any) => a?.parents_order - b?.parents_order)
        ?.map((item: any) => (
          <div className="listed-meal">
            <div>{item?.category_string}</div>
            <div>
              <span className="listed-meal-time">
                {item?.time?.slice(0, 5)}
              </span>
              {item?.name}
            </div>
          </div>
        )),
    },
  };
}
