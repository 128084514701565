import {
  Link,
  NavLink,
  unstable_useViewTransitionState,
} from "react-router-dom";

export default function EmployeeCards({
  employees,
  selectedEmployee,
  setSelectedEmployee,
  setMobileMenuOpen,
  location,
}: {
  employees: any;
  selectedEmployee: any;
  setSelectedEmployee: Function;
  setMobileMenuOpen: Function;
  location: string;
}) {
  //   console.log(employees);

  const employeesUse =
    employees &&
    employees.map((e: any) => ({
      ...e,
      contract:
        e.contract_type === 4
          ? "pre_contract"
          : e.contract_type === 1
          ? "employment_contract"
          : e.contract_type === 2
          ? "contract_of_mandate_student"
          : e.contract_type === 3
          ? "contract_of_mandate_zus"
          : e.contract_type === 5
          ? "contract_for_specific_work"
          : e.contract_type === 10
          ? "internship"
          : e.contract_type === 11
          ? "practice"
          : e.holiday_relevant ||
            e.location === "biuro" ||
            e.contract_type === 1
          ? "employment_contract"
          : e.contract_type === 9
          ? "cooperation"
          : "",
    }));

  const contractTypes: any = {
    employment_contract: {
      name: "umowa o pracę",
      employees:
        employeesUse &&
        employeesUse.filter((e: any) => e.contract === "employment_contract"),
    },
    pre_contract: {
      name: "umowa na okres próbny",
      employees:
        employeesUse &&
        employeesUse.filter((e: any) => e.contract === "pre_contract"),
    },
    cooperation: {
      name: "umowa współpracy (fakt./rach.)",
      employees:
        employeesUse &&
        employeesUse.filter((e: any) => e.contract === "cooperation"),
    },
    contract_of_mandate_student: {
      name: "umowa zlecenie (student)",
      color: "#f9ccfa",
      employees:
        employeesUse &&
        employeesUse.filter(
          (e: any) => e.contract === "contract_of_mandate_student"
        ),
    },
    contract_of_mandate_zus: {
      name: "umowa zlecenie (zus)",
      color: "#a5e492",
      employees:
        employeesUse &&
        employeesUse.filter(
          (e: any) => e.contract === "contract_of_mandate_zus"
        ),
    },
    contract_for_specific_work: {
      name: "umowa o dzieło",
      employees:
        employeesUse &&
        employeesUse.filter(
          (e: any) => e.contract === "contract_for_specific_work"
        ),
    },
    internship: {
      name: "staż zawodowy",
      employees:
        employeesUse &&
        employeesUse.filter(
          (e: any) => e.contract === "internship"
        ),
    },
    practice: {
      name: "praktyki zawodowe",
      employees:
        employeesUse &&
        employeesUse.filter(
          (e: any) => e.contract === "practice"
        ),
    },
    other: {
      name: "brak danych o umowie",
      employees:
        employeesUse && employeesUse.filter((e: any) => e.contract === ""),
    },
  };

  function Employee({ employee }: { employee: any }) {
    return (
      <NavLink to={location + "/" + employee?.id} unstable_viewTransition>
        <div
          onClick={() => {
            setSelectedEmployee(employee.id);
            setTimeout(() => setMobileMenuOpen(false), 200);
          }}
          key={employee.id}
          className={
            "employee-card" +
            (employee.id === selectedEmployee ? " active" : "")
          }
        >
          <div
            className={
              "employee-card-inner" + (!selectedEmployee ? " full" : "")
            }
          >
            <div>
              {" "}
              {employee.last_name} {employee.first_name}
            </div>
            <div className="employee-group-name">
              {!selectedEmployee ? employee.group_name : ""}
            </div>
          </div>
        </div>
      </NavLink>
    );
  }
  console.log(employeesUse);

  return (
    <div className={"employee-cards"}>
      {Object.keys(contractTypes)
        .map((key: string) => contractTypes[key])
        .map((group: any) =>
          group.employees && group.employees.length ? (
            <div className="employee-group">
              <div
                className="employee-group-name"
                style={{ background: group?.color }}
              >
                {group.name}
              </div>
              <div className="employee-group-list">
                {group?.employees?.map((employee: any) => (
                  <Employee employee={employee} />
                ))}
              </div>
            </div>
          ) : (
            <></>
          )
        )}

      {/* {employees &&
        employees?.map((employee: any) => (
          <EmployeeGroup employee={employee} />
        ))} */}
    </div>
  );
}
