import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import { ViewContext } from "../../contexts/ViewContext";
import insertGeneric from "../../dataFetch/insertGeneric";
import GenericForm from "../../forms/GenericForm";
import AddMissing from "../AddMissing";

export default function AttendanceEvents({
  which,
  pair,
  records,
  datum,
  setUpdate,
  setActive,
  isExitFromLast,
  isEnterToNext,
  location,
}: {
  which: string;
  pair: any;
  records: any;
  datum: any;
  setUpdate: any;
  setActive: any;
  isExitFromLast: boolean;
  isEnterToNext: boolean;
  location: string;
}) {
  const viewContext = useContext(ViewContext);
  const { permissions } = useContext(UserContext);
  const p = parseInt(permissions["Attendance"]);

  const datumCor = new Date(datum.obj);
  datumCor.setHours(2, 0, 0);

  function changeEntryActiveStatus(item: any) {
    const idFromField =
      item.parentNode.parentNode.querySelector("#id").textContent;
    const activeFromField =
      item.parentNode.parentNode.querySelector("#active").textContent;
    setActive(
      idFromField,
      parseInt(activeFromField),
      location === "hotel"
        ? "attendance"
        : location === "biuro"
        ? "attendance_3maja"
        : null
    );
    setUpdate((prev: number) => prev + 1);
  }

  return (
    <td style={isEnterToNext ? { borderBottom: "0.5px solid #fdfdfd" } : {}}>
      <div className={"attendanceEvents " + which}>
        {records?.map((e: any) => {
          const ip = e.mod_data ? e.mod_data.split("/")[0] : null;
          const dateModified = e.mod_data
            ? new Date(e.mod_data.split("/")[1] * 1000).toLocaleString("pl-PL")
            : null;

          return (
            <div
              style={{
                color:
                  e.type === 2
                    ? "#77b7af"
                    : e.type === 3
                    ? "rgb(89, 127, 122)"
                    : e.type === 4
                    ? "rgb(226, 81, 162)"
                    : e.type === 5
                    ? "rgb(246, 65, 166)"
                    : "inherit",
              }}
            >
              {which === "in" ? (
                <span
                  style={{
                    marginRight: "3px",
                    fontSize: "9px",
                  }}
                >
                  ▲
                </span>
              ) : (
                <></>
              )}
              {which === "out" && isExitFromLast && (
                <div
                  style={{
                    /*fontWeight: "500",
                  fontSize: "9px",
                  marginRight: "5px",
                  marginBottom: "5px",
                  position: "absolute",
                  right: "91%",
                  rotate: "180deg",*/
                    position: "absolute",
                    right: "calc(100% + 14px)",
                    width: "1.5px",
                    height: "28px",
                    rotate: "-52deg",
                    opacity: ".54",
                    background: "#3fb71570",
                    //borderTop:"3px solid green",
                    borderBottom: "3px solid red",
                    top: "-16px",
                  }}
                >
                  {
                    //"⤵"
                  }
                </div>
              )}
              <div
                className={
                  "attendanceTime" +
                  (e?.mod_data?.match(/\/typ$/)
                    ? " amended-silent"
                    : e.custom || e.mod_data || e.modified_by
                    ? " amended"
                    : "") +
                  (e.source === "attendance" && location === "biuro"
                    ? " hotel"
                    : "")
                }
                title={
                  e.custom
                    ? "Zmienione przez użytkownika nr " +
                      e.modified_by +
                      " / IP: " +
                      ip +
                      " / " +
                      dateModified
                    : "Zapis z urządzenia"
                }
                onClick={() =>
                  !(p > 3)
                    ? undefined
                    : viewContext.setModal({
                        show: true,
                        heading: "Edytuj wpis",
                        content: (
                          <GenericForm
                            formContent={[
                              {
                                name: "",
                                label: "Osoba",
                                initialValue: "",
                                jsx: (
                                  <div>
                                    <div
                                      style={{
                                        fontWeight: "500",
                                        fontSize: "18px",
                                      }}
                                    >
                                      {pair.person}
                                    </div>
                                  </div>
                                ),
                              },
                              {
                                name: "",
                                label: "Czas",
                                initialValue: "",
                                jsx: (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <div id="id" style={{ display: "none" }}>
                                      {e.id}
                                    </div>
                                    <div
                                      id="active"
                                      style={{ display: "none" }}
                                    >
                                      {e.active}
                                    </div>
                                    <div style={{ fontSize: "16px" }}>
                                      {datumCor.toLocaleDateString("pl-PL")}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        marginLeft: "9px",
                                        fontFamily: "monospace",
                                      }}
                                    >
                                      {e.timestring.split(" ")[1].slice(0, 5)}
                                    </div>
                                    <div style={{ display: "none" }}>
                                      {pair.detailed.cardname}
                                    </div>
                                    <div style={{ display: "none" }}>
                                      {pair.detailed.carduserid}
                                    </div>
                                  </div>
                                ),
                              },
                              {
                                name: "delete",
                                initialValue: "",
                                label: "Usuwanie wpisu",
                                jsx: (
                                  <button
                                    onClick={(e) => {
                                      viewContext.setModal((prev: any) => ({
                                        ...prev,
                                        show: false,
                                      }));
                                      changeEntryActiveStatus(e.target);
                                    }}
                                    type="button"
                                    className="button"
                                    style={{
                                      padding: "5px 12px",
                                      width: "auto",
                                      fontSize: "14px",
                                    }}
                                  >
                                    USTAW JAKO NIEAKTYWNY
                                  </button>
                                ),
                              },
                              {
                                name: "typeshort",
                                initialValue: e.type,
                                label: "Rodzaj wpisu",
                                element: "select",
                                autoFocus: true,
                                optionsList: [
                                  {
                                    id: 0,
                                    name: "przyjście do pracy",
                                    active: true,
                                  },
                                  {
                                    id: 1,
                                    name: "wyjście z pracy",
                                    active: true,
                                  },
                                  {
                                    id: 2,
                                    name: "ZDALNIE - początek pracy",
                                    active: true,
                                  },
                                  {
                                    id: 3,
                                    name: "ZDALNIE - koniec pracy",
                                    active: true,
                                  },
                                  {
                                    id: 4,
                                    name: "INNE - początek pracy",
                                    active: true,
                                  },
                                  {
                                    id: 5,
                                    name: "INNE - koniec pracy",
                                    active: true,
                                  },
                                ],
                              },
                              /*{
                          name: "time",
                          initialValue: e.timestring.split(" ")[1].slice(0, 5),
                          label: "Godzina",
                          inputType:"time"
                        },*/
                            ]}
                            onSubmit={async (values: any) => {
                              // console.log(values, pair.person);
                              //datumCor.toISOString().slice(0, 10)
                              await insertGeneric("update-attendance-type", {
                                timestamp: e.timestamp,
                                typeshort: values.typeshort,
                                custom: e.custom,
                                location: location,
                              });
                              setUpdate((prev: number) => prev + 1);
                              viewContext.setModal((prev: any) => ({
                                ...prev,
                                show: false,
                              }));
                            }}
                          />
                        ),
                      })
                }
              >
                {new Date(e.timestring).toLocaleTimeString("pl-PL", {
                  hour: "numeric",
                  minute: "numeric",
                })}
              </div>
              {/*which === "in" && isEnterToNext && (
              <div
                style={{
                  rgb(63 183 21 / 44%)
                  position: "absolute",
                  right: "-4px",
                  width: "1.5px",
                  height: "20px",
                  rotate: "-45deg",
                  background: "#3fb71570",
                  top: "8px",
                }}
              >
                {
                  //"⤵"
                }
              </div>
            )*/}
              {which === "out" ? (
                <span
                  style={{
                    marginRight: "3px",
                    fontSize: "9px",
                  }}
                >
                  ▼
                </span>
              ) : (
                <></>
              )}
              <div className="addMissing">
                <AddMissing
                  event={pair}
                  datum={datum}
                  defaultEvent={which === "in" ? 0 : 1}
                  setUpdate={setUpdate}
                  location={location}
                />
              </div>
            </div>
          );
        })}
        {!records.length ? (
          <>
            <AddMissing
              event={pair}
              datum={datum}
              defaultEvent={which === "in" ? 0 : 1}
              setUpdate={setUpdate}
              location={location}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </td>
  );
}
