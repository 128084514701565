import { useEffect } from "react";
import MultiAddField from "../MultiAddField";
import MultiAddSubField from "./MultiAddCell";
import MultiAddTableRow from "./MultiAddTableRow";
import { multiAddProps } from "../../formContentType";
import { useFormContext } from "react-hook-form";

export default function MultiAddTable({
  columns,
  columnsSum,
  entries,
  setEntries,
  multiAddProps,
  usedUniqueOptions,
  setUsedUniqueOptions,
  sub,
  topSchema,
  uniqueID,
}: {
  columns: any;
  columnsSum?: Function;
  entries: any;
  setEntries: Function;
  multiAddProps?: multiAddProps;
  usedUniqueOptions: any;
  setUsedUniqueOptions: Function;
  sub: any;
  topSchema?: any;
  uniqueID?: Number;
}) {
  
  const columnArr =
    columns &&
    Object.keys(columns)
      .filter((k: string) => !columns[k]?.fullRow)
      .map((key: string) =>
        typeof columns[key] === "function" ? columns[key]({}) : columns[key]
      );

  const sum = columnsSum && columnsSum(columns, entries);

  const multiAddDataBlocks = entries?.map(
    (entry: any, index: number) =>
      entry && (
        <MultiAddTableRow
          key={
            entry.guid ? entry.guid : entry.id ? entry.id : entry?.name || index
          }
          entry={entry}
          entries={entries}
          setEntries={setEntries}
          columns={columns}
          index={index}
          multiAddProps={multiAddProps}
          usedUniqueOptions={usedUniqueOptions}
          setUsedUniqueOptions={setUsedUniqueOptions}
          topSchema={topSchema}
          uniqueID={uniqueID}
        />
      )
  );

  return multiAddProps?.compact ? (
    <div className="multi-compact">{multiAddDataBlocks}</div>
  ) : (
    <div>
      <table className="multi-add-table">
        <thead>
          <tr>
            <th></th>
            {columnArr?.map((column: any, index: number) =>
              column?.hidden ? (
                <></>
              ) : (
                <th key={index} title={column?.title}>
                  {column.name}
                </th>
              )
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>{multiAddDataBlocks}</tbody>
        {sum && <tfoot>{sum}</tfoot>}
      </table>
    </div>
  );
}
