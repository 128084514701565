import insertGeneric from "../../../dataFetch/insertGeneric";
import Form from "../../../forms_new/Form";
import { objectOrJSONparse } from "../../../functions/tools";
import { registerSchema } from "../schemaType";
import expandIcon from "../../../../images/icons/expand.svg";

export function schema(data: any, functions: any, auth: any): registerSchema {
  return {
    name: "files",
    defaultParams: {
      name:"getFiles",
      order: {
        by: "id",
        dir: "DESC",
      },
    },
  };
}
