import CalendarSettings from "./components/calendar/CalendarSettings";

export default function Settings({ page }: { page: string }) {
  return (
    <div style={{ padding: "15px", width:"100%" }} className="settings">
      {page === "calendar" ? (
       <CalendarSettings/>
      ) : (
        <>
          <div>Brak ustawień</div>
        </>
      )}
    </div>
  );
}
