export default function Pagination({
  rows,
  page,
  pages,
  setPage,
}: {
  rows: any;
  page: number;
  pages: number;
  setPage: Function;
}) {
  return (
    <div className="page-change-wrap">
      <div
        className={"page-change" + (pages > 5 ? " active" : "")}
        onClick={() => setPage(0)}
      >
        {"|<"}
      </div>
      <div
        className={"page-change" + (pages > 1 ? " active" : "")}
        onClick={() => setPage((prev: number) => (prev > 0 ? prev - 1 : prev))}
      >
        {"<"}
      </div>
      <div className="page-change-main">
        {rows && (
          <>
            {page + 1}/{pages ? pages : 1}
          </>
        )}
      </div>
      <div
        className={"page-change" + (pages > 1 ? " active" : "")}
        onClick={() =>
          setPage((prev: number) => (prev + 1 < pages ? prev + 1 : prev))
        }
      >
        {">"}
      </div>
      <div
        className={"page-change" + (pages > 5 ? " active" : "")}
        onClick={() => setPage((prev: number) => pages - 1)}
      >
        {">|"}
      </div>
    </div>
  );
}
