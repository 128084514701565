import { JsxEmit } from "typescript";
import BooleanSymbol from "../viewElements/BooleanSymbol";
import { viewTypes } from "./types/viewTypes";

export default function paymentFormChangeView(item: any): {
  columns: viewTypes;
} {
  const columns = [
    {
      ref: "entry_number",
      name: "Lp.",
      jsx: <>{item.entry_number}</>,
      style: { width: "40px" },
      formHide: true,
    },
    {
      ref: "document_type",
      name: "Rodzaj dokumentu sprzedaży",
      jsx: <>{item.sales_document_type_string}</>,
      style: { fontWeight: "bold" },
      formElement: "select",
      getOptionsFrom: "salesDocumentTypes",
    },
    {
      ref: "document_number",
      name: "Numer FV/PAR",
      jsx: <>{item.document_number}</>,
    },
    {
      ref: "gross_amount",
      name: "Kwota brutto na FV/PAR",
      jsx: <>{item.gross_amount}</>,
    },
    {
      ref: "incorrect",
      name: "Forma błędna",
      jsx: <>{item.payment_form_string_incorrect}</>,
      formElement: "select",
      getOptionsFrom: "paymentFormsList",
    },
    {
      ref: "correct",
      name: "Forma właściwa",
      jsx: <>{item.payment_form_string_correct}</>,
      formElement: "select",
      getOptionsFrom: "paymentFormsList",
    },
    {
      ref: "reporting_employee",
      name: "Pracownik zgłaszający",
      jsx: (
        <>
          {item.employee_first_name} {item.employee_last_name}
        </>
      ),
      formElement: "select",
      getOptionsFrom: "employeesGeneric",
    },
    {
      ref: "resolved",
      name: "Poprawiono?",
      jsx: <BooleanSymbol value={item.resolved} />,
      formHide: true,
    },
    {
      ref: "active",
      name: "Aktywne",
      style: { cursor: "pointer" },
      jsx: <BooleanSymbol value={item.active} />,
      formHide: true,
    },
  ];
  return { columns: columns };
}
