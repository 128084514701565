import insertGeneric from "../../../dataFetch/insertGeneric";
import Form from "../../../forms_new/Form";
import { objectOrJSONparse } from "../../../functions/tools";
import { invoiceSearch, receiptSearch } from "./formSchemaMain";

//|id |error_id|correction_document_type|
//doc_id|doc_number|orrection_document_issue_date
//|doc_client|created_by|modified_by|last_edit|

export default function AddCorrectDocument({
  row,
  functions,
}: {
  row: any;
  functions: any;
}) {
  const { doc_number: cor_doc_number } = row;

  return (
    <div className="editor form-clear" style={{ padding: 0, width: "100%" }}>
      <Form
        name="corrected"
        data={row}
        submit={async (payload: any) => {
          await insertGeneric("set-error-correction", payload);
          await functions?.setUpdates((prev: number) => prev + 1);
          functions?.showFunction(false);
        }}
        schema={{
          korekta: {
            name: "",
            content: [
              [
                {
                  name: "cor_id",
                  outerStyle: {
                    display: "none",
                  },
                  initialValue: row?.cor_id,
                },
                {
                  name: "error_id",
                  outerStyle: {
                    display: "none",
                  },
                  initialValue: row.id,
                },
                {
                  name: "doc_type",
                  label: "Rodzaj dokumentu",
                  element: "select-picker",
                  initialValue: row?.cor_doc_type,
                  //   format: "half-1",
                  optionsList: [
                    {
                      id: 1,
                      name: "faktura",
                    },
                    {
                      id: 2,
                      name: "paragon",
                    },
                  ],
                },
                {
                  //hybrid
                  //   format: "half-2",
                  ...invoiceSearch(row),
                  visibleIf: (v: any) => Number(v?.doc_type) === 1,
                  resultUpdate: (item: any) => [
                    [
                      "doc_number",
                      `FV/${item.DocNo}/${item.month}/${item.year}/H`,
                    ],
                    [
                      "doc_issue_date",
                      new Date(item.Data).toISOString().slice(0, 10),
                    ],
                    ["doc_client", item.ko_firma],

                    ["doc_id", item.SCID || ""],

                    ["doc_gross", Math.round(item.ValueBWal * 100) / 100 || ""],
                  ],
                },
                {
                  //hybrid
                  //   format: "half-2",
                  ...receiptSearch(row),
                  visibleIf: (v: any) => Number(v?.doc_type) === 2,
                  resultUpdate: (item: any) => [
                    ["doc_number", item?.full_number],
                    [
                      "doc_issue_date",
                      new Date(item?.data_dzienna).toISOString().slice(0, 10),
                    ],
                    ["doc_client", item.ko_firma],
                    ["doc_id", item?.id || ""],
                    ["doc_gross", Math.round(item.ValueBWal * 100) / 100 || ""],
                  ],
                },
                {
                  name: "doc_id",
                  label: "Id dokumentu",
                  outerStyle: { display: "none" },
                  initialValue: row?.cor_doc_id,
                },
                {
                  name: "doc_number",
                  label: "Nr dokumentu",
                  readOnly: true,
                  format: "half-1",
                  initialValue: row?.cor_doc_number,
                },
                {
                  name: "doc_issue_date",
                  inputType: "date",
                  readOnly: true,
                  label: "Data wydania dokumentu",
                  format: "half-2",
                  initialValue: row?.cor_doc_issue_date,
                },
                {
                  name: "doc_client",
                  readOnly: true,
                  label: "Klient",
                  format: "half-1",
                  initialValue: row?.cor_doc_client,
                },
                {
                  name: "doc_gross",
                  readOnly: true,
                  label: "Kwota brutto",
                  format: "half-2",
                  initialValue: row?.cor_doc_gross,
                },
                {
                  name: "doc_file",
                  label: "Dodaj skan/zdjęcie",
                  element: "file",
                  filesLoad: {
                    module: "errors",
                    sub_module:"corrected",
                    module_id: row?.id,
                    sub_category: "doc_file",
                  },
                  //   objectOrJSONparse(row?.files)?.filter(
                  //     (d: any) => d.field === "other_file"
                  //   ),
                  fileInfo: {
                    module: "errors",
                    moduleID: row?.id,
                    subModule: "corrected",
                    category: "correction-documents",
                    // sub_category: "doc_file",
                    subID: 0,
                  },
                },
              ],
            ],
          },
        }}
      />
    </div>
  );
}
