import { useContext } from "react";
import { NameContext } from "./FormatPeople";
import Loading from "./Loading";

export default function Person({
  name,
  short,
}: {
  name: string;
  short?: Boolean;
}) {
  const {names: people, loading} = useContext(NameContext);
  const found: any = people?.find(
    (p: any) => p?.original?.toUpperCase() === name?.toUpperCase()
  );
  const firstName = found && found?.first && found?.first;

  const split = name?.split(" ")?.filter((v: any) => v !== "");

  const firstNameOutput =
    firstName && firstName?.charAt(0)?.toUpperCase() + firstName?.slice(1)?.toLowerCase();
  const lastName = name?.toUpperCase()?.replace(firstName, "")?.replace(" ","");
  const lastNameOutput =
    firstName && lastName?.toUpperCase()?.charAt(0) + lastName?.slice(1)?.toLowerCase();
  
  const surnameFirst =
    split?.length === 2 && firstName
      ? lastNameOutput + " " + firstNameOutput
      : name;

  const surSplit = surnameFirst?.split(" ")?.filter((v: any) => v !== "");

  const shortName =
    surSplit?.[0]?.length > 1
      ? surSplit?.[0] + " " + surSplit[1]?.slice(0, 1)
      : name;

  return (
    <span data-name={name} className="person-converted" style={{opacity: loading ? .8 : 1}}>
      {short ? shortName : surnameFirst}
      {/* {surnameFirst} */}
    </span>
  );
}
