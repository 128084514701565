import DateDisplay from "./viewElements/DateDisplay";
import thumbsUpIcon from "../../images/icons/thumbs-up.svg";
import plusIcon from "../../images/icons/plus.svg";
import thumbsUpActiveIcon from "../../images/icons/thumbs-up-green.svg";

import { useContext, useState } from "react";
import insertGeneric from "../dataFetch/insertGeneric";
import { objectOrJSONparse } from "../functions/tools";
import { UserContext } from "../contexts/UserContext";

export default function ChatItem({
  user,
  comment,
  setUpdates,
}: {
  user: any;
  comment: any;
  setUpdates: Function;
}) {
  //   const [reaction, setReaction] = useState(false);//comment.flag);

  const reactions = objectOrJSONparse(comment?.flags);
  const activeReactions = reactions?.filter((r: any) => r.flag == 1);
  const { userID } = useContext(UserContext);

  const myReaction = reactions?.find((item: any) => item?.user_id === userID);

  async function setDiscussionFlag(id?: number) {
    console.log({
      id: id || undefined,
      message_id: comment?.id,
      flag: Number(!Boolean(myReaction?.flag)),
    });
    const result = insertGeneric("discussion-flag", {
      id: id || undefined,
      message_id: comment?.id,
      flag: Number(!Boolean(myReaction?.flag)),
    });
    setUpdates((prev: number) => (prev = prev + 1));
  }
  return (
    <div
      key={comment.id}
      className={
        "commentItem " + (user.userID === comment.author ? "mine" : "not-mine")
      }
    >
      <div className="commentWrap">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="commentAuthor">
            {user.userID === comment.author ? (
              <>Ja</>
            ) : (
              <>
                {comment.firstname} {comment.lastname}
              </>
            )}
          </div>
          <div className="commentTime">
            <DateDisplay
              dateString={comment.time}
              format="datetime"
              options={{ singleLine: true, weekDay: "long" }}
            />
          </div>
        </div>
        <div className="commentContent">{comment.content}</div>
        <div
          onClick={() => {
            if (myReaction) {
              setDiscussionFlag(myReaction?.flagID);
            } else {
              setDiscussionFlag();
            }
          }}
          className={
            "reaction" +
            (reactions?.find((i: any) => i.flag == 1) ? " up" : " no")
          }
          style={activeReactions?.length > 1 ? { width: "32px" } : {}}
          title={activeReactions?.map((r: any) => r?.user_name)?.join(", ")}
        >
          <img src={reactions?.length ? thumbsUpActiveIcon : thumbsUpIcon} />
          {activeReactions?.length > 1 ? (
            <div style={{ fontSize: "11px", fontWeight:"bold" }}>{reactions?.length}</div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
