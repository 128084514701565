import { useContext, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import { URL } from "../paths";
import DocumentModule from "./DocumentModule";
import FileUpload, { prettyFileSize } from "./FileUpload";
import FileUploadList from "./FileUploadList";
import Value from "./Value";
import { ViewContext } from "../contexts/ViewContext";
import Form from "../forms_new/Form";
import Loading from "../common/Loading";
import insertGeneric from "../dataFetch/insertGeneric";
import EditContract from "./EditContract";
import editIcon from "../../images/icons/pencil.png";
import removeIcon from "../../images/icons/delete.svg";
import prettyDate from "../functions/prettyDate";

export default function ContractDetails({
  employee,
  updates,
  setUpdates,
}: {
  employee: any;
  updates: number;
  setUpdates: Function;
}) {
  const { modal, setModal } = useContext(ViewContext);
  const [tab, setTab] = useState<false | number>(false);
  const tabs = [
    {
      title: "Część A - Ubieganie się o zatrudnienie",
      module: "contracts",
      subModule: "A",
    },
    {
      title: "Część B - Nawiązanie stosunku pracy i przebieg zatrudnienia",
      module: "contracts",
      subModule: "B",
    },
    {
      title: "Część C - Ustanie stosunku pracy",
      module: "contracts",
      subModule: "C",
    },
    {
      title: "Część D - Odpowiedzialność porządkowa lub inna",
      module: "contracts",
      subModule: "D",
    },
  ];
  const f = useGeneric({
    name: "uploads_list",
    limit: 20,
    update: updates + "|" + employee.id,
    query: {
      module: "contracts",
      module_id: employee.id,
      active: true,
      in_trash: 0,
    },
    reload: true,
  });

  const c = useGeneric({
    name: "employee_contracts",
    limit: 500,
    update: updates + "|" + employee.id,
    query: {
      employee_id: employee.id,
      active: true,
    },
    reload: true,
  });

  async function deleteItem(item: any) {
    const response = await fetch(`${URL}backend/inserts/set-contract.php`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ id: item.id, remove: true }),
    });
    const result = await response.json();
    if (response.ok) {
      setUpdates((prev: number) => prev + 1);
    }
  }

  const uploadedFiles = !f.loading && f?.rows;
  const contracts = !c.loading && c?.rows;

  return (
    <div>
      <div className="heading">
        <h2 className="bigger">Umowy</h2>
        <div
          className="add-files-toggle"
          onClick={() =>
            setModal({
              show: true,
              heading: "Dodaj umowę",
              content: (
                <EditContract
                  employee={employee}
                  setUpdates={setUpdates}
                  setModal={setModal}
                />
              ),
            })
          }
        >
          + Dodaj umowę
        </div>
      </div>
      <div className="contract-history">
        {contracts && contracts.length ? (
          <table className="data-table midi">
            <thead>
              <tr>
                <th>Lp.</th>
                <th>Rodzaj umowy</th>
                <th>Stanowisko</th>
                <th>Od</th>
                <th>Do</th>

                <th>Etat</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {contracts?.map((c: any, index: number) => (
                <tr>
                  <td>{contracts.length - index}</td>
                  <td>{c.contract_type_string}</td>
                  <td style={{ fontSize: "90%", width: "180px" }}>
                    {c.job_title}
                  </td>
                  <td>
                    {prettyDate(c.contract_start_date, "", true, {
                      year: "numeric",
                    })}
                  </td>
                  <td>
                    {!c.contract_indefinite
                      ? prettyDate(c.contract_end_date, "", true, {
                          year: "numeric",
                        })
                      : "czas nieokreślony"}
                  </td>

                  <td>{c.contract_full_time_equivalent}</td>
                  <td
                    onClick={() =>
                      setModal({
                        show: true,
                        heading: "Dodaj umowę",
                        content: (
                          <EditContract
                            id={c.id}
                            employee={employee}
                            setUpdates={setUpdates}
                            setModal={setModal}
                            editData={c}
                          />
                        ),
                      })
                    }
                  >
                    <img src={editIcon} width={15} />
                  </td>
                  <td
                    onClick={() => {
                      if (window.confirm("Czy na pewno chesz usunąć wpis?")) {
                        deleteItem(c);
                      }
                    }}
                  >
                    <img src={removeIcon} width={15} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : c.loading ? (
          <Loading little={true} />
        ) : (
          <div className="info">Nie wprowadzono umów</div>
        )}
      </div>
      {/* <Value
        label="Wynagrodzenie brutto"
        data="-"
        isEditing={false}
        editor="number"
      />
      <Value
        label="Wynagrodzenie netto"
        data="-"
        isEditing={false}
        editor="number"
      />
      <Value label="Dodatki" data="-" isEditing={false} editor="number" /> */}
      <div className="heading">
        <h2 className="bigger">Akta osobowe</h2>
        <div className="employee-document-tabs">
          <div
            className={tab === false ? "active" : ""}
            onClick={() => setTab(false)}
          >
            Wszystkie
          </div>
          {tabs.map((t: any, index: number) => (
            <div
              className={tab !== false && tab === index ? "active" : ""}
              onClick={() => setTab(index)}
            >
              {t.subModule}
            </div>
          ))}
        </div>
      </div>

      {tabs.map(
        (t: any, index: number) =>
          (tab === false || tab === index) && (
            <DocumentModule
              key={t.subModule}
              title={t.title}
              module={t.module}
              subModule={t.subModule}
              moduleID={employee.id}
              uploadedFiles={uploadedFiles}
              setUpdates={setUpdates}
            />
          )
      )}
    </div>
  );
}
