import { useState } from "react";
import { createGUID, flatOptions } from "../../functions/tools";

export default function MultiAddTemplateAdder({
  template,
  setEntries,
  setModal,
  columns,
}: {
  template: any;
  setEntries: Function;
  setModal: Function;
  columns: any;
}) {
  // console.log(template)
  const [selectedEntries, setSelectedEntries] = useState(() =>
    template.map((t: any) => t?.guid)
  );

  function selectIdToName(id: any, prop: any) {
    const options = columns[prop]?.optionsList;
    return options
      ? flatOptions(options)?.find((o: any) => o.id === id)?.name
      : id;
  }

  return (
    <div style={{ width: "100%" }}>
      {template &&
        Array.isArray(template) &&
        template?.map((i: any) => {
          const isSelected =
            selectedEntries.findIndex((guid: any) => guid === i?.guid) !== -1;

          return (
            <div
              className={"template-list-row" + (isSelected ? " selected" : "")}
              onClick={() =>
                setSelectedEntries((prev: any) => {
                  if (prev.find((guid: any) => guid === i?.guid)) {
                    return prev.filter((guid: any) => guid !== i?.guid);
                  } else {
                    return [...prev, i?.guid];
                  }
                })
              }
            >
              <div>
                <input type="checkbox" checked={isSelected} />
              </div>
              {Object.keys(i)
                .filter(
                  (k: string) => k !== "guid" && (k == "name" || k == "nazwa" || k == "category")
                )
                .map((k: string) => (
                  <div key={k} title={k}>
                    <div
                      style={{
                        fontSize: "12px",
                        display: "flex",
                      }}
                    >
                      {Number.isInteger(i?.[k])
                        ? selectIdToName(i?.[k], k)
                        : i?.[k]}
                    </div>
                  </div>
                ))}
            </div>
          );
        })}
      {selectedEntries.length ? (
        <button
          onClick={() => {
            console.log(
              template.filter((t: any) =>
                selectedEntries.find((f: any) => t.guid === f)
              )
            );
            // .map((t: any) => {
            //   if (t.guid) {
            //     delete t.guid;
            //   }
            //   return t;
            // }))
            setEntries(
              (prev: any) =>
                template.filter((t: any) =>
                  selectedEntries.find((f: any) => t.guid === f)
                )
              //   .map((t: any) => {
              //     if (t.guid) {
              //       delete t.guid;
              //     }
              //     return t;
              //   })
            );

            setModal((prev: any) => ({
              ...prev,
              show: false,
            }));
          }}
          className="normal-button"
          style={{ float: "right" }}
        >
          Wstaw wybrane ({selectedEntries.length})
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
