import Value from "./Value";
import editIcon from "../../images/icons/pencil.png";
import listIcon from "../../images/icons/eye.svg";
import { useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import prettyDate from "../functions/prettyDate";
import { datesSince } from "../functions/timeAgo";
import ContractMandateInfo from "./ContractMandateInfo";

export default function Contract({
  employee,
  subView,
  setSubView,
  updates,
}: {
  employee: any;
  subView: string;
  setSubView: Function;
  updates: number;
}) {
  const editing = false;

  const c = useGeneric({
    name: "employee_contracts",
    limit: 100,
    query: {
      employee_id: employee.id,
      active: 1,
    },
    update: updates,
    reload: true,
  });

  const lastContract = !c.loading && c?.rows?.[0];

  

  // total months under contract of employment so far - indefinite term after 33
  const totalMonths = Math.round(
    c?.rows
      ?.filter((d: any) => d.contract_type === 1)
      ?.map((d: any) => {
        const start = d.contract_start_date && new Date(d.contract_start_date);
        const end = d.contract_start_date && new Date(d.contract_end_date);
        if (start && end) {
          const timeBetween = end.getTime() - start.getTime();
          const daysBetween = timeBetween / 1000 / 60 / 60 / 24;
          return daysBetween;
        } else {
          return 0;
        }
      })
      ?.reduce((a: number, b: number) => a + b, 0) / 30
  );

  // number of contracts of employment so far
  const numberOfCoE = c?.rows?.filter((d: any) => d.contract_type === 1).length;

  return (
    <div className="division">
      <div className="heading">
        <h2>Umowa</h2>
        <div className="options">
          {/* <button>
            <img src={editIcon} />
          </button> */}
          <button
            className={
              "more-details" + (subView === "contract" ? " active" : "")
            }
            onClick={() => setSubView("contract")}
          >
            <img src={listIcon} />
          </button>
        </div>
      </div>
      {lastContract ? (
        <>
          <Value
            label="Stanowisko"
            name="job_title"
            data={lastContract.job_title} //"--nazwa stanowiska--"
            isEditing={editing}
            editor="input"
          />
          <Value
            label="Rodzaj"
            name="contract_type"
            data={lastContract.contract_type_string}
            isEditing={editing}
            editor="select"
          />
          <Value
            label="Termin"
            name="contract_indefinite"
            data={
              lastContract.contract_indefinite === 0
                ? "czas określony" +
                  " (do " +
                  prettyDate(lastContract.contract_end_date, "", true, {
                    year: "numeric",
                  }) +
                  ")"
                : lastContract.contract_indefinite === 1
                ? "czas nieokreślony"
                : ""
            }
            isEditing={editing}
            editor="date"
          />
          {totalMonths ? (
            <>
              <Value
                label="Łączny czas trwania umów o pracę (liczba umów)"
                name="contract_time"
                data={
                  (totalMonths ? totalMonths + " mies." : "brak") +
                  " " +
                  "(" +
                  numberOfCoE +
                  " szt.)"
                }
                isEditing={editing}
                editor="input"
              />
              <Value
                label="Do umowy na czas nieokreślony (pozostało umów)"
                name="contract_time"
                data={
                  (totalMonths ? 33 - totalMonths + " mies." : "brak") +
                  " " +
                  "(" +
                  (3 - numberOfCoE) +
                  " szt.)"
                }
                isEditing={editing}
                editor="input"
              />
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className="info">Brak danych o umowie</div>
      )}
      {/* <div className="labeled-value">
        <label>Dokument</label>
        <button>zobacz</button>
      </div> */}
      <ContractMandateInfo contract={lastContract}/>
    </div>
  );
}
