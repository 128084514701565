import { formField, forms } from "../forms_new/formContentType";
import minutesToJSX, { minutesToString } from "../functions/minutesToJSX";
import prettyDate, {
  compareDay,
  dayDiff,
  isDate,
  prettyDateRange,
} from "../functions/prettyDate";
import prettyNumber from "../functions/prettyNumber";
import {
  arrayToNumbersAndStrings,
  cloneField,
  compareTimeStrings,
  createGUID,
  flatOptions,
  inflect,
  isJSONParsable,
  objectOrJSONparse,
  timeStringToNumber,
  timeStringToTime,
} from "../functions/tools";
import GenericDataView from "./GenericDataView";
import {
  dish_type,
  lsi_restaurant,
  schedule_item_type,
  meal_type,
  miscellanea,
  music_type,
  special_options,
  pickDatedFormValues,
  key_info_types,
  scheduleCurrent,
  yesOrNo,
  getDatedFormValue,
  dateFromDatedName,
} from "./definitions/common";
import UserName from "./UserName";
import InvoiceSelector from "./InvoiceSelector";
import ReceiptDetails from "./ReceiptDetails";
import eventIcon from "../../images/icons/star.svg";
import venueIcon from "../../images/icons/location.svg";
import calendarIcon from "../../images/icons/calendar.svg";
import personIcon from "../../images/icons/people.svg";
import { sumCellValues } from "./definitions/calculables";
import SumSettlement from "./SumSettlement";
import { diffPart } from "./definitions/functions";
import { weddingForm } from "./addEventFormWedding";

function venueInput(data: any): formField {
  return {
    name: "venue",
    initialValue: data ? data.venue : "",
    label: "Sala",
    format: "half-2",
    element: "select",
    getOptionsFrom: "venues",
    // required: "Wybierz salę",
    outerStyle: { display: "none" },
  };
}

function isSingleDay(vs: any) {
  return (
    !vs.date_end || !isDate(new Date(vs.date_end)) || vs.date_end === vs.date
  );
}

function times(data: any): formField[] {
  return [
    {
      name: "time_start",
      format: "half-1",
      initialValue: data ? data.time_start : "",
      label: "Od",
      inputType: "time",
      visibleIf: (vs: any) => isSingleDay(vs),
      required: "Podaj o której godzinie zaczyna się impreza",
    },
    {
      name: "time_end",
      format: "half-2",
      initialValue: data ? data.time_end : "",
      label: "Do",
      inputType: "time",
      visibleIf: (vs: any) => isSingleDay(vs),
      required: "Podaj do której godziny trwa impreza",
      /*  validate: (v: string, vs: any) => {
        const t1 = parseInt(vs.time_start.replace(":", ""));
        const t2 = parseInt(vs.time_end.replace(":", ""));
        //console.log(t1,t2);
        return (
          t1 < t2 ||
          "Godzina zakończenia jest wcześniejsza niż godzina rozpoczęcia"
        );
      },*/
    },
    /*
    {
      name: "duration",
      label: "Czas trwania",
      from: {
        values: ["time_start", "time_end"],
        transform: (values) => {
          const a = new Date("2000-01-01 " + values.time_start);
          const b = new Date("2000-01-01 " + values.time_end);
          if (a.getTime() > b.getTime()) {
            b.setDate(b.getDate() + 1);
          }
          return minutesToJSX((b.getTime() - a.getTime()) / 1000 / 60, false);
        },
      },
    },*/
  ];
}

function eventType(data: any): formField {
  return {
    name: "event_type",
    initialValue: data ? data.event_type : "",
    label: "Rodzaj imprezy",
    element: "select",
    format: "half-1",
    getOptionsFrom: "events",
    required: "Wybierz rodzaj imprezy",
  };
}

function eventDate(data: any, date: any, name = "date"): formField {
  return {
    name: name,
    initialValue: data
      ? data[name]
      : date
      ? new Date(date).toISOString().slice(0, 10)
      : "",
    label: "Data",
    inputType: "date",
    required: "Podaj datę imprezy",
    min: {
      value: "2015-01-01",
      message: "Data wydarzenia zbyt odległa w czasie",
    },
    max: {
      value: new Date(new Date().setFullYear(new Date().getFullYear() + 5))
        .toISOString()
        .slice(0, 10),
      message: "Możesz planować wydarzenia tylko na 5 lat w przyszłość",
    },
  };
}

export function hybridDocumentField(values: any): any {
  const documentType = values.documentType;
  const isInvoice = documentType == 1;
  const isReceipt = documentType == 2;
  return {
    name: "Szukaj LSI",
    element: "hybrid",
    disableIf: !isInvoice && !isReceipt,
    style: { width: "90px" },
    schema: {
      name: "lsi_invoice_id",
      visibleIf: { document_type: "0" },
      initialValue: "",
      label: isInvoice ? "Nr faktury LSI" : isReceipt ? "Nr paragonu LSI" : "",
      //visibleIf: { is_company: 1 },
      element: "hybrid",
      db: isInvoice
        ? "lsi_invoice_query"
        : isReceipt
        ? "lsi_receipt_query"
        : "",
      groupBy: [
        (v: any) => (v?.SCID ? v.SCID : v.id),
        ["VAT", "description", "amount", "SCPID"],
      ],
      query: (input: string | number) => ({
        multiQuery: input,
      }),
      resultElement: isInvoice ? (
        (item: any) => (
          <div className="invoice-find">
            <div style={{ fontWeight: "500" }}>
              FV/{item.DocNo}/{item.month}/{item.year}/H
            </div>
            <div style={{ textAlign: "left" }}>{item.ko_firma}</div>
            <div style={{ textAlign: "right" }}>
              {Math.round(item.ValueBWal * 100) / 100} zł
            </div>
          </div>
        )
      ) : isReceipt ? (
        (item: any) => (
          <div
            className="invoice-find"
            style={{
              gridTemplateColumns: "70px 80px 1fr 100px",
              alignItems: "center",
            }}
          >
            <div style={{ fontWeight: "500" }}>
              <span style={{ fontSize: "10px" }}>PAR/</span>
              {item.DocNo}
            </div>
            <div style={{ fontSize: "10px" }}>
              {item.data_dzienna.slice(0, 10)}
            </div>
            <div style={{ textAlign: "left" }}>{item.ko_firma}</div>
            <div style={{ textAlign: "right" }}>
              {Math.round(item.ValueBWal * 100) / 100} zł
            </div>
          </div>
        )
      ) : (
        <></>
      ),
      resultUpdateSub: (item: any) => {
        let date = item.Data,
          docNo = item.DocNo,
          net = item.ValueN,
          details = item.SCID,
          vat = item.VAT,
          gross = item.ValueBWal,
          itemID = item?.SCPID?.replace(/\+\|\+/g, ",");
        if (isReceipt) {
          date = item.data_dzienna;
          net = item.paid_net;
          details = item.id;
          vat = 0;
        }
        return [
          ["date", new Date(date).toISOString().slice(0, 10)],
          ["itemID", itemID],
          [
            "docNo",
            isInvoice
              ? `FV/${docNo}/${item.month}/${item.year}/H`
              : isReceipt
              ? `PAR/${docNo}`
              : "",
          ],
          ["net", Math.round(net * 100) / 100],
          ["documentDetails", details],
          [
            "vat",
            vat && vat.search(/\+\|\+/) !== -1
              ? vat?.split("+|+")?.join(", ")
              : vat,
          ],
          ["gross", Math.round(gross * 100) / 100],
          ["guid", createGUID()],
        ];
      },
      /*resultUpdate: (item: any) => [
      ["lsi_invoice_id", item.DocNo],
      ["lsi_receipt_id", ""],
    ],*/
      inputType: "search",
    },
  };
}

function showDates(values: any) {
  const dates = values.dates ? JSON.parse(values.dates) : [];
  const firstDate = dates.length && dates[0].date;
  const lastDate = dates.length && dates.at(-1).date;

  return (
    dates && (
      <>
        {firstDate != lastDate ? (
          <>
            {prettyDateRange(firstDate, lastDate)}
            {/*
              <div style={{ fontSize: "15px" }}>
                {prettyDate(firstDate, "", true)} -{" "}
                {prettyDate(lastDate, "", true)}
              </div>{" "}
              <div style={{ color: "grey", fontSize: "11px" }}>
                {inflect(dates.length, ["dni", "dzień", "dni", "dni"])}
          </div>*/}
          </>
        ) : (
          <>{prettyDate(firstDate, "Dziś", true)}</>
        )}
      </>
    )
  );
}

export const eventForm = (
  data: any,
  date?: Date,
  auth?: any,
  userID?: number
): forms => ({
  super: {
    name: "",
    content: [
      [
        {
          name: "date-description",
          description: "",
          outerStyle: {
            background: "#c7e1ec",
            borderRadius: "5px",
            padding: "2px 15px",
            marginBottom: "0px",
            boxShadow: "0px 0px 14px rgba(0,0,0,.2)",
          },
          from: {
            values: [
              // commented out - doesn't matter any more
              "dates",
              "is_company",
              "company_name",
              "first_name",
              "last_name",
              "schedules",
              "_guest_number_child",
              "_guest_number_adult",
              //    "venue",
              //    "event_type",
            ],
            transform: (values: any, flatSchema: any, customData: any) => {
              //  console.log(customData?.options?.events?.rows);
              const eventOptions = customData?.options?.events?.rows;
              // const venueOptions = customData?.options?.venues?.rows;
              const eventName = eventOptions?.find(
                (e: any) => e.id == values.event_type
              )?.name;

              const schedules: any = [];
              Object.keys(values).forEach((k: any) => {
                if (k.match(/schedule%.*%/)) {
                  schedules?.push(values[k] && JSON.parse(values[k]));
                }
              });
              // console.log(schedules.flat());
              const venues = [
                ...new Set(schedules.flat().map((s: any) => s.venue_string)),
              ];

              const propNames = Object.keys(values);
              const guestPropNames = propNames.filter(
                (name: string) =>
                  name.match(/guest_number_/) &&
                  !name.match(/guest_number_diets/)
              );
              const guestPropsByDate: any = [];
              guestPropNames.forEach((name: string) => {
                const date = name.match(/.*%(.*)%/)?.[1];
                // console.log(date);
                if (date && Array.isArray(guestPropsByDate[date])) {
                  guestPropsByDate[date]?.push(values[name]);
                } else if (date) {
                  guestPropsByDate[date] = [values[name]];
                }
              });

              const people = Object.keys(guestPropsByDate)?.map((key: any) =>
                guestPropsByDate[key]
                  ?.map((n: any) => Number(n))
                  ?.reduce((a: number, b: number) => a + b, 0)
              );
              // console.log(guestPropNames, guestPropsByDate, people);
              const maxPeople = Array.isArray(people) ? Math.max(...people) : 0;
              // const people =
              //   values.dates && isJSONParsable(values.dates)
              //     ? JSON.parse(values.dates).map(
              //         (d: any) =>
              //           (d._guest_number_adult
              //             ? Number(d._guest_number_adult)
              //             : 0) +
              //           (d._guest_number_child
              //             ? Number(d._guest_number_child)
              //             : 0)
              //       )
              //     : 0;
              // const maxPeople =
              //   people && Array.isArray(people) ? Math.max(...people) : 0;

              return (
                <div className="event-summary-top">
                  <div>
                    {values.is_company == "2" ||
                    values.company_name ||
                    values.first_name ||
                    values.last_name ? (
                      <img src={personIcon} />
                    ) : (
                      <></>
                    )}
                    {values.is_company == "2" ? (
                      <>AGIT</>
                    ) : values.is_company == "1" && values.company_name ? (
                      values.company_name
                    ) : (
                      <>
                        {values.first_name} {values.last_name}
                      </>
                    )}
                  </div>
                  <div>
                    {venues.length ? <img src={venueIcon} /> : <></>}
                    <div style={{ display: "flex" }}>
                      {venues.map((v: any, i: number, all: any) => {
                        const notLast =
                          i < all?.filter((a: any) => a)?.length - 1;
                        return v ? (
                          <div
                            style={{
                              marginRight: notLast ? "8px" : "0px",
                              paddingRight: "4px",
                              boxShadow: notLast
                                ? "5px 0 2px -4px grey"
                                : undefined,
                            }}
                          >
                            {i > 0 ? v?.replace("sala", "") : v}
                          </div>
                        ) : (
                          <></>
                        );
                      })}
                    </div>
                  </div>
                  <div>
                    {eventName ? <img src={eventIcon} /> : <></>}
                    {eventName}
                  </div>
                  <div>
                    <img src={calendarIcon} />
                    {showDates(values)}
                  </div>
                  <div title={Array.isArray(people) ? people?.join(", ") : ""}>
                    {maxPeople > 0 ? <>{maxPeople} os.</> : <></>}
                  </div>
                </div>
              );
            },
          },
        },
      ],
    ],
  },
  basics: {
    name: "Główne",
    hide: !auth?.basic,
    readOnly: auth?.basic != 2,
    className: "basic-columns",
    content: [
      [
        {
          name: "is_company",
          initialValue: data ? (data.is_company == "1" ? 1 : 0) : 0,
          label: "Rodzaj klienta",
          format: "half-1",
          element: "select-picker",
          optionsList: [
            {
              id: 0,
              name: "indywidualny",
              active: true,
            },
            {
              id: 1,
              name: "firma",
              active: false,
            },
            {
              id: 2,
              name: "własne",
              active: false,
            },
          ],
          required: "Wskaż rodzaj klienta",
        },
        {
          name: "company_nip",
          initialValue: data ? data.company_nip : "",
          label: "NIP",
          format: "half-2",
          minLength: {
            value: 10,
            message: "Numer NIP jest za krótki",
          },
          maxLength: {
            value: 10,
            message: "Numer NIP jest za długi",
          },

          validate: (nip: any) => {
            if (typeof nip !== "string") return false;

            nip = nip.replace(/[\ \-]/gi, "");

            let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
            let sum = 0;
            let controlNumber = parseInt(nip.substring(9, 10));
            let weightCount = weight.length;
            for (let i = 0; i < weightCount; i++) {
              sum += parseInt(nip.substr(i, 1)) * weight[i];
            }

            return sum % 11 === controlNumber || "Nieprawidłowy numer NIP";
          },
          visibleIf: { is_company: "1" },
          element: "hybrid",
          db: "nip",
          query: (input: string | number) => ({
            nip: input,
          }),
          resultElement: (item: any) => <div>{item.name}</div>,
          resultUpdate: (item: any) => [
            ["company_name", item.name],
            ["company_details", JSON.stringify(item)],
          ],
          inputType: "search",
        },
        {
          name: "company_name",
          initialValue: data ? data.company_name : "",
          label: "Nazwa firmy",
          element: "textarea",
          rows: 2,
          style: { height: "60px", lineHeight: "105%" },
          visibleIf: { is_company: "1" },
          required: "Podaj nazwę firmy",
        },
        {
          name: "contact_person_heading",
          element: false,
          description: (
            <div className="little-description-highlight">
              Osoba do kontaktu:
            </div>
          ),
          visibleIf: { is_company: "1" },
        },
        {
          name: "first_name",
          initialValue: data ? data.first_name : "",
          label: "Imię",
          format: "half-1",
          required: "Podaj imię klienta/osoby do kontaktu",
          visibleIf: (values: any) =>
            values.is_company == "0" || values.is_company == "1",
        },
        {
          name: "last_name",
          initialValue: data ? data.last_name : "",
          label: "Nazwisko",
          format: "half-2",
          required: "Podaj nazwisko klienta/osoby do kontaktu",
          visibleIf: (values: any) =>
            values.is_company == "0" || values.is_company == "1",
        },
        {
          name: "email",
          initialValue: data ? data.email : "",
          label: "E-mail",
          format: "half-1",
          type: "email",
          required: "Podaj e-mail klienta",
          pattern: {
            value:
              /(?!(^[.-].*|[^@]*[.-]@|.*\.{2,}.*)|^.{254}.)([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~.-]+@)(?!-.*|.*-\.)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,15}/,
            message: "Niewłaściwy format adresu e-mail",
          },
          visibleIf: (values: any) =>
            values.is_company == "0" || values.is_company == "1",
        },
        {
          name: "phone",
          initialValue: data ? data.phone : "",
          label: "Telefon",
          format: "half-2",
          required: "Podaj telefon klienta",
          visibleIf: (values: any) =>
            values.is_company == "0" || values.is_company == "1",
        },
      ],
      [
        {
          name: "id",
          initialValue: data ? data.id : "",
          inputType: "hidden",
        },
        eventType(data),
        venueInput(data),
        /*{
          ...eventDate(data, date),
          label: "Data od",
          format: "half-1",
          outerStyle: { display: "none" },
        },*/
        /* {
          ...eventDate(data, date, "date_end"),
          label: "Data do",
          format: "half-2", 
          validate: (v: any, values: any) => {
            const dateStart = new Date(values.date);
            const dateEnd = new Date(values.date_end);
            const monthStart = dateStart.getMonth();
            const yearStart = dateStart.getFullYear();
            const monthEnd = dateEnd.getMonth();
            const yearEnd = dateEnd.getFullYear();
            return (
              (dateEnd >= dateStart &&
                (yearStart === yearEnd
                  ? monthEnd - monthStart < 2
                  : monthStart === 11 && monthEnd === 0)) ||
              "Niedozwolone daty rozpoczęcia i zakończenia"
            );
          },
        },*/
        /*...times(data),*/

        {
          name: "dates",
          //fullSpan: true,
          //visibleIf: (vs: any) => !isSingleDay(vs),
          disabled: !auth?.agenda,
          label: "Daty",
          element: "multiAdd",
          className: "days",
          multiAddProps: {
            //mini: true,
            unique: ["date"],
            changeEffect: (
              form: any,
              loaded: any,
              prevRowValues: any,
              rowValues: any,
              setValue: Function
            ) => {
              const schedules =
                form && form?.schedules
                  ? JSON.parse(form.schedules)
                  : loaded && loaded.schedules && JSON.parse(loaded.schedules);
              const newDating = schedules?.map((o: any) => ({
                ...o,
                date: o.date === prevRowValues?.date ? rowValues?.date : o.date,
              }));
              setValue("schedules", JSON.stringify(newDating));
              const dateRegex = new RegExp(`%${prevRowValues?.date}%`);
              Object.keys(form).forEach((key: string) => {
                const isDateSpecific = key.match(dateRegex);
                if (isDateSpecific) {
                  const oldValue = form[key];
                  delete form[key];
                  const newKey =
                    key.replace(/%.*%/, "") + "%" + rowValues?.date + "%";
                  setValue(newKey, oldValue);
                }
              });
            },
            maxLength: 7,
            reorderable: false,
            sort: {
              cell: "date",
              callBack: (a: any, b: any) => dayDiff(b.date, a.date),
            },
            foldable: false,
            clear: false,
            add: [
              (entries: any) => {
                const nextDate = new Date(entries.at(-1).date);

                nextDate.setDate(nextDate.getDate() + 1);
                console.log(nextDate);
                return {
                  date: nextDate.toISOString().slice(0, 10),
                  guid: createGUID(),
                };
              },
              "DODAJ DZIEŃ",
            ],
            affects: (values: any) => {
              const a = new Date("2000-01-01 " + values.time_from);
              const b = new Date("2000-01-01 " + values.time_to);
              if (a.getTime() > b.getTime()) {
                b.setDate(b.getDate() + 1);
              }
              return {
                time_sum: minutesToString(
                  (b.getTime() - a.getTime()) / 1000 / 60,
                  false
                ),
              };
            },
            itemString: ["DNI", "DZIEŃ", "DNI", "DNI"],
            multiEntries: (v: any) => [
              {
                date: date ? new Date(date).toISOString().slice(0, 10) : "",
                //time_from: v.time_start,
                //   time_to: v.time_end,
              },
            ],
            /*const rows = [];
              const n = 0;//dayDiff(v.date, v.date_end);
              let date  = new Date(v.date);
              for ( let p = 0; p <= n; p++) {
                const curDate = new Date(date);
                curDate.setDate(date.getDate() + p);
                rows.push({
                  date: curDate.toISOString().slice(0,10)
                });
              }
              return rows;
            },*/

            columns: {
              /*number: {
                name: "Lp",
                index: true,
              },*/
              date: {
                name: "Dzień",
                type: "date",
                inputStyle: {
                  width: "112px",
                  fontSize: "15px",
                  padding: "0 6px",
                  textAlign: "left",
                  background: "linear-gradient(10deg, #94c4d1, #c1dfe6)",
                  boxShadow: "0 2px rgba(0,0,0,.2)",
                },
                style: {
                  verticalAlign: "baseline",
                  width: "115px",
                },
                //disabled: true,
              },
              summary: {
                name: "Program",
                linkTab: auth?.agenda ? 1 : undefined,
                linkThisDate: true,
                info: true,
                //comments (form: any, row: any) => form && row && form?.[`comments%${row["date"]}%`],
                render: (form: any, row: any) => {
                  if (form && row) {
                    const schedule = form?.[`schedule%${row["date"]}%`];

                    //console.log(form);

                    const sch =
                      schedule &&
                      JSON.parse(schedule).map((r: any) => {
                        const catString = (r: any) =>
                          flatOptions(schedule_item_type).find(
                            (i: any) => i.id === r?.category
                          )?.name;

                        return (
                          <div className="info-item" key={r?.id}>
                            <div className="info-item-event">
                              <div className="info-item-times">
                                {r.time_from?.slice(0, 5)}-
                                {r?.time_to.slice(0, 5)}
                              </div>
                              <div className="info-item-location">
                                {r.venue_string?.toUpperCase()}
                              </div>
                            </div>

                            <div className="info-item-description">
                              <div className="info-item-category">
                                {catString(r)}
                              </div>
                              <div>{r?.description}</div>
                            </div>
                          </div>
                        );
                      });
                    return (
                      <div className="info-date-details">
                        {schedule && schedule != "[]" ? (
                          sch
                        ) : (
                          <div className="click-link">
                            {/* Kliknij dwukrotnie, żeby przejść do agendy */}
                          </div>
                        )}
                        {auth?.agenda ? (
                          <div className="info-date-hover">
                            Kliknij dwukrotnie, żeby przejść do agendy
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  }
                },
              },
              // time_from: {
              //   name: "Od",
              //   type: "time",
              //   step: 60,
              //   inputStyle: {
              //     fontSize: "15px",
              //   },
              // },
              // time_to: {
              //   name: "Do",
              //   type: "time",
              //   step: 60,
              //   inputStyle: {
              //     fontSize: "15px",
              //   },
              // },
              // time_sum: {
              //   name: "Czas",
              //   disabled: true,
              //   inputStyle: {
              //     fontSize: "11px",
              //   },
              // },
            },
          },
        },
      ],
      {
        blockStyle: {
          order: 1,
          gridColumn: "1/4",
          gridTemplateRows: "1fr",
        },
        content: [
          {
            name: "key_info",
            hide: !auth?.agenda,
            disabled: auth?.agenda != 2,
            label: "AGENDA - Ogólne",
            element: "multiAdd",
            multiAddProps: {
              // mini: true,
              columns: {
                category: {
                  name: "Kategoria",
                  element: "select",
                  style: { width: "150px" },
                  inputStyle: {
                    padding: "12px 8px",
                  },
                  optionsList: key_info_types,
                },
                content: {
                  name: "Treść",
                  element: "textarea",
                  inputStyle: {
                    resize: "vertical",
                  },
                },
              },
            },
            // element: "textarea",
            // style: {
            //   height:"150px"
            // },
            // placeholder: "Podaj kluczowe informacje dotyczące całej imprezy, np. specjalne wymogi klienta,  wymagania przetargu, niezbędny sprzęt, ogólne uwagi",
            // initialValue: data ? data.key_info : ""
          },
        ],
      },
    ],
  },
  // details: {
  //   name: "Szczegóły",
  //   content: [
  //     [

  //       {
  //         name: "details",
  //         initialValue: data ? data.details : "",
  //         label: "Dodatkowe informacje",
  //         element: "textarea",
  //       },
  //     ],
  //   ],
  // },

  agenda: {
    name: "Harmonogram",
    hide: !auth?.agenda,
    readOnly: auth?.agenda != 2,
    content: [
      [
        {
          name: "agenda-by-day",
          element: "subTabs",
          subTabs: {
            source: ["dates", "date"],
            transform: (value: any) => {
              const d = new Date(value);
              return (
                <>
                  <div style={{ textTransform: "uppercase", fontWeight: 700 }}>
                    {d.toLocaleDateString("pl-PL", {
                      weekday: "long",
                    })}
                  </div>
                  {"  "}
                  <div style={{ fontSize: "11px" }}>
                    {d.toLocaleDateString("pl-PL", {
                      day: "numeric",
                      month: "long",
                    })}
                  </div>
                </>
              );
            },
            fields: [
              [
                {
                  name: "stand_text",
                  diffTransform: (data: any) =>
                    diffPart(data, {
                      parent: "dates",
                      splitBy: "date",
                      subProp: "stand_text",
                    }),
                  label: "Oznakowanie standa/TV",
                },
                {
                  name: "guests",
                  description: "Liczba gości",
                  style: {
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                  },
                  format: "quarter-1",
                },
                {
                  name: "guest_number_adult",
                  diffTransform: (data: any) =>
                    diffPart(data, {
                      parent: "dates",
                      splitBy: "date",
                      subProp: "guest_number_adult",
                      number: true,
                    }),

                  // (data: any) => {
                  //   const full = data?.dates;
                  //   const dates = JSON.parse(data?.dates)
                  //     .flat()
                  //     .flat()
                  //     .flat();
                  //   const dated: any = {};
                  //   dates.forEach((s: any) => {
                  //     if (dated[s.date]) {
                  //       dated[s.date].push(s.guest_number_adult);
                  //     } else {
                  //       dated[s.date] = s.guest_number_adult;
                  //     }
                  //   });

                  //   return dated;
                  // },
                  format: "quarter-2",
                  initialValue: "",
                  inputType: "number",
                  label: "Dorośli",
                  required: "Podaj liczbę gości",
                  max: 599,
                },
                {
                  name: "guest_number_diets",
                  diffTransform: (data: any) =>
                    diffPart(data, {
                      parent: "dates",
                      splitBy: "date",
                      subProp: "guest_number_diets",
                      number: true,
                    }),
                  format: "half-2",
                  initialValue: 0,
                  inputType: "number",
                  label: "(w tym diety)",
                  required: "Podaj liczbę gości",
                  max: 599,
                },
                {
                  name: "guest_number_child_0_3",
                  visibleIf: (values: any) =>
                    values?.event_type != 9 && values?.event_type != 10,
                  diffTransform: (data: any) =>
                    diffPart(data, {
                      parent: "dates",
                      splitBy: "date",
                      subProp: "guest_number_child_0_3",
                      number: true,
                    }),
                  format: "quarter-3",
                  initialValue: 0, //doesn't work
                  inputType: "number",
                  label: "Dzieci 0-3",
                  required: "Podaj liczbę gości",
                  max: 599,
                },
                {
                  name: "guest_number_child_4_12",
                  visibleIf: (values: any) => {
                    // console.log(values);
                    return values?.event_type != 9 && values?.event_type != 10;
                  },
                  diffTransform: (data: any) =>
                    diffPart(data, {
                      parent: "dates",
                      splitBy: "date",
                      subProp: "guest_number_child_4_12",
                      number: true,
                    }),
                  format: "quarter-4",
                  initialValue: 0,
                  inputType: "number",
                  label: "Dzieci 4-12",
                  required: "Podaj liczbę gości",
                  max: 599,
                },
                {
                  name: "tv_hide",
                  label: "Ukrywanie w TV",
                  element: "select-picker",
                  onNull: "0",
                  optionsList: [
                    { id: "0", name: "widoczne" },
                    {
                      id: "1",
                      name: "ukryte",
                    },
                  ],
                },
                // {
                //   name: "guest_number_child",
                //   diffTransform: (data: any) =>
                //     diffPart(data, {
                //       parent: "dates",
                //       splitBy: "date",
                //       subProp: "guest_number_child",
                //       number: true,
                //     }),
                //   format: "quarter-4",
                //   initialValue: "",
                //   inputType: "number",
                //   label: "Dzieci 4-12",
                //   required: "Podaj liczbę gości",
                //   max: 599,
                // },
              ],
              [
                {
                  name: "service",
                  description: "Obsługa",
                  style: {
                    marginBottom: "12px",
                  },
                },
                {
                  name: "waiters",
                  diffTransform: (data: any) =>
                    diffPart(data, {
                      parent: "dates",
                      splitBy: "date",
                      subProp: "waiters",
                      number: true,
                    }),
                  initialValue: "",
                  label: (
                    <div>
                      Kelnerzy dod.
                      <br />
                      oprócz rest./śniad.
                      <br />
                    </div>
                  ),
                  format: "half-1",
                  inputType: "number",
                  style: {
                    width: "100%",
                  },
                  outerStyle: {
                    maxWidth: "100px",
                  },
                  required: "Podaj liczbę kelnerów",
                },
                {
                  name: "chefs",
                  diffTransform: (data: any) =>
                    diffPart(data, {
                      parent: "dates",
                      splitBy: "date",
                      subProp: "chefs",
                      number: true,
                    }),
                  initialValue: "",
                  format: "quarter-3",
                  label: (
                    <div>
                      Kucharze
                      <br />
                      oprócz proced.
                    </div>
                  ),
                  inputType: "number",
                  required: "Podaj liczbę kucharzy",
                },
                {
                  name: "dishwashers",
                  diffTransform: (data: any) =>
                    diffPart(data, {
                      parent: "dates",
                      splitBy: "date",
                      subProp: "dishwashers",
                      number: true,
                    }),
                  format: "quarter-4",
                  initialValue: "",
                  label: <div>Zmywak ogółem osób</div>,
                  inputType: "number",
                  required: "Podaj liczbę osób na zmywaku",
                },
                {
                  name: "ready_before",
                  // initialValue: 30,
                  outerStyle: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: "18px",
                  },
                  label: <div>Gotowość (minut przed)</div>,
                  step: 5,
                  max: 180,
                  inputType: "number",
                },
                {
                  name: "ready_before_time",
                  from: {
                    values: ["ready_before"],
                    transform: (
                      values: any,
                      formSchema: any,
                      customData: any,
                      name: string
                    ) => {
                      const thisDate = dateFromDatedName(name);
                      const readyBefore: any = getDatedFormValue(
                        values,
                        "ready_before",
                        thisDate
                      );
                      const thisSchedule = getDatedFormValue(
                        values,
                        "schedule",
                        thisDate
                      );
                      const firstItem: any = thisSchedule?.[0];
                      const d = new Date();
                      let timeStart = firstItem?.time_from;
                      if (timeStart) {
                        d.setHours(
                          timeStart?.slice(0, 2),
                          timeStart?.slice(4, 6),
                          0
                        );
                        d.setTime(d.getTime() - readyBefore * 60 * 1000);
                      }
                      return (
                        timeStart &&
                        readyBefore && (
                          <div
                            style={{
                              textAlign: "right",
                              fontSize: "90%",
                              padding: "1px 17px",
                              marginRight: "20px",
                              background: "rgb(220,220,220)",
                              borderRadius: "4px",
                            }}
                          >
                            {d.toLocaleTimeString("pl-pl", {
                              hour: "numeric",
                              minute: "numeric",
                            })}
                          </div>
                        )
                      );
                    },
                  },
                },
              ],
              [
                {
                  name: "day_comments",
                  diffTransform: (data: any) =>
                    diffPart(data, {
                      parent: "dates",
                      splitBy: "date",
                      subProp: "day_comments",
                      number: true,
                    }),
                  label: "Opis dnia",
                  element: "textarea",
                  style: {
                    height: "120px",
                  },
                  placeholder: "opcjonalne uwagi dla danego dnia",
                },
              ],
              {
                blockStyle: {
                  order: 1,
                  gridColumn: "1/4",
                  gridTemplateRows: "1fr",
                },
                content: [
                  {
                    name: "schedule",
                    diffTransform: (data: any) => {
                      const full = data?.schedules;
                      const schedules = objectOrJSONparse(data?.schedules)
                        ?.flat()
                        ?.flat()
                        ?.flat();
                      const datedSchedules: any = {};
                      schedules?.forEach((s: any) => {
                        if (s?.date) {
                          if (datedSchedules[s.date]) {
                            datedSchedules[s.date]?.push(s);
                          } else {
                            datedSchedules[s.date] = [s];
                          }
                        } else {
                          if (datedSchedules?.nowy) {
                            datedSchedules?.[s.date]?.push(s);
                          } else {
                            datedSchedules.nowy = [s];
                          }
                        }
                      });

                      return datedSchedules;
                    },
                    label: "Plan dnia",
                    element: "multiAdd",
                    className: "schedule-field",
                    validate: (v: any) => {
                      let message = "";
                      const hasContent = v && v?.length > 0;
                      const firstStartTime = hasContent && v.at(0)?.time_from;
                      const lastEndTime = hasContent && v.at(-1)?.time_to;
                      // console.log(v, firstStartTime, lastEndTime);
                      const valid =
                        hasContent && firstStartTime && lastEndTime
                          ? true
                          : false;
                      if (!lastEndTime) message = "Brak godziny zakończenia.";
                      if (!firstStartTime)
                        message = "Brak godziny rozpoczęcia.";

                      if (!hasContent) {
                        message = "Brak wpisów";
                      }
                      return (
                        valid ||
                        "Uzupełnij harmonogram" +
                          (message ? ": " : "") +
                          message
                      );
                    },
                    multiAddProps: {
                      fromProp: ["schedules", "date"],
                      help: (
                        <div>
                          Dodaj godziny imprezy, sale, posiłki/napoje itp.
                        </div>
                      ),
                      add: [
                        (entries: any) => {
                          //const specificDate = entries?.at(-1) && new Date(entries.at(-1).date);
                          const last = entries?.at(-1) || "";
                          return {
                            // date: date ? new Date(date).toISOString().slice(0, 10) : undefined,
                            guid: createGUID(),
                            time_from: last?.time_to,
                            time_to: "",
                            venue: last?.venue,
                            description: "",
                            category: 0,
                          };
                        },
                        "DODAJ GODZINY",
                      ],

                      foldable: false,
                      subData: [
                        {
                          name: "snacks",
                          label: "Przystawki zimne",
                          initialValue: "",
                          element: "multiAdd",
                          multiAddProps: {
                            fromProp: [
                              "snacks",
                              ["schedule_id", "schedule_guid"],
                            ],
                            multipleInstances: true,
                            nameSub: ["id", "guid"],
                            foldable: false,
                            reorderable: true,
                            compact: true,
                            maxLength: 15,
                            templates: {
                              standard: [
                                {
                                  name: "Mięsa pieczone",
                                },
                                {
                                  name: "Pasztet wieprzowo-drobiowy z suszonymi pomidorami",
                                },
                                {
                                  name: "Śledź w dwóch odsłonach",
                                },
                                {
                                  name: "Galaretka wieprzowa",
                                },
                                { name: "Mix sałat z szynką dojrzewająca" },
                              ],
                              okolicznościowy: [
                                {
                                  name: "Klasyczny tatar wołowy z marynowanymi dodatkami",
                                },
                                {
                                  name: "Wolno pieczony rostbef podany z plastrami gruszki, prażonymi orzechami nerkowca, rukolą i dresingiem cytrusowym",
                                },
                                {
                                  name: "Tatar z łososia z zielonym ogórkiem, marynowana dynią z chilii, olejem sezamowym podany z hummusem curry",
                                },
                                {
                                  name: "Tartaletka z wędzonym twarogiem, szalotkami i porem podana z kompozycją sałat",
                                },
                              ],
                            },
                            columns: {
                              search: lsi_restaurant,
                              name: {
                                name: "Nazwa",
                                // element: "input",
                                // type: "text",
                                element: "textarea",
                                style: {
                                  width: "100%",
                                },
                                // inputStyle: {
                                //   textAlign: "left",
                                //   // fontWeight:"normal",
                                //   padding: "2px 5px",
                                // },
                              },
                            },
                          },
                          //   visibleIfParent: (values: any) =>
                          //     arrayToNumbersAndStrings([
                          //       1, 2, 3, 4, 5, 6, 7, 8, 9,
                          //     ]).includes(values?.category),
                        },
                        {
                          name: "drinks",
                          label: "Napoje",
                          initialValue: "",
                          element: "multiAdd",
                          multiAddProps: {
                            fromProp: [
                              "drinks",
                              ["schedule_id", "schedule_guid"],
                            ],
                            multipleInstances: true,
                            nameSub: ["id", "guid"],
                            foldable: false,
                            reorderable: true,

                            // compact: true,
                            maxLength: 15,
                            templates: {
                              "bez ograniczeń": [
                                {
                                  name: "napoje zimne oraz gorące bez ograniczeń (m.in. herbata kopertowana Sir Williams, kawa ziarnista z ekspresu ciśnieniowego, napoje gazowane /niegazowane, soki/woda w karafkach",
                                },
                              ],
                            },
                            columns: {
                              // category: {
                              //   name: "Kategoria",
                              //   element: "select",
                              //   optionsList: [
                              //     {
                              //       id: 1,
                              //       name: "zimne",
                              //     },
                              //     {
                              //       id: 2,
                              //       name: "gorące",
                              //     },
                              //   ],
                              //   style: {
                              //     width: "160px",
                              //   },
                              //   inputStyle: {
                              //     color: "black", //"#0a6b89",
                              //     width: "160px",
                              //     textAlign: "left",
                              //     fontSize: "12px",
                              //     textTransform: "uppercase",
                              //   },

                              //   className: "clear-item",
                              // },
                              name: {
                                name: "Nazwa",
                                // element: "input",
                                // type: "text",
                                element: "textarea",
                                style: {
                                  width: "100%",
                                },
                                // inputStyle: {
                                //   textAlign: "left",
                                //   // fontWeight:"normal",
                                //   padding: "2px 5px",
                                // },
                              },
                            },
                          },
                          //   visibleIfParent: (values: any) =>
                          //     arrayToNumbersAndStrings([
                          //       1, 2, 3, 4, 5, 6, 7, 8, 9,
                          //     ]).includes(values?.category),
                        },
                        {
                          name: "meals",
                          initialValue: "",
                          diffTransform: (data: any) => {
                            const schedules = objectOrJSONparse(
                              data?.schedules
                            );

                            const meals = schedules
                              ?.flat()
                              ?.flat()
                              ?.map(
                                (item: any) =>
                                  item?.meals && objectOrJSONparse(item?.meals)
                              )
                              .flat();

                            // console.log(data);
                            //const meals = JSON.parse(mealsJSON).flat();

                            const subbed: any = {};
                            meals?.forEach((s: any) => {
                              if (s?.schedule_guid) {
                                if (subbed[s.schedule_guid]) {
                                  subbed[s.schedule_guid]?.push(s);
                                } else {
                                  subbed[s.schedule_guid] = [s];
                                }
                              } else {
                                if (subbed?.nowy) {
                                  if (s) {
                                    subbed.nowy?.push(s);
                                  }
                                } else {
                                  if (s) {
                                    subbed.nowy = [s];
                                  }
                                }
                              }
                            });
                            return subbed;
                          },
                          label: "Posiłki",
                          labelOptions: schedule_item_type,
                          element: "multiAdd",
                          multiAddProps: {
                            multipleInstances: true,
                            fromProp: [
                              "meals",
                              ["schedule_id", "schedule_guid"],
                            ],
                            nameSub: ["id", "guid"],
                            affects: (values: any) => ({
                              name:
                                values?.category == 101 && values?.name == ""
                                  ? flatOptions(meal_type)?.find(
                                      (o: any) => o.id == values?.category
                                    )?.content
                                  : values.name,
                            }),
                            // %date%@id/guid@
                            foldable: false,
                            reorderable: true,
                            order: true,
                            // sort: {
                            //     cell: "time",
                            //     callBack: (a:any, b:any) => {
                            //       return Number(String(a)?.replace(":","")) - Number(String(b)?.replace(":",""));
                            //     }
                            // },
                            templates: {
                              // zupa i drugie danie, deser, 6 przystawek zimnych, pikle, sosy,
                              // napoje zimne oraz gorące bez ograniczeń (m.in. herbata kopertowana Sir Williams,
                              // kawa ziarnista z ekspresu cisnieniowego, napoje gazowane /niegazowane, soki)
                              "puste bez dzieci": [
                                // {
                                //   category: 21,
                                //   name: "",
                                // },
                                {
                                  category: 2,
                                  name: "",
                                },
                                {
                                  category: 3,
                                  name: "",
                                },
                                {
                                  category: 4,
                                  name: "",
                                },
                              ],
                              "puste z dziećmi": [
                                // {
                                //   category: 21,
                                //   name: "",
                                // },
                                {
                                  category: 2,
                                  name: "",
                                },
                                {
                                  category: 2,
                                  name: "",
                                  special: 1,
                                },
                                {
                                  category: 3,
                                  name: "",
                                },
                                {
                                  category: 3,
                                  name: "",
                                  special: 1,
                                },
                                {
                                  category: 4,
                                  name: "",
                                },

                                {
                                  category: 4,
                                  name: "",
                                  special: 1,
                                },
                              ],
                            },
                            columns: {
                              category: {
                                name: "Kategoria",
                                element: "select",
                                optionsList: meal_type,
                                style: {
                                  width: "120px",
                                },
                                inputStyle: {
                                  color: "black", //"#0a6b89",
                                  textAlign: "left",
                                  fontSize: "12px",
                                  textTransform: "uppercase",
                                },

                                className: "clear-item",
                              },
                              parent_category: {
                                name: "Kategoria wyżej",
                                hidden: true,
                              },
                              search: lsi_restaurant,
                              name: {
                                name: "Nazwa",
                                style: {
                                  verticalAlign: "center",
                                },
                                element: "textarea",
                                className: "clear-item",
                                inputClass: "textarea-multi",
                                // inputStyle: {
                                //   textAlign: "left",
                                //   //fontFamily: "sans-serif",
                                //   fontSize: "14px",
                                //   letterSpacing: "-.5px",
                                //   padding:"5px",
                                //   height:"35px",
                                //   maxHeight:"max-content",
                                //   lineHeight:"110%",
                                //   margin:0
                                // },
                              },
                              special: {
                                name: "Specjalne",
                                style: {
                                  width: "100px",
                                },
                                element: "select",
                                inputStyle: {
                                  fontSize: "12.5px",
                                  letterSpacing: "-.5px",
                                  padding: "4px 0",
                                },
                                optionsList: special_options,
                                blankName: "-",
                                // type: "time",
                                // step: 60,
                              },
                              time: {
                                name: "Godzina",
                                style: {
                                  width: "80px",
                                },
                                type: "time",
                                step: 60,
                              },
                              quantity: {
                                name: "Ilość",
                                style: {
                                  width: "45px",
                                },
                                placeholder: "*",
                                type: "number",
                              },
                              order: {
                                name: "Kolejność",
                                hidden: true,
                                type: "number",
                              },
                            },
                          },
                        },
                      ],
                      columns: {
                        number: {
                          name: "Lp",
                          index: true,
                          className: "prominent-number",
                        },
                        time_from: {
                          name: "Od",
                          type: "time",
                          step: 60,
                          style: {
                            width: "75px",
                          },

                          inputStyle: {
                            fontSize: "17px",
                            background: "white",
                            margin: "2px",
                            padding: "1px 3px",
                            width: "100%",
                          },
                        },
                        time_to: {
                          name: "Do",
                          type: "time",
                          step: 60,
                          style: {
                            width: "75px",
                          },

                          inputStyle: {
                            fontSize: "17px",
                            background: "white",
                            margin: "2px",
                            padding: "1px 3px",
                            width: "100%",
                          },
                        },
                        next_day: {
                          name: ">>>",
                          type: "checkbox",
                          // disabled: true,
                          title: "następnego dnia",
                          onEdit: (row: any) => {
                            // console.log(
                            //   row.time_to,
                            //   timeStringToNumber(row.time_to)
                            // );
                            return compareTimeStrings(
                              row.time_to,
                              row.time_from
                            ) < 0 ||
                              (timeStringToNumber(row.time_from) < 501 &&
                                timeStringToNumber(row.time_to) < 801)
                              ? true
                              : false;
                          },
                        },
                        venue: {
                          name: "Sala",
                          element: "select",
                          selectNameAs: "venue_string",
                          optionsList: "venues",
                          inputStyle: {
                            textTransform: "uppercase",
                          },
                        },
                        // category: {
                        //   name: "Dania i napoje",
                        //   element: "select",
                        //   optionsList: [{ id: 1, name: "TAK" }], // schedule_item_type,
                        //   blankName: "NIE",
                        //   inputStyle: {
                        //     textAlign: "left",
                        //   },
                        // },
                        music: {
                          name: "Muzyka",
                          element: "select",
                          optionsList: music_type,
                          blankName: "",
                          style: {
                            width: "145px",
                          },
                          inputStyle: {
                            textAlign: "left",
                          },
                        },
                        description: {
                          name: "Opis",
                          inputStyle: {
                            resize: "vertical",
                          },
                          placeholder: "brak opisu",
                          element: "textarea",
                          className: "description-area",
                          fullRow: true,
                        },
                        // time_sum: {
                        //   name: "Czas",
                        //   disabled: true,
                        //   inputStyle: {
                        //     fontSize: "11px",
                        //   },
                        // },
                      },
                    },
                  },
                ],
              },
              /*{
                blockStyle: {
                  gridColumn: "1/4",
                },
                content: [
                  
                ],
              },*/
            ],
          },
        },

        // {
        //   name: "agenda",
        //   label: "",
        //   element: "multiAddEditor",
        // }
      ],
    ],
  },

  conferenceSpecific: {
    name: "Impreza okolicznościowa",
    visibleIf: (d: any) => d.event_type == 15,
    content: [],
  },
  weddingSpecific: weddingForm(data),
  settlement: {
    name: "Rozliczenie",
    hide: !auth?.settlements,
    readOnly: auth?.settlements != 2,
    content: [
      [
        {
          description: "",
          name: "calculate",
          //format: "half-1",
          outerStyle: {
            color: "black",
            background: "#c7e1ec",
            padding: "5px 12px",
            borderRadius: "7px",
          },
          from: {
            values: ["hotel_rooms", "item_amounts", "payments"],
            transform: (values: any) => {
              const rooms = values?.hotel_rooms
                ? JSON.parse(values.hotel_rooms)
                : [];
              const items = values?.item_amounts
                ? JSON.parse(values.item_amounts)
                : [];
              const payments = values?.payments
                ? JSON.parse(values.payments)
                : [];

              return (
                <SumSettlement
                  rooms={rooms}
                  items={items}
                  payments={payments}
                  data={values}
                  initialData={data}
                />
              );
            },
          },
        },
        {
          name: "payments",
          diffTransform: (data: any) => {
            const full = data?.payments;
            return JSON.stringify(JSON.parse(data?.payments).flat().flat());
          },
          element: "multiAdd",
          multiAddProps: {
            columnsSumValue: (entries: any) => sumCellValues(entries, "gross"),
            columnsSum: (columns: any, entries: any) => {
              const gross =
                entries &&
                Math.round(
                  entries
                    ?.map((e: any) => Number(e.gross))
                    ?.reduce((a: any, b: any) => a + b, 0) * 100
                ) / 100;
              const net =
                entries &&
                Math.round(
                  entries
                    ?.map((e: any) => Number(e.net))
                    ?.reduce((a: any, b: any) => a + b, 0) * 100
                ) / 100;
              return (
                <tr>
                  {[...Array(7).keys()].map((cell: any) => (
                    <td></td>
                  ))}
                  {net && columns.net ? (
                    <td className="multi-add-sum">{net}</td>
                  ) : (
                    <></>
                  )}

                  <td></td>
                  {gross && columns.gross ? (
                    <td className="multi-add-sum">{gross}</td>
                  ) : (
                    <></>
                  )}
                </tr>
              );
            },
            columns: {
              id: {
                name: "Lp.",
                index: true,
              },
              documentType: {
                name: "Dokument",
                element: "select",
                clearOnChange: true,
                inputStyle: {
                  fontSize: "11px",
                  padding: "3px 0",
                },
                confinedList: true,
                // defaultOption: "1",
                optionsList: [
                  {
                    id: 1,
                    name: "FV z LSI",
                  },
                  {
                    id: 2,
                    name: "PAR z LSI",
                  },
                  {
                    id: 3,
                    name: "saldo",
                    active: userID && userID == 16 ? true : false,
                  },
                ],
              },
              document: (values: any) => hybridDocumentField(values),
              documentDetails: (v: any) => ({
                name: "",
                element: "dataSelector",
                disableIf: v.documentType != 1 && v.documentType != 2,
                windowHeading:
                  v.documentType == 1
                    ? "Faktura"
                    : v.documentType == 2
                    ? "Paragon"
                    : "Podgląd",
                dataSelectorElement: (data: any, setRow: Function) =>
                  v.documentType == 1 ? (
                    <InvoiceSelector scid={data} setRow={setRow} />
                  ) : v.documentType == 2 ? (
                    <ReceiptDetails id={data} />
                  ) : (
                    <></>
                  ),
              }),

              docNo: {
                name: "Numer",
                style: { width: "120px" },
                disableIf: (values: any) =>
                  values.documentType == 1 || values.documentType == 2,
              },
              date: {
                name: "Data",
                type: "date",
                style: { width: "90px" },
                disableIf: (values: any) =>
                  values.documentType == 1 || values.documentType == 2,
              },
              net: {
                name: "Kwota netto",
                type: "number",
                disableIf: (values: any) =>
                  values.documentType == 1 || values.documentType == 2,
              },
              vat: {
                name: "VAT%",
                //type:"number",
                disableIf: (values: any) =>
                  values.documentType == 1 || values.documentType == 2,
              },
              gross: {
                name: "Kwota brutto",
                type: "number",
                disableIf: (values: any) =>
                  values.documentType == 1 || values.documentType == 2,
                //style: { width: "88px" },
              },
              itemID: {
                name: "Pozycja",
                hidden: true,
              },
            },
            affects: (values: any) => ({
              gross:
                Math.round(values.net * (1 + values.vat / 100) * 100) / 100,
              net:
                Math.round((values.gross / (1 + values.vat / 100)) * 100) / 100,
            }),
          },
          initialValue: "",
          label: "Wpłaty",
        },
        {
          description: "Wykaz kosztów",
          name: "costList",
          format: "quarter-1",
          style: {
            marginTop: "10px",
            color: "black",
          },
        },
        {
          name: "hotel_rooms",
          diffTransform: (data: any) => {
            const full = data?.hotel_rooms;
            return JSON.stringify(JSON.parse(data?.hotel_rooms).flat().flat());
          },
          label: "Usługa hotelowa",
          element: "multiAdd",
          /* useData: {
            db:"",
            query: {

            }
          }*/
          multiAddProps: {
            add: true,
            maxLength: 10,
            // multiEntries: [
            //   { roomType: "single", amount: 0, vat: 8 },
            //   { roomType: "double", amount: 0, vat: 8 },
            //   { roomType: "apartament", amount: 0, vat: 8 },
            // ],
            affects: (values: any) => ({
              grossUnit:
                Math.round(values.netUnit * (1 + values.vat / 100) * 100) / 100,
              netUnit: [
                "grossUnit", // change only when this value changes
                Math.round((values.grossUnit / (1 + values.vat / 100)) * 100) /
                  100,
              ],
              grossSum:
                Math.round(values.grossUnit * values.amount * 100) / 100,
            }),
            columnsSumValue: (entries: any) => {
              return entries
                ? Math.round(
                    entries
                      ?.map((e: any) => (e.grossSum ? Number(e.grossSum) : 0))
                      ?.reduce((a: any, b: any) => a + b, 0) * 100
                  ) / 100
                : 0;
            },
            columnsSum: (columns: any, entries: any) => {
              const gross =
                entries &&
                Math.round(
                  entries
                    ?.map((e: any) => Number(e.grossSum))
                    ?.reduce((a: any, b: any) => a + b, 0) * 100
                ) / 100;
              return (
                <tr>
                  {[...Array(7).keys()].map((cell: any) => (
                    <td></td>
                  ))}
                  {gross && columns.grossSum ? (
                    <td className="multi-add-sum">{gross}</td>
                  ) : (
                    <></>
                  )}
                </tr>
              );
            },
            columns: {
              id: {
                name: "Lp.",
                index: true,
              },
              roomType: {
                name: "Pokój",
                element: "select",
                optionsList: [
                  { id: "single", name: "single" },
                  { id: "double", name: "double" },
                  { id: "triple", name: "triple" },
                  { id: "quadruple", name: "quadruple (4)" },
                  { id: "apartament", name: "apartament" },
                ],
                disabled: false,
              },
              amount: {
                name: "Liczba",
                type: "number",
              },
              netUnit: {
                name: "Cena jedn. netto",
                type: "number",
              },
              vat: {
                name: "VAT%",
                type: "number",
              },
              grossUnit: {
                name: "Cena jedn. brutto",
                type: "number",
              },
              grossSum: {
                name: "Kwota brutto",
                type: "number",
                disabled: true,
              },
            },
          },
        },
        {
          name: "item_amounts",
          label: "Pozycje kosztów",
          element: "multiAdd",
          multiAddProps: {
            add: true,
            // unique: ["item"],
            multiEntries: [
              { item: 1, vat: 23 },
              { item: 2, vat: 23 },
              /* { item: 3, vat: 23 },
              { item: 4, vat: 23 },
              { item: 5, vat: 23 },
              { item: 6, vat: 23 },
              { item: 7, vat: 23 },
              { item: 8, vat: 23 },
              { item: 9, vat: 23 },
              { item: 10, vat: 23 },
              { item: 11, vat: 23 },
              { item: 12, vat: 23 },*/
              //{ item: 0, vat: 23 },
            ],
            affects: (values: any) => ({
              unitPrice: [
                "unitPriceGross", // change only when this value changes
                Math.round(
                  (values.unitPriceGross / (1 + Number(values.vat) / 100)) * 100
                ) / 100,
              ],
              unitPriceGross: [
                ["unitPrice", "vat"],
                Math.round(
                  values.unitPrice * (1 + Number(values.vat) / 100) * 100
                ) / 100,
              ],
              //values.amount
              totalGross:
                Math.round(
                  values.unitPriceGross * Number(values.amount) * 100
                ) / 100,
            }),
            affectsParent: (values: any) => ({
              /* guest_number_adult: values.item == 1,
              guest_number_child: values.item == 2,*/
              /*waiters: values.item == 3,
              chefs: values.item == 4,
              dishwashers: values.item == 5,*/
            }),
            columnsSumValue: (entries: any) =>
              entries
                .map((e: any) => Number(e.totalGross) || 0)
                .reduce((a: number, b: number) => a + b, 0),
            columnsSum: (columns: any, entries: any) => {
              const sum = entries
                .map((e: any) => Number(e.totalGross) || 0)
                .reduce((a: number, b: number) => a + b, 0);
              return (
                <>
                  {[...Array(6).keys()].map((cell: any) => (
                    <td></td>
                  ))}
                  <td>
                    <div className="multi-add-sum">
                      {prettyNumber(Math.round(sum * 100) / 100)}
                    </div>
                  </td>
                </>
              );
            },
            columns: {
              item: {
                name: "Pozycja",
                element: "select",
                style: { textAlign: "left" },
                optionsList: miscellanea,
              },
              amount: {
                name: "Liczba",
                type: "number",
                max: (values: any) =>
                  values.item === 1
                    ? 499
                    : values.item === 2
                    ? 499
                    : values.item === 3
                    ? 19
                    : undefined,
                suggestions: (values: any, rowValues: any) =>
                  rowValues.item === 1
                    ? pickDatedFormValues(values, "guest_number_adult")
                    : rowValues.item === 2
                    ? pickDatedFormValues(values, "guest_number_child")
                    : false,

                linkParent: [
                  /* {
                    parentField: "guest_number_adult",
                    condition: (values: any) => values.item == 1,
                  },
                  {
                    parentField: "guest_number_child",
                    condition: (values: any) => values.item == 2,
                  },*/
                  /*
                  {
                    parentField: "waiters",
                    condition: (values: any) => values.item == 3,
                  },
                  {
                    parentField: "chefs",
                    condition: (values: any) => values.item == 4,
                  },
                  {
                    parentField: "dishwashers",
                    condition: (values: any) => values.item == 5,
                  },*/
                ],
              },
              unitPrice: {
                name: "Cena j. netto",
                type: "number",
              },
              vat: {
                name: "VAT%",
                type: "number",
              },
              unitPriceGross: {
                name: "Cena j. brutto",
                type: "number",
              },
              totalGross: {
                name: "Kwota brutto",
                type: "number",
                disabled: true,
              },
            },
          },
        },

        /*
        {
          name: "lsi_receipt_id",
          visibleIf: { document_type: "1" },
          initialValue: "",
          label: "Nr rachunku LSI",
          element: "hybrid",
          db: "lsi_receipt_query_by_number",
          query: (input: string | number) => ({
            multiQuery: input,
          }),
          resultElement: (item: any) => (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "100px 1fr 100px",
                fontSize: "11px",
              }}
            >
              <div>{item.docNo}</div>
              <div>{item.ko_firma}</div>
              <div>{Math.round(item._KwRozliczenia * 100) / 100} zł</div>
            </div>
          ),
          resultUpdate: (item: any) => [
            ["lsi_receipt_id", item.docNo],
            ["lsi_invoice_id", ""],
          ],
          inputType: "search",
        },

        {
          name: "lsi_invoice_id",
          visibleIf: { document_type: "0" },
          initialValue: "",
          label: "Nr faktury LSI",
          //visibleIf: { is_company: 1 },
          element: "hybrid",
          db: "lsi_invoice_query",
          groupBy: (v: any) => v?.SCID,
          query: (input: string | number) => ({
            multiQuery: input,
          }),
          resultElement: (item: any) => (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "100px 1fr 100px",
              }}
            >
              <div>
                {item.DocNo} / {item.month} / {item.year}
              </div>
              <div>{item.pl_firma}</div>
              <div>{Math.round(item.ValueBWal * 100) / 100} zł</div>
            </div>
          ),
          resultUpdate: (item: any) => [
            ["lsi_invoice_id", item.DocNo],
            ["lsi_receipt_id", ""],
          ],
          inputType: "search",
        },

        // for lsi invoice
        {
          name: "",
          jsxWithData: (data: any, customData: any, context: any) => {
            const details = customData?.lsi_invoice_id?.dataObject;
            return data.lsi_invoice_id && details ? (
              <div
                style={{
                  display: "flex",
                  fontSize: "12px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>{details.ko_firma}</div>
                <div>
                  {details.DocNo}/{details.month}/{details.year}
                </div>

                <button
                  className="normal-button"
                  onClick={(e) => {
                    e.preventDefault();
                    context.setModal2({
                      show: true,
                      heading: <>Faktura</>,
                      content: <GenericDataView data={details} />,
                    });
                  }}
                >
                  Dane dokumentu
                </button>
              </div>
            ) : (
              <></>
            );
          },
        },

        // for lsi receipt
        {
          name: "",
          jsxWithData: (data: any, customData: any, context: any) => {
            const details = customData?.lsi_receipt_id?.dataObject;
            return data.lsi_receipt_id && details ? (
              <div
                style={{
                  display: "flex",
                  fontSize: "12px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>{details.ko_firma}</div>
                <div>{details.docNo}</div>

                <button
                  className="normal-button"
                  onClick={(e) => {
                    e.preventDefault();
                    context.setModal2({
                      show: true,
                      heading: <>Faktura</>,
                      content: <GenericDataView data={details} />,
                    });
                  }}
                >
                  Dane dokumentu
                </button>
              </div>
            ) : (
              <></>
            );
          },
        },
        /*       {
          name: "lsi_bill_id",
        },*/
      ],
      /*[
        {
          name: "advance_amount",
          initialValue: data ? data.advance_amount : "",
          label: "Wpłacona zaliczka",
        },
       
      ],*/
    ],
  },

  details2: {
    name: "Uwagi",
    hide: !auth?.comments,
    readOnly: Number(auth?.comments) < 2,
    content: [
      [
        // {
        //   name: "details",
        //   initialValue: data ? data.details : "",
        //   label: "Uwagi ogólne",
        //   element: "textarea",
        // },
        {
          name: "comments",
          element: "multiAdd",
          label: "Uwagi",
          multiAddProps: {
            add: true,
            /*    affects: (values: any) => ({
            date: new Date().toLocaleDateString(),
          }),*/
            columns: {
              id: {
                name: "Lp.",
                index: true,
              },
              content: {
                name: "Treść",
                element: "textarea",
                inputStyle: {
                  textAlign: "left",
                  fontSize: "13px",
                },
              },
              date: {
                name: "",
                type: "text",
                onEdit: (values: any) => new Date().toLocaleDateString(),
                disabled: true,
                style: {
                  width: "70px",
                },
                inputStyle: {
                  fontSize: "11px",
                },
              },
              user: {
                name: "Użytkownik",
                fillUser: (userContext: any) => userContext.name,
                disabled: true,
                style: {
                  width: "85px",
                },
                inputStyle: {
                  fontSize: "11px",
                },
              },
            },
          },
        },
      ],
    ],
  },
});
