import useGeneric from "../dataFetch/useGeneric";
import minutesToJSX from "../functions/minutesToJSX";

export default function Employees({
  employeeViewMode,
  deviceUpdates,
  cardsFull,
  lastCheckInRows,
  cardsAndEmployees,
  employeeGroups,
  selectedPerson,
  setSelectedPerson,
  setSelectedEmployeeData,
  dataByPerson,
  location,
  holidayCount,
}: any) {
  //console.log(dataByPerson[selectedPerson]);
  employeeGroups = employeeGroups && [
    ...employeeGroups,
    {
      name: location === "hotel" ? "Z biura" : "W hotelu",
      location: "hotel",
      active: 1,
      id: "biuro",
      outer: true,
    },
  ];
  const cardsCombined = cardsFull.map((c: any) => {
    if (
      c.location === "biuro" &&
      lastCheckInRows
        .filter((l: any) => l.cardperson === c.cardname)?.[0]
        ?.sources?.split(",")
        .at(-1) === "attendance"
    ) {
      // console.log(
      //   lastCheckInRows.filter((l: any) => l.cardperson === c.cardname)?.[0]
      // );
      return {
        ...c,
        group: "biuro",
      };
    } else {
      return c;
    }
  });

  //console.log(holidayCount);
  return (
    <div
      className={"peopleList" + (deviceUpdates === 0 ? " notUpdated" : "")}
      style={!selectedPerson ? { gridGap: "20px" } : {}}
    >
      {!cardsAndEmployees.loading && cardsFull && employeeGroups ? (
        employeeGroups?.map((group: any) => (
          <div className={"employeeGroup" + (group.outer ? " outer" : "")}>
            <h1>{group.name.toUpperCase()}</h1>
            <div className="cardPeople">
              {cardsCombined
                .filter((c: any) => c.group === group.id)
                .map((j: any, ind: number) => {
                  lastCheckInRows.sort(
                    (a: any, b: any) => b.timestamp - a.timestamp
                  );
                  const thisPerson = lastCheckInRows.filter(
                    (l: any) => l.cardperson === j.cardname
                  )[0];

                  const daysOff = j.holidays
                    ? JSON.parse("[" + j.holidays.replace("\\") + "]")
                    : [];
                  //  console.log(daysOff);
                  const noPayDays = daysOff.filter((h: any) =>
                    [4].includes(h.type)
                  );

                  // inne urlopy
                  const otherHolidays = daysOff.filter((h: any) =>
                    [10].includes(h.type)
                  );

                  const childCareDays = daysOff.filter((h: any) =>
                    [5].includes(h.type)
                  );

                  const daysOffForSaturday = daysOff.filter((h: any) =>
                    [6].includes(h.type)
                  );

                  //urlopy
                  const holidays = daysOff.filter((h: any) =>
                    // [1, 2, 3, 5, 6].includes(h.type)
                    [1, 2].includes(h.type)
                  );

                  const holidaysCountHours = daysOff.filter((h: any) =>
                    // [1, 2, 3, 5, 6].includes(h.type)
                    [1, 2, 3, 5, 9].includes(h.type)
                  );

                  //zwolnienia
                  const sickLeaves = daysOff.filter((h: any) => h.type === 7);

                  const holidayToday = daysOff.filter(
                    (h: any) => h.date === new Date().toISOString().slice(0, 10)
                  );
                  const isHolidayToday = holidayToday.length;
                  // console.log(isHolidayToday);

                  const isAtWork =
                    thisPerson?.typeshorts.charAt(
                      thisPerson.typeshorts.length - 1
                    ) === "0" ||
                    thisPerson?.typeshorts.charAt(
                      thisPerson.typeshorts.length - 1
                    ) === "2" ||
                    thisPerson?.typeshorts.charAt(
                      thisPerson.typeshorts.length - 1
                    ) === "4";

                  const isWorkingFromHome =
                    thisPerson?.typeshorts.charAt(
                      thisPerson.typeshorts.length - 1
                    ) === "2";

                  const isOther =
                    thisPerson?.typeshorts.charAt(
                      thisPerson.typeshorts.length - 1
                    ) === "4";

                  /*lastCheckInRows 
                    .filter(
                      (f: any) =>
                        f.typeshorts.charAt(f.typeshorts.length - 1) === "0"
                    )
                    .find((l: any) => l.cardperson === j.cardname);*/
                  const max = thisPerson && thisPerson.max;
                  const isMaxToday =
                    thisPerson &&
                    new Date(thisPerson.max).getDate() === new Date().getDate();
                  const isYesterday =
                    new Date(thisPerson?.max).getDate() ===
                    new Date().getDate() - 1;
                  const isYesterdayOffice =
                    isYesterday && j.location === "biuro";

                  const initialHolidayPool = holidayCount
                    ? holidayCount.filter(
                        (h: any) => h.id === j.id && h.holiday_relevant === 1
                      )?.[0]?.initial_holiday_pool
                    : undefined;

                  const personalHolidayData =
                    holidayCount !== undefined &&
                    holidayCount.filter(
                      (h: any) =>
                        h.id === j.id &&
                        h.type === 1 &&
                        h.holiday_relevant === 1
                    )?.[0];

                  const anyEmployeeHolidayData =
                    holidayCount !== undefined &&
                    holidayCount.filter(
                      (h: any) => h.id === j.id && h.holiday_relevant === 1
                    )?.[0];

                  const holidayLeft =
                    anyEmployeeHolidayData !== undefined
                      ? anyEmployeeHolidayData.initial_holiday_pool +
                        anyEmployeeHolidayData.added_holiday_pools -
                        (personalHolidayData
                          ? personalHolidayData.holiday_used
                          : 0)
                      : initialHolidayPool;

                  //base hours to calculate holiday hours (for total time)
                  const holidayCountPerson: any =
                    holidayCount &&
                    holidayCount.find((i: any) => i.cardname === j.cardname);

                  const baseHours =
                    holidayCountPerson && holidayCountPerson.base_hours_per_day
                      ? Number(holidayCountPerson.base_hours_per_day)
                      : 8;

                  return (
                    <div
                      className={
                        "selectPerson" +
                        (selectedPerson === j.cardname ? " active" : "") +
                        (isAtWork ? " atWork" : "") +
                        (isWorkingFromHome ? " fromHome" : "") +
                        (isYesterdayOffice ? " tooLong" : "") +
                        (isOther ? " otherEmployment" : "") +
                        (isHolidayToday ? " holiday" : "") +
                        (thisPerson && !isAtWork && isMaxToday
                          ? " lastWorked"
                          : "")
                      }
                      style={{
                        /*    order: isYesterdayOffice
                          ? "1"
                          : isAtWork
                          ? "0"
                          : thisPerson && !isAtWork && isMaxToday
                          ? "2"
                          : "3",*/
                        animationDelay: 0.3 + ind / 30 + "s",
                      }}
                      onClick={() => {
                        setSelectedPerson(j.cardname);
                        setSelectedEmployeeData(j);
                      }}
                    >
                      <div>
                        {j.name.length > 20
                          ? j.first_name[0] + ". " + j.last_name
                          : j.name}
                      </div>

                      {employeeViewMode === "present" ? (
                        <>
                          <div className="worksSince">
                            {isAtWork ? (
                              <>
                                {isYesterday ? (
                                  <span>od wczoraj </span>
                                ) : (
                                  <>od </>
                                )}
                                {new Date(thisPerson?.max).toLocaleTimeString(
                                  "pl-PL",
                                  {
                                    hour: "numeric",
                                    minute: "numeric",
                                  }
                                )}{" "}
                                {j.source === "attendance" ? "hotel" : ""}
                              </>
                            ) : (
                              <>
                                {thisPerson && isMaxToday ? (
                                  "▼ " +
                                  new Date(max).toLocaleTimeString("pl-PL", {
                                    hour: "numeric",
                                    minute: "numeric",
                                  })
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </div>
                          {isHolidayToday ? (
                            <div className="dayOffLabel">
                              {holidayToday[0].short_name}
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {employeeViewMode === "sums" ? (
                        <div className="sumHoursPerson">
                          {dataByPerson && dataByPerson[j.cardname] ? (
                            <>
                              {minutesToJSX(
                                dataByPerson[j.cardname].totalMinutes +
                                  holidaysCountHours.length * 60 * baseHours +
                                  otherHolidays.length * 60 * baseHours,
                                // j.holiday_count * 60 * 8,
                                false
                              )}
                              {dataByPerson[j.cardname].totalMinutesCustom ? (
                                <span
                                  style={{
                                    position: "relative",
                                    color: "grey",
                                    fontSize: "90%",
                                  }}
                                >
                                  (
                                  {minutesToJSX(
                                    dataByPerson[j.cardname]
                                      .totalMinutesCustom +
                                      holidays.length * 60 * baseHours +
                                      otherHolidays.length * 60 * baseHours,
                                    // j.holiday_count * 60 * 8,
                                    false
                                  )}
                                  )
                                  <span
                                    title="(ścinane do ustalonej liczby godzin)"
                                    style={{
                                      position: "absolute",
                                      top: "74%",
                                      left: "50%",
                                      borderRadius: "8px",
                                      padding: "0 8px",
                                      background: "white",

                                      fontSize: "7px",
                                      width: "max-content",
                                    }}
                                  >
                                    wg grafiku
                                  </span>
                                </span>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {daysOff.filter((d: any) => d.type).length ? (
                            <div className="dayOffSummary">
                              {holidays.length ? (
                                <div>
                                  <div className="name">URLOP</div>
                                  <div className="value">
                                    {holidays.length}{" "}
                                    <span>
                                      {holidays.length === 1 ? " dz." : " dni"}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {daysOffForSaturday.length ? (
                                <div className="forSaturday">
                                  <div className="name">ZA ŚW.</div>
                                  <div className="value">
                                    {daysOffForSaturday.length}{" "}
                                    <span>
                                      {daysOffForSaturday.length === 1 ? " dz." : " dni"}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}

                              {sickLeaves.length ? (
                                <div className="sick">
                                  <div className="name">CH</div>
                                  <div className="value">
                                    {sickLeaves.length}{" "}
                                    <span>
                                      {sickLeaves.length === 1
                                        ? " dz."
                                        : " dni"}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {noPayDays.length ? (
                                <div
                                  className="noPayDays"
                                  style={{ background: "lightgrey" }}
                                >
                                  <div className="name">BEZPŁ.</div>
                                  <div className="value">
                                    {noPayDays.length}{" "}
                                    <span>
                                      {noPayDays.length === 1 ? " dz." : " dni"}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {childCareDays.length ? (
                                <div
                                  className="childCareDays"
                                  style={{ background: "pink" }}
                                >
                                  <div className="name">OPIEKA</div>
                                  <div className="value">
                                    {childCareDays.length}{" "}
                                    <span>
                                      {childCareDays.length === 1
                                        ? " dz."
                                        : " dni"}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {otherHolidays.length ? (
                                <div
                                  className="otherHolidays"
                                  style={{ background: "#e97a7a" }}
                                >
                                  <div className="name">INNE</div>
                                  <div className="value">
                                    {otherHolidays.length}{" "}
                                    <span>
                                      {otherHolidays.length === 1
                                        ? " dz."
                                        : " dni"}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      {employeeViewMode === "holidays" ? (
                        <>
                          <div className="progressBar">
                            <div
                              className="progressBarFill"
                              style={{
                                width: (holidayLeft / 26) * 100 + "%",
                              }}
                            ></div>
                            <div
                              style={{
                                zIndex: 2,
                                position: "relative",
                                fontSize: "13px",
                                color: "black",
                              }}
                            >
                              {holidayLeft ? (
                                holidayLeft === 1 ? (
                                  <>{holidayLeft} dzień</>
                                ) : (
                                  <>{holidayLeft} dni</>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}
