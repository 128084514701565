import { useContext } from "react";
import { HybridContext } from "../HybridField";
import { useFormContext } from "react-hook-form";
import { FormDataContext } from "../../Form";
import { rowsCombine } from "../../../functions/tools";
import { getData } from "../../../dataFetch/useGeneric";

export default function HybridResults() {
  const {
    name,
    foundRows,
    resultElement,
    setResultsWindow,
    setHovered,
    resultUpdate,
    resultUpdateSub,
    setSubData,
    groupBy,
    hoverTimeout,
  } = useContext(HybridContext);
  const { setValue } = useFormContext();

  const { customFieldData, setCustomFieldData } = useContext(FormDataContext);

  async function update(item: any) {
    if (resultUpdate && !setSubData) {
      resultUpdate(item).forEach(async (pair: any[]) => {
        const fieldName = pair[0];
        let newValue = pair[1];

        // console.log(pair[0], pair[1]);
        if (Array.isArray(newValue)) {
          console.log(newValue);
          const db = newValue[0]?.name;
          const query = newValue[0]?.query;
          const f = await getData(
            {
              name: db,
              query: query,
            },
            undefined
          );
          const data = !f?.loading && f?.rows;
          const value = newValue?.[1]?.(data);
          setValue(fieldName, value);
        } else {
          setValue(fieldName, newValue);
        }
      });
    }

    if (setSubData) {
      setSubData((prev: any) => {
        resultUpdateSub(item).forEach((pair: any[]) => {
          prev[pair[0]] = pair[1];
        });
        // console.log(prev);
        return prev;
      });
    }
    setResultsWindow(false);
    setHovered(false);
  }

  return (
    <div
      className="hybrid-search-results"
      onMouseOver={() => clearTimeout(hoverTimeout)}
    >
      {foundRows.length ? (
        foundRows.map((row: any) => {
          const item =
            groupBy && Array.isArray(groupBy)
              ? rowsCombine(row, groupBy[1])
              : groupBy
              ? row[0]
              : row;
          return (
            <div
              className="hybrid-search-result"
              onClick={() => {
                update(item);
                setCustomFieldData((prev: any) => ({
                  ...prev,
                  [name]: {
                    ...prev[name],
                    dataObject: item,
                  },
                }));
              }}
            >
              {resultElement(item)}
            </div>
          );
        })
      ) : (
        <div style={{ padding: "7px 5px" }}>Nie znaleziono</div>
      )}
    </div>
  );
}
