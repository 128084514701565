export default function holidayCounters(holidayCount:any, selectedPerson:any) {
  const initialHolidayPool = holidayCount
    ? holidayCount.filter(
        (h: any) => h.cardname === selectedPerson  && h.holiday_relevant === 1
      )?.[0]?.initial_holiday_pool
    : undefined;

  const personalHolidayData =
    holidayCount !== undefined &&
    holidayCount.filter(
      (h: any) => h.cardname === selectedPerson && h.type === 1 && h.holiday_relevant === 1
    )?.[0];

  const anyEmployeeHolidayData =
    holidayCount !== undefined &&
    holidayCount.filter(
      (h: any) => h.cardname === selectedPerson && h.holiday_relevant === 1
    )?.[0];


  const holidayLeft =
    anyEmployeeHolidayData !== undefined
      ? anyEmployeeHolidayData.initial_holiday_pool +
        anyEmployeeHolidayData.added_holiday_pools -
        (personalHolidayData ? personalHolidayData.holiday_used : 0)
      : initialHolidayPool;

      const holidayStartPool=
      anyEmployeeHolidayData !== undefined
        ? anyEmployeeHolidayData.initial_holiday_pool +
          anyEmployeeHolidayData.added_holiday_pools : initialHolidayPool;

  return {
    initialHolidayPool: initialHolidayPool,
    personalHolidayData: personalHolidayData,
    anyEmployeeHolidayData: anyEmployeeHolidayData,
    holidayLeft: holidayLeft,
    holidayStartPool: holidayStartPool
  };
}
