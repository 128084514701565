export default function prettyMoney(input: number | string, params?: any) {
  let number = Number(input);
  
  if (params?.round) {
    number = Math.round(Number(input) * 100) / 100;
  }
  const numString = String(number);
  const numArr = numString.replace(".", ",").split(",");
  const integer: string = numArr[0];
  let decimals = numArr[1] ? numArr[1] : "";
  decimals =
    decimals.length === 1
      ? decimals + "0"
      : decimals.length === 0
      ? decimals + "00"
      : decimals;
  decimals = "," + decimals;

  let integerPretty = integer.replace(/(?<=\d)(?=(\d\d\d)+(?!\d))/g, " ");

  /*
      switch (integer.length) {
          case 4: 
              integerPretty = integer.at(0) + integer.slice(1,4);
          break;
          case 5:
              integerPretty = integer.at(0) + integer.slice(1,4);
          break;
      }*/

  return params?.text ? (
    String(integerPretty) +
      String(decimals) +
      (params?.postfix ? " " + params.postfix : "")
  ) : (
    <span
      className={params?.className}
      style={
        params && params.scale
          ? {
              ...params?.style,
              color: `rgb(0,${((Number(integer) / params.scale) * 90) % 255},${
                ((Number(integer) / params.scale) * 50) % 200
              })`,
            }
          : { ...params?.style }
      }
    >
      {String(integerPretty)} 
      <span className="decimals">{String(decimals)}</span>
      {params?.postfix}
    </span>
  );
}
