import Value from "./Value";
import listIcon from "../../images/icons/eye.svg";
import addIcon from "../../images/icons/add.svg";
import { useContext } from "react";
import { ViewContext } from "../contexts/ViewContext";
import AddEntryBHP from "./AddEntryBHP";
import useGeneric from "../dataFetch/useGeneric";
import prettyDate from "../functions/prettyDate";

export default function Bhp({
  employee,
  subView,
  setSubView,
  updates,
  setUpdates,
}: {
  employee: any;
  subView: string;
  setSubView: Function;
  updates: number;
  setUpdates: Function;
}) {
  const editing = false;

  const { modal, setModal } = useContext(ViewContext);

  return (
    <div className="division">
      <div className="heading">
        <h2>Szkolenia BHP</h2>
        <div className="options">
          <button
            onClick={() =>
              setModal({
                show: true,
                heading: "Dodaj szkolenie BHP",
                content: (
                  <AddEntryBHP employee={employee} setUpdates={setUpdates} />
                ),
              })
            }
          >
            <img src={addIcon} />
          </button>
          <button
            className={"more-details" + (subView === "bhp" ? " active" : "")}
            onClick={() => setSubView("bhp")}
          >
            <img src={listIcon} />
          </button>
        </div>
      </div>
      {employee.last_bhp_date || employee.next_bhp_date ? (
        <>
          {/* <Value
            label="Ostatnie szkolenie"
            data={prettyDate(employee.last_bhp_date, "", true, {
              year: "always",
            })}
            isEditing={editing}
            editor="date"
          /> */}
          <Value
            label="Kolejne szkolenie"
            data={prettyDate(employee.next_bhp_date, "", true, {
              year: "always",
            })}
            isEditing={editing}
            editor="date"
          />
          {employee.next_bhp_date ? (
            <div className="labeled-value">
              <label>Pozostało dni</label>
              <div>
                {Math.round(
                  new Date(
                    new Date(employee.next_bhp_date).getTime() -
                      new Date().getTime()
                  ).getTime() /
                    1000 /
                    60 /
                    60 /
                    24
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className="info">Brak danych</div>
      )}
    </div>
  );
}
