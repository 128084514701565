import { Scanner } from "@yudiel/react-qr-scanner";
import { useContext, useState } from "react";
import { ViewContext } from "../contexts/ViewContext";
import VoucherDetails from "./VoucherDetails";

export default function QRRead() {
  const [reads, setReads] = useState<any>([]);
    // const [currentRead, setCurrentRead] = useState<any>();
    // const [readingMode, setReadingMode] = useState(true);
  const [currentRead, setCurrentRead] = useState<any>([
    { rawValue: "100-759" },
  ]);
  const [readingMode, setReadingMode] = useState(false);

  const { modal, setModal } = useContext(ViewContext);

  const readNumber = currentRead?.[0]?.rawValue;

  return (
    <>
      <div className="qr-full">
        {readingMode ? (
          <div style={{ width: "400px", height: "400px" }}>
            <Scanner
              onScan={(result) => {
                setReads((prev: any) => [...prev, result]);
                setCurrentRead(result);
                setReadingMode(false);
              }}
              //   constraints={{frameRate:10}}
            />
          </div>
        ) : (
          <></>
        )}
        {currentRead && currentRead[0] ? (
          <div
            className="qr-main"
            onClick={() =>
              setModal({
                content: <VoucherDetails number={readNumber} />,
                heading: "Voucher",
                show: true,
              })
            }
          >
            <div>{readNumber}</div>
          </div>
        ) : (
          <></>
        )}
        {!readingMode ? (
          <button
            onClick={() => setReadingMode(true)}
            className="normal-button huge"
          >
            Czytaj następny
          </button>
        ) : (
          <></>
        )}
        <div style={{ marginTop: "30px" }}>Ostatnie odczyty</div>
        <div className="qr-list">
          {reads?.map((r: any) => (
            <div>{r?.[0]?.rawValue}</div>
          ))}
        </div>
      </div>
    </>
  );
}
