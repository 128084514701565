import { useContext, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import { ViewContext } from "../contexts/ViewContext";

export default function ProductsView() {
  const { setCurrentPage } = useContext(ViewContext);
  setCurrentPage("products");

  const [update, setUpdate] = useState(0);
  const [name, setName] = useState();

  const fetchProducts = useGeneric({
    name: "lsi_restaurant",
    limit: 100,
    offset: 0,
    query: {
      nazwa: name,
    },
    update: update,
  });
  const products = !fetchProducts.loading && fetchProducts.rows;
  return products ? (
    <div className="standardWrap">
      <div>
        <h1>Wyszukiwarka produktów LSI </h1>
      </div>
      <div className="filters" style={{ padding: "7px 4px" }}>
        <form className="generic-search-form">
          <div>
            <label htmlFor="name">Nazwa</label>
            <input
              onChange={(e: any) => {
                setName(e.target.value);
                setUpdate((prev: number) => prev + 1);
              }}
              id="name"
            />
          </div>
          <div>
            {products?.length ? <>Znaleziono {products.length}</> : <></>}
          </div>
        </form>
      </div>
      <table className="data-table">
        <thead>
          <tr>
            <th>Grupa</th>
            <th>Nazwa</th>
            <th>Cena netto</th>
            <th>Cena bruto</th>
            <th>Koszt surowców netto</th>
            <th>Koszt surowców brutto</th>
          </tr>
        </thead>
        <tbody>
          {products.map((p: any) => (
            <tr>
              <td style={{ width: "150px" }}>{p.GrupaAdm}</td>
              <td style={{ width: "50%" }}>{p.nazwa}</td>
              <td>{Math.round(p.cenaSN * 100) / 100}</td>
              <td>{Math.round(p.cenaSB * 100) / 100}</td>
              <td>{Math.round(p.kosztSurowcowNetto * 100) / 100}</td>
              <td>{Math.round(p.kosztSurowcowBrutto * 100) / 100}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
}
