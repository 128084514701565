import Loading from "../common/Loading";
import useGeneric from "../dataFetch/useGeneric";
import prettyDate from "../functions/prettyDate";
import deleteIcon from "../../images/icons/delete.svg";
import insertGeneric from "../dataFetch/insertGeneric";
import { URL } from "../paths";
export default function SanitaryDetails({
  employee,
  updates,
  setUpdates,
}: {
  employee: any;
  updates: number;
  setUpdates: Function;
}) {
  const f = useGeneric({
    name: "employee_sanitary",
    limit: 1000,
    query: { employee_id: employee.id, active: true },
    update: updates,
    showLoadingOnManualUpdate: true,
  });
  const data = !f.loading && f.rows;

  async function deleteItem(item: any) {
    const response = await fetch(`${URL}backend/inserts/set-sanitary.php`, {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ id: item.id, remove: true }),
    });
    const result = await response.json();
    if (response.ok) {
      setUpdates((prev: number) => prev + 1);
    }
  }

  return (
    <div className="emp-report">
      <h2>Badania do celów sanitarno-epidemiologicznych</h2>
      {f.loading ? (
        <Loading little />
      ) : !data || !data.length ? (
        <div className="info">Brak danych</div>
      ) : (
        <table className="data-table midi">
          <thead>
            <tr>
              <th>Lp.</th>
              <th>Data</th>
              <th>Ważne do</th>
              <th>Uwagi</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item: any, index: number) => (
              <tr>
                <td>{data.length - index}</td>
                <td>{prettyDate(item.date, "", true, { year: "always" })}</td>
                <td>
                  {prettyDate(item.date_next, "", true, { year: "always" })}
                </td>
                <td>{item.comment}</td>
                <td>
                  <img
                    onClick={() => {
                      if (window.confirm("Czy na pewno chesz usunąć wpis?")) {
                        deleteItem(item);
                      }
                    }}
                    width={15}
                    style={{
                      filter:
                        "invert(.2) sepia(1) saturate(12) hue-rotate(324deg)",
                      cursor: "pointer",
                    }}
                    src={deleteIcon}
                  />
                </td>
                {/* <td>{item.next_type_string}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
