import useGeneric from "../dataFetch/useGeneric";
import holidayCounters from "./holidayCounters";

export default function HolidaysTable({
  selectedPerson,
  year,
  holidayCount,
  update,
  holidaySumType,
}: {
  selectedPerson: string | false;
  year: number;
  holidayCount: any;
  update: number;
  holidaySumType: number;
}) {
  const isAnnualLeave = [1, 2].includes(holidaySumType);
  const holidayRanges = useGeneric({
    name: "holidayRanges",
    limit: 9999999,
    query: { year: year },
    update: update,
  });
  // console.log(holidayRanges);
  if (holidayRanges.rows) {
    const data = holidayRanges.rows.filter(
      (i: any) =>
        i.name === selectedPerson &&
        (holidaySumType === 0
          ? true
          : [1, 2].includes(holidaySumType)
          ? [1, 2].includes(i.type)
          : i.type === holidaySumType) //[1, 2].includes(i.type)
    );
    const prepData =
      data &&
      data.map((x: any) => {
        const dates = x.dates.split(",");
        const inters: any = [];
        let lastFullIndex = 0;
        const interval = dates.forEach((item: any, index: number, arr: any) => {
          const thisDate: any = new Date(item);
          const prevDate: any = arr[index - 1]
            ? new Date(arr[index - 1])
            : false;
          //  const nextDate = arr[index+1] ? new Date(arr[index+1]) : false;
          const dayMs = 60 * 60 * 24 * 1000;
          const isSeriesBorder = thisDate - prevDate !== dayMs ? item : false;

          if (isSeriesBorder) {
            inters[index] = [isSeriesBorder];
            lastFullIndex = index;
          } else {
            inters[lastFullIndex].push(item);
          }
        });

        return { ...x, dates: dates, inters: inters.filter((e: any) => e) };
      });

    return !holidayRanges.loading ? (
      <table className="data-table holidaysTable">
        <thead>
          <tr>
            {holidaySumType === 0 ? <th>Rodzaj</th> : <></>}
            <th>Dni</th>
            <th style={{ width: "70px" }}>Liczba</th>
            {isAnnualLeave ? <th>{"> "}Saldo</th> : <></>}
          </tr>
        </thead>
        <tbody>
          {prepData.map((d: any, i: number, arr: any) => {
            let holidayPool = holidayCounters(
              holidayCount,
              selectedPerson
            ).holidayStartPool;
            let sum = 0;
            return d?.inters?.map((di: any) => {
              sum = sum + di.length;
              return (
                <tr>
                  {holidaySumType === 0 ? (
                    <td style={{ fontSize: "9px" }}>{d.typeshort}</td>
                  ) : (
                    <></>
                  )}
                  <td>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        textAlign: "left",
                      }}
                    >
                      <span style={{ fontSize: "10px" }}>
                        {di[0] !== di.at(-1) ? (
                          <>
                            {new Date(di[0]).toLocaleDateString("pl-PL", {
                              weekday: "short",
                            })}{" "}
                            —{" "}
                            {new Date(di.at(-1)).toLocaleDateString("pl-PL", {
                              weekday: "short",
                            })}
                          </>
                        ) : (
                          <>
                            {new Date(di[0]).toLocaleDateString("pl-PL", {
                              weekday: "short",
                            })}
                          </>
                        )}
                      </span>
                      {new Date(di[0]).toLocaleDateString(
                        "pl-PL",
                        di[0] !== di.at(-1) &&
                          new Date(di[0]).getMonth() ===
                            new Date(di.at(-1)).getMonth()
                          ? { day: "numeric" }
                          : { month: "long", day: "numeric" }
                      )}
                      {di[0] !== di.at(-1) ? (
                        <>
                          {" "}
                          -{" "}
                          {new Date(di.at(-1)).toLocaleDateString("pl-PL", {
                            month: "long",
                            day: "numeric",
                          })}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>
                  <td>{di.length}</td>
                  {isAnnualLeave ? <td>{holidayPool - sum}</td> : <></>}
                </tr>
              );
            });
          })}
          {/* <tr>
             data?.[0]?.add_from
        </tr>*/}
          <tr>
            {holidaySumType === 0 ? <td></td> : <></>}
            <td>SUMA</td>
            <td style={{ fontWeight: "bold" }}>
              {prepData?.[0]?.dates?.length}
            </td>
          </tr>
        </tbody>
      </table>
    ) : (
      <>Wczytywanie</>
    );
  } else {
    return <></>;
  }
}
