import { useState } from "react";
import generateCalendarData from "../calendar/generateCalendarData";
import Loading from "../common/Loading";
import useGeneric from "../dataFetch/useGeneric";
import minutesToJSX, {
  minutesToShortJSX,
  minutesToString,
} from "../functions/minutesToJSX";
import AttendanceGroupTable from "./attendanceReports/AttendanceGroupTable";
import HamsterLoading from "../common/HamsterLoading";

export default function AttendanceReports({
  location,
  date,
  groups,
  lastCheckIns,
}: {
  location: string;
  date: any;
  groups: any;
  lastCheckIns: any;
}) {
  const now = new Date();

  const raw = useGeneric({
    name: ["attendanceSums", "holidaySums"],
    query: {
      month: date.month + 1,
      year: date.year,
      location: location === "biuro" ? "1" : "0",
    },
    limit: 99999,
    reload: true,
  });

  const att = !raw.loading && raw.multiple.attendanceSums?.rows;
  const hol = !raw.loading && raw.multiple.holidaySums?.rows;
  const data: any[] = [];

  if (att && hol) {
    att.forEach((a: any) => {
      a.seconds = parseInt(a.seconds);
      a.holidaySeconds = 0;
      a.totalSeconds = a.seconds;
      data.push(a);
    });
    hol.forEach((h: any) => {
      const person = h.cardperson;
      const personIndex = data.findIndex((v: any) => v.cardperson === person);
      if (personIndex !== -1) {
        data[personIndex] = { ...data[personIndex], ...h };
        data[personIndex].seconds = parseInt(data[personIndex].seconds);
        data[personIndex].holidaySeconds =
          parseInt(h.holidayCount) *
            (h.base_hours_per_day ? Number(h.base_hours_per_day) : 8) *
            60 *
            60 +
          parseInt(h.otherHolidayCount) *
            (h.base_hours_per_day ? Number(h.base_hours_per_day) : 8) *
            60 *
            60;
        data[personIndex].totalSeconds =
          parseInt(data[personIndex].holidaySeconds) +
          parseInt(data[personIndex].seconds);
      } else {
        h.seconds = 0;
        h.holidaySeconds =
          parseInt(h.holidayCount) *
            (h.base_hours_per_day ? Number(h.base_hours_per_day) : 8) *
            60 *
            60 +
          parseInt(h.otherHolidayCount) *
            (h.base_hours_per_day ? Number(h.base_hours_per_day) : 8) *
            60 *
            60;
        h.totalSeconds = parseInt(h.holidaySeconds) + parseInt(h.seconds);

        data.push(h);
      }
    });
  }
  //console.log(data);

  const calData = generateCalendarData(
    { month: date.month, year: date.year },
    { loading: true }
  );

  const dataGroupped =
    data && groups
      ? groups.map((group: any) => ({
          ...group,
          data: data.filter((i: any) => i.group === group.id),
        }))
      : undefined;
  //console.log(dataGroupped);
  return data && data.length ? (
    <div className="attendanceReports">
      <AttendanceGroupTable
        groups={dataGroupped}
        calendar={calData}
        location={location}
        lastCheckIns={lastCheckIns}
      />
    </div>
  ) : (
    <HamsterLoading />
  );
}
