import QRCode from "react-qr-code";
import { URL } from "../paths";
import { useEffect, useState } from "react";

export function usePaymentQRvalue(amount: number, title: string) {
  const [data, setData] = useState("");

  async function fetchMe() {
    const f = await fetch(URL + "backend/lsi_dev/bank_code_generator.php", {
      body: JSON.stringify({
        title: title,
        amount: Number(amount),
      }),
      credentials: "include",
      method: "POST",
    });
    if (f.ok) {
      const d = await f.text();
      setData(d);
    }
  }

  useEffect(() => {
    fetchMe();
  }, []);

  return data;
}

export default function PaymentQR({
  amount,
  title,
}: {
  amount: number;
  title: string;
}) {
  const data = usePaymentQRvalue(amount, title);
  console.log(data);

  return data ? (
    <div style={{ width: "150px", padding: "10px" }} id="fff">
      <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={data}
        viewBox={`0 0 256 256`}
      />
    </div>
  ) : (
    <></>
  );
}
