import { useContext, useState } from "react";
import settingsIcon from "../../../images/icons/gear.svg";
import { useFormContext } from "react-hook-form";
import { FormDataContext } from "../Form";
import prettyDate from "../../functions/prettyDate";

export default function FieldOptions({
  sub,
  fieldValue,
  fieldName,
}: {
  sub: Boolean;
  fieldValue: any;
  fieldName: string;
}) {
  const [extended, setExtended] = useState(false);
  const { getValues, setValue } = useFormContext();
  const { touchedMultiAdds, setTouchedMultiAdds } = useContext(FormDataContext);

  const fieldNameGeneric = fieldName.replace(/%.*%/, "");
  const keys = Object.keys(getValues()).filter((k: any) =>
    k.match(fieldNameGeneric)
  );
  const currIndex = keys.findIndex((k: any) => k === fieldName);
  let prevName;
  if (keys?.[currIndex - 1]) {
    prevName = prettyDate(
      keys[currIndex - 1].replace(/^(.*)%(.*)%/, "$2"),
      undefined,
      true
    );
  }

  function copyToAll(onlyEmpty: Boolean) {
    keys.forEach((k: string) => {
      if (onlyEmpty ? !getValues(k) : true) {
        if (k !== fieldNameGeneric) {
          setValue(k, fieldValue);
        }
      }
    });
  }

  function getFromDayBefore() {
    if (keys?.[currIndex - 1]) {
      const prevKey = keys[currIndex - 1];
      const prevValue = getValues(prevKey);
      setValue(fieldName, prevValue);
      setTouchedMultiAdds((prev: any) => ({
        ...prev,
        [fieldName]: true,
      }));
    }
  }
  return (
    sub && (
      <span className="field-options">
        <div>
          <img src={settingsIcon} />
        </div>
        <ul className="field-options-list">
          {prevName ? (
            <li onClick={() => getFromDayBefore()}>Pobierz z {prevName}</li>
          ) : (
            <></>
          )}
          <li onClick={() => copyToAll(false)}>Kopiuj do wszystkich</li>
          <li onClick={() => copyToAll(true)}>Kopiuj do pustych</li>
        </ul>
      </span>
    )
  );
}
