import AddCorrectDocument from "./AddCorrectDocument";
import plusIcon from "../../../../images/icons/plus.svg";
import editIcon from "../../../../images/icons/pencil.png";
import removeIcon from "../../../../images/icons/delete.svg";
import insertGeneric from "../../../dataFetch/insertGeneric";

export default function CorrectDocumentOptions({
  row,
  f,
}: {
  row: any;
  f: any;
}) {
  return (
    <div style={{ display: "flex" }}>
      {row?.resolved || f?.auth?.errors < 9 ? (
        <></>
      ) : (
        <button
          className="normal-button"
          style={{
            width: "100%",
            background: "none",
            padding: "3px 5px",
            fontSize: "13px",
          }}
          onClick={() =>
            f?.setModal({
              show: true,
              style: {
                // width: "600px"
              },
              heading: <>Dodaj dokument korekty</>,
              content: <AddCorrectDocument row={row} functions={f} />,
            })
          }
        >
          <img
            src={row?.cor_doc_number ? editIcon : plusIcon}
            style={{ width: "15px" }}
          />
        </button>
      )}

      {f?.auth?.errors < 9 ? (
        <></>
      ) : !row?.resolved && row?.cor_doc_number ? (
        <button
          className="normal-button"
          style={{
            width: "100%",
            background: "none",
            padding: "3px 5px",
            fontSize: "13px",
          }}
          onClick={async () => {
            if (
              window.confirm("Czy na pewno chcesz usunąć dokument korekty?")
            ) {
              await insertGeneric("remove-error-correction", {
                id: row?.cor_id,
              });
              f?.setUpdates((prev: number) => prev + 1);
            }
          }}
        >
          <img style={{ width: "11px" }} src={removeIcon} />{" "}
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
