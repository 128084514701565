function easterDate(year:number) {
	var f = Math.floor,
		// Golden Number - 1
		G = year % 19,
		C = f(year / 100),
		// related to Epact
		H = (C - f(C / 4) - f((8 * C + 13)/25) + 19 * G + 15) % 30,
		// number of days from 21 March to the Paschal full moon
		I = H - f(H/28) * (1 - f(29/(H + 1)) * f((21-G)/11)),
		// weekday for the Paschal full moon
		J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
		// number of days from 21 March to the Sunday on or before the Paschal full moon
		L = I - J,
		month = 3 + f((L + 40)/44),
		day = L + 28 - 31 * f(month / 4);

	return new Date(year,month-1,day, 14);
}

function easterMondayDate(y: number) {
  let easter = easterDate(y);
  let monday = easter.setDate(easter.getDate() + 1);
  return new Date(monday);
}

function pentecostDate(y: number) {
  let easter = easterDate(y);
  let pentecost = easter.setDate(easter.getDate() + 49);
  return new Date(pentecost);
}

function ashWednesday(y: number) {
  let easter = easterDate(y);
  let ashWednesday = easter.setDate(easter.getDate() - 46);
  return new Date(ashWednesday);
}

function fatThursday(y:number) {
  let easter = easterDate(y);
  let ashWednesday = easter.setDate(easter.getDate() - 52);
  return new Date(ashWednesday);
}

function corpusChristi(y:number) {
  let easter = easterDate(y);
  let cc = easter.setDate(easter.getDate() + 60);
  return new Date(cc);
}

function holidays(y:number) {
  return [
    new Date(y, 0, 1),
    new Date(y, 0, 6),
    easterDate(y),
    easterMondayDate(y),
    new Date(y, 4, 1),
    new Date(y, 4, 3),
    corpusChristi(y),
    new Date(y, 7, 15),
    new Date(y, 10, 1),
    new Date(y, 10, 11),
    new Date(y, 11, 25),
    new Date(y, 11, 26),
  ];
}

function importantDaysFull(y:number) {
  return [
    { date: fatThursday(y), name: "Tłusty Czwartek" },
    { date: ashWednesday(y), name: "Środa Popielcowa" },
  ];
}

export function holidaysFull(y:number) {
  const easter = easterDate(y);
  const easterMonday = easterMondayDate(y);
  const corpusChristiDay = corpusChristi(y);

  return [
    { month: 1, day: 1, description: "Nowy Rok" },
    { month: 1, day: 6, description: "Święto Trzech Króli" },
    {
      month: easter.getMonth() + 1,
      day: easter.getDate(),
      description: "Wielkanoc",
    },
    {
        month: easterMonday.getMonth() + 1,
        day: easterMonday.getDate(),
        description: "Poniedziałek Wielkanocny",
    },
    { month: 5, day: 1, description: "Święto Pracy" },
    { month: 5, day: 3, description: "Święto Narodowe Trzeciego Maja" },
    {
        month: corpusChristiDay.getMonth() + 1,
        day: corpusChristiDay.getDate(),
        description: "Boże Ciało",
    },
    {
      month: 8,
      day: 15,
      description: "Wniebowzięcie Najświętszej Marii Panny",
    },
    { month: 11, day: 1, description: "Wszystkich Świętych" },
    { month: 11, day: 11, description: "Święto Niepodległości" },
    { month: 12, day: 25, description: "Boże Narodzenie" },
    { month: 12, day: 26, description: "2. dzień Świąt Bożego Narodzenia" }
  ];
}

function getHolidayData(y:number, m:number, d:number) {
  let getHoliday = holidaysFull(y)
    .filter((item:any) => {
      if (item.date.getMonth() === m && item.date.getDate() === d) return 1;
    })
    .map((item: any) => item.name);
  return getHoliday;
}

function holidaysSimplifiedFull(y:number) {
  let dates: any = [];
  for (let i = 0; i < 12; i++) {
    dates.push([]);
  }

  let result = holidaysFull(y).forEach((date: any) =>
    dates[date.date.getMonth()].push({
      day: date.date.getDate(),
      name: date.name,
    })
  );
  return dates;
}

export function holidaysSimplified(y:number) {
  let dates: any = [];
  for (let i = 0; i < 12; i++) {
    dates.push([]);
  }

  let result = holidays(y).forEach((date) =>
    dates[date.getMonth()].push(date.getDate())
  );
  return dates;
}

export function holidaysInMonth( m:number, y:number) {
  //console.log(easterDate(y));
  return holidaysSimplified(y)[m];
}

export function holidaysISO(y: number) {
  return holidays(y).map(y => y.toISOString().slice(0,10));
}