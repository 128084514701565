import { formContent } from "../forms_new/formContentType";
import prettyDate from "../functions/prettyDate";
import { datesSince } from "../functions/timeAgo";
import { createGUID, inflect, objectOrJSONparse } from "../functions/tools";
import { timeDiffString } from "../tables/views/viewFunctions";

export default function holidayCoreSchema(
  editData: any,
  employee: any
): formContent {
  return [[
    {
        name: "user_display",
        label: "Użytkownik",
        disabled: true,
        initialValue: editData?.first_name + " " + editData?.last_name
    },
    {
        name:"id",
        inputType:"hidden"
    },
    {
        name:"initial_holiday_pool",
        label:"Pula początkowa w dniach",
        inputType:"number"
    },
    {
        name:"holiday_pool_start_date",
        label:"Dzień od którego liczona jest pula początkowa",
        inputType:"date"
    },
    {
        name:"annual_holiday_pool1",
        label:"Roczny wymiar urlopu",
        inputType:"number"
    },
    {
        name:"annual_holiday_pool2",
        label:"Dodatkowy roczny wymiar urlopu",
        inputType:"number"
    },
    {
        name:"annual_holiday_pool2_add_from",
        label:"Dzień od którego obowiązuje dodatkowy urlop",
        inputType:"date"
    }
  ]];
}
