import { isEmptyArray } from "formik";
import BooleanSymbol from "../viewElements/BooleanSymbol";

function ipTranslate(ip: string) {
  let ipText = "";
  switch (ip) {
    case "46.232.128.34":
      case "46.232.128.36":
      ipText = "HOTEL/VPN";
      break;
    case "46.170.175.82":
      ipText = "BIURO";
      break;
    default:
      ipText = ip;
      break;
  }
  return <span title={ip}>{ipText}</span>;
}

function translateFlag(flags: number) {
  switch (flags) {
    case 0:
    case 2:
      return {
        //stateText = "wejście służbowe",
        stateText: "rozpoczęcie pracy",
        stateInfo: "➽",
        stateString: "przyjście",
        state: 0,
        stateVar: "workStart",
      };
      break;
    case 1:
    case 3:
      return {
        //stateText = "wyjście służbowe",
        stateText: "zakończenie pracy",
        stateInfo: "⌫",
        stateString: "wyjście",
        state: 1,
        stateVar: "workEnd",
      };
      break;
    default:
      return {
        stateText: "brak oznaczenia",
        stateInfo: "-",
        state: 3,
        stateVar: "noTag",
      };
      break;
  }
}

export default function attendanceView(item: any) {
  const date = new Date(item.timestring).toLocaleDateString("pl-PL", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const time = new Date(item.timestring).toLocaleTimeString("pl-PL", {
    hour: "numeric",
    minute: "numeric",
  });
  const columns = [
    {
      ref: "date",
      name: "Data",
      jsx: (
        <div title={item.id}>
          {/*correctTS(item.timestamp).toLocaleString("pl-PL")*/}
          <div>{date}</div>
        </div>
      ),
      style: { width: "80px", fontFamily: "monospace" },
    },
    {
      ref: "timestamp",
      name: "Czas",
      jsx: (
        <div title={item.id}>
          <div
            style={{
              fontFamily: "monospace",
              fontSize: "14px",
              color:
                translateFlag(item.typeshort).state === 0
                  ? "#3eb714"
                  : "#c15329",
            }}
          >
            {time}
          </div>
        </div>
      ),
      style: { width: "35px" },
    },
    {
      ref: "cardid",
      name: "Zdarzenie",
      jsx: (
        <div
          style={{
            display: "grid",
            textAlign: "left",
            paddingLeft: "10px",
            gridTemplateColumns: "30px 1fr",
          }}
        >
          <div
            style={{
              width: "25px",
              fontWeight: "500",
              fontSize: "12px",
              ...(translateFlag(item.typeshort).state === 0
                ? { color: "#3eb714" }
                : { color: "#c15329" }),
            }}
          >
            {translateFlag(item.typeshort).stateInfo}
          </div>
          <div>
            {item.cardperson}{" "}
            {item.typeshort === 2 || item.typeshort === 3 ? (
              <span
                style={{
                  fontSize: "80%",
                  background: "#335b56",
                  color: "white",
                  padding: "0px 4px",
                  borderRadius: "3px",
                  display: "inline-block",
                  marginLeft: "3px",
                }}
              >
                ZDALNIE
              </span>
            ) : (
              <></>
            )}
            {item.typeshort === 4 || item.typeshort === 5 ? (
              <span
                style={{
                  fontSize: "80%",
                  background: "orangered",
                  color: "white",
                  padding: "0px 4px",
                  borderRadius: "3px",
                  display: "inline-block",
                  marginLeft: "3px",
                }}
              >
                INNE
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      ),
    },
    {
      ref: "custom",
      name: "Źródło",
      jsx: (
        <div>
          {item.custom ? (
            <span style={{ fontWeight: "700" }}>aplikacja</span>
          ) : (
            <span style={{ fontSize: "80%" }}>urządzenie</span>
          )}
        </div>
      ),
      style: { width: "75px" },
    },
    {
      ref: "modified_by",
      name: "Dane wpisu",
      style: { width: "230px", cursor: "pointer" },
      jsx: (
        <div style={{ fontSize: "10px", display:"flex", justifyContent:"space-evenly" }}>
          {item.mod_data ? (
            <>
              <div>
                {new Date(item.mod_data.split("/")[1] * 1000).toLocaleString()}
              </div>
              <div>IP {ipTranslate(item.mod_data.split("/")[0])}</div>
              <div style={{fontWeight:"700"}}>{item.modified_by}</div>
              {item.mod_data.split("/")[2] ? <div>{item.mod_data.split("/")[2]}</div> : <></>}
            </>
          ) : (
            <>rejestrator {item.location === "attendance" ? "hotel" : "biuro"}</>
          )}
        </div>
      ),
    },
    {
      ref: "active",
      name: "Aktywne",
      style: { width: "20px", cursor: "pointer" },
      jsx: <BooleanSymbol value={item.active} />,
    },
  ];
  return {
    columns: columns,
    classRef:
      (item.active ? "active" : "inactive") + (item.custom ? " custom" : ""),
  };
}
