import prettyDate from "../../functions/prettyDate";
import ScheduleItem from "./ScheduleItem";
import dateIcon from "../../../images/icons/date.png";
import { useState } from "react";
import collapseIcon from "../../../images/icons/collapse.svg";
import useEventViewItems from "../useEventViewItems";
import waitersIcon from "../../../images/icons/new/waiter.svg";
import chefsIcon from "../../../images/icons/new/chef-cap2.svg";
import dishWashIcon from "../../../images/icons/new/dishwash2.svg";

export default function DateItem({
  d,
  event,
  schedules,
  meals,
  auth
}: {
  d: any;
  event: any;
  schedules: any;
  meals: any;
  auth?: any;
}) {
  const [collapsed, setCollapsed] = useState(false);
  const date = prettyDate(d.date, "", true, {
    month: "numeric",
    year: "numeric",
    weekday: "long",
  });
  const view = useEventViewItems(event, new Date(d.date));

  return !view ? <></> : (
    <div className="schedule-date">
      <div className="date-main">
        <div className="date">
          <img src={dateIcon} />
          <div>
            {date?.split(", ")?.map((i: any) => (
              <div>{i}</div>
            ))}
          </div>
        </div>
        {auth?.agenda == "2" ?
        <div className="service-numbers">
          <div className="framed-icon-group" title="Kelnerzy">
            <img className="framed-icon" src={waitersIcon} />{" "}
            <div>{view.values.waiters}</div>
          </div>

          <div className="framed-icon-group" title="Kucharze">
            <img className="framed-icon" src={chefsIcon} />
            <div>{view.values.chefs}</div>
          </div>
          <div className="framed-icon-group" title="Na zmywaku">
            <img className="framed-icon" src={dishWashIcon} />{" "}
            <div>{view.values.dishwashers}</div>
          </div>
        </div> : <div></div>}
        <pre className="day-comments">{d._day_comments}</pre>
        <div
          className="collapse"
          onClick={() => setCollapsed((prev: Boolean) => !prev)}
        >
          {collapsed ? (
            <img style={{ transform: "rotateX(180deg)" }} src={collapseIcon} />
          ) : (
            <img src={collapseIcon} />
          )}
        </div>
      </div>

      <div style={collapsed ? { display: "none" } : {}}>
        {schedules
          .filter((s: any) => s.date === d.date)
          .map((item: any) => (
            <ScheduleItem item={item} meals={meals} event={event}/>
          ))}
      </div>
    </div>
  );
}
