import minutesToJSX, {
  minutesToShortJSX,
  minutesToString,
} from "../../functions/minutesToJSX";
import AttendanceTableRow from "./AttendanceTableRow";

export default function AttendanceGroupTable({
  groups,
  calendar,
  location,
  lastCheckIns,
}: {
  groups: any;
  calendar: any;
  location: string;
  lastCheckIns: any;
}) {
  const today = new Date();
  return (
    groups &&
    groups.map((g: any) =>
      g.data.length === 0 ? (
        <></>
      ) : (
        <>
          <h4 style={{ textTransform: "uppercase" }}>{g.name}</h4>
          <table className="data-table">
            <thead>
              <tr>
                <th style={{ minWidth: "150px", height: "25px" }}>Osoba</th>
                <th style={{ minWidth: "100px" }}>Suma</th>
                {calendar.dates.map((d: any) => (
                  <th
                    key={d.obj.toISOString()}
                    title={d.obj.toISOString()}
                    className={
                      "tableDay" +
                      (d.holiday ? " holiday" : "") +
                      (d.obj.getDay() === 6 ? " saturday" : "") +
                      (d.obj.getDay() === 0 ? " sunday" : "") +
                      (d.obj.getDay() === 0 ? " sunday" : "") +
                      (d.obj.getDate() === today.getDate() &&
                      d.obj.getMonth() === today.getMonth() &&
                      d.obj.getFullYear() === today.getFullYear()
                        ? " today"
                        : "")
                    }
                  >
                    <span className="standard">{d.obj.getDate()}</span>
                    <span className="hovered">
                      {d.obj.toLocaleDateString("pl-PL", {
                        weekday: "short",
                      })}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {g.data &&
                g.data.length &&
                g.data.map((item: any) => {
                  //lastCheckIns.reverse();
                  const checkIn = lastCheckIns.find((checkIn: any) => item.cardperson === checkIn.cardperson);
                  return (
                    <AttendanceTableRow
                      key={item.cardperson}
                      item={item}
                      calendar={calendar}
                      location={location}
                      checkIn={checkIn}
                    />
                  );
                })}
            </tbody>
          </table>
        </>
      )
    )
  );
}
