export default function decodePermissions(p: string | undefined) {
    let permissions: any = undefined;
     if (p && p.length > 0) {
        permissions = {};
        permissions.RegisterUser = p[0];
        permissions.Users = p[1];
        permissions.Attendance = p[2];
        permissions.ErrorRegister = p[3];
        permissions.Calendar = p[4];
        permissions.Procedures = p[5];
        permissions.Tasks = p[6];
        permissions.Chat = p[7];
        permissions.Schedules = p[8];
     }
     return permissions;
}