import insertGeneric from "../dataFetch/insertGeneric";
import Form from "../forms_new/Form";
import contractSchema from "./addContract";
import holidayCoreSchema from "./holidayCoreSchema";

export default function EditHolidayCore({
  id,
  employee,
  setUpdates,
  setModal,
  editData,
}: {
  id?: number;
  employee: any;
  setUpdates: Function;
  setModal: Function;
  editData?: any;
}) {
  return (
    <Form
      name="holidayFrom"
      className="holiday-form"
      schema={{
        basic: {
          name: "Dane podstawowe",
          content: holidayCoreSchema(editData, employee),
        },
      }}
      //   auth={auth}
      data={editData ? editData : {}}
      submit={async (data: any) => {
        // alert(JSON.stringify(data));
       
        return await insertGeneric("set-employee-holiday-core", data);
      }}
      sideEffects={() => {
        setUpdates((prev: any) => prev + 1);
        setModal((prev: any) => ({ ...prev, show: false }));
      }}
    />
  );
}
