import { useContext, useEffect, useState } from "react";
import HybridField from "../HybridField";
import { multiAddProps } from "../../formContentType";
import { useFormContext } from "react-hook-form";
import { createGUID } from "../../../functions/tools";
import { FormDataContext } from "../../Form";
import { UserContext } from "../../../contexts/UserContext";
import { ViewContext } from "../../../contexts/ViewContext";
import MultiAddSelect from "./MultiAddSelect";

export default function MultiAddCell({
  schema: schemaPrep,
  fieldValue,
  fieldName,
  colSpan,
  entries,
  setEntries,
  rowIndex,
  rowValues,
  sortCell,
  setRowValues,
  setPrevRowValues,
  multiAddProps,
  usedUniqueOptions,
  setUsedUniqueOptions,
  rowTouched,
  setRowTouched,
  topSchema,
  setRowStyle,
  uniqueID,
}: {
  schema: any;
  fieldValue: any;
  fieldName: string;
  colSpan: number;
  entries: any;
  setEntries: Function;
  rowIndex: number;
  rowValues: any;
  sortCell?: string;
  setRowValues: Function;
  setPrevRowValues: Function;
  multiAddProps?: multiAddProps;
  usedUniqueOptions: any;
  setUsedUniqueOptions: Function;
  rowTouched: number;
  setRowTouched: Function;
  topSchema?: any;
  setRowStyle: Function;
  uniqueID?: Number;
}) {
  const { getValues } = useFormContext();
  // if column schema is a function apply it with row values
  const schema =
    typeof schemaPrep === "function" ? schemaPrep(rowValues) : schemaPrep;

  const disable =
    schema?.disableIf &&
    (typeof schema.disableIf === "function"
      ? schema.disableIf(rowValues)
      : schema.disableIf);

  const [manualUpdate, setManualUpdate] = useState(0);

  const [forceDisabled, setForceDisabled] = useState(disable);

  const parentForm = useFormContext();

  const userContext = useContext(UserContext);

  const { subUpdate, setCustomFieldData } = useContext(FormDataContext);

  const { modal2, setModal2 } = useContext(ViewContext);

  async function setFieldValue(e: any) {
    setPrevRowValues(rowValues);

    let commitChange = true;
    if (multiAddProps && multiAddProps.unique) {
      multiAddProps.unique.forEach((u: string) => {
        if (u === fieldName) {
          if (entries.find((r: any) => r[u] === e.target.value)) {
            commitChange = false;
          }
        }
      });
    }
    if (commitChange) {
      if (e.target.options?.[e.target.selectedIndex]) {
        const id = e.target.options?.[e.target.selectedIndex]?.value;
        const rowStyleData = schema?.optionsList?.[id - 1]?.rowStyle;

        setRowStyle((prev: any) => (rowStyleData ? rowStyleData : {}));
      }
      await setRowValues((prev: any) => {
        if (schema?.selectNameAs) {
          prev[schema?.selectNameAs] =
            e.target.options[e.target.selectedIndex].text;
        }
        const result = {
          ...prev,
          [fieldName]: e.target.value,
        };
        // console.log(result);
        return result;
      });

      setManualUpdate((prev: number) => prev + 1);
      setRowTouched((prev: number) => prev + 1);
      // console.log(rowValues);
    }
  }

  function multiAffect() {
    if (multiAddProps && multiAddProps.affects) {
      const affectedValues = multiAddProps.affects(rowValues);

      delete affectedValues[fieldName];

      let affectedStep = affectedValues;
      for (let i = 0; i < 3; i++) {
        const rowModStep = {
          ...rowValues,
          ...affectedStep,
        };

        affectedStep = multiAddProps.affects(rowModStep);
        const affectedStepFilter: any = {};

        /* if affects property is an array, first item specifies
        which value affects the property (second value) */
        Object.keys(affectedStep).forEach((key: any) => {
          if (Array.isArray(affectedStep[key])) {
            // multiple values
            if (Array.isArray(affectedStep[key][0])) {
              affectedStep[key][0].forEach((i: any) => {
                if (i === fieldName) {
                  affectedStepFilter[key] = affectedStep[key][1];
                }
              });
            } else if (affectedStep[key][0] === fieldName) {
              affectedStepFilter[key] = affectedStep[key][1];
            }
          } else {
            affectedStepFilter[key] = affectedStep[key];
          }
          if (Number.isNaN(affectedStep[key])) {
            affectedStepFilter[key] = "";
          }
        });
        affectedStep = affectedStepFilter;
        delete affectedStep[fieldName];
      }
      // console.log(affectedStep);
      if (rowIndex === 0) {
        //  console.count("affect");
        //    console.log(rowIndex, affectedStep);
      }
      setRowValues((prev: any) => ({
        ...prev,
        ...affectedStep,
      }));
    }
  }

  useEffect(() => {
    // get from parent
    if (schema.linkParent) {
      let value = "";
      schema.linkParent.forEach((link: any) => {
        const { condition, parentField } = link;
        if (condition(rowValues)) {
          value = parentForm.getValues(parentField);
        }
      });
      if (value) {
        //  console.log(fieldName, " < sub-item updated from parent", value);

        setRowValues((prev: any) => ({
          ...prev,
          [fieldName]: value,
        }));
        // multiAffect();
      }
    }
  }, [JSON.stringify(parentForm.getValues())]);

  // useEffect(() => {
  // suggestions?: (values:any, rowValues:any) => JSX.Element | false;
  // SUGGESTIONS
  //   if (schema.suggestions) {

  //   }
  // }, [JSON.stringify(parentForm.getValues()), JSON.stringify(rowValues)])

  useEffect(() => {
    //  update parent on user (manual) change of field
    if (schema.linkParent) {
      schema.linkParent.forEach((link: any) => {
        const { condition, parentField } = link;
        if (condition(rowValues)) {
          //   console.log(fieldName, " > parent updated from sub-item");
          parentForm.setValue(parentField, fieldValue, { shouldTouch: false });
        }
      });
    }
    if (schema.clearOnChange && manualUpdate) {
      setRowValues((prev: any) => {
        const cleared: any = {};
        Object.keys(prev).forEach((key: any) => {
          cleared[key] = "";
        });
        return {
          ...cleared,
          [fieldName]: fieldValue,
        };
      });
    }
  }, [manualUpdate]);

  useEffect(() => {
    if (manualUpdate > 0) {
      const waitForUpdate = setTimeout(() => multiAffect(), 60);

      return () => clearTimeout(waitForUpdate);
    }
  }, [manualUpdate]); //, subUpdate]);

  useEffect(() => {
    if (schema.linkParent && subUpdate > 0) {
      const waitForUpdate = setTimeout(() => multiAffect(), 60);
      return () => clearTimeout(waitForUpdate);
    }
  }, [subUpdate]);

  /// WHEN ROW HAS BEEN EDITED
  useEffect(() => {
    // const order = multiAddProps?.order ? { order: rowIndex } : {};
    // console.log(order);
    if (schema.fillUser && rowTouched > 0) {
      setRowValues((prev: any) => ({
        ...prev,
        [fieldName]: schema.fillUser(userContext),
        // ...order
      }));
    }
    if (
      schema.onEdit &&
      rowTouched > 0 &&
      schema.onEdit(rowValues) !== undefined
    ) {
      setRowValues((prev: any) => ({
        ...prev,
        [fieldName]: schema.onEdit(rowValues),
        // ...order
      }));
    }
    if (disable) {
      if (disable) {
        setForceDisabled(true);
      } else {
        setForceDisabled(false);
      }
    }
  }, [rowTouched]);

  function sortRows(e: any) {
    if (multiAddProps?.sort?.cell === fieldName) {
      setEntries((prev: any[]) => {
        if (multiAddProps?.sort?.callBack) {
          let mod;
          prev.sort(multiAddProps.sort.callBack);
          mod = prev.map((item: any) => ({ ...prev /*guid: createGUID()*/ }));
          console.log(mod);
        }

        return prev;
      });
    }
  }

  let suggestionBox = <></>;

  if (schema.suggestions) {
    // suggestions?: (values:any, rowValues:any) => JSX.Element | false;

    const suggestions = schema.suggestions(getValues(), rowValues);
    suggestionBox = suggestions && (
      <div>
        {suggestions?.map((s: any) => (
          <div
            className="suggestion-box-single"
            onClick={() => {
              setRowValues((prev: any) => ({
                ...prev,
                [fieldName]: s?.value,
              }));

              setManualUpdate((prev: number) => prev + 1);
              setRowTouched((prev: number) => prev + 1);
            }}
          >
            <span>{s?.value}</span>{" "}
            {suggestions?.length > 1 ? (
              <span className="suggestion-date">{s?.dateShort}</span>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>
    );
  }

  const actualInput = schema?.index ? (
    <div>{rowIndex + 1}</div>
  ) : !schema.element ? (
    <input
      value={!schema?.disabled && !fieldValue ? null : fieldValue}
      onChange={setFieldValue}
      onBlur={sortRows}
      className={schema?.inputClass}
      placeholder={!fieldValue ? "-" : schema?.placeholder}
      type={schema.type ? schema.type : "text"}
      readOnly={
        topSchema.readOnly
          ? true
          : forceDisabled
          ? forceDisabled
          : schema.disabled
          ? true
          : false
      }
      disabled={schema.type === "checkbox" ? schema?.disabled : undefined}
      onWheel={(e: any) => e.target.blur()}
      style={schema?.inputStyle}
      step={schema?.step ? schema.step : 1}
      min={schema.type === "number" ? 0 : undefined}
      max={
        schema.max
          ? typeof schema.max === "function"
            ? schema.max(rowValues)
            : schema.max
          : undefined
      }
      checked={schema.type === "checkbox" ? fieldValue : undefined}
    />
  ) : schema.element === "select" ? (
    <MultiAddSelect
      fieldValue={fieldValue}
      fieldName={fieldName}
      setFieldValue={setFieldValue}
      sortRows={sortRows}
      schema={schema}
      usedUniqueOptions={usedUniqueOptions}
      blankName={schema?.blankName}
      readOnly={topSchema.readOnly}
    />
  ) : schema.element === "textarea" ? (
    <textarea
      value={fieldValue}
      style={{
        ...schema?.inputStyle,
        height:
          fieldValue?.length > 119 ? 60 : fieldValue?.length > 60 ? 42 : 26,
        resize: fieldValue?.length > 100 ? "vertical" : "none",
      }}
      className={schema?.inputClass}
      placeholder={schema?.placeholder}
      onChange={setFieldValue}
      rows={3}
      readOnly={topSchema.readOnly}
    />
  ) : schema.element === "hybrid" && (disable ? !disable : true) ? (
    <HybridField
      key={schema.name + rowIndex}
      schema={{
        ...schema.schema,
        name: schema.name + rowIndex,
        readOnly: topSchema?.readOnly,
      }}
      subData={rowValues}
      setSubData={setRowValues}
    />
  ) : schema.element === "dataSelector" && (disable ? !disable : true) ? (
    <button
      type="button"
      className="more-details-button"
      onClick={() =>
        setModal2({
          show: true,
          style: {
            width: "600px",
          },
          heading: schema.windowHeading,
          content: schema.dataSelectorElement(
            rowValues[fieldName],
            setRowValues
          ), //dataSelector
        })
      }
    >
      =
    </button>
  ) : (
    <></>
  );

  return schema.hidden ? (
    <></>
  ) : multiAddProps?.compact ? (
    <div key={schema.name} data-label={schema.name} style={schema.style}>
      {actualInput}
    </div>
  ) : (
    <td
      key={schema.name}
      data-label={schema.name}
      colSpan={colSpan}
      style={schema.style}
      className={
        schema?.className +
        (schema?.linkParent?.find((link: any) => link.condition(rowValues))
          ? " linked"
          : "") +
        (manualUpdate ? " edited" : "")
      }
    >
      {actualInput}
      <div className="suggestion-box">{suggestionBox}</div>
    </td>
  );
}
