import prettyDate from "../functions/prettyDate";
import { roomBookings } from "./RoomsView";

export default function RoomDetails({
  d,
  b,
  bl,
}: {
  d: any;
  b: any;
  bl: Boolean;
}) {
  const booking = !bl ? roomBookings(b, d)?.[0] : undefined;
  const b_checkIn =
    booking?.data_od?.slice(0, 10) + " " + booking?.godzina_od?.slice(0, 5);
  const b_checkOut =
    booking?.data_do?.slice(0, 10) + " " + booking?.godzina_do?.slice(0, 5);
  console.log(b_checkIn);
  const avail = !d?.status?.match(/zajety/);

  return (
    <div style={{ width: "100%" }}>
      <div className="room-detailed-view">
        <div>
          <div>Pokój</div>
          <div>
            <span style={{ fontWeight: "bold" }}>{d?.numer}</span> {" > "}
            {d.rodzaj_pokoju ? d.rodzaj_pokoju + " / " : ""}
            {d.rodzaj}{" "}
            <span
              style={{
                fontSize: "11px",
                display: "inline-block",
                float: "right",
                padding: "5px",
              }}
            >
              {d.ilosc_lozek} łóż. / {d.ilosc_miejsc} m.
            </span>
          </div>
        </div>
        <div>
          <div>Status</div>
          <div>
            {d.status === "zajety" ? (
              <div>
                <span style={{ color: "orangered" }}>⬤</span> zajęty
              </div>
            ) : d.status === "wolny - brudny" ? (
              <div>
                <span style={{ color: "yellow" }}>⬤</span> {d.status}
              </div>
            ) : d.status === "wolny - czysty" ? (
              <div>
                <span style={{ color: "green" }}>⬤</span> {d.status}
              </div>
            ) : (
              <>{d.status}</>
            )}{" "}
          </div>
        </div>
        {d.data_od || d.data_do || (!bl && booking)  ? (
          <div>
            <div>Meldunek</div>
            <div>
              <div>
                <span
                  style={{
                    display: "inline-block",
                    color: "green",
                    fontSize: "70%",
                    width: "90px",
                  }}
                >
                  ▲ check-in
                </span>
                {prettyDate(
                  avail && booking ? b_checkIn : d.data_od,
                  "dziś, ",
                  false,
                  {
                    weekday: "long",
                  }
                )}
              </div>
              <div>
                <span
                  style={{
                    display: "inline-block",
                    color: "brown",
                    fontSize: "70%",
                    width: "90px",
                  }}
                >
                  ▼ check-out
                </span>
                {prettyDate(
                  avail && booking ? b_checkOut : d.data_do,
                  "",
                  false,
                  {
                    weekday: "long",
                  }
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {d.first_name || d.last_name || booking?.gosc ? (
          <div>
            <div>Klient</div>
            <div className="guest-full-name" style={{ fontStyle: "italic" }}>
              {booking?.gosc ? (
                booking.gosc
              ) : (
                <>
                  {d.first_name?.toLowerCase()}
                  &nbsp;
                  {d.last_name?.toLowerCase()}
                </>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
        {d.goscie ? (
          <div>
            <div>Goście</div>
            <div>
              {d.goscie.split(",")?.map((g: string) => {
                const first_name = g.split(" ")?.[0];
                const last_name = g.split(" ")?.[1];
                return (
                  <div
                    className="guest-full-name"
                    style={{ fontStyle: "italic" }}
                  >
                    {first_name?.toLowerCase()}
                    &nbsp;
                    {last_name?.toLowerCase()}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <></>
        )}
        {d.nr_rezerwacji || booking?.numer_rezerwacji ? (
          <div>
            <div>Nr rezerwacji</div>
            <div>{avail && booking?.numer_rezerwacji ? booking?.numer_rezerwacji : d.nr_rezerwacji}</div>
          </div>
        ) : (
          <></>
        )}
        {d.ilosc_osob ? (
          <div>
            <div>Dorośli</div>
            <div>{d.ilosc_osob}</div>
          </div>
        ) : (
          <></>
        )}
        {d.ilosc_dzieci ? (
          <div>
            <div>Dzieci</div>
            <div>{d.ilosc_dzieci}</div>
          </div>
        ) : (
          <></>
        )}
        {d.cena ? (
          <div>
            <div>Cena</div>
            <div>{Math.round(d.cena * 100) / 100}</div>
          </div>
        ) : (
          <></>
        )}
        {d.uwaga || booking?.uwaga ? (
          <div>
            <div>Uwaga</div>
            <div style={{ fontSize: "80%" }}>{d.uwaga ? d.uwaga : booking?.uwaga}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
