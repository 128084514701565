import { useContext } from "react";
import { RegisterViewContext } from "./View";
import editIcon from "../../images/icons/pencil.png";
import trashIcon from "../../images/icons/trash.svg";
import restoreIcon from "../../images/icons/restore.svg";
import setActive from "../dataFetch/setActive";
import { ItemContext } from "./ViewItem";
import { ViewContext } from "../contexts/ViewContext";
import { useNavigate } from "react-router-dom";

function Wrap({
  type,
  className,
  children,
}: {
  type: "div" | "td";
  className: string;
  children: JSX.Element;
}) {
  return type === "div" ? (
    <div className={className}>{children}</div>
  ) : type === "td" ? (
    <td className={className}>{children}</td>
  ) : (
    <></>
  );
}

export default function Options({
  row,
  type,
  addSchema,
  addFunctions,
}: {
  row: any;
  type: "table" | "cards";
  addSchema?: any;
  addFunctions?: any;
}) {
  const {
    rows,
    auth,
    schema,
    rowSchema,
    setOrderASC,
    setOrderColumn,
    orderASC,
    orderColumn,
    editor,
    setEditor,
    setEditData,
    setUpdates,
    functions,
    source,
    expanded,
    setExpanded,
  } = useContext(RegisterViewContext);

  const { modal, setModal } = useContext(ViewContext);

  const { activeFilter } = useContext(RegisterViewContext);
  const { subRow, setSubRow } = useContext(ItemContext);
  const { options, name, edit, remove } =
    addSchema && addFunctions
      ? addSchema(row, addFunctions, auth)
      : schema(row, functions, auth);
  const navigate = useNavigate();
  return (
    <Wrap type={type === "table" ? "td" : "div"} className="row-options-cell">
      <div
        className="row-options"
        style={{ opacity: editor ? ".2" : "initial" }}
      >
        {!auth?.[name] || auth?.[name] > 1 ? (
          <>
            {edit === false ? (
              <></>
            ) : (
              <img
                onClick={() => {
                  if (editor) return;
                  setEditData(row);
                  setEditor(true);
                }}
                src={editIcon}
              />
            )}
            {remove === false || activeFilter === false ? (
              <></>
            ) : (
              <img
                onClick={async () => {
                  if (editor) return;
                  if (
                    window.confirm(
                      "Czy na pewno chcesz " +
                        (row?.active
                          ? "wyłączyć pozycję"
                          : "przywrócić pozycję") +
                        "?"
                    )
                  ) {
                    await setActive(row.id, row.active ? "1" : "0", source);
                    setUpdates((prev: number) => prev + 1);
                  }
                }}
                src={row.active ? trashIcon : restoreIcon}
              />
            )}
          </>
        ) : (
          <></>
        )}
        {options ? (
          <div className="row-options additional">
            {options &&
              options?.map((option: any) => (
                <>
                  {option?.icon ? (
                    <img
                      style={option?.style}
                      onClick={() => {
                        if (option?.linkType === "below") {
                          setSubRow(option?.target(row));
                          setExpanded((prev: any) =>
                            prev != row?.id ? row?.id : undefined
                          );
                        } else if (option?.linkType === "modal") {
                          setModal({
                            heading: option?.title,
                            content: option?.target(row),
                            show: true,
                          });
                        } else if (option?.linkType === "route") {
                          navigate("./" + Number(row.id));
                        }
                      }}
                      src={option.icon}
                    />
                  ) : (
                    <>{option?.number}</>
                  )}
                </>
              ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </Wrap>
  );
}
