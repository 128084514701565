import Loading from "../common/Loading";
import useGeneric from "../dataFetch/useGeneric";
import Value from "../employeeFiles/Value";
import prettyDate from "../functions/prettyDate";
import prettyMoney from "../functions/prettyMoney";
import prettyNumber from "../functions/prettyNumber";
import { groupByMulti } from "../functions/tools";
import trailingZero from "../functions/trailingZero";
import InvoicePrint from "./InvoicePrint";
import invoiceTypes from "./Print/invoiceTypes";
import PaymentQR, { usePaymentQRvalue } from "./PaymentQR";
import { useContext, useState } from "react";
import { UserContext } from "../contexts/UserContext";

export function groupInvoiceItems(item: invoiceTypes[]) {
  let itemGroups =
    item &&
    groupByMulti(item, (i: invoiceTypes) => [
      i?.type,
      i?.description,
      i?.item_gross,
      i?.jm,
    ]);

  itemGroups =
    item &&
    itemGroups
      ?.map((g: any) => {
        const sum_net = g
          ?.map(
            (n: any) => Number(n?.item_gross) / (1 + Number(n?.itemVAT) / 100)
          ) // Number(n?.item_net))
          ?.reduce((a: number, b: number) => a + b, 0);

        const sum_cor_net = g
          ?.map(
            (n: any) =>
              Number(n?.item_cor_gross) / (1 + Number(n?.item_cor_vat) / 100)
          )
          ?.reduce((a: number, b: number) => a + b, 0);

        return {
          ...g?.[0],
          amount: g
            ?.map((n: any) => Number(n?.amount))
            ?.reduce((a: number, b: number) => a + b, 0),
          sum_net: sum_net,
          sum_gross: g
            ?.map((n: any) => Number(n?.item_gross))
            ?.reduce((a: number, b: number) => a + b, 0),
          sum_cor_net: sum_cor_net,
          sum_cor_gross: g
            ?.map((n: any) => Number(n?.item_cor_gross))
            ?.reduce((a: number, b: number) => a + b, 0),
        };
      })
      ?.flat();
  return itemGroups;
}

export default function LSIinvoiceView({
  //   a,
  //   item,
  id,
  userID,
}: {
  //   a?: any;
  //   item?: any;
  id?: number;
  userID?: number;
}) {
  const f = useGeneric({
    name: /*!(a && item) &&*/ id ? "lsi_invoice_query" : "",
    query: {
      scid: id,
    },
    groupBy: (v: any) => (v?.SCID ? v.SCID : v.id),
  });
 

  const item = !f.loading && f?.rows?.[0];

  const itemGroups = groupInvoiceItems(item);

  const a = item?.[0];
  // console.log(item, a);

  if (a) {
    const docName = `${a?.DocNo}/${trailingZero(a?.month, 2)}/${a?.year}/H`;
    a.document_name_full =
      a?.do_paragonu == 1
        ? `(do paragonu) ${docName}`
        : a?.type == 0
        ? `FV/${docName}`
        : a?.type == 3
        ? `Kor FV/${docName}`
        : "";
  }

  return (
    item &&
    a && (
      <div className="lsi-invoice-view-full">
        <div style={{ background: "rgb(254,254,254)" }}>
          <div
            style={{
              fontSize: "13px",

              background: "rgb(240,240,240)",
            }}
          >
            <div
              style={{
                background: "white", //"#d5d5d5",
                // boxShadow: "0 0 4px rgba(0,0,0,.3)",
                display: "grid",
                gridGap: "8px",
                gridTemplateColumns: "max-content 1fr",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "12px 12px",
              }}
            >
              <div>
                <span style={{ fontSize: "130%" }} title={a?.SCID}>
                  {a?.document_name_full}
                  {/* FV/{a.DocNo}/{trailingZero(a?.month, 2)}/{a.year}/H */}
                </span>
              </div>
              <div style={{ textAlign: "right" }}>{a.ko_firma}</div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1.5fr repeat(3,1fr)",
                gridGap: "7px",
                padding: "21px 12px",
              }}
            >
              <div>
                Nr rezerwacji <strong>{a.numer_rezerwacji}</strong>
              </div>
              <div>
                Pokój{" "}
                <strong>{a.numer_pokoju ? a.numer_pokoju : <>-</>}</strong>
              </div>
              <div>
                Przyjazd <strong>{prettyDate(a.przyjazd, "", true)}</strong>
              </div>
              <div>
                Wyjazd <strong>{prettyDate(a.wyjazd, "", true)}</strong>
              </div>
            </div>
            {a.Uwagi ? (
              <div style={{ padding: "7px 12px" }}>Uwagi: {a.Uwagi}</div>
            ) : (
              <></>
            )}
          </div>
          <div style={{ padding: "20px" }}>
            <table className="data-table invoice-table shrink-mobile">
              <thead>
                <tr>
                  <th>Lp.</th>
                  <th>Nazwa</th>
                  <th>jm</th>
                  <th>Ilość</th>
                  <th>Cena jedn. brutto</th>
                  <th>Wartość netto</th>
                  <th>VAT</th>
                  <th>Wartość brutto</th>
                </tr>
              </thead>
              <tbody>
                {itemGroups?.map((i: any, index: number) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td style={{ fontSize: "70%" }}>{i.description}</td>
                    <td>{i.jm}</td>
                    <td>{i.amount}</td>

                    <td title={i?.item_net}>
                      {prettyMoney(i.item_unit_gross, {
                        round: 2,
                        // postfix: "zł",
                      })}
                    </td>
                    <td>
                      {prettyMoney(i.sum_net, {
                        round: 2,
                        // postfix: "zł",
                      })}
                    </td>
                    <td>{Math.round(Number(i?.VAT))}</td>
                    <td>
                      {prettyMoney(i.sum_gross, {
                        round: 2,
                        // postfix: "zł",
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
              {/* <tfoot>
                <tr className="invoice-sums">
                  <td>Suma</td>
                  <td></td>

                  <td></td>
                  <td>
                    {item
                      ?.map((s: any) => Number(s?.amount))
                      ?.reduce((a: number, b: number) => a + b, 0)}
                  </td>
                  <td></td>
                  <td>
                    {prettyMoney(
                      itemGroups
                        ?.map((s: any) => Number(s?.sum_net))
                        ?.reduce((a: number, b: number) => a + b, 0),
                      {
                        round: 2,
                        // postfix: "zł",
                      }
                    )}
                  </td>
                  <td></td>
                  <td>
                    {prettyMoney(a.ValueB, {
                      round: 2,
                      // postfix: "zł",
                    })}
                  </td>
                </tr>
              </tfoot> */}
            </table>
            <div className="invoice-sums-box">
              <div>
                <Value
                  label="Suma netto"
                  data={prettyMoney(
                    itemGroups
                      ?.map((s: any) => Number(s?.sum_net))
                      ?.reduce((a: number, b: number) => a + b, 0),
                    {
                      round: 2,
                      // postfix: "zł",
                    }
                  )}
                  isEditing={false}
                  editor={false}
                />
                <Value
                  label="Suma brutto"
                  data={prettyMoney(a.ValueB, {
                    round: 2,
                    // postfix: "zł",
                  })}
                  dataClass="gross"
                  isEditing={false}
                  editor={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style={{ display: "flex" }}>
            {/* <PaymentQR
              amount={a.ValueB}
              title={`FV/${a.DocNo}/${a.month}/${a.year}/H`}
            /> */}

            <InvoicePrint
              item={item}
              itemGroups={itemGroups}
              invoice={a}
              userID={userID}
            />
          </div>
        </div>
      </div>
    )
  );
}
