import insertGeneric from "../../../dataFetch/insertGeneric";
import Form from "../../../forms_new/Form";
import { registerSchema } from "../schemaType";

export function schema(data: any, functions: any): registerSchema {
  return {
    timeline: true,
    activeFilter: false,
    preSort:
      data && data?.length &&
      data
        ?.map((item: any) => ({
          ...item,
          active: 1,
          order: item?.checkinowana == 1 ? 0 : 1,
          order2: item?.numer_rezygnacji ? 1 : 0,
        }))
        ?.sort((a: any, b: any) => a.order - b.order)
        ?.sort((a: any, b: any) => a.order2 - b.order2),
    filtersConfirm: true,
    filters: [
      {
        column: "numer",
        name: "Pokój",
        suggestions: [
          101, 102, 103, 105, 106, 107, 108, 109, 110, 111, 112, 115, 201, 202,
          203, 205, 206, 207, 208, 209, 210, 121, 122, 123, 125, 126, 127, 128,
          129, 221, 222, 223, 225, 226, 227, 228, 229, 211, 212, 218, 219,
        ],
        // element: "select",
        // getOptionsFrom: "rooms",
      },
    ],
  };
}
