import BooleanSymbol from "../viewElements/BooleanSymbol";

export default function employeesView(item: any) {
  const columns = [
    {
      ref: "id",
      name: "Lp.",
      jsx: <>{item.id}</>,
      style: { width: "40px" },
    },
    {
      ref: "initials",
      name: "Inicjały",
      jsx: <>{item.initials}</>,
      style: { width: "40px", fontWeight: "bold" },
    },
    {
      ref: "name",
      name: "Imię i nazwisko",
      style: { textAlign: "left", fontSize: "15px" },
      styleHeading: { textAlign: "left" },
      jsx: (
        <>
          {item.first_name} {item.last_name}
        </>
      ),
    },  
    { ref: "group", name: "Grupa", jsx: <>{item.group_name}</> },
    { ref: "location", name: "Dział", jsx: <>{item.location}</> },
    {
      ref: "active",
      name: "Aktywne",
      style: { width: "20px", cursor: "pointer" },
      jsx: <BooleanSymbol value={item.active} />,
    },
  ];
  return { columns: columns };
}
