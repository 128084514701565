import { useContext } from "react";
import { displayStyles } from "./types";
import { CollectionContext } from "./CollectionContext";

export default function ItemWrap({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const { displayStyle } = useContext(CollectionContext);
  return displayStyle === "table" ? <tr>{children}</tr> : <div>{children}</div>;
}
