import HolidayCell from "./HolidayCell";

export default function SumCell({
  data,
  holidayCell,
}: {
  data: any;
  holidayCell: any;
}) {
  const {
    pair,
    index,
    nextIn,
    nextIn2,
    isEnterToNext,
    wasEnterToNext,
    isExitFromLast,
  } = data;
  console.log(nextIn2?.[0]?.entries);
  return pair && pair.entries ? (
    <div className="sumTime" style={{ textAlign: "left" }}>
      {holidayCell}
      {pair.entries.length === 1 &&
      (pair.entries[0].type === 1 ||
        pair.entries[0].type === 3 ||
        pair.entries[0].type === 5) &&
      !isExitFromLast ? (
        <span className="questionMark">
          {index === 0 ? (
            <span style={{ fontSize: "11px", fontWeight: "normal" }}>
              poprzedni m-c (?)
            </span>
          ) : (
            <>?</>
          )}
        </span>
      ) : (
        <></>
      )}
      {pair.timeBetween}{" "}
      {nextIn && isEnterToNext && nextIn[0] && nextIn[0].timeBetween ? (
        <div>{nextIn[0].timeBetween}</div>
      ) : (
        <></>
      )}
      {nextIn2 &&
      isEnterToNext &&
      wasEnterToNext &&
      nextIn2[0] &&
      nextIn2[0].timeBetween ? (
        <div
          style={{ textDecoration: "overline", textDecorationColor: "rgb(200,200,200)" }}
        >
          {nextIn2[0].timeBetween}
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
}
