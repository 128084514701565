import { Formik, Form, Field } from "formik";
import { useState } from "react";
import FieldAndLabel from "../forms/FieldAndLabel";
import { URL } from "../paths";
import logo from "../../images/agit.svg";

export default function Login({ set, status }: { set: any; status: boolean }) {
  const [attemptResult, setAttemptResult] = useState(false);
  const [comment, setComment] = useState("");
  async function log(username: string, password: string) {
    const response = await fetch(URL + "backend/login/login.php", {
      method: "POST",
      body: JSON.stringify({
        login: username,
        password: password,
      }),
      credentials: "include",
    });
    const result = await response.json();
    setAttemptResult(true);
    setComment(result?.comment);
    set((prev: number) => prev + 1);
    // window.setTimeout(() => window.location.reload(), 500);
    //return result;
  }

  return (
    <div className="loginWrap">
      <img className="agit-logo" src={logo}/>
      <div className="loginWindow">
        <div className="loginHeader"></div>
        <div className="loginContent">
          
          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            onSubmit={(values) => log(values.username, values.password)}
          >
            <Form>
              <FieldAndLabel name="username" label="Użytkownik" />
              <FieldAndLabel name="password" label="Hasło" type="password" />
              <button type="submit">Zaloguj się</button>
            </Form>
          </Formik>
          <div className="error">
            { 
            attemptResult ? <>{comment}</> : <></> 
            }
          </div>
        </div>
      </div>
    </div>
  );
}
