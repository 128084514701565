import { datesSince } from "../functions/timeAgo";
import Value from "./Value";

export default function ContractMandateInfo({ contract }: { contract: any }) {
  let y26;
  if (contract?.birthday) {
    y26 = new Date(contract.birthday);
    y26.setFullYear(y26.getFullYear() + 26);
  }
  console.log("y26", y26, contract);

  return Number(contract?.contract_type) === 2 ? (
    <>
      <Value
        label="Data urodzenia"
        name="birthday"
        data={
          <div>
            <div>{new Date(contract?.birthday)?.toLocaleDateString()}</div>
            <div style={{fontSize:"86%"}}>
              {!datesSince(new Date(), y26)?.text
                ? "Ukończone 26 lat"
                : "26 lat za " + datesSince(new Date(), y26)?.text}
            </div>
          </div>
        }
        isEditing={false}
        editor="date"
      />
      <Value
        label="Data ważności legitymacji studenckiej"
        name="student_id_until"
        data={contract?.student_id_until && contract?.student_id_until != "0000-00-00" && new Date(contract?.student_id_until)?.toLocaleDateString()}
        isEditing={false}
        editor="date"
      />
      <Value
        label="Status studenta do"
        name="student_status_file_until"
        data={contract?.student_status_file_until && contract?.student_status_file_until != "0000-00-00" && new Date(
          contract?.student_status_file_until
        )?.toLocaleDateString()}
        isEditing={false}
        editor="date"
      />
    </>
  ) : (
    <></>
  );
}
