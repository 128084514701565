import generateCalendarData from "../calendar/generateCalendarData";
import React from "react";
import Info from "../common/Info";
import AddMissing from "./AddMissing";
import AttendanceEvents from "./table/AttendanceEvents";
import AttendanceRow from "./table/AttendanceRow";
import exportToExcel from "../../file_generation/exportToExcel";

export default function AttendanceTable({
  selectedPerson,
  selectedEmployeeData,
  rows,
  loading,
  calendar,
  date,
  update,
  setUpdate,
  setSmallUpdate,
  setActive,
  holidays,
  holidaysSuggested,
  location,
}: {
  selectedPerson: string | false;
  selectedEmployeeData: any | false;
  rows: any;
  loading: boolean;
  calendar: any;
  date: { month: number; year: number };
  update: number;
  setUpdate: any;
  setSmallUpdate: any;
  setActive: any;
  holidays: any[] | undefined;
  holidaysSuggested: any[] | undefined;
  location: string;
}) {
  function filterByDate(datum: Date, items: any) {
    //console.log(datum, items);

    return items
      .map((b: any, index: number) => ({ ...b, gi: index }))
      .filter(
        (i: any) =>
          datum.getDate() === new Date(i.first).getDate() &&
          datum.getMonth() === new Date(i.first).getMonth() &&
          datum.getFullYear() === new Date(i.first).getFullYear()
      );
  }

  function generateXLSX(data: any) {
    console.log(data);
    let rows = data.map((i: any, ind: number) => ({
      Dzień: { v: i.datum.obj.toISOString(), t: "d" },
      Przyjście: {
        v:
          i.items &&
          i.items[0]?.entries
            .filter((e: any) => e.type === 0)?.[0]
            ?.date.toLocaleTimeString("pl-PL", {
              hours: "numeric",
              minutes: "numeric",
            }),
        t: "n",
      },
      Wyjście: {
        v:
          i.items &&
          i.items[0]?.entries
            .filter((e: any) => e.type === 1)?.[0]
            ?.date.toLocaleTimeString("pl-PL", {
              hours: "numeric",
              minutes: "numeric",
            }),
        t: "n",
      },
      "Czas pracy": { f: `=C${ind+2}-B${ind+2}`, t: "n" },
    }));

    exportToExcel({
      fileName: "attendance",
      workBookName: "att1",
      rows: rows,
      colStyles: [{ width: 10 }, { width: 9 }, { width: 9 }, { width: 12 }],
      colFormatData: {
        columns: [3],
        string: `h "h" m "min";"";""`,
      },
    });
  }

  function addItemsToDates(dates: Date[], items: any) {
    //console.log(datum, items);
    return dates.map((d: any) => {
      const datesWithEvents = items.filter(
        (i: any, index: number) =>
          new Date(i.first).getDate() === d.obj.getDate() &&
          new Date(i.first).getMonth() === d.obj.getMonth() &&
          new Date(i.first).getFullYear() === d.obj.getFullYear()
      );
      return {
        datum: d,
        items: datesWithEvents.length
          ? datesWithEvents
          : {
              person:
                items && items[0]
                  ? items[0].detailed.cardperson
                  : selectedPerson,
              detailed: {
                carduserid:
                  items && items[0] ? items[0].detailed.carduserid : null, //null TEMPORARY, MUST GET THIS DATA
                cardname: items && items[0] ? items[0].detailed.cardname : null, //null TEMPORARY, MUST GET THIS DATA
              },
            },
      };
    });
  }
  //  console.log(addItemsToDates(calendar.dates, rows));

  if (selectedPerson) {
    rows = rows.filter((row: any) => row.person === selectedPerson);
  }
  const attendanceByDate = addItemsToDates(calendar.dates, rows);

  return !loading ? (
    selectedPerson ? (
      <table className="data-table attendanceTable">
        <thead /*onClick={() => generateXLSX(attendanceByDate)}*/>
          <tr>
            <th style={{ width: "75px" }}>Dzień</th>
            <th style={{ width: "75px" }}>Przyjście</th>
            <th style={{ width: "75px" }}>Wyjście</th>
            <th style={{ minWidth: "120px", textAlign: "left" }}>Czas pracy</th>
          </tr>
        </thead>
        <tbody>
          {attendanceByDate.map(
            ({ datum, items }: any, index: number, arr: any[]) => (
              <AttendanceRow
                key={index}
                datum={datum}
                items={items}
                index={index}
                arr={arr}
                update={update}
                setUpdate={setUpdate}
                setSmallUpdate={setSmallUpdate}
                setActive={setActive}
                holidays={holidays}
                holidaysSuggested={holidaysSuggested}
                employeeData={selectedEmployeeData}
                location={location}
              />
            )
          )}
        </tbody>
      </table>
    ) : (
      <Info type="pickPerson" />
    )
  ) : (
    <>Wczytywanie</>
  );
}
