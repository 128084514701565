import { useEffect, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import Unit from "./Unit";
import Editables from "./Editables";
import EditableItem from "./EditableItem";
import insertGeneric from "../dataFetch/insertGeneric";
import addIcon from "../../images/icons/add.svg";
import { updateOrder } from "../MenuEditor";

export default function MenuCategories({ kind }: { kind: "meals" | "drinks" }) {
  const [updates, setUpdates] = useState(0);

  const { loading, rows } = useGeneric({
    name: "restaurant-menu-categories",
    update: kind + updates,
    info: kind,
  });

  const [order, setOrder] = useState(rows?.map((d: any) => d.id));

  const [orderChanges, setOrderChanges] = useState(0);
  useEffect(() => {
    if (!loading) {
      setOrder(rows?.map((d: any) => d.id));
    }
  }, [loading]);

  useEffect(() => {
    if (orderChanges > 0) {
      const d: any = [];
      order.forEach((o: any) => {
        d.push(rows?.find((item: any) => item?.id === o));
      });

      //   setOrderedData(d);

      updateOrder("update-menu-categories-order", setUpdates, order);
    }
  }, [orderChanges]);

  async function addItem() {
    if (window.confirm("Czy chcesz dodać nową kategorię do karty?")) {
      await insertGeneric("add-menu-category", {
        category_order:
          Math.max(...rows?.map((r: any) => r?.category_order)) + 1,
        kind: kind,
      });

      setUpdates((prev: number) => prev + 1);
    }
  }

  return (
    <Editables>
      {/* <>{JSON.stringify(order)}</> */}
      <div onClick={async () => addItem()} className="menu-add-button">
        <img src={addIcon} width={20} />
        Dodaj nową kategorię menu
      </div>
      <div className="entry-editor">
        <div className="entry-editor-unit heading menu-categories">
          <div></div>
          {/* <div>ID</div> */}
          <div>Nazwa PL</div>
          <div>Uwaga PL</div>
          <div>Nazwa EN</div>
          <div>Uwaga EN</div>
          <div>Aktywna</div>
        </div>
        {rows &&
          rows?.map((c: any, index: number) => (
            <Unit
              classNames={"menu-categories"}
              data={c}
              setUpdates={setUpdates}
              index={index}
              key={c?.id}
              setActiveDB={
                kind === "drinks" ? "menuDrinkCategory" : "menuCategory"
              }
              deleteDB="remove-restaurant-menu-category"
              kind={kind}
              order={order}
              setOrder={setOrder}
              setOrderChanges={setOrderChanges}
              length={rows?.length}
            >
              {/* <EditableItem
                data={c}
                name="id"
                locked={true}
                updateDB="update-menu-categories"
              /> */}
              <EditableItem
                data={c}
                kind={kind}
                name="name_pl"
                updateDB="update-menu-categories"
              />
              <EditableItem
                data={c}
                kind={kind}
                name="comment_pl"
                updateDB="update-menu-categories"
              />
              <EditableItem
                data={c}
                kind={kind}
                name="name_en"
                updateDB="update-menu-categories"
              />
              <EditableItem
                data={c}
                kind={kind}
                name="comment_en"
                updateDB="update-menu-categories"
              />
            </Unit>
          ))}
      </div>
    </Editables>
  );
}
