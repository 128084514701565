import { ErrorMessage, Field, useFormikContext } from "formik";
import { useContext, useState } from "react";
import Loading from "../common/Loading";
import { FormContext } from "../tables/EditErrorEntry";
import HybridField from "./HybridField";

export default function FieldAndLabel({
  name,
  label,
  optionData,
  as,
  type = "text",
  description = "",
  disabled = false,
  fieldStyle,
  format = undefined,
  autoFocus = false,
  minLength,
  visibleIf,
  db,
  query,
  resultElement,
  resultUpdate,
}: {
  name: string;
  label: string | JSX.Element | undefined;
  optionData?: { id: string | number; name: string; active: string }[];
  as?: string | false;
  type?: string;
  description?: string | JSX.Element;
  disabled?: boolean;
  fieldStyle?: React.CSSProperties;
  format?: string | undefined;
  autoFocus?: boolean;
  minLength?: number;
  visibleIf?:
    | {
        [index: string]: string | number;
      }
    | undefined;
  db?: string;
  query?: Function;
  resultElement?: Function;
  resultUpdate?: Function;
}) {
  /*return <div>
    <label>{label}</label>
    <div style={{color:"grey"}}>{name}</div>
  </div>*/

  const context = useContext(FormContext);
  const { values }: { values: any } = useFormikContext();
  const isDisabled = disabled || context ? context.disabled : false;

  let conditionalDisplay = true;

  if (visibleIf) {
    conditionalDisplay = Object.keys(visibleIf)
      .map((key) => {
        return values[key] == visibleIf[key]
      })
      .reduce((a, b) => a || b, false);
    //console.log(conditionalDisplay);
  }
  if (as === false && conditionalDisplay) {
    return description ? (
      <div className="field-description">{description}</div>
    ) : (
      <></>
    );
  }

  return (
    <div
      className={
        "field" +
        (type === "checkbox" ? " checkbox" : "") +
        (format ? " " + format : "")
      }
      style={{
        display: type === "hidden" || !conditionalDisplay ? "none" : undefined,
      }}
    >
      {type !== "hidden" ? (
        <label style={{ display: "block" }} htmlFor={name}>
          {label}
        </label>
      ) : (
        <></>
      )}
      {as === "hybrid" && db && query && resultElement && resultUpdate ? (
        <HybridField
          name={name}
          type={type}
          db={db}
          query={query}
          resultElement={resultElement}
          resultUpdate={resultUpdate}
          minLength={minLength}
        />
      ) : as === "select" && optionData && optionData.length === 0 ? (
        <div className="loadingOptions">
          <Loading little={true} />
        </div>
      ) : (
        <>
          <Field
            as={as}
            type={type}
            name={name}
            disabled={disabled}
            style={fieldStyle}
            autoFocus={autoFocus}
            minLength={minLength}
          >
            {as === "select" && optionData ? (
              <>
                {optionData[0].id === 0 ? (
                  <></>
                ) : (
                  <option value={""} hidden={true} selected={true}></option>
                )}
                {optionData &&
                  optionData.map((option: any) => (
                    <option key={option.id} value={option.id}>
                      {option.name}{" "}
                      {option.group
                        ? ` ${".".repeat(Math.abs(35 - option.name.length))} ${
                            option.group_name
                          }`
                        : ``}{" "}
                      {/*option.location ? ` ${option.location}` : ``*/}
                    </option>
                  ))}
              </>
            ) : null}
          </Field>{" "}
          <ErrorMessage name={name} component="span" className="error" />
        </>
      )}
      {description ? (
        <div className="field-description">{description}</div>
      ) : (
        <></>
      )}
    </div>
  );
}
