import { createContext, Dispatch, SetStateAction } from "react";

export type modalProps = {
  show: boolean;
  content: JSX.Element;
  maximize?: boolean;
  heading?: string | JSX.Element;
  options?: string | JSX.Element;
  context?: any;
  updater?: Function;
  style?: React.CSSProperties;
  identity?: "modal" | "notification";
};
type viewProps = {
  modal: modalProps;
  setModal: Dispatch<SetStateAction<modalProps>>;
  modal2: modalProps;
  setModal2: Dispatch<SetStateAction<modalProps>>;
  currentPage: string;
  setCurrentPage: Dispatch<SetStateAction<string>>;
  pinned: Boolean;
  setPinned: Function;
  setTemporary: Function;
};

export const ViewContext = createContext({} as viewProps);
