import Field from "./Field";
import { formField } from "../formContentType";
import { useFormContext } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { FormDataContext } from "../Form";
import { ViewContext } from "../../contexts/ViewContext";
import FieldOptions from "./FieldOptions";
import ShowOtherValues from "./ShowOtherValues";

export default function FieldWrap({ schema }: { schema: formField }) {
  const {
    name,
    inputType,
    format,
    className,
    element,
    help,
    outerStyle,
    label,
    from,
    visibleIf,
    description,
    validateTriggers,
    jsxWithData,
    icon,
    iconStyle,
    readOnly
  } = schema;

  const {
    watch,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext();

  const viewContext = useContext(ViewContext);

  const { data, customFieldData, setCustomFieldData, flatSchema } =
    useContext(FormDataContext);

  const [nonFieldDisplay, setNonFieldDisplay] = useState(<></>);

  const conditional =
    visibleIf && typeof visibleIf === "function"
      ? visibleIf(getValues())
      : visibleIf
      ? Object.keys(visibleIf)
          .map((key: string) => {
            return watch(key) == visibleIf[key];
          })
          .reduce((a, b) => a && b, true)
      : true;

  const visible = conditional && inputType !== "hidden";

  useEffect(() => {
    if (from) {
      //setValue(name, from.transform(getValues()), { shouldTouch: false });
      setNonFieldDisplay(
        from.transform(getValues(), flatSchema, customFieldData, name)
      );
      // console.log(getValues());
    }
  }, [
    JSON.stringify(from ? getValues(/*from.values*/) : []),
    JSON.stringify(customFieldData),
  ]);

  useEffect(() => {
    setCustomFieldData((prev: any) => ({
      ...prev,
      [name]: { ...prev[name], shouldSubmit: conditional },
    }));
  }, [conditional]);

  if (jsxWithData) {
    return <div>{jsxWithData(getValues(), customFieldData, viewContext)}</div>;
  }

  return visible ? (
    <div
      className={
        "field" +
        (format ? " " + format : "") +
        (element === "multiAdd" ? " multi" : "") +
        (element === "file" ? " files" : "") +
        (element === "select-picker" ? " select-picker-wrap" : "") +
        (className ? " " + className : "")
      }
      style={outerStyle}
    >
      
      {description ||
      (element && element === "multiAdd") ||
      (element && element === "subTabs") ? (
        <></>
      ) : (
        description !== "" && (
          <div>
            <label
              htmlFor={name}
              className={errors?.[name] ? "error-label" : ""}
            >
              {icon ? (
                <img
                  style={iconStyle}
                  className="field-label-icon"
                  src={icon}
                />
              ) : (
                <></>
              )}
              <div>{schema.label}</div>
            </label>
            {schema?.readOnly ? (
              <></>
            ) : (
              <FieldOptions
                sub={name.match(/%.*%/) ? true : false}
                fieldValue={getValues(name)}
                fieldName={name}
              />
            )}
            <ShowOtherValues
              visible={name.match(/%.*%/) ? true : false}
              fieldValue={getValues(name)}
              fieldName={name}
            />
          </div>
        )
      )}

      {from ? (
        <>
          {description ? (
            <label>
              {icon ? <img className="field-label-icon" src={icon} /> : <></>}
              {description}
            </label>
          ) : (
            <></>
          )}
          <div>{nonFieldDisplay}</div>
        </>
      ) : (
        <Field schema={schema} visible={visible} />
      )}
      {/*errors[name] ? (
        <div role="alert" className="error">
          {errors?.[name]?.message?.toString()}
        </div>
      ) : (
        <></>
      )*/}
      {help ? <div className="field-help">{help}</div> : <></>}
    </div>
  ) : (
    <Field
      schema={{
        ...schema,
        inputType: "hidden",
      }}
      visible={visible}
    />
  );
}
