import { useContext } from "react";
import { ViewContext } from "./contexts/ViewContext";

export default function Procedures() {
    const { setCurrentPage } = useContext(ViewContext);
    setCurrentPage("procedures");
    return <div className="procedures">
        <ul>
            <li>Finansowa</li>
            <li>Kuchnia</li>
            <li>Recepcja</li>
            <li>Pokoje</li>
            <li>Kelnerzy</li>
        </ul>
        <pre>
            Tytuł
            Kto dodał
            Uwagi od innych użytkowników zapisywane datami i kto zgłosił (czat)
            Możliwość edycji tylko dla administratora
            Mail z powiadomieniem - ręcznie
        </pre>
    </div>
}