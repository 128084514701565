export default function VoucherDateUntil({
  group,
  setIt,
}: {
  group: any;
  setIt: Function;
}) {
  function dateOffset(months: number) {
    let d = new Date(group?.valid_until);
    d.setDate(15);
    d.setMonth(d.getMonth() + (months + 1));

    d.setDate(0);
    if (d.getTime() < new Date().getTime()) {
      d = new Date();
      d.setDate(15);
      d.setMonth(d.getMonth() + 1);
      
      d.setDate(0);
    }
    setIt(d.toISOString().slice(0, 10), "valid_until");
  }

  return (
    <div className="input-box">
      <label>Ważny do</label>
      <input
        onChange={(e) => {
          setIt(e.target.value, "valid_until");
        }}
        min={new Date().toISOString().slice(0, 10)}
        //   max={100}
        type={"date"}
        value={group?.valid_until}
      />
      <button
        className="normal-button little skew"
        onClick={() => dateOffset(-3)}
      >
        -3
      </button>
      <button
        className="normal-button little skew"
        onClick={() => dateOffset(-1)}
      >
        -1
      </button>
      <button
        className="normal-button little skew"
        onClick={() => dateOffset(1)}
      >
        +1
      </button>
      <button
        className="normal-button little skew"
        onClick={() => dateOffset(3)}
      >
        +3
      </button>
    </div>
  );
}
