import { useContext } from "react";
import Day from "./Day";
import { ViewContext } from "../contexts/ViewContext";

interface dateObj {
  obj: Date;
  events: any;
  holiday?: any;
}

export default function CalendarMonth({
  cal,
  expand
}: {
  cal: any;
  expand: Boolean;
}) {

  const weekdays = <div className="weekdays">
  <div>Poniedziałek</div>
  <div>Wtorek</div>
  <div>Środa</div>
  <div>Czwartek</div>
  <div>Piątek</div>
  <div className="saturday">Sobota</div>
  <div className="sunday">Niedziela</div>
</div>
  const monthView = (
    <div className="calendar">
      {weekdays}
      {cal.preDates.map((date: dateObj) => (
        <Day key={date.obj.getDate()} date={date} type="other" expand={expand} />
      ))}
      {cal.dates.map((date: dateObj) => (
        <Day key={date.obj.getDate()} date={date} expand={expand}/>
      ))}
      {cal.postDates.map((date: dateObj) => (
        <Day key={date.obj.getDate()} date={date} type="other" expand={expand}/>
      ))}
    </div>
  );

  return monthView;
}
