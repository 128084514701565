export function Cluster({ children }: { children: JSX.Element[] | JSX.Element}) {
  return <div className="cluster">{children}</div>;
}

export function Unit({
  h,
  children,
  className,
}: {
  h: string | JSX.Element;
  children: any;
  className?: string;
}) {
  return (
    <div className={className}>
      <h3>{h}</h3>
      <div>{children}</div>
    </div>
  );
}

export default function Sheet({
  children,
  h,
  side,
  className,
}: {
  children: JSX.Element;
  h: string | JSX.Element;
  side?: Boolean;
  className?: string;
}) {
  return (
    <div
      className={
        (side ? "side-sheet" : "sheet") + (className ? " " + className : "")
      }
    >
      <h2>{h}</h2>
      {children}
    </div>
  );
}
