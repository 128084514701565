import { useEffect, useState } from "react";
import generateCalendarData from "../calendar/generateCalendarData";

export default function CalendarControls({
  date,
  cal,
  calWeek,
  items,
  dateSetter,
  extended = true,
  mode,
  noFuture = false,
  yearSet = false,
  cl,
}: {
  date: { year: number; month: number };
  cal: any;
  calWeek: any;
  items: any;
  dateSetter: Function;
  extended?: boolean;
  mode:
    | "month"
    | "week"
    | "day"
    | "list"
    | "table"
    | "search"
    | "tv"
    | "kitchen"
    | string;
  noFuture?: boolean;
  yearSet?: boolean;
  cl?: string;
}) {
  const current = new Date(date.year, date.month);

  // const [datePicker, setDatePicker] = useState(false);

  const [dateValue, setDateValue] = useState(
    cal?.dates?.at(0)?.obj?.toISOString()?.slice(0, 7)
  );

  useEffect(() => {
      setDateValue(cal?.dates?.at(0)?.obj?.toISOString()?.slice(0, 7));
  }, [cal.month, cal.year])

  const lastDay = new Date(
    new Date(current).setMonth(date.month + 1, 0)
  ).getDate();

  const weeks = Math.ceil(lastDay / 7);

  const prev2Month = new Date(
    new Date(current).setMonth(current.getMonth() - 2)
  );
  const prev2MonthString = prev2Month.toLocaleDateString("pl-PL", {
    month: "long",
    year: "numeric",
  });
  const prevMonth = new Date(
    new Date(current).setMonth(current.getMonth() - 1)
  );
  const prevMonthString = prevMonth.toLocaleDateString("pl-PL", {
    month: "long",
    year: "numeric",
  });
  const nextMonth = new Date(
    new Date(current).setMonth(current.getMonth() + 1)
  );
  const nextMonthString = nextMonth.toLocaleDateString("pl-PL", {
    month: "long",
    year: "numeric",
  });
  const next2Month = new Date(
    new Date(current).setMonth(current.getMonth() + 2)
  );
  const next2MonthString = next2Month.toLocaleDateString("pl-PL", {
    month: "long",
    year: "numeric",
  });

  function dateToYearMonth(date: Date) {
    return {
      year: date.getFullYear(),
      month: date.getMonth(),
    };
  }
  // console.log(cal);

  return (
    <div className={"calendarHeader" + (cl ? " " + cl : "")}>
      <div className="calendarMonthOptions">
        <div>
          {extended ? (
            <div>
              <div
                className="adjoiningMonth"
                onClick={() =>
                  dateSetter((prev: any) => ({
                    ...prev,
                    year: prev2Month.getFullYear(),
                    month: prev2Month.getMonth(),
                  }))
                }
              >
                {prev2MonthString}
              </div>{" "}
              <div
                className="adjoiningMonth"
                onClick={() =>
                  dateSetter((prev: any) => ({
                    ...prev,
                    year: prevMonth.getFullYear(),
                    month: prevMonth.getMonth(),
                  }))
                }
              >
                {prevMonthString}
              </div>{" "}
            </div>
          ) : (
            <></>
          )}
          <div>
            {yearSet ? (
              <button
                className="button"
                onClick={() => {
                  dateSetter((prev: any) => ({
                    ...prev,
                    year: nextMonth.getFullYear() - 1,
                  }));
                }}
              >
                {"◁◁"}
              </button>
            ) : (
              <button
                className="button"
                onClick={() =>
                  dateSetter((prev: any) => ({
                    year: prevMonth.getFullYear(),
                    month: prevMonth.getMonth(),
                    week: 1,
                  }))
                }
              >
                {"◁"}
              </button>
            )}
            <div className="selectedMonth" >
              {mode === "week" && calWeek.monthStart !== calWeek.monthEnd ? (
                <>
                  {calWeek.monthStart} / {calWeek.monthEnd}
                </>
              ) : !yearSet ? (
                cal.month
              ) : (
                ""
              )}{" "}
              {cal.year}
              <div className="date-picker">
                <input
                  type="month"
                  min="2022-01"
                  onChange={(e: any) => {
                    setDateValue(e.target.value);
                    dateSetter((prev: any) => ({
                      year: e.target.value.slice(0, 4),
                      month: Number(e.target.value.slice(5, 7)) - 1,
                      week: 1,
                    }));
                  }}
                  value={dateValue}
                />
              </div>
            </div>
            {!yearSet ? (
              <button
                className="button"
                style={
                  noFuture &&
                  date.month === new Date().getMonth() &&
                  date.year === new Date().getFullYear()
                    ? { visibility: "hidden" }
                    : {}
                }
                onClick={() => {
                  dateSetter((prev: any) => ({
                    year: nextMonth.getFullYear(),
                    month: nextMonth.getMonth(),
                    week: 1,
                  }));
                }}
              >
                {"▷"}
              </button>
            ) : (
              <button
                className="button"
                style={
                  noFuture && date.year === new Date().getFullYear()
                    ? { visibility: !yearSet ? "hidden" : "visible" }
                    : {}
                }
                onClick={() => {
                  dateSetter((prev: any) => ({
                    ...prev,
                    year: nextMonth.getFullYear() + 1,
                  }));
                }}
              >
                {"▷▷"}
              </button>
            )}
          </div>
          {extended ? (
            <div>
              <div
                className="adjoiningMonth"
                onClick={() =>
                  dateSetter((prev: any) => ({
                    ...prev,
                    year: nextMonth.getFullYear(),
                    month: nextMonth.getMonth(),
                  }))
                }
              >
                {nextMonthString}
              </div>
              <div
                className="adjoiningMonth"
                onClick={() =>
                  dateSetter((prev: any) => ({
                    ...prev,
                    year: next2Month.getFullYear(),
                    month: next2Month.getMonth(),
                  }))
                }
              >
                {next2MonthString}
              </div>{" "}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {mode === "week" ? (
        <div className="calendarMonthOptions">
          <button
            className="button"
            onClick={() =>
              dateSetter((prev: any) =>
                prev.week !== 1
                  ? { ...prev, week: prev.week - 1 }
                  : {
                      ...prev,
                      week: weeks,
                      year: prevMonth.getFullYear(),
                      month: prevMonth.getMonth(),
                    }
              )
            }
          >
            {"◁"}
          </button>
          <div>
            {calWeek.range[0].toLocaleDateString("pl-PL", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}{" "}
            -{" "}
            {calWeek.range[1].toLocaleDateString("pl-PL", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </div>
          <button
            className="button"
            onClick={() =>
              dateSetter((prev: any) =>
                prev.week === weeks
                  ? {
                      ...prev,
                      year: nextMonth.getFullYear(),
                      month: nextMonth.getMonth(),
                      week: 1,
                    }
                  : { ...prev, week: prev.week + 1 }
              )
            }
          >
            {"▷"}
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
