export const errorSearchSchema = (data: any) => {
  return [
    {
      name: "guest",
      label: "Imię i nazwisko gościa",
      fuzzy: true,
      value: "",
    },
    {
      name: "lsi_document_number",
      label: "Nr dokumentu",
      fuzzy: true,
      value: "",
    },
    {
      name: "booking_number",
      label: "Nr rezerwacji",
      fuzzy: true,
      value: "",
    },
    {
      name: "problem_type",
      label: "Rodzaj problemu",
      fuzzy: false,
      value: "",
      optionData: data.problemTypes.rows,
      as: "select",
    },
    {
      name: "what_is_corrected",
      label: "Co podlega korekcie",
      fuzzy: false,
      value: "",
      optionData: data.whatIsCorrected.rows,
      as: "select",
    },

    {
      name: "employee_involved_id",
      label: "Pracownik powiązany",
      fuzzy: false,
      value: "",
      optionData: data.employeesGeneric?.rows.map((e: any) => ({
        ...e,
        name: e.first_name + " " + e.last_name,
      })),
      as: "select",
    },
  ];
};
