import { Styles, Text, View } from "@react-pdf/renderer";

export default function InvoiceTableHead({styles, cW}: {styles: Styles, cW: any}) {
    return <View style={{ fontSize: "8px", fontWeight: "bold" }}>
    <View style={[styles.table, { flexDirection: "row" }]}>
      <View style={[styles.tableCell, { width: cW[0] }]}>
        <Text>Lp.</Text>
      </View>
      <View style={[styles.tableCell, { width: cW[1] }]}>
        <Text>Nazwa towaru lub usługi</Text>
      </View>
      {/* <View style={[styles.tableCell, { width: cW[2] }]}>
        <Text>Symbol PKWiU</Text>
      </View> */}
      <View style={[styles.tableCell, { width: cW[2] }]}>
        <Text>jm</Text>
      </View>
      <View style={[styles.tableCell, { width: cW[3] }]}>
        <Text>Ilość</Text>
      </View>
      <View style={[styles.tableCell, { width: cW[4] }]}>
        <Text>Cena jedn. brutto</Text>
      </View>
      <View style={[styles.tableCell, { width: cW[5] }]}>
        <Text>Wartość</Text>
        <Text>sprzedaży netto</Text>
      </View>
      <View style={[styles.tableCell, { padding: 0, width: cW[6] }]}>
        <Text>Podatek VAT</Text>
        <View
          style={[
            styles.table,
            { padding: "0", borderRight: "none", flexDirection: "row" },
          ]}
        >
          <View
            style={{
              borderRight: "1px solid black",
              padding: "2 1",
              width: 30,
            }}
          >
            <Text>%</Text>
          </View>
          <View style={{ padding: "2 1", width: 70 }}>
            <Text>Kwota</Text>
          </View>
        </View>
      </View>
      <View style={[styles.tableCell, { width: cW[7] }]}>
        <Text>Wartość</Text>
        <Text>sprzedaży brutto</Text>
      </View>
    </View>
  </View>
}