import { createContext, useContext, useEffect, useState } from "react";
import Info from "./common/Info";
import Loading from "./common/Loading";
import useGeneric from "./dataFetch/useGeneric";
import NewSchedule from "./schedules/NewSchedule";
import ScheduleEditor from "./schedules/ScheduleEditor";
import { ViewContext } from "./contexts/ViewContext";

export const ScheduleContext = createContext<any>({
  employees: undefined,
  defaultHours: undefined,
  days: undefined,
});

export default function Schedules() {
  const { setCurrentPage } = useContext(ViewContext);
  setCurrentPage("schedules");
  const [mode, setMode] = useState<"view" | "new">("view");
  const [options, setOptions] = useState(false);
  const [viewedSchedule, setViewedSchedule] = useState(0);
  const [update, setUpdate] = useState(0);
  const fetchEmployees = useGeneric({ name: "employeesGeneric", limit: 5000 });
  const employees = !fetchEmployees.loading ? fetchEmployees.rows : undefined;
  
  const categories = employees
    ? [
        ...new Set(
          employees
            .filter((e: any) => e.location === "hotel")
            .map((e: any) => e.group_name)
        ),
      ]
    : undefined;

  const fetchSchedules = useGeneric({
    name: "schedules",
    limit: 5000,
    update: mode + update,
    reload: true,
    secondsToReload: 10,
  });
  const schedules = !fetchSchedules.loading ? fetchSchedules.rows : undefined;

  const fetchTemplates = useGeneric({ name: "scheduleTemplates", limit: 1000, reload: true, secondsToReload:30, update: mode + update });
  const templates = !fetchTemplates.loading ? fetchTemplates.rows : undefined;

  useEffect(() => {
    if (update === 0) {
      if (schedules && schedules.length) {
        setViewedSchedule(schedules[0].id);
      } else {
       // setMode("new");
      }
    }
  }, []);

  return employees && schedules ? (
    <div
      className="schedules"
      onClick={(e) => {
        const target = e.target as Element;
        if (!target.classList.contains("options")) {
          setOptions(false);
        }
      }}
    >
      <div className="controls">
        {schedules && schedules.length ? (
          <button
            className={mode === "view" ? "active" : ""}
            onClick={() => setMode("view")}
          >
            Grafiki
          </button>
        ) : (
          <></>
        )}
        <button
          className={mode === "new" ? "active" : ""}
          onClick={() => setMode("new")}
        >
          Nowy grafik
        </button>
      </div>
      {mode === "view" ? (
        <div className="scheduleView">
          <div className="scheduleList">
            {schedules ? (
              schedules.map((item: any) => (
                <button
                  className={viewedSchedule === item.id ? "active" : ""}
                  onClick={() => setViewedSchedule(item.id)}
                >
                  {item.name}
                </button>
              ))
            ) : (
              <></>
            )}
          </div>
          <ScheduleEditor
            employees={employees}
            categories={categories}
            schedule={schedules.find((item: any) => item.id === viewedSchedule)}
            setUpdate={setUpdate}
            update={update}
            options={options}
            setOptions={setOptions}
            templates={templates}
          />
        </div>
      ) : (
        <></>
      )}
      {mode === "new" ? (
        <NewSchedule
          employees={employees}
          categories={categories}
          setUpdate={setUpdate}
        />
      ) : (
        <></>
      )}
    </div>
  ) : (
    <Loading />
  );
}
