import { useContext, useEffect, useState } from "react";
import { URL } from "../paths";
import { ScheduleContext } from "../Schedules";
import ScheduleHour from "./ScheduleHour";
import { isSameDay } from "./ScheduleTable";

export default function ScheduleCell({
  employee,
  day,
  data,
  defaultHours,
  scheduleID,
  nthDay,
}: {
  employee: any;
  day: any;
  data: any;
  defaultHours: any;
  scheduleID: any;
  nthDay: number;
}) {
  const {
    templateEdit,
    specials,
    editSpecials,
    setEditSpecials,
    addEntry,
    setUpdate,
    selectedDay,
  } = useContext(ScheduleContext);
  const [selectedSpecial, setSelectedSpecial] = useState("");
  const [acceptedSpecial, setAcceptedSpecial] = useState(false);
  //console.log(specialsArray);
  const isSpecialEdit =
    editSpecials.e === employee.id &&
    editSpecials.d === day.getTime() &&
    editSpecials.s === scheduleID &&
    editSpecials.a === true;

  useEffect(() => {
    if (acceptedSpecial === true) {
      setEditSpecials(false);
      if (specials && specials.length) {
        const thisSpecial = specials.find(
          (s: any) => Number(s.id) === Number(selectedSpecial)
        );
        const times = [
          thisSpecial?.time1?.slice(0, 5),
          thisSpecial?.time2?.slice(0, 5),
        ];
        if (times && times.length && times[0]) {
          console.log(times[0], times[1]);

          addEntry({
            id: data && data.id ? data.id : undefined,
            scheduleID: scheduleID,
            employeeID: employee.id,
            date: day,
            time: times[0],
            time2: times[1],
            end: "both",
          });
        }
        setAcceptedSpecial(false);
      }
    }
  }, [acceptedSpecial]);

  const timeString = data ? data.time + "," + data.time2 : undefined;
  const template =
    specials && specials.length
      ? specials.find((s: any) => s.time1 + "," + s.time2 === timeString)
      : false;
  console.log(template);
  return (
    <td
      className={
        "weekday" +
        (isSameDay(day, new Date()) ? " today" : "") +
        (selectedDay === nthDay ? " selectedDay" : "")
      }
      key={employee.id + "." + day.getDay()}
    >
      <div
        className={
          "cellWrap" +
          (isSpecialEdit && !templateEdit ? " special" : "") +
          (template && !templateEdit ? " template" : "")
        }
        style={{ position: "relative" }}
      >
        {template && !templateEdit ? (
          <div
            className="templateName"
            style={{ backgroundColor: template.color }}
          >
            {template?.name}
          </div>
        ) : (
          <></>
        )}
        {isSpecialEdit ? (
          <></>
        ) : (
          <>
            <ScheduleHour
              key={1}
              employee={employee}
              day={day}
              data={data}
              defaultData={defaultHours}
              scheduleID={scheduleID}
              end={false}
            />
            {templateEdit ? (
              defaultHours && defaultHours.t2 ? (
                <>▸</>
              ) : (
                <></>
              )
            ) : data && data.time2 ? (
              <span className="separator">▸</span>
            ) : (
              <></>
            )}
            <ScheduleHour
              key={2}
              employee={employee}
              day={day}
              data={data}
              defaultData={defaultHours}
              scheduleID={scheduleID}
              end={true}
            />
          </>
        )}
        {isSpecialEdit ? (
          <div className="hourEditor">
            <select onChange={(e) => setSelectedSpecial(e.target.value)}>
              <option value="">wybierz...</option>
              {specials && specials.length ? (
                specials.map((s: any) => {
                  const timesString =
                    s.time1.slice(0, 5) + "-" + s.time2.slice(0, 5);
                  return (
                    <option title={s.name + ": " + timesString} value={s.id}>
                      {s.name}: {timesString}
                    </option>
                  );
                })
              ) : (
                <></>
              )}
            </select>
          </div>
        ) : (
          <></>
        )}
        {isSpecialEdit ? (
          <button
            className="specialTrigger"
            onClick={() => setAcceptedSpecial(true)}
          >
            ✓
          </button>
        ) : (
          <></>
        )}
        {templateEdit ? (
          <></>
        ) : (
          specials.length ?
          <button
            className={"specialTrigger" + (!isSpecialEdit ? " start" : "")}
            onClick={() =>
              setEditSpecials((prev: any) => ({
                e: employee.id,
                d: day.getTime(),
                s: scheduleID,
                a: prev.a !== undefined ? !prev.a : true,
              }))
            }
          >
            {isSpecialEdit ? <>✖</> : <>⬤</>}
          </button> : <></>
        )}
      </div>
    </td>
  );
}
