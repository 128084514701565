import insertGeneric from "./insertGeneric";

export default function fillLog(data: {
  user: number | undefined;
  section: string;
  event: string;
  item_id: number | undefined;
}) {
  insertGeneric("fill-log", {
    ...data,
  });
}
