import { useContext } from "react";
import { URLProd } from "./paths";
import { ViewContext } from "./contexts/ViewContext";
import Preview from "./employeeFiles/Preview";
import FileListItem from "./employeeFiles/FileListItem";

export default function FilesView({ files }: { files: any }) {
  const { setModal2 } = useContext(ViewContext);

  function openPreview(file: any, link: any) {
    setModal2({
      show: true,
      style: { minWidth: "60vw" },
      heading: "Podgląd",
      content: (
        <Preview
          format={
            file.format.match(/image/)
              ? "image"
              : file.format.match(/pdf/)
              ? "pdf"
              : file.format.match(/audio/)
              ? "audio"
              : file.format.match(/video/)
              ? "video"
            : ""
          }
          data={file}
          link={link}
        />
      ),
    });
  }

  return (
    <div className="files-list">
      {!files?.length ? (
        <>brak</>
      ) : (
        files?.map((f: any) => (
          <FileListItem file={f} />
          //   <div
          //     className="files-view-item"
          //     onClick={() => openPreview(f, URLProd + "uploads/files/" + f?.path)}
          //   >
          //     {f?.name?.replace(/[0-9]*_/, "")}
          //   </div>
        ))
      )}
    </div>
  );
}
