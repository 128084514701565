import { useContext, useState } from "react";
import Menus from "./menuEditor/Menus";
import MenuMeals from "./menuEditor/MenuMeals";
import MenuCategories from "./menuEditor/MenuCategories";
import { ViewContext } from "./contexts/ViewContext";
import insertGeneric from "./dataFetch/insertGeneric";
import MenuCategoriesChoose from "./menuEditor/MenuCategoriesChoose";

export async function updateOrder(
  db: string,
  setUpdates: Function,
  order: number[]
) {
  await insertGeneric(
    db,
    order?.map((o: any, index: number) => ({ id: o, index: index }))
  );
  setUpdates((prev: number) => prev + 1);
}

export default function MenuEditor() {
  const { currentPage, setCurrentPage, modal } = useContext(ViewContext);
  const [tab, setTab] = useState<
    "meals" | "drinks" | "menus" | "categories" | "preview"
  >("meals");
  setCurrentPage("menu_editor");
  return (
    <div className="standardWrap">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          zIndex: 2,
          position: "sticky",
          top: 0,
          background: "whitesmoke",
          border: ".5px solid #c5c5c5",
        }}
      >
        <button
          onClick={() => setTab("menus")}
          className={"normal-button" + (tab === "menus" ? " active" : "")}
        >
          Karty
        </button>
        <button
          onClick={() => setTab("categories")}
          className={"normal-button" + (tab === "categories" ? " active" : "")}
        >
          Kategorie
        </button>
        <button
          onClick={() => setTab("meals")}
          className={"normal-button" + (tab === "meals" ? " active" : "")}
        >
          Dania
        </button>
        <button
          onClick={() => setTab("drinks")}
          className={"normal-button" + (tab === "drinks" ? " active" : "")}
        >
          Napoje
        </button>
        <button
          onClick={() => setTab("preview")}
          className={"normal-button" + (tab === "preview" ? " active" : "")}
        >
          Podgląd
        </button>
      </div>
      {tab === "menus" ? (
        <Menus />
      ) : tab === "categories" ? (
        <MenuCategoriesChoose />
      ) : tab === "meals" ? (
        <MenuMeals subset={"meals"} />
      ) : tab === "drinks" ? (
        <MenuMeals subset={"drinks"} />
      ) : tab === "preview" ? (
        <iframe
          src={"https://karta.agit.com.pl"}
          style={{ width: "100dvw", height: "90dvh" }}
        />
      ) : (
        <></>
      )}
      <div></div>
    </div>
  );
}
