import { useContext, useState } from "react";
import { EntryContext } from "../contexts/EntryContext";
import { UserContext } from "../contexts/UserContext";
import { ViewContext } from "../contexts/ViewContext";
import setResolved from "../dataFetch/setResolved";
import useGeneric from "../dataFetch/useGeneric";
import { URL } from "../paths";
import Chat from "./Chat";
import Table from "./Table";
import BooleanSymbol from "./viewElements/BooleanSymbol";
import errorRegisterView from "./views/errorRegisterView";
import { viewType } from "./views/types/viewTypes";

export default function RowControls({
  content,
  entry,
  columnLength,
}: {
  content: viewType[];
  entry?: any;
  columnLength?: number;
}) {
  //console.log(content,entry);

  const [commentView, setCommentView] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [solution, setSolution] = useState(entry.approved_solution);

  const context = useContext(EntryContext);
  const viewContext = useContext(ViewContext);
  const userContext = useContext(UserContext);
  const colors = {
    no: " brown",
    noWeak: " rgb(68, 204, 109)",
    yesWeak: " #ff000047",
    yes: " rgb(37, 175, 78)",
  };

  async function modifyAsInsert(values: any, query: "add" | "update") {
    const add = await fetch(URL + "backend/modify-as-insert.php", {
      method: "POST",
      body: JSON.stringify({
        data: values,
      }),
      credentials: "include",
    });
    const result = await add.json();
    console.log(result);

    if (userContext.role === 0) setResolved(entry.id, "0", "errorRegister");

    context.setUpdate((prev: number) => prev + 1);
    setCommentView((prev: boolean) => false);
  }

  return processing ? <tr><td colSpan={17}><div style={{padding:"10px 4px", fontWeight:"700"}}>Zapisywanie...</div></td></tr> : (
    <>
      <tr>
        <td
          style={{
            padding: "0px",
            backgroundColor: "rgb(252,252,252)",
            textAlign: "left",
          }}
          colSpan={100}
        >
          <div className="secondRow">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "110px",
              }}
            >
              <div
                className="is-resolved"
                style={{
                  color: entry.resolved === 1 ? colors.yes : colors.no,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  minHeight: "60px",
                }}
              >
                <BooleanSymbol
                  style={{ fontSize: "18px", padding: "10px" }}
                  value={entry.resolved}
                />
                {entry.resolved === 1 ? (
                  <div>Rozwiązana</div>
                ) : (
                  <strong>W toku</strong>
                )}
              </div>
              <div
                className="moreButton"
                onClick={() => {
                  setCommentView((prev: boolean) => !prev);
                }}
              >
                {commentView ? <>Zwiń ▴</> : <>Opcje ▾</>}
              </div>
            </div>
            {content.map((item: any) => (
              <div key={item.name} style={item.style}>
                {entry.resolved === 0 ? (
                  <>
                    {item.name === "Rozwiązanie" ? (
                      entry.approved_solution.length ? (
                        <>
                          <div className="srHeading">
                            Ostatnie sugerowane rozwiązanie
                          </div>
                          <div>{item.jsx}</div>
                        </>
                      ) : (
                        <div>Brak rozwiązania</div>
                      )
                    ) : (
                      <>
                        {" "}
                        <div className="srHeading">{item.name}</div>
                        <div>{item.jsx}</div>
                      </>
                    )}
                    {item.name === "Rozwiązanie" && !commentView ? (
                      <button
                        className="button small"
                        onClick={(e) => {
                          e.preventDefault();
                          /*  setResolved(
                            entry.id,
                            entry.resolved,
                            "errorRegister"
                          );
                          context.setUpdate((prev: number) => prev + 1);*/
                          setCommentView((prev: boolean) => true);
                        }}
                      >
                        {userContext.role === 0 ? (
                          <>Finalizacja sprawy</>
                        ) : (
                          <>Sugeruj rozwiązanie</>
                        )}
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    <div className="srHeading">{item.name}</div>
                    <div>{item.jsx}</div>
                    {entry.resolved === 1 && item.name === "Rozwiązanie" ? (
                      <button
                        className="button small"
                        onClick={(e) => {
                          e.preventDefault();
                          const really = window.confirm(
                            "Na pewno chcesz otworzyć sprawę?"
                          );
                          if (really) {
                            setResolved(entry.id, "1", "errorRegister");
                            context.setUpdate((prev: number) => prev + 1);
                            setCommentView(true);
                          }
                        }}
                      >
                        Otwórz sprawę
                      </button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
          {commentView ? (
            <div className="in-form">
              <div>
                <h1>Rozmowa</h1>
                <Chat
                  query={{
                    table: "error_register",
                    table_id: entry.entry_number,
                  }}
                />
              </div>
              <div>
                <h1>Rozwiązanie</h1>
                <textarea
                  style={{ height: "150px", marginBottom: "5px" }}
                  className="chatInputArea"
                  value={solution}
                  onChange={(e) => setSolution(e.target.value)}
                  disabled={entry.resolved === 1 ? true : false}
                ></textarea>
                {entry.resolved === 0 ? (
                  <button
                    className="button"
                    onClick={async (e) => {
                      e.preventDefault();
                      setProcessing(true);
                      const result: any = await modifyAsInsert(
                        {
                          ...entry,
                          approved_solution: solution,
                          resolved: "1",
                        },
                        "update"
                      );
                      if (result) {
                        setProcessing(false);
                      }
                    }}
                  >
                    Zapisz rozwiązanie i zamknij sprawę
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </td>
      </tr>
      <tr
        style={{
          borderTop:
            "6px solid" +
            (entry.resolved === 1 ? colors.noWeak : colors.yesWeak),
          boxShadow: "0 4px 4px rgba(0,0,0,.2)",
        }}
        className="separator-row"
      >
        {columnLength ? (
          Array.from(Array(columnLength).keys()).map((i) => (
            <td
              key={i}
              style={{
                borderRight: ".5px solid rgba(0,0,0,.06",
                //background: "whitesmoke",
                padding: "0px",
              }}
            ></td>
          ))
        ) : (
          <></>
        )}
      </tr>
    </>
  );
}
