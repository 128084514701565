export default function BooleanSymbol({ value, style }: { value: string | boolean | number, style?: React.CSSProperties }) {
  return (
    <div className="booleanSymbol" style={style}>
      {value === "1" || value === true || value === 1 ? (
        <span className="yeah">✓</span>
      ) : (
        <span className="nope">✖</span>
      )}
    </div>
  );
}
