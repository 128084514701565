import prettyDate from "../../../functions/prettyDate";

export function row(row?: any): any[] {
  const online =
    new Date(row?.last_online).getTime() - new Date().getTime() > -1000 * 60;
  return [
    {
      name: "ID",
      value: row?.id,
      className: "id",
    },
    {
      source: "username",
      value: row?.username,
    },
    {
      name: "Połączony",
      source: "last_online",
      rowClassName: online ? "checked-in" : "",
      value: online ? "tak" : "nie",
    },
    {
      name: "Ostatnio online",
      source: "last_online",
      value: prettyDate(row?.last_online, "dziś, "),
    },
    {
        name: "Zakładka",
        source: "last_location",
        value: row?.last_location,
      },
  ];
}
