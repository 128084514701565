import { Styles, Text, View } from "@react-pdf/renderer";
import prettyMoney from "../../functions/prettyMoney";
import invoiceTypes from "./invoiceTypes";

export default function InvoiceTableBody({
  styles,
  cW,
  invoice,
  itemGroups,
}: {
  styles: Styles;
  cW: any;
  invoice: invoiceTypes;
  itemGroups: any[];
}) {
  return (
    <>
      {itemGroups &&
        itemGroups?.map((i: invoiceTypes, lp: number) => (
          <View style={{ fontSize: "8px" }}>
            <View
              style={[
                styles.table,
                {
                  borderTop: invoice?.type == 3 ? "1px solid black" : "none",
                  flexDirection: "row",
                },
              ]}
            >
              <View style={[styles.tableCell, { width: cW[0] }]}>
                <Text>{lp + 1}</Text>
              </View>
              <View
                style={[styles.tableCell, { textAlign: "left", width: cW[1] }]}
              >
                <Text>
                  {i?.description} {invoice?.type === 3 ? i?.cSCPID : <></>}
                </Text>
              </View>
              {/* <View
              style={[
                styles.tableCell,
                { textAlign: "left", width: cW[2] },
              ]}
            >
              <Text></Text>
            </View> */}
              <View
                style={[styles.tableCell, { textAlign: "right", width: cW[2] }]}
              >
                <Text>{i?.jm}</Text>
              </View>
              <View
                style={[styles.tableCell, { textAlign: "right", width: cW[3] }]}
              >
                <Text>{i?.amount}</Text>
              </View>
              <View
                style={[styles.tableCell, { textAlign: "right", width: cW[4] }]}
              >
                <Text>
                  {prettyMoney(i?.item_unit_gross, { text: true, round: 2 })}
                </Text>
              </View>
              <View
                style={[styles.tableCell, { textAlign: "right", width: cW[5] }]}
              >
                <Text>{prettyMoney(i?.sum_net, { text: true, round: 2 })}</Text>
              </View>
              <View style={[styles.tableCell, { padding: 0, width: cW[6] }]}>
                <View
                  style={[
                    {
                      padding: "0",
                      borderRight: "none",
                      flexDirection: "row",
                    },
                  ]}
                >
                  <View
                    style={{
                      padding: "2 1",
                      borderRight: "1px solid black",
                      width: 30,
                    }}
                  >
                    <Text>{parseInt(i?.itemVAT)}</Text>
                  </View>
                  <View
                    style={{ textAlign: "right", padding: "2 1", width: 70 }}
                  >
                    <Text>
                      {prettyMoney(
                        invoice?.type == 3
                          ? Number(i?.sum_cor_gross) - Number(i?.sum_cor_net)
                          : Number(i?.sum_gross) - Number(i?.sum_net),
                        {
                          text: true,
                          round: 2,
                        }
                      )}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={[styles.tableCell, { textAlign: "right", width: cW[7] }]}
              >
                <Text>
                  {prettyMoney(i?.sum_gross, { text: true, round: 2 })}
                </Text>
              </View>
            </View>
          </View>
        ))}
    </>
  );
}
