import { useContext } from "react";
import Loading from "../common/Loading";
import { ViewContext } from "../contexts/ViewContext";
import useGeneric from "../dataFetch/useGeneric";

export default function EventPaymentsView() {
  const { setCurrentPage, modal, setModal } = useContext(ViewContext);
  setCurrentPage("event-payments-viewer");

  const f = useGeneric({
    name: "event_calendar_payments",
    limit: 200,
  });
  const data = !f.loading && f?.rows;

  if (f.loading) {
    return <Loading />;
  }

  if (!data.length) {
    return <div>Brak wyników</div>;
  }

  return (
    <div>
      {data.map((payment: any) => (
        <div>{payment.id} {payment.document_number} {payment.document_id}</div>
      ))}
    </div>
  );
}
