import BooleanSymbol from "../viewElements/BooleanSymbol";

export default function eventsView(item: any) {
  const columns = [
    {
      ref: "id",
      name: "Lp.",
      jsx: <>{item.id}</>,
      style: { width: "40px" },
    },
    {
      ref: "color",
      name: "Kolor",
      styleHeading: { textAlign: "left" },
      style: {
        background: item.color,
        width: "20px",
        boxShadow: "0 0 15px rgba(0,0,0,.06) inset",
        border:"none"
      },
      jsx: <></>,
    },
    {
      ref: "name",
      name: "Nazwa",
      style: { textAlign: "left", fontSize: "15px" },
      styleHeading: { textAlign: "left" },
      jsx: <>{item.name}</>,
    },

    {
      ref: "active",
      name: "Aktywne",
      style: { width: "20px", cursor: "pointer" },
      jsx: <BooleanSymbol value={item.active} />,
    },
  ];
  return { columns: columns };
}
