import { useContext, useState } from "react";
import { RegisterViewContext } from "./View";
import Options from "./Options";
import { ItemContext } from "./ViewItem";
import { ViewContext } from "../contexts/ViewContext";

export default function ViewTableRow() {
  const { orderColumn, expanded, setExpanded} =
    useContext(RegisterViewContext);
  const { modal, setModal } = useContext(ViewContext);
  const { schema, row, subRow, setSubRow } = useContext(ItemContext);
  return (
    <>
      <tr className={schema?.find((c: any) => c?.rowClassName)?.rowClassName}>
        {schema?.map((cell: any) => (
          <td
            onClick={() => {
              if (cell?.onClickModal) {
                setModal({
                  heading: "",
                  content: cell?.onClickModal,
                  show: true,
                });
              }
            }}
            className={cell?.className}
            style={{
              opacity: row?.active ? 1 : row?.active === 0 ? 0.6 : 1,
              ...cell?.style,
              background:
                cell?.source &&
                orderColumn === cell?.source &&
                "rgba(255, 255, 157,.3)",
            }}
          >
            <div style={cell?.innerStyle}>
              {typeof cell?.value === "function"
                ? cell?.value(row)
                : cell?.value}
            </div>
          </td>
        ))}
        {schema === false ? <></> : <Options type="table" row={row} />}
      </tr>
      {expanded && expanded === row?.id ? (
        <tr>
          <td className="plus-row" colSpan={Object.keys(schema)?.length + 1}>
            {subRow}
          </td>
        </tr>
      ) : (
        <></>
      )}
    </>
  );
}
