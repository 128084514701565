import { useContext } from "react";
import { RegisterViewContext } from "../../View";
import urgentIcon from "../../../../images/icons/urgent.svg";
import modifyIcon from "../../../../images/icons/modify.svg";
import editIcon from "../../../../images/icons/pencil.png";
import insertGeneric from "../../../dataFetch/insertGeneric";
import { editUrgency } from "./functions";

export default function ErrorOptionButtons({
  row,
  setStatusEdit,
}: {
  row: any;
  setStatusEdit: Function;
}) {
  const { auth, setEditData, setEditor, editor, setUpdates } =
    useContext(RegisterViewContext);

  return (
    <>
      {auth?.errors > 2 ? (
        <button
          className={
            "normal-button" +
            (row?.urgent ? " active" : " inactive") +
            (row?.resolved ? " disabled" : "")
          }
          title={row?.resolved ? "Zgłoszenie rozwiązane." : ""}
          onClick={() =>
            !row?.resolved &&
            editUrgency({
              value: !row?.urgent,
              row: row,
              f: {
                setUpdates: setUpdates,
                setStatusEdit: setStatusEdit,
              },
            })
          }
        >
          <img src={urgentIcon} style={{ width: "16px" }} />{" "}
          <span style={{ width: "50px" }}>
            {row?.urgent ? <>PILNE</> : <>NIEPILNE</>}
          </span>
        </button>
      ) : (
        <></>
      )}
      {auth?.errors > 2 ? (
        <button
          className={"normal-button" + (row?.resolved ? " disabled" : "")}
          title={
            row?.resolved
              ? "Zgłoszenie rozwiązane. Brak możliwości edycji."
              : ""
          }
          onClick={() => {
            if (editor || row?.resolved) return;
            setEditData(row);
            setEditor(true);
          }}
        >
          <img width={14} src={editIcon} /> Edytuj
        </button>
      ) : (
        <></>
      )}
      {auth?.errors > 2 ? (
        <button
          className="normal-button"
          onClick={() => setStatusEdit((prev: Boolean) => !prev)}
        >
          <img width={14} src={modifyIcon} /> Zmień status
        </button>
      ) : (
        <></>
      )}
    </>
  );
}
