export function row(row?: any): any[] {
  return [
    {
      source: "id",
      name: "ID",
      value: row?.id,
      className: "id",
    },
    {
      source: "type",
      name: "Dokument",
      style: {
        width:"72px"
      },
      value: row?.type === 1 ? "faktura" : row?.type === 2 ? "paragon" : "inny",
    },
    {
      source: "name",
      name: "Nazwa",
      value: row?.name,
      style: {
        width:"30%"
      }
    },
    {
        source: "short_name",
        name: "Nazwa skrócona",
        value: row?.short_name,
      },
  ];
}
