export function sumCellValues(data: any, cellName: string | Function) {
  return (
    data ?
    Math.round(
      data
        ?.map((e: any) => typeof cellName === "function" ? Number(cellName(e)) : Number(e?.[cellName]))
        ?.reduce((a: any, b: any) => a + b, 0) * 100
    ) / 100 : 0
  );
}
