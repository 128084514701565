export default function process(data: any) {
  //check & remove duplicates
  return data.filter((item: any, index: number, array: any) => {
    let test = true;

    // remove duplicates when next in array
    if (array[index - 1]) {
      if (
        array[index - 1].cardperson === item.cardperson &&
        array[index - 1].timestamp - item.timestamp < 500 &&
        array[index - 1].timestamp - item.timestamp >= 0
      ) {
        //console.log("duplicate!", array[index],array[index+1]);
        test = false;
      } else {
        test = true;
      }
    }

    return test;
  });
  //return data
}
