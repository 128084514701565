import { forms } from "../../../forms_new/formContentType";
import { objectOrJSONparse } from "../../../functions/tools";

export function receiptSearch(data: any): any {
  return {
    name: "lsi_receipt_search",
    label: "Wyszukaj paragon LSI",
    element: "hybrid",
    db: "lsi_receipt_query",
    groupBy: [
      (v: any) => (v?.SCID ? v.SCID : v.id),
      ["VAT", "description", "amount", "SCPID"],
    ],
    query: (input: string | number) => ({
      multiQuery: input,
    }),
    resultElement: (item: any) => (
      <div
        className="invoice-find"
        style={{
          gridTemplateColumns: "70px 80px 1fr 100px",
          alignItems: "center",
        }}
      >
        <div style={{ fontWeight: "500" }}>
          <span style={{ fontSize: "10px" }}>PAR/</span>
          {item.DocNo}
        </div>
        <div style={{ fontSize: "10px" }}>{item.data_dzienna.slice(0, 10)}</div>
        <div style={{ textAlign: "left" }}>{item.ko_firma}</div>
        <div style={{ textAlign: "right" }}>
          {Math.round(item.ValueBWal * 100) / 100} zł
        </div>
      </div>
    ),
  };
}

export function invoiceSearch(data: any): any {
  return {
    name: "lsi_invoice_search",
    label: "Wyszukaj fakturę LSI",
    element: "hybrid",
    db: "lsi_invoice_query",
    groupBy: [
      (v: any) => (v?.SCID ? v.SCID : v.id),
      ["VAT", "description", "amount", "SCPID"],
    ],
    query: (input: string | number) => ({
      multiQuery: input,
    }),
    resultElement: (item: any) => (
      <div className="invoice-find">
        <div style={{ fontWeight: "500" }}>
          FV/{item.DocNo}/{item.month}/{item.year}/H
        </div>
        <div style={{ textAlign: "left" }}>{item.ko_firma}</div>
        <div style={{ textAlign: "right" }}>
          {Math.round(item.ValueBWal * 100) / 100} zł
        </div>
      </div>
    ),
  };
}

export function formSchema(data: any): forms {
  return {
    main: {
      name: "Edycja błędu",
      content: [
        [
          {
            name: "id",
            style: {
              display: "none",
            },
            // disabled: true,
          },
          {
            name: "problem_type",
            label: "Rodzaj problemu",
            element: "select",
            getOptionsFrom: "problemTypes",
            required: "Podaj rodzaj problemu",
          },
          {
            name: "problem_description",
            visibleIf: (v: any) => Number(v?.problem_type) === 7,
            label: "Opis problemu",
            element: "textarea",
            required: "Podaj rodzaj problemu",
            maxLength: 300,
          },
          {
            name: "sales_document_type",
            label: "Rodzaj dokumentu sprzedaży",
            element: "select-picker",
            getOptionsFrom: "salesDocumentTypes",
            required: "Podaj rodzaj dokumentu sprzedaży",
            validate: (v: any, vs: any) => {
              return (
                ([1, 2, 40].includes(Number(v)) &&
                  [1, 2, 40].includes(Number(vs?.problem_type))) ||
                ([3, 4].includes(Number(v)) &&
                  [3, 4, 5, 7].includes(Number(vs?.problem_type))) ||
                [6, 7].includes(Number(vs?.problem_type)) ||
                Number(v) === 5 ||
                "Niezgodność dokumentu sprzedaży z rodzajem problemu"
              );
            },
          },
          {
            name: "is_original_receipt_present",
            label: "Czy dostępny oryginał?",
            element: "select-picker",
            required: "Określ dostępność oryginału",
            visibleIf: (v: any) => [1, 2, 40].includes(Number(v?.problem_type)),
            help: (
              <>
                W przypadku PARAGONU
                <br />
                oryginał paragonu fiskalnego MUSI BYĆ OBECNY i&nbsp;dopięty do
                rozliczenia JEŚLI ORYGINAŁ JEST - zaznacz
              </>
            ),
            optionsList: [
              { id: "1", name: "TAK" },
              { id: "0", name: "NIE" },
            ],
          },
          {
            name: "filenames",
            label: "Załącz dokument przed korektą",
            //   format: "half-2",
            visibleIf: (v: any) => [1, 2, 40].includes(Number(v?.problem_type)),
            element: "file",
            // files: objectOrJSONparse(data?.files)?.filter(
            //   (d: any) => d.field === "other_file"
            // ),
            filesLoad: {
              module: "errors",
              category: "original-document",
              sub_module: "bad",
              // module_id: data?.id ? data?.id : 0,
              module_id: data?.id || undefined,
              temp_id: data?.id ? undefined : data?.temp_id,
              // sub_id:
              sub_category: "filenames",
            },
            fileInfo: {
              module: "errors",
              moduleID: data?.id || 0,
              subID: 0,
              subModule: "bad", // folder
              temp_id: data?.temp_id,
              category: "original-document",
            },
          },
          {
            name: "employee_involved_id",
            style: {
              display: "none",
            },
            required: "Podaj pracownika związanego z problemem",
            // disabled: true,
          },
        ],
        [
          {
            ...invoiceSearch(data),

            visibleIf: (v: any) =>
              Number(v?.sales_document_type) === 3 ||
              Number(v?.sales_document_type) === 4,

            resultUpdate: (item: any) => [
              [
                "lsi_document_number",
                `FV/${item.DocNo}/${item.month}/${item.year}/H`,
              ],
              [
                "date_of_sale_from_sales_document",
                new Date(item.Data).toISOString().slice(0, 10),
              ],
              ["purchaser_number_on_document", item.ko_firma],
              ["booking_number", item.numer_rezerwacji || ""],
              ["lsi_document_id", item.SCID || ""],
              ["booking_id", item.idRezerwacji || ""],
              [
                "guest",
                [
                  {
                    name: "lsi_guests_by_invoice",
                    query: { invoice_id: item.SCID },
                  },
                  (queryDB: any) => {
                    return queryDB?.[0]?.gosc_imie ||
                      queryDB?.[0]?.gosc_nazwisko
                      ? queryDB?.[0]?.gosc_imie
                          ?.replace(/ $/, "")
                          .replace(/^ /, "") +
                          " " +
                          queryDB?.[0]?.gosc_nazwisko
                            ?.replace(/ $/, "")
                            .replace(/^ /, "")
                      : "";
                  },
                ],
              ],
              [
                "gross_amount_original_document",
                Math.round(item.KwRozliczenia * 100) / 100 || "",
              ],
            ],
          },
          {
            visibleIf: (v: any) =>
              Number(v?.sales_document_type) === 1 ||
              Number(v?.sales_document_type) === 2,

            ...receiptSearch(data),
            resultUpdate: (item: any) => [
              [
                "lsi_document_number",
                item?.full_number, //`PAR/${item.docNo}`,
              ],
              [
                "date_of_sale_from_sales_document",
                new Date(item?.data_dzienna).toISOString().slice(0, 10),
              ],
              ["purchaser_number_on_document", item?.ko_firma],
              //   ["booking_number", item.numer_rezerwacji || ""],
              ["lsi_document_id", item?.id || ""],
              //   ["booking_id", item?.id_rezerwacji || ""],
              //   [
              //     "guest",
              //     item?.gosc_imie || item?.gosc_nazwisko
              //       ? item?.gosc_imie + " " + item?.gosc_nazwisko
              //       : "",
              //   ],
              [
                "gross_amount_original_document",
                Math.round(item.ValueBWal * 100) / 100 || "",
              ],
            ],
          },
          {
            name: "lsi_document_number",
            visibleIf: (v: any) =>
              v?.sales_document_type && v?.sales_document_type != 5,
            label: "Nr dokumentu",
            autoComplete: false,
            element: "input",
            readOnly: true,
            required: "Podaj nr dokumentu LSI",
          },
          {
            name: "date_of_sale_from_sales_document",
            label: "Data sprzedaży z dokumentu LSI",
            visibleIf: (v: any) =>
              v?.sales_document_type && v?.sales_document_type != 5,
            readOnly: true,
            inputType: "date",
            required: "Podaj datę sprzedaży",
          },
          {
            name: "purchaser_number_on_document",
            label: "Nazwa nabywcy na dokumencie",
            readOnly: true,
            visibleIf: (v: any) =>
              v?.sales_document_type && v?.sales_document_type != 5,
            // disabled: true,
            required: "Podaj nazwę nabywcy",
          },
          { name: "guest", label: "Gość", required: "Podaj gościa" },
          {
            name: "lsi_document_id",
            label: "Id dokumentu",
            outerStyle: {
              display: "none",
            },
            visibleIf: (v: any) =>
              v?.sales_document_type && v?.sales_document_type != 5,
            // disabled: true,
            format: "half-1",
          },
          {
            name: "booking_id",
            label: "Id rezerwacji",
            outerStyle: {
              display: "none",
            },
            visibleIf: (v: any) =>
              v?.sales_document_type &&
              v?.sales_document_type != 5 &&
              Number(v?.sales_document_type) !== 1 &&
              Number(v?.sales_document_type) !== 2,
            // disabled: true,
            format: "half-2",
          },
          {
            name: "booking_number",
            label: "Numer rezerwacji",
            visibleIf: (v: any) =>
              v?.sales_document_type && v?.sales_document_type != 5,
          },
          {
            name: "what_is_corrected",
            label: "Co podlega korekcie?",
            outerStyle: { marginTop: "25px" },
            element: "select",
            getOptionsFrom: "whatIsCorrected",
            required: "Wybierz, co podlega korekcie",
          },
          {
            name: "previous_value",
            label: "Było",
            step: 0.01,
            format: "half-1",
            inputType: "number",
            style: { width: "90px" },
          },
          {
            name: "should_be",
            label: "Powinno być",
            format: "half-2",
            inputType: "number",
            step: 0.01,
            style: { width: "90px" },
          },
          {
            name: "gross_amount_original_document",
            label: "Kwota brutto pierwotnego dokumentu",
            format: "half-1",
            style: { width: "110px" },
            inputType: "number",
            step: 0.01,
          },
          {
            name: "gross_value_as_corrected",
            label: "Łącznie prawidł. kwota brutto po korekcie",
            format: "half-2",
            style: { width: "110px" },
            inputType: "number",
            step: 0.01,
          },
        ],
        [
          {
            name: "reporting_form",
            element: "select-picker",
            getOptionsFrom: "reportingFormTypes",
            label: "Forma zgłoszenia problemu",
            required: "Sprecyzuj formę zgłoszenia problemu",
          },
          {
            name: "employee_involved_search",
            element: "hybrid",
            db: "employeesHybridField",
            format: "half-1",
            label: "Szukaj pracownika",
            // required: "Podaj pracownika związanego z problemem",
            query: (input: string | number) => ({
              multiQuery: input,
            }),
            resultElement: (item: any) => (
              <div style={{ width: "90%" }}>
                <div style={{ fontWeight: "500" }}>
                  {item?.first_name} {item?.last_name}
                </div>
              </div>
            ),
            resultUpdate: (item: any) => [
              ["employee_involved_id", item?.id],
              ["employee_involved_search", undefined],
              [
                "employee_involved_name",
                item?.first_name + " " + item?.last_name,
              ],
            ],
          },
          {
            name: "employee_involved_name",
            label: "Pracownik powiązany",
            element: "input",
            disabled: true,
            format: "half-2",
            initialValue:
              data?.employee_first_name || data?.employee_last_name
                ? data?.employee_first_name + " " + data?.employee_last_name
                : "",
          },
          {
            name: "date_of_discovery",
            inputType: "date",
            outerStyle: { marginTop: "10px" },
            label: (
              <>
                Data zgłoszenia przez Gościa
                <br />
                albo zauważenia przez Pracownika
              </>
            ),
            required: "Dodaj datę",
          },

          {
            name: "correction_reason",
            label: "Przyczyna korekty / Dokładny opis sytuacji",
            element: "textarea",
            required: "Podaj przyczynę korekty",
          },
          {
            name: "approved_solution",
            label: "Propozycja rozwiązania",
            element: "textarea",
          },
        ],
      ],
    },
  };
}
