import prettyDate, {
  dateString,
  prettyDateRange,
} from "../functions/prettyDate";
import prettyNumber from "../functions/prettyNumber";
import { flatOptions } from "../functions/tools";
import { calculateSettlementSums } from "./SumSettlement";
import {
  key_info_types,
  meal_sub,
  meal_type,
  miscellanea,
  music_type,
  schedule_item_type,
  special_options,
} from "./definitions/common";
import { event_calendar } from "./event_calendar";

function sameNumber(dates: any[], prop: string) {
  console.log(
    prop,
    dates?.map((d: any) => Number(d?.[prop])),
    dates && prop
      ? dates
          .map((d: any) => Number(d?.[prop]))
          .every((v: any, i: number, arr: any[]) => v == arr[0])
      : true
  );
  return dates && prop
    ? dates
        .map((d: any) => Number(d?.[prop]))
        .every((v: any, i: number, arr: any[]) => v == arr[0])
    : true;
}

function sameText(dates: any[], prop: string) {
  console.log(dates.map((d: any) => d?.[prop]?.replace(/ /g, "")));
  return dates && dates.length > 1
    ? dates
        .map((d: any) => d?.[prop]?.replace(/ /g, ""))
        .reduce((a: any, b: any) => a == b)
    : true;
}

export default function eventDataToWord(event: event_calendar) {
  const datesBasic = event.dates ? JSON.parse(event.dates) : [];
  const schedules = event.schedules ? JSON.parse(event.schedules) : [];
  const meals = event.meals ? JSON.parse(event.meals) : [];
  const payments = event.payments ? JSON.parse(event.payments) : [];
  const itemAmounts = event.item_amounts ? JSON.parse(event.item_amounts) : [];
  const comments = event.comments ? JSON.parse(event.comments) : [];
  const hotelRooms = event.hotel_rooms ? JSON.parse(event.hotel_rooms) : [];
  const keyInfo = event.key_info ? JSON.parse(event.key_info) : [];
  const weddingData: any = {};
  Object.keys(event)
    ?.filter((key: string) => key.match(/^wedding&/))
    ?.forEach((k: string) => {
      const newKey = k.replace(/wedding&(.*)/, "$1");
      weddingData[newKey] = event[k as keyof typeof event];
    });

  const sums = calculateSettlementSums({
    rooms: hotelRooms,
    items: itemAmounts,
    payments: payments,
  });

  const dates = datesBasic?.map((d: any, index: number) => ({
    ...d,
    dateShort: new Date(d.date)?.toLocaleDateString("pl-PL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }),
    weekDay: new Date(d.date)?.toLocaleDateString("pl-PL", { weekday: "long" }),
    datePL: new Date(d.date)?.toLocaleDateString("pl-PL", {
      day: "numeric",
      month: "long",
      year: "numeric",
    }),
    ready_before: () => {
      if (!d?._ready_before) {
        return false;
      }
      const date = new Date();
      let timeStart = schedules
        ?.filter((i: any) => i.date === d.date)
        ?.at(0)?.time_from;
      if (timeStart) {
        date.setHours(timeStart?.slice(0, 2), timeStart?.slice(4, 6), 0);
        date.setTime(date.getTime() - d?._ready_before * 60 * 1000);
      }
      return date.toLocaleTimeString().slice(0, 5);
    },
    time_from_min: schedules?.filter((i: any) => i.date === d.date)?.at(0)
      ?.time_from,
    time_to_max: schedules?.filter((i: any) => i.date === d.date)?.at(-1)
      ?.time_to,
    number: index + 1,
    schedule: schedules
      .filter((i: any) => i.date === d.date)
      ?.map((s: any) => ({
        ...s,
        description: s.description ? s.description : "",
        venue_string: s?.venue_string?.replace("sala ", ""),
        item_meals: meals
          .filter(
            (m: any) => s.guid === m.schedule_guid || s.id === m.schedule_id
          )
          .map((meal: any, index: number, allMeals: any) => ({
            ...meal,
            parent_sub:
              meal?.parent_field_name !==
              allMeals?.[index - 1]?.parent_field_name
                ? meal_sub.find((m: any) => m.value === meal?.parent_field_name)
                    ?.name
                : "",
            quantity: meal?.quantity ? `dla ${meal.quantity} os.` : "",
            time: meal?.time?.slice(0, 5) || "",
            category:
              index > 0 &&
              meal.category &&
              allMeals.at(index - 1).category === meal.category
                ? "same_as_above"
                : flatOptions(meal_type)?.find(
                    (o: any) => Number(o.id) === Number(meal.category)
                  )?.name || "",
            special:
              flatOptions(special_options)
                ?.find((o: any) => o?.id === meal?.special)
                ?.name?.toUpperCase() || "",
          })),
        meal_type_string: flatOptions(schedule_item_type).find(
          (o: any) => o.id == s.category
        )?.name,
        music_type_string: music_type.find((o: any) => o.id == s.music)?.name,
      })),
  }));

  const datesSimple = dates?.map((d: any) => d.date);

  // console.log(sums);
  const zl = { text: true, postfix: "zł" };

  const finalDataBlock = {
    ...event,
    ...sums?.text,
    wedding: weddingData,
    // person: event.created_string + (event.edited_string ? ` / ostatnia edycja: ${event.edited_string}` : ""),
    keyInfo: keyInfo.map((i: any) => ({
      ...i,
      category: flatOptions(key_info_types)?.find(
        (o: any) => Number(o?.id) === Number(i?.category)
      )?.name,
    })),
    //date: event.date ? new Date(event.date).toLocaleDateString() : "",
    dates: dates,
    chefs_max: dates
      .map((d: any) => Number(d._chefs))
      .sort((a: number, b: number) => b - a)
      ?.at(-1),
    waiters_max: dates
      .map((d: any) => Number(d._waiters))
      .sort((a: number, b: number) => b - a)
      ?.at(-1),
    dishwashers_max: dates
      .map((d: any) => Number(d._dishwashers))
      .sort((a: number, b: number) => b - a)
      ?.at(-1),
    chefs_same: sameNumber(dates, "_chefs"),
    waiters_same: sameNumber(dates, "_waiters"),
    dishwashers_same: sameNumber(dates, "_dishwashers"),
    service_same:
      sameNumber(dates, "_chefs") &&
      sameNumber(dates, "_waiters") &&
      sameNumber(dates, "_dishwashers"),
    stand_same: sameText(dates, "_stand_text"),
    adult_same: sameNumber(dates, "_guest_number_adult"),
    child_0_3_same: sameNumber(dates, "_guest_number_child_0_3"),
    child_4_12_same: sameNumber(dates, "_guest_number_child_4_12"),
    diet_same: sameNumber(dates, "_guest_number_diets"),
    guests_same:
      sameNumber(dates, "_guest_number_adult") &&
      sameNumber(dates, "_guest_number_child_0_3") &&
      sameNumber(dates, "_guest_number_child_4_12") &&
      sameNumber(dates, "_guest_number_diets"),
    // chefs_same: dates
    //   .map((d: any) => Number(d._chefs))
    //   .reduce((a: number, b: number) => a == b, true),
    // waiters_same: dates
    //   .map((d: any) => Number(d._waiters))
    //   .reduce((a: number, b: number) => a == b, true),
    // dishwashers_same: dates
    //   .map((d: any) => Number(d._dishwashers))
    //   .reduce((a: number, b: number) => a == b, true),

    guest_number_adult_max: dates
      .map((d: any) => Number(d._guest_number_adult))
      .sort((a: number, b: number) => b - a)
      ?.at(0),
    guest_number_child_max: dates
      .map(
        (d: any) =>
          Number(d._guest_number_child_0_3) + Number(d._guest_number_child_4_12)
      )
      .sort((a: number, b: number) => b - a)
      ?.at(0),
    guest_number_child_0_3_max: dates
      .map((d: any) => Number(d._guest_number_child_0_3))
      .sort((a: number, b: number) => b - a)
      ?.at(0),
    guest_number_child_4_12_max: dates
      .map((d: any) => Number(d._guest_number_child_4_12))
      .sort((a: number, b: number) => b - a)
      ?.at(0),
    guest_number_diet_max: dates
      .map((d: any) => Number(d._guest_number_diets))
      .sort((a: number, b: number) => b - a)
      ?.at(0),
    dateStrings: () => {
      return datesSimple.length === 1
        ? new Date(datesSimple.at(0)).toLocaleDateString("pl-PL", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })
        : datesSimple.length > 0
        ? prettyDateRange(datesSimple.at(0), datesSimple.at(-1), {
            format: "text",
            year: "always",
          })
        : "";
    },
    weekdayRange: () => {
      return datesSimple.length === 1
        ? new Date(datesSimple.at(0)).toLocaleDateString("pl-PL", {
            weekday: "long",
          })
        : datesSimple.length > 0
        ? new Date(datesSimple.at(0)).toLocaleDateString("pl-PL", {
            weekday: "long",
          }) +
          " — " +
          new Date(datesSimple.at(-1)).toLocaleDateString("pl-PL", {
            weekday: "long",
          })
        : "";
    },
    venues: [
      ...new Set(
        schedules.map((s: any) =>
          s.venue_string?.replace("+", " + ")?.replace("sala ", "")
        )
      ),
    ],
    // meals: meals.map((meal: any) => ({
    //   category: 0 /*meal_type.find((m: any) =>
    //     m.group
    //       ? m.options.find((m2: any) => meal.category === m2.id)
    //       : meal.category == m.id
    //   )?.name*/,
    //   name: meal.name ? meal.name : "brak danych",
    // })),
    customer_name:
      (event?.first_name ? event?.first_name?.replace(/ $/, "") : "") +
      " " +
      (event.last_name ? event?.last_name?.replace(/ $/, "") : ""),
    event_string: event.event_string?.toUpperCase(),
    time_start: event.time_start?.slice(0, 5),
    time_end: event.time_end?.slice(0, 5),
    payments: payments
      .filter((item: any) => item.docNo && item.date && item.net)
      .map((payment: any) => ({
        ...payment,
        date: new Date(payment.date).toLocaleDateString(),
        net: prettyNumber(payment.net, zl),
        gross: prettyNumber(payment.gross, zl),
      })),
    hotel_rooms: hotelRooms
      ?.filter((row: any) =>
        Object.keys(row)
          ?.filter((r: any) => r !== "vat")
          ?.reduce((a: any, b: any) => row[a] || row[b], false)
      )
      ?.map((room: any) => ({
        ...room,
        netUnit: prettyNumber(room.netUnit, zl),
        grossUnit: prettyNumber(room.grossUnit, zl),
        grossSum: prettyNumber(room.grossSum, zl),
      })),
    comments: comments
      .map((item: any, index: number) => ({ ...item, number: index + 1 }))
      .reverse(),
    venue_string: event.venue_string?.replace("sala ", ""),
    item_amounts: itemAmounts
      ?.filter((item: any) => item.amount)
      ?.map((item: any) => ({
        ...item,
        item: miscellanea.find((m: any) => item.item === m.id)?.name,
        unitPrice: prettyNumber(item.unitPrice, zl),
        unitPriceGross: prettyNumber(item.unitPriceGross, zl),
        totalGross: prettyNumber(item.totalGross, zl),
      })),
    file_generation_date: new Date()?.toLocaleString("pl-PL"),
  };
  finalDataBlock.dates = finalDataBlock?.dates?.map((d: any) => ({
    chefs_same: finalDataBlock.chefs_same,
    waiters_same: finalDataBlock.waiters_same,
    dishwashers_same: finalDataBlock.dishwashers_same,
    service_same: finalDataBlock.service_same,
    stand_same: finalDataBlock.stand_same,
    adult_same: finalDataBlock.adult_same,
    child_0_3_same: finalDataBlock.child_0_3_same,
    child_4_12_same: finalDataBlock.child_4_12_same,
    diet_same: finalDataBlock.diet_same,
    guests_same:finalDataBlock.guests_same,
    ...d,
  }));
  console.log(finalDataBlock);
  return finalDataBlock;
}
