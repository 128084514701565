import { prettyTime } from "../../functions/tools";
import { music_type } from "../definitions/common";
import Meals from "./Meals";
import musicIcon from "../../../images/icons/audio.svg";
import timeIcon from "../../../images/icons/time.svg";

export default function ScheduleItem({
  item,
  event,
  meals,
}: {
  item: any;
  event: any;
  meals: any;
}) {
  return (
    <div className="schedule-item">
      <div className="schedule-item-main">
        <div className="schedule-item-heading">
          <div className="times">
            <img src={timeIcon} />
            <div>
              {prettyTime(item.time_from)} - {prettyTime(item.time_to)}
            </div>
          </div>
          <div className="venue">{item.venue_string}</div>
        </div>
        <div className={`music m-${item.music}`}>
          <img src={musicIcon} />
          {music_type.find((m: any) => m?.id === item.music)?.jsx}
        </div>
        <div>{item.description}</div>
      </div>{" "}
      <Meals meals={meals} scheduleItem={item} event={event}/>
    </div>
  );
}
