import datesToRanges from "./datesToRanges";
import HolidayReportPersonTypes from "./HolidayReportPersonTypes";

export default function HolidayReportList({
  byPersonArray,
  aggregateDates,
  viewType,
  date,
  setDate,
}: {
  byPersonArray: any[];
  aggregateDates: any;
  viewType: string;
  date: any;
  setDate: Function;
}) {
  const months: any[] = [
    { name: "Styczeń", dates: [] },
    { name: "Luty", dates: [] },
    { name: "Marzec", dates: [] },
    { name: "Kwiecień", dates: [] },
    { name: "Maj", dates: [] },
    { name: "Czerwiec", dates: [] },
    { name: "Lipiec", dates: [] },
    { name: "Sierpień", dates: [] },
    { name: "Wrzesień", dates: [] },
    { name: "Październik", dates: [] },
    { name: "Listopad", dates: [] },
    { name: "Grudzień", dates: [] },
  ];
  return (
    <div className="holidayReportList">
      {viewType === "list" ? (
        <select
          style={{ marginTop: "20px", fontWeight:"bold", fontSize: "15px", padding: "12px" }}
          value={date.month}
          onChange={(e) =>
            setDate((prev: any) => ({ ...prev, month: e.target.value }))
          }
        >
          {months.map((m: any, n: number) => (
            <option value={n}>{m.name} {date.year}</option>
          ))}
        </select>
      ) : (
        <></>
      )}
      {byPersonArray.map((item: any) => {
        item.groups.forEach((group: any) =>
          group.dates.forEach((date: any) => {
            const mth = new Date(date);
            const mthNumber = mth.getMonth();
            months[mthNumber].dates.push({
              type: group.type,
              typename: group.name,
              typeshort: group.shortName,
              date: date,
              month: group.month,
              count: group.count,
            });
          })
        );
        return viewType === "monthList" ? (
          <div className="hrPerson">
            <div className="hrPersonName">
              {item.first_name} {item.last_name}
            </div>
            <div className="hrPersonMonths">
              {months.map((month: any) => (
                <div className="hrMonth">
                  <div className="hrMonthName">{month.name}</div>
                  <div className="hrPersonMonthList">
                    {month.dates.map((d: any, ind: number, arr: any[]) => (
                      <div className="hrPersonMonthDates">
                        {(ind > 1 && arr[ind - 1].typename !== d.typename) ||
                        ind === 0 ? (
                          <div className="hrPersonLittleTypeName">
                            {d.typeshort}
                          </div>
                        ) : (
                          <></>
                        )}
                        <span className={"circle u" + d.type}></span>
                        {new Date(d.date).toLocaleDateString("pl-PL", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}{" "}
                        <span style={{ fontSize: "67%", color: "darkgray" }}>
                          {new Date(d.date).toLocaleDateString("pl-PL", {
                            weekday: "short",
                          })}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <HolidayReportPersonTypes item={item} date={date} />
        );
      })}
    </div>
  );
}
