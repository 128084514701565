import invoiceIcon from "../../../../images/icons/invoice2.svg";
import receiptIcon from "../../../../images/icons/receipt2.svg";
import Form from "../../../forms_new/Form";
import InvoicesView from "../../../experiments/InvoicesView";
import LSIinvoiceView from "../../../experiments/LSIinvoiceView";
import ReceiptDetails from "../../../calendar/ReceiptDetails";
import DateDisplay from "../../../tables/viewElements/DateDisplay";

export default function SalesDocumentClickable({
  row,
  f,
}: {
  row: any;
  f: any;
}) {
  return (
    <div
      className="invoice-or-receipt-details"
      onClick={() => {
        console.log(f);
        f?.setModal({
          show: true,
          style: {
            width: Number(row?.sales_document_code) === 1 ? "800px" : "600px",
          },
          heading:
            Number(row?.sales_document_code) === 1
              ? "Faktura"
              : Number(row?.sales_document_code) === 2
              ? "Paragon"
              : "",
          content:
            Number(row?.sales_document_code) === 1 ? (
              <LSIinvoiceView id={row?.lsi_document_id} />
            ) : Number(row?.sales_document_code) === 2 ? (
              <ReceiptDetails id={row?.lsi_document_id} />
            ) : (
              <></>
            ),
        });
      }}
      style={{ display: "flex", alignItems: "center" }}
    >
      {row?.sales_document_code === 1 ? (
        <img
          style={{ opacity: ".9", paddingRight: "4px" }}
          src={invoiceIcon}
          width={19}
        />
      ) : row?.sales_document_code === 2 ? (
        <img
          style={{ opacity: ".9", paddingRight: "4px" }}
          src={receiptIcon}
          width={17}
        />
      ) : undefined}
      <div>
        <div className="doc-number">{row?.lsi_document_number}</div>
        <div
          style={{ position: "relative", fontSize: "90%", paddingLeft: "3px" }}
        >
          <DateDisplay
            dateString={row?.date_of_sale_from_sales_document}
            format="date"
          />
        </div>
      </div>
    </div>
  );
}
