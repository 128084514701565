import { useContext, useState } from "react";
import { ScheduleContext } from "../Schedules";
import ScheduleCell from "./ScheduleCell";
import { URL } from "../paths";

export default function ScheduleRow({
  employee,
  data,
  setTargetOrder,
  targetOrder,
  dragOver,
  setDragOver,
  dragged,
  setDragged,
  reorderEmployees,
}: {
  employee: any;
  data: any;
  setTargetOrder: Function;
  targetOrder: any;
  dragOver: any;
  setDragOver: Function;
  dragged: any;
  setDragged: Function;
  reorderEmployees: Function;
}) {
  const {
    defaultHours,
    setUpdate,
    days,
    employeeIDs,
    scheduleID,
    templateEdit,
  } = useContext(ScheduleContext);

  const defH = JSON.parse(defaultHours).find(
    (h: any) => h.employeeID === employee.id
  );

  const defHours =
    defH && defH.weekdays
      ? JSON.parse("[" + defH.weekdays.replace("\\", "") + "]")
      : [];

  async function removeEmployee() {
    if (
      !window.confirm("Czy chcesz usunąć pracownika " + employee.cardname + "?")
    )
      return;
    const employees = employeeIDs.split(",");
    const employeesLess = employees.filter(
      (e: any) => Number(e) !== Number(employee.id)
    );
    console.log(employees, employeesLess);
    const response = await fetch(
      URL + "/backend/schedules/edit-employees.php",
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          id: scheduleID,
          employeeIDs: employeesLess.join(","),
        }),
      }
    );
    const result = await response.json();
    setUpdate((prev: number) => prev + 1);

    return result;
  }

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  //console.log(defHours)
  return employee && days ? (
    <tr
      draggable={templateEdit ? true : false}
      style={{
        border: dragOver === employee.id ? "2px solid orange" : "inherit",
        opacity: dragged === employee.id ? ".01" : "1",
      }}
      onDragStart={(e) => (templateEdit ? setDragged(employee.id) : false)}
      onDragEnd={(e) => {
        setDragged(false);
      }}
    >
      <td className="employee">
        <div
          data-order={employee.order}
          className="employeeCellWrap"
          onDragEnter={(e) => {
            handleDrop(e);
            setDragOver(employee.id);
            const target = e.target as HTMLElement;
          }}
          onDragOver={(e) => {
            if (!templateEdit) return;
            handleDrop(e);

            const target = e.target as HTMLElement;
            setTargetOrder(Number(target.dataset.order) - 1);
            console.log(Number(target.dataset.order) - 1);
          }}
          onDrop={(e) => {
            if (!templateEdit) return;
            handleDrop(e);
            reorderEmployees(employee, scheduleID);
          }}
          onDragLeave={(e) => {
            if (!templateEdit) return;
            setDragOver(false);
          }}
        >
          {employee.first_name} {employee.last_name}{" "}
          {templateEdit ? (
            <div className="rowControls">
              <div onClick={() => removeEmployee()}>✖</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </td>
      {days.map((day: Date, nthDay: number) => (
        <ScheduleCell
          scheduleID={scheduleID}
          employee={employee}
          day={day}
          nthDay={nthDay}
          key={day.toLocaleDateString()}
          data={data.find(
            (d: any) => d.date === day.toISOString().slice(0, 10)
          )}
          defaultHours={defHours.find((h: any) => h.day === day.getDay())}
        />
      ))}
    </tr>
  ) : (
    <></>
  );
}
