export function row(row?: any): any[] {
  return [
    {
      source: "id",
      name: "ID",
      value: row?.id,
      className: "id",
    },
    {
      source: "color",
      name: "Kolor",
      style: { padding: 0, width: "60px" },
      innerStyle: {
        boxShadow: "0 0 10px rgba(0,0,0,0.14) inset",
        background: row?.color,
        width: "100%",
        height: "30px",
      },
      cardValueStyle: {
        boxShadow: "0 0 10px rgba(0,0,0,0.14) inset",
        background: row?.color,
      },
      value: <> </>,
    },
    {
      source: "name",
      name: "Nazwa",
      value: row?.name,
    },
  ];
}
