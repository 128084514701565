import { useContext, useEffect, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import prettyDate from "../functions/prettyDate";
import { ViewContext } from "../contexts/ViewContext";

export default function InvoiceSelector({
  scid,
  setRow,
}: {
  scid: any;
  setRow: Function;
}) {
  const { setModal2 } = useContext(ViewContext);
  const fetchInvoiceItems = useGeneric({
    name: "lsi_invoice_query",
    query: {
      scid: scid,
    },
    limit: 200,
  });

  const invoiceItems = !fetchInvoiceItems.loading
    ? fetchInvoiceItems.rows
    : undefined;

  const [selected, setSelected] = useState<any>({});

  useEffect(() => {
    if (!fetchInvoiceItems.loading) {
      setSelected((prev: any) => {
        const def: any = {};
        invoiceItems.forEach((item: any) => (def[item.SCPID] = false));
        return def;
      });
    }
  }, [fetchInvoiceItems.loading]);

  console.log(selected);

  const selectedFull = invoiceItems
    ? invoiceItems.filter(
        (i: any) =>
          Object.keys(selected).includes(i.SCPID) && selected[i.SCPID] === true
      )
    : [];

  const selectedGross = selectedFull
    ? Math.round(
        selectedFull
          .map((n: any) => Math.round(n.item_gross * 100) / 100)
          .reduce((a: any, b: any) => a + b, 0) * 100
      ) / 100
    : 0;

  const selectedNet = selectedFull
    ? Math.round(
        selectedFull
          .map((n: any) => Math.round(n.item_net * 100) / 100)
          .reduce((a: any, b: any) => a + b, 0) * 100
      ) / 100
    : 0;

  const selectedVAT = selectedFull
    ? selectedFull.map((n: any) => n.VAT).join(", ")
    : 0;

  return invoiceItems && invoiceItems.length ? (
    <div className="invoice-selector">
      <div className="invoice-general-info">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "8px",
          }}
        >
          <div style={{ fontSize: "18px" }}>
            FV/{invoiceItems[0].DocNo}/{invoiceItems[0].month}/
            {invoiceItems[0].year}/H
          </div>
          <div style={{ fontSize: "11px" }}>{invoiceItems[0].pos}</div>
          <div style={{ fontSize: "12px" }}>
            {prettyDate(invoiceItems[0].data_modyfikacji)}
          </div>
        </div>
        <div>{invoiceItems[0].ko_firma}</div>
      </div>
      <div className="invoice-table">
        <div
          className="invoice-rows-heading"
          onClick={() => {
            setSelected((prev: any) => {
              const n: any = {};
              const allSelected = Object.keys(selected)
                .map((key: any) => selected[key])
                .reduce((a: any, b: any) => a && b, true);
              //const noneSelected = !Object.keys(selected).map((key:any) => selected[key]).find((i:any) => i);

              Object.keys(selected).forEach((key: any) => {
                n[key] = allSelected ? false : true;
              });

              return n;
            });
          }}
        >
          <div>
            {" "}
            <input
              checked={Object.keys(selected)
                .map((key: any) => selected[key])
                .reduce((a: any, b: any) => a && b, true)}
              type="checkbox"
            />
          </div>
          <div>Pozycja</div>
          <div>Ilość</div>
          <div>VAT</div>
          <div>Netto</div>
          <div>Brutto</div>
        </div>
        {invoiceItems.map((item: any, index: any) => (
          <div
            onClick={(e) => {
              setSelected((prev: any) => ({
                ...prev,
                [item.SCPID]: !prev[item.SCPID],
              }));
            }}
            className={"invoice-row" + (selected[item.SCPID] ? " checked" : "")}
            key={item.SCPID}
          >
            <div>
              <input checked={selected[item.SCPID]} type="checkbox" />
            </div>
            <div>{item.description}</div>
            <div>{item.amount}</div>
            <div>{item.VAT}</div>
            <div>{Math.round(item.item_net * 100) / 100}</div>
            <div>{Math.round(item.item_gross * 100) / 100}</div>
          </div>
        ))}
      </div>

      <div className="invoice-selected-summary">
        <div className="invoice-summary-data">
          <div>Netto: {selectedNet}</div>
          <div>Brutto: {selectedGross}</div>
        </div>
        <button
          className="normal-button"
          type="button"
          style={{
            visibility: Object.keys(selected).filter(
              (key: any) => selected[key]
            ).length
              ? "visible"
              : "hidden",
          }}
          onClick={() => {
            setRow((prev: any) => ({
              ...prev,
              gross: selectedGross,
              vat: selectedVAT,
              net: selectedNet,
            }));
            setModal2((prev: any) => ({ ...prev, show: false }));
          }}
        >
          Wybierz
        </button>
      </div>
    </div>
  ) : (
    <></>
  );
}
