import Uppy from "@uppy/core";
import { DragDrop, StatusBar } from "@uppy/react";
import XHRUpload from "@uppy/xhr-upload";
import Polish from "@uppy/locales/lib/pl_PL";
import { URL, URLProd } from "../paths";
import { useState } from "react";
import { Field, useFormikContext } from "formik";

export const uppyParameters = {
  id: Math.random(),
  locale: Polish,
  onBeforeFileAdded: (currentFile, files) => {
    const name = Date.now() + "_" + currentFile.name;
    const modifiedFile = {
      ...currentFile,
      meta: {
        ...currentFile.meta,
        name,
      },
      name,
    };
    uppy.log(modifiedFile.name);
    return modifiedFile;
  },
  restrictions: {
    maxFileSize: 10485760, // 10 MB
    maxTotalFileSize: 32505856, // 31 MB
    maxNumberOfFiles: 3,
    allowedFileTypes: [".pdf", "image/*"],
  },
};

const uppy = new Uppy(uppyParameters).use(XHRUpload, {
  endpoint: URL + "uploads/upload.php",
});

export default function FileUpload() {
  const [files, setFiles] = useState({ successful: [], failed: [] });

  const formikProps = useFormikContext();

  uppy.on("complete", (result) => {
    setFiles({ successful: result.successful, failed: result.failed });
    formikProps.setFieldValue("filenames", result.successful.map((f) => f.name).join(","))
  });


  return (
    <div style={{ padding: "10px" }}>
      <div>
        <DragDrop uppy={uppy} />
        <StatusBar uppy={uppy} hideAfterFinish={false} />
      </div>
      <div>
        <div style={{ display: "flex" }}>
          {files.successful ? (
            <div className="field">
              <Field
                name="filenames"
                hidden={true}
                value={formikProps.values.filenames} // or whatever the value is
                onChange={formikProps.handleChange}
                //value={files.successful.map((f) => f.name).join(",")}
              />
              <div className="file_preview">
               { formikProps.values.filenames.split(",").map(item => 
                <><img style={{width:"50px"}} src={URL + "uploads/files/" + item}/>
                <p style={{fontSize:"9px"}}>{item}</p></>
               )}
                </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
