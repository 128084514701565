import { useContext, useEffect } from "react";
import { FormDataContext } from "../Form";
import { useFormContext } from "react-hook-form";
import {
  formField,
  option,
  optionSingle,
  optionsList,
} from "../formContentType";
import Loading from "../../common/Loading";

export default function Select({
  schema,
  hidden,
  setManualChanges,
}: {
  schema: formField;
  hidden: any;
  setManualChanges: Function;
}) {
  const { options, data } = useContext(FormDataContext);
  const {
    name,
    getOptionsFrom,
    required,
    validate,
    optionsList,
    initialValue,
  } = schema;
  const {
    register,
    setValue,
    formState: { errors, touchedFields },
  } = useFormContext();

  // console.log("options", getOptionsFrom);
  const optionsSpecs = getOptionsFrom?.name ? getOptionsFrom?.name : getOptionsFrom;
  const optionsForThis: any =
    options && optionsSpecs
      ? options[optionsSpecs as keyof typeof options]
      : undefined;

  const optionsObject = optionsForThis ? optionsForThis.rows : optionsList;

  useEffect(() => {
    if (data && data[name as keyof typeof data] !== undefined) {
      setValue(name, data[name as keyof typeof data]);
    } else if (initialValue) {
      setValue(name, initialValue);
    }
  }, [JSON.stringify(optionsObject)]);

  return optionsForThis?.rows && !Object.keys(optionsObject).length ? (
    <div className="loadingOptions">
      <Loading little={true} />
    </div>
  ) : (
    <select
      className={
        (errors?.[name] ? "error" : "") +
        (touchedFields?.[name] ? " touched" : "")
      }
      disabled={schema?.readOnly ? true : false}
      hidden={hidden}
      title={errors[name]?.message?.toString()}
      id={name}
      {...register(name, {
        required: required,
        validate: validate,
        onChange: () => setManualChanges((prev: number) => prev + 1),
        value: String(schema?.subDefaults?.["_" + name?.split("%")?.[0]]),
      })}
    >
      <option value=""></option>
      {optionsObject?.map((option: optionSingle) => (
        <option
          disabled={option?.active === false}
          value={String(option.id)}
          key={name + "." + option.id}
        >
          {option.name}
        </option>
      ))}
    </select>
  );
}
