import { formField, formFull } from "../formContentType";
import FieldSet from "./FieldSet";

export default function SubTabFields({
  index,
  subItem,
  setSubItem,
  item,
  propName,
  settings,
  topSchema
}: {
  index: number | string;
  subItem: any;
  setSubItem: Function;
  item: any;
  propName: string;
  settings: any;
  topSchema: formField;
}) {
    // console.log(settings.fields)
  return (
    <div
      className={
        "sub-tab-fields" + (subItem === item?.[propName] ? " active" : "")
      }
    >
      <FieldSet
        schema={settings.fields}
        subItem={subItem}
        setSubItem={setSubItem}
        tabSpecific={item?.[propName]}
        defaultsSet={item}
        display={true}
        key={index}
        readOnly={topSchema?.readOnly}
      />
    </div>
  );
}
