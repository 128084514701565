export default function NewScheduleTable({
    employees,
  }: {
    employees: any[];
  }) {
    return (
      <table className="scheduleTable">
        <thead>
          <tr>
            <th>Pracownik</th>
            <th>Poniedziałek</th>
            <th>Wtorek</th>
            <th>Środa</th>
            <th>Czwartek</th>
            <th>Piątek</th>
            <th>Sobota</th>
            <th>Niedziela</th>
          </tr>
        </thead>
        <tbody>
          {employees.map((employee: any) => (
            <tr>
              <td>
                {employee.first_name} {employee.last_name}
              </td>
              {[1, 2, 3, 4, 5, 6, 0].map((day: number) => (
                <td className="weekday" key={employee.id + "." + day}>
                  
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
  