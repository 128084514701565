import { useState } from "react";
import FileUpload from "./FileUpload";
import FileUploadList from "./FileUploadList";

export default function DocumentModule({
  title,
  module,
  subModule,
  moduleID,
  setUpdates,
  uploadedFiles,
}: {
  title: string;
  module: string;
  subModule: string;
  moduleID: number;
  setUpdates: Function;
  uploadedFiles: any;
}) {
  const [uploading, setUploading] = useState(false);
  return (
    <>
      <div className="heading">
        <h2>{title}</h2>
        {uploading ? (
          <div className="add-files-toggle" onClick={() => setUploading(false)}>
            ✖ Zamknij
          </div>
        ) : (
          <div className="add-files-toggle" onClick={() => setUploading(true)}>
            + Dodaj pliki
          </div>
        )}
      </div>
      {uploading ? (
        <div>
          <FileUpload
            module={module}
            subModule={subModule}
            moduleID={moduleID}
            setUpdates={setUpdates}
          />
        </div>
      ) : (
        <></>
      )}
      <FileUploadList
        module={module}
        subModule={subModule}
        uploadedFiles={uploadedFiles}
        setUpdates={setUpdates}
      />
    </>
  );
}
