import { Form, Formik } from "formik";
import insertGeneric from "./components/dataFetch/insertGeneric";
import FieldAndLabel from "./components/forms/FieldAndLabel";
import * as Yup from "yup";
import { useState } from "react";

const signUpSchema = Yup.object().shape({
  username: Yup.string()
    .min(5, "Co najmniej 5 znaków")
    .required("Pole wymagane"),
  email: Yup.string()
    .email("Wpisz prawidłowy adres e-mail")
    .required("Pole wymagane"),
  first_name: Yup.string().min(2, "Za krótkie").required("Pole wymagane"),
  last_name: Yup.string().min(2, "Za krótkie").required("Pole wymagane"),
});

export default function RegisterUser() {
  function addUser(values: any) {
    //window.alert("success");
    insertGeneric("add-user", values);
  }

  const [submitted, setSubmitted] = useState(false);

  return (
    <div
      className="editor"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        boxShadow: "2px 2px 8px rgba(0,0,0,.2)",
      }}
    >
      <div
        style={{
          border: "1px solid lightgrey",
          width: "100%",
          maxWidth: "300px",
          padding: "30px",
          background: "rgba(255,255,255,.6)",
        }}
      >
        <h1>Nowy użytkownik</h1>
        {!submitted ? (
          <Formik
            initialValues={{
              username: "",
              first_name: "",
              last_name: "",              
              email: "",
            }}
            validationSchema={signUpSchema}
            onSubmit={(values) => {
              setSubmitted(true);
              addUser(values);
            }}
          >
            {(formik: any) => {
              const { values, isValid, dirty } = formik;

              return (
                <Form>
                  <FieldAndLabel name="first_name" label="Imię" />
                  <FieldAndLabel name="last_name" label="Nazwisko" />
                  <FieldAndLabel name="username" label="Nazwa użytkownika" />
                  <FieldAndLabel type="email" name="email" label="E-mail" />
        

                  <button
                    style={{
                      marginTop: "15px",
                      opacity: isValid ? "1" : ".25",
                    }}
                    className="button"
                    type="submit"
                  >
                    Dodaj użytkownika<br/>i prześlij link aktywacyjny
                  </button>
                </Form>
              );
            }}
          </Formik>
        ) : (
          <div>
            Wysłano e-mail, który umożliwi rejestrację i konfigurację konta.
          </div>
        )}
      </div>
    </div>
  );
}
