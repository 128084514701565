import { useEffect, useState } from "react";

export default function Clock() {
  const [clock, setClock] = useState(() => new Date());
  useEffect(() => {
    const updateClock = setInterval(() => {
      setClock(new Date());
    }, 1000);
    return () => clearInterval(updateClock);
  }, []);

  return <div className="clock">{clock.toLocaleTimeString()}</div>;
}
