import { Link } from "react-router-dom";
import genericIcon from "../../images/icons/generic-data.svg";

export default function RegistersTab({
  section,
  auth,
  selected,
  setSelected,
  setMobileShow,
}: {
  section: any;
  auth: any;
  selected: any;
  setSelected: Function;
  setMobileShow: Function;
}) {
  const disabled = !auth || auth < 1;
  return (
    <Link
      className={
        (selected === section.name ? "active" : "") +
        (disabled ? " disabled" : "")
      }
      to={!disabled ? section.name : ""}
      title={auth}
      onClick={() => {
        if (!disabled) {
          setSelected(section.name);
          setMobileShow(false);
        }
      }}
    >
      <div className="with-icon">
        <img src={section?.icon || genericIcon} title={section?.text} />
        <span className="section-text">{section.text}</span>
      </div>
    </Link>
  );
}
/*
<Link
  className={
    (selected === sub.name ? "active" : "") +
    (!auth?.[section?.name] || auth?.[section?.name] < 1 ? " disabled" : "")
  }
  to={sub.name}
  title={auth?.[section?.name]}
  onClick={() => setSelected(sub.name)}
>
  {sub.icon ? (
    <div className="with-icon">
      <img src={sub.icon} title={sub?.text} />
      <span className="section-text">{sub.text}</span>
    </div>
  ) : (
    <span>{sub.text}</span>
  )}
</Link>;*/
