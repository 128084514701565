import { useContext, useState } from "react";
import ChatSum from "../../../tables/ChatSum";
import insertGeneric from "../../../dataFetch/insertGeneric";
import { ItemContext } from "../../ViewItem";

import { RegisterViewContext } from "../../View";
import ErrorOptionButtons from "./ErrorOptionButtons";
import { resolveEntry } from "./functions";

export default function RowMore({ setUpdates }: { setUpdates: Function }) {
  const [statusEdit, setStatusEdit] = useState(false);

  const { row } = useContext(ItemContext);
  //   const [urgent, setUrgent] = useState(false);

  return (
    <div
      className="row-more"
      style={{
        marginBottom: "21px",
        boxShadow: "1px 0 5px rgba(0,0,0,.2)",
      }}
    >
      <ChatSum
        query={{
          table: "error_register",
          table_id: row.id,
          details: row,
        }}
        moreButtons={
          <ErrorOptionButtons row={row} setStatusEdit={setStatusEdit} />
        }
        activatedWindow={
          statusEdit ? (
            <div className="error-resolve-options">
              <button
                onClick={() =>
                  resolveEntry({
                    value: !row?.resolved,
                    row: row,
                    f: { setUpdates: setUpdates, setStatusEdit: setStatusEdit },
                  })
                }
                className="normal-button"
              >
                {row?.resolved ? <>Otwórz</> : <>Rozwiąż</>}
              </button>
            </div>
          ) : (
            false
          )
        }
      />

      {/* <Chat
      query={{
        table: "error_register",
        table_id: row.entry_number,
      }}
    /> */}
    </div>
  );
}
