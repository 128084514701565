import insertGeneric from "../dataFetch/insertGeneric";
import Form from "../forms_new/Form";
import contractSchema from "./addContract";

export default function EditContract({
  id,
  employee,
  setUpdates,
  setModal,
  editData,
}: {
  id?: number;
  employee: any;
  setUpdates: Function;
  setModal: Function;
  editData?: any;
}) {
  return (
    <Form
      name="contractForm"
      className="contract-form"
      schema={{
        basic: {
          name: "Dane podstawowe",
          content: contractSchema(editData, employee)
        },
      }}
      //   auth={auth}
      data={editData ? editData : {}}
      //   defaultSubTab={date?.obj?.toISOString()?.slice(0, 10)}
      submit={async (data: any) => {
        console.log(data);
        data["employee_id"] = employee.id;
        if (id) {
          data["id"] = id;
        }
        return await insertGeneric("employee-contract", data);
      }}
      sideEffects={() => {
        setUpdates((prev: any) => prev + 1);
        setModal((prev: any) => ({ ...prev, show: false }));
      }}
    />
  );
}
