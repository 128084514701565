import { Form, Formik } from "formik";
import { useEffect, useState, Fragment } from "react";
import useGeneric from "../dataFetch/useGeneric";
import FieldAndLabel from "./FieldAndLabel";
import { formContent } from "./formContent";
import GenericFormInner from "./GenericFormInner";

export type formProps = {
  name?: string;
  formContent: formContent[] | formContent[][];
  onSubmit?: Function;
  sideEffects?: Function;
  gatherData?: Function;
  validateOn?: number;
  validationSchema?: any;
  temp?: [number, Function];
};

export default function GenericForm({
  name,
  formContent,
  onSubmit,
  sideEffects,
  gatherData,
  validateOn,
  validationSchema,
  temp,
}: formProps) {
  const [updateTimes, setUpdateTimes] = useState(0);

  const prefetchDBs = formContent
    .map((f: any) =>
      Array.isArray(f)
        ? f.map((ff: any) => ff.getOptionsFrom)
        : f.getOptionsFrom
    )
    .flat()
    .filter((item: any) => item);

  //  console.log(prefetchDBs);
  const options = useGeneric({ name: prefetchDBs, limit: 100, active: true });

  useEffect(() => {
    if (updateTimes > 0 && sideEffects) {
      sideEffects();
    }
  }, [updateTimes]);

  const initialValues: any = {};
  formContent.forEach((f) => {
    if (Array.isArray(f)) {
      f.forEach((ff: any) => (initialValues[ff.name] = ff.initialValue));
    } else {
      initialValues[f.name] = f.initialValue;
    }
  });
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values) => {
        if (onSubmit) {
          await onSubmit(values);
          setUpdateTimes((prev: number) => prev + 1);
        }
      }}
    >
      <GenericFormInner
        name={name}
        gatherData={gatherData}
        formContent={formContent}
        options={options}
        validateOn={validateOn}
      />
    </Formik>
  );
}
