import { useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import ScheduleTable from "./ScheduleTable";
import CalendarControls from "../common/CalendarControls";
import { URL } from "../paths";
import TemplateBindingEditor from "./TemplateBindingEditor";

export default function ScheduleEditor({
  schedule,
  employees,
  categories,
  update,
  setUpdate,
  options,
  setOptions,
  templates,
}: {
  schedule: any;
  employees: any[];
  categories: any;
  update: number;
  setUpdate: Function;
  options: boolean;
  setOptions: Function;
  templates: any;
}) {
  const [week, setWeek] = useState(() => {
    const d = new Date();
    const a = new Date(d.getFullYear(), 0, 1);
    const c = d.getTime() - a.getTime();
    return (c / 1000 / 60 / 60 / 24 + 1) / 7;
  });
  const [day, setDay] = useState(0);
  const [templateEdit, setTemplateEdit] = useState(false);
  const weekDays = generateWeek(week);
  const [editTemplateBinding, setEditTemplateBinding] = useState(false);

  async function deactivateSchedule() {
    const response = await fetch(
      URL + "/backend/schedules/deactivate-schedule.php",
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          active: 0,
          id: schedule.id,
        }),
      }
    );
    const result = await response.json();
    setUpdate((prev: number) => prev + 1);
    return result;
  }

  return editTemplateBinding ? (
    <TemplateBindingEditor
      schedule={schedule}
      setUpdate={setUpdate}
      templates={templates}
      set={setEditTemplateBinding}
    />
  ) : schedule && employees && weekDays ? (
    <div className="schedule">
      <div className="buttons">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            padding: "2px 15px",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {templateEdit ? (
            <>
              <span style={{ fontSize: "13px" }}>
                Edytujesz szablon grafiku
              </span>
              <button
                style={{ marginLeft: "5px", padding: "4px 12px" }}
                onClick={() => setTemplateEdit(false)}
              >
                Powrót
              </button>
            </>
          ) : (
            <></>
          )}
        </div>

        <div
          className="weekControls"
          style={{ visibility: templateEdit ? "collapse" : "visible" }}
        >
          <button
            onClick={() => {
              setWeek((prev: number) => prev - 1);
            }}
          >
            ◂
          </button>
          <div className="weekTitle" key={String(weekDays.first)}>
            {weekDays.weekTitle}
          </div>
          <button onClick={() => setWeek((prev: number) => week + 1)}>▸</button>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            textAlign: "right",
          }}
        >
          <button
            className="options editButton"
            onClick={(e) => {
              setOptions((prev: any) => !prev);
              console.log(e.target);
            }}
          >
            <span
              className="options"
              style={{ rotate: "90deg", fontSize: "18px" }}
            >
              ⋮
            </span>
          </button>
          {options ? (
            <div className="options options-menu">
              <button
                className={"options editButton"}
                onClick={() => {
                  setTemplateEdit((prev: boolean) => !prev);
                }}
              >
                {!templateEdit ? (
                  <>
                    <span>✎</span>
                    <span>Edytuj grafik</span>
                  </>
                ) : (
                  <>
                    <span>◂</span>
                    <span>Powrót</span>
                  </>
                )}
              </button>
              {templateEdit ? (
                <>
                  <button
                    className={"options editButton"}
                    onClick={() => {
                      setEditTemplateBinding((prev: boolean) => !prev);
                    }}
                  >
                    <span>T</span>
                    <span>Szablony godzin</span>
                  </button>
                  <button
                    className={"options editButton"}
                    style={{ color: "brown" }}
                    onClick={() => {
                      const really = window.confirm(
                        "Czy na pewno chcesz usunąć grafik?"
                      );
                      if (really) {
                        deactivateSchedule();
                      }
                      //setTemplateEdit((prev: boolean) => !prev);
                    }}
                  >
                    <>
                      <span>✖</span>
                      <span>Usuń grafik</span>
                    </>
                  </button>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="dayControls">
        <button
          onClick={() => {
            if (day !== 0) {
              setDay((prev: number) => prev - 1);
            } else {
              setDay(6);
              setWeek((prev: number) => week - 1);
            }
          }}
        >
          ◂
        </button>
        <div className="weekTitle" key={String(weekDays.first)}>
          {weekDays?.week[day]?.toLocaleDateString("pl-PL", {
            weekday: "short",
            day: "numeric",
            month: "long",
          })}
        </div>
        <button
          onClick={() => {
            if (day !== 6) {
              setDay((prev: number) => prev + 1);
            } else {
              setDay(0);
              setWeek((prev: number) => week + 1);
            }
          }}
        >
          ▸
        </button>
      </div>
      <ScheduleTable
        employees={employees.filter((item: any) =>
          schedule.employeeIDs.split(",").includes(String(item.id))
        )}
        otherEmployees={employees.filter(
          (item: any) =>
            !schedule.employeeIDs.split(",").includes(String(item.id))
        )}
        selectedDay={day}
        specials={templates}
        employeeIDs={schedule.employeeIDs}
        categories={categories}
        key={schedule.id}
        id={schedule.id}
        defaultHours={
          schedule.defaultsArray /*? JSON.parse(schedule.defaultsArray.replace("\\", "")): undefined*/
        }
        days={weekDays.week}
        templateEdit={templateEdit}
        setUpdate={setUpdate}
        update={update}
      />
    </div>
  ) : (
    <></>
  );
}

function generateWeek(week: number) {
  let day = <></>;
  let lastDay: Date = new Date();
  lastDay.setHours(12);
  const weekDates = [];
  const firstDay = new Date(new Date().getFullYear(), 0, 1);
  firstDay.setHours(12);
  firstDay.setDate(firstDay.getDate() * 7 * week);
  const weekDay = firstDay.getDay();
  firstDay.setDate(firstDay.getDate() - (weekDay === 0 ? 7 : weekDay) + 1);

  day = (
    <>
      {firstDay.toLocaleDateString("pl-PL", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        weekday: "long",
      })}
    </>
  );

  for (let i = 0; i < 7; i++) {
    const nextDay = new Date(firstDay);
    nextDay.setDate(firstDay.getDate() + i);
    weekDates.push(nextDay);
    lastDay = nextDay;
  }

  return {
    first: day,
    week: weekDates,

    weekTitle: (
      <>
        {firstDay.toLocaleDateString("pl-PL", {
          day: "numeric",
          month:
            firstDay.getMonth() !== lastDay.getMonth() ? "long" : undefined,
        })}
        {" - "}
        {lastDay.toLocaleDateString("pl-PL", {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
      </>
    ),
  };
}
