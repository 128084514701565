import {
  Font,
  StyleSheet,
  View,
  Document,
  Page,
  PDFViewer,
  Image,
  Text,
} from "@react-pdf/renderer";

import { URL } from "../paths";
import QRCode from "qrcode";
import { useCallback } from "react";

Font.register({
  family: "Lato",
  fonts: [
    {
      src: URL + "backend/fonts/Lato/Lato-Light.ttf",
      fontWeight: 300,
    },
    {
      src: URL + "backend/fonts/Lato/Lato-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: URL + "backend/fonts/Lato/Lato-Bold.ttf",
      fontWeight: 600,
    },
  ],
});

Font.register({
  family: "Nyala",
  fonts: [
    {
      src: URL + "backend/fonts/Nyala/nyala.ttf",
    },
  ],
});

Font.register({
  family: "EBGaramond",
  fonts: [
    {
      src: URL + "backend/fonts/EBGaramond/EBGaramond-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: URL + "backend/fonts/EBGaramond/EBGaramond-SemiBold.ttf",
      fontWeight: 500,
    },
    {
      src: URL + "backend/fonts/EBGaramond/EBGaramond-Bold.ttf",
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: "Noto Serif",
  fonts: [
    {
      src: URL + "backend/fonts/NotoSerif/NotoSerif-Light.ttf",
      fontWeight: 200,
    },
    {
      src: URL + "backend/fonts/NotoSerif/NotoSerif-Regular.ttf",
      fontWeight: 400,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "EBGaramond",
    fontSize: "10pt",
    fontWeight: 200,
  },
  pageBackground: {
    position: "absolute",
    minWidth: "100%",
    minHeight: "100%",
    // height:"100%",
    width: "100%",
  },
  voucherBackground: {
    padding: "5mm",
    height: "9.85cm",
  },
  absolute: {
    position: "absolute",
  },
  tighten: {
    marginBottom: "-1px",
  },
});

const Background = ({ i, index }: { i: any; index: number }) => {
  function QRCodePDF(props: { url: string; style?: any }) {
    const urlPromise = QRCode.toDataURL(props.url, {
      color: {
        dark: "#8e5213", // Blue dots
        light: "#ffffff6b", // Transparent background
      },
    });
    return (
      <Image
        style={[
          {
            width: "2.1cm",
            height: "2.1cm",
            padding: 0,
          },
          props.style || {},
        ]}
        src={urlPromise}
      />
    );
  }
  return (
    <>
      {i?.type !== 99 ? (
        <Image
          style={styles.voucherBackground}
          src={"img/vouchers/voucher_back.jpg"}
        />
      ) : (
        <View style={styles.voucherBackground}></View>
      )}
      {(index + 1) % 3 !== 0 ? (
        <View style={{ borderTop: "1px dotted rgb(210,210,210)" }}></View>
      ) : (
        <></>
      )}
    </>
  );
};

const Texts = ({ i, index }: { i: any; index: number }) => {
  const offset = 9.85;

  const QR = useCallback(() => {
    const urlPromise = QRCode.toDataURL(
      String(i?.paid ? "" : "G") + i?.amount + "-" + i?.number,
      {
        color: {
          dark: "#8e5213", // Blue dots
          light: "#ffffff6b", // Transparent background
        },
      }
    );
    return (
      <Image
        style={{
          width: "2.1cm",
          height: "2.1cm",
          padding: 0,
        }}
        src={urlPromise}
      />
    );
  }, [i?.number, i?.amount, i?.paid]);

  return i?.type === 99 ? (
    <View
      break
      style={{ position: "absolute", width: "100%", height: offset + "cm" }}
    ></View>
  ) : (
    <View
      break
      style={{ position: "absolute", width: "100%", height: offset + "cm" }}
    >
      <View
        style={[
          styles.absolute,
          {
            top: 5 + 2.5 + offset * index * 10 + "mm",
            left: 5 + 2.2 + "mm",
          },
        ]}
      >
        <Text style={[styles.tighten, { fontSize: "9pt", fontWeight: 700 }]}>
          Hotel AGIT Congress & SPA
        </Text>
        <View
          style={{
            fontSize: "9pt",
            fontWeight: 200,
          }}
        >
          <Text style={styles.tighten}>ul. Wojciechowska 29</Text>
          <Text style={styles.tighten}>20-704 Lublin</Text>
          <Text style={styles.tighten}>tel. 721 103 300</Text>
          <Text style={styles.tighten}>hotel@agit.com.pl</Text>
          <Text style={styles.tighten}>www.agit.com.pl</Text>
        </View>
      </View>
      <View
        style={[
          styles.absolute,
          {
            left: "0%",
            width: "100%",
            // flexDirection:"row",
            alignItems: "center",
            top: 4.6 + offset * index + "cm",
            fontSize: "14pt",

            letterSpacing: ".4pt",
          },
        ]}
      >
        <Text style={[styles.tighten, { fontSize: "16pt", color: "#e9521c" }]}>
          {i?.type === 2 ? (
            <>dla {i?.for_name}</>
          ) : (
            <>o wartości {i?.amount} PLN</>
          )}
        </Text>
        <Text style={[styles.tighten, { fontSize: "13pt" }]}>
          do wykorzystania na usługi {i?.type === 1 ? "SPA" : ""}
        </Text>

        {i?.type === 1 ? (
          <></>
        ) : (
          <Text style={[styles.tighten, { fontSize: "13pt" }]}>
            kosmetyczne | sportowe | menu restauracyjne
          </Text>
        )}
        <Text style={[{ color: "#e9521c" }]}>w AGIT Hotel Congress & SPA</Text>
      </View>
      <View
        style={[
          styles.absolute,
          {
            left: "0%",
            width: "100%",
            // flexDirection:"row",
            alignItems: "center",
            top: 7.8 + offset * index + "cm",
            fontSize: "8pt",

            letterSpacing: ".4pt",
          },
        ]}
      >
        <View style={{ flexDirection: "row", fontSize: "9pt" }}>
          <Text>
            Do wykorzystania w Hotel AGIT Congress & SPA
            {i?.valid_until ? " do" : ""}
          </Text>
          <Text style={{ marginLeft: "3px", fontWeight: 500 }}>
            {i?.valid_until ? (
              new Date(i?.valid_until).toLocaleDateString()
            ) : (
              <>bezterminowo</>
            )}
          </Text>
          <Text> r.</Text>
        </View>

        <View style={{ flexDirection: "row", fontSize: "9pt" }}>
          <Text>
            Prosimy o wcześniejszą rezerwację telefoniczną pod numerem
          </Text>
          <Text style={{ marginLeft: "3px", fontWeight: 500 }}>
            721 103 300
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.absolute,
          {
            left: 5 + 2.2 + "mm",
            width: "100%",
            flexDirection: "row",
            alignItems: "flex-end",
            // justifyContent:"flex-end",
            //QR
            
            // top: 7 + offset * index + "cm", 
            top: 8.2 + offset * index + "cm",
            fontSize: "8pt",
            fontWeight: 400,
            letterSpacing: ".4pt",
          },
        ]}
      >
        {/* <QR /> */}
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-end",
            marginLeft: "2mm",
            marginBottom: "3mm",
          }}
        >
          <Text>Numer vouchera: </Text>
          <Text style={{ fontSize: "9.5pt", fontWeight: 500 }}>
            {i?.paid === 1 ? "" : "G"}
            {i?.amount}-{i?.number}
          </Text>
        </View>
      </View>
      <View
        style={[
          styles.absolute,
          {
            right: 5 + 2.2 + "mm",
            width: "100%",
            // flexDirection:"row",
            alignItems: "flex-end",
            top: 8.2 + offset * index + "cm",
            fontSize: "8pt",
            fontWeight: 400,
            letterSpacing: ".4pt",
          },
        ]}
      >
        {i?.paid === 1 ? (
          <>
            <Text>................................</Text>
            <Text>Nr faktury/paragonu</Text>
          </>
        ) : (
          <View
            style={{
              fontWeight: 700,
              padding: "1px 3px",
              borderTop: "1px solid #905314",
              borderLeft: "1px solid #905314",
              borderRadius: "5px",
              justifyContent: "center",
              alignItems: "center",
              color: "#905314",
              backgroundColor: "rgba(255,255,255,.4)",
              fontSize: "10pt",
            }}
          >
            <Text>G</Text>
          </View>
        )}
      </View>
    </View>
  );
};

const Voucher = ({ i, index }: { i: any; index: number }) => {
  return (
    <>
      <Background i={i} index={index} />
      <Texts i={i} index={index} />
    </>
  );
};

function splitArrayIntoGroups(array: any[], groupSize: number) {
  const groups = [];
  for (let i = 0; i < array.length; i += groupSize) {
    groups.push(array.slice(i, i + groupSize));
  }
  return groups;
}

const MyDocument = ({ items }: { items: any }) => {
  const itemCount = items?.length;
  //   console.log(items);

  const itemGroups = splitArrayIntoGroups(items, 3);

  return (
    <Document>
      {itemGroups.map((group: any) => (
        <Page key={group?.id} size="A4" wrap={true} style={styles.page}>
          <View style={styles.pageBackground}>
            {group?.map((i: any, index: number) => (
              <Voucher i={i} index={index} />
            ))}
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default function VoucherPDF({ items }: { items: any }) {
  //   const doc = useCallback(
  //     () => MyDocument({ items: items }),
  //     [JSON.stringify(items)]
  //   );

  const pdf = (
    <PDFViewer width={"100%"} height="640px">
      {<MyDocument items={items} />}
    </PDFViewer>
  );

  return <>{pdf}</>;
}
