export function isToday(someDate: Date) {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
}

function timeDiff(milliseconds: number) {
  const hours = Math.floor(milliseconds / 1000 / 60 / 60);
  return {
    hours: hours,
    minutes: Math.floor((milliseconds / 1000 / 60 / 60 - hours) * 60),
  };
}

export function timeDiffString(milliseconds: number) {
  const { hours, minutes } = timeDiff(milliseconds);
  if ((!hours && !minutes) || hours < 0) return "przed chwilą";
  return (
    (hours ? hours + "h" : "") + "\u00A0" + minutes + "\u00A0" + "min temu"
  );
}

export function timeDiffJSX(milliseconds: number) {
  const { hours, minutes } = timeDiff(milliseconds);
  if ((!hours && !minutes) || hours < 0) return <>przed chwilą</>;
  return (
    <>
      {hours ? <>{hours}{"\u00A0"}h{"\u00A0"}</> : <></>}{minutes}{"\u00A0"}min{"\u00A0"}temu
    </>
  );
}
