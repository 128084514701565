import { URLProd } from "../../../paths";
import BooleanSymbol from "../../../tables/viewElements/BooleanSymbol";

export function row(row?: any): any[] {
  return [
    {
      source: "id",
      name: "Lp.",
      value: <>{row?.id}</>,
      style: { width: "40px" },
    },

    {
      source: "username",
      name: "Nazwa użytkownika",
      value: <>{row?.username}</>,
    },
    {
      source: "activated",
      style: { width: "20px", textAlign: "center" },
      name: "Aktywacja",
      value: <BooleanSymbol value={row?.activated} />,
    },
    {
      source: "first_name",
      name: "Imię",
      value: <>{row?.first_name}</>,
    },
    {
      source: "last_name",
      name: "Nazwisko",
      value: <>{row?.last_name}</>,
    },

    {
      source: "activation_code",
      name: "Link do aktywacji/resetu",
      value: <>{URLProd + "activate/" + row?.activation_code}</>,
    },
    {
      source: "create_date",
      name: "Data utworzenia",
      value: <>{new Date(row?.create_date).toLocaleDateString()}</>,
    },
    {
      source: "blocked",
      name: "Blokada",
      value: <>{row?.blocked ? <span style={{ color: "red" }}>✖</span> : ""}</>,
    },
    // {
    //   source: "modified_date",
    //   name: "Ostatnia zmiana",
    //   value: <>{row?.modified_date && new Date(row?.modified_date).toLocaleDateString()}</>,
    // },
  ];
}
