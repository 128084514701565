import BooleanSymbol from "../../../tables/viewElements/BooleanSymbol";
import DateDisplay from "../../../tables/viewElements/DateDisplay";
import statusIcon from "../../../../images/icons/status.svg";
import unresolvedIcon from "../../../../images/icons/bad.svg";
import resolvedIcon from "../../../../images/icons/good.svg";
import urgentIcon from "../../../../images/icons/urgent2.svg";

import messageIcon from "../../../../images/icons/message.svg";
import { editUrgency } from "./functions";

import AddCorrectDocument from "./AddCorrectDocument";
import insertGeneric from "../../../dataFetch/insertGeneric";
import SalesDocumentClickable from "./SalesDocumentClickable";
import CorrectDocumentOptions from "./CorrectDocumentOptions";

export function row(row?: any, f?: any): any[] {
  return [
    //   {
    //     name: "ID",
    //     source: "id",
    //     value: row?.id,
    //     className: "id"
    //   },
    {
      name: (
        <div style={{ lineHeight: "7px" }}>
          <img style={{ width: "14px" }} src={statusIcon} />
        </div>
      ),
      source: "resolved",
      rowClassName:
        (row?.resolved === 1 ? "resolved" : "unresolved") +
        (row?.resolved != 1 && row?.urgent ? " urgent" : ""),
      value: (
        <div
          className="error-status-icon"
          title={
            row?.urgent
              ? "PILNE\r\nOznacz jako zwykłe"
              : "ZWYKŁE\r\nOznacz jako pilne"
          }
          onClick={() => {
            if (row?.resolved || Number(f?.auth?.errors) < 9) return;
            editUrgency({ value: !row?.urgent, row: row, f: f });
            f?.setUpdates((prev: number) => prev + 1);
          }}
        >
          {row?.resolved === 1 ? (
            <img
              width={10}
              style={{ padding: "0 3px", filter: "invert(1) brightness(.5)" }}
              src={resolvedIcon}
            />
          ) : row?.urgent ? (
            <img
              width={16}
              style={{ filter: "hue-rotate(-40deg) " }}
              src={urgentIcon}
            />
          ) : (
            <img
              width={16}
              style={{
                filter:
                  "invert(1) brightness(.3) sepia(1) hue-rotate(-60deg) saturate(4)",
              }}
              src={unresolvedIcon}
            />
          )}
        </div>
      ),
      // value: <BooleanSymbol value={row?.resolved} />,
    },

    {
      name: "Lp.",
      // className: "id"?,
      style: { width: "33px" },
      source: "entry_number",
      value: (
        <>
          {row?.entry_number}/{row?.entry_month}/{row?.entry_year}
        </>
      ),
    },
    {
      name: <img width={10} src={messageIcon} />,
      // source: "entry_number",
      value: (
        <>
          {row?.message_count ? (
            <div>
              <span
                style={{
                  borderRadius: "5px",
                  background: "whitesmoke",
                  fontStyle: "monospace",
                  padding: "2px 4px",
                  fontSize: "13px",
                  width: "40px",
                }}
              >
                {row?.message_count}
              </span>
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      name: "Data utw.",
      source: "entry_datetime",
      style: { width: "65px" },
      innerStyle: {
        display: "block",
      },
      value: (
        <div
          title={new Date(row?.entry_datetime)
            ?.toLocaleTimeString()
            ?.slice(0, 5)}
        >
          <DateDisplay
            dateString={row?.entry_datetime}
            format="date"
            options={{ month: "2-digit" }}
          />
        </div>
      ),
    },
    {
      source: "problem_type",
      name: "Problem",
      style: { width: "90px" },
      value: (
        <>
          {row?.problem_type_string === "problem wewnętrzny"
            ? "wewnętrzny"
            : row?.problem_type_short}
        </>
      ),
    },
    {
      source: "is_original_receipt_present",
      name: "Dok./oryginał?",
      style: { width: "120px" },
      value: (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row?.sales_document_type_string === "faktura osoba prywatna"
            ? "FV pryw."
            : row?.sales_document_type_string
                ?.replace(/faktura/, "FV")
                ?.replace(/paragon/, "PAR")}{" "}
          {row?.sales_document_type_string?.match("paragon") ? (
            <BooleanSymbol value={row.is_original_receipt_present} />
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      source: "date_of_sale_from_sales_document",
      //   name: "Nr i data dok. sprzedaży LSI",
      name: "Dok. sprzedaży",
      style: { width: "100px" },
      value: <SalesDocumentClickable row={row} f={f} />,
    },

    {
      source: "purchaser_number_on_document",
      name: "Nabywca z dokumentu",
      style: { minWidth: "180px", width: "20%" },
      className: "client-cell",
      value: () => {
        const full = row?.purchaser_number_on_document;
        const short = full?.slice(0, 32);

        return (
          <div title={full?.length > short?.length ? full : ""}>
            {short}
            {full?.length > short?.length ? "..." : ""}
          </div>
        );
      },
    },

    {
      source: "guest",
      name: "Gość i nr rez.",
      style: { width: "140px" },
      value: (
        <>
          <span className="guest-in-row">{row?.guest}</span>
          <br />
          {row?.booking_number}
        </>
      ),
    },
    //   {
    //     // ref: "separator",
    //     name: "",
    //     style: separatorStyle,
    //     styleHeading: separatorHeadingStyle,
    //     jsx: <></>,
    //   },
    /* {
      source: "gross_amount_original_document",
      name: "Kw. brutto pierw.",
      style: { width: "60px", color: "brown" },
      value: <>{row?.gross_amount_original_document}</>,
    },
    {
      source: "previous_value",
      name: "Było",
      style: { width: "60px", color: "brown" },
      value: <>{row?.previous_value}</>,
    },
    {
      source: "what_is_corrected",
      name: "Co podlega korekcie?",
      style: { width: "85px" },
      value: <>{row?.what_is_corrected_string}</>,
    },
    {
      source: "should_be",
      name: "Powinno być",
      style: { width: "60px", color: "teal", fontWeight: "bold" },
      value: <>{row?.should_be}</>,
    },
    {
      source: "gross_value_as_corrected",
      name: "Kw. brutto po kor.",
      style: { width: "60px", color: "teal", fontWeight: "500" },
      value: <>{row?.gross_value_as_corrected}</>,
    },*/
    //   {
    //     ref: "separator",
    //     name: "",
    //     style: separatorStyle,
    //     styleHeading: separatorHeadingStyle,
    //     jsx: <></>,
    //   },

    {
      source: "date_of_discovery",
      name: <>Forma i data zgł.</>,
      style: { width: "110px" },
      value: (
        <>
          {row?.reporting_form_string === "problem wewnętrzny"
            ? "wewnętrzny"
            : row?.reporting_form_string}
          <br />
          <DateDisplay
            dateString={row?.date_of_discovery}
            format="date"
            options={{ month: "2-digit" }}
          />
        </>
      ),
    },

    {
      source: "employee_last_name",
      name: "Prac.",
      style: { width: "48px", paddingInline: "3px" },
      value: (
        <div title={row?.employee_first_name + " " + row?.employee_last_name}>
          {row?.employee_initials}
        </div>
      ),
    },
    {
      source: "correction_document_number",
      name: "Dok. korekty",
      style: { width: "90px" },
      value: (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 50px" }}>
          {row?.cor_doc_id || row?.cor_doc_issue_date ? (
            <div>
              {row?.cor_doc_number ? (
                <SalesDocumentClickable
                  row={{
                    ...row,
                    sales_document_code: row?.cor_doc_type,
                    lsi_document_number: row?.cor_doc_number,
                    lsi_document_id: row?.cor_doc_id,
                    date_of_sale_from_sales_document: row?.cor_doc_issue_date,
                  }}
                  f={f}
                />
              ) : (
                <>-</>
              )}
              {/* <DateDisplay dateString={row?.cor_doc_issue_date} format="date" /> */}
            </div>
          ) : (
            <></>
          )}
          <CorrectDocumentOptions row={row} f={f} />
        </div>
      ),
    },
  ];
}
