import { createContext } from "react";

export const CalendarContext = createContext({
    update: 0,
    setUpdate: () => {},
    setFormShow: () => {},
    formData: {},
    mode: "month",
    creatorHighlight: false,
    auth: {}
} as {
    update: number;
    setUpdate: any;
    setFormShow: any;
    formData: any;
    mode: any;
    creatorHighlight: boolean;
    auth: any;
});