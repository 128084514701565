import { useContext } from "react";
import useGeneric from "../../../dataFetch/useGeneric";
import { FormDataContext } from "../../Form";
import MultiAddSelectOption from "./MultiAddSelectOption";

export default function MultiAddSelect({
  fieldValue,
  fieldName,
  setFieldValue,
  sortRows,
  schema,
  usedUniqueOptions,
  blankName,
  readOnly,
}: {
  fieldValue: any;
  fieldName: any;
  setFieldValue: any;
  sortRows: any;
  schema: any;
  usedUniqueOptions: any;
  blankName: string;
  readOnly?: Boolean;
}) {
  const { options } = useContext<any>(FormDataContext);

  const fetchedOptions: any =
    !Array.isArray(schema?.optionsList) &&
    options[schema.optionsList] &&
    !options.loading
      ? options[schema.optionsList].rows
      : undefined;

  const optionsList = Array.isArray(schema?.optionsList)
    ? schema?.optionsList
    : fetchedOptions
    ? fetchedOptions
    : [];

  return (
    <select
      value={
        fieldValue
          ? fieldValue
          : schema?.defaultOption
          ? schema?.defaultOption
          : "0"
      }
      onChange={setFieldValue}
      onBlur={sortRows}
      style={schema?.inputStyle}
      disabled={readOnly ? true : false}
    >
      <>
        {optionsList.map((option: any) =>
          option.group ? (
            <optgroup label={option.group}>
              {option.options.map((opt: any) => (
                <MultiAddSelectOption
                  option={opt}
                  usedUniqueOptions={usedUniqueOptions}
                  fieldName={fieldName}
                />
              ))}
            </optgroup>
          ) : (
            <MultiAddSelectOption
              option={option}
              usedUniqueOptions={usedUniqueOptions}
              fieldName={fieldName}
            />
          )
        )}
        <option value={0} disabled={schema?.confinedList ? true : false}>
          {blankName ? blankName : <>Inne</>}
        </option>
      </>
    </select>
  );
}
