import { useContext, useState } from "react";
import prettyNumber from "../functions/prettyNumber";
import { sumCellValues } from "./definitions/calculables";
import insertGeneric from "../dataFetch/insertGeneric";
import { FormDataContext } from "../forms_new/Form";
import { CalendarContext } from "../contexts/CalendarContext";
import { ModalContext } from "../Modal";

export function calculateSettlementSums(
  data: { rooms: any[]; items: any[]; payments: any[] },
  net?: Boolean
) {
  const { rooms, items, payments } = data;
  const totalCost =
    Math.round(
      (sumCellValues(
        rooms,
        net ? (e: any) => e["netUnit"] * e["amount"] : "grossSum"
      ) +
        sumCellValues(
          items,
          net ? (e: any) => e["unitPrice"] * e["amount"] : "totalGross"
        )) *
        100
    ) / 100 || 0;
  const paymentsSum =
    Math.round(
      sumCellValues(payments, net ? (e: any) => e["net"] : "gross") * 100
    ) / 100 || 0;
  const balance = paymentsSum - totalCost;
  return {
    raw: {
      totalCost: totalCost,
      paymentsSum: paymentsSum,
      balance: balance,
    },
    text: {
      totalCost: prettyNumber(totalCost, { text: true }) + " zł",
      paymentsSum: prettyNumber(paymentsSum, { text: true }) + " zł",
      balance:
        prettyNumber(Math.round(balance * 100) / 100, { text: true }) + " zł",
    },
    jsx: {
      totalCost: <>{prettyNumber(totalCost)} zł</>,
      paymentsSum: <>{prettyNumber(paymentsSum)} zł</>,
      balance: <>{prettyNumber(Math.round(balance * 100) / 100)} zł</>,
    },
  };
}

export default function SumSettlement({
  rooms,
  items,
  payments,
  data,
  initialData,
}: {
  rooms: any[];
  items: any[];
  payments: any[];
  data: any;
  initialData?: any;
}) {
  const [net, setNet] = useState(false);
  const [settled, setSettled] = useState(initialData?.settled);

  const { updater: setUpdate } = useContext(ModalContext);

  const {
    jsx: { totalCost, paymentsSum, balance },
    raw: {
      totalCost: totalCostNumber,
      paymentsSum: paymentsSumNumber,
      balance: balanceNumber,
    },
  } = calculateSettlementSums(
    {
      rooms: rooms,
      items: items,
      payments: payments,
    },
    net
  );

  return (
    <div
      style={{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "max-content max-content 1fr 210px",
        gridGap: "0 32px",
        fontSize: "14px",
      }}
    >
      <div>
        <>
          <span className="sum-label" style={{ color: "brown" }}>
            Cena łącznie
          </span>
          {totalCost}{" "}
        </>
      </div>
      <div>
        <span className="sum-label" style={{ color: "darkgreen" }}>
          Zapłacono
        </span>{" "}
        {paymentsSum}{" "}
      </div>
      <div style={{ textAlign: "right" }}>
        {paymentsSum || totalCost ? (
          <>
            <span className="sum-label">Saldo</span> {balance}{" "}
            <span
              style={{
                fontSize: "12px",
                display: "inline-block",
                padding: "2px 8px",
                color: "grey",
              }}
              onClick={() => setNet((prev: Boolean) => !prev)}
            >
              {net ? <>NETTO</> : <>BRUTTO</>} ◂▸
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="settle-field">
        {settled ? (
          <>
            <span style={{fontWeight:"bold", display:"inline-block", marginRight:"10px"}}>ROZLICZONE</span>
            <button
              className="settle-button"
              style={{fontSize:"9px"}}
              onClick={async (e: any) => {
                e.preventDefault();
                const really = window.confirm("Czy na pewno chcesz cofnąć rozliczenie?");
                if (!really) return;
                const response = await insertGeneric("settle-calendar-event", {
                  id: data?.id,
                  settled: 0,
                });
                if (response) {
                  setSettled(false);
                  setUpdate((prev: number) => prev + 1);
                }
                // console.log(await response);
                // alert("Funkcja w trakcie przygotowania. Spróbuj później.");
              }}
            >
              COFNIJ
            </button>
          </>
        ) : (paymentsSumNumber || totalCostNumber) &&
          Math.abs(balanceNumber) < 1 ? (
          <button
            className="settle-button"
            onClick={async (e: any) => {
              e.preventDefault();
              const response = await insertGeneric("settle-calendar-event", {
                id: data?.id,
                settled: 1,
              });
              if (response) {
                setSettled(true);
                setUpdate((prev: number) => prev + 1);
              }
              // console.log(await response);
              // alert("Funkcja w trakcie przygotowania. Spróbuj później.");
            }}
          >
            OZNACZ JAKO ROZLICZONE
          </button>
        ) : balanceNumber < 0 ? (
          <>saldo ujemne</>
        ) : balanceNumber > 0 ? (
          <>saldo dodatnie</>
        ) : (
          <>brak danych do rozliczenia</>
        )}
      </div>
    </div>
  );
}
