import compareStrings from "../functions/compareStrings";
import {
  deepCompare,
  flatOptions,
  isJSONParsable,
  isObject,
  looseCompare,
} from "../functions/tools";
import ChangeMultiCell from "./ChangeMultiCell";
import MultiShow from "./MultiShow";
import useRowCompare from "./useRowCompare";

export default function ChangeRow({
  prop,
  flatSchema,
  original,
  proposed,
  options,
}: {
  prop: string;
  flatSchema: any;
  original: any;
  proposed: any;
  options?: any;
}) {
  const {
    show,
    propSchema,
    multi,
    optionsList,
    deepAnalysis,
    isJSON,
    columns,
  } = useRowCompare({
    prop: prop,
    before: original,
    after: proposed,
    schema: flatSchema,
    options: options,
  });
  // console.log(propSchema, optionsList);

  // if (deepAnalysis) {
  //   console.log(prop, deepAnalysis);
  // }

  const propParent = prop && prop?.match(/.*[%](.*)[%]/)?.[1];
  // console.log(prop, propParent);

  // if (propSchema?.element === "textarea") {
  //   const z = compareStrings(original[prop],proposed[prop]);
  //   console.log(z)
  // }

  return show ? (
    <>
      {/* <tr>
        <td colSpan={3}>
          {JSON.stringify(deepAnalysis)}
          <div>{deepAnalysis?.same ? "Taki sam" : "Inny"}</div>
        </td>
      </tr> */}
      {deepAnalysis ? (
        !deepAnalysis?.same ? (
          <tr className="comparison-row">
            <th className="comparison-row-head">
              {propSchema?.label}{" "}
              <span className="prop-parent">{propParent}</span>
            </th>
            <td colSpan={2}>
              <table className="data-table form-changes">
                <thead>
                  <tr>
                    {Object.keys(columns)?.map((name: any) => (
                      <th>{columns[name].name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {deepAnalysis?.analysis?.map((row: any) => (
                    <tr>
                      {row?.compare?.map((cell: any) => (
                        <td>
                          <ChangeMultiCell
                            cell={cell}
                            columns={columns}
                            options={options}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        ) : (
          <></>
        )
      ) : original[prop] !== proposed[prop] ? (
        <tr className="comparison-row">
          <td className="comparison-row-head">
            {/*multi*/}
            {propSchema?.label}{" "}
            <span className="prop-parent">{propParent}</span>
          </td>{" "}
          <td>
            <div className="comparison-simple">
              <div>
                {multi && isJSON ? (
                  <MultiShow schema={propSchema} data={original[prop]} />
                ) : (
                  <>
                    {optionsList && Array.isArray(optionsList)
                      ? flatOptions(optionsList)?.find(
                          (option: any) => option?.id == original[prop]
                        )?.name
                      : JSON.stringify(original[prop])
                      ? JSON.stringify(original[prop])
                      : "brak"}
                  </>
                )}
              </div>

              <div>
                {multi && isJSON ? (
                  <MultiShow schema={propSchema} data={proposed[prop]} />
                ) : (
                  <>
                    {optionsList && Array.isArray(optionsList)
                      ? flatOptions(optionsList)?.find(
                          (option: any) => option?.id == proposed[prop]
                        )?.name
                      : JSON.stringify(proposed[prop])}
                  </>
                )}
              </div>
            </div>
          </td>
        </tr>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
}
