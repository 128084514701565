import { optionSingle } from "../../formContentType";

export default function MultiAddSelectOption({
  option,
  usedUniqueOptions,
  fieldName,
}: {
  option: optionSingle;
  usedUniqueOptions: any;
  fieldName: string;
}) {
  return (
    <option
      key={option.id}
      value={option.id}
      disabled={
        option?.active === false || (usedUniqueOptions && usedUniqueOptions[fieldName]?.includes(option.id)
          ? true
          : false)
      }
    >
      {option.name}
    </option>
  );
}
