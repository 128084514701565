import { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormDataContext } from "../../Form";

export default function MultiAddInfoCell({
  render,
  rowValues,
  schema,
  topSchema
}: {
  render: Function;
  rowValues: any;
  schema?:any;
  topSchema?:any;
}) {
  const { getValues } = useFormContext();
  const { subTab, setSubTab } = useContext(FormDataContext);


  return <td onDoubleClick={() => {
    // console.log(topSchema, schema)
    if (topSchema && schema.linkTab) {
      topSchema.setTab(schema.linkTab);
    }
    if (topSchema && schema.linkThisDate && setSubTab) {
      setSubTab(rowValues.date);
    }
    
  }}>{render(getValues(), rowValues)}</td>;
}
