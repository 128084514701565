import { useContext, useState } from "react";
import useGeneric from "./dataFetch/useGeneric";
import Chat from "./tables/Chat";
import { ViewContext } from "./contexts/ViewContext";

export default function ChatApp() {
  const { setCurrentPage } = useContext(ViewContext);
  setCurrentPage("chat");
  const [selected, setSelected] = useState({table:"", table_id: 0}) 

  const tableNames: any = {
    "error_register": "Rejestr błędów"
  };

  const allDiscussions = useGeneric({
    name: "discussionCategories",
    limit: 25,
    update: 0,
  });

  return (
    <div className="chatApp">
      <div className="chatOptions">
        <h1>Rozpoczęte czaty</h1>
        <hr/>
        <div className="chatsRow">
         <div>Ogólny</div><div></div>
        </div>
        
        {allDiscussions.rows.map((item: {table: string, table_id: number}) => (
          <div key={item.table+item.table_id} className="chatsRow" onClick={() => setSelected({table:item.table, table_id:item.table_id})}>
            <div>{tableNames[item.table]}</div><div>{item.table_id}</div>
          </div>
        ))}
      </div>
      <Chat query={{ table: selected.table, table_id: selected.table_id }} />
    </div>
  );
}
