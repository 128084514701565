import {
  minutesToShortJSX,
  minutesToString,
} from "../../functions/minutesToJSX";

export default function AttendanceTableCell({
  attendance,
  holiday,
  day,
  n,
  location,
}: {
  attendance: any;
  holiday: any;
  day: any;
  n: number;
  location: string;
}) {
  const hJSX =
    attendance && attendance.length ? (
      attendance.map((hi: any) => (
        <div
          className={
            "singleWorkTime tooltip" +
            (hi.tp1 === 2 ? " remote" : "") +
            (location === "biuro" && hi.db === "a" ? " hotel" : "")
          }
        >
          {minutesToShortJSX(parseInt(hi.minutes), false)}
          <div
            className="top"
            style={{
              padding: "2px",
              left: "-70%",
              borderRadius: "5px 5px 0 5px",
              minWidth: "80px",
            }}
          >
            <div>
              <div style={{ fontWeight: "bold" }}>
                {new Date(hi.t1).toLocaleTimeString("pl-PL", {
                  hour: "numeric",
                  minute: "numeric",
                }) +
                  "-" +
                  new Date(hi.t2).toLocaleTimeString("pl-PL", {
                    hour: "numeric",
                    minute: "numeric",
                  })}
              </div>
              {location === "biuro" && hi.db === "a" ? <div>HOTEL</div> : <></>}
              {hi.tp1 === 2 ? <div>ZDALNIE</div> : <></>}
              {/*<div>{minutesToString(parseInt(hi.minutes), false)}</div>*/}
            </div>
          </div>
        </div>
      ))
    ) : (
      <></>
    );
  const hol =
    holiday && holiday.date && holiday.type
      ? " dayOff" + " u" + holiday.type
      : "";

  return (
    <td
      className={
        "tableDay" +
        (day.holiday ? " holiday" : "") +
        (day.obj.getDay() === 6 ? " saturday" : "") +
        (day.obj.getDay() === 0 ? " sunday" : "") +
        hol
      }
    >
      {((attendance && attendance.length) || holiday) && !day.atWork ? (
        <div>
          {hJSX}

          {holiday && holiday.abbr ? (
            <div className="holidayText tooltip">
              <div className="holidayName">{holiday.abbr}</div>
              <div
                className="top"
                style={{
                  padding: "3px",
                  left: "-170%",
                  borderRadius: "5px 5px 0 5px",
                  minWidth: "80px",
                  fontSize: "9px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {holiday && holiday.typestring ? holiday.typestring : ""}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : day.atWork && day.atWork.max ? (
        <div className={"atWork" + (day.atWork.typeshorts.split(",").at(-1) === "2" ? " remote" : "")}>
          {new Date(day.atWork.max).toLocaleTimeString("pl-PL", {
            minute: "numeric",
            hour: "numeric"
          })}
        </div>
      ) : (
        <></>
      )}
    </td>
  );
}
