import diff_match_patch from "diff-match-patch";

export default function compareStrings(s1: string, s2: string) {
  if (s1 !== undefined && s2 !== undefined) {
    const diff = new diff_match_patch();
    const diffCalc = diff.diff_main(s1, s2);
    const diffClean = diff.diff_cleanupSemantic(diffCalc);
    return diff.diff_prettyHtml(diffCalc);
  } else return "<div></div>";
  //   const breakpoint = /(\b[^\s]+\b)/;
  //   console.log(s1, s2);
  //   const words1 = s1?.split(breakpoint);
  //   const words2 = s2?.split(breakpoint);

  //   if (Array.isArray(words1) && Array.isArray(words2)) {
  //     return words2.map((w: string, index: number) =>
  //       w === words1?.[index] ? w : <span style={{ color: "green" }}>{w}</span>
  //     );
  //   } else {
  //     return <></>;
  //   }
}
