import waitersIcon from "../../images/icons/new/waiter.svg";
import chefsIcon from "../../images/icons/new/chef-cap2.svg";
import dishWashIcon from "../../images/icons/new/dishwash2.svg";
import peopleIcon from "../../images/icons/people.svg";
import backHighlight from "../../images/back-highlight4.png";

export default function EventService({
  view,
  abbr,
  auth
}: {
  view: any;
  abbr?: Boolean;
  auth?: any;
}) {
  return view ? (
    <div className="event-numbers">
     {Number(auth?.settlements) > 0 ? <><div className="framed-icon-group" title="Kelnerzy">
        <img className="framed-icon" src={waitersIcon} width={15} />{" "}
        <div>{view?.values?.waiters}</div>
        {abbr ? <></> : <span>Kelnerzy</span>}
      </div>

      <div className="framed-icon-group" title="Kucharze">
        <img className="framed-icon" src={chefsIcon} width={18} />
        <div>{view?.values?.chefs}</div>
        {abbr ? <></> : <span>Kucharze</span>}
      </div>
      <div className="framed-icon-group" title="Na zmywaku">
        <img className="framed-icon" src={dishWashIcon} width={18} />{" "}
        <div>{view?.values?.dishwashers}</div>
        {abbr ? <></> : <span>Na zmywaku</span>}
      </div>
      </> : <></>}
      <div
        className="framed-icon-group guest-number-all context-window-wrap"
        style={{
          background: abbr ? "transparent" : "white",
          padding: "2px 8px",
          marginLeft: "8px",
          borderRadius: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // borderRadius:"50%"
        }}
        // title="Liczba osób w tym dniu łącznie"
      >
        <img width={25} src={peopleIcon} />
        <div
          style={
            abbr
              ? { fontSize: "11px", marginLeft:"8px" }
              : {
                  fontSize: "17px",
                  textShadow: "0px 0px 1px black",
                }
          }
        >
          {view.jsx.guest_number}
        </div>
        <div className="context-window left guest-number-details">
          <div>Dorośli: {view.values.guest_number_adult}</div>
          {view.values?.guest_number_child_0_3 ? (
            <div>Dzieci 0-3: {view.values?.guest_number_child_0_3}</div>
          ) : (
            <></>
          )}
          {view.values?.guest_number_child_4_12 ? (
            <div>Dzieci 4-12: {view.values?.guest_number_child_4_12}</div>
          ) : (
            <></>
          )}
          {view.values?.guest_number_diets ? (
            <div>w tym diety: {view.values?.guest_number_diets}</div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  ) : <></>;
}
