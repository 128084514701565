import { useState } from "react";
import { createContext } from "react";

export const EditableContext = createContext({
  currentEditID: "" as any,
  setCurrentEditID: false as any,
  lastSavedID: "" as any,
  setLastSavedID: false as any,
});

export default function Editables({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const [currentEditID, setCurrentEditID] = useState<any>("");
  const [lastSavedID, setLastSavedID] = useState<any>("");

  return (
    <EditableContext.Provider
      value={{
        currentEditID: currentEditID,
        setCurrentEditID: setCurrentEditID,
        lastSavedID: lastSavedID,
        setLastSavedID: setLastSavedID,
      }}
    >
      {children}
    </EditableContext.Provider>
  );
}
