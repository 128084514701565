import { Form, useForm } from "react-hook-form";
import { URL } from "../paths";
import { useContext, useEffect } from "react";
import { ViewContext } from "../contexts/ViewContext";

export default function AddEntrySanitary({
  employee,
  setUpdates,
}: {
  employee: any;
  setUpdates: Function;
}) {
  const { register, control, setValue, getValues } = useForm();
  const { setModal } = useContext(ViewContext);

  useEffect(() => {
    const currentDate = new Date();
    const nextDate = new Date();
    nextDate.setFullYear(nextDate.getFullYear() + 5);
    nextDate.setDate(nextDate.getDate() - 1);
    setValue("employee_id", employee.id);
    setValue("date", currentDate.toISOString().slice(0, 10));
    setValue("date_next", nextDate.toISOString().slice(0, 10));
  }, []);

  return (
    <div className="add-employee-entry">
      <Form
        action={`${URL}backend/inserts/set-sanitary.php`}
        headers={{ "Content-Type": "application/json" }}
        onSuccess={async ({ response }: { response: any }) => {
          //   const result = await response;
          console.log(response.json());
          setUpdates((prev: number) => prev + 1);
          setModal((prev: any) => ({ ...prev, show: false }));
        }}
        onError={() => {
          alert("Wystąpił problem.");
        }}
        control={control}
      >
        <div className="field">
          <label htmlFor="date">Data badania</label>
          <input id="date" type="date" {...register("date")} />
        </div>
        <div className="field">
          <label className="modifiers" htmlFor="date_next">
            <div>Data kolejnego badania</div>
            <div className="mod">
              <div
                onClick={() => {
                  const d = new Date(getValues("date"));
                  d.setFullYear(d.getFullYear() + 2);
                  d.setDate(d.getDate() - 1);
                  const adj = d.toISOString().slice(0, 10);
                  setValue("date_next", adj);
                }}
              >
                +2
              </div>
              <div
                onClick={() => {
                  const d = new Date(getValues("date"));
                  d.setFullYear(d.getFullYear() + 5);
                  d.setDate(d.getDate() - 1);
                  const adj = d.toISOString().slice(0, 10);
                  setValue("date_next", adj);
                }}
              >
                +5
              </div>
            </div>
          </label>
          <input id="date_next" type="date" {...register("date_next")} />
        </div>

        <div className="field">
          <label htmlFor="comment">Uwagi</label>
          <textarea id="comment" {...register("comment")} />
        </div>
        <div>
          <button type="submit">Dodaj</button>
        </div>
      </Form>
    </div>
  );
}
