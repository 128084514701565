export function row(row?: any): any[] {
  return [
    {
      name: "ID",
      value: row?.id,
      className: "id",
    },

    {
      name: "Dokument sprzedaży",
      value: row?.sales_document_type_string,
    },
    {
      name: "Nr faktury/paragonu",
      value: row?.document_number,
    },
    {
      name: "Kwota brutto na FV/Par",
      value: row?.gross_amount,
    },
    {
      name: "Forma błędna",
      value: row?.payment_form_string_incorrect,
    },
    {
      name: "Forma właściwa",
      value: row?.payment_form_string_correct,
    },
    { 
      name: "Zgłaszający",
      value: <>{row?.employee_first_name} {row?.employee_last_name}</>
    },
    { 
      name: "Poprawiono?",
      value: <>{row?.resolved}</>
    }
  ];
}
