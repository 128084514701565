import minutesToJSX, {
  minutesToShortJSX,
  minutesToString,
} from "../../functions/minutesToJSX";
import { isSameDay } from "../../schedules/ScheduleTable";
import AttendanceTableCell from "./AttendanceTableCell";

export default function AttendanceTableRow({
  item,
  calendar,
  location,
  checkIn,
}: {
  item: any;
  calendar: any;
  location: string;
  checkIn: any;
}) {
  const details =
    item.details && JSON.parse("[" + item.details.replace("\\", "") + "]");
  const holidayDates = item.holidates
    ? JSON.parse("[" + item.holidates.replace("\\", "") + "]")
    : undefined;
  console.log(item?.cardperson, details);

  const workAndHoliday = holidayDates?.filter((item: any) =>
    [1, 2, 5, 9]?.includes(item.type) && details?.find((d:any) => isSameDay(d.t1, item.date))
  );
  const holidayLength = workAndHoliday?.length ? workAndHoliday?.length : 0;
  const overTime =
    item?.minutes_nominal -
    item?.minutes -
    holidayLength *
      (item?.base_hours_per_day ? item?.base_hours_per_day : 8) *
      60;
  return (
    <tr>
      <>
        <td style={{ textAlign: "left" }}>{item.cardperson}</td>
        <td
          style={{ textAlign: "left" }}
          title={
            (overTime > 0 ? "-" : "+") +
            minutesToString(Math.abs(overTime), false)
          }
        >
          {minutesToJSX(item.totalSeconds / 60, false)}
        </td>
        {calendar.dates.map((c: any, n: number) => {
          const attendance =
            details &&
            details.filter((i: any) => new Date(i.t1).getDate() - 1 === n);
          const holiday =
            holidayDates &&
            holidayDates.find(
              (hd: any) => new Date(hd.date).getDate() - 1 === n
            );
          c.atWork =
            checkIn &&
            checkIn.max &&
            checkIn.typeshorts &&
            [0, 2, 4].includes(
              parseInt(checkIn.typeshorts.split(",").at(-1))
            ) &&
            new Date(checkIn.max).getDate() === c.obj.getDate() &&
            new Date(checkIn.max).getMonth() === c.obj.getMonth() &&
            new Date(checkIn.max).getFullYear() === c.obj.getFullYear()
              ? checkIn
              : undefined;
          /* (checkIn &&
            checkIn.timestring &&
            new Date(checkIn.timestring).getDate() === c.obj.getDate())
              ? new Date(checkIn.timestring).toLocaleTimeString()
              : false;*/

          return (
            <AttendanceTableCell
              key={c.string}
              attendance={attendance}
              holiday={holiday}
              day={c}
              n={n}
              location={location}
            />
          );
        })}
      </>
    </tr>
  );
}
