import { useContext } from "react";
import Loading from "../common/Loading";
import insertGeneric from "../dataFetch/insertGeneric";
import useGeneric from "../dataFetch/useGeneric";
import Form from "../forms_new/Form";
import EventHistoryNew from "./EventHistoryNew";
import { eventForm } from "./addEventFormUpdated";
import { calendarEntry } from "./calendarEntry";
import { UserContext } from "../contexts/UserContext";

export default function EventDataProvider({
  data,
  process,
  auth,
  date,
  modal,
  calendar,
  setUpdateCustom,
}: {
  data: any;
  process: Function;
  auth: any;
  date: any;
  modal: any;
  calendar: any;
  setUpdateCustom?: Function;
}) {
  const getFullData = useGeneric({
    name: data && data?.id && "event-calendar",
    limit: 1,
    query: {
      id: data?.id,
    },
  });

  const fullData = data && !getFullData?.loading && getFullData?.rows?.[0];
 
  const {userID} = useContext(UserContext);
  
  return !data?.potential && (data && getFullData?.loading) ? (
    <Loading />
  ) : (
    (data?.potential || !data || fullData) && (
      <Form
        name="calendar_form"
        confirm={true}
        schema={eventForm(fullData, date.obj, auth, userID)}
        auth={auth}
        data={data?.potential ? data : fullData}
        defaultSubTab={date?.obj?.toISOString()?.slice(0, 10)}
        submit={async (fullData: calendarEntry) => {
          const dataFinal = process(fullData);
          // console.log(fullData);
          return await insertGeneric("calendar-event", dataFinal);
        }}
        sideEffects={() => {
          calendar.setUpdate((prev: number) => prev + 1);
          modal.setModal((prev: any) => ({ ...prev, show: false }));
          if (setUpdateCustom) {
            setUpdateCustom((prev: number) => prev + 1);
          }
        }}
        settings="local-history"
        history={(options: any) =>
          fullData?.id ? (
            <EventHistoryNew
              id={data?.id}
              data={fullData}
              options={options}
              schema={Object.keys(eventForm(fullData, date?.obj))
                .map(
                  (key: string) => eventForm(fullData, date?.obj)[key].content
                )
                .flat()
                .flat()}
            />
          ) : (
            <></>
          )
        }
      />
    )
  );
}
