import { useContext } from "react";
import prettyDate from "../functions/prettyDate";
import RoomDetails from "./RoomDetails";
import { ViewContext } from "../contexts/ViewContext";
import loadingSpinner from "../../images/loading.svg";
import { roomBookings as roomBookingsFunction } from "./RoomsView";
import Person from "../common/Person";

export default function RoomsTable({
  data,
  bookings,
  bookingsLoading,
  heading,
}: {
  data: any;
  bookings: any;
  bookingsLoading: Boolean;
  heading: string;
}) {
  const { modal, setModal } = useContext(ViewContext);

  const tableData = (d: any) => {
    const roomBookings = roomBookingsFunction(bookings, d);
    // console.log(d?.numer, roomBookings);
    const avail = !d?.status?.match(/zajety/);
    return [
      {
        name: "Nr",
        content:
          avail && !roomBookings?.length ? (
            <span
              style={{ textShadow: "1px 1px 1px yellowgreen", color: "green" }}
            >
              {d.numer}
            </span>
          ) : (
            d.numer
          ),
        title: d.rodzaj,
      },
      {
        name: "Status",
        content: d.status?.replace(/zajety/, "zajęty")?.toUpperCase(),
      },
      // { name: "Rodzaj", content: d.rodzaj },
      { name: "M-ca", content: d.ilosc_miejsc },
      { name: "Dorośli", content: d.ilosc_osob },
      { name: "Dzieci", content: d.ilosc_dzieci },
      {
        name: "Check-in",
        style: { fontSize: "90%" },
        content: (
          <>
            {d.data_od &&
              prettyDate(d.data_od, "", false, { weekday: "short" })}
            {avail && roomBookings?.length ? (
              <>
                {roomBookings?.map(
                  (rb: any) => "> " + rb?.godzina_od?.slice(0, 5)
                )}
              </>
            ) : (
              <>
                {avail && !bookingsLoading ? (
                  <>DZIŚ WOLNY</>
                ) : bookingsLoading ? (
                  <img
                    src={loadingSpinner}
                    style={{ width: "12px", scale: 2, filter: "saturate(0)" }}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        ),
      },
      {
        name: "Check-out",
        style: { fontSize: "90%" },
        content:
          avail && roomBookings?.length ? (
            <>
              {roomBookings?.map(
                (rb: any) =>
                  rb.data_do &&
                  prettyDate(rb.data_do, "", true, { weekday: "short" })
              )}
            </>
          ) : (
            d.data_do &&
            prettyDate(d.data_do, "dziś, ", false, { weekday: "short" })
          ),
      },
      {
        name: "Rezerwacja na nazwisko",
        className: "guest-full-name",
        style: { fontStyle: "italic" },
        content:
          d?.first_name || d?.last_name ? (
            // <>
            //   {d?.first_name?.toLowerCase()}
            //   &nbsp;
            //   {d?.last_name?.toLowerCase()}
            // </>
            <Person name={d?.last_name + " " + d?.first_name} />
          ) : roomBookings?.length ? (
            roomBookings?.map((rb: any) => <Person name={rb?.gosc} />)
          ) : (
            false
          ),
      },
    ];
  };

  return (
    <div className="grid-table rooms">
      <h2 className="grid-heading">{heading}</h2>
      <div className="grid-table-head">
        <div className="grid-table-row">
          {tableData(data)?.map((d: any) => (
            <div>{d.name}</div>
          ))}
        </div>
      </div>
      {data.map((room: any) => {
        return (
          <div
            className={
              "grid-table-row room-status" +
              (room.status.match(/zajety/)
                ? " occupied"
                : room.status.match(/czysty/)
                ? " avail-clean"
                : " avail-dirty")
            }
            title={
              roomBookingsFunction(bookings, room)?.length
                ? roomBookingsFunction(bookings, room)?.map(
                    (rb: any) => rb?.numer_rezerwacji + "\r\n" + rb?.uwaga
                  )
                : (room?.nr_rezerwacji ? room?.nr_rezerwacji + "\r\n" : "") +
                  (room?.cena
                    ? Math.round(room?.cena * 100) / 100 + " zł\r\n"
                    : "") +
                  (room?.uwaga ? room?.uwaga : "")
            }
            onClick={() =>
              setModal({
                show: true,
                heading: "Podgląd pokoju",
                content: (
                  <RoomDetails d={room} b={bookings} bl={bookingsLoading} />
                ),
              })
            }
          >
            {tableData(room)?.map((d: any) => (
              <div
                style={d?.style}
                className={d?.className + (!d?.content ? " empty" : "")}
                title={d?.title}
              >
                <div className="inline-heading">{d?.name}</div>
                <div>{d?.content}</div>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
}
