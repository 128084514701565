import { useContext, useState } from "react";
import FieldSet from "./Elements/FieldSet";
import { formContent, formFull, forms } from "./formContentType";
import { useFormContext } from "react-hook-form";
import gearIcon from "../../images/icons/gear.svg";
import LocalHistory from "./Elements/LocalHistory";
import { FormDataContext } from "./Form";
import { ViewContext } from "../contexts/ViewContext";
import { CalendarContext } from "../contexts/CalendarContext";
import { FormContext } from "../tables/EditErrorEntry";

export default function Tabs({
  schema,
  displayedTab,
  setDisplayedTab,
  settings,
  history,
}: {
  schema: forms;
  displayedTab: number;
  setDisplayedTab: Function;
  settings?: string;
  history?: JSX.Element;
}) {
  const { auth } = useContext(FormDataContext);
  const {
    formState: { errors },
    setValue,
    getValues,
  } = useFormContext();

  const { data, setCustomFieldData, options } =
    useContext<any>(FormDataContext);

  const { modal2, setModal2 } = useContext(ViewContext);

  const schemaKeys = Object.keys(schema)
    .filter((k: string) => k !== "super")
    .filter((k: string) => {
      if (schema[k].visibleIf) {
        return schema[k]?.visibleIf?.(getValues());
      } else {
        return true;
      }
    });

  function setValues(values: Object) {
    Object.keys(values).forEach((key: string) => {
      setValue(key, values[key as keyof typeof values]);
      /*
      console.log(values[key as keyof typeof values], getValues(key));
      if (values[key as keyof typeof values] !== getValues(key)) {
        setCustomFieldData((prev: any) => ({
          ...prev,
          [key]: { ...prev[key], tempclass: "changed" },
        }));
      }*/
    });
  }

  return (
    <div>
      {schema && schema.super ? (
        <div className="super-field">
          <FieldSet key="super" schema={schema.super.content} display={true} />
        </div>
      ) : (
        <></>
      )}
      <div className="form-tab-wrap">
        <div className="form-tabs-all">
          <div className="form-tabs">
            {schemaKeys.map((key: string, index: number) => (
              <div
                key={key}
                onClick={() => setDisplayedTab(index)}
                style={{
                  ...(schema[key]?.tabStyle ? schema[key]?.tabStyle : {}),
                  display: schema[key]?.hide ? "none" : undefined,
                }}
                className={
                  (displayedTab === index ? "active" : "") +
                  (Object.keys(errors).find((errorField: string) =>
                    schema[key].content
                      .flat()
                      .map((i: any) => i.name)
                      .includes(errorField)
                  )
                    ? " error-tab"
                    : "")
                }
              >
                {schema[key].name}
              </div>
            ))}
          </div>
          {settings && settings === "local-history" && auth?.history ? (
            <div className="form-options-icon">
              <img width={15} src={gearIcon} />
              <div className="form-options">
                {data !== undefined && history ? (
                  <div
                    style={{
                      padding: "5px 27px",
                      color: "black",
                      fontSize: "16px",
                    }}
                    onClick={() =>
                      setModal2({
                        show: true,
                        content: history,
                        heading: "Historia zmian",
                        style: {
                          width: "900px",
                          maxHeight: "500px",
                        },
                      })
                    }
                  >
                    Historia
                  </div>
                ) : (
                  <></>
                )}
                {/*<LocalHistory
                identifier="calendar"
                id={data?.id}
                setter={(item: any) => setValues(JSON.parse(item))}
        />*/}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={"form-content"}>
          {schemaKeys.map((key: string, index: number) => (
            <FieldSet
              key={key}
              setTab={setDisplayedTab}
              schema={schema[key].content}
              schemaOptions={schema[key]}
              readOnly={schema[key].readOnly}
              //display={true}
              display={displayedTab === index}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
