import { useEffect, useRef, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import invoiceTypes from "./Print/invoiceTypes";
import axios from "axios";
import { URL, URLProd } from "../paths";
import Loading from "../common/Loading";
import prettyDate from "../functions/prettyDate";
import prettyMoney from "../functions/prettyMoney";
import fillLog from "../dataFetch/fillLog";

export default function InvoiceSend({
  invoice,
  setMode,
  pdf,
  paymentLink,
  style,
  userID,
}: {
  invoice: invoiceTypes;
  setMode: Function;
  pdf: Blob;
  paymentLink: string;
  style: any;
  userID?: number;
}) {
  const previewHTML: any = useRef(0);
  const form: any = useRef();
  const [formUpdate, setFormUpdate] = useState<number>(0);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState(
    "Faktura nr " + invoice?.document_name_full
  );

  const [sent, setSent] = useState(false);
  const [sending, setSending] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchClient = useGeneric({
    name: "lsi_clients",
    query: {
      id: invoice?.usID,
    },
    reload: false,
  });
  const client = !fetchClient?.loading && fetchClient?.rows;
  //   console.log(client);

  useEffect(() => {
    setEmail(client?.[0]?.Email);
  }, [fetchClient?.loading]);

  function getPreview(data: any) {
    const preview = axios
      .postForm(URL + "backend/mail/invoice-mail-preview.php", data)
      .then((result: any) => {
        console.log(result);
        if (previewHTML.current) {
          previewHTML.current.innerHTML = result.data;
        }
      });
  }

  function sendEmail(data: any) {
    setSending(true);

    axios
      .postForm(URLProd + "backend/mail/mail-invoice.php", data)
      .then((result: any) => {
        if (result?.data?.success) {
          setSent(true);
          fillLog({
            user: userID,
            section: "lsi-sales-invoices",
            event: "mail-sent",
            item_id: invoice?.SCID,
          });
        } else {
          setSendError(true);
          setErrorMessage(result?.data?.error);
          fillLog({
            user: userID,
            section: "lsi-sales-invoices",
            event: "mail-failed",
            item_id: invoice?.SCID,
          });
        }
      })
      .catch((error: any) => setSendError(true))
      .finally(() => {
        setTimeout(() => setSending(false), 60);
      });
  }

  function getFormData(form: FormData) {
    return {
      recipient: form.get("recipient"),
      comments: form.get("comments"),
      subject: form.get("subject"),
      attachment: pdf,
      paymentLink: paymentLink,
      date: prettyDate(new Date(invoice?.data_modyfikacji), "", true, {
        format: "text",
      }),
      amount: prettyMoney(invoice?.ValueBWal, { text: true }),
    };
  }

  function makePreview() {
    const f = form.current;
    if (f) {
      const data = {
        // recipient: e.target.parentNode.parentNode.querySelector("#recipient").textContent,
        comments: f.querySelector("#comments").value,
        amount: prettyMoney(invoice?.ValueBWal, { text: true }),
        paymentLink: paymentLink,
        date: prettyDate(new Date(invoice?.data_modyfikacji), "", true, {
          format: "text",
        }),
      };

      getPreview(data);
    }
  }
  makePreview();

  return (
    <div className="invoice-send" style={style}>
      <div
        className="return"
        onClick={() => {
          setMode("print");
          setSent(false);
          setSendError(false);
        }}
      >
        {"<"} Powrót
      </div>
      {sending ? (
        <div>
          <Loading little={true} title="Wysyłanie" />
        </div>
      ) : sendError ? (
        <>
          <div className="sent">
            <span className="error-x">X</span>Nie udało się wysłać wiadomości
          </div>
          <div>{sendError}</div>
        </>
      ) : sent ? (
        <div className="sent">
          <span className="tick">✓</span> Wiadomość została wysłana
        </div>
      ) : (
        <div>
          <h1>Wyślij fakturę e-mailem</h1>
          <form
            ref={form}
            onChange={makePreview}
            onSubmit={(e: any) => {
              e.preventDefault();
              const form = new FormData(e.target);
              const data = getFormData(form);
              sendEmail(data);
            }}
          >
            <div>
              <label>Adres e-mail klienta</label>
              <input
                type="email"
                required={true}
                name="recipient"
                id="recipient"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                placeholder={
                  fetchClient?.loading
                    ? "Pobieranie z bazy LSI..."
                    : "Wpisz e-mail"
                }
                disabled={fetchClient?.loading}
              />
            </div>
            <div>
              <label>Temat</label>
              <input
                type="text"
                name="subject"
                value={subject}
                onChange={(e: any) => setSubject(e.target.value)}
              />
            </div>
            <div>
              <textarea
                name="comments"
                id="comments"
                rows={6}
                placeholder="Dodatkowe uwagi"
              />
            </div>
            <div>
              {pdf ? (
                <button className="normal-button" type="submit">
                  Wyślij
                </button>
              ) : (
                <>Ładowanie załącznika...</>
              )}
            </div>
          </form>
        </div>
      )}
      <div
        style={{ marginTop:"27px", transformOrigin: "50% 0", scale: ".85" }}
        ref={previewHTML}
      ></div>
    </div>
  );
}
