import { objectOrJSONparse } from "../functions/tools";
import EventInTable from "./EventInTable";
import FoundItem from "./FoundItem";
import { calendarEntry } from "./calendarEntry";
import openEditor from "./openEditor";

export default function FoundItems({
  items,
  setUpdate,
}: {
  items: calendarEntry[];
  setUpdate: Function;
}) {
  let futureWas = false;
  return (
    items && (
      <div className="found-items">
        <table className="data-table midi calendar-table-element">
          <thead>
            <tr>
              <th>Nr</th>
              <th>Data</th>
              <th>Rodzaj</th>
              <th>Sala</th>
              <th>Czas</th>
              <th>Stand/TV</th>
              <th>Klient</th>
              <th>Szczegóły</th>
              <th>Dni</th>
              <th>Wpłaty</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items.map((event: any, index: number) => {
              // console.log(objectOrJSONparse(event?.dates)?.[0]);
              const future =
                !futureWas &&
                new Date(
                  objectOrJSONparse(event?.dates)?.at(-1)?.date
                ).getTime() > new Date().getTime();
              // console.log(future, futureWas, index);
              if (future) {
                futureWas = true;
              }

              return (
                objectOrJSONparse(event?.dates)?.[0]?.date && (
                  <>
                    {future ? (
                      <tr style={{ background: "none" }}>
                        <td
                          style={{ textAlign: "left", fontSize: "10px" }}
                          colSpan={10}
                        >
                          ▼
                        </td>
                      </tr>
                    ) : (
                      <></>
                    )}
                    <EventInTable
                      key={event?.id}
                      event={event}
                      editor={openEditor}
                      list={true}
                      date={
                        new Date(objectOrJSONparse(event?.dates)?.[0]?.date)
                      }
                      index={index}
                      search={true}
                    />
                  </>
                  // <FoundItem key={event.id} item={event} setUpdate={setUpdate}/>
                )
              );
            })}
          </tbody>
        </table>
      </div>
    )
  );
}
