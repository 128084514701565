import { isToday } from "../functions/prettyDate";
import Day from "./Day";

interface dateObj {
  obj: Date;
  events: any;
}

export default function CalendarWeek({ cal }: { cal: any }) {
  const weekdays = [
    { name: "Pon", className: "" },
    { name: "Wt", className: "" },
    { name: "Śr", className: "" },
    { name: "Czw", className: "" },
    { name: "Pt", className: "" },
    { name: "Sob", className: "saturday" },
    { name: "Nie", className: "sunday" },
  ];

  const weekView = (
    <div className="calendar week-view">
      <div className="weekdays">
        {weekdays.map(
          (day: { name: string; className: string }, index: number) => {
            const shownDate = new Date(cal.range[0]);
            shownDate.setDate(shownDate.getDate() + index);
            return (
              <div
                key={index}
                className={
                  day.className +
                  " calendar-day-number" +
                  (isToday(shownDate) ? " today" : "")
                }
              >
                {day.name},{" "}
                {shownDate.toLocaleDateString("pl-PL", {
                  day: "2-digit",
                  month: "short",
                })}
              </div>
            );
          }
        )}

        {/*cal.dates.map((date: dateObj) => (
          <div className={weekdays[date.obj.getDay()].className}>
            {weekdays[date.obj.getDay()].name}
          </div>
        ))*/}
      </div>
      {cal.dates.map((date: dateObj) => (
        <Day key={date.obj.getDate()} date={date} />
      ))}
    </div>
  );

  return weekView;
}
