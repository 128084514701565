export default function DateSwitch({
  date,
  setDate,
}: {
  date: string | undefined;
  setDate: Function;
}) {
  return date ? (
    <div className="page-change-wrap">
        {/* <div
        className={"page-change active"}
        onClick={() => {
            const o = new Date(date);
            o.setMonth(o.getMonth() - 1);
            setDate(o?.toISOString()?.slice(0,10));
          }}
      >
        {"M<"}
      </div> */}
      <div
        className={"page-change active"}
        onClick={() => {
          const o = new Date(date);
          o.setDate(o.getDate() - 1);
          setDate(o?.toISOString()?.slice(0,10));
        }}
      >
        {"<"}
      </div>
      <div className="page-change-main">
        {date && <>{new Date(date)?.toLocaleDateString("pl-PL", {
            day: "2-digit",
            month:"2-digit",
            year:"numeric"
        })}</>}
      </div>
      <div
        className={"page-change active"}
        onClick={() => {
          const o = new Date(date);
          o.setDate(o.getDate() + 1);
          setDate(o?.toISOString()?.slice(0,10));
        }}
      >
        {">"}
      </div>
      {/* <div
        className={"page-change active"}
        onClick={() => {
            const o = new Date(date);
            o.setMonth(o.getMonth() + 1);
            setDate(o?.toISOString()?.slice(0,10));
          }}
      >
        {">M"}
      </div> */}
    </div>
  ) : (
    <></>
  );
}
