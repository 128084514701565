import { useContext } from "react";
import Form from "../forms_new/Form";
import EventInTable from "./EventInTable";
import { addEventFormPotential } from "./addEventFormPotential";
import openEditor from "./openEditor";
import { ViewContext } from "../contexts/ViewContext";
import insertGeneric from "../dataFetch/insertGeneric";
import { objectOrJSONparse } from "../functions/tools";
import { isToday } from "../functions/prettyDate";
import AddPotentialEvent from "./AddPotentialEvent";
import AddPotentialButton from "./AddPotentialButton";

export default function TableDay({
  date,
  auth,
  calendar,
  ref,
}: {
  date: any;
  auth: any;
  calendar: any;
  ref: any;
}) {
  const { modal, setModal } = useContext(ViewContext);
  return (
    <>
      <tr
        className={"event-table-day" + (isToday(date?.obj) ? " today" : "")}
        style={
          !date.events.length
            ? { background: "none" }
            : { position: "sticky", top: "17px", zIndex: 2 }
        }
      >
        <td
          colSpan={10}
          style={{
            textAlign: "left",
            fontSize: "75%",
            padding: 0, //"3px 5px",
            // paddingTop: "8px",
            opacity: date.events.length ? 1 : 0.7,
            // fontWeight: date.events.length ? "bold" : "100",
            textTransform: "uppercase",
          }}
        >
          <div
            ref={ref}
            id={"d" + date.obj.getDate()}
            style={{
              // background: "yellow",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                // background: "yellow",
                display: "grid",
                gridTemplateColumns: "150px max-content",
                background: date?.events?.length
                  ? "linear-gradient(90deg, #dfdfb1, #dfdfb1, #dfdfb1, transparent)"
                  : "none",
                padding: "2px 9px",
                minWidth: "280px",
                // borderRadius: "3px",
                // boxShadow: "-14px 0px 0px #dfdfb1",
                // boxShadow: "0 7px 4px -5px rgba(0,0,0,0.13)"
              }}
            >
              <div style={{ padding: "0 10px" }}>
                {date.obj.toLocaleDateString("pl-PL", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </div>
              <div style={{ padding: "0 10px" }}>
                {date.obj.toLocaleDateString("pl-PL", {
                  weekday: "long",
                })}
              </div>
            </div>
            {auth?.add ? (
              <div
                style={{ display: "grid", gridTemplateColumns: "150px 100px" }}
              >
                <AddPotentialButton
                  setModal={setModal}
                  auth={auth}
                  date={date}
                  calendar={calendar}
                />
                <div
                  className="add-event-table"
                  onClick={() =>
                    openEditor(
                      undefined,
                      date,
                      { modal: { modal, setModal }, calendar: calendar },
                      auth
                    )
                  }
                  title={"Dodaj wydarzenie"}
                >
                  + DODAJ
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </td>
      </tr>
      {date.potentialEvents.length ? (
        date.potentialEvents.map((i: any, index: number) => {
          const dates = objectOrJSONparse(i?.dates);
          console.log(dates, date);
          const whichDay =
            dates && dates.length > 1
              ? dates
                  .map((d: any) => d.date)
                  .indexOf(date?.obj?.toISOString().slice(0, 10)) + 1
              : undefined;
          return (
            <EventInTable
              key={i?.id + "_" + date?.obj?.toISOString()?.slice(0, 10)}
              event={i}
              editor={openEditor}
              list={true}
              date={date.obj}
              index={index}
              potential={true}
            />
            // <tr className="potential-event">
            //   <td>%</td>
            //   <td>{i.event_type_string}</td>
            //   <td>{i.venue_string}</td>
            //   <td colSpan={2} style={{ background: "transparent" }}>
            //     <div style={{textTransform:"uppercase", fontSize:"11px", color:"grey", background:"white"}}>robocze</div>
            //   </td>
            //   <td style={{ textAlign: "left", padding: "0 5px" }}>
            //     {i?.is_company
            //       ? i?.company_name
            //       : i?.first_name + " " + i?.last_name}
            //   </td>
            //   <td colSpan={2}>{i?.comment}</td>
            //   <td>{whichDay}</td>
            //   <td></td>
            //   <td></td>
            // </tr>
          );
        })
      ) : (
        <></>
      )}
      {date.events.length ? (
        date.events.map((i: any, index: number) => (
          <EventInTable
            key={i?.id + "_" + date?.obj?.toISOString()?.slice(0, 10)}
            event={i}
            editor={openEditor}
            list={true}
            date={date.obj}
            index={index}
          />
        ))
      ) : (
        <></>
      )}
    </>
  );
}
