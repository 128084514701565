import useGeneric from "../dataFetch/useGeneric";
import addIcon from "../../images/icons/add.svg";
import { ViewContext } from "../contexts/ViewContext";
import { useContext, useEffect, useState } from "react";
import Editables from "./Editables";
import Unit from "./Unit";
import { updateOrder } from "../MenuEditor";
import EditableItem from "./EditableItem";
import NewMenu from "./NewMenu";

export default function Menus() {
  const [updates, setUpdates] = useState(0);

  const { loading, rows } = useGeneric({
    name: "restaurant-menus",
    update: updates,
  });
  const { modal, setModal } = useContext(ViewContext);

  return (
    <>
      <div
        onClick={async () => {
          setModal({
            show: true,
            heading: "Nowa karta",
            content: <NewMenu setModal={setModal} setUpdates={setUpdates} />,
          });
        }}
        className="menu-add-button"
      >
        <img src={addIcon} width={20} />
        Dodaj nową kartę
      </div>
      <div className="entry-editor">
        <div className="entry-editor-unit heading menus-edit">
          <div></div>

          {/* <div></div> */}
          <div>ID</div>
          <div>Nazwa</div>
          <div>Aktywne od</div>
          <div>Widoczna</div>
        </div>
        <Editables>
          {rows ? (
            rows?.map((i: any, index: number, arr: any) => (
              <Unit
                classNames={"menus-edit"}
                data={i}
                setUpdates={setUpdates}
                index={index}
                key={i?.id}
                style={
                  arr?.findIndex((a: any) => a?.current) === index
                    ? { background: "#a1f7a1" }
                    : {}
                }
              >
                {/* <div className="entry-editor-unit"> */}
                <EditableItem
                  data={i}
                  name="id"
                  locked={true}
                  updateDB="update-menus-item"
                />
                <EditableItem
                  data={i}
                  name="name"
                  updateDB="update-menus-item"
                />
                <EditableItem
                  data={i}
                  name="active_from"
                  type="date"
                  updateDB="update-menus-item"
                />
                <EditableItem
                  data={i}
                  name="enabled"
                  type="checkbox"
                  updateDB="update-menus-item"
                  copy={false}
                />
              </Unit>
            ))
          ) : (
            <></>
          )}
        </Editables>
      </div>
    </>
  );
}
