import { useFormContext } from "react-hook-form";

export default function ShowOtherValues({
  visible,
  fieldValue,
  fieldName,
}: {
  visible: Boolean;
  fieldValue: string;
  fieldName: string;
}) {
  const { getValues } = useFormContext();

  const fieldNameGeneric = fieldName.replace(/%.*%/, "");
  const keys = Object.keys(getValues()).filter((k: any) =>
    k.match(fieldNameGeneric)
  );
  //   const currIndex = keys.findIndex((k: any) => k === fieldName);


  return visible ? (
    <div className="show-other-values">
      {keys.map((i: string) => {
        const value = getValues(i);
        const valueAbbr = String(value).slice(0, 15);
        if (i === fieldName) {
          return <div style={{ padding: "0 4px" }}>⬩</div>;
        } else {
          return valueAbbr ? (
            <div className="other-values-item">
              {valueAbbr}
              {value != valueAbbr ? "..." : ""}
            </div>
          ) : <div className="other-values-item" style={{fontSize:"8px"}}>{"<->"}</div>;
        }
      })}
    </div>
  ) : (
    <></>
  );
}
