import prettyDate from "../../../functions/prettyDate";
import mailIcon from "../../../../images/icons/envelope.png";

export function row(row?: any): any[] {
  return [
    {
      name: "Nr klienta",
      value: row?.NumerKlienta?.replace("001-0000000000", "..."),
      className: "NumerKlienta",
      style: { fontSize: "9px", width: "60px", color: "rgb(30,30,30)" },
    },
    {
      name: "Nazwisko",
      source: "Name",
      value: row?.Name,
    },
    {
      name: "Imię",
      source: "FirstName",
      value: row?.FirstName,
    },
    {
      name: "Nazwa firmy",
      source: "FirmName",
      value: row?.FirmName,
      style: {
        width: "260px",
      },
    },
    {
      name: "Miasto",
      source: "AddrCity",
      value: row?.AddrCity,
    },
    {
      name: "Ulica",
      source: "AddrStreet",
      value: `${row?.AddrStreet || "-"} ${row?.AddrLNo || ""} ${
        row?.AddrCountry && row?.AddrCountry != "Polska" ? row?.AddrCountry : ""
      }`,
      style: {width:"100px"}
    },
    {
      name: "Kod pocztowy",
      source: "AddrZIP",
      value: row?.AddrZIP,
    },
    {
      name: "Telefon",
      source: "Phone",
      value: row?.Phone,
    },
    {
      name: "Komórka",
      source: "TelefonKom",
      value: row?.TelefonKom,
    },
    {
      name: "Email",
      source: "Email",
      value: row?.Email ? (
        <a href={"mailto:" + row?.Email}>
          <img width={15} src={mailIcon} />
        </a>
      ) : <>-</>,
    },
    {
      name: "Data ostatniej aktywności",
      source: "DataOstatniejAktywnosci",
      value: prettyDate(row?.DataOstatniejAktywnosci),
      style: { width: "120px" },
    },
  ];
}
