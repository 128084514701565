import { useState } from "react";

export default function ScheduleColumnEvents({
  events,
  view,
  setView,
  selectedDay
}: {
  events: any;
  view: boolean;
  setView: Function;
  selectedDay:boolean;
}) {
  return view && events && events.length ? (
    <td className={"eventsCell" + (selectedDay ? " selectedDay" : "")} key={events ? events[0].id : 0}>
        <div className="foldBack" onClick={() => setView(false)}></div>
        <div className="events">
          {events.map((event: any) => (
            <div className="event">
              <div className="time">
                {event.time_start.slice(0, 5)}-{event.time_end.slice(0, 5)}
              </div>
              <div className="type" title={event.details}>
                {event.event_string}
              </div>
              <div className="client">
                {event.company_name || event.first_name + " " + event.last_name}
              </div>
              <div className="venue">{event.venue_string}</div>
            </div>
          ))}
        </div>
    </td>
  ) : !view ? (
    <td className="foldedEventView" onClick={() => setView(true)}></td>
  ) : <td></td>;
}
