import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import RegistersTabs from "./RegistersTabs";
import { registerTabSchema } from "./registerTabSchema";
import { ViewContext } from "../contexts/ViewContext";
import { UserContext } from "../contexts/UserContext";
import ViewError from "./Schemas/Errors/ViewError";

export default function Registers() {
  // const location = useLocation();
  // const subPath = location?.pathname.match(/\/registers\/(.*)/);
  // const { subPath } = useParams();
  const params = useParams();
  const subPath = params?.["*"];

  // console.log(params, subPath);
  const [selected, setSelected] = useState(subPath ? subPath?.[1] : false);
  const [collapse, setCollapse] = useState(
    localStorage.getItem("register-collapse") === "true" ? true : false
  );
  const { currentPage, setCurrentPage } = useContext(ViewContext);
  const { auth } = useContext(UserContext);
  // console.log(auth);
  // setCurrentPage("registers");
  const sections = registerTabSchema;
  let begin = !selected;

  useEffect(() => {
    localStorage.setItem("register-collapse", String(collapse));
  }, [collapse]);

  // useEffect(() => {
  //   if (!subPath?.[1]) {
  //     setSelected(false);
  //   } else {
  //     setSelected(subPath?.[1]);
  //   }
  // }, [location?.pathname]);

  return (
    <div
      className={
        "standardWrap registers" +
        (begin ? " welcome" : "") +
        (collapse && !begin ? " collapsed" : "")
      }
    >
      <RegistersTabs
        sections={sections}
        selected={selected}
        setSelected={setSelected}
        collapse={collapse}
        setCollapse={setCollapse}
      />
      <main>
        <Routes>
          {sections?.map((route: any) => (
            <>
              <Route path={route?.name} element={route?.element} />

              {route?.sub?.map((route: any) => (
                <Route path={route?.name} element={route?.element} />
              ))}
              <Route path={"errors/:id"} element={<ViewError />} />
            </>
          ))}
        </Routes>
      </main>
    </div>
  );
}
