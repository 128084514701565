import { useContext, useEffect, useRef, useState } from "react";
import { EditableContext } from "./Editables";
import insertGeneric from "../dataFetch/insertGeneric";
import copyIcon from "../../images/icons/copy.svg";
import { RowUnitContext } from "./Unit";

export default function EditableItem({
  data,
  name,
  field = "input",
  type = "text",
  locked = false,
  updateDB,
  copy = true,
  kind,
}: {
  data: any;
  name: string;
  field?: string;
  type?: string;
  locked?: Boolean;
  updateDB: string;
  copy?: Boolean;
  kind?: string;
}) {
  const { currentEditID, setCurrentEditID, lastSavedID, setLastSavedID } =
    useContext(EditableContext);

  const initialValue = data?.[name];
  const [value, setValue] = useState(initialValue);
  const [savedValue, setSavedValue] = useState(initialValue);
  const [saving, setSaving] = useState(false);

  const [error, setError] = useState(false);

  const id = name + "-" + data?.id;

  const { setUpdates } = useContext(RowUnitContext);

  const userInput = useRef<any>();

  async function commit() {
    if (value !== savedValue) {
      setSavedValue(value);
      setCurrentEditID("");
      setSaving(true);

      const res = await insertGeneric(updateDB, {
        prop: name,
        value: value,
        id: data?.id,
        kind: kind,
      });
      // console.log(res);
      if (res?.response?.result === "OK") {
        setLastSavedID(id);
      } else {
        setError(true);
      }
      setSaving(false);

      setUpdates((prev: any) => prev + 1);
    }
  }

  useEffect(() => {
    if (currentEditID === id) {
      userInput?.current?.focus();
    }
  }, [currentEditID]);

  return currentEditID !== id ? (
    <div
      className="editing-item-view"
      style={
        saving
          ? { background: "yellow" }
          : lastSavedID === id
          ? { background: "lightgreen" }
          : error
          ? { background: "brown" }
          : {}
      }
      onDoubleClick={() => {
        !locked && setCurrentEditID(id);
      }}
      onClick={() => setCurrentEditID("")}
    >
      {type === "checkbox" ? savedValue ? <>tak</> : <>nie</> : savedValue}{" "}
      {savedValue && copy ? (
        <span
          className="copy"
          onClick={() => {
            navigator.clipboard.writeText(savedValue);
          }}
        >
          <img src={copyIcon} width={11} style={{ opacity: 0.6 }} />
        </span>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <div className="editing-item">
      {/* {JSON.stringify(value)} */}
      {field === "textarea" ? (
        <textarea
          tabIndex={0}
          ref={userInput}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              commit();
            }
          }}
        />
      ) : field === "input" && type === "checkbox" ? (
        <input
          ref={userInput}
          tabIndex={0}
          checked={Boolean(value)}
          type="checkbox"
          onChange={(e) => {
            console.log(e.target.checked);
            setValue(e.target.checked ? true : false);
          }}
        />
      ) : field === "input" ? (
        <input
          ref={userInput}
          tabIndex={0}
          value={value}
          type={type}
          onChange={(e) => setValue(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              commit();
            }
          }}
        />
      ) : (
        <></>
      )}
      <div className="buttons">
        <button
          className={value === savedValue ? "inactive" : ""}
          onClick={commit}
        >
          Zapisz
        </button>
        <button onClick={() => setCurrentEditID("")}>Anuluj</button>
      </div>
    </div>
  );
}
