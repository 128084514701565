import { useContext, useEffect, useRef, useState } from "react";
import { isSameDay } from "../../schedules/ScheduleTable";
import { ViewContext } from "../../contexts/ViewContext";
import Person from "../../common/Person";
import FormatPeople from "../../common/FormatPeople";
import { prettyDateRange } from "../../functions/prettyDate";

export default function BookingCell({
  date,
  booking,
  previous,
  next,
}: {
  date: Date;
  booking: any;
  previous: any;
  next: any;
}) {
  const { modal, setModal } = useContext(ViewContext);
  const startDay = isSameDay(booking.data_od, date);
  const endDay = isSameDay(booking.data_do, date);
  const guestSplit = booking?.gosc?.split(" ");
  const guestShort = guestSplit?.[0] + " " + guestSplit?.[1].slice(0, 1);

  const source = booking?.nazwa_zrodla
    ?.replace(/rezerwacja /gi, "")
    ?.replace(/telefoniczna/i, "tel.")
    ?.replace(/booking engine/i, "Profitroom");

  const cell: any = useRef();
  const [cellPosition, setCellPosition] = useState({
    top: 0,
    left: 0,
    windowWidth: window.innerWidth,
  });
  const [scrollTop, setScrollTop] = useState(0);
  const [scrolling, setScrolling] = useState(false);

  const onScroll = (e: any) => {
    setScrollTop(e.target.documentElement.scrollTop);
    setScrolling(e.target.documentElement.scrollTop > scrollTop);
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);
  useEffect(() => {
    var rect = cell.current.getBoundingClientRect();
    setCellPosition({
      top: rect.top + window.scrollY,
      left: rect.left,
      windowWidth: window.innerWidth,
    });
    // console.log(rect.top, rect.right, rect.bottom, rect.left);
  }, [scrollTop]);

  return (
    <div
      //   title={
      //     booking?.gosc +
      //     "\r\n" +
      //     booking?.numer_rezerwacji +
      //     "\r\n" +
      //     source +
      //     "\r\n" +
      //     (new Date(booking?.data_od).toLocaleDateString() +
      //       ", " +
      //       booking?.godzina_od?.slice(11, 16)) +
      //     " - " +
      //     (new Date(booking?.data_do).toLocaleDateString() +
      //       ", " +
      //       booking?.godzina_do?.slice(11, 16)) +
      //     "\r\n" +
      //     booking?.uwaga
      //   }
      onClick={() =>
        setModal({
          show: true,
          heading: "Rezerwacja",
          content: (
            <div className="room-detailed-view" style={{ width: "100%" }}>
              <div>
                <div>Gość</div>
                <div>
                  <FormatPeople loaded={!modal?.show}>
                    <Person name={booking?.gosc} />
                  </FormatPeople>
                </div>
              </div>
              <div>
                <div>Nr rezerwacji</div>
                <div>{booking?.numer_rezerwacji}</div>
              </div>
              <div>
                <div>Pokój</div>
                <div>{booking?.numer}</div>
              </div>
              <div>
                <div>Od</div>
                <div>
                  {new Date(booking?.data_od).toLocaleDateString()}{" "}
                  {booking?.godzina_od?.slice(10, 16)}
                </div>
              </div>
              <div>
                <div>Do</div>
                <div>
                  {new Date(booking?.data_do).toLocaleDateString()}{" "}
                  {booking?.godzina_do?.slice(10, 16)}
                </div>
              </div>
              <div>
                <div>Uwaga</div>
                <div>
                  <pre>{booking?.uwaga}</pre>
                </div>
              </div>
            </div>
          ),
        })
      }
      className={
        "guest" +
        " tooltip slow" +
        (startDay ? " check-in" : endDay ? " check-out" : "") +
        (booking?.checkinowana === "1"
          ? " present"
          : booking?.checkinowana === "0"
          ? " will-be"
          : booking?.checkinowana === "2"
          ? " was"
          : "")
      }
      ref={cell}
    >
      <div
        className="top"
        style={{
          padding: "1px 4px",
          borderRadius: "5px",
          fontSize: "10px",
          maxHeight: "120px",
          overflowY: "auto",
          textAlign: "left",
          width: "250px",
          textWrap: "pretty",
          // translate: "230px 0",
          zIndex: 9,
          // right: cellPosition.right < 300 ? "300px" : "100%",
          left:
            cellPosition.windowWidth - cellPosition.left < 300
              ? "-100px"
              : "80%",
          top: cellPosition.top < 300 ? "90px" : "-4px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div style={{ fontSize: "12px", fontWeight: "bold" }}>
            <Person name={booking?.gosc} />
          </div>{" "}
          <div style={{ padding: "3px", color: "brown" }}>{source}</div>
          <div
            style={{
              marginTop: "1.5px",
              padding: "1px 5px",
              background: "brown",
              color: "white",
            }}
          >
            {booking?.numer}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        ><div>
          {prettyDateRange(new Date(booking?.data_od), new Date(booking?.data_do))}
          {/* <div>
            {new Date(booking?.data_od).toLocaleDateString("pl-PL", {
              month: "long",
              day: "numeric",
            }) +
              ", " +
              booking?.godzina_od?.slice(11, 16) +
              " - "}
          </div>
          <div>
            {new Date(booking?.data_do).toLocaleDateString("pl-PL", {
              month: "long",
              day: "numeric",
            }) +
              ", " +
              booking?.godzina_do?.slice(11, 16)}
          </div> */}
          </div>
          <div style={{ color: "brown" }}>{booking?.numer_rezerwacji}</div>
        </div>
        <pre style={{fontSize:"11px"}}>{booking?.uwaga}</pre>
      </div>

      <div
        className={
          "guest-name-tag" +
          (next?.gosc === booking?.gosc &&
          next?.checkinowana === booking?.checkinowana
            ? " long"
            : "")
        }
      >
        {previous?.gosc === booking?.gosc ? (
          ""
        ) : (
          <>
            <Person name={booking?.gosc} short={true} />
            <div className="details">{source}</div>
          </>
        )}
      </div>
    </div>
  );
}
