import ReceiptDetails from "../../../calendar/ReceiptDetails";
import prettyDate from "../../../functions/prettyDate";
import prettyNumber from "../../../functions/prettyNumber";
import BooleanSymbol from "../../../tables/viewElements/BooleanSymbol";

export function row(row?: any): any[] {
  return [
    {
      source: "id",
      name: "Id",
      value: <>{row?.id}</>,
      className: "id",
    },
    {
      source: "pos",
      name: "POS",
      value: <>{row?.pos}</>,
    },
    {
      source: "full_number",
      name: "Nr dok.",
      // value: <>{row?.DocNo}</>,
      onClickModal: <ReceiptDetails id={row?.id} />,
      value: <>{row?.full_number || row?.DocNo}</>,
    },
    {
      source: "ko_firma",
      name: "Klient",
      style: {
        width: "250px",
      },
      value: <>{row?.ko_firma}</>,
    },
    {
      source: "ilosc_osob",
      name: "Liczba osób",
      value: <>{row?.ilosc_osob}</>,
    },
    {
      source: "TerminPlatnosciRachunku",
      name: "Termin płatności",
      value: <>{prettyDate(row?.TerminPlatnosciRachunku)}</>,
    },
    {
      source: "ValueBWal",
      name: "Wartość brutto",
      value: (
        <>{prettyNumber(row?.ValueBWal, { round: true, postfix: "zł" })}</>
      ),
    },
    {
      source: "paid_net",
      name: "Wartość netto",
      value: (
        <>
          {row?.paid_net &&
            prettyNumber(row?.paid_net, {
              round: true,
              postfix: "zł",
            })}
        </>
      ),
    },
    {
      source: "paid_type",
      name: "Sposób płatnośći",
      value: <>{row?.paid_type}</>,
    },
    {
      source: "wystawiono_fakture",
      name: "Z fakturą",
      value: <>{row?.wystawiono_fakture == "1" ? "TAK, nr " + row?.invoice_number : ""}</>,
    },
  ];
}
/*
NipNabywcy: null;
RezHotelID: "85237";
RezHotelPokojID: "115111";
SymbRozr: "2024";
data_dzienna: "2024-05-07 00:00:00.000";
data_modyfikacji: "2024-05-07 09:21:42.023";
do_przedplaty: "0";
do_wizyty: null;
docNo: "2114";
doc_id: "153817";
id: "94754";
id_dzienne: null;
id_karty_lojalnosciowej: "0";
id_karty_rabatowej: "0";
id_klienta: "66015";
id_lokalu: "1";
id_rezerwacji: "0";
id_sprzed_spec: "0";
ilosc_osob: "0";
kelner_konc: "171";
kelner_rozp: "171";
ko_adres: "ul. Do Dysa 5";
ko_firma: "POLBIOECO POLSKA SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ";
ko_miasto: "20-149 Lublin";
ko_nip: "9462649202";
koniec_realizacji: "2024-05-07 09:21:31.333";
koryg_id: null;
korygowano: "0";
mdowind: "0";
mojeGastroOrderID: "00000000-0000-0000-0000-000000000000";
month: "5";
napiwek_fiskalny: "0.0";
napiwek_kasa: "0.0";
napiwek_niefiskalny: "0.0";
numer: "1";
numerNaBon: null;
numer_karty_lojalnosciowej: "";
numer_karty_rabatowej: null;
numer_karty_wirtualnej: null;
powod_korekty: "0";
poziom_cenowy: "1";
program: "Hotel H2019.2 FR02.5188";
rabat: "0.0";
rachunkidoc_id: null;
rodzaj_zamowienia: "8";
rowguid: "2056FBC4-9DA2-4734-A827-2D0CFBB09442";
rozliczone: "1.0";
rozp_realizacji: "2024-05-06 18:45:18.000";
stolik: "0";
tryb_zapisu_dodatkow: "0";
typ: "0";
typ_rezerwacji: "0";
wartosc: "10.0";
wartosc_total: "10.0";
wplata: null;
wyroznik: null;
wystawiono_fakture: null;
wystawiono_note: null;
wystawiono_paragon: "1";
year: "2024";
z_zamowienia: "231734";
_DataWpisu: "2024-05-07 09:21:31.423";
_KwRozliczenia: "10.0";
_WB: "10.0";
_WZALB: "0.0";
_execute: "0";*/
