import { useEffect, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import prettyDate from "../functions/prettyDate";
import { prettyTime } from "../functions/tools";
import invoiceTypes from "./Print/invoiceTypes";

export default function InvoiceLog({
  invoice,
  setMode,
}: {
  invoice: invoiceTypes;
  setMode: Function;
}) {
  const [all, setAll] = useState(false);
  const fetchLog = useGeneric({
    name: "get-log",
    query: {
      section: "lsi-sales-invoices",
      item_id: invoice?.SCID,
      event: all ? undefined : ["view", "negative"],
    },
    reload: true,
    secondsToReload: 15,
  });

  const log = !fetchLog?.loading && fetchLog?.rows;

  if (log && Array.isArray(log) && log?.at(-1)?.id !== -1) {
    log?.push({
      id: -1,
      time: invoice?.data_modyfikacji,
      section: "lsi-sales-invoices",
      userstring:
        invoice?.imie_uzytkownika + " " + invoice?.nazwisko_uzytkownika,
      event: "issued",
    });
  }
  console.log(log);

  return log ? (
    <div className="invoice-send">
      <div
        className="return"
        onClick={() => {
          setMode("print");
        }}
      >
        {"<"} Powrót
      </div>
      <h1>Historia</h1>
      <button
        onClick={() => setAll((prev: boolean) => !prev)}
        className="normal-button"
        style={{ width: "200px", float: "right" }}
      >
        {all ? <>Tylko ważne</> : <>Zobacz wszystkie</>}
      </button>
      <div>
        <table className="data-table midi">
          <thead>
            <tr>
              <th>Czas</th>
              <th>Użytkownik</th>
              <th>Zdarzenie</th>
              {/* <th>Faktura</th> */}
              {/* <th>IP</th> */}
            </tr>
          </thead>
          <tbody>
            {log?.map((i: any) => {
              const event =
                i?.event === "view"
                  ? "podgląd"
                  : i?.event === "save-pdf"
                  ? "pobrano pdf"
                  : i?.event === "mail-sent"
                  ? "wysłano e-mail"
                  : i?.event === "issued"
                  ? "wystawiona LSI"
                  : i?.event;
              return (
                <tr
                  style={
                    i?.event === "issued"
                      ? { background: "#c6ffc6" }
                      : i?.event === "mail-sent"
                      ? { background: "#a9c1ff" }
                      : {}
                  }
                >
                  <td>{prettyDate(i.time)}</td>
                  <td>{i.userstring}</td>
                  <td>{event}</td>
                  {/* <td>{i.item_id}</td> */}
                  {/* <td>{i.ip_full}</td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <></>
  );
}
