export default function SelectHolidayType({
  holidayTypes,
  setSumType,
  all,
  def
}: {
  holidayTypes: any;
  setSumType: Function;
  all?: Boolean;
  def?:number;
}) {
  return (
    <div>
      <select
        onChange={(e: any) => {
          e.preventDefault();
          setSumType(parseInt(e.target.value));
        }}
        defaultValue={def ? def : undefined}
      >
        {all ? <option value={0}>Wszystkie</option> : <></>}
        {holidayTypes
          .filter((i: any) => i.id !== 2)
          .map((i: any) => (
            <option value={i.id}>{i.name}</option>
          ))}
      </select>
    </div>
  );
}
