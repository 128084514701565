import { deepCompare, isJSONParsable, isObject } from "../functions/tools";

export default function useRowCompare({
    prop,
    before,
    after,
    schema,
    options,
  }: {
    prop: string;
    before: any;
    after: any;
    schema: any;
    options: any;
  }) {
    const show = JSON.stringify(before[prop]) !== JSON.stringify(after[prop]);
  
    const propRaw = prop?.match(/^(.*)[%@](.*)[%@]/)?.[1]
      ? prop.match(/^(.*)[%@](.*)[%@]/)?.[1]
      : prop;
  
    const propSchema = schema.find(
      (s: any) => s.name === prop || s.name === propRaw
    );
  
    const columns = propSchema?.multiAddProps?.columns;
    const columnKeys = columns && Object.keys(propSchema?.multiAddProps?.columns);
  
    const multi = propSchema?.multiAddProps ? true : false;
  
    const isJSON =
      isJSONParsable(before[prop]) &&
      JSON.parse(before[prop]) ||
      isJSONParsable(after[prop]) &&
      JSON.parse(after[prop]);


    let deepAnalysis: any =
      multi && isJSON && deepCompare(before[prop], after[prop], columnKeys);
    // console.log(deepAnalysis);
    // console.log(propRaw);


  
    const optionsList =
      propSchema?.getOptionsFrom && Array.isArray(propSchema?.getOptionsFrom)
        ? propSchema?.getOptionsFrom
        : propSchema?.getOptionsFrom
        ? options?.[propSchema?.getOptionsFrom]?.rows
        : undefined;
  
      return {
          show: show,
          propSchema: propSchema,
          propRaw: propRaw,
          multi: multi, 
          optionsList: optionsList, 
          deepAnalysis: deepAnalysis,
          isJSON: isJSON,
          columns: columns,
          columnKeys:columnKeys
      }
  }
  