import useGeneric from "../dataFetch/useGeneric";
import prettyDate from "../functions/prettyDate";

export default function VoucherDetails({
  id,
  number,
}: {
  id?: any;
  number?: any;
}) {
  const { loading, rows } = useGeneric({
    name: number || id ? "voucher-register" : "",
    query: number ? {
      number_text: number,
    } : id ? {
        id: id
    } : {},
  });
  const v = !loading && rows?.[0];

  return (
    <div style={{ width: "100%" }}>
      <h2
        style={{
          marginBlock: "0px",
          padding: "3px 15px",
          background: "whitesmoke",
        }}
      >
        {number || v?.number_text}
      </h2>
      <div className="voucher-details">
        <div className="row">
          <div>Zamówił(a)</div>
          <div>{v?.request_user}</div>
        </div>
        <div className="row">
          <div>Ważny do</div>
          <div>{v?.valid_until}</div>
        </div>
      </div>{" "}
      <div className="voucher-details" style={{ background: "whitesmoke" }}>
        <div className="row">
          <div>1. Wydruk</div>
          <div>
            {prettyDate(v?.print_date)}
            <br />
            <span style={{ fontSize: "12px" }}>{v?.print_user}</span>
          </div>
        </div>
        <div className="row">
          <div>2. Odbiór przez recepcję</div>
          <div>{prettyDate(v?.received_date) || <button className="normal-button big">Oznacz jako odebrany</button>}</div>
        </div>

        <div className="row">
          <div>3. Sprzedaż/wydanie klientowi</div>
          <div>
            {v?.sold_date || (
              <button className="normal-button big">Oznacz jako sprzedany</button>
            )}
          </div>
        </div>
        <div className="row">
          <div>4. Skorzystanie przez klienta</div>
          <div>
            {v?.client_used_date || (
              <button className="normal-button big">
                Oznacz jako wykorzystany
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
