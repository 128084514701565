import { createContext, useEffect, useState } from "react";

export const ItemContext = createContext<any>({
  subRow: <></>,
  setSubRow: () => {},
});

export default function ViewItem({
  schema,
  row,
  children,
}: {
  schema: any;
  row: any;
  children: JSX.Element;
}) {
  const [subRow, setSubRow] = useState();

  return (
    <ItemContext.Provider
      value={{
        row: row,
        schema: schema,
        subRow: subRow,
        setSubRow: setSubRow,
      }}
    >
      {children}
    </ItemContext.Provider>
  );
}
