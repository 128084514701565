import moneyBagIcon from "../../images/icons/money-bag.png";
import prettyNumber from "../functions/prettyNumber";

export default function DepositIcon({
  view,
  settled,
}: {
  view: any;
  settled?: Boolean;
}) {
  const amount = prettyNumber(
    view?.values?.payments
      ?.map((i: any) => (i.gross ? Number(i.gross) : 0))
      .reduce((a: number, b: number) => a + b, 0),
    { round: true, postfix: "zł" }
  );
  return (
    <div
      className="deposit-icon"
      style={
        settled
          ? {
              textShadow: "1px 1px 1px black",
              background:"#008f32", // "linear-gradient(90deg, rgb(150,255,150),#008f32, #008f32)",
              color: "white",
            }
          : {}
      }
      title={
        "Zapłacone: " +
        amount
      }
    >
      <img src={moneyBagIcon} width={settled ? 20 : 17} />
      <div>
        {amount}
      </div>
    </div>
  );
}
