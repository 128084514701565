import prettyDate from "../../../functions/prettyDate";

export function row(row?: any): any[] {
  return [
    {
      name: "id",
      value: row?.id,
      className: "Id",
    },
    {
      name: "pos",
      value: row?.pos,
    },
    {
      name: "data_od",
      value: row?.data_od?.slice(0,16),
      style: { width: "120px"}
    },
    {
        name: "produkt",
        value: row?.produkt,
      },
    {
      name: "konto1",
      value: row?.konto1,
    },
    {
      name: "płatnik",
      value: row?.platnik,
    },

    {
      name: "wartość",
      value: row?.wartosc,
    },
    {
      name: "winien",
      value: row?.winien,
    },
  
  ];
}
