import { createRef, useContext, useEffect, useState } from "react";
import talkIcon from "../../images/icons/talk.svg";
import { UserContext } from "../contexts/UserContext";
import { ViewContext } from "../contexts/ViewContext";
import useGeneric from "../dataFetch/useGeneric";
import DateDisplay from "./viewElements/DateDisplay";
import { inflect } from "../functions/tools";
import Chat from "./Chat";
import messageIcon from "../../images/icons/message.svg";
import prettyDate from "../functions/prettyDate";

export default function ChatSum({
  query,
  moreButtons,
  activatedWindow,
}: {
  query: { table: string; table_id: number; details?: any };
  moreButtons?: JSX.Element;
  activatedWindow?: JSX.Element | false;
}) {
  const [commentUpdate, setCommentUpdate] = useState(0);

  const discussion = useGeneric({
    name: "discussion",
    limit: 500,
    query: {
      table: query.table,
      table_id: query.table_id,
    },
    update: commentUpdate,
  }) as {
    rows: {
      content: string;
      author: number;
      table: string;
      table_id: number;
    }[];
    count: number;
    prevCount?: number;
    loading?: boolean;
  };

  const user = useContext(UserContext);
  // const current = useContext(EntryContext);
  const view = useContext(ViewContext);

  useEffect(() => {
    const reload = setInterval(
      () => setCommentUpdate((prev: number) => prev + 1),
      6000
    );

    return () => clearInterval(reload);
  }, []);

  const rows = discussion?.rows?.slice(0);
  const messageNumber = rows?.length;
  const lastComment: any = rows?.at(0);
  const lastCommentTime = lastComment?.time;

  const abbrContent =
    lastComment?.content?.length > 70
      ? lastComment?.content?.slice(0, 70) + "..."
      : lastComment?.content;

  const [fullChat, setFullChat] = useState(false);

  return (
    <>
      <div className="chat-sum">
        {!discussion.loading ? (
          <>
            <div className="messages-summary">
              <div>
                <img width={14} src={messageIcon} /> {messageNumber}
              </div>
              {lastComment ? (
                <div>
                  ▸ {prettyDate(lastCommentTime, "dziś, ")} {abbrContent} (
                  {lastComment?.firstname} {lastComment?.lastname})
                </div>
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>Sprawdzanie wiadomości...</>
        )}
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "6px" }}
        >
          <button
            className="normal-button"
            onClick={() => setFullChat((prev: any) => !prev)}
          >
            <img width={16} src={talkIcon} />{" "}
            {fullChat ? <>Zwiń konwersację</> : <>Rozwiń konwersację</>}
          </button>
          {moreButtons ? moreButtons : <></>}
        </div>
      </div>
      <div style={{ position: "relative" }}>
        {fullChat ? (
          <Chat
            query={{
              table: query.table,
              table_id: query.table_id,
              details: query?.details,
            }}
          />
        ) : (
          <></>
        )}
        {activatedWindow ? activatedWindow : <></>}
      </div>
    </>
  );
}
