import React, { Fragment, useEffect, useState } from "react";
import insertGeneric from "../../dataFetch/insertGeneric";
import AddMissing from "../AddMissing";
import AttendanceEvents from "./AttendanceEvents";
import HolidayCell from "./HolidayCell";
import SumCell from "./SumCell";

export default function AttendanceRow({
  datum,
  items,
  index,
  arr,
  setUpdate,
  update,
  setSmallUpdate,
  setActive,
  holidays,
  holidaysSuggested,
  employeeData,
  location,
}: {
  datum: any;
  items: any;
  index: number;
  arr: any[];
  update: number;
  setUpdate: Function;
  setSmallUpdate: Function;
  setActive: Function;
  holidays: any[] | undefined;
  holidaysSuggested: any[] | undefined;
  employeeData: any | false;
  location: string;
}) {
  const [holidayOptions, setHolidayOptions] = useState(false);
  const dateIn = items;
  const prevIn = arr[index - 1] ? arr[index - 1].items : [];
  let nextIn = arr[index + 1] ? arr[index + 1].items : [];
  const nextIn2 = arr[index + 2] ? arr[index + 2].items : [];

  const datumCor = new Date(datum.obj);
  datumCor.setHours(10);
  const dateSQL = datumCor.toISOString().slice(0, 10);

  const isHoliday = holidays
    ? holidays.find((h: any) => h.date === dateSQL)
    : false;

  const isHolidaySuggested = holidaysSuggested
    ? holidaysSuggested.find((h: any) => h.date === dateSQL)
    : false;

  const currentHoliday = holidays
    ? holidays.filter((h: any) => h.date === dateSQL)[0]
    : false;

  const currentHolidaySuggested = holidaysSuggested
    ? holidaysSuggested.filter((h: any) => h.date === dateSQL)[0]
    : false;

  function setHolidayType(holidayType: number) {
    if (employeeData && employeeData.id) {
      insertGeneric("holidays", {
        date: dateSQL,
        employeeID: employeeData.id,
        type: holidayType,
        status: 1,
      });
    }
    setSmallUpdate((prev: number) => prev + 1);
    setHolidayOptions(false);
  }

  return (
    <>
      <tr
        key={datum.obj.getDate()}
        className={
          (!dateIn.length ? "empty " : "") +
          (datum.obj.getDate() === new Date().getDate() &&
          datum.obj.getMonth() === new Date().getMonth() &&
          datum.obj.getFullYear() === new Date().getFullYear()
            ? "today"
            : "") +
          (isHoliday ? " holiday" : "") +
          (datum.obj.getDay() === 0 ? " weekEnd" : "")
        }
      >
        <td
          className={
            (datum.obj.getDay() === 0 ? "sunday" : "") +
            (datum.holiday ? " stateHoliday" : "")
          }
        >
          <div className="date">
            <div className="dayOfWeek">
              {datum.obj.toLocaleDateString("pl-PL", {
                weekday: "short",
              })}
            </div>
            <div className="dayNumber">
              {datum.obj.toLocaleDateString("pl-PL", {
                day: "2-digit",
              })}
            </div>
          </div>
        </td>
        <>
          {dateIn.length ? (
            dateIn.map((pair: any) => {
              const enters = pair?.entries?.filter(
                (e: any) => e.type === 0 || e.type === 2 || e.type === 4
              );
              const exits = pair?.entries?.filter(
                (e: any) => e.type === 1 || e.type === 3 || e.type === 5
              );

              const isExitFromLast = prevIn[0]
                ? (prevIn[0].entries.at(-1).type === 0 ||
                    prevIn[0].entries.at(-1).type === 2 ||
                    prevIn[0].entries.at(-1).type === 4) &&
                  (pair.entries[0].type === 1 ||
                    pair.entries[0].type === 3 ||
                    pair.entries[0].type === 5)
                : false;

              const wasEnterToNext = prevIn[0]
                ? (prevIn[0].entries[0].type === 1 ||
                  prevIn[0].entries[0].type === 3 ||
                  prevIn[0].entries[0].type === 5) &&
                  (pair.entries.at(-1).type === 0 ||
                    pair.entries.at(-1).type === 2 ||
                    pair.entries.at(-1).type === 4)
                : false;

              const isEnterToNext = nextIn[0]
                ? (nextIn[0].entries[0].type === 1 ||
                    nextIn[0].entries[0].type === 3 ||
                    nextIn[0].entries[0].type === 5) &&
                  (pair.entries.at(-1).type === 0 ||
                    pair.entries.at(-1).type === 2 ||
                    pair.entries.at(-1).type === 4)
                : false;

              const nextlastIsEnter = nextIn[0]
                ? nextIn[0].entries.at(-1).type === 0 ||
                  nextIn[0].entries.at(-1).type === 2 ||
                  nextIn[0].entries.at(-1).type === 4
                : false;

              // console.log(nextIn && nextIn[0] ? nextIn[0].first : isEnterToNext);
              const entries = pair?.entries?.filter((e: any) => !e);

              /* const pairs = pair.entries?.length < 3 ? [pair.entries] : [pair.entries.slice(0,1), pair.entries.slice(2,3)];
              console.log(pairs);*/

              return (
                <React.Fragment key={pair?.detailed?.last_timestamp}>
                  <AttendanceEvents
                    which="in"
                    pair={pair}
                    datum={datum}
                    records={enters}
                    setUpdate={setUpdate}
                    setActive={setActive}
                    isExitFromLast={isExitFromLast}
                    isEnterToNext={isEnterToNext}
                    location={location}
                  />

                  <AttendanceEvents
                    which="out"
                    pair={pair}
                    datum={datum}
                    records={exits}
                    setUpdate={setUpdate}
                    setActive={setActive}
                    isExitFromLast={isExitFromLast}
                    isEnterToNext={isEnterToNext}
                    location={location}
                  />
                  {isExitFromLast ? (
                    <></>
                  ) : (
                    <td
                      rowSpan={isEnterToNext ? (nextlastIsEnter ? 3 : 2) : 1}
                      style={
                        isEnterToNext && !isExitFromLast
                          ? { borderLeft: ".5px solid lightgrey" }
                          : {}
                      }
                    >
                      <SumCell
                        data={{
                          pair,
                          index,
                          isExitFromLast,
                          nextIn,
                          nextIn2,
                          isEnterToNext,
                          wasEnterToNext
                        }}
                        holidayCell={
                          <HolidayCell
                            isHoliday={isHoliday}
                            isHolidaySuggested={isHolidaySuggested}
                            currentHoliday={currentHoliday}
                            currentHolidaySuggested={currentHolidaySuggested}
                            employeeData={employeeData}
                            setHolidayOptions={setHolidayOptions}
                            setSmallUpdate={setSmallUpdate}
                            dateSQL={dateSQL}
                          />
                        }
                      />
                    </td>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <>
              <td className="empty">
                <AddMissing
                  datum={datum}
                  event={items}
                  defaultEvent={0}
                  setUpdate={setUpdate}
                  location={location}
                />
              </td>
              <td className="empty">
                <AddMissing
                  datum={datum}
                  event={items}
                  defaultEvent={1}
                  setUpdate={setUpdate}
                  location={location}
                />
              </td>
              <td
                style={{ display: "block", position: "relative" }}
                className={
                  isHoliday || isHolidaySuggested ? "holiday" : "empty"
                }
              >
                <HolidayCell
                  isHoliday={isHoliday}
                  isHolidaySuggested={isHolidaySuggested}
                  currentHoliday={currentHoliday}
                  currentHolidaySuggested={currentHolidaySuggested}
                  employeeData={employeeData}
                  setHolidayOptions={setHolidayOptions}
                  setSmallUpdate={setSmallUpdate}
                  dateSQL={dateSQL}
                />
              </td>
            </>
          )}
        </>
      </tr>
      {holidayOptions ? (
        <div className="holidayOptions">
          <ul>
            <li onClick={() => setHolidayType(1)}>Urlop wypoczynkowy</li>
            <li onClick={() => setHolidayType(7)}>Chorobowe</li>
            <li onClick={() => setHolidayType(3)}>Urlop okolicznościowy</li>
            <li onClick={() => setHolidayType(6)}>Dzień wolny za święto</li>
            <li onClick={() => setHolidayType(5)}>Opieka nad dzieckiem</li>
            <li onClick={() => setHolidayType(4)}>Urlop bezpłatny</li>
            <li onClick={() => setHolidayType(9)}>Dzień wolny z nadgodzin</li>
            <li onClick={() => setHolidayType(10)}>Urlop - inny</li>
          </ul>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
