import { useContext, useEffect, useState } from "react";
import VoucherPDF from "./vouchers/VoucherPDF";
import VoucherGroup from "./vouchers/VoucherGroup";
import VoucherRegister from "./vouchers/VoucherRegister";
import VoucherList from "./vouchers/VoucherList";
import useGeneric from "./dataFetch/useGeneric";
import { UserContext } from "./contexts/UserContext";
import insertGeneric from "./dataFetch/insertGeneric";
import listIcon from "../images/icons/list.svg";
import addIcon from "../images/icons/add.svg";
import scanIcon from "../images/icons/scanner.svg";
import eyeIcon from "../images/icons/eye.svg";
import { objectOrJSONparse } from "./functions/tools";
import Info from "./common/Info";
import QRRead from "./vouchers/QRRead";
import VoucherAddGroups from "./vouchers/VoucherAddGroups";
import VoucherAddFinalize from "./vouchers/VoucherAddFinalize";

export default function Vouchers() {
  //   const [items, setItems] = useState([
  //     { amount: 100, number: 477 },
  //     { amount: 100, number: 478 },
  //   ]);
  /* TRANSAKCJE  PRODUKTU
  select * from dbo.fv_DajTransakcjeProduktu(
  '001-0000000000004716', 3, '0', '2024-10-01', '2024-11-19', 4) 
  */

  const { userID, auth } = useContext(UserContext);

  const [update, setUpdate] = useState(0);
  const [showPDF, setShowPDF] = useState(false);

  const { loading: loadingMemory, rows: memoryData } = useGeneric({
    name: "voucher-register-memory",
    query: {
      print_user_id: userID,
    },
    // update: update,
    order: {
      by: ["print_order", "number"],
      dir: ["ASC", "DESC"],
    },
  });

  const memory = !loadingMemory && memoryData;
  //   console.log(memory);

  const [tab, setTab] = useState(0);
  const [finalize, setFinalize] = useState(false);
  const [groups, setGroups] = useState<any>([]);
  const [filter, setFilter] = useState({});

  const { loading: loadingRegister, rows: registerData } = useGeneric({
    name: "voucher-register",
    update: JSON.stringify(groups) + update,
    reload: true,
    secondsToReload: 45,
    query: filter,
  });
  const voucherRegister =
    !loadingRegister && registerData?.filter((r: any) => r?.status != 99);

  const items: any = [];

  useEffect(() => {
    if (!loadingMemory) {
      const retrievedGroups: any = {};
      let copies: any = {};
      memoryData.forEach((i: any) => {
        if (i?.group_id) {
          copies[i?.group_id] = (copies[i?.group_id] || 0) + 1;
          retrievedGroups[i.group_id] = {
            ...i,
            first: i?.number,
            copies: copies?.[i?.group_id],
          };
        }
      });

      setGroups((prev: any) =>
        Object.keys(retrievedGroups)?.map((k: any) => retrievedGroups[k])
      );
      console.log(retrievedGroups);
    }
  }, [loadingMemory]);

  groups?.forEach((g: any) => {
    if (g) {
      const {
        first,
        copies,
        amount,
        paid,
        valid_until,
        type,
        for_name,
        request_user,
        request_user_id,
        ordering_client,
        group_id,
        comment,
        status,
      } = g;
      for (let i = 0; i < copies; i++) {
        items.push({
          number: first + i,
          amount: amount,
          paid: paid,
          valid_until: valid_until,
          type: type,
          for_name: for_name,
          request_user: request_user,
          request_user_id: request_user_id,
          ordering_client: ordering_client,
          group_id: group_id,
          comment: comment,
          status: status,
        });
      }
    }
  });
  useEffect(() => {
    // console.log(groups);
    const runDB = setTimeout(() => {
      //   console.log(items);
      let order = 0;
      insertGeneric(
        "set-voucher-memory",
        items && items.length
          ? items?.map((i: any, index: number, arr: any) => {
              if (
                arr.at(index - 1) &&
                arr.at(index - 1)?.group_id !== i?.group_id
              ) {
                order = order + 1;
              }
              return {
                ...i,
                group_id: i?.group_id,
                print_user_id: userID,
                print_date:
                  new Date().toISOString().slice(0, 10) +
                  " " +
                  new Date().toTimeString(),

                order: order,
              };
            })
          : { print_user_id: userID }
      );
    }, 800);
    return () => clearTimeout(runDB);
  }, [JSON.stringify(groups), JSON.stringify(items)]);

  const voucherAuth = objectOrJSONparse(auth?.modules)?.vouchers;
  //   console.log(voucherAuth);
  return !voucherAuth ? (
    <Info type="unauthorised" />
  ) : (
    <div className="voucher-editor">
      <div className="voucher-nav-wrap">
        <nav className="voucher-nav">
          <div className={tab === 0 ? "active" : ""} onClick={() => setTab(0)}>
            <img src={listIcon} /> <div>Rejestr voucherów</div>
          </div>

          {voucherAuth === 9 ? (
            <div
              className={tab === 1 ? "active" : ""}
              onClick={() => setTab(1)}
            >
              <img src={addIcon} /> <div>Dodawanie</div>
            </div>
          ) : (
            <></>
          )}

          <div className={tab === 2 ? "active" : ""} onClick={() => setTab(2)}>
            <img src={scanIcon} /> <div>QR</div>
          </div>
        </nav>
        <nav className="voucher-nav">
          {tab === 1 ? (
            <div
              style={{ justifySelf: "right" }}
              onClick={() => setShowPDF((prev: boolean) => !prev)}
            >
              <img src={eyeIcon} /> <div></div>
            </div>
          ) : (
            <></>
          )}
        </nav>
      </div>
      {tab === 0 && voucherAuth ? (
        <VoucherRegister
          items={items}
          auth={voucherAuth}
          setUpdate={setUpdate}
          register={voucherRegister || []}
          filter={filter}
          setFilter={setFilter}
        />
      ) : finalize ? (
        <VoucherAddFinalize
          setFinalize={setFinalize}
          setUpdate={setUpdate}
          auth={auth}
          items={items}
          userID={userID}
          groups={groups}
          setGroups={setGroups}
        />
      ) : tab === 1 && voucherAuth === 9 ? (
        <VoucherAddGroups
          setGroups={setGroups}
          items={items}
          setFinalize={setFinalize}
          groups={groups}
          showPDF={showPDF}
          setShowPDF={setShowPDF}
        />
      ) : tab === 2 ? (
        <QRRead />
      ) : (
        <></>
      )}{" "}
    </div>
  );
}
