import insertGeneric from "../../../dataFetch/insertGeneric";

export async function resolveEntry({
  value,
  row,
  f,
}: {
  value: Boolean;
  row: any;
  f: any;
}) {
  await insertGeneric("set-error-resolve", {
    resolved: value ? 1 : 0,
    id: row?.id,
    data: row,
  });
  f.setUpdates((prev: number) => prev + 1);
  f.setStatusEdit(false);
}

export async function editUrgency({
  value,
  row,
  f,
}: {
  value: Boolean;
  row: any;
  f: any;
}) {
  await insertGeneric("set-error-urgent", {
    urgent: value ? 1 : 0,
    id: row?.id,
  });
  f.setUpdates((prev: number) => prev + 1);
  f.setStatusEdit(false);
}
