import { Link, useParams } from "react-router-dom";
import backIcon from "../../../../images/icons/back.svg";
import urgentIcon from "../../../../images/icons/urgent.svg";
import editIcon from "../../../../images/icons/pencil.png";
import modifyIcon from "../../../../images/icons/modify.svg";
import useGeneric from "../../../dataFetch/useGeneric";
import Loading from "../../../common/Loading";
import Options from "../../Options";
import { schema as errorsSchema } from "./schema";
import { useContext, useEffect, useState } from "react";
import ChatSum from "../../../tables/ChatSum";
import BooleanSymbol from "../../../tables/viewElements/BooleanSymbol";
import { ViewContext } from "../../../contexts/ViewContext";
import LSIinvoiceView from "../../../experiments/LSIinvoiceView";
import ReceiptDetails from "../../../calendar/ReceiptDetails";
import RowMore from "./RowMore";
import { RegisterViewContext } from "../../View";
import { ItemContext } from "../../ViewItem";
import ErrorOptionButtons from "./ErrorOptionButtons";
import { editUrgency, resolveEntry } from "./functions";
import { UserContext } from "../../../contexts/UserContext";
import { objectOrJSONparse } from "../../../functions/tools";
import Sheet, { Cluster, Unit } from "./Sheet";
import useFiles from "../../../forms_new/Elements/useFiles";
import { URL, URLProd } from "../../../paths";
import FilesView from "../../../FilesView";
import CorrectDocumentOptions from "./CorrectDocumentOptions";
import DateDisplay from "../../../tables/viewElements/DateDisplay";

export default function ViewError({ context }: { context?: any }) {
  const { id } = useParams();

  const [localUpdates, setLocalUpdates] = useState(0);
  const [urgent, setUrgent] = useState();

  const { auth: authAll } = useContext(UserContext);
  const auth = objectOrJSONparse(authAll?.["registers"]);

  const f = useGeneric({
    name: "errorTable",
    query: {
      id: id,
    },
    update: localUpdates,
    limit: 1,
  });
  const item = !f?.loading && f?.rows?.[0];

  const files = useFiles(
    {
      module: "errors",
      module_id: Number(id),
      // category: "original-document"
    },
    localUpdates
  );

  useEffect(() => {
    setUrgent(item?.urgent);
  }, [item?.urgent]);

  const schema = errorsSchema;
  const [editData, setEditData] = useState();
  const nd = <span style={{ color: "grey" }}>bd.</span>;

  const { modal, setModal } = useContext(ViewContext);
  const { setUpdates } = useContext(RegisterViewContext);
  const [editor, setEditor] = useState(false);

  const [subRow, setSubRow] = useState();

  const number =
    item?.entry_number + "/" + item?.entry_month + "/" + item?.entry_year;
  return editor ? (
    <div className="editor-wrap">
      {
        schema(
          item,
          { showFunction: setEditor, setUpdates: setLocalUpdates },
          auth
        )?.form
      }
    </div>
  ) : item ? (
    <div className="clean-view standardWrap">
      <div className="summary">
        <div>
          WPIS {number} ▸{" "}
          <span style={{ color: "brown" }}>{item?.problem_type_string}</span> ▸{" "}
          {/* <span style={{ color: "green" }}>{item?.lsi_document_number}</span> ▸{" "} */}
          <span style={{ fontSize: "80%" }}>
            {item?.purchaser_number_on_document?.toUpperCase()}
          </span>
        </div>
        <div style={{ display: "flex" }}>
          {auth?.errors > 2 ? (
            <>
              <div
                className={"normal-button" + (urgent ? " urgent" : "")}
                style={{ width: "90px", opacity: item?.resolved ? ".3" : "1" }}
                onClick={() => {
                  if (item?.resolved) return;
                  editUrgency({
                    value: !item?.urgent,
                    row: item,
                    f: {
                      setUpdates: setLocalUpdates,
                      setStatusEdit: () => {},
                    },
                  });
                }}
              >
                <img src={urgentIcon} />
                {urgent ? <>PILNE </> : <>NIEPILNE </>}{" "}
              </div>
              <div
                className={"normal-button"}
                style={{ width: "90px", opacity: item?.resolved ? ".3" : "1" }}
                onClick={() => {
                  if (item?.resolved) return;
                  setEditor(true);
                }}
              >
                <img src={editIcon} />
                EDYTUJ
              </div>
              <div
                className={
                  "normal-button" + (item?.resolved ? " resolved" : "")
                }
                style={{ width: "140px" }}
                onClick={() => {
                  resolveEntry({
                    value: !item?.resolved,
                    row: item,
                    f: {
                      setUpdates: setLocalUpdates,
                      setStatusEdit: () => {},
                    },
                  });
                }}
              >
                <img src={modifyIcon} />
                <div>{!item?.resolved ? "ROZWIĄŻ" : "OTWÓRZ PONOWNIE"}</div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <Link to="../errors">
          <img src={backIcon} />
          Powrót do listy
        </Link>
      </div>

      {item?.resolved ? (
        <div className="sheet-single">
          <h2>
            <BooleanSymbol value={true} />
            To zgłoszenie zostało rozwiązane.
          </h2>
        </div>
      ) : (
        <></>
      )}
      <div className="content">
        <Sheet h="Podstawowe dane" side={true}>
          <div className="cluster-vertical">
            <Unit h="Nr wpisu">{number}</Unit>
            <Unit h="Data utworzenia">
              {new Date(item.entry_datetime).toLocaleDateString()}
            </Unit>
            <Unit h="Rodzaj problemu">{item.problem_type_string}</Unit>
            <Unit h="Forma zgłoszenia">{item.reporting_form_string}</Unit>
            <Unit h="Data zgłoszenia">{item.date_of_discovery || nd}</Unit>
            <Unit h={<>Pracownik związany z&nbsp;problemem</>}>
              {item.employee_first_name || item.employee_last_name ? (
                <>
                  {item.employee_first_name} {item.employee_last_name}
                </>
              ) : (
                nd
              )}
            </Unit>
            <Unit h="Utworzył">{item.created_by_string}</Unit>
            <Unit h="Modyfikował">{item.modified_by_string}</Unit>
          </div>
        </Sheet>
        <div className="sheets">
          <Sheet h="Dokument powiązany">
            <Cluster>
              <Unit h="Dotyczy dokumentu">{item.lsi_document_number}</Unit>
              <Unit h="Rodzaj">{item.sales_document_type_string}</Unit>
              <Unit h="Data sprzedaży z dokumentu">
                <DateDisplay
                  dateString={item?.date_of_sale_from_sales_document}
                />
              </Unit>
              <Unit h="Klient">{item.purchaser_number_on_document}</Unit>
              <Unit h="Gość i nr rezerwacji">
                <div style={{ lineHeight: "120%" }}>
                  <div style={{ display: "inline-block" }}>{item.guest}</div>
                  <div
                    style={{
                      display: "block",
                      //   marginLeft: "7px",
                      fontSize: "80%",
                      color: "brown",
                    }}
                  >
                    {item.booking_number}
                  </div>
                </div>
              </Unit>
              <Unit h="Podgląd dokumentu">
                <button
                  title={item.lsi_document_id}
                  className="normal-button"
                  style={{ padding: "10px 20px" }}
                  onClick={() =>
                    setModal({
                      show: true,
                      style: {
                        width: "900px",
                      },
                      heading: (
                        <>Szczegóły: {item?.purchaser_number_on_document}</>
                      ),
                      content:
                        item?.sales_document_code === 1 ? (
                          <LSIinvoiceView id={item?.lsi_document_id} />
                        ) : item?.sales_document_code === 2 ? (
                          <ReceiptDetails id={item?.lsi_document_id} />
                        ) : (
                          <></>
                        ),
                    })
                  }
                >
                  OTWÓRZ
                </button>
              </Unit>
              <Unit h="Pliki" className="files-view">
                <FilesView
                  files={files?.filter(
                    (f: any) => f?.category === "original-document"
                  )}
                />
              </Unit>
            </Cluster>
          </Sheet>

          <Sheet
            h="Dokument korekty"
            className={item?.cor_doc_id ? "correction" : ""}
          >
            <Cluster>
              {item?.cor_doc_id ? (
                <>
                  <Unit h="Numer dokumentu korekty">
                    {item?.cor_doc_number}
                  </Unit>
                  <Unit h="Rodzaj">
                    {item?.cor_doc_type == 1 ? "faktura" : "paragon"}
                  </Unit>
                  <Unit h="Data dokumentu">
                    {new Date(item?.cor_doc_issue_date).toLocaleDateString()}
                  </Unit>

                  <Unit h="Nazwa klienta na dokumencie">
                    {item?.cor_doc_client}
                  </Unit>
                  <Unit h="Kwota dokumentu">{item?.cor_doc_gross}</Unit>
                  <Unit h="Podgląd dokumentu">
                    <button
                      title={item.lsi_document_id}
                      className="normal-button"
                      style={{ padding: "10px 20px" }}
                      onClick={() =>
                        setModal({
                          show: true,
                          style: {
                            width: "900px",
                          },
                          heading: (
                            <>Szczegóły: {item?.purchaser_number_on_document}</>
                          ),
                          content:
                            item?.cor_doc_type === 1 ? (
                              <LSIinvoiceView id={item?.cor_doc_id} />
                            ) : item?.cor_doc_type === 2 ? (
                              <ReceiptDetails id={item?.cor_doc_id} />
                            ) : (
                              <></>
                            ),
                        })
                      }
                    >
                      OTWÓRZ
                    </button>
                  </Unit>
                  <Unit h="Pliki" className="files-view">
                    <FilesView
                      files={files?.filter(
                        (f: any) => f?.category === "correction-documents"
                      )}
                    />
                  </Unit>
                </>
              ) : (
                <></>
              )}
              <Unit h={item?.cor_doc_id ? "Edytuj dokument korekty" : ""}>
                <CorrectDocumentOptions
                  row={item}
                  f={{
                    setModal,
                    setUpdates: setLocalUpdates,
                    showFunction: setModal,
                  }}
                />
              </Unit>
            </Cluster>
          </Sheet>

          <div className="sheet">
            <h2>Dyskusja</h2>
            <ChatSum
              query={{
                table: "error_register",
                table_id: item.id,
                details: item,
              }}
            />
          </div>
          <Sheet h="Opis i propozycje">
            <Cluster>
              <Unit h="Przyczyna korekty / dokładny opis sytuacji">
                {item.correction_reason}
              </Unit>
              <Unit h="Zmiana kwoty">
                <>
                  <span
                    style={{
                      color: "brown",
                    }}
                  >
                    {item.previous_value}
                  </span>{" "}
                  {" > "}{" "}
                  <span style={{ fontSize: "125%", color: "green" }}>
                    {item.should_be}
                  </span>
                </>
              </Unit>
              <Unit h="Wstępna propozycja rozwiązania">
                {item.approved_solution}
              </Unit>
            </Cluster>
          </Sheet>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
}

// active
// approved_solution
// booking_number
// correction_document_issue_date
// correction_document_number
// correction_reason
// correction_signed_or_arrangements_made
// correction_signed_string
// created_by
// date_of_discovery
// date_of_sale_from_sales_document
// date_reported
// employee_first_name
// employee_initials
// employee_involved_id
// employee_last_name
// entry_approved_datetime
// entry_datetime
// entry_month
// entry_number
// entry_year
// filenames
// gross_amount_original_document
// gross_value_as_corrected
// guest
// history
// id
// is_original_receipt_present
// lsi_document_number
// modified_by
// previous_value
// problem_type
// problem_type_string
// purchaser_number_on_document
// reporting_form
// reporting_form_string
// resolved
// sales_document_type
// sales_document_type_string
// should_be
// urgent
// what_is_corrected
// what_is_corrected_string
