import prettyDate from "../../../functions/prettyDate";
import prettyNumber from "../../../functions/prettyNumber";

export function row(row?: any): any[] {
  // indeks/przychod/rozchod/Data
  return [
    {
      name: "Gość",
      value: row?.gosc,
      style:{width:"165px"}
    },
    {
      name: "Pokój",
      value: row?.pokoj,
    },
    {
      name: "Płatnik",
      value: row?.GoscGl,
      style:{width:"165px"}
    },
    {
      name: "Nr rezerwacji",
      value: row?.numer_rezerwacji,
    },
    {
      name: "Przyjazd",
      value: prettyDate(row?.przyjazd, "dziś", true),
    },
    {
      name: "Wyjazd",
      value: prettyDate(row?.wyjazd, "dziś", true),
    },
    {
      name: "Uwaga",
      value: row?.uwaga,
      style: {width:"20%", fontSize:"10px"}
    },
  ];
}
