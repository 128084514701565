interface totals {
  holidays: string;
  work: string;
  customWork: string;
  remoteWork: string;
  all: string;
  sickDays: number;
}

export default function AttendanceSummary({ totals }: { totals: totals }) {
  return totals.all ? (
    <div className="attendanceSummary">
      <div className="total">
        <div className="name">SUMA</div>
        <div className="value">{totals.all}</div>
      </div>

      <div>
        <div className="name">PRACA</div>
        <div className="value">
          {totals.work}{" "}
          {totals.customWork ? (
            <span style={{ position: "relative", fontSize:"90%", color: "grey" }}>
              ({totals.customWork}){" "}
              <span
                title="(ścinane do ustalonej liczby godzin)"
                style={{
                  position: "absolute",
                  top: "74%",
                  left: "50%",
                  borderRadius: "8px",
                  padding: "0 8px",
                  background: "white",

                  fontSize: "7px",
                  width: "max-content",
                }}
              >
                wg grafiku
              </span>
            </span>
          ) : (
            <></>
          )}{" "}
        </div>
      </div>
      {totals.remoteWork ? (
        <div>
          <div className="name">w tym ZDALNIE</div>
          <div className="value">{totals.remoteWork}</div>
        </div>
      ) : (
        <></>
      )}
      {totals.holidays ? (
        <div>
          <div className="name">URLOP</div>
          <div className="value">{totals.holidays}</div>
        </div>
      ) : (
        <></>
      )}
      {totals.sickDays ? (
        <div>
          <div className="name">ZWOLNIENIE LEKARSKIE (dni)</div>
          <div className="value">{totals.sickDays}</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
}
