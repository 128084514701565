import { hybridDocumentField } from "../calendar/addEventFormUpdated";
import insertGeneric from "../dataFetch/insertGeneric";
import Form from "../forms_new/Form";

export default function VoucherEdit({
  data,
  setUpdate,
  setBag,
  setModal,
}: {
  data?: any;
  setUpdate: Function;
  setBag: Function;
  setModal: Function;
}) {
  let multiData = false;
  if (Array.isArray(data) && data?.length) {
    multiData = true;
  }
  return (
    <div
      style={{ width: "100%", display: "grid", gridTemplateColumns: "1fr 6fr" }}
    >
      <div className="voucher-editor-listing">
        {multiData ? (
          data?.map((d: any) => <div>{d?.number_text}</div>)
        ) : (
          <div className="voucher-editor-listing">{data?.number_text}</div>
        )}
      </div>
      <Form
        name="voucher"
        submit={async (d: any) => {
          //   window.alert(JSON.stringify(d))
          return await insertGeneric("update-voucher-register", d);
        }}
        sideEffects={() => {
          setUpdate((prev: number) => prev + 1);
          setBag((prev: any) => {
            const n = prev?.slice();
            return n?.map((g: any) => false);
          });
          setModal((prev: any) => ({ ...prev, show: false }));
        }}
        schema={{
          main: {
            name: "Vouchery",
            content: [
              [
                {
                  name: "id",

                  style: { display: "none" },
                },
                {
                  name: "number_text",
                  initialValue: multiData
                    ? data?.map((d: any) => d?.number_text).join(",")
                    : data["number_text"],
                  label: "Numer",
                  disabled: true,
                  outerStyle: { display: "none" },
                },
                {
                  name: "sell_document_type",
                  label: "Dokument sprzedaży",
                  element: "select-picker",
                  optionsList: [
                    {
                      name: "brak",
                      id: 0,
                    },
                    {
                      name: "faktura",
                      id: 1,
                    },
                    { name: "paragon", id: 2 },
                  ],
                },
                {
                  name: "sell_document_number",
                  outerStyle: { display: "none" },
                },
                {
                  ...hybridDocumentField({ documentType: 1 })?.schema,
                  name: "sell_document_search",
                  visibleIf: { sell_document_type: 1 },
                  resultUpdate: (item: any) => [
                    ["doc_data", { ...item }],
                    [
                      "sell_document_number",
                      `FV/${item?.DocNo}/${item?.month}/${item?.year}/H`,
                    ],
                    ["sell_document_lsi_id", item?.SCID],
                  ],
                  resultUpdateSub: false,
                },
                {
                  ...hybridDocumentField({ documentType: 2 })?.schema,
                  name: "sell_document_search",
                  visibleIf: { sell_document_type: 2 },
                  resultUpdate: (item: any) => [
                    ["doc_data", { ...item }],
                    ["sell_document_number", `PAR/${item?.DocNo}`],
                    ["sell_document_lsi_id", item?.SCID],
                  ],
                },
                {
                  name: "sell_document_lsi_id",
                  outerStyle: { display: "none" },
                },
                {
                  name: "descr",
                  element: false,
                  description: "",
                  from: {
                    values: ["doc_data"],
                    transform: (values: any) => {
                      const item = values?.doc_data;
                      return item && values?.sell_document_type == 1 ? (
                        <div>
                          <div style={{ fontWeight: "500" }}>
                            FV/{item?.DocNo}/{item?.month}/{item?.year}
                            /H
                          </div>
                          <div style={{ textAlign: "left" }}>
                            {item?.ko_firma}
                          </div>
                          <div style={{ textAlign: "right" }}>
                            {Math.round(item?.ValueBWal * 100) / 100} zł
                          </div>
                        </div>
                      ) : item && values?.sell_document_type == 2 ? (
                        <div>PAR/{item?.DocNo}</div>
                      ) : (
                        <></>
                      );
                    },
                  },
                },
                {
                  name: "ordering_client",
                  element: "hybrid",
                  db: "lsi_clients",
                  label: "Klient",
                  query: (input: any) => ({
                    active: true,
                    multiQuery: [input, "fuzzy"],
                  }),
                  resultElement: (item: any) => (
                    <div>
                      {item?.FirstName} {item?.Name}{" "}
                      <span style={{ color: "grey" }}>{item?.AddrCity}</span>
                    </div>
                  ),
                  resultUpdate: (item: any) => [
                    ["ordering_client", item?.FirstName + " " + item?.Name],
                    ["ordering_client_id", item?.usID],
                  ],
                  inputType: "search",
                },
                {
                  name: "ordering_client_id",
                  style: { display: "none" },
                },
              ],
            ],
          },
        }}
        data={
          multiData ? { ...data?.[0], id: data?.map((d: any) => d?.id) } : data
        }
      />
    </div>
  );
}
