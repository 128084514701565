import { useEffect, useState } from "react";
import { Link, Routes, Route } from "react-router-dom";
import Loading from "../common/Loading";
import { EntryContext } from "../contexts/EntryContext";
import insertGeneric from "../dataFetch/insertGeneric";
import useGeneric from "../dataFetch/useGeneric";
import Search from "../forms/Search";
import { errorSearchSchema } from "../forms/searchSchemas/errorSearchSchema";
import Modal from "../Modal";
import LinkButton from "./buttons/LinkButton";
import StateButton from "./buttons/StateButton";
import EditErrorEntry from "./EditErrorEntry";
import ErrorHeader from "./ErrorHeader";
import ErrorItems from "./ErrorItems";
import Table from "./Table";
import employeeGroupsView from "./views/employeeGroupsView";
import employeesView from "./views/employeesView";
import errorRegisterView from "./views/errorRegisterView";
import eventsView from "./views/eventsView";
import paymentFormChangeView from "./views/paymentFormChangeView";
import transferReceiptsView from "./views/transferReceiptsView";
import { viewType } from "./views/types/viewTypes";

export default function ErrorRegister() {
  const [update, setUpdate] = useState(0);
  const [offset, setOffset] = useState(0);
  const [filter, setFilter] = useState(false);
  const [active, setActive] = useState(true);
  const [search, setSearch] = useState({}) as [search: any, setSearch: any];

  const fuzzy: any = {};
  for (const v in search) {
    if (
      v === "employee_involved_id" ||
      v === "what_is_corrected" ||
      v === "problemy_type"
    ) {
      fuzzy[v] = search[v];
    } else {
      fuzzy[v] = [search[v], "fuzzy"];
    }
  }

  const data = useGeneric({
    name: "errorTable",
    limit: 3,
    offset: offset,
    query: {
      active: active,
      ...fuzzy,
    },
    reload: true,
    update: update,
    /*query: {
      employee_involved: 2
    },*/
  });

  const options = useGeneric({
    name: [
      "users",
      "employeesGeneric",
      "problemTypes",
      "whatIsCorrected",
      "reportingFormTypes",
      "salesDocumentTypes",
    ],
    limit: 100,
    active: true,
    //condition: filter,
  });

  return (
    <>
      <ErrorHeader />
      {data.loading ? (
        <Loading />
      ) : (
        <EntryContext.Provider value={{ update: update, setUpdate: setUpdate }}>
          <div className="errorRegister">
            <div className="errorPanel">
              <Routes>
                <Route
                  path=""
                  element={
                    <>
                      <div className="table-options-head">
                        <LinkButton
                          to="new-entry"
                          text="Nowy"
                          icon={<i className="gg-file-add" />}
                        />
                        <StateButton
                          text="Bieżące"
                          icon={<i className="gg-list" />}
                          stateSetter={() => {
                            setActive(true);
                            setUpdate((prev: number) => prev + 1);
                            setOffset(0);
                          }}
                          activeState={true}
                          refState={!active}
                        />
                        <StateButton
                          text="Archiwalne"
                          icon={<i className="gg-box" />}
                          stateSetter={() => {
                            setActive(false);
                            setUpdate((prev: number) => prev + 1);
                            setOffset(0);
                          }}
                          activeState={false}
                          refState={!active}
                        />
                        <StateButton
                          text="Filtruj"
                          icon={<i className="gg-search" />}
                          stateSetter={() =>
                            setFilter((prev: boolean) => !prev)
                          }
                          activeState={filter}
                        />

                        {!options.loading && filter ? (
                          <>
                            {!Object.entries(search).length ? (
                              <Search
                                valueData={errorSearchSchema(options.multiple)}
                                setter={(values: any) => {
                                  setSearch(values);
                                  setUpdate((prev: number) => prev + 1);
                                  setOffset(0);
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {Object.entries(search)
                                  .filter(([key, val]) => val)
                                  .map(([key, val]) => (
                                    <div style={{ padding: "5px 12px" }}>
                                      <>
                                        <div
                                          style={{
                                            fontSize: "9px",
                                            background: "whitesmoke",
                                            borderRadius: "5px",
                                            padding: "3px",
                                          }}
                                        >
                                          {key}
                                        </div>
                                        <div style={{ fontSize: "12px" }}>
                                          {String(val)}
                                        </div>
                                      </>
                                    </div>
                                  ))}
                                <button
                                  className="button"
                                  onClick={() => setSearch({})}
                                >
                                  Powrót
                                </button>
                              </div>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        style={{
                          padding: "3px",
                          fontSize: "9px",
                          display: "flex",
                        }}
                      ></div>
                      <Table
                        rows={data.rows}
                        metaData={data}
                        offset={offset}
                        setOffset={setOffset}
                        references={{
                          users: !options.loading
                            ? options.multiple.users
                            : undefined,
                        }}
                        view={errorRegisterView}
                        editor="link"
                      />
                    </>
                  }
                />
                <Route
                  path="payment-form-change"
                  element={
                    <ErrorItems
                      heading="Zmiana formy płatności"
                      name="paymentFormChange"
                      customView={paymentFormChangeView}
                      formData={{
                        formContent: paymentFormChangeView({})
                         .columns/*.filter(
                            (item: viewType) => item.formHide === undefined
                          )*/
                          .map((item: viewType) => ({
                            name: item.ref,
                            element: item.formElement
                              ? item.formElement
                              : "input",
                            label: item.name,
                            inputType: item.formHide === true ? "hidden" : undefined,
                            initialValue: "",
                            getOptionsFrom: item.getOptionsFrom,
                          })),
                        onSubmit: (values: any) => {
                          insertGeneric("payment-form-change", values);
                        },
                      }}
                    />
                  }
                />
                <Route
                  path="problems"
                  element={
                    <ErrorItems
                      heading="Rodzaje problemów"
                      name="problemTypes"
                      formData={{
                        formContent: [
                          {
                            name: "name",
                            initialValue: "",
                            label: "Pozycja",
                          },
                        ],
                        onSubmit: (values: any) => {
                          insertGeneric("problem-types", values);
                        },
                      }}
                    />
                  }
                />
                <Route
                  path="sales-documents"
                  element={
                    <ErrorItems
                      heading="Rodzaje dokumentów sprzedaży"
                      name="salesDocumentTypes"
                      formData={{
                        formContent: [
                          {
                            name: "name",
                            initialValue: "",
                            label: "Nazwa",
                          },
                        ],
                        onSubmit: (values: any) => {
                          insertGeneric("sales-documents", values);
                        },
                      }}
                    />
                  }
                />
                <Route
                  path="reporting-forms"
                  element={
                    <ErrorItems
                      heading="Formy zgłoszenia"
                      name="reportingFormTypes"
                      formData={{
                        formContent: [
                          {
                            name: "name",
                            initialValue: "",
                            label: "Nazwa",
                          },
                        ],
                        onSubmit: (values: any) => {
                          insertGeneric("reporting-forms", values);
                        },
                      }}
                    />
                  }
                />
                <Route
                  path="what-is-corrected"
                  element={
                    <ErrorItems
                      heading="Co poprawiono"
                      name="whatIsCorrected"
                      formData={{
                        formContent: [
                          {
                            name: "name",
                            initialValue: "",
                            label: "Nazwa",
                          },
                        ],
                        onSubmit: (values: any) => {
                          insertGeneric("what-is-corrected", values);
                        },
                      }}
                    />
                  }
                />
                <Route
                  path="correction-signed"
                  element={
                    <ErrorItems
                      heading="Opcje korekty"
                      name="correctionSigned"
                      formData={{
                        formContent: [
                          {
                            name: "name",
                            initialValue: "",
                            label: "Nazwa",
                          },
                        ],
                        onSubmit: (values: any) => {
                          insertGeneric("correction-signed", values);
                        },
                      }}
                    />
                  }
                />
                <Route
                  path="transfer-receipts"
                  element={
                    <ErrorItems
                      heading="Paragony przelewowe do wystawienia"
                      name="transferReceipts"
                      customView={transferReceiptsView}
                      formData={{
                        formContent: [
                          {
                            name: "payer_first_name",
                            initialValue: "",
                            label: "Imię",
                          },
                          {
                            name: "payer_last_name",
                            initialValue: "",
                            label: "Nazwisko",
                          },
                          {
                            name: "payment_date",
                            initialValue: "",
                            label: "Data wpłaty",
                            inputType: "date",
                          },
                          {
                            name: "amount",
                            initialValue: "",
                            label: "Kwota",
                          },
                          {
                            name: "service",
                            initialValue: "",
                            label: "Rodzaj imprezy",
                            element: "select",
                            getOptionsFrom: "events",
                          },
                          {
                            name: "service_performance_date",
                            initialValue: "",
                            label: "Data imprezy",
                            inputType: "date",
                          },
                        ],
                        onSubmit: (values: any) => {
                          insertGeneric("transfer-receipts", values);
                        },
                      }}
                    />
                  }
                />
                <Route
                  path="employees"
                  element={
                    <ErrorItems
                      heading="Pracownicy"
                      name="employeesGeneric"
                      customView={employeesView}
                      formData={{
                        formContent: [
                        
                          {
                            name: "first_name",
                            initialValue: "",
                            label: "Imię",
                          },
                          {
                            name: "last_name",
                            initialValue: "",
                            label: "Nazwisko",
                          },
                          {
                            name: "initials",
                            initialValue: "",
                            label: "Inicjały",
                          },
                          {
                            name: "sex",
                            initialValue: "",
                            label: "Płeć",
                            element: "select",
                            optionsList: [
                              { id: 0, name: "kobieta", active: true },
                              { id: 1, name: "mężczyzna", active: true },
                            ],
                          },
                          {
                            name: "group",
                            initialValue: "",
                            label: "Grupa",
                            element: "select",
                            getOptionsFrom: "employeeGroups"
                          },
                          /*{
                            name: "annual_holiday_pool2_add_from",
                            initialValue:null,
                            inputType:"date",
                            label: "Data nabycia praw do wydłużonego urlopu (puste jeśli nabyty)",
                          },
                          {
                            name: "termination_date",
                            initialValue:null,
                            inputType:"date",
                            label: "Data zakończenia zatrudnienia",
                          },
                          {
                            name: "annual_holiday_pool2",
                            initialValue:null,
                            inputType:"number",
                            label: "Liczba dni doliczanych do urlopu od podanej daty",
                          },*/
                          {
                            name: "cardname",
                            initialValue: "",
                            label: "Nazwa karty",
                          },
                        ],
                        onSubmit: (values: any) => {
                          insertGeneric("employees", values);
                        },
                      }}
                    />
                  }
                />
                <Route
                  path="employee-groups"
                  element={
                    <ErrorItems
                      heading="Grupy pracowników"
                      name="employeeGroups"
                      customView={employeeGroupsView}
                      formData={{
                        formContent: [
                          {
                            name: "name",
                            initialValue: "",
                            label: "Nazwa",
                          },
                          {
                            name: "location",
                            initialValue: "",
                            label: "Lokalizacja",
                          },
                        ],
                        onSubmit: (values: any) => {
                          insertGeneric("employee-groups", values);
                        },
                      }}
                    />
                  }
                />
                <Route
                  path="events"
                  element={
                    <ErrorItems
                      heading="Imprezy"
                      name="events"
                      customView={eventsView}
                      formData={{
                        formContent: [
                          {
                            name: "name",
                            initialValue: "",
                            label: "Nazwa",
                          },
                        ],
                        onSubmit: (values: any) => {
                          insertGeneric("events", values);
                        },
                      }}
                    />
                  }
                />
                <Route
                  path="venues"
                  element={
                    <ErrorItems
                      heading="Sale"
                      name="venues"
                      formData={{
                        formContent: [
                          {
                            name: "name",
                            initialValue: "",
                            label: "Nazwa",
                          },
                          {
                            name: "description",
                            initialValue: "",
                            label: "Opis",
                          },
                        ],
                        onSubmit: (values: any) => {
                          insertGeneric("venues", values);
                        },
                      }}
                    />
                  }
                />
                <Route
                  path="new-entry"
                  element={<EditErrorEntry setUpdate={setUpdate} />}
                />
                <Route
                  path="edit-entry/:id"
                  element={<EditErrorEntry setUpdate={setUpdate} />}
                />
              </Routes>
            </div>
          </div>
        </EntryContext.Provider>
      )}
    </>
  );
}
