import QRCode from "react-qr-code";
import { objectOrJSONparse } from "../functions/tools";

export default function EventQR({ event }: { event: any }) {
  const schedules = objectOrJSONparse(event?.schedules);
  const dates = objectOrJSONparse(event?.dates);

  console.log(schedules);
  const first = schedules?.[0];
  const last = schedules?.at(-1);
  const start =
    first?.date?.replace(/-/g, "") +
    "T" +
    first?.time_from?.replace(/:/g, "") +
    "00";
  const end =
    last?.date?.replace(/-/g, "") +
    "T" +
    last?.time_to?.replace(/:/g, "") +
    "00";
  console.log(dates);

  const summary = dates?.[0]?._stand_text;

  const detailsURL = "https://events.agit.com.pl/2390-233-434-ss942";

  const ics = `BEGIN:VEVENT\nDTSTART;TZID=Europe/Warsaw:${start}\nDTEND;TZID=Europe/Warsaw:${end}\nSUMMARY:${summary}\nDESCRIPTION:${detailsURL}\nLOCATION:AGIT Hotel Congress & SPA\nEND:VEVENT`;
  //20240724T120000
  return <QRCode value={ics} />;
}
