export default function Tasks() {

    return <div className="tasks">
        <ul>
            <li>Zadanie
                <ul>
                    <li>pilne!/czerwone</li>
                    <li>normalne/zielone</li>
                    <li>w wolnej chwili /niebieskie</li>
                    <li>zrealizowane /szare, zjeżdżają poniżej aktywnych</li>
                </ul>
            </li>
            <li>od kogo / dla kogo</li>
            <li>termin realizacji/brak terminu</li>
            <li>treść zadania</li>
            <li>dodaj uwagi w trakcie realizacji (data, kto dodał)</li>
            <li>zrealizowano / poproś o zawieszenie / niemożliwe do realizacji</li>
            <li>wyświetla się pole z uwagami/info w związku z realizacją zadania</li>
            <li>powiadomienie na mail o zadaniu i jego edycji/dodaniu uwag</li> 
        </ul>
    </div>
}