import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export default function ChangeOrder({
  index,
  length,
  entries,
  setEntries,
  orderSave
}: {
  index: number;
  length: number;
  entries: any;
  setEntries: Function;
  orderSave?: Boolean;
}) {

  return (
    <td className="changeOrder">
      <div
        style={{ visibility: index === 0 ? "hidden" : "visible" }}
        onClick={() => {
          setEntries((prev: any[]) => {
            const n: any = [];
            prev.forEach((v: any, i: number) => {
              if (i === index) {
                n[i - 1] = orderSave ? {...v, order: i-1} : v
                n[i] = prev[i - 1];
              } else {
                n[i] = v;
              }
            });
            return orderSave ? n.map((n:any, i: number) => ({...n, order:i})) : n;
          });
          
        }}
      >
        ▲
      </div>

      <div
        style={{ visibility: index === length - 1 ? "hidden" : "visible" }}
        onClick={() => {
          setEntries((prev: any[]) => {
            const n: any = [];
            prev.forEach((v: any, i: number) => {
              if (i === index) {
                n[i + 1] = orderSave ? {...v,order: i+1} : v;
                n[i] = prev[i + 1];
              } else if (i !== index + 1) {
                n[i] = v;
              }
            });
            return orderSave ? n.map((n:any, i: number) => ({...n, order:i})) : n;
          });
         
        }}
      >
        ▼
      </div>
    </td>
  );
}
