import { aggregateDates } from "../../functions/dates";

export default function HolidayReportPersonTypes({
  item,
  date,
}: {
  item: any;
  date: any;
}) {
  // console.log(item.groups);
  const month = item.groups.filter(
    (group: any) => parseInt(group.month) === parseInt(date.month)
  );
  // console.log(month);
  return month?.[0]?.dates?.length ? (
    <div className="hrPerson">
      <div className="hrPersonName">
        {item.first_name} {item.last_name}
      </div>
      <div className="hrPersonTypes">
        <table className="simpleTable" style={{marginTop:"15px"}}>
          <tbody>
            {month.map((group: any) => {
              const ranges = aggregateDates(
                group.dates.map((d: any) => ({ date: d }))
              ).map((dateRange: any) => {
                const localeParams: Intl.DateTimeFormatOptions = {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                };

                const date1 = dateRange[0].date;
                const date2 = dateRange.at(-1).date;
                return {
                  count: dateRange.length,
                  string:
                    date1 === date2
                      ? new Date(date1).toLocaleDateString(
                          "pl-PL",
                          localeParams
                        )
                      : new Date(date1).toLocaleDateString("pl-PL", {
                          day: "numeric",
                        }) +
                        " - " +
                        new Date(date2).toLocaleDateString(
                          "pl-PL",
                          localeParams
                        ),
                };
              });

              return (
                <tr style={{ verticalAlign: "top" }}>
                  <td style={{ width: "200px" }}>
                    <div style={{fontWeight:"500"}}>{group.name}</div>
                    <div>
                      <span style={{ fontSize: "90%" }}>
                        {"SUMA: "}
                        {group.dates.length === 1
                          ? group.dates.length + " dzień"
                          : group.dates.length + " dni"}
                      </span>
                    </div>
                  </td>
                  <td style={{ width: "300px", fontSize:"90%" }}>
                    {ranges.map((r: any) => (
                      <div
                        style={{
                          paddingBottom: "2px",
                          marginBottom:"7px",
                          borderBottom:".5px solid lightgrey",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems:"flex-end"
                        }}
                      >
                        <div>{r.string} </div>
                        <div style={{ fontSize: "80%" }}>
                          {r.count === 1
                            ? r.count +
                              (group.type != 7
                                ? " dzień rob."
                                : " dzień kalend.")
                            : r.count +
                              (group.type != 7 ? " dni rob." : " dni kalend.")}
                        </div>
                      </div>
                    ))}
                  </td>
                </tr>
              );
              return (
                <div className="hrPersonType">
                  <div className="hrPersonTypeName">
                    {group.name}{" "}
                    <span style={{ marginLeft: "5px", fontSize: "70%" }}>
                      {" "}
                      {group.dates.length === 1
                        ? group.dates.length + " dzień"
                        : group.dates.length + " dni"}
                    </span>
                  </div>
                  <div className="hrPersonDates">
                    {ranges.map((r: any) => (
                      <div>{r}</div>
                    ))}
                    {/*group.dates.map((date: any, ind: number, arr: any) => {
                const month = new Date(date).getMonth();
                const monthDate = new Date();
                monthDate.setMonth(month);
                return (
                  <>
                    {(ind > 0 &&
                      arr[ind - 1] &&
                      new Date(arr[ind - 1]).getMonth() !==
                        new Date(date).getMonth()) ||
                    ind === 0 ? (
                      <div className="monthTag">
                        {monthDate.toLocaleDateString("pl-PL", {
                          month: "long",
                        })}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div>
                      {new Date(date).toLocaleDateString("pl-PL", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}{" "}
                      <span
                        style={{
                          fontSize: "11px",
                          color: "grey",
                        }}
                      >
                        {new Date(date).toLocaleDateString("pl-PL", {
                          weekday: "long",
                        })}
                      </span>
                    </div>
                  </>
                );
                    })*/}
                  </div>
                </div>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <></>
  );
}
