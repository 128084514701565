import Draggable from "react-draggable";
import { Link } from "react-router-dom";
import gearIcon from "../images/icons/gear.svg";
import { createContext, useState } from "react";

export const ModalContext = createContext<any>({
  updater: () => {},
});

export default function Modal({
  heading,
  options,
  maximize = false,
  content,
  show,
  style,
  updater,
  identity,
}: {
  heading?: string | JSX.Element;
  options?: string | JSX.Element;
  maximize?: boolean;
  content: JSX.Element;
  show: Function;
  style?: React.CSSProperties;
  updater?: Function;
  identity?: string;
}) {
  const [maximized, setMaximized] = useState(maximize);

  return (
    <div
      className={
        "modalWrap" + (identity === "notification" ? " notification" : "")
      }
    >
      <ModalContext.Provider value={{ updater: updater }}>
        <Draggable handle=".modal-heading-text">
          <div
            className={"modal" + (maximized ? " maximized" : "")}
            style={{
              ...style,
              minHeight: maximized ? "100%" : style?.minHeight,
              width: maximized ? "inherit" : style?.width,
            }}
          >
            <div className="modal-heading">
              <div className="modal-heading-text">{heading}</div>
              {options ? (
                <div className="modal-options-icon">
                  <img width={15} src={gearIcon} />
                  <div className="modal-options">{options}</div>
                </div>
              ) : (
                <></>
              )}
              <div
                className="modal-maximize"
                onClick={() => setMaximized((prev: boolean) => !prev)}
              >
                🗖
              </div>
              <div
                className="modal-close"
                onClick={() => show((prev: boolean) => !prev)}
              >
                ✖
              </div>
            </div>
            <div className="modal-content">{content}</div>
          </div>
        </Draggable>
      </ModalContext.Provider>
    </div>
  );
}
