import insertGeneric from "../dataFetch/insertGeneric";
import Form from "../forms_new/Form";
import { objectOrJSONparse } from "../functions/tools";
import { addEventFormPotential } from "./addEventFormPotential";

export default function AddPotentialEvent({
  auth,
  date,
  event,
  calendar,
  setModal,
}: {
  auth: any;
  date: any;
  event: any;
  calendar: any;
  setModal: any;
}) {
  return (
    <div style={{ width: "100%", maxHeight: "500px" }}>
      <Form
        name="calendar-event-potential"
        auth={auth}
        confirm={false}
        submit={async (data: any) => {
          if (data?.dates) {
            data.first_date = objectOrJSONparse(data?.dates)?.at(0)?.date;
          }
          const result = await insertGeneric("calendar-event-potential", data);
          if (result) {
            calendar.setUpdate((prev: any) => prev + 1);
            setModal((prev: any) => ({
              ...prev,
              show: false,
            }));
          }
        }}
        schema={addEventFormPotential({}, date?.obj, auth)}
        data={event}
      />
    </div>
  );
}
