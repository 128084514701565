import { useEffect, useState } from "react";
import { formField } from "../../formContentType";
import MultiAddField from "../MultiAddField";
import { createGUID, objectOrJSONparse } from "../../../functions/tools";
import { useFormContext } from "react-hook-form";

export default function MultiAddSubShow({
  subShow,
  regularColumnKeys,
  topSchema,
  rowValues,
  data,
}: {
  subShow: any;
  regularColumnKeys: any;
  topSchema: any;
  rowValues: any;
  data: any;
}) {
  const { getValues } = useFormContext();
  const categories: any = subShow.map((s: any) => s?.name);

  let foundKeys: any = [];

  Object.keys(getValues()).forEach((key: string) => {
    categories.forEach((name: string) => {
      const reg = new RegExp("^(" + name + ")@.*");
      const match = key.match(reg);
      // console.log(key, match, name);
      if (match) {
        foundKeys.push(match?.[0]);
      }
    });
  });
  const dataSpecific = getValues(foundKeys);

  const dataSpecificPrep = dataSpecific
    ?.map((scheduleWide: any) =>
      Object.keys(scheduleWide)?.map((index: any) =>
        scheduleWide[index]?.map((m: any) => m)
      )
    )
    .flat()
    .flat();

  const dataInitPrep = data
    ? Object.keys(data)
        ?.filter((k: string) => categories.includes(k))
        ?.map(
          (
            category: any //"meals"
          ) => {
            // console.log(data?.[category]);
            return objectOrJSONparse(data?.[category])
              .filter((item: any) => item?.schedule_guid === rowValues?.guid)
              .map((item: any) =>
                item?.parent_field_name !== null && item?.parent_order !== null
                  ? item?.parent_field_name + " " + item?.parent_order
                  : undefined
              );
          }
        )
        .flat()
        .filter((f: any) => f)
    : [];

  const subRowsNew: any = dataSpecificPrep && dataSpecificPrep.length ?
  dataSpecificPrep
    .filter((item: any) => item?.schedule_guid === rowValues?.guid)
    .map((item: any) =>
      item?.parent_field_name !== null && item?.parent_order !== null
        ? item?.parent_field_name + " " + item?.parent_order
        : undefined
    ) : dataInitPrep;

    // console.log(data, getValues(), foundKeys, 
    // 'specific', dataSpecificPrep, 
    // 'guid', rowValues?.guid,
    // 'sub',subRowsNew);

  const categoryData = [...new Set(subRowsNew)]
    ?.map((cid: any) => {
      const id = Number(cid?.split(" ")?.[1]);
      const parentField = cid?.split(" ")?.[0];

      return {
        active: true,
        id: id,
        name: parentField,
        schema: subShow?.find((sub: any) => sub?.name === parentField),
        data: JSON.stringify(
          objectOrJSONparse(data?.["meals"])?.filter(
            (r: any) =>
              id === r?.parent_order && r?.parent_field_name === parentField
          )
          // ?.map((item:any) => ({...item, schedule_guid: rowValues?.guid}))
        ),
      };
    })
    ?.sort((a: any, b: any) => (a?.name === "snacks" ? -1 : 1));

  // function displayedSubSetter() {
  //   {
  //     // const data = getValues();

  //     const subRows = data
  //       ? Object.keys(data)
  //           ?.filter((k: string) => categories.includes(k))
  //           ?.map(
  //             (
  //               category: any //"meals"
  //             ) => {
  //               // console.log(data?.[category]);
  //               return objectOrJSONparse(data?.[category])
  //                 .filter(
  //                   (item: any) => item?.schedule_guid === rowValues?.guid
  //                 )
  //                 .map((item: any) =>
  //                   item?.parent_field_name !== null &&
  //                   item?.parent_order !== null
  //                     ? item?.parent_field_name + " " + item?.parent_order
  //                     : undefined
  //                 );
  //             }
  //           )
  //           .flat()
  //           .filter((f: any) => f)
  //       : [];

  // }

  const [displayedSubs, setDisplayedSubs] = useState<any>(
    categoryData && categoryData.length ? categoryData : []
  );

  //   console.log(categoryData?.at(-1));
  const [lastIndex, setLastIndex] = useState(
    Number.isInteger(displayedSubs?.at(-1)?.id)
      ? Number(displayedSubs?.at(-1)?.id) + 1
      : 1
  );
  //   console.log(data?["meals"], displayedSubs);
  // console.log(displayedSubs, data, subShow, getValues(), lastIndex);
  return (
    <>
      {/* <tr>{JSON.stringify(rowValues)}</tr> */}
      <tr>
        <td colSpan={regularColumnKeys.length + 2}>
          <div className="subField-selector">
            {subShow?.map((sub: any) => {
              return (
                <div
                  className="subItemToggle"
                  onClick={() => {
                    //   const id = createGUID();
                    // if (displayedSubs.length < 11) {

                    setDisplayedSubs((prev: any) => [
                      ...prev,
                      {
                        active: true,
                        id: lastIndex,
                        name: sub?.name,
                        schema: sub,
                        data: JSON.stringify([]),
                      },
                    ]);
                    setLastIndex((prev: number) => prev + 1);
                    // console.log(objectOrJSONparse(data?.meals));
                    // }
                  }}
                >
                  DODAJ {sub?.label?.toUpperCase()}
                </div>
              );
            })}
          </div>
          <div className="multi-collection">
            {displayedSubs?.map((item: any) => {
              const uniqueID = item?.id;
              return (
                <MultiAddField
                  schema={{ ...item.schema, name: item?.name, div: uniqueID }}
                  readOnly={topSchema?.readOnly}
                  subData={displayedSubs}
                  setSubData={setDisplayedSubs}
                  parent={rowValues}
                  initialValues={item?.data}
                  hidden={
                    item.schema?.visibleIfParent
                      ? !item.schema?.visibleIfParent(rowValues)
                      : false
                  }
                  uniqueID={uniqueID}
                  key={uniqueID}
                />
              );
            })}
            {/* <div>
              {JSON.stringify(foundKeys)}
            </div>
            <div>
              {dataSpecific &&
                dataSpecific?.map((scheduleWide: any) =>
                  Object.keys(scheduleWide)?.map((index: any) =>
                    scheduleWide[index]?.map((m: any) => (
                      <div style={{ margin: "8px" }}>
                      
                        {m?.schedule_guid} - {m?.name}
                      </div>
                    ))
                  )
                )}
            </div> */}
          </div>
        </td>
      </tr>
    </>
  );
}
