import { useContext, useState } from "react";
import { RegisterViewContext } from "./View";
import sortASCicon from "../../images/icons/asc.svg";
import sortDESCicon from "../../images/icons/desc.svg";

import Options from "./Options";
import ViewTableRow from "./ViewTableRow";
import ViewItem from "./ViewItem";
import { ViewContext } from "../contexts/ViewContext";
export default function ViewTable() {
  const {
    rows,
    schema,
    rowSchema,
    setOrderASC,
    setOrderColumn,
    orderASC,
    orderColumn,
    auth,
    setUpdates,
  } = useContext(RegisterViewContext);
  const { setModal } = useContext(ViewContext);

  return (
    <table className="table-layout">
      <thead>
        <tr>
          {rows && rows.length ? (
            rowSchema()?.map((cell: any) => (
              <th
                onClick={() => {
                  if (cell?.source === orderColumn) {
                    if (schema?.()?.sortable !== false) {
                      setOrderASC((prev: boolean) => !prev);
                    }
                  } else {
                    if (schema?.()?.sortable !== false) {
                      setOrderColumn(cell?.source);
                    }
                  }
                }}
              >
                {cell?.name}{" "}
                {cell?.source && cell?.source === orderColumn ? (
                  <img width={8} src={orderASC ? sortASCicon : sortDESCicon} />
                ) : (
                  <></>
                )}
              </th>
            ))
          ) : (
            <></>
          )}
        </tr>
      </thead>
      <tbody>
        {rows && rows.length ? (
          rows?.map((row: any) => {
            return (
              <ViewItem
                schema={rowSchema(row, {
                  setModal,
                  auth,
                  setUpdates,
                  setStatusEdit: () => {},
                  showFunction: setModal,
                })}
                row={row}
              >
                <ViewTableRow />
              </ViewItem>
            );
          })
        ) : (
          <></>
        )}
      </tbody>
    </table>
  );
}
