import { useState } from "react";

export default function TablePagination({
  metaData,
  setOffset,
}: {
  metaData: any;
  setOffset: any;
}) {
  const { offset, count, limit } = metaData;
  const iterations = Math.ceil(count / limit);

  const pageNumber = Array(iterations).length;
  let pageArray: (number | string)[] = Array.from(Array(iterations).keys());
  //let pageArray2: (number | string)[] = Array.from(Array(iterations).keys());
  //let half = Math.floor(pageNumber / 2);
  if (pageNumber > 6) {
    const numbers =
      offset < iterations - 3
        ? pageArray.splice(
            0 + (Math.floor(offset) - 3 > 0 ? Math.floor(offset) - 3 : 0),
            7
          )
        : pageArray.splice(-7);
    pageArray = [...numbers];

    if (pageArray.at(-1) !== pageNumber - 1) {
      pageArray.push("...");
    } else {
      pageArray.push("  ");
    }
    if (pageArray[0] !== 0) {
      pageArray.unshift("...");
    } else {
      pageArray.unshift("  ");
    }
  }

  let countWord = "wpisów";
  let countLastDigit = Number(String(count).at(-1));
  
  if (countLastDigit > 1 && countLastDigit < 5) countWord = "wpisy";
  if (count > 5 ) countWord = "wpisów";
  if (count == 1 ) countWord = "wpis";
  
  
    
  //  const [active,setActive] = useState(0);

  return metaData.count && metaData.count > 0 && pageArray.length > 0 ? (
    <div className="pagination-wrapper">
    <div className="pagination">
      <div className="section">
        {pageArray.length === 1 ? (
          <></>
        ) : (
          <button
            className="paginationButton back"
            style={{ opacity: offset > 0 ? "1" : ".04" }}
            onClick={() =>
              offset > 0 ? setOffset((prev: number) => prev - 1) : <></>
            }
          >
            ◀
          </button>
        )}
      </div>
      <div className="section">
        <button
          style={{ opacity: offset < 4 ? "0" : "1" }}
          className="paginationButton first"
          onClick={() => setOffset(0)}
        >
          1
        </button>
        {pageArray.map((item) =>
          typeof item === "number" ? (
            <button
              className={
                "paginationButton" + (offset === item ? " active" : "")
              }
              onClick={() => setOffset(item)}
              key={item}
            >
              {item + 1}
            </button>
          ) : (
            <span className="paginationButton inactive">{item}</span>
          )
        )}

        <button
          style={{ opacity: iterations - 5 < offset ? "0" : "1" }}
          className="paginationButton last"
          onClick={() => setOffset(iterations - 1)}
        >
          {iterations}
        </button>
      </div>
      <div className="section">
        {pageArray.length === 1 ? (
          <></>
        ) : (
          <button
            className="paginationButton forward"
            style={{ opacity: offset < iterations - 1 ? "1" : ".04" }}
            onClick={() =>
              offset < iterations - 1 ? (
                setOffset((prev: number) => prev + 1)
              ) : (
                <></>
              )
            }
          >
            ▶
          </button>
        )}
      </div>
    </div>
       <div className="pagination-description">
       <div>Strona {offset + 1} / {iterations}</div>
       <div>{count} {countWord}</div>
     </div></div>
  ) : (
    <></>
  );
}
