import { useContext } from "react";
import Day from "./Day";
import openEditor from "./openEditor";
import { CalendarContext } from "../contexts/CalendarContext";
import { ViewContext } from "../contexts/ViewContext";
import Search from "./Search";
import useEventViewItems from "./useEventViewItems";
import EventQuickView from "./EventQuickView";

interface dateObj {
  obj: Date;
  events: any;
  holiday?: any;
}

export default function CalendarList({ cal }: { cal: any }) {
  const calendar = useContext(CalendarContext);
  const modal = useContext(ViewContext);
  
  const monthView = (
    <>
      <div className="day-list">
        {cal.dates.map((date: dateObj) => (
          <div
            key={date.obj.toISOString()}
            className={
              (date.events.length ? "events" : "") +
              (date.obj.getDay() === 0 || date.holiday === true
                ? " holiday"
                : "") +
              (date.obj.getDay() === 6 ? " saturday" : "")
            }
            // onDoubleClick={() => {
            //   openEditor(undefined, date, { modal: modal, calendar: calendar });
            // }}
            onClick={() => {
              const dayId = "d" + date.obj.getDate();
              const dayEl = document.getElementById(dayId)
              

              dayEl?.scrollIntoView({
                behavior: "smooth",
                inline: "start",
                block: "start",
              });
            }}
            //title={JSON.stringify(date)}
          >
            <div>{date.obj.getDate()}</div>
            <div
              className={
                "day-summary" + (date.obj.getDate() > 22 ? " right" : "")
              }
            >
              <div className="day-data">
                {date.obj.toLocaleDateString("pl-PL", { weekday: "long" })}
              </div>
              {date.events.map((event: any) => (
                <EventQuickView
                  key={event?.id + "_" + date?.obj.getDate()}
                  event={event}
                  date={date?.obj}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="calendar-list">
        {cal.dates.map((date: dateObj) => (
          <Day key={date.obj.getDate()} date={date} list={true} />
        ))}
      </div>
    </>
  );

  return monthView;
}
