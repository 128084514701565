import { useState } from "react";
import VoucherList from "./VoucherList";
import vIcon from "../../images/icons/voucher.svg";
import { ViewContext } from "../contexts/ViewContext";
import vouchersAllIcon from "../../images/icons/vouchers-all.svg";
import vouchersPrintedIcon from "../../images/icons/vouchers-waiting.svg";
import vouchersAvailableIcon from "../../images/icons/vouchers-available.svg";
import vouchersGivenIcon from "../../images/icons/vouchers-received.svg";
import vouchersUsedIcon from "../../images/icons/vouchers-used.svg";

export default function VoucherRegister({
  items,
  register,
  auth,
  setUpdate,
  filter,
  setFilter,
}: {
  items: any;
  register: any;
  auth: number;
  setUpdate: Function;
  filter: any;
  setFilter: Function;
}) {
  const i = auth === 9 ? [...items?.slice()?.reverse(), ...register] : register;

  const [selected, setSelected] = useState("100");
  const [selectedG, setSelectedG] = useState("100");
  const [paidMode, setPaidMode] = useState(true);

  function getAvailableVoucherNumber(i: any, amount: number, paid: boolean) {
    const left = i?.filter(
      (i: any) => i?.amount === amount && i?.paid == paid && i?.status === 2
    )?.length;
    const all = i?.filter(
      (i: any) => i?.amount === amount && i?.paid == paid
    )?.length;

    return left > 0 ? (
      <>
        {amount} zł{" "}
        <span
          className="v-number"
          style={{ color: left < 3 ? "red" : left < 6 ? "yellow" : "green" }}
        >
          ({left})
        </span>
      </>
    ) : (
      <span style={{ opacity: all ? 1 : 0.3 }}>{amount} zł</span>
    );
  }

  const viewMap: any = (paid: 0 | 1) => {
    return {
      "50": (
        <VoucherList
          key={50 + paid}
          setUpdate={setUpdate}
          auth={auth}
          items={i}
          heading="50 zł"
          amount={50}
          paid={paid}
        />
      ),
      "100": (
        <VoucherList
          key={100 + paid}
          setUpdate={setUpdate}
          auth={auth}
          items={i}
          heading="100 zł"
          amount={100}
          paid={paid}
        />
      ),
      "150": (
        <VoucherList
          key={150 + paid}
          setUpdate={setUpdate}
          auth={auth}
          items={i}
          heading="150 zł"
          amount={150}
          paid={paid}
        />
      ),
      "200": (
        <VoucherList
          key={200 + paid}
          setUpdate={setUpdate}
          auth={auth}
          items={i}
          heading="200 zł"
          amount={200}
          paid={paid}
        />
      ),
    };
  };
  return (
    <div className="voucher-register">
      <div className="grid-1-3">
        <h1
          className="switch"
          onClick={() => setPaidMode((prev: boolean) => !prev)}
        >
          <img style={{ width: "22px", marginRight: "8px" }} src={vIcon} />
          {paidMode ? <>PŁATNE</> : <>GRATISOWE</>}{" "}
        </h1>
        <div className="buttons">
          {paidMode ? (
            <>
              <button
                className={
                  "normal-button highlight1" +
                  (selected === "50" ? " active" : "")
                }
                onClick={() => setSelected("50")}
              >
                {getAvailableVoucherNumber(i, 50, true)}
              </button>
              <button
                className={
                  "normal-button highlight1" +
                  (selected === "100" ? " active" : "")
                }
                onClick={() => setSelected("100")}
              >
                {getAvailableVoucherNumber(i, 100, true)}
              </button>
              <button
                className={
                  "normal-button highlight1" +
                  (selected === "150" ? " active" : "")
                }
                onClick={() => setSelected("150")}
              >
                {getAvailableVoucherNumber(i, 150, true)}
              </button>
              <button
                className={
                  "normal-button highlight1" +
                  (selected === "200" ? " active" : "")
                }
                onClick={() => setSelected("200")}
              >
                {getAvailableVoucherNumber(i, 200, true)}
              </button>
            </>
          ) : (
            <>
              <button
                className={
                  "normal-button highlight1" +
                  (selectedG === "50" ? " active" : "")
                }
                onClick={() => setSelectedG("50")}
              >
                {getAvailableVoucherNumber(i, 50, false)}
              </button>
              <button
                className={
                  "normal-button highlight1" +
                  (selectedG === "100" ? " active" : "")
                }
                onClick={() => setSelectedG("100")}
              >
                {getAvailableVoucherNumber(i, 100, false)}
              </button>
              <button
                className={
                  "normal-button highlight1" +
                  (selectedG === "150" ? " active" : "")
                }
                onClick={() => setSelectedG("150")}
              >
                {getAvailableVoucherNumber(i, 150, false)}
              </button>
              <button
                className={
                  "normal-button highlight1" +
                  (selectedG === "200" ? " active" : "")
                }
                onClick={() => setSelectedG("200")}
              >
                {getAvailableVoucherNumber(i, 200, false)}
              </button>
            </>
          )}
        </div>
      </div>
      <div className="buttons" style={{ gridTemplateColumns: "repeat(5,1fr)" }}>
        <button
          className={
            "normal-button little highlight1" +
            (!filter?.status ? " active" : "")
          }
          onClick={() => {
            setFilter({});
            setUpdate((prev: number) => prev + 1);
          }}
        >
          <img src={vouchersAllIcon} />
          WSZYSTKIE
        </button>
        <button
          className={
            "normal-button little highlight1" +
            (filter?.status === 1 ? " active" : "")
          }
          onClick={() => {
            setFilter({ status: 1 });
            setUpdate((prev: number) => prev + 1);
          }}
        >
          <img src={vouchersPrintedIcon} />
          OCZEKUJĄCE
        </button>
        <button
          className={
            "normal-button little highlight1" +
            (filter?.status === 2 ? " active" : "")
          }
          onClick={() => {
            setFilter({ status: 2 });
            setUpdate((prev: number) => prev + 1);
          }}
        >
          <img src={vouchersAvailableIcon} />
          DOSTĘPNE NA RECEPCJI
        </button>
        <button
          className={
            "normal-button little highlight1" +
            (filter?.status === 3 ? " active" : "")
          }
          onClick={() => {
            setFilter({ status: 3 });
            setUpdate((prev: number) => prev + 1);
          }}
        >
          <img src={vouchersGivenIcon} />
          WYDANE KLIENTOWI
        </button>
        <button
          className={
            "normal-button little highlight1" +
            (filter?.status === 4 ? " active" : "")
          }
          onClick={() => {
            setFilter({ status: 4 });
            setUpdate((prev: number) => prev + 1);
          }}
        >
          <img src={vouchersUsedIcon} />
          WYKORZYSTANE
        </button>
      </div>
      <div>
        <div className="voucher-lists">
          {paidMode ? viewMap(1)?.[selected] : viewMap(0)?.[selectedG]}
        </div>
      </div>
    </div>
  );
}
