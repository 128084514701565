import { useState } from "react";
import useSuperFlatSchema from "../calendar/useSuperFlatSchema";
import { categoryDictionary } from "./FileUploadList";
import contractSchema from "./addContract";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { Document, Page, Thumbnail } from "react-pdf";

export default function Preview({
  data,
  link,
  format,
}: {
  data: any;
  link: string;
  format: string;
}) {
  const contractForm = useSuperFlatSchema(contractSchema({}, {}));
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pdfScale, setPdfScale] = useState<number>(1);

  const pageArray = new Array(numPages).fill("");
  console.log(pageArray);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: format === "pdf" ? "static" : "relative",
        overflow: "hidden",
      }}
    >
      {format === "image" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactPanZoom image={link} />
        </div>
      ) : format === "audio" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <audio controls={true}>
            <source src={link} type={data?.format} />
          </audio>
        </div>
      ) : format === "video" ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <video
            controls={true}
            style={{ maxHeight: "70vh", maxWidth: "80vw" }}
          >
            <source src={link} type={data?.format} />
          </video>
        </div>
      ) : format === "pdf" ? (
        <>
          {/* <p style={{ fontSize: "8px" }}>{link}</p> */}
          <div
            style={{
              display: "flex",
              padding: "20px",
              background: "grey",
              justifyContent: "center",
            }}
          >
            <div className="pdf-preview">
              <Document file={link} onLoadSuccess={onDocumentLoadSuccess}>
                {pageArray.map((p: any, index: number) => (
                  <Thumbnail
                    width={800}
                    scale={pdfScale}
                    pageNumber={index + 1}
                  />
                ))}
              </Document>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {format === "pdf" ? (
        <div className="pdfScale">
          <div onClick={() => setPdfScale((prev: number) => prev * 1.25)}>
            +
          </div>
          <div onClick={() => setPdfScale((prev: number) => prev / 1.25)}>
            -
          </div>{" "}
        </div>
      ) : (
        <></>
      )}
      {data?.time_created ? (
        <div
          style={
            format === "pdf"
              ? {
                  fontSize: "10px",
                  padding: "3px 6px",
                  boxShadow: "0 0 10px rgba(0,0,0,.3)",
                  boxSizing: "border-box",
                  position: "absolute",
                  bottom: "10px",
                  left: "10px",
                  width: "95%",
                  maxWidth: "400px",
                  background: "white",
                  borderRadius: "10px",
                }
              : { fontSize: "10px", padding: "5px" }
          }
        >
          {format === "pdf" ? (
            <div>
              <strong>Dokument PDF</strong> | {numPages} str.
            </div>
          ) : format === "image" ? (
            <></>
          ) : (
            <></>
          )}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{new Date(data?.time_created)?.toLocaleString()}</div>
            <div>{data?.module}</div>
          </div>
          <div>
            {categoryDictionary?.[data?.category] || data?.category} {">"}{" "}
            {contractForm?.find((f: any) => f.name === data?.sub_category)
              ?.label || data?.sub_category}
          </div>

          <div>{data?.sub_id}</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
