import { Link } from "react-router-dom";

export default function LinkButton({
  to,
  text,
  icon,
}: {
  to: string;
  text: string;
  icon: string | JSX.Element;
}) {
  return (
    <Link className="link" to={to}>
      <button className="button withIcon">
        <span className="text-icon">{icon}</span>
        {text}
      </button>
    </Link>
  );
}
