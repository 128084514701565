import {
  formField,
  optionGroup,
  optionsList,
} from "../forms_new/formContentType";
import minutesToJSX from "./minutesToJSX";

export function createGUID(length?: number) {
  return Math.floor(Math.random() * (length ? Math.pow(10, length) : 10000000));
}

export function sqlCurrentDate() {
  return (
    new Date().toISOString().slice(0, 10) + " " + new Date().toTimeString()
  );
}

export function groupBy(x: any, f: any) {
  const res = x.reduce(
    (a: any, b: any, i: any) => ((a[f(b, i, x)] ||= []).push(b), a),
    {}
  );
  const arr: any = [];
  Object.keys(res)
    .reverse()
    .forEach((key) => arr.push(res[key]));

  return arr;
}

export function rowsCombine(rows: any[], concatNames?: any[]) {
  const combinedRow: any = [];
  rows.forEach((row: any) => {
    Object.keys(row).forEach((key: any) => {
      if (concatNames && concatNames.includes(key)) {
        if (combinedRow[key]) {
          combinedRow[key] = combinedRow[key] + "+|+" + row[key];
        } else {
          combinedRow[key] = row[key];
        }
      } else {
        combinedRow[key] = row[key];
      }
    });
  });
  console.log(combinedRow);
  return combinedRow;
}

export function addGUIDtoObject(obj: any) {
  const withGUID: any = {};
  return obj
    ? Object.keys(obj).forEach((key: any) => {
        withGUID[key] = { guid: createGUID(), ...obj[key] };
      })
    : {};
}
export function addGUIDtoArray(arr: any) {
  // console.log(arr);
  return arr ? arr.map((obj: any) => ({ guid: createGUID(), ...obj })) : [];
}

export function cloneField(item: formField): formField {
  return {
    ...item,
    name: item.name + "-clone",
  };
}

export function inflect(number: number, forms: string[]) {
  let inflected = forms[0];
  const lastNum = Number(String(number).at(-1));
  if (number === 0) {
    inflected = forms[0];
  } else if (number === 1) {
    inflected = forms[1];
  } else if (
    (Number(String(number).slice(-2)) < 10 && lastNum > 1 && lastNum < 5) ||
    (Number(String(number).slice(-2)) > 19 && lastNum > 1 && lastNum < 5)
  ) {
    inflected = forms[2];
  } else {
    inflected = forms[3];
  }
  return number + " " + inflected;
}

export function minutesUntil(
  timeString: string,
  date?: Date | string,
  next?: Boolean
) {
  if (!timeString) return 0;
  const now = date ? new Date(date) : new Date();
  const nextDate = next ? (date ? new Date(date) : new Date()) : undefined;
  if (nextDate) {
    nextDate.setDate(nextDate.getDate() + 1);
  }

  const time = timeStringToTime(timeString, nextDate ? nextDate : date);

  const timeUntil = (time.getTime() - now.getTime()) / 60 / 1000;

  return timeUntil;
}

export function timeStringToNumber(timeString: string) {
  return timeString ? Number(timeString.slice(0, 5).replace(":", "")) : 0;
}

export function timeStringToTime(timeString: string, date?: Date | string) {
  if (!timeString) return date ? new Date(date) : new Date();
  const time = date ? new Date(date) : new Date();
  const splitTimeString = timeString.split(":");
  const hours = Number(splitTimeString[0]);
  const minutes = Number(splitTimeString[1]);
  time.setHours(hours, minutes);
  return time;
}

export function prettyTime(t: string) {
  return String(t)?.match(/^0/)
    ? String(t)?.slice(1, 5)
    : String(t)?.slice(0, 5);
}

export function compareTimeStrings(time1: string, time2: string) {
  return timeStringToTime(time1).getTime() - timeStringToTime(time2).getTime();
}

export function arrayToNumbersAndStrings(array: (number | string)[]) {
  // [1, 2, 3, 4, 5, 6, "1", "2", "3", "4", "5", "6"]
  const newArr: (string | number)[] = [];
  array.forEach((item: number | string) => {
    newArr.push(String(item));
    newArr.push(Number(item));
  });
  return newArr;
}

export function flatOptions(options: any) {
  return options
    .map((item: any) => {
      if (item.group) {
        return item.options;
      } else {
        return item;
      }
    })
    .flat();
}

export function isObject(value: any) {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

export function looseCompare(v1: any, v2: any) {
  return (
    v1 == v2 ||
    (v1 === undefined && v2 === "") ||
    (v1 === undefined && v2 === 0)
  );
}

export function deepCompare(first: string, second: string, columns: any) {
  const rows1 = isJSONParsable(first) ? JSON.parse(first) : [];
  const rows2 = isJSONParsable(second) ? JSON.parse(second) : [];
  const sameLength = rows1 && rows2 && rows1.length === rows2.length;
  const longer = rows2.length > rows1.length;

  const guids1 = rows1?.map((row: any) => row?.guid);
  const guids2 = rows1?.map((row: any) => row?.guid);

  if (longer) {
    const newRows = rows2?.filter((row: any) => !guids1?.includes(row?.guid));

    newRows?.forEach((n: any) =>
      rows1?.push(() => {
        const r: any = {};
        columns.forEach((name: string) => (r[name] = undefined));
      })
    );
  }

  let details = rows1?.map((row: any, index: number) => {
    const rowKeys = Object.keys(row);
    const otherRow = rows2?.[index];
    const guid = row?.guid;

    const compare = columns?.map((key: any) => {
      return {
        name: key,
        v1: row?.[key],
        v2: otherRow?.[key],
        same:
          key === "guid" ||
          key === "id" ||
          looseCompare(row?.[key], otherRow?.[key])
            ? true
            : row?.[key] === otherRow?.[key],
      };
    });
    return {
      compare: compare,
      same: compare
        ?.map((c: any) => c.same)
        ?.reduce((a: Boolean, b: Boolean) => a && b, true),
    };
  });

  return {
    analysis: details,
    same: details
      ?.map((r: any) => r.same)
      ?.reduce((a: Boolean, b: Boolean) => a && b, true),
  };
}

export function timePairProps(
  time1: string,
  time2: string,
  next?: Boolean,
  date?: any
) {
  const nextBoth =
    compareTimeStrings(time1, time2) > 0 && next ? false : next ? true : false;
  const minutesTo = minutesUntil(time1, date, nextBoth);
  const minutesLeft =
    minutesUntil(time2, date, next) > 0
      ? minutesUntil(time2, date, next)
      : false;
  const ongoing = minutesTo <= 0 && minutesLeft;
  console.log({
    minutesTo: minutesTo / 60 / 24,
    minutesLeft: minutesLeft,
    ongoing: ongoing,
  });
  return {
    minutesTo: minutesTo,
    minutesLeft: minutesLeft,
    ongoing: ongoing,
  };
}

export function isJSONParsable(input: any) {
  if (input) {
    try {
      JSON.parse(input);
    } catch (e) {
      return false;
    }
    if (JSON.parse(input)) {
      return true;
    } else {
      return false;
    }
  }
}

export function objectOrJSONparse(input: string | Object) {
  if (typeof input === "string" && isJSONParsable(input)) {
    return JSON.parse(input);
  } else {
    return input;
  }
}

export function groupByMulti(array: any[], f: Function) {
  let groups: any = {};
  array.forEach(function (o) {
    var group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
}

export function swapElements(array: any[], index1: number, index2: number) {
  array[index1] = array.splice(index2, 1, array[index1])[0];
}
