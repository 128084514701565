export default function useSuperFlatSchema(schema:any) {
    const superFlat = schema
    ?.map((s: any) => {
      if (s.element === "subTabs") {
        return s?.subTabs?.fields;
      } else {
        return s;
      }
    })
    ?.flat()
    .flat()
    ?.map((i: any) => (i.content ? i.content : i))
    ?.flat();
  // ?.map((i: any) =>
  //   i.multiAddProps?.subData ? i.multiAddProps?.subData("override") : i
  // ).flat();

  superFlat?.push(
    superFlat
      ?.filter((s: any) => s?.multiAddProps?.subData)
      ?.map((m: any) => m?.multiAddProps?.subData) || []
  );

  return superFlat?.flat()?.flat();
}