import HeaderButton from "../tables/HeaderButton";

interface menuitem {
  link: string;
  id: string;
  icon?: string;
  text: string | JSX.Element;
  sub?: menudata;
  map?: any;
}

interface menudata extends Array<menuitem> {}

function menuItems(item: menuitem) {
  return (
    <li key={item.id}>
      <HeaderButton
        link={item.link}
        icon={item.icon}
        id={item.id}
        text={
          <>
            <>{item.text}</>
            {item.sub ? (
              <span
                style={{
                  marginLeft: "8px",
                  fontSize: "9px",
                }}
              >
                ▼
              </span>
            ) : (
              <></>
            )}
          </>
        }
      />
      {item.sub ? (
        <ul className="menu sub">
          {item.sub.map((subitem: any) => menuItems(subitem))}
        </ul>
      ) : (
        <></>
      )}
    </li>
  );
}

export default function Menu({
  data,
  style,
}: {
  data: menudata;
  style?: React.CSSProperties;
}) {
  return (
    <nav className="menuWrap" style={style}>
      <ul className="menu">{data.map((item: any) => menuItems(item))}</ul>
    </nav>
  );
}
