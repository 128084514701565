import { Link } from "react-router-dom";
import BooleanSymbol from "../viewElements/BooleanSymbol";
import DateDisplay from "../viewElements/DateDisplay";

export interface errorRegister {
  id: number;
  entry_number: string;
  entry_datetime: string;
  entry_approved_datetime: string;
  problem_type: string;
  problem_type_string: string;
  sales_document_type: string;
  sales_document_type_string: string;
  is_original_receipt_present: string;
  lsi_document_number: string;
  date_of_sale_from_sales_document: string;
  purchaser_number_on_document: string;
  guest: string;
  booking_number: string;
  gross_amount_original_document: string;
  gross_amount_original_document_currency: string;
  what_is_corrected: string;
  what_is_corrected_string: string;
  previous_value: string;
  previous_value_currency: string;
  should_be: string;
  should_be_currency: string;
  gross_value_as_corrected: string;
  gross_value_as_corrected_currency: string;
  correction_reason: string;
  reporting_form: string;
  reporting_form_string: string;
  date_of_discovery: string;
  employee_involved_id: string;
  employee_first_name: string;
  employee_last_name: string;
  employee_initials: string;
  date_reported: string;
  approved_solution: string;
  correction_document_number: string;
  correction_document_issue_date: string;
  correction_signed_or_arrangements_made: string;
  correction_signed_string: string;
  created_by: string;
  modified_by: string;
  resolved: string;
}

export default function errorRegisterView(
  item: errorRegister,
  references: any
) {
  const separatorStyle = {
    padding: "0",
    width: "4px",
    border: "none",
    boxShadow: "2px 0 4px -2px rgba(0,0,0,.2) inset",
    //borderTop: "1px dashed rgb(170,170,170)",
    background: "rgb(254,254,254)",
  };
  const separatorHeadingStyle = {
    ...separatorStyle,
    //background: "none",
    //borderTop: "none",
  };

  const users = references.users;
  const createdBy =
    item && item.created_by
      ? users
        ? users.rows.filter((user: any) => user.userID === item.created_by)[0]
        : undefined
      : undefined;
  const modifiedBy =
    item && item.modified_by
      ? users
        ? users.rows.filter((user: any) => user.userID === item.modified_by)[0]
        : undefined
      : undefined;

  const columns = !item
    ? [
        {
          name: "brak wpisów",
          styleHeading: { fontSize: "15px", padding: "12px" },
        },
      ]
    : [
        {
          ref: "entry_number",
          name: "Lp.",
          style: { width: "70px", height: "0", padding: "0", fontSize: "18px" },
          className: "linkcell",
          //rowSpan: "2",
          jsx: (
            <div
              style={{
                textDecoration: "none",
                color: item.resolved === "0" ? "brown" : "black",
                fontWeight: item.resolved === "0" ? "bold" : "normal",
                padding: "10px",
                margin: "-10px",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {item.entry_number}
            </div>
          ),
        },
        {
          ref: "entry_datetime",
          name: "Data utworzenia",
          style: { width: "90px", height: "1px" },
          //rowSpan: 2,
          jsx: (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                height: "95%",
                maxHeight: "120px",
                fontSize: "12px",
              }}
            >
              <div>
                <DateDisplay
                  dateString={item.entry_datetime}
                  format="datetime"
                />
              </div>
            </div>
          ),
        },
        {
          ref: "problem_type",
          name: "Problem",
          style: { width: "120px" },
          jsx: <>{item.problem_type_string}</>,
        },
        {
          ref: "sales_document_type_original_receipt",
          name: "Dokument / jest oryginał",
          jsx: (
            <>
              {item.sales_document_type_string}{" "}
              {item.sales_document_type_string === "paragon" ? (
                <BooleanSymbol value={item.is_original_receipt_present} />
              ) : (
                <></>
              )}
            </>
          ),
        },
        /*
        {
          ref: "is_original_receipt_present",
          name: "Oryginał paragonu fiskalnego",
          style: { width: "30px" },
          jsx: <BooleanSymbol value={item.is_original_receipt_present} />,
        },*/
        {
          ref: "lsi_document_number+date",
          name: "Nr i data dok. sprzedaży LSI",
          style: { width: "100px" },
          jsx: (
            <>
              {item.lsi_document_number}
              <hr
                style={{
                  border: "none",
                  borderTop: "1px solid lightgrey",
                  margin: "1px",
                }}
              />
              <DateDisplay
                dateString={item.date_of_sale_from_sales_document}
                format="date"
              />
            </>
          ),
        },
        /*  {
      ref: "date_of_sale_from_sales_document",
      name: "Data sprzedaży z dok. sprzedaży",
      jsx: item.date_of_sale_from_sales_document,
    },*/
        {
          ref: "purchaser_number_on_document",
          name: "Nazwa Nabywcy",
          style: { width: "120px" },
          jsx: <>{item.purchaser_number_on_document}</>,
        },

        {
          ref: "guest_and_booking_number",
          name: "Gość i nr rezerwacji",
          style: { width: "120px" },
          jsx: (
            <>
              {item.guest}
              <br />
              {item.booking_number}
            </>
          ),
        },
        {
          ref: "separator",
          name: "",
          style: separatorStyle,
          styleHeading: separatorHeadingStyle,
          jsx: <></>,
        },
        {
          ref: "gross_amount_original_document",
          name: "Kwota brutto pierwotnego dokumentu",
          style: { width: "60px", color: "brown" },
          jsx: <>{item.gross_amount_original_document}</>,
        },
        {
          ref: "previous_value",
          name: "Było",
          style: { width: "60px", color: "brown" },
          jsx: <>{item.previous_value}</>,
        },
        {
          ref: "what_is_corrected",
          name: "Co podlega korekcie?",
          style: { width: "85px" },
          jsx: <>{item.what_is_corrected_string}</>,
        },
        {
          ref: "should_be",
          name: "Powinno być",
          style: { width: "60px", color: "teal", fontWeight: "bold" },
          jsx: <>{item.should_be}</>,
        },
        {
          ref: "gross_value_as_corrected",
          name: "Kwota brutto dokumentu po korekcie",
          style: { width: "60px", color: "teal", fontWeight: "500" },
          jsx: <>{item.gross_value_as_corrected}</>,
        },
        {
          ref: "separator",
          name: "",
          style: separatorStyle,
          styleHeading: separatorHeadingStyle,
          jsx: <></>,
        },
        /*
        {
          ref: "correction_reason",
          name: "Przyczyna / opis",
          style: { fontSize: "11px", width: "150px", textAlign: "left" },
          jsx: (
            <>
              {item.correction_reason.length > 100 ? (
                <>
                  {item.correction_reason.slice(0, 100)} <span>(...)</span>
                </>
              ) : (
                item.correction_reason
              )}
            </>
          ),
        },
        */
        {
          ref: "reporting_form_and_discovery_date",
          name: "Forma zgłoszenia / Data zauważenia",
          style: { width: "60px" },
          jsx: (
            <>
              {item.reporting_form_string}
              <br />
              <DateDisplay dateString={item.date_of_discovery} format="date" />
            </>
          ),
        },
        /*
        {
          ref: "date_of_discovery",
          name: "Data zgłoszenia albo zauważenia",
          jsx: (
            <DateDisplay dateString={item.date_of_discovery} format="date" />
          ),
        },*/
        {
          ref: "employee_involved",
          name: "Pracownik powiązany",
          style: { width: "30px" },
          jsx: (
            <div
              title={item.employee_first_name + " " + item.employee_last_name}
            >
              {item.employee_initials}
            </div>
          ),
        },
        /*
        {
          ref: "approved_solution",
          name: "Rozwiązanie",
          style: { fontSize: "11px", width: "130px", textAlign: "left" },
          jsx: (
            <>
              {item.approved_solution.length > 100 ? (
                <>
                  {item.approved_solution.slice(0, 100)}
                  <span>(...)</span>
                </>
              ) : (
                <>{item.approved_solution}</>
              )}
            </>
          ),
        },*/
        {
          ref: "correction_document_number",
          name: "Wystawiony dokument korekty",
          jsx: (
            <>
              {item.correction_document_number ? (
                item.correction_document_number
              ) : (
                <>-</>
              )}
              <br />
              <DateDisplay
                dateString={item.correction_document_issue_date}
                format="date"
              />
            </>
          ),
        },
        /* {
          ref: "correction_signed_or_arrangements_made",
          name: "Korekta podpisana przez Klienta lub ustalenia mailowe",
          style: { width: "90px" },
          jsx: <>{item.correction_signed_string}</>,
        },*/
      ];
  return {
    columns: columns,
    secondRow: [
      {
        ref: "correction_reason",
        name: "Przyczyna / opis",
        style: { fontSize: "12px", textAlign: "left" },
        jsx: (
          <>
            {item.correction_reason.length > 2500 ? (
              <>
                {item.correction_reason.slice(0, 2500)} <span>(...)</span>
              </>
            ) : (
              item.correction_reason
            )}
          </>
        ),
      },
      {
        ref: "approved_solution",
        name: "Rozwiązanie",
        style: { fontSize: "12px", textAlign: "left" },
        jsx: (
          <>
            {item.approved_solution.length > 2500 ? (
              <>
                {item.approved_solution.slice(0, 2500)}
                <span>(...)</span>
              </>
            ) : (
              <>{item.approved_solution}</>
            )}
          </>
        ),
      },
      {
        ref: "authors",
        name: "Autorzy",
        style: { maxWidth: "150px", background: "none", boxShadow: "none" },
        jsx:
          users && users.loading ? (
            <></>
          ) : (
            <div>
              <div className="created-by">
                {createdBy?.first_name.slice(0, 1)}. {createdBy?.last_name}
              </div>
              {modifiedBy &&
              createdBy &&
              createdBy.userID !== modifiedBy.userID ? (
                <div className="modified-by">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i
                      className="gg-pen"
                      style={{
                        scale: "0.5",
                        color: "grey",
                        marginRight: "5px",
                      }}
                    />{" "}
                    {modifiedBy.first_name
                      ? modifiedBy.first_name.slice(0, 1) + " "
                      : ""}
                    {modifiedBy?.last_name}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ),
      },
    ],
  };
}
