import insertGeneric from "../../../dataFetch/insertGeneric";
import Form from "../../../forms_new/Form";
import { objectOrJSONparse } from "../../../functions/tools";
import { registerSchema } from "../schemaType";

export const voucherPrep = {
  groupBy: (v: any) => v?.cid,
  process: (rows: any) => {
    const result = rows
      .map((a: any) => ({
        ...a?.[0],
        stan: a
          ?.map((i: any) => parseInt(i?.stan))
          .reduce((a: number, b: number) => a + b, 0),
      }))
      .flat();
    // console.log(result);
    return result;
  },
};

export function schema(data: any, functions: any): registerSchema {
  return {
    filters: [],
    activeFilter: false,
    sortable: false,
    edit: false,
    remove: false,
    preSort:
      data && data?.length && data?.sort((a: any, b: any) => a.cid - b.cid),
    form: (
      <Form
        name="vouchers"
        showFunction={functions?.showFunction}
        data={data}
        submit={async (payload: any) => {
          //   await insertGeneric("employees", payload);
          //   await functions?.setUpdates((prev: number) => prev + 1);
          //   functions?.showFunction(false);
        }}
        // defaultSubTab="main"
        schema={{
          main: {
            name: "Edycja voucherów",
            content: [
              [
                {
                  name: "id",
                  style: {
                    display: "none",
                  },
                  disabled: true,
                },
                // {
                //   name: "type",
                //   label: "Rodzaj vouchera",
                //   element: "select",
                //   optionsList: [
                //     { id: "0", name: "gratisowy" },
                //     { id: "1", name: "płatny" },
                //   ],
                // },
                {
                  name: "type",
                  label: "Rodzaj z LSI",
                  element: "select-picker",
                  getOptionsFrom: { name: "lsi_vouchers", params: voucherPrep },
                },
                {
                  name: "multi-count",
                  label: "Ilość",
                  inputType: "number",
                  max: 50,
                  min: 1,
                  format: "half-1",
                },
                {
                  name: "requested_by",
                  label: "Kto zlecił?",
                  format: "half-2",
                  element: "select",
                  getOptionsFrom: {
                    name: "employeesGeneric",
                    params: {
                      process: (rows: any) => [
                        { id: "ar", name: "Agnieszka Rydz" },
                        ...rows?.filter(
                          (a: any) =>
                            a?.location === 0 && [1, 7].includes(Number(a?.group))
                        ),
                      ],
                    },
                  },
                },
              ],
            ],
          },
        }}
      />
    ),
  };
}
