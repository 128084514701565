import { useContext, useEffect, useState } from "react";
import { ViewContext } from "../contexts/ViewContext";
import setActive from "../dataFetch/setActive";
import useGeneric from "../dataFetch/useGeneric";
import GenericForm, { formProps } from "../forms/GenericForm";
import LinkButton from "./buttons/LinkButton";
import StateButton from "./buttons/StateButton";
import ErrorHeader from "./ErrorHeader";
import Table from "./Table";
import itemsView from "./views/itemsView";

export default function ErrorItems({
  name,
  process,
  heading,
  customView,
  controls = true,
  formData,
  editAction,
  updatePush = 0,
  active,
  limit,
  query,
  editable = true
}: {
  name: string;
  process?: Function;
  heading?: string;
  customView?: any;
  controls?: boolean;
  formData?: formProps;
  editAction?: any;
  updatePush?: number;
  active?: boolean;
  limit?: number;
  query?: any;
  editable?: boolean;
}) {
  const [update, setUpdate] = useState(0);
  const [activeView, setActiveView] = useState<boolean>(active !== undefined ? active : true);
  const [offset, setOffset] = useState(0);
  const data = useGeneric({
    name: name,
    limit: limit ? limit : 10,
    offset: offset,
    active: activeView,
    update: update + updatePush,
    reload: true,
    query: query ? query : undefined,
    process: process,
  });

  const view = useContext(ViewContext);

  useEffect(() => {
    setUpdate((prev: number) => prev + 1);
  }, [activeView]);

  useEffect(() => {
    setOffset(0);
  }, [name]);

  function editor(data: any = undefined) {
    const formContent = formData && formData.formContent
      ? formData.formContent.map((f: any) => ({
          ...f,
          initialValue: data[f.name] ? data[f.name] : "",
        }))
      : [];
      //console.log(formContent,formData);
    view.setModal((prev) => ({
      ...prev,
      heading: heading,
      content: formData ? (
        <GenericForm
          formContent={[
            { name: "id", initialValue: data.id, inputType: "hidden" },
            ...formContent,
          ]}
          onSubmit={formData.onSubmit}
          sideEffects={() => {
            setUpdate((prev: number) => prev + 1);
            view.setModal((prev) => ({ ...prev, show: false }));
          }}
        />
      ) : (
        <></>
      ),
      show: true,
    }));
  }

  return (
    <div className="errorItems">
      {data.loading ? (
        <></>
      ) : (
        <>
          {controls ? (
            <>
              {heading ? <h1>{heading}</h1> : <></>}
              <div className="tableMenu">
                {/*<LinkButton to="new-entry" text="Nowy" icon="+" />*/}
                <StateButton
                  text="Nowy"
                  icon={<i className="gg-file-add" />}
                  stateSetter={editor}
                />
                <StateButton
                  text="Aktywne"
                  icon={<i className="gg-box" />}
                  activeState={true}
                  refState={!activeView}
                  stateSetter={() => {
                    setActiveView(true);
                    setOffset(0);
                  }}
                />
                <StateButton
                  text="Wszystkie"
                  icon={<i className="gg-box" />}
                  activeState={true}
                  refState={activeView}
                  stateSetter={() => {
                    setActiveView(false);
                    setOffset(0);
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
          <Table
            rows={data.rows}
            metaData={data}
            offset={offset}
            setOffset={setOffset}
            view={customView ? customView : itemsView}
            editor={editable ? editor : false}
            action={{
              active: async (id: any, active: any) => {
                await setActive(id, active, name);
                setUpdate((prev: number) => prev + 1);
              },
            }}
          />
        </>
      )}
    </div>
  );
}
