import { useState } from "react";
import MenuCategories from "./MenuCategories";

export default function MenuCategoriesChoose() {
  const [tab, setTab] = useState<"meals" | "drinks">("meals");
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          zIndex: 2,
          position: "sticky",
          top: 0,
          background: "white",
          marginBottom: "12px",
        }}
      >
        <button
          className={"normal-button" + (tab === "meals" ? " active" : "")}
          onClick={() => setTab("meals")}
        >
          Kategorie dań
        </button>
        <button
          className={"normal-button" + (tab === "drinks" ? " active" : "")}
          onClick={() => setTab("drinks")}
        >
          Rodzaje napojów
        </button>
      </div>
      <MenuCategories kind={tab} />
    </div>
  );
}
