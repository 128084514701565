import { useContext, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import { ViewContext } from "../contexts/ViewContext";
import prettyDate, { compareDay, dayDiff } from "../functions/prettyDate";
import prettyNumber from "../functions/prettyNumber";
import HamsterLoading from "../common/HamsterLoading";
import LSIinvoiceView from "./LSIinvoiceView";
import trailingZero from "../functions/trailingZero";
import cashIcon from "../../images/icons/cash.svg";
import creditCardIcon from "../../images/icons/credit-card.svg";
import transferIcon from "../../images/icons/transfer.svg";
import onlinePaymentsIcon from "../../images/icons/online-payment.svg";
import SelectPicker from "../forms_new/Elements/SelectPicker";
import Loading from "../common/Loading";
import { Dots } from "./Dots";
import { UserContext } from "../contexts/UserContext";
import { objectOrJSONparse } from "../functions/tools";
import fillLog from "../dataFetch/fillLog";
import { isSameDay } from "../schedules/ScheduleTable";
import prettyMoney from "../functions/prettyMoney";

export default function InvoicesView({ sub }: { sub?: Boolean }) {
  const { setCurrentPage, modal, setModal } = useContext(ViewContext);
  const [updates, setUpdates] = useState(0);
  setCurrentPage("invoice-viewer");
  const [input, setInput] = useState("");
  const daySeparate = Number(localStorage?.getItem("invoices-day-separate"));

  const [invoiceType, setInvoiceType] = useState();
  const [dateFrom, setDateFrom] = useState(() => {
    const d = new Date();
    d.setDate(d.getDate() - 7);
    return d.toISOString()?.slice(0, 10);
  });
  const [dateTo, setDateTo] = useState(() =>
    new Date().toISOString()?.slice(0, 10)
  );
  const [settled, setSettled] = useState("");
  const { auth, userID } = useContext(UserContext);
  const authReg = objectOrJSONparse(auth?.["registers"]);
  const fetching = useGeneric({
    name: "lsi_invoice_query",
    // limit: 220,
    query: {
      multiQuery: input || undefined,
      range: input ? undefined : true,
      from: input ? undefined : dateFrom,
      to: input ? undefined : dateTo + "T23:59:59",
      invoiceType: invoiceType || undefined,
      settled: settled || undefined,
    },
    reload: true,
    secondsToReload: 45,
    update: input + String(invoiceType) + String(settled),
    updateWithLoading: updates + String(daySeparate),
    groupBy: (v: any) => (v?.SCID ? v.SCID : v.id),
  });

  const data = !fetching.loading && fetching.rows;
  console.log(fetching);

  function getMin() {
    const d = dateTo ? new Date(dateTo) : new Date();
    d.setDate(d.getDate() - 31);
    return d?.toISOString()?.slice(0, 10);
  }

  return authReg?.["lsi-sales-invoices"] >= 1 ? (
    <div className={sub ? "" : "standardWrap"}>
      <div className="registers-top-panel">
        <div className="heading">Faktury sprzedażowe LSI</div>

        <div className="filters">
          <form className="generic-search-form">
            <div
              onClick={() => {
                localStorage.setItem(
                  "invoices-day-separate",
                  Number(localStorage.getItem("invoices-day-separate")) === 0
                    ? "1"
                    : "0"
                );
              }}
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              ¬
            </div>
            <div title="Data od">
              <label>Od</label>
              <input
                disabled={input ? true : false}
                onChange={(e) => {
                  setDateFrom(e.target.value);
                  if (dateTo < e.target.value) {
                    setDateTo(e.target.value);
                  }
                }}
                value={dateFrom}
                type="date"
                min={getMin()}
                max={new Date().toISOString().slice(0, 10)}
              />
            </div>
            <div title="Data do">
              <label>Do</label>
              <input
                disabled={input ? true : false}
                onChange={(e) => {
                  setDateTo(e.target.value);
                  if (dateFrom > e.target.value) {
                    setDateFrom(e.target.value);
                  }
                }}
                value={dateTo}
                type="date"
                max={new Date().toISOString().slice(0, 10)}
              />
            </div>
            <div title="Przeszukaj według numeru lub nazwy klienta">
              <label>Szukaj</label>
              <input
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                value={input}
                type="search"
              />
            </div>
            <div>
              <label>Rodzaj</label>
              <select
                value={invoiceType}
                onChange={(e: any) => setInvoiceType(e.target.value)}
              >
                <option value={""}>wszystkie</option>
                <option value={"fv"}>faktury</option>
                <option value={"do-par"}>do paragonu</option>
                <option value={"kor"}>korekty</option>
              </select>
            </div>
            <div>
              <label>Rozliczone</label>
              <select
                value={settled}
                onChange={(e: any) => setSettled(e.target.value)}
              >
                <option value="">nie filtruj</option>
                <option value={1}>tak</option>
                <option value={0}>nie</option>
              </select>
            </div>
          </form>
          <button
            onClick={() => {
              if (!fetching?.loading && !fetching?.hiddenLoading) {
                setUpdates((prev: number) => prev + 1);
              }
            }}
            className={
              "normal-button" +
              (fetching?.loading || fetching?.hiddenLoading ? " disabled" : "")
            }
            style={{
              width: "120px",
              textTransform: "uppercase",
              fontSize: "11px",
              borderRadius: "3px",
              marginLeft: "8px",
              padding: "3px 12px",
            }}
          >
            {fetching?.loading || fetching?.hiddenLoading ? (
              <>Ładowanie</>
            ) : (
              <>Odśwież</>
            )}
          </button>
        </div>
      </div>
      {fetching.loading ? (
        <Loading absolute={false} />
      ) : (
        <div className="viewer" style={{ position: "relative" }}>
          {data ? (
            <table className="table-layout clickable-rows">
              <thead>
                <tr>
                  <th>Nr dok.</th>
                  <th>Data</th>
                  <th>Nabywca</th>
                  <th>Poz.</th>
                  <th>Netto</th>
                  <th>Brutto</th>
                  <th></th>
                  <th>Termin zapłaty</th>
                  <th>Data wpłaty</th>
                </tr>
              </thead>
              <tbody style={{ userSelect: "none" }}>
                {data?.map((item: any, index: number, arr: any) => {
                  const a = item?.[0];
                  const previous = arr?.at(index - 1)?.[0];
                  const d1 = a?.data_modyfikacji;
                  const d2 = previous?.data_modyfikacji;

                  const sameDate = isSameDay(d1, d2);
                  const docName = `${a.DocNo}/${trailingZero(a?.month, 2)}/${
                    a.year
                  }/H`;
                  a.document_name_full =
                    a?.do_paragonu == 1 ? (
                      <>
                        <span style={{ fontSize: "80%" }}>do PAR</span>{" "}
                        {docName}
                      </>
                    ) : a?.type == 0 ? (
                      `FV/${docName}`
                    ) : a?.type == 3 ? (
                      <span style={{ color: "orangered" }}>
                        Kor FV/{docName}
                      </span>
                    ) : (
                      ""
                    );

                  return (
                    <>
                      {(index === 0 || !sameDate) && daySeparate === 1 ? (
                        <tr className="day-heading">
                          <td
                            style={{
                              fontWeight: "500",
                              fontSize: "11px",
                              padding: "15px 5px 3px 5px",
                              color: "grey",
                              textTransform: "uppercase",
                            }}
                            colSpan={4}
                          >
                            {prettyDate(a?.data_modyfikacji, "DZISIAJ", true)},{" "}
                            {new Date(a?.data_modyfikacji).toLocaleDateString(
                              "pl-PL",
                              { weekday: "long" }
                            )}
                          </td>
                          <td
                            style={{
                              fontWeight: "500",
                              fontSize: "10px",
                              padding: "15px 5px 3px 5px",
                            }}
                          >
                            {prettyMoney(
                              arr
                                ?.filter((i: any) =>
                                  isSameDay(
                                    a?.data_modyfikacji,
                                    i?.[0]?.data_modyfikacji
                                  )
                                )
                                .map((i: any) => Number(i?.[0]?.ValueN))
                                .reduce((a: number, b: number) => a + b, 0),
                              { round: 2, postfix: " zł" }
                            )}
                          </td>
                          <td
                            style={{
                              fontWeight: "500",
                              fontSize: "10px",
                              padding: "15px 5px 3px 5px",
                            }}
                          >
                            {prettyMoney(
                              arr
                                ?.filter((i: any) =>
                                  isSameDay(
                                    a?.data_modyfikacji,
                                    i?.[0]?.data_modyfikacji
                                  )
                                )
                                .map((i: any) => Number(i?.[0]?.ValueB))
                                .reduce((a: number, b: number) => a + b, 0),
                              { round: 2, postfix: " zł" }
                            )}
                          </td>
                          <td colSpan={3}></td>
                        </tr>
                      ) : (
                        <></>
                      )}
                      <tr
                        style={
                          a.KwRozliczenia == a.ValueB
                            ? { background: "rgba(99, 255, 99, 0.22)" }
                            : a.KwRozliczenia != a.ValueB &&
                              dayDiff(new Date(a?.PayTerm), new Date()) > 0 &&
                              !a?.KorygowanaSCID
                            ? {
                                background:
                                  "linear-gradient(-20deg, rgb(255 248 219), rgb(244 255 199))",
                              }
                            : {}
                        }
                        onClick={() => {
                          fillLog({
                            user: userID,
                            section: "lsi-sales-invoices",
                            event: "view",
                            item_id: a.SCID,
                          });
                          setModal({
                            show: true,
                            maximize: true,
                            style: {
                              width: "900px",
                            },
                            heading: (
                              <>
                                Szczegóły Faktury
                                {/* : FV/{a.DocNo}/{a.month}/{a.year}/H /{" "} */}
                                {/* {a.ko_firma} */}
                              </>
                            ),
                            content: (
                              <LSIinvoiceView id={a.SCID} userID={userID} />
                            ),
                          });
                        }}
                      >
                        <td style={{ width: "125px" }}>
                          {a?.document_name_full}
                        </td>
                        <td style={{ width: "115px" }}>
                          {prettyDate(a.SourceDate)}
                        </td>
                        <td style={{ width: "40%" }}>{a.ko_firma}</td>
                        <td style={{ width: "20px" }}>
                          <Dots number={item?.length} />
                        </td>
                        <td
                          style={{
                            width: "80px",
                            color:
                              Number(a.ValueN) < 0 ? "orangered" : "initial",
                          }}
                        >
                          {prettyNumber(a.ValueN, {
                            round: true,
                            postfix: "zł",
                          })}
                        </td>
                        <td
                          style={{
                            width: "80px",
                            color:
                              Number(a.ValueB) < 0 ? "orangered" : "initial",
                          }}
                        >
                          {prettyNumber(a.ValueB, {
                            round: true,
                            postfix: "zł",
                          })}
                        </td>
                        <td style={{ width: "18px" }}>
                          <img
                            width={16}
                            style={a?.Rozliczone == 1 ? { opacity: 0.25 } : {}}
                            src={
                              a?.typ_platnosci === "Karta kredytowa"
                                ? creditCardIcon
                                : a?.typ_platnosci === "Przelew"
                                ? transferIcon
                                : a?.typ_platnosci === "System płatności online"
                                ? onlinePaymentsIcon
                                : cashIcon
                            }
                            title={a?.typ_platnosci}
                          />
                          {/* {a?.typ_platnosci} */}
                        </td>
                        <td
                          style={{
                            width: "120px",
                            // fontWeight: "bold",
                            fontSize: "80%",
                          }}
                        >
                          {prettyDate(a.PayTerm, "dziś", true)}
                          {dayDiff(new Date(), a.PayTerm) > 0 ? (
                            <>
                              {" "}
                              (
                              {dayDiff(new Date(), a.PayTerm) === 1
                                ? "jutro"
                                : "za " +
                                  dayDiff(new Date(), a.PayTerm) +
                                  " dni"}
                              )
                            </>
                          ) : dayDiff(new Date(), a.PayTerm) === 0 ? (
                            <></>
                          ) : !a?.Rozliczone ? (
                            <span style={{ color: "red" }}>
                              {" "}
                              (
                              {dayDiff(new Date(), a.PayTerm) === -1
                                ? "wczoraj"
                                : Math.abs(dayDiff(new Date(), a.PayTerm)) +
                                  " dni temu"}
                              )
                            </span>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td
                          style={{
                            width: "110px",
                            // fontWeight: "bold",
                            fontSize: "80%",
                          }}
                        >
                          {a.DataWplaty ? (
                            <>
                              <span style={{ fontSize: "12px" }}>✓</span>{" "}
                              {prettyDate(a.DataWplaty, "dziś", true)}
                            </>
                          ) : (
                            <span style={{ fontWeight: "bold", color: "grey" }}>
                              do zapłaty
                            </span>
                          )}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  ) : (
    <>Brak uprawnień</>
  );
}
