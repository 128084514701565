import useGeneric from "../dataFetch/useGeneric";
import { isObject } from "../functions/tools";

export function getFromSchema(dbData: any, schema: any) {
  // console.log(dbData);
  const obj: any = {};
  if (dbData) {
    schema?.forEach((item: any) => {
        // if (item?.name === "meals") console.log(item.diffTransform(dbData))
      if (item?.diffTransform) {
        obj[item.name] = item.diffTransform(dbData);
      } else {
        obj[item.name] = dbData?.[item.name];
      }
    });
  }
  return obj;
}

// MAKES SUBKEYS AND ADDS THEM TO SCHEMA TO MIRROR DEEP DATA
export function getSubKeys(
  original: any,
  proposed: any,
  superFlatSchema: any,
  separators: string[]
) {
  [...new Set([...Object.keys(original), ...Object.keys(proposed)])].forEach(
    (key: any) => {
      if (isObject(original[key]) || isObject(proposed[key])) {
        const subKeys =
          Object.keys(proposed[key]) || Object.keys(original[key]);
        subKeys.forEach((subKey: string) => {
          separators.forEach((separator: string) => {
            original[key + separator + subKey + separator] = JSON.stringify(
              original?.[key]?.[subKey]
            );
            proposed[key + separator + subKey + separator] = JSON.stringify(
              proposed?.[key]?.[subKey]
            );
            superFlatSchema.push({
              name: key + separator + subKey + separator,
              multiAddProps: superFlatSchema?.find((i: any) => i?.name === key)
                ?.multiAddProps,
            });
          });
        });
        delete original[key];
        delete proposed[key];
      }
    }
  );

  return { original: original, proposed: proposed, superFlatSchema: superFlatSchema };
}

export default function useCompare(
  superFlatSchema: any,
  selectedIndex: number,
  all: any
) {
  const proposedInit = getFromSchema(all[selectedIndex], superFlatSchema);

  // all[selectedIndex];
  const originalInit = getFromSchema(all[selectedIndex + 1], superFlatSchema);

  const { original, proposed } = getSubKeys(
    originalInit,
    proposedInit,
    superFlatSchema,
    ["%"]
  );

  return {
    original: original,
    proposed: proposed,
  };
}
