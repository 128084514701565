import { Form, useFormikContext } from "formik";
import { Fragment, useEffect } from "react";
import RenderFieldAndLabel from "./RenderFieldAndLabel";

export default function GenericFormInner({
  name,
  formContent,
  options,
  gatherData,
  validateOn
}: {
  name?: string;
  formContent: any;
  options: any;
  gatherData?: Function;
  validateOn?: number;
}) {
  const { values, validateForm, errors } = useFormikContext();

  useEffect(() => {
    if (gatherData && name) {
      gatherData(values, name);
    }
  }, [values]);


  /// trying to contstruct validation that works across several forms
  /*useEffect(() =>  {
    if(validateOn && validateOn > 0) {
      validateForm().then(errors => console.log(errors));
    }
  }, [validateOn])*/

  return (
    <div className="editor">
      <Form>
        <div
          className={
            Array.isArray(formContent[0])
              ? "multiColumn columns-" + formContent.length
              : ""
          }
        >
          {formContent.map((f: any) =>
            !Array.isArray(f) ? (
              <Fragment key={f.name}>
                <RenderFieldAndLabel
                  data={f}
                  options={options}
                  optionsList={f.optionsList}
                />
              </Fragment>
            ) : (
              <div className="formColumn">
                {f.map((ff: any) => (
                  <Fragment key={ff.name}>
                    <RenderFieldAndLabel
                      data={ff}
                      options={options}
                      optionsList={ff.optionsList}
                    />
                  </Fragment>
                ))}
              </div>
            )
          )}
        </div>
        <button className="button" type="submit">
          Dodaj
        </button>
      </Form>
    </div>
  );
}
