import { useContext, useState } from "react";
import { ViewContext } from "./contexts/ViewContext";
import useGeneric from "./dataFetch/useGeneric";
import prettyDate, { simpleDate, timeUntil } from "./functions/prettyDate";
import hotelIcon from "../images/icons/room3.svg";
import officeIcon from "../images/icons/briefcase.svg";
import allIcon from "../images/icons/location.svg";
import { Link } from "react-router-dom";
import HamsterLoading from "./common/HamsterLoading";

export default function EmployeeFilesSummaries() {
  const { setCurrentPage, modal, setModal } = useContext(ViewContext);
  const [filters, setFilters] = useState<any>({ active: true });
  setCurrentPage("employee-files");

  const load = useGeneric({
    name: "employee_dates_incoming",
    query: {
      // active: 1,
    },
  });

  let sums = !load.loading && load?.rows;

  //apply filters
  if (sums && sums.length) {
    sums = sums?.filter((item: any) => {
      let f: any = [];
      Object.keys(filters).forEach((key: any) => {
        if (filters[key] === undefined) {
          return;
        }
        let value = item[key];
        if (key === "active" || key === "date_this_month") {
          value = item[key] ? true : false;
        }
        if (value !== "-") {
          f.push(value === filters[key]);
        }
      });
      return f.reduce((a: Boolean, b: Boolean) => a && b, true);
    });
  }
  console.log(filters);
  const [sortBy, setSortBy] = useState<any>();

  const sorted =
    sums &&
    sums
      .map((item: any) => {
        const dates = [
          item.contract_until,
          item.next_medical,
          item.next_bhp,
          item.next_sanepid,
        ];
        const timeValues: number[] = dates
          .map((d: string) => new Date(d).getTime())
          .filter((v: any) => v > 0);

        const lowest = Math.min(...timeValues);
        return {
          ...item,
          lowest: lowest,
        };
      })
      .sort((a: any, b: any) => a.lowest - b.lowest);

  let manualSort = sums;

  if (sortBy && sums) {
    Object.keys(sortBy).forEach((cat: string) => {
      const s = sortBy[cat].status;
      const type = sortBy[cat].type;

      if (s !== 0) {
        manualSort?.sort((a: any, b: any) => {
          // console.log(b[s], a[s], b[s] - a[s]);
          if (type === "number") {
            return s === 2 ? b[cat] - a[cat] : a[cat] - b[cat];
          } else if (type === "string") {
            return s === 2
              ? b[cat].localeCompare(a[cat])
              : a[cat].localeCompare(b[cat]);
          } else if (type === "date") {
            return s === 2
              ? new Date(b[cat]).getTime() - new Date(a[cat]).getTime()
              : new Date(a[cat]).getTime() - new Date(b[cat]).getTime();
          }
        });
        if (type !== "number") {
          const x = manualSort.filter((item: any) => item[cat]);
          const y = manualSort.filter((item: any) => !item[cat]);

          manualSort = [...x, ...y];
        }
      } else {
        manualSort = sorted;
      }
    });
  }

  function doSort(cat: string, valueType: string) {
    setSortBy((prev: any) => ({
      [cat]: {
        status:
          prev?.[cat]?.status === 1 ? 2 : prev?.[cat]?.status === 2 ? 0 : 1,
        type: valueType,
      },
    }));
  }

  return load.loading ? <HamsterLoading/> : (
    <div className="standardWrap employee-sums-wrap">
      <div className="filters">
        <Link to="/employee-files"  unstable_viewTransition>
          <div>
            Przejdź do edytora
          </div>
        </Link>

        <div>FILTRY</div>
        <div
          className={"filter" + (filters.active ? "" : " greyed-out")}
          onClick={() =>
            setFilters((prev: any) => ({ ...prev, active: !prev.active }))
          }
        >
          <div>{filters.active ? "⬤" : "◯"}</div>
          <div>AKTYWNE</div>
        </div>
        <div
          className="filter"
          onClick={() =>
            setFilters((prev: any) => ({
              ...prev,
              location:
                prev.location === 0 ? 1 : prev.location === 1 ? undefined : 0,
            }))
          }
        >
          <div>
            {filters.location === 0 ? (
              <img width={12} src={hotelIcon} />
            ) : filters.location === 1 ? (
              <img width={12} src={officeIcon} />
            ) : (
              <>
                <img width={12} src={hotelIcon} />
                <img width={12} src={officeIcon} />
              </>
            )}
          </div>
          <div>
            {filters.location === 0
              ? "HOTEL"
              : filters.location === 1
              ? "BIURO"
              : "WSZĘDZIE"}
          </div>
        </div>
        <div
          className={"filter" + (filters.date_this_month ? "" : " greyed-out")}
          onClick={() =>
            setFilters((prev: any) => ({
              ...prev,
              date_this_month: !prev.date_this_month,
            }))
          }
        >
          <div>{filters.date_this_month ? "⬤" : "◯"}</div>
          <div>W TYM MIESIĄCU</div>
        </div>
      </div>
      {sums ? (
        <div>
          <table className="data-table midi employee-sums">
            <thead>
              <tr>
                <th
                  className={
                    sortBy?.["location"]?.status === 1
                      ? "asc"
                      : sortBy?.["location"]?.status === 2
                      ? "desc"
                      : ""
                  }
                  onClick={() => doSort("location", "number")}
                >
                  Dział
                </th>
                <th
                  style={{ width: "250px" }}
                  className={
                    sortBy?.["last_name"]?.status === 1
                      ? "asc"
                      : sortBy?.["last_name"]?.status === 2
                      ? "desc"
                      : ""
                  }
                  onClick={() => doSort("last_name", "string")}
                >
                  Imię i nazwisko
                </th>
                <th
                  className={
                    sortBy?.["contract_until"]?.status === 1
                      ? "asc"
                      : sortBy?.["contract_until"]?.status === 2
                      ? "desc"
                      : ""
                  }
                  onClick={() => doSort("contract_until", "date")}
                >
                  Umowa obowiązuje do
                </th>
                <th
                  className={
                    sortBy?.["next_medical"]?.status === 1
                      ? "asc"
                      : sortBy?.["next_medical"]?.status === 2
                      ? "desc"
                      : ""
                  }
                  onClick={() => doSort("next_medical", "date")}
                >
                  Następne badanie lekarskie
                </th>
                <th
                  className={
                    sortBy?.["next_bhp"]?.status === 1
                      ? "asc"
                      : sortBy?.["next_bhp"]?.status === 2
                      ? "desc"
                      : ""
                  }
                  onClick={() => doSort("next_bhp", "date")}
                >
                  Następne szkolenie BHP
                </th>
                <th
                  className={
                    sortBy?.["next_sanepid"]?.status === 1
                      ? "asc"
                      : sortBy?.["next_sanepid"]?.status === 2
                      ? "desc"
                      : ""
                  }
                  onClick={() => doSort("next_sanepid", "date")}
                >
                  Następne badanie sanepid
                </th>
              </tr>
            </thead>
            <tbody>
              {!sortBy?.length && !sorted?.length ? (
                <div className="info">Brak wyników</div>
              ) : (
                (sortBy ? manualSort : sorted)?.map((item: any) => (
                  <tr
                    style={
                      item.contract_until ||
                      item.next_medical ||
                      item.next_bhp ||
                      item.next_sanepid
                        ? {}
                        : { opacity: 0.53 }
                    }
                  >
                    <td>
                      {item.location === 1 ? (
                        <span style={{ color: "green" }}>biuro</span>
                      ) : item.location === 0 ? (
                        <span style={{ color: "orangered" }}>hotel</span>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      <Link
                        to={
                          "/employee-files" +
                          (item?.location === 1 ? "/biuro/" : "/hotel/") +
                          item?.employee_id
                        }
                      >
                        {item.first_name} {item.last_name}
                      </Link>
                    </td>
                    <td>
                      <div className="grid-2">
                        {simpleDate(item.contract_until, {}, "-")}
                        <span className="timeUntil">
                          {timeUntil(item.contract_until)}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="grid-2">
                        {simpleDate(item.next_medical, {}, "-")}{" "}
                        <span className="timeUntil">
                          {timeUntil(item.next_medical)}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="grid-2">
                        {simpleDate(item.next_bhp, {}, "-")}{" "}
                        <span className="timeUntil">
                          {timeUntil(item.next_bhp)}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div>
                        {simpleDate(item.next_sanepid, {}, "-")}{" "}
                        <span className="timeUntil">
                          {timeUntil(item.next_sanepid)}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
