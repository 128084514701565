import { useContext } from "react";
import Day from "./Day";
import openEditor from "./openEditor";
import { CalendarContext } from "../contexts/CalendarContext";
import { ViewContext } from "../contexts/ViewContext";
import Search from "./Search";
import useEventViewItems from "./useEventViewItems";
import EventQuickView from "./EventQuickView";
import { inflect } from "../functions/tools";

interface dateObj {
  obj: Date;
  events: any;
  holiday?: any;
}

export default function CalendarTable({ cal }: { cal: any }) {
  const calendar = useContext(CalendarContext);
  const modal = useContext(ViewContext);

  function countEvents(dates: any) {
    return dates
      .map((d: any) => d?.events.length)
      .reduce((a: number, b: number) => a + b, 0);
  }

  const monthView = (
    <>
      <div className="day-list">
        {cal.dates.map((date: dateObj) => (
          <div
            key={date.obj.toISOString()}
            className={
              (date.events.length ? "events" : "") +
              (date.obj.getDay() === 0 || date.holiday === true
                ? " holiday"
                : "") +
              (date.obj.getDay() === 6 ? " saturday" : "")
            }
            // onDoubleClick={() => {
            //   openEditor(undefined, date, { modal: modal, calendar: calendar });
            // }}
            onClick={() => {
              const dayId = "d" + date.obj.getDate();
              const dayEl = document.getElementById(dayId);

              dayEl?.scrollIntoView({
                behavior: "smooth",
                inline: "start",
                block: "start",
              });
            }}
            //title={JSON.stringify(date)}
          >
            <div>{date.obj.getDate()}</div>
            <div
              className={
                "day-summary" + (date.obj.getDate() > 22 ? " right" : "")
              }
            >
              <div className="day-data">
                {date.obj.toLocaleDateString("pl-PL", { weekday: "long" })}
              </div>
              {date.events.map((event: any) => (
                <EventQuickView
                  key={event?.id + "_" + date?.obj.getDate()}
                  event={event}
                  date={date?.obj}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="calendar-list">
        <div className="calendar-sums">
          <div>
            {inflect(Number(countEvents(cal.dates)), [
              "wydarzeń",
              "wydarzenie",
              "wydarzenia",
              "wydarzeń",
            ])}
          </div>
          <div>
            {inflect(
              cal.dates
                .map((d: any) => (d?.events.length ? 1 : 0))
                .reduce((a: number, b: number) => a + b, 0),
              ["dni", "dzień", "dni", "dni"]
            )}{" "}
            z wydarzeniami
          </div>
        </div>
        <table className="data-table midi calendar-table-element">
          <thead>
            <tr>
              <th>Nr</th>
              <th>Rodzaj</th>
              <th>Sala</th>
              <th>Czas</th>
              <th>Stand/TV</th>
              <th>Klient</th>
              <th>Szczegóły</th>
              <th>Dzień</th>
              <th>Wpłaty</th>
              <th>Ozn.</th>
            </tr>
          </thead>
          <tbody>
            {cal.dates.map((date: dateObj) => (
              <Day key={date.obj.getDate()} date={date} list={true} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );

  return monthView;
}
