import copyIcon from "../../images/icons/copy.svg";
import viewIcon from "../../images/icons/eye.svg";

import deleteIcon from "../../images/icons/delete.svg";
import setActive from "../dataFetch/setActive";
import insertGeneric from "../dataFetch/insertGeneric";
import { swapElements } from "../functions/tools";
import { useContext } from "react";
import { EditableContext } from "./Editables";
import { createContext } from "react";

export const RowUnitContext = createContext({
  setUpdates: {} as any,
});

export default function Unit({
  classNames,
  data,
  setUpdates,
  children,
  index,
  order,
  setOrder,
  length,
  setOrderChanges,
  clipBoard,
  setActiveDB,
  deleteDB,
  style,
  kind,
}: {
  classNames: string;
  data: any;
  setUpdates: Function;
  children: JSX.Element | JSX.Element[];
  index: number;
  order?: number[];
  setOrder?: Function;
  length?: number;
  setOrderChanges?: Function;
  clipBoard?: Function;
  setActiveDB?: string;
  deleteDB?: string;
  style?: React.CSSProperties;
  kind?: string;
}) {
  const currentIndex =
    order && order.findIndex((item: any) => item === data?.id);
  const { currentEditID } = useContext(EditableContext);
  const currentEditRow = parseInt(
    currentEditID?.replace(/([^0-9]*)-([0-9]*)/, "$2")
  );

  return (
    <RowUnitContext.Provider value={{ setUpdates: setUpdates }}>
      <div
        className={"entry-editor-unit" + (classNames ? " " + classNames : "")}
        style={{
          position: "relative",
          background:
            data?.active || data?.enabled ? "white" : "rgb(240,240,240)",
          color: data?.active || data?.enabled ? "black" : "grey",
          zIndex: currentEditRow === data?.id ? 2 : 1,
          ...style,
        }}
      >
        {clipBoard ? (
          <div onClick={() => clipBoard && clipBoard(data)}>
            <img src={copyIcon} width={14} />
          </div>
        ) : (
          <></>
        )}
        <div style={{ display: "flex" }}>
          <div>{index + 1}</div>
          {order &&
          setOrderChanges &&
          currentIndex !== undefined &&
          length !== undefined ? (
            <div className="index-pointers">
              <div
                style={{
                  opacity: currentIndex === 0 ? 0.2 : 0.9,
                }}
                onClick={() => {
                  if (currentIndex !== 0) {
                    swapElements(order, currentIndex, currentIndex - 1);
                    setOrderChanges((prev: number) => prev + 1);
                  }
                }}
              >
                ▲
              </div>
              <div
                style={{
                  opacity: currentIndex == length - 1 ? 0.2 : 0.9,
                }}
                onClick={() => {
                  if (currentIndex !== length - 1) {
                    swapElements(order, currentIndex, currentIndex + 1);
                    setOrderChanges((prev: number) => prev + 1);
                  }
                }}
              >
                ▼
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {children}
        {setActiveDB || deleteDB ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {setActiveDB ? (
              <img
                style={{
                  paddingInline: "6px",
                  opacity: data?.active ? 1 : 0.2,
                }}
                src={viewIcon}
                height={18}
                onClick={async () => {
                  await setActive(
                    data?.id,
                    String(data?.active == 0 ? 0 : 1),
                    setActiveDB
                  );
                  setUpdates((prev: any) => prev + 1);
                }}
              />
            ) : (
              <></>
            )}
            {deleteDB ? (
              <img
                style={{ paddingInline: "6px" }}
                src={deleteIcon}
                height={15}
                onClick={async () => {
                  const doIt = window.confirm(
                    "Czy na pewno chcesz usunąć tę pozycję?"
                  );
                  if (doIt) {
                    const r: any = await insertGeneric(deleteDB, {
                      id: data?.id,
                      kind: kind,
                    });
                    setUpdates((prev: any) => prev + 1);
                    console.log(r);
                    if (r?.response?.errorHasItems == true) {
                      window.alert(
                        "Nie można usunąć kategorii do której przypisane są pozycje."
                      );
                    } else {
                      window.setTimeout(
                        () =>
                          setOrderChanges &&
                          setOrderChanges((prev: number) => prev + 1),
                        500
                      );
                    }
                  }
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </RowUnitContext.Provider>
  );
}
