import insertGeneric from "../../../../dataFetch/insertGeneric";
import Form from "../../../../forms_new/Form";

export function schema(data: any, functions: any) {
  return {
    filters: [],
    form: (
      <Form
        name="sales-documents"
        showFunction={functions?.showFunction}
        data={data}
        submit={async (payload: any) => {
          if (payload?.id === "") {
            delete payload.id;
          }
          await insertGeneric("sales-documents", payload);
          await functions?.setUpdates((prev: number) => prev + 1);
          functions?.showFunction(false);
        }}
        // defaultSubTab="main"
        schema={{
          main: {
            name: "Edycja dokumentu sprzedaży",
            content: [
              [
                {
                  name: "id",
                  style: {
                    display: "none",
                  },
                  disabled: true,
                },
                {
                  name: "name",
                  label: "Nazwa dokumentu sprzedaży",
                  required: "Podaj nazwę dokumentu",
                },
              ],
            ],
          },
        }}
      />
    ),
  };
}
