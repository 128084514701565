import { Form, Formik } from "formik";
import FieldAndLabel from "../forms/FieldAndLabel";
import * as Yup from "yup";
import useGeneric from "../dataFetch/useGeneric";
import insertGeneric from "../dataFetch/insertGeneric";
import { useLocation } from "react-router-dom";
import { useState } from "react";

const signUpSchema = Yup.object().shape({
  username: Yup.string()
    .min(5, "Co najmniej 5 znaków")
    .required("Pole wymagane"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Co najmniej 8 znaków, 1 duża litera, 1 mała i 1 znak specjalny"
    )
    .required("Pole wymagane"),
  password2: Yup.string().oneOf(
    [Yup.ref("password"), ""],
    "Hasła muszą być takie same"
  ),
});

export default function Activate() {
  const activation_code = useLocation().pathname.split("/").at(-1);
  const [done, setDone] = useState(false);
  const which = useGeneric({
    name: "getUserByCode",
    limit: 99999,
    query: { activation_code: activation_code },
  });
  const userData = which && !which.loading ? which?.rows[0] : undefined;

  console.log(activation_code);

  return (
    <div className="editor" style={{ padding: "30px" }}>
      <div
        style={{
          margin: "70px auto",
          maxWidth: "400px",
          textAlign: "center",
          boxShadow: "0px -5px 15px -4px rgba(0, 0, 0, .2)",
          borderRadius: "10px",
          padding: "30px",
        }}
      >
        {!which.loading && which.rows.length === 0 ? (
          <div>
            <h1>Nie znaleziono użytkownika.</h1>
          </div>
        ) : userData && userData.blocked === 1 ? (
          <div>
            <h1>Użytkownik jest zablokowany.</h1>
            <p>Skontaktuj się z administratorem.</p>
          </div>
        ) : !userData ? (
          <></>
        ) : done || userData.activated === 1 ? (
          <div>
            <h1>Użytkownik aktywowany!</h1>
            <p>
              Przejdź do <a href="../">systemu</a>
            </p>
          </div>
        ) : (
          <>
            <h1>Aktywacja konta haPP AGIT</h1>
            <p>
              Witamy w systemie, {userData.first_name[0]}
              {userData.last_name[0]}!
            </p>
            <p>Wprowadź hasło do logowania.</p>
            <p>Możesz też zmienić nazwę użytkownika (login).</p>
            <Formik
              initialValues={{
                username: userData.username,
                password: "",
                password2: "",
              }}
              validationSchema={signUpSchema}
              onSubmit={(values) => {
                insertGeneric("update-credentials-by-code", {
                  username: values.username,
                  password: values.password,
                  activation_code: activation_code,
                  email: userData.email,
                });
                setDone(true);
              }}
            >
              <div style={{ maxWidth: "400px", textAlign: "left" }}>
                <Form>
                  <FieldAndLabel
                    label="Użytkownik"
                    type="text"
                    name="username"
                  />
                  <FieldAndLabel
                    label="Hasło"
                    type="password"
                    name="password"
                  />
                  <FieldAndLabel
                    label="Powtórz hasło"
                    type="password"
                    name="password2"
                  />
                  <button type="submit" className="button">
                    Aktywuj konto
                  </button>
                </Form>
              </div>
            </Formik>
          </>
        )}
      </div>
    </div>
  );
}
