import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function StateButton({
  text,
  text2,
  icon,
  icon2,
  stateSetter,
  refState = undefined,
  activeState = undefined
}: {
  text: string;
  text2?: string;
  icon: string | JSX.Element;
  icon2?: string | JSX.Element;
  stateSetter: any;
  refState?: any;
  activeState?: any;
}) {
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    if (refState !== undefined) {
      setToggled((prev: boolean) => !prev);
    }
  }, [refState]);

  return (
    <button onClick={stateSetter} className={"button withIcon" + (activeState !== undefined ? (refState === !activeState  ? " active" : "") : "")}>
      <span className="text-icon">{!toggled ? icon : icon2 ? icon2 : icon}</span>
      <div>
        <div className="active-text">
          {icon2 ? (
            <>
              <span style={toggled ? { opacity: ".25" } : {}}>{text}</span> |{" "}
              <span style={!toggled ? { opacity: ".25" } : {}}>{text2}</span>
            </>
          ) : (
            <span>{text}</span>
          )}
        </div>
      </div>
    </button>
  );
}
