import { useContext } from "react";
import { Link } from "react-router-dom";
import { HeaderContext } from "./ErrorHeader";

export default function HeaderButton({
  link,
  //activate,
  //active,
  id,
  text,
  icon
}: {
  link: string;
  //activate?: any;
  //active?: string;
  id: string;
  text: string | JSX.Element;
  icon?: string;
}) {

  const context = useContext(HeaderContext);
  
  return (
    <Link to={link}>
      <button
        onClick={() => context.activate(id)}
        className={"button" + (context.active === id ? " active" : "") + (icon ? " withIcon" : "")}
      >
        {icon ? <span className="text-icon"><i className={icon}/></span> : <></>}
        {text}
      </button>
    </Link>
  );
}
