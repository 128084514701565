export default function trailingZero(number: any, length: number = 2) {
  const n = String(number);
  if (n.length >= length) {
    return n;
  } else {
    let result = "";
    for (let i = 1; i < length; i++) {
      result += "0";
    }
    result += n;
    return result;
  }
}
