import BookingCell from "./BookingCell";

export default function BookingRowDate({
  row,
  date,
  previous,
  next
}: {
  row: any;
  date: Date;
  previous: any;
  next:any;
}) {
  function filterBookings(bookings: any, date: Date) {
    return bookings?.filter((b: any) => {
      const startDate = new Date(b?.data_od);
      startDate.setHours(0, 0, 0);
      const endDate = new Date(b?.data_do);
      endDate.setHours(23, 59, 59);
      return date >= startDate && date <= endDate;
    });
  }
  return (
    <div className="row-date">
      {filterBookings(row?.bookings, date)?.map((b: any) => (
        <BookingCell
          date={date}
          booking={b}
          previous={filterBookings(row?.bookings, previous)?.find(
            (f: any) => f?.gosc === b?.gosc
          )}
          next={filterBookings(row?.bookings, next)?.find(
            (f: any) => f?.gosc === b?.gosc
          )}
        />
      ))}
    </div>
  );
}
