import MenuMeal from "./MenuMeal";
import addIcon from "../../images/icons/add.svg";
import { useEffect, useState } from "react";
import { createGUID } from "../functions/tools";
import insertGeneric from "../dataFetch/insertGeneric";
import { updateOrder } from "../MenuEditor";

export default function MenuMealCategory({
  data,
  category,
  updates,
  setUpdates,
  menu_id,
  subset
}: {
  data: any;
  category: any;
  updates: number;
  setUpdates: Function;
  menu_id: number | undefined;
  subset: string;
}) {
  const [order, setOrder] = useState(data?.map((d: any) => d.id));

  const [orderChanges, setOrderChanges] = useState(0);

  let orderedData = data;
  //   const [orderedData, setOrderedData] = useState(data);

  useEffect(() => {
    if (orderChanges > 0) {
      const d: any = [];
      order.forEach((o: any) => {
        d.push(data?.find((item: any) => item?.id === o));
      });

      //   setOrderedData(d);

      updateOrder("update-menu-order", setUpdates, order);
    }
  }, [orderChanges]);

  //   useEffect(() => {

  //   }, [updates]);

  const length = data?.length;

  async function addItem() {
    await insertGeneric("add-restaurant-menu-item", {
      category: category?.id,
      menu_id: menu_id,
      drink: subset === "drinks" ? 1 : 0
    });
    await setUpdates((prev: number) => prev + 1);
    window.setTimeout(() => setOrder(data?.map((d: any) => d.id)), 500);
  }

  return (
    <>
      {/* <div>{JSON.stringify(order)}</div> */}
      <div className="meal-heading" id={category?.id}>
        {category?.name_pl}{" "}
        <img
          src={addIcon}
          onClick={async () => {
            addItem();
          }}
        />
      </div>
      <div className="entry-editor">
        <div className="entry-editor-unit heading meals-edit">
          <div></div>
          <div>-</div>
          <div>Nazwa PL</div>
          <div>Szczegóły PL</div>
          <div>Nazwa EN</div>
          <div>Szczegóły EN</div>
          <div>Ilość</div>
          <div>Jm</div>
          <div>Cena</div>
          <div>Wege</div>
          <div>Specj.</div>
          <div></div>
        </div>
        {data &&
          data?.map((m: any, index: number) => (
            <MenuMeal
              index={index}
              meal={m}
              setUpdates={setUpdates}
              order={order}
              setOrder={setOrder}
              setOrderChanges={setOrderChanges}
              length={length}
              key={m?.id}
            />
          ))}
      </div>
    </>
  );
}
