import { useContext, useEffect, useState } from "react";
import { URL } from "../paths";
import { ScheduleContext } from "../Schedules";

export default function ScheduleHour({
  employee,
  day,
  data,
  defaultData,
  scheduleID,
  end,
}: {
  employee: any;
  day: any;
  data: any;
  defaultData: any;
  scheduleID: any;
  end?: boolean;
}) {
  const [add, setAdd] = useState(0);
  const { addEntry, templateEdit } = useContext(ScheduleContext);

  // data, defaultData
  const [hours, setHours] = useState(
    data ? (end ? data.time2 : data.time) : undefined
  );

  const [defaultHours, setDefaultHours] = useState(
    defaultData ? (end ? defaultData.t2 : defaultData.t1) : undefined
  );
  
  useEffect(() => {
    setHours(data ? (end ? data.time2 : data.time) : undefined);
  },[data]);

  useEffect(() => {
    if (add > 0) {
      const t = window.setTimeout(
        () =>
          templateEdit &&
          (!defaultHours.length || (defaultHours && defaultHours.length > 4))
            ? addEntry(
                {
                  id:
                    defaultData && defaultData.id ? defaultData.id : undefined,
                  scheduleID: scheduleID,
                  employeeID: employee.id,
                  weekday: day.getDay(),
                  time: defaultHours,
                  end: end,
                },
                true
              )
            : !hours.length || (hours && hours.length > 4)
            ? addEntry({
                id: data && data.id ? data.id : undefined,
                scheduleID: scheduleID,
                employeeID: employee.id,
                date: day,
                time: hours,
                end: end,
              })
            : undefined,
        600
      );

      return () => clearTimeout(t);
    }
  }, [add]);

  return (
    <div
      className={
        "hourEditor" +
        (!templateEdit && !hours && defaultHours ? " default" : "")
      }
    >
      <>
        {templateEdit ? (
          <input
            title={day.toLocaleDateString()}
            style={{ color: "brown" }}
            value={defaultHours ? defaultHours : ""}
            step={60}
            onChange={(e) => {
              setDefaultHours(e.target.value);
              setAdd((prev: number) => prev + 1);
            }}
            onKeyUp={(e) => {
              if (e.key === "Escape") {
                e.preventDefault();
                if (defaultHours) {
                  setDefaultHours("");
                  setAdd((prev: number) => prev + 1);
                }
              }
            }}
            type="time"
          />
        ) : (
          <input
            title={day.toLocaleDateString()}
            value={hours ? hours : defaultHours ? defaultHours : ""}
            step={60}
            onChange={(e) => {
              setHours(e.target.value);
              setAdd((prev: number) => prev + 1);
            }}
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (!hours) {
                  setHours(defaultHours);
                  setAdd((prev: number) => prev + 1);
                }
              } else if (e.key === "Escape") {
                e.preventDefault();
                if (hours) {
                  setHours("");
                  setAdd((prev: number) => prev + 1);
                }
              }
            }}
            type="time"
          />
        )}

        {/*<div
          className="default"
          onClick={() => {
            setHours(defaulTime);
            setAdd((prev: number) => prev + 1);
          }}
        >
          ◁
        </div>*/}

        {/*
          <div
            className="default revert"
            style={{ color: "red" }}
            onClick={() => {
              setHours("");
              setAdd((prev: number) => prev + 1);
            }}
          >
            ✖
          </div>*/}
      </>

      {/*edited ? (
          <button onClick={() => setEdited(false)} className="acceptor">
            ✓
          </button>
        ) : (
          <></>
        )*/}
    </div>
  );
}
