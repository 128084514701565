export default async function save(
  data: any,
  submit: Function | undefined,
  sideEffects: Function | undefined,
  setApiError: Function | undefined,
  tempID: Number
) {
  //console.log(data);

  // make clones undefined:
  Object.keys(data).forEach((key) =>
    key.match("-clone") ? (data[key] = undefined) : null
  );
  const result = submit ? await submit(data, tempID) : undefined;
  console.log(result);
  
  if (result) {
    sideEffects && sideEffects();
  } else {
    setApiError && setApiError(true);
  }
}
