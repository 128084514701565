import React from "react";
import { Form, Formik, useFormikContext } from "formik";
import { useEffect } from "react";
import FieldAndLabel from "./FieldAndLabel";

interface args {
  valueData: any;
  setter: any;
}

export default function Search({ valueData, setter }: args) {
  // const { values, submitForm } = useFormikContext() as { values: any, submitForm: any};
  let initials: any = {};
  valueData.forEach((val: any) => {
    initials[val.name] = val.value;
  });

  /* useEffect(() => {
    if (values.filter((v:any) => v.length > 3)) {
        submitForm();
    }
  }, [values])*/

  return (
    <div className="search">
    <Formik
      initialValues={initials}
      enableReinitialize={true}
      onSubmit={(values) => setter(values)}
    >
      <Form className="searchForm">
        {valueData.map((val: any) => (
          <React.Fragment key={val.name}>
            <FieldAndLabel
              as={val.as ? val.as : "input"}
              optionData={val.optionData ? val.optionData : []}
              type="search"
              label={val.label}
              name={val.name}
            />
          </React.Fragment>
        ))}
        <button type="submit" className="button">
          Szukaj
        </button>
      </Form>
    </Formik>
    </div>
  );
}
