import { useContext } from "react";
import { RegisterViewContext } from "./View";
import sortASCicon from "../../images/icons/asc.svg";
import sortDESCicon from "../../images/icons/desc.svg";
import editIcon from "../../images/icons/pencil.png";
import eyeIcon from "../../images/icons/eye.svg";
import setActive from "../dataFetch/setActive";
import Options from "./Options";
import ViewCardItem from "./ViewCardItem";
import ViewItem from "./ViewItem";
import { ViewContext } from "../contexts/ViewContext";
export default function ViewCards() {
  const {
    rows,
    rowSchema,
    setOrderASC,
    setOrderColumn,
    orderASC,
    orderColumn,
    editor,
    setEditor,
    setEditData,
    setUpdates,
    source,
    auth,
  } = useContext(RegisterViewContext);
  const { setModal } = useContext(ViewContext);
  return (
    <div className="cards-layout">
      {rows && rows.length ? (
        rows?.map((row: any) => {
          return (
            <ViewItem
              schema={rowSchema(row, {
                setModal,
                auth,
                showFunction: setModal,
              })}
              row={row}
            >
              <ViewCardItem />
            </ViewItem>
          );
        })
      ) : (
        <></>
      )}
    </div>
  );
}
