import { useContext, useState } from "react";
import { addAlpha, colorShade } from "./definitions/functions";
import useEventViewItems from "./useEventViewItems";
import { ViewContext } from "../contexts/ViewContext";
import { CalendarContext } from "../contexts/CalendarContext";
import { UserContext } from "../contexts/UserContext";
import EventService from "./EventService";
import DepositIcon from "./DepositIcon";
import prettyDate from "../functions/prettyDate";
import { objectOrJSONparse } from "../functions/tools";
import AgendaGenerateButtons from "./AgendaGenerateButtons";
import AgendaEditDeleteButtons from "./AgendaEditDeleteButtons";
import AddPotentialEvent from "./AddPotentialEvent";

export default function EventInTable({
  event,
  editor,
  list,
  date,
  index,
  search,
  potential,
}: {
  event: any;
  editor: any;
  list?: Boolean;
  date: Date;
  index: number;
  search?: Boolean;
  potential?: Boolean;
}) {
  const modal = useContext(ViewContext);
  const calendar = useContext(CalendarContext);
  const user = useContext(UserContext);
  const { auth } = calendar;
  const view = useEventViewItems(event, date, potential);

  const [leftMouse, setLeftMouse] = useState(0);
  const [options, setOptions] = useState(false);

  let whichDayPotential = "";
  if (potential) {
    const dates = objectOrJSONparse(event?.dates);
    console.log(dates, date);
    whichDayPotential =
      dates && dates.length > 1
        ? dates
            .map((d: any) => d.date)
            .indexOf(date?.toISOString().slice(0, 10)) + 1
        : undefined;
  }
  
  return (
    <>
      <tr
        className={"event-table-row" + (event?.settled ? " settled" : "")}
        onContextMenu={(e: any) => {
          e.preventDefault();
          setLeftMouse(e?.clientX - 50);
          setOptions(true);
        }}
        onMouseLeave={(e: any) => {
          setTimeout(() => setOptions(false), 100);
        }}
        onDoubleClick={() =>
          auth?.edit
            ? potential
              ? modal.setModal({
                show: true,
                heading: "Edycja niepotwierdzonego wydarzenia",
                style: { width: "750px" },
                content: (
                  <AddPotentialEvent
                    auth={auth}
                    event={event}
                    date={date}
                    calendar={calendar}
                    setModal={modal.setModal}
                  />
                ),
              })
              : editor(
                  event,
                  { obj: date }, //{ obj: undefined },
                  { modal: modal, calendar: calendar },
                  auth
                )
            : ""
        }
      >
        <td style={{ width: "12px", padding: "5px 2px", fontSize: "10px" }}>
          {potential ? " " : index + 1}
        </td>
        {search ? (
          <td style={{ fontSize: "11px", width: "120px", textAlign: "left" }}>
            {prettyDate(date, "", true)}
          </td>
        ) : (
          <></>
        )}
        <td
          style={{
            width: "100px",
            textTransform: "uppercase",
            // fontSize: event?.settled ? "70%" : "80%",
            background:
              "linear-gradient(90deg," +
              addAlpha(colorShade(event?.color, 50), 0.55) +
              ", " +
              addAlpha(colorShade(event?.color, 50), 0.25) +
              ")",
          }}
        >
          {event?.event_string ? (
            event?.event_string?.replace(/impreza /, "")
          ) : (
            <></>
          )}
        </td>
        <td
          style={{
            width: "150px",
            fontSize: "70%",
            textTransform: "uppercase",
          }}
        >
          {potential ? event?.venue_string : view?.values?.venuesShort}
        </td>
        {potential ? (
          <td colSpan={2} style={{ background: "transparent" }}>
            <div
              style={{
                textTransform: "uppercase",
                fontSize: "11px",
                color: "grey",
                background: "white",
              }}
            >
              robocze
            </div>
          </td>
        ) : (
          <>
            <td style={{ width: search ? "150px" : "120px", fontSize: "70%" }}>
              {view?.jsx?.times}
            </td>
            <td
              style={{
                textAlign: "left",
                fontStyle: "italic",
                color: "green",
                // width: "160px",
                fontSize: "70%",
              }}
            >
              <div className="nowrap" style={{ maxWidth: "150px" }}>
                {view?.values.standText}
              </div>
            </td>
          </>
        )}
        {potential ? (
          <td style={{ textAlign: "left", padding: "0 5px" }}>
            {event?.is_company
              ? event?.company_name
              : event?.first_name + " " + event?.last_name}
          </td>
        ) : (
          <td style={{ textAlign: "left", width: "30%" }}>
            {view?.jsx?.client}
          </td>
        )}
        {potential ? (
          <td colSpan={1} style={{ fontSize: "10px" }}>
            {event?.comment}
          </td>
        ) : (
          <td>
            <EventService view={view} abbr={true} auth={auth} />
          </td>
        )}
        <td style={{ width: "30px" }}>
          {potential ? (
            whichDayPotential
          ) : search ? (
            view?.values?.dates?.length
          ) : view?.values?.dayNumeral ? (
            <>{view?.values?.dayNumeral}.</>
          ) : (
            <></>
          )}
        </td>
        <td>
          <div>
            {Number(auth?.settlements) > 0 && view?.values?.depositPaid ? (
              <DepositIcon view={view} settled={event?.settled} />
            ) : (
              <></>
            )}
          </div>
        </td>
        <td>
          {event?.settled ? (
            <span className="settled" title="rozliczone">
              R
            </span>
          ) : (
            ""
          )}
        </td>
        <div
          className={"event-table-row-options" + (options ? " context" : "")}
          style={{ left: leftMouse - 50 }}
        >
          {!potential ? (
            <AgendaGenerateButtons
              event={event}
              date={date}
              modal={modal}
              view={view}
              auth={auth}
            />
          ) : (
            <></>
          )}
          <AgendaEditDeleteButtons
            auth={auth}
            editor={editor}
            modal={modal}
            event={event}
            date={date}
            // calendar={calendar}
            potential={potential}
          />
        </div>
      </tr>
    </>
  );
}
