import { useState } from "react";
import VoucherGroup from "./VoucherGroup";
import VoucherPDF from "./VoucherPDF";

export default function VoucherAddGroups({
  setGroups,
  items,
  setFinalize,
  groups,
  showPDF,
  setShowPDF,
}: {
  setGroups: Function;
  items: any;
  setFinalize: Function;
  groups: any;
  showPDF: Boolean;
  setShowPDF: Function;
}) {
  const defaultValidDate = new Date();
  defaultValidDate.setDate(defaultValidDate.getDate() + 30 * 6);
  defaultValidDate.setDate(defaultValidDate.getDate() + 30);
  defaultValidDate.setDate(0);
  return (
    <div className="voucher-layout">
      <div className="voucher-editor">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <button
            className="normal-button"
            style={{
              margin: "2px 8px",
              fontWeight: "bold",
              //   width: "200px",
            }}
            onClick={() => {
              setGroups((prev: any[]) => [
                ...prev,
                {
                  first: 1,
                  copies: 1,
                  amount: 100,
                  paid: 1,
                  valid_until: defaultValidDate.toISOString().slice(0, 10),
                  type: 0,
                  for_name: "",
                  ordered_by: 0,
                  ordering_client: "",
                  group_id: "",
                  comment: "",
                  status: 0,
                },
              ]);
            }}
          >
            + Dodaj grupę
          </button>
          <div>
            Suma: <strong>{items?.length}</strong> | Strony:{" "}
            <strong>{Math.ceil(items?.length / 3)}</strong>
          </div>
          <div>
            <button
              className={"normal-button" + (items?.length ? "" : " disabled")}
              style={{
                background: items?.length ? "lightgreen" : "whitesmoke",
                fontWeight: "bold",
                borderRadius: "6px",
                padding: "12px 16px",
                opacity: items?.length ? 1 : 0.13,
                //   width: "200px",
              }}
              onClick={() => {
                if (items?.length) {
                  setFinalize(true);
                }
              }}
            >
              WPISZ DO REJESTRU <span style={{ fontSize: "18px" }}>↝</span>
            </button>
          </div>
        </div>

        <div className="voucher-groups-editor">
          {!groups?.length ? (
            <div
              style={{
                padding: "12px",
                color: "grey",
                fontSize: "12px",
                marginTop: "12px",
              }}
            >
              Nie dodałeś jeszcze żadnej grupy voucherów.
            </div>
          ) : (
            groups?.map((g: any, index: number) =>
              g ? (
                <VoucherGroup
                  items={items}
                  group={g}
                  setGroups={setGroups}
                  groups={groups}
                  index={index}
                />
              ) : (
                <></>
              )
            )
          )}
        </div>
      </div>
      <div>
        {showPDF ? (
          <VoucherPDF items={items} />
        ) : (
          <div
            style={{
              padding: "24px",
            }}
          >
            <button
              className="normal-button huge"
              onClick={() => {
                setShowPDF(true);
              }}
            >
              Generuj PDF
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
