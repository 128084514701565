import { registerSchema } from "../schemaType";

export function schema(data: any, functions: any, auth: any): registerSchema {
  return {
    name: "lsi_balance_list",
    activeFilter: false,
    //   filters: [
    //       {
    //           column: "multiQuery",
    //           name:"Klient"
    //       }
    //   ],
    preSort:  data && data?.length &&
    data
      ?.sort((a: any, b: any) => new Date(b.data_od).getTime() - new Date(a.data_od).getTime()),
    defaultParams: {
      name: "lsi_balance_list",

      //   order: {
      //     by: "last_online",
      //     dir: "DESC",
      //   },
    },
  };
}
