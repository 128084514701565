import { useContext } from "react";
import HamsterLoading from "../common/HamsterLoading";
import useGeneric from "../dataFetch/useGeneric";
import prettyDate from "../functions/prettyDate";
import { objectOrJSONparse } from "../functions/tools";
import EditErrorEntry from "../tables/EditErrorEntry";
import { ViewContext } from "../contexts/ViewContext";
import { CalendarContext } from "../contexts/CalendarContext";
import openEditor from "./openEditor";
import viewIcon from "../../images/icons/eye.svg";
import AgendaView from "./AgendaView";

export default function CalendarHistory({ auth }: { auth: any }) {
  const modal = useContext(ViewContext);
  const calendar = useContext(CalendarContext);
  const dataFetch = useGeneric({ name: "event-calendar-history", limit: 200 });
  const preData = !dataFetch.loading && dataFetch.rows;
  const data =
    preData &&
    preData.filter((d: any) => d.edited_by != 1 && d.created_by != 1);

  return (
    <div className="calendar-list" style={{ padding: "20px" }}>
      {data && data.length ? (
        <table className="data-table midi">
          <thead style={{ top: "-20px" }}>
            <tr>
              <th>ID</th>
              <th>Użytkownik</th>
              <th>Data edycji</th>
              <th>Daty imprezy</th>
              <th>Rodzaj</th>
              <th>Klient</th>
              <th>Powód zmiany</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item: any) => {
              const dates = objectOrJSONparse(item.dates);
              return (
                <tr>
                  <td>{item.id}</td>
                  <td style={{ fontSize: "70%", width:"100px", color: "grey" }}>
                    {item.edited_f?.slice(0,1)}. {item.edited_l}
                  </td>
                  <td style={{ fontSize: "70%", width:"100px", color: "grey" }}>
                    {prettyDate(item.edited_time, "dziś")}
                  </td>
                  <td style={{padding:"0 9px"}}>
                    {dates.map((d: any) => (
                      <div>
                        {prettyDate(d.date, "", true, {
                          year: "always",
                          month: "numeric",
                        })}
                      </div>
                    ))}
                  </td>
                  <td style={{width:"200px"}}>{item.event_string}</td>
                  <td style={{padding:"0 9px", textAlign:"left"}}>
                    {item.is_company ? (
                      item.company_name
                    ) : (
                      <>
                        {item.first_name} {item.last_name}
                      </>
                    )}
                  </td>
                  <td style={{ fontSize: "70%"}}>
                    {item.reason ? (
                      <div style={{color:"black"}}>{item.reason}</div>
                    ) : (
                        <div style={{color:"green"}}>dodanie wpisu</div>
                    )}
                  </td>
                  <td
                    onClick={() => {
                      modal.setModal({
                        show: true,
                        heading: "Podgląd",
                        style: {
                          width: "95vw",
                        },
                        content: (
                          <AgendaView
                            id={item.id}
                            date={dates?.[0]?.date}
                            auth={auth}
                          />
                        ),
                      });
                    }}
                  >
                    <img src={viewIcon} width={12} style={{ opacity: ".7" }} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <HamsterLoading />
      )}
    </div>
  );
}
