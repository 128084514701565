import imageIcon from "../../images/icons/picture.svg";
import pdfIcon from "../../images/icons/pdf-file.svg";
import { useContext } from "react";
import { ViewContext } from "../contexts/ViewContext";
import Preview from "./Preview";
import { prettyFileSize } from "./FileUpload";
import { URLProd } from "../paths";

export default function FileListItem({ file }: { file: any }) {
  const { path, description } = file;
  const link = URLProd + "uploads/files/" + path;
  const { setModal2 } = useContext(ViewContext);
  const fileNameSoleWithExt = file.name.replace(/[^_]*_(.*)/, "$1");
  const fileNameSole = fileNameSoleWithExt.replace(/(\..*)(?!.*\.)/, "");

  function openPreview() {
    setModal2({
      show: true,
      style: { minWidth: "60vw" },
      heading: "Podgląd",
      content: (
        <Preview
          format={
            file.format.match(/image/)
              ? "image"
              : file.format.match(/pdf/)
              ? "pdf"
              : file.format.match(/audio/)
              ? "audio"
              : file.format.match(/video/)
              ? "video"
              : ""
          }
          data={file}
          link={link}
        />
      ),
    });
  }

  return (
    <div className="file-upload-list-item">
      <div className="file">
        <div>
          {/* <a target="_blank" href={link}> */}
          {file.format.match(/image/) || file.format.match(/pdf/) ? (
            <img
              onClick={openPreview}
              src={file.format.match(/image/) ? imageIcon : pdfIcon}
            />
          ) : (
            <span onClick={openPreview} style={{ fontSize: "8px" }}>
              {file?.format?.split("/").at(-1)}
            </span>
          )}
          {/* </a> */}
          <div className="file-names">
            <div className="file-description">
              <div>{file?.description || fileNameSole}</div>

              <div className="files-bottom-bar">
                <div className="options">
                  <div>
                    <a
                      target="_blank"
                      href={link}
                      download={fileNameSoleWithExt}
                    >
                      Zapisz
                    </a>
                  </div>
                </div>
                {/* <div className="file-name">{file.name}</div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="file-size" style={{ color: "grey" }}>
          {prettyFileSize(file.size)}
        </div>

        {/* {JSON.stringify(file)} */}
      </div>
    </div>
  );
}
