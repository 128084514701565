import { useContext, useEffect, useState } from "react";
import { formField, option, optionSingle } from "../formContentType";
import MultiAddTable from "./MultiAddElements/MultiAddTable";
import { useFormContext } from "react-hook-form";
import { FormDataContext } from "../Form";
import {
  addGUIDtoArray,
  addGUIDtoObject,
  createGUID,
  flatOptions,
  inflect,
  objectOrJSONparse,
} from "../../functions/tools";
import prettyNumber from "../../functions/prettyNumber";
import { ViewContext } from "../../contexts/ViewContext";
import templateIcon from "../../../images/icons/add-template.svg";
import MultiAddTemplateAdder from "./MultiAddTemplateAdder";

export default function MultiAddField({
  schema,
  hidden,
  parent,
  subData,
  setSubData,
  initialValues,
  readOnly,
  uniqueID,
}: {
  schema: formField;
  hidden?: Boolean;
  parent?: any;
  subData?: any;
  setSubData?: any;
  initialValues?: any;
  readOnly?: Boolean;
  uniqueID?: number;
}) {
  const { name, label, multiAddProps, className, hide, div } = schema;
  // if (name === "meals")  console.log(name, schema);
  const validate = schema?.validate;

  const [empty, setEmpty] = useState(false);

  const { modal2, setModal2 } = useContext(ViewContext);

  const uniqueFields = multiAddProps?.unique;

  // const isDiv = div !== undefined;

  const {
    setValue,
    getValues,
    formState: { errors, isSubmitted },
    setError,
    clearErrors,
  } = useFormContext();

  const { data, customFieldData, auth, setTouchedMultiAdds } =
    useContext(FormDataContext);

  const thisData: any = initialValues || data?.[name as keyof typeof data];

  const {
    add,
    fromProp,
    clear,
    foldable,
    reorderable,
    columns,
    columnsSum,
    columnsSumValue,
    multiEntries,
    maxLength = 200,
  } = multiAddProps || {
    add: undefined,
    fromProp: undefined,
    clear: undefined,
    foldable: undefined,
    reorderable: undefined,
    columns: undefined,
    columnsSum: undefined,
    columnsSumValue: undefined,
    multiEntries: undefined,
    maxLength: undefined,
  };

  // FOR NESTED MULTIADD FIELDS
  const nameSub = multiAddProps?.nameSub;

  const orderInCollection = div;

  const isDiv = orderInCollection !== undefined;

  const nameSubId =
    nameSub && Array.isArray(nameSub)
      ? parent[nameSub[0]]
        ? parent[nameSub[0]]
        : parent[nameSub[1]]
      : nameSub && parent[nameSub];
  const formPropName = nameSub ? name + "@" + nameSubId + "@" : name;

  const [usedUniqueOptions, setUsedUniqueOptions] = useState(() => {
    let u: any;
    uniqueFields?.forEach((field: string) => {
      if (typeof u !== "object") {
        u = {};
      }
      u[field] = [];
    });
    return u;
  });

  const [templateSelectVisible, setTemplateSelectVisible] = useState(false);
  const [entries, setEntries] = useState<any>(() => {
    let initialState = [];

    if (formPropName.match(/%/) && getValues(formPropName)) {
      initialState = JSON.parse(getValues(formPropName));
    } else if (parent && getValues(formPropName)) {
      // console.log(formPropName, parent);
      if (isDiv) {
        initialState = objectOrJSONparse(
          getValues(formPropName)?.[orderInCollection]
        );
      } else {
        initialState = JSON.parse(getValues(formPropName));
      }
    } else if (fromProp) {
      const init: any =
        initialValues || data?.[fromProp[0] as keyof typeof data];

      const initData = (init && JSON.parse(init)) || [];
      const sourceData = initData;

      const propBy = Array.isArray(fromProp[1]) ? fromProp[1][0] : fromProp[1]; /// e.g. "date"
      const propBy2 = Array.isArray(fromProp[1]) ? fromProp[1][1] : undefined;

      const thisSub = formPropName.match(/%/)
        ? formPropName.match(/%(.*)%/)?.[1]
        : ""; // %2023-12-13%

      if (sourceData && sourceData.length) {
        initialState = sourceData.filter((i: any) => i[propBy] === thisSub);
      }
      if (nameSub) {
        const allSubs = data?.[fromProp[0] as keyof typeof data];

        const propId = Number(formPropName.match(/@(.*)@/)?.[1]);
        const id = parent?.id;
        const guid = parent?.guid;

        // console.log(propId, id, guid);
        initialState = sourceData
          .filter(
            (i: any) =>
              i[propBy] === id ||
              (propBy2 && guid ? i[propBy2] === guid : false)
          )
          .map((i: any) => ({
            ...i,
            parent_id: i[propBy],
            parent_guid: propBy2 && i[propBy2],
          }));

        //console.log(formPropName, sourceData, initialState);
      }
    } else if (thisData && JSON.parse(thisData)) {
      initialState = addGUIDtoArray(JSON.parse(thisData));
    } else if (
      schema &&
      schema.subDefaults &&
      schema.subDefaults["_" + formPropName?.split("%")?.[0]]
    ) {
      initialState = JSON.parse(
        schema.subDefaults["_" + formPropName?.split("%")?.[0]]
      );
    } else if (typeof multiEntries === "function") {
      initialState = multiEntries(getValues());
    } else if (multiEntries) {
      initialState = addGUIDtoArray(multiEntries);
    }
    return initialState;
  });

  const [entriesBackup, setEntriesBackup] = useState();

  const [extended, setExtended] = useState(true);
  //console.log(thisData && JSON.parse(thisData));

  function validateMulti() {
    if (validate) {
      const v = validate(entries);
      if (v !== true) {
        setError(name, { type: "required", message: v });
      } else {
        clearErrors(name);
      }
    }
  }

  useEffect(() => {
    // push all the data into a virtual form field
    // console.log(name, JSON.stringify(entries));
    // console.log(formPropName, entries);

    // preserve other fields when multiple fields are present
    if (isDiv) {
      // console.log(getValues(formPropName));
      setValue(
        formPropName,
        getValues(formPropName)
          ? {
              ...objectOrJSONparse(getValues(formPropName)),
              [orderInCollection]: entries?.map((p: any) => ({
                ...p,
                parent_order: orderInCollection,
              })),
            }
          : {
              [orderInCollection]: entries?.map((p: any) => ({
                ...p,
                parent_order: orderInCollection,
              })),
            }
      );
    } else {
      // standard, non-multiple behaviour
      setValue(formPropName, JSON.stringify(entries));
    }
    validateMulti();

    // set virtual touched
    setTouchedMultiAdds((prev: any) => ({ ...prev, [formPropName]: true }));

    // update the unique options being used
    if (uniqueFields) {
      const keynames = Object.keys(usedUniqueOptions);

      const newUnique: any = {};
      keynames.forEach((key: any) => {
        const inUse = entries.map((entry: any) => Number(entry[key]) || "");
        newUnique[key] = inUse;
      });
      setUsedUniqueOptions(newUnique);
    }
  }, [JSON.stringify(entries)]);

  useEffect(() => {
    if (!isSubmitted) {
      clearErrors(name);
    } else {
      validateMulti();
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (getValues(formPropName)) {
      if (isDiv) {
        const partOfMultiple = objectOrJSONparse(getValues(formPropName))?.[
          orderInCollection
        ];
        const withOrder = partOfMultiple
          ? partOfMultiple.map((p: any) => ({
              ...p,
              parent_order: orderInCollection,
            }))
          : [];
        // console.log(div, partOfMultiple);
        if (partOfMultiple) {
          setEntries(partOfMultiple);
        } else {
          setEntries([]);
        }
      } else {
        setEntries(JSON.parse(getValues(formPropName)));
      }
    }
  }, [
    JSON.stringify(
      customFieldData[formPropName as keyof typeof customFieldData]
    ),
  ]);

  function showGuts() {
    const values = getValues(name) ? JSON.parse(getValues(name)) : entries;
    return values ? (
      <div>
        <div>{formPropName}</div>
        <div>
          {values.map((item: any) => (
            <div style={{ display: "flex" }}>
              {Object.keys(item).map((v: any) => (
                <div
                  style={{ display: "grid", gridTemplateColumns: "100px 1fr" }}
                >
                  <div>{v}</div>
                  <div style={{ fontWeight: "bold" }}>
                    {JSON.stringify(item[v])}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    ) : (
      <></>
    );
  }

  const isReadOnly = readOnly || schema?.disabled;
  if (schema?.name === "key_info") {
    // console.log(schema)
  }

  return empty ? (
    <></>
  ) : (
    <div
      style={{
        display: hidden || hide ? "none" : "block",
        order: orderInCollection,
      }}
      className={
        "multiAdd" +
        (multiAddProps?.mini ? " mini" : "") +
        (className ? " " + className : "")
      }
    >
      <div
        className="multiAdd-label"
        onClick={() => !extended && setExtended((prev: Boolean) => !prev)}
      >
      {/* {div !== undefined ? <div>{div}</div> : <></>} */}
        {schema?.labelOptions ? (
          <div>
            <select
              onChange={(e: any) => {
                setEntries((prev: any) =>
                  prev.map((p: any) => ({
                    ...p,
                    parent_category: e.target.value,
                  }))
                );
                // setRowValues((prev: any) => ({
                //   ...prev,
                //   parent_category: e.target.value,
                // }));
              }}
              value={entries?.[0]?.parent_category}
              style={{ textAlign: "left", textTransform: "uppercase" }}
            >
              {flatOptions(schema?.labelOptions).map((o: optionSingle) => (
                <option value={o?.id}>{o?.name}</option>
              ))}
            </select>
          </div>
        ) : (
          <label
            onClick={() =>
              foldable !== false &&
              extended &&
              setExtended((prev: Boolean) => !prev)
            }
            // htmlFor={formPropName}
            className={errors?.[formPropName] ? "error-label" : ""}
          >
            {foldable !== false ? (
              !extended ? (
                " + "
              ) : (
                <span
                  style={{
                    opacity: 0.4,
                    marginRight: "10px",
                    fontSize: "10px",
                  }}
                >
                  {"•"}
                </span>
              )
            ) : (
              <></>
            )}
            {schema.label}
          </label>
        )}
        {multiAddProps?.templates ? (
          <div className="template-zone">
            <label
              style={{ display: "flex", alignItems: "center" }}
              onClick={() => setTemplateSelectVisible((prev: Boolean) => !prev)}
            >
              <img
                style={{ width: "14px", marginRight: "5px" }}
                src={templateIcon}
              />
              <span>Szablony</span>
            </label>
            {templateSelectVisible ? (
              <select
                onChange={(e: any) => {
                  if (e.target.value != 0) {
                    setModal2({
                      heading:
                        "Szablon" +
                        (e.target.value ? " - " + e.target.value : ""),
                      content: (
                        <MultiAddTemplateAdder
                          template={multiAddProps?.templates?.[
                            e.target.value
                          ]?.map((t: any) => ({
                            ...t,
                            guid: createGUID(),
                            parent_field_name: schema?.name,
                            parent_order: orderInCollection,
                            parent_id: parent?.id,
                            parent_category: entries?.[0]?.parent_category,
                            schedule_guid: parent?.guid,
                            // parent_field_name: schema?.name,
                          }))}
                          setModal={setModal2}
                          setEntries={setEntries}
                          columns={columns}
                        />
                      ),
                      show: true,
                    });
                  }
                  setTemplateSelectVisible(false);
                }}
                style={{ width: "max-content", margin: "0 4px" }}
              >
                <option value={0}>wybierz</option>
                {Object.keys(multiAddProps?.templates).map(
                  (o: any, index: number) => (
                    <option key={o} value={o}>
                      {o}
                    </option>
                  )
                )}
              </select>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
        {isReadOnly ? (
          <span
            style={{
              fontSize: "9px",
              color: "grey",
              textTransform: "uppercase",
            }}
          >
            tylko podgląd
          </span>
        ) : (
          <></>
        )}
        <div className="multi-add-top-summary">
          {entries?.length ? (
            <>
              {inflect(
                entries.length,
                multiAddProps?.itemString
                  ? multiAddProps.itemString
                  : ["pozycji", "pozycja", "pozycje", "pozycji"]
              )}{" "}
              {columnsSumValue && columnsSumValue(entries) ? (
                <span style={{ fontWeight: "bold" }}>
                  {prettyNumber(
                    Math.round(columnsSumValue(entries) * 100) / 100
                  )}{" "}
                  zł
                </span>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>{multiAddProps?.help}</>
          )}
        </div>
        {!isReadOnly && add !== false && extended ? (
          <div className="multi-add-buttons">
            {nameSub ? (
              <button
                className="normal-button clear-button"
                onClick={(e) => {
                  e.preventDefault();
                  setEntriesBackup(entries);
                  setEntries([]);
                  setEmpty(true);
                }}
              >
                <>Usuń</>
              </button>
            ) : (
              <></>
            )}
            {!entriesBackup && entries && entries.length ? (
              auth.beta && clear !== false ? (
                <button
                  className="normal-button clear-button"
                  onClick={(e) => {
                    e.preventDefault();
                    setEntriesBackup(entries);
                    setEntries([]);
                  }}
                >
                  {multiAddProps?.mini ? <>-</> : <>Wyczyść</>}
                </button>
              ) : (
                <></>
              )
            ) : entriesBackup ? (
              <button
                className="normal-button clear-button"
                onClick={(e) => {
                  e.preventDefault();
                  setEntries(entriesBackup);
                  setEntriesBackup(undefined);
                }}
              >
                {multiAddProps?.mini ? <>-</> : <>Przywróć</>}
              </button>
            ) : (
              <></>
            )}
            {!isReadOnly && entries?.length < maxLength ? (
              <button
                className="normal-button strong"
                onClick={(e) => {
                  e.preventDefault();
                  if (columns) {
                    let entryValues: any = {};
                    if (typeof add === "function") {
                      entryValues = add(entries);
                    } else if (Array.isArray(add)) {
                      entryValues = add[0](entries);
                    } else {
                      Object.keys(columns).forEach(
                        (key: string) => (entryValues[key] = "")
                      );
                    }
                    setEntries((prev: any) => [
                      ...prev,
                      {
                        ...entryValues,
                        guid: createGUID(),
                        parent_id: parent?.id,
                        parent_category: entries?.[0]?.parent_category,
                        schedule_guid: parent?.guid,
                        parent_field_name: schema?.name,
                        // schloss: "sztos"
                      },
                    ]);
                  }
                  setTimeout(() => console.log(entries), 1300);
                }}
              >
                {multiAddProps?.mini ? (
                  <>+</>
                ) : Array.isArray(add) ? (
                  <>+ {add[1]}</>
                ) : (
                  <>+ DODAJ</>
                )}
              </button>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>

      <form
        className="multi-add-field-wrap"
        noValidate={true}
        style={!extended ? { display: "none" } : {}}
      >
        {entries && entries.length && columns ? (
          <MultiAddTable
            columns={columns}
            columnsSum={columnsSum}
            entries={entries}
            setEntries={setEntries}
            multiAddProps={multiAddProps}
            usedUniqueOptions={usedUniqueOptions}
            setUsedUniqueOptions={setUsedUniqueOptions}
            sub={formPropName}
            topSchema={{ ...schema, readOnly: isReadOnly }}
            uniqueID={uniqueID}
          />
        ) : (
          <div className="no-results">Brak wpisów</div>
        )}
      </form>
      {/* <div style={{ fontSize: "10px" }}>
        {getValues(formPropName) ? (
          <>
            <div>{objectOrJSONparse(getValues(formPropName))?.length}</div>
            <div>
              {JSON.stringify(objectOrJSONparse(getValues(formPropName)))}
            </div>
          </>
        ) : (
          <></>
        )}
      </div> */}
    </div>
  );
}
