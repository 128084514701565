import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import Login from "./components/login/Login";
import useLogged from "./components/login/useLogged";
import { URL } from "./components/paths";
import Main from "./components/Main";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import ErrorRegister from "./components/tables/ErrorRegister";
import Calendar from "./components/tables/Calendar";
import { UserContext } from "./components/contexts/UserContext";
import Header from "./Header";
import Modal from "./components/Modal";
import { modalProps, ViewContext } from "./components/contexts/ViewContext";
import Loading from "./components/common/Loading";
import Chat from "./components/tables/Chat";
import ChatApp from "./components/ChatApp";
import RegisterUser from "./RegisterUser";
import insertGeneric from "./components/dataFetch/insertGeneric";
import useGeneric from "./components/dataFetch/useGeneric";
import Users from "./components/Users";
import Procedures from "./components/Procedures";
import Tasks from "./Tasks";
import Info from "./components/common/Info";
import Attendance from "./components/Attendance";
import Activate from "./components/login/Activate";
import decodePermissions from "./components/common/decodePermissions";
import AttendanceReports from "./components/attendance/AttendanceReports";
import Schedules from "./components/Schedules";
import MedicalCertificates from "./components/MedicalCertificates";
import CalendarTV from "./components/calendar/CalendarTV";
import generateCalendarData from "./components/calendar/generateCalendarData";
import CalendarTVexposed from "./components/calendar/CalendarTVexposed";
import AgendaView from "./components/calendar/AgendaView";
import RoomsView from "./components/experiments/RoomsView";
import ProductsView from "./components/experiments/ProductsView";
import InvoicesView from "./components/experiments/InvoicesView";
import EmployeeFiles from "./components/EmployeeFiles";
import EventPaymentsView from "./components/experiments/EventPaymentsView";
import EventCreator from "./components/experiments/EventCreator";
import EmployeeFilesSummaries from "./components/EmployeeFilesSummaries";
import { pdfjs } from "react-pdf";
import SessionsLSI from "./components/SessionsLSI";
import Registers from "./components/registers/Registers";
import { objectOrJSONparse } from "./components/functions/tools";
import Vouchers from "./components/Vouchers";
import Collection from "./components/dataManager/Collection/Collection";
import DM_Employees from "./components/dataManager/DM_Employees";
import MenuEditor from "./components/MenuEditor";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function App() {
  const [logins, setLogins] = useState(0);
  const [onlineUpdates, setOnlineUpdates] = useState(0);
  const [temporary, setTemporary] = useState(false);
  const [pinned, setPinned] = useState(
    !window.localStorage.getItem("view-unpin-top")
      ? true
      : window.localStorage.getItem("view-unpin-top") === "false"
      ? false
      : true
  );
  // console.log(
  //   "unpin",
  //   !window.localStorage.getItem("view-unpin-top")
  //     ? true
  //     : !Boolean(window.localStorage.getItem("view-unpin-top")).valueOf()
  // );
  /*
  const [notificationsActive, setNotificationsActive] = useState(() =>
    localStorage.getItem("orderNotificationsEnable")
      ? localStorage.getItem("orderNotificationsEnable") === "true"
        ? trues
        : false
      : false
  );*/

  const [currentPage, setCurrentPage] = useState("home");

  const [modal, setModal] = useState({
    show: false,
    content: <></>,
    options: undefined,
    heading: "",
    updater: undefined,
    style: {},
    identity: undefined,
  } as modalProps);

  const [modal2, setModal2] = useState({
    show: false,
    content: <></>,
    options: undefined,
    heading: "",
    updater: undefined,
    style: {},
    identity: undefined,
  } as modalProps);

  const userData = useLogged(logins);
  const loggedIn = userData.accept;

  async function logOut() {
    const result = await fetch(URL + "backend/login/logout.php", {
      credentials: "include",
    });
    //const response = await result.json();
    setLogins((prev: number) => prev + 1);
  }

  const onlineUsers = useGeneric({
    name: "onlineUsers",
    limit: 40,
    offset: 0,
    update: onlineUpdates,
    onlyOnFocus: true,
  });

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("Powiadomienia nie są obsługiwane przez tę przeglądarkę.");
    } else {
      Notification.requestPermission();
    }
  }, []);

  // RECORD WHEN LAST ONLINE IN DB
  useEffect(() => {
    const location = window?.location?.pathname;
    if (!loggedIn) return;
    const online = setInterval(() => {
      setOnlineUpdates((prev) => prev + 1);
      if (!userData.loading)
        insertGeneric("online", {
          location: location,
          userID: userData.userID,
        });
    }, 4000);
    return () => clearInterval(online);
  }, [userData.loading, loggedIn]);

  const p = decodePermissions(userData.permissions);
  const authAll = userData?.auth && objectOrJSONparse(userData?.auth);
  const auth: any = authAll && objectOrJSONparse(authAll["modules"]);
  // console.log(auth);

  return (
    <BrowserRouter>
      <UserContext.Provider
        value={{
          loggedIn: loggedIn,
          username: userData.user,
          role: userData.type,
          name:
            userData.firstName && userData.lastName
              ? "" + userData.firstName + " " + userData.lastName
              : undefined,
          userID: userData.userID,
          userType: userData.userType,
          logOut: logOut,
          onlineUsers: !onlineUsers.loading ? onlineUsers.rows : undefined,
          permissions: p,
          auth: authAll,
          employeeID: userData.employeeID,
        }}
      >
        <ViewContext.Provider
          value={{
            modal: modal,
            setModal: setModal,
            modal2: modal2,
            setModal2: setModal2,
            currentPage: currentPage,
            setCurrentPage: setCurrentPage,
            pinned: pinned,
            setPinned: setPinned,
            setTemporary: setTemporary,
          }}
        >
          <div
            className={
              "App" +
              (currentPage ? " " + currentPage : "") +
              (!pinned ? " unpinned" : "") +
              (temporary ? " temporary" : "")
            }
          >
            {!userData.loading && !loggedIn ? (
              <Routes>
                <Route path="activate/*" element={<Activate />} />
                <Route path="calendar-tv/*" element={<CalendarTVexposed />} />
                <Route path="kitchen-tv/*" element={<AgendaView />} />
                <Route
                  path="*"
                  element={<Login set={setLogins} status={loggedIn}></Login>}
                />
              </Routes>
            ) : !userData.loading ? (
              <>
                <Header currentPage={currentPage} />
                <Routes>
                  <Route path="" element={<Main />} />
                  <Route path="activate/*" element={<Activate />} />
                  <Route
                    path="errors/*"
                    element={
                      p["ErrorRegister"] !== "0" ? (
                        <ErrorRegister />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  />
                  <Route
                    path="registers/*"
                    element={
                      auth?.["registers"] > 0 ? (
                        <Registers />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  />
                  <Route path="vouchers/*" element={<Vouchers />} />
                  <Route
                    path="schedules/*"
                    element={
                      auth?.["schedules"] > 0 ? (
                        <Schedules />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  />
                  {/* <Route
                    path="medical-certificates/*"
                    element={
                      p["Medical"] !== "0" ? (
                        <MedicalCertificates />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  /> */}
                  <Route
                    path="calendar"
                    element={
                      auth?.["calendar"] > 0 ? (
                        <Calendar />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  />
                  <Route
                    path="calendar-view-agenda"
                    element={
                      auth?.["calendar"] > 0 ? (
                        <AgendaView />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  >
                    <Route path=":id" element={<></>} />
                  </Route>
                  <Route
                    path="procedures"
                    element={
                      auth?.["procedures"] > 0 ? (
                        <Procedures />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  />
                  <Route path="tasks" element={<Tasks />} />

                  <Route
                    path="devs"
                    element={
                      <div className="devs">
                        <div>
                          {/* <Link to="../employee-files">Kadry</Link>
                          <Link to="../rooms-view/*">Status pokoi</Link> */}

                          <Link to="../event-payments">
                            Płatności za wydarzenia
                          </Link>
                          <Link to="../sessions-lsi">Sesje LSI</Link>
                        </div>
                      </div>
                    }
                  />
                  <Route
                    path="employee-files-summaries/*"
                    element={
                      auth?.["employee_files"] > 0 ? (
                        <EmployeeFilesSummaries />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  />
                  <Route
                    path="employee-files/*"
                    element={
                      auth?.["employee_files"] > 0 ? (
                        <EmployeeFiles />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  />
                  {/* <Route path="event-creator" element={<EventCreator />} /> */}
                  <Route
                    path="event-payments"
                    element={
                      auth?.["calendar"] > 0 ? <EventPaymentsView /> : <>F</>
                    }
                  />
                  <Route path="sessions-lsi" element={<SessionsLSI />} />
                  <Route
                    path="rooms-view/*"
                    element={
                      auth?.["rooms_view"] > 0 ? (
                        <RoomsView />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  />
                  <Route path="products-view" element={<ProductsView />} />
                  <Route path="invoices-view" element={<InvoicesView />} />
                  <Route
                    path="chat"
                    element={
                      auth?.["chat"] > 0 ? (
                        <ChatApp />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  />

                  <Route
                    path="users"
                    element={
                      auth?.["users"] > 0 ? (
                        <Users />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  />
                  <Route
                    path="register-user"
                    element={
                      auth?.["register_user"] > 0 ? (
                        <RegisterUser />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  />

                  <Route
                    path="attendance/*"
                    element={
                      auth?.["attendance"] > 0 ? (
                        <Attendance />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  />

                  <Route
                    path="attendance/requests/*"
                    element={
                      auth?.["attendance"] > 0 ? (
                        <Attendance />
                      ) : (
                        <Info type="unauthorised" />
                      )
                    }
                  />
                  <Route path="data-manager" element={<DM_Employees />} />
                  <Route path="menu-editor" element={<MenuEditor />} />
                </Routes>
                {modal.show ? (
                  <Modal
                    heading={modal.heading}
                    content={modal.content}
                    maximize={modal?.maximize}
                    show={setModal}
                    options={modal.options}
                    updater={modal?.updater}
                    style={modal.style}
                    identity={modal.identity}
                  />
                ) : (
                  <></>
                )}
                {modal2.show ? (
                  <Modal
                    heading={modal2.heading}
                    content={modal2.content}
                    show={setModal2}
                    options={modal2.options}
                    updater={modal2?.updater}
                    style={modal2.style}
                    identity={modal2.identity}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Loading />
            )}
          </div>
        </ViewContext.Provider>
      </UserContext.Provider>
    </BrowserRouter>
  );
}

export default App;
