import minutesToJSX, { minutesToString } from "../functions/minutesToJSX";
import {
  compareTimeStrings,
  flatOptions,
  minutesUntil,
  objectOrJSONparse,
  timePairProps,
} from "../functions/tools";
import { isSameDay } from "../schedules/ScheduleTable";
import { event_calendar } from "./event_calendar";
import venueIcon from "../../images/icons/room2.svg";
import { schedule_item_type } from "./definitions/common";

function niceTime(timeString: string) {
  const splitTime = timeString.split(":");
  let hours = splitTime?.[0];
  if (hours.charAt(0) === "0") {
    hours = hours.slice(1);
  }
  const minutes = splitTime?.[1];
  return (
    <>
      {hours}:
      <span style={{ display: "inline-block", fontSize: "100%" }}>
        {minutes}
      </span>
    </>
  );
}

export default function CalendarTVevent({
  event,
  date,
}: {
  event: event_calendar;
  date?: Date;
}) {
  const dates = event.dates ? JSON.parse(event.dates) : [];
  const schedules = event.schedules ? JSON.parse(event.schedules) : [];
  const todayData = dates && dates.find((i: any) => isSameDay(i.date, date));
  const meals = event.meals ? objectOrJSONparse(event.meals) : [];
  const todaySchedulesData =
    schedules &&
    schedules
      .filter((i: any) => isSameDay(i.date, date))
      .map((sch: any) => ({
        ...sch,
        meals: meals?.filter(
          (m: any) => m.schedule_guid === sch.guid || m.schedule_id === sch.id
        ),
      }));

  // .sort(
  //   (a: any, b: any) =>
  //     b?.next_day ? -1 : compareTimeStrings(a?.time_from, b?.time_from)
  // );
  const s = todaySchedulesData;
  
  const whichDay =
    dates && dates.findIndex((i: any) => isSameDay(i.date, date)) + 1;
  const minutesToGo = Math.min(
    ...todaySchedulesData
      .map((scheduleItem: any) =>
        Number(
          timePairProps(
            scheduleItem?.time_from,
            scheduleItem?.time_to,
            scheduleItem?.next_day,
            date
          ).minutesTo
        )
      )
      .filter((i: any) => i)
  );

  minutesUntil(todayData?.time_from);
  // const stillOn = minutesUntil(todayData.time_to) >= 0;
  const ongoing = todaySchedulesData
    .filter(
      (scheduleItem: any) =>
        timePairProps(
          scheduleItem?.time_from,
          scheduleItem?.time_to,
          scheduleItem?.next_day,
          date
        ).ongoing
    )
    .reduce((a: any, b: any) => a || b, false);
  // console.log(
  //   todaySchedulesData.map((scheduleItem: any) =>
  //     timePairProps(scheduleItem?.time_from, scheduleItem?.time_to)
  //   )
  // );
  const anonymised =
    event?.first_name && event?.last_name
      ? event?.first_name?.slice(0, 1) + "" + event?.last_name?.slice(0, 1)
      : "";

  const anyOngoing =
    todaySchedulesData.filter(
      (scheduleItem: any) =>
        timePairProps(
          scheduleItem?.time_from,
          scheduleItem?.time_to,
          scheduleItem?.next_day,
          date
        ).ongoing
    ).length > 0;

  const pastFar = !todaySchedulesData.filter((scheduleItem: any) => {
    const left: any = timePairProps(
      scheduleItem?.time_from,
      scheduleItem?.time_to,
      scheduleItem?.next_day,
      date
    ).minutesLeft;
    return left > -50; // Z TYM JEST COŚ NIE TAK
  });

  const past = !todaySchedulesData.filter((scheduleItem: any) => {
    const left: any = timePairProps(
      scheduleItem?.time_from,
      scheduleItem?.time_to,
      scheduleItem?.next_day,
      date
    ).minutesLeft;
    return left > 0; // Z TYM JEST COŚ NIE TAK
  }).length;

  console.log(pastFar);
  const lastTime = todaySchedulesData?.at(-1)?.time_to;

  return todayData?.["_tv_hide"] || (past && pastFar) ? (
    <></>
  ) : (
    <div
      className={
        "today-event" + (ongoing ? " ongoing" : "") + (past ? " past" : "")
      }
      style={{ order: past ? 2 : "unset" }}
    >
      {/* <div
        style={{
          position: "absolute",
          background: "white",
          rotate: "90deg",
          left: "-30%",
          top: "40%",
          padding: "20px",
          fontSize: "18px",
          opacity: "1",
          zIndex: 3,
        }}
      >
        {JSON.stringify(todaySchedulesData)}
      </div> */}
      <div className="details">
        <div className="two-columns">
          <div className="customer">
            {event.is_company !== 2 || todayData?._stand_text ? (
              <div>
                {todayData?._stand_text ? todayData?._stand_text : anonymised}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div
            className={
              "timeToGo" + (minutesToGo < 31 && !ongoing ? " very-soon" : "")
            }
          >
            {past ? (
              <>zakończone</>
            ) : minutesToGo > 0 && !ongoing ? (
              <>
                za{" "}
                {minutesToGo < 5 ? (
                  <>chwilę</>
                ) : (
                  <>{minutesToJSX(minutesToGo, true)}</>
                )}
              </>
            ) : ongoing ? (
              <div className="event-on">trwa</div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="today-event-type">
          {todayData?._stand_text?.replace(/ $/, "")?.toUpperCase() ===
          event?.event_string?.toUpperCase() ? (
            <>{event?.event_string}</>
          ) : (
            <>{event?.event_string}</>
          )}
          {dates.length > 1 ? (
            <span className="day-order">Dzień {whichDay}</span>
          ) : (
            <></>
          )}
        </div>
        <div className="venue-display">
          <div>
            {s
              .filter((scheduleItem: any) => {
                const ongoing = timePairProps(
                  scheduleItem?.time_from,
                  scheduleItem?.time_to,
                  scheduleItem?.next_day,
                  date
                ).ongoing;
                const toCome: any = timePairProps(
                  scheduleItem?.time_from,
                  scheduleItem?.time_to,
                  scheduleItem?.next_day,
                  date
                ).minutesTo;
                return (
                  ongoing ||
                  (!anyOngoing ? toCome > 0 && toCome < 150 : toCome > 0)
                );
              })
              .map((scheduleItem: any, index: number, allItems: any) => {
                const coffeeBreak = scheduleItem?.meals.find(
                  (m: any) => m.parent_category == 7
                );
                return (
                  <div className="venue-single">
                    <div className="two-columns">
                      {index > 0 ? (
                        <div
                          className="two-columns"
                          style={{ fontSize: "80%" }}
                        >
                          <div style={{ opacity: ".5" }}>
                            {allItems?.[index - 1]?.venue_string !==
                            scheduleItem?.venue_string ? (
                              <>
                                <span style={{ fontSize: "60%" }}>
                                  następnie:
                                </span>{" "}
                                {scheduleItem?.venue_string}
                                {coffeeBreak ? (
                                  <div
                                    className="meal"
                                    style={{ display: "inline-block" }}
                                  >
                                    {
                                      flatOptions(schedule_item_type)?.find(
                                        (t: any) =>
                                          t?.id == coffeeBreak?.parent_category
                                      )?.name
                                    }
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div style={{ opacity: ".5", fontSize: "90%" }}>
                            {niceTime(scheduleItem?.time_from)} -{" "}
                            {niceTime(scheduleItem?.time_to)}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="two-columns"
                          style={{
                            padding: "2px 12px",
                            borderRadius: "2px",
                            background: "rgba(255,255,255,.33)",
                            boxShadow: "0 6px 1px -5px rgba(0,0,0,.13)",
                            marginBottom: "4px",
                          }}
                        >
                          <div>
                            <div className="current-item">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img src={venueIcon} />{" "}
                                {scheduleItem?.venue_string}
                              </div>
                              <div>
                                {coffeeBreak ? (
                                  <div className="meal">
                                    {
                                      flatOptions(schedule_item_type)?.find(
                                        (t: any) =>
                                          t?.id == coffeeBreak?.parent_category
                                      )?.name
                                    }
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            {niceTime(scheduleItem?.time_from)} -{" "}
                            {niceTime(scheduleItem?.time_to)}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {/* <div>
        <div className="times">
          {todayData && (
            <>
              {past
                ? "~ " + lastTime
                : s.map((item: any, index: number, all: any) => {
                    const same =
                      all?.[index - 1]?.time_from?.slice(0, 5) ==
                        item?.time_from?.slice(0, 5) &&
                      all?.[index - 1]?.time_to?.slice(0, 5) ==
                        item?.time_to?.slice(0, 5);

                    const firstTwo = index < 2;

                    return same || !firstTwo ? (
                      <></>
                    ) : timePairProps(item?.time_from, item?.time_to)
                        .minutesLeft ? (
                      <div>
                        {item?.time_from?.slice(0, 5)} -{" "}
                        {item?.time_to?.slice(0, 5)}
                      </div>
                    ) : (
                      <></>
                    );
                  })}
            </>
          )}{" "}
        </div>
      </div> */}
    </div>
  );
}
