import { colorShade } from "./definitions/functions";
import useEventViewItems from "./useEventViewItems";
import guestIcons from "../../images/icons/people.svg";
import { ViewContext } from "../contexts/ViewContext";
import { useContext, useState } from "react";
import DepositIcon from "./DepositIcon";
import { CalendarContext } from "../contexts/CalendarContext";

export default function CalendarReportEvent({
  e,
  date,
  isMobile,
}: {
  e: any;
  date: any;
  isMobile: Boolean;
}) {
  const [mealSee, setMealSee] = useState(false);
  const event = useEventViewItems(e, new Date(date));
  const modal = useContext(ViewContext);
  const { auth } = useContext(CalendarContext);

  const color = e?.color ? e.color : "rgb(165,165,165)";
  const colorBright = color === "#d3d3d3" ? color : colorShade(color, 80);

  return (
    <div
      className="event-report-box"
      style={{
        borderLeft: (isMobile ? "12px solid" : "8px solid ") + colorBright,
      }}

      // onClick={() =>
      //   modal?.setModal({
      //     show: true,
      //     heading: "Podgląd wydarzenia",
      //     content: (
      //       <div className="big-event-view">
      //         <CalendarReportEvent e={e} date={date} />
      //       </div>
      //     ),
      //   })
      // }
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            padding: isMobile ? "7px 3px" : "3px",
            fontSize: isMobile ? "14px" : "11px",

            width: isMobile ? "140px" : "100px",
            background: colorBright,
            borderRadius: "0 0 8px 0",
            textTransform: "uppercase",
            // color:"white",
          }}
        >
          {e?.event_string === "impreza okolicznościowa"
            ? "impreza okol."
            : e?.event_string}
        </div>
        <div
          style={{
            textTransform: "uppercase",
            fontSize: isMobile ? "13px" : "small",
            // display: "flex",
            padding: "2px 7px",
            width: "120px",
            wordWrap: "break-word",
            // color: "rgb(200,50,50)",
          }}
        >
          {event?.values?.venues?.map((v: any) => (
            <div
              style={{
                paddingRight: "2px",
                fontSize: isMobile ? "13px" : "smaller",
                marginRight: "6px",
              }}
            >
              <span style={{ color: "rgb(180,180,180)" }}>•</span>{" "}
              {v?.replace(/sala /, "")}
            </div>
          ))}
        </div>
      </div>
      <div style={{ padding: "3px 5px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              color: "rgb(30,30,30)",
              fontSize: isMobile ? "large" : "smaller",
              marginBottom: "0px",
              fontStyle: "italic",
              fontWeight: "bold",
              marginRight: "12px",
            }}
          >
            {event?.jsx?.times}
          </div>
          <div>
            <div>
              {Number(auth?.settlements) > 0 && event?.values?.depositPaid ? (
                <DepositIcon view={event} settled={e?.settled} />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>{" "}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "60px",
          padding: "2px 5px",
        }}
      >
        <div
          style={{
            fontSize: isMobile
              ? "17px"
              : "smaller" /* maxHeight: "27px", overflow: "hidden" */,
          }}
        >
          {event?.values?.standText ? (
            <>
              <div>{event?.values?.standText}</div>
              <div
                style={{
                  fontSize: isMobile ? "11px" : "9px",
                  color: "grey",
                  marginTop: "2px",
                  lineBreak:"anywhere"
                }}
              >
                {event?.values?.client}
              </div>
            </>
          ) : (
            <>{event?.values?.client}</>
          )}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "4px",
            }}
          >
            <div
              style={{
                fontSize: "smaller",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <img src={guestIcons} style={{ width: "21px" }} />{" "} */}
              <div style={{ fontSize: "6px", color: "grey" }}>➤</div>
              <div
                style={{
                  fontSize: isMobile ? "24px" : "14px",
                  paddingLeft: "1px",
                  lineHeight: "8px",
                }}
              >
                <span style={{ paddingRight: "3px" }}>
                  {event?.jsx?.guest_number}
                </span>
                <span style={{ fontWeight: "normal", fontSize: "10px" }}>
                  os.
                </span>
              </div>{" "}
              {event?.values?.guest_number_child ? (
                <div style={{ fontSize: isMobile ? "small" : "x-small" }}>
                  +{event?.values?.guest_number_child} dz.
                </div>
              ) : (
                <></>
              )}
            </div>
            <div
              style={{
                fontSize: isMobile ? "13px" : "x-small",
                textAlign: "right",
                color: "rgb(230,230,230)",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {[
                { v: event?.values?.chefs, n: "Kch. " },
                { v: event?.values?.waiters, n: "Kln. " },
                { v: event?.values?.dishwashers, n: "Zmw. " },
              ]?.map((i: any, index: number) => (
                <>
                  <div
                    style={{
                      color: !i.v ? "rgb(200,200,200)" : "black",
                      textAlign: "center",
                      padding: isMobile ? "1px 5px" : "0",
                      boxShadow:
                        index !== 2 && i?.v
                          ? "9px 8px 2px -8px rgb(220,220,220)"
                          : "none",
                    }}
                  >
                    <div>{i.n}</div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: isMobile ? "16px" : "small",
                      }}
                    >
                      {i.v || "-"}
                    </div>
                  </div>{" "}
                </>
              ))}
            </div>
          </div>
          <div
            style={{
              fontSize: isMobile ? "15px" : "smaller",
              marginTop: isMobile ? "8px" : "2px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            {!event?.values?.musicMax ? (
              <span style={{ color: "rgb(150,150,150)", fontSize: "smaller" }}>
                {event?.jsx?.music}
              </span>
            ) : (
              <span>{event?.jsx?.music}</span>
            )}
            {event?.jsx?.get_dish_type() ? (
              <span
                style={{
                  fontSize: "10px",
                  padding: isMobile ? "12px 8px" : "3px 5px",
                  background: "rgb(238,238,238)",
                  textTransform: "uppercase",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setMealSee((prev: Boolean) => !prev);
                }}
              >
                {event?.jsx?.get_dish_type()}
              </span>
            ) : (
              <span
                style={{
                  color: "rgb(150,150,150)",
                  fontSize: "smaller",
                  padding: isMobile ? "4px 5px" : "1px 3px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setMealSee((prev: Boolean) => !prev);
                }}
              >
                brak posiłków
              </span>
            )}
          </div>
        </div>
      </div>
      {mealSee ? <div className="meals">{event?.jsx?.mealsList}</div> : <></>}
    </div>
  );
}
