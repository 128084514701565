export default function Value({
  label,
  data,
  dataClass,
  name,
  isEditing,
  editor,
}: {
  label: any;
  data: any;
  dataClass?: string;
  name?: string;
  isEditing: Boolean;
  editor: any;
}) {
  return (
    <div className="labeled-value">
      <label>{label}</label>
      <div className={!data ? "no-data" : dataClass || ""}>
        {isEditing ? (
          editor === "select" ? (
            <select></select>
          ) : (
            <input name={name} id={name} type={editor === "date" ? "date" : ""} value={data} />
          )
        ) : (
          data
        )}
      </div>
    </div>
  );
}
