import { useState } from "react";
import insertGeneric from "../dataFetch/insertGeneric";

export default function NewMenu({
  setModal,
  setUpdates,
}: {
  setModal: Function;
  setUpdates: Function;
}) {
  const [activeFrom, setActiveFrom] = useState(() => {
    const n = new Date();
    n.setDate(n.getDate() + 1);
    return n.toISOString()?.slice(0, 10);
  });
  const [enabled, setEnabled] = useState(false);
  const [name, setName] = useState("");
  const formData: any = new FormData();
  return (
    <div className="editor">
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          formData.append("name", name);
          formData.append("enabled", String(enabled));
          formData.append("active_from", activeFrom);

          const data = Object.fromEntries(formData.entries());

          const response: any = await insertGeneric("add-menu", data);
          console.log(response);
          if (response?.response?.result === "OK") {
            setModal({ show: false });
            setUpdates((prev: number) => prev + 1);
          }
        }}
      >
        <div className="field">
          <label htmlFor="name">Nazwa</label>
          <input
            id="name"
            type="text"
            required={true}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="field">
          <label htmlFor="active_from">Obowiązuje od</label>
          <input
            id="active_from"
            type="date"
            value={activeFrom}
            required={true}
            onChange={(e) => setActiveFrom(e.target.value)}
          />
        </div>
        <div className="field">
          <label htmlFor="enabled">Aktywne</label>
          <input
            id="enabled"
            type="checkbox"
            checked={enabled}
            onChange={(e) => setEnabled(e.target.checked)}
          />
        </div>
        <div className="field">
          <button type="submit">Zapisz</button>
        </div>
      </form>
    </div>
  );
}
