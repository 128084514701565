import { useContext } from "react";
import { ViewContext } from "../contexts/ViewContext";
import mailIcon from "../../images/icons/envelope.png";
import draftIcon from "../../images/icons/draft.svg";
import { CalendarContext } from "../contexts/CalendarContext";
import { UserContext } from "../contexts/UserContext";
import idToName, { dish_type, idToJSX, music_type } from "./definitions/common";
import waitersIcon from "../../images/icons/new/waiter.svg";
import chefsIcon from "../../images/icons/new/chef-cap.svg";
import dishWashIcon from "../../images/icons/new/washing.svg";
import musicIcon from "../../images/icons/new/music.svg";
import eatIcon from "../../images/icons/new/fork.svg";
import peopleIcon from "../../images/icons/people.svg";
import backHighlight from "../../images/back-highlight4.png";
import generateWord from "../docx-generator/generateWord";
import useEventViewItems from "./useEventViewItems";
import DepositIcon from "./DepositIcon";
import AgendaGenerateButtons from "./AgendaGenerateButtons";
import AgendaEditDeleteButtons from "./AgendaEditDeleteButtons";
import prettyNumber from "../functions/prettyNumber";
import { objectOrJSONparse } from "../functions/tools";
import EditErrorEntry from "../tables/EditErrorEntry";
import expandEventIcon from "../../images/icons/expand_event.svg";

export default function Event({
  event,
  editor,
  list,
  date,
  potential,
}: {
  event: any;
  editor: any;
  list?: Boolean;
  date: Date;
  potential?: Boolean;
}) {
  const modal = useContext(ViewContext);
  const calendar = useContext(CalendarContext);
  const user = useContext(UserContext);
  const { auth } = calendar;

  const view = useEventViewItems(event, date, potential);

  const isCreator = user.userID === event.created_by;
  const isCreatorActive = calendar.creatorHighlight;
  const isAdminOrCreator = user.role === 0 || isCreator;

  let whichDayPotential = "";
  if (potential) {
    const dates = objectOrJSONparse(event?.dates);

    whichDayPotential =
      dates && dates.length > 1
        ? dates
            .map((d: any) => d.date)
            .indexOf(date?.toISOString().slice(0, 10)) + 1
        : undefined;
  }

  return (
    <div
      className={
        "event" +
        (isCreatorActive ? (isCreator ? " creator" : " non-creator") : "") +
        (list ? " list-event" : "") +
        (view?.values?.depositPaid ? " deposit-paid" : "")
      }
      key={event.id}
      style={
        isCreatorActive
          ? isCreator
            ? {
                borderLeft: "5px solid " + event.color,
                borderRadius: "2px 8px 8px 2px",
              }
            : {}
          : {}
      }
    >
      <div className="eventCreator">{view?.values?.personInvolved}</div>
      <div className="eventTimes">
        {potential ? (
          <span style={{ fontSize: "10px" }}>WSTĘPNE</span>
        ) : (
          view?.jsx.times
        )}
      </div>

      <div title={event.details}>
        <div
          className="main"
          style={{
            background: event.color
              ? event.color
              : potential
              ? "transparent"
              : "grey",
            padding: "1px 3px",
            color: potential ? "#ab4100" : undefined,
            fontWeight: potential ? "bold" : undefined,
          }}
        >
          <div className="eventType">{event.event_string}</div>
        </div>
      </div>
      {Number(auth?.settlements) > 0 && view?.values?.depositPaid ? (
        <DepositIcon view={view} settled={event?.settled} />
      ) : (
        <></>
      )}
      {list ? (
        <div>
          <div
            className="details"
            style={{ display: "grid", gridTemplateColumns: "350px 1fr 200px" }}
          >
            <div
              className="eventClient"
              style={event?.settled ? { color: "green" } : {}}
            >
              {event.is_company == 1 ? (
                event.company_name
              ) : (
                <div>
                  {event.first_name} {event.last_name}
                </div>
              )}
            </div>
            <div className="eventRow">
              <div className="venue" style={{ fontWeight: "300" }}>
                {view?.values?.venues && view.values.venues.length ? (
                  <div style={{ display: "flex" }}>
                    {[...new Set(view.values.venues)].map(
                      (i: any, index: number) => (
                        <div
                          key={i + index}
                          style={{
                            margin: "0px 4px",
                            padding: "3px 10px",
                            fontWeight: "500",
                            fontSize: "17px",
                          }}
                        >
                          {i?.replace("sala", "")?.toUpperCase()}
                        </div>
                      )
                    )}
                  </div>
                ) : event?.venue?.string ? (
                  event?.venue_string?.toUpperCase()
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div
              className="framed-icon-group"
              style={{
                background: `url(${backHighlight})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <img width={35} src={peopleIcon} />
              <div>
                {Number(event.guest_number_adult) +
                  Number(event.guest_number_child)}
              </div>
            </div>
          </div>
          <div
            className="details"
            style={{
              display: "grid",
              gridTemplateColumns: "350px 1fr 200px",
              fontSize: "13px",
            }}
          >
            <div>
              <div>
                <img width={12} style={{ marginRight: "4px" }} src={eatIcon} />{" "}
                {idToName(dish_type, event.dish_type)}
              </div>
              <div>
                <img
                  width={11}
                  style={{ marginRight: "4px" }}
                  src={musicIcon}
                />{" "}
                {idToJSX(music_type, event.music_type)}
              </div>
            </div>
            {/* <div className="eventDetails">{event.details}</div> */}
            <div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="framed-icon-group" title="Kelnerzy">
                  <img className="framed-icon" src={waitersIcon} width={20} />{" "}
                  <div>{event.waiters}</div>
                </div>

                <div className="framed-icon-group" title="Kucharze">
                  <img className="framed-icon" src={chefsIcon} width={20} />
                  <div>{event.chefs}</div>
                </div>
                <div className="framed-icon-group" title="Na zmywaku">
                  <img className="framed-icon" src={dishWashIcon} width={20} />{" "}
                  <div>{event.dishwashers}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="details">
          <div
            className="eventClient"
            style={event?.settled ? { color: "green" } : {}}
          >
            <div title={view?.values?.standText} style={{ display: "flex" }}>
              {potential ? (
                <img
                  src={draftIcon}
                  style={{ marginRight: "4px" }}
                  width={18}
                />
              ) : (
                <></>
              )}
              {event.is_company == 1 ? (
                <div>
                  <div className="event-company-name">{event.company_name}</div>
                  <div className="event-company-contact">
                    {event.first_name} {event.last_name}
                  </div>
                </div>
              ) : (
                <>
                  {event.first_name} {event.last_name}
                </>
              )}
            </div>
          </div>
          <div className="eventRow">
            <div className="venues">
              {view?.values?.venues && view.values.venues.length ? (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {[...new Set(view.values.venues)].map(
                    (i: any, index: number) => (
                      <div key={i + index} className="venue-individual">
                        {i?.replace("sala", "")?.toUpperCase()}
                      </div>
                    )
                  )}
                </div>
              ) : event?.venue?.string ? (
                event?.venue_string?.toUpperCase()
              ) : (
                <></>
              )}
            </div>
            <div className="advance">
              {event.advance_amount
                ? prettyNumber(event.advance_amount, {
                    round: true,
                    postfix: "zł",
                  })
                : ""}
            </div>
          </div>
          <div className="eventDetails">{event.details}</div>
        </div>
      )}
      {/*<div className="edit">?</div>*/}
      <div className="options-hover">
        <div className="more-options">
          {event.email ? (
            <a href={"mailto:" + event.email}>
              <img src={mailIcon} />
            </a>
          ) : (
            <></>
          )}
          <AgendaGenerateButtons
            event={event}
            date={date}
            modal={modal}
            view={view}
            auth={auth}
            potential={potential}
          />
        </div>
        <div>{"<"}</div>
        {/* <img
              src={eyeIcon}
              onClick={() =>
                modal.setModal({
                  show: true,
                  heading: "Szczegóły wydarzenia",
                  content: <EventFullView event={event} />,
                })
              }
            />*/}
        <AgendaEditDeleteButtons
          auth={auth}
          editor={editor}
          modal={modal}
          date={date}
          event={event}
          // calendar={calendar}
          potential={potential}
        />
      </div>
      {potential ? (
        <div
          style={{ fontSize: "10px", padding: "2px", color: "rgb(40,40,40)" }}
        >
          <div>
            {event?.comment ? (
              <div>{event?.comment?.slice(0, 100)}</div>
            ) : (
              <>Brak opisu</>
            )}
            <button
              className="normal-button"
              style={{ padding: "2px 10px", float: "right" }}
              onClick={() => {
                if (auth?.edit) {
                  editor(
                    { ...event, id: undefined, potential: true },
                    { obj: undefined },
                    {
                      modal: modal,
                      calendar: calendar,
                    },
                    auth
                  );
                }
              }}
            >
              <img src={expandEventIcon} width={20} />
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
