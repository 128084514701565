import React from "react";
import RowControls from "./RowControls";
import TablePagination from "./TablePagination";
import editIcon from "../../images/icons/pencil.png";
import { Link } from "react-router-dom";

export default function Table({
  view,
  rows,
  metaData,
  offset,
  setOffset,
  references,
  heading,
  action,
  editor,
}: {
  view: any;
  rows: any;
  metaData?: any;
  offset?: number;
  setOffset?: any;
  references?: any;
  heading?: string;
  action?: any;
  editor?: any;
}) {
  //console.log(metaData);

  return rows.length ? (
    <>
      {metaData && metaData.count && setOffset ? (
        <TablePagination metaData={metaData} setOffset={setOffset} />
      ) : (
        <></>
      )}
      <table className="data-table">
        <thead>
          <tr>
            {view(rows[0], references).columns.map(
              (item: any, index: number) => (
                <th key={index} style={item?.styleHeading}>
                  {item.name}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {rows.map((item: any) => (
            <React.Fragment key={item.id}>
              <tr
                /*style={
                  item.resolved === "0" ? { background: "linear-gradient(100deg, #fcffad 0%, transparent 50%)" } : {}
                }*/
                className={view(item, references)?.classRef}
                key={item.id}
              >
                {view(item, references).columns.map((cell: any) => (
                  <td
                    key={cell.name}
                    className={cell?.className}
                    rowSpan={cell?.rowSpan}
                    onClick={() => 
                      action[cell.ref]
                        ? action[cell.ref](item.id, item.active)
                        : () => false
                    }
                    style={cell?.style}
                  >
                    {cell.jsx}
                  </td>
                ))}
                <td>
                  <div className="row-buttons">
                    {editor === "link" ? (
                      <Link
                        style={{
                          textDecoration: "none",
                          color: item.resolved === "0" ? "brown" : "black",
                          fontWeight: item.resolved === "0" ? "bold" : "normal",
                          padding: "10px",
                          margin: "-10px",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        to={`edit-entry/${item.id}`}
                      >
                        <img
                        style={{ width: "21px" }}
                        src={editIcon}
                      />
                      </Link>
                    ) : editor ? (
                      <img
                        onClick={() =>editor(item)}
                        style={{ width: "21px" }}
                        src={editIcon}
                      />
                    ) : <></>}
                  </div>
                </td>
              </tr>
              {view(item, references).secondRow ? (
                <RowControls
                  content={view(item, references).secondRow}
                  entry={item}
                  columnLength={view(item, references).columns.length}
                />
              ) : (
                <></>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </>
  ) : (
    <div className="empty-table">
      Brak wpisów zgodnych ze wskazanymi kryteriami.
    </div>
  );
}
