export function genericRow(row?: any): any[] {
  return [
    {
      source:"id",
      name: "ID",
      value: row?.id,
      className: "id",
    },

    {
      source:"name",
      name: "Nazwa",
      value: row?.name,
      style: {
        width:"100%"
      }
    },
  ];
}
