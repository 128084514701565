import { objectOrJSONparse } from "../functions/tools";
import { holidaysInMonth } from "./easter";

export default function generateCalendarData(
  date: { month: number; week?: number; year: number },
  data: any,
  potentialData?: any
) {
  const monthCorrected = new Date(date.year, date.month).getMonth();
  const holidays: any = holidaysInMonth(monthCorrected, date.year);

  const potential =
    potentialData && potentialData.length
      ? potentialData.map((p: any) => ({
          dates: [{ date: p.first_date }],
          ...p,
        }))
      : [];

  //console.log(holidays, monthCorrected, date.year);
  const startDate = new Date(date.year, date.month, 1);
  const endDate = new Date(date.year, date.month + 1, 0);
  const dates = [];
  const preDates = [];
  const postDates = [];
  let dates2months:any = [];
  const currentDate = new Date(startDate);
  let rangeStart = new Date();
  let rangeEnd = new Date();
  //const dataRows = !data.loading ? data.multiple["event-calendar"].rows : [];

  function addEvents(rows: any, first: Date) {
    function compare(dt: Date) {
      return (
        dt.getDate() === new Date(first).getDate() &&
        dt.getMonth() === new Date(first).getMonth() &&
        dt.getFullYear() === new Date(first).getFullYear()
      );
    }
    return rows.filter((i: any) => {
      const dates = i.dates && objectOrJSONparse(i.dates);
      //console.log(dates)
      return (
        dates &&
        dates
          .map((d: any) => compare(new Date(d.date)))
          .reduce((a: Boolean, b: Boolean) => a || b, false)
      );
    });
    /* return rows.filter((i: any) => {
      const eventDate = new Date(i.date);
      const eventEndDate = i.date_end ? new Date(i.date_end) : undefined;

      if (!eventEndDate || i.date === i.date_end) {
        return compare(eventDate);
      } else {
        eventDate.setHours(0, 0, 0, 0);
        eventEndDate.setHours(0, 0, 0, 0);
        const diffDays =
          (eventEndDate.getTime() - eventDate.getTime()) / 24 / 60 / 60 / 1000;
        //console.log(diffDays)
        const dt = new Date(eventDate);
        let datesToReturn: any = [];
        if (!diffDays) return false;
        for (let d = 0; d <= diffDays; d++) {
          dt.setDate(eventDate.getDate() + d);
          datesToReturn.push(compare(dt));
        }
       
        const dt2 = new Date(eventEndDate);
        for (let d = 0; d <= diffDays; d++) {
          dt2.setDate(eventEndDate.getDate() - d);
          datesToReturn.push(compare(dt2));
        }

       return datesToReturn.includes(true);
      }
    });*/
  }

  if (date.week !== undefined) {
    const newFirstDate = new Date(currentDate);
    const offset = newFirstDate.getDay() - 1; //newFirstDate.getDay() === 0 ? 6 : newFirstDate.getDay() - 1;
    newFirstDate.setDate(newFirstDate.getDate() - offset + (date.week - 1) * 7);
    rangeStart = new Date(newFirstDate);
    for (let i = 0; i < 7; i++) {
      dates.push({
        obj: new Date(newFirstDate),
        dw: new Date(newFirstDate).toLocaleDateString("pl-PL", {
          weekday: "long",
        }),
        events: !data.loading ? addEvents(data.rows, newFirstDate) : [],
        potentialEvents: potential ? addEvents(potential, newFirstDate) : [],
        holiday: holidays
          ? holidays.includes(new Date(newFirstDate).getDate())
          : false,
      });
      newFirstDate.setDate(newFirstDate.getDate() + 1);
    }
    rangeEnd = new Date(newFirstDate);
    rangeEnd.setDate(rangeEnd.getDate() - 1);
  } else {
    while (currentDate.getMonth() === startDate.getMonth()) {
      dates.push({
        obj: new Date(currentDate),
        string: currentDate.toDateString(),
        events: !data.loading ? addEvents(data.rows, currentDate) : [],
        potentialEvents: potential ? addEvents(potential, currentDate) : [],
        holiday: holidays
          ? holidays.includes(new Date(currentDate).getDate())
          : false,
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    //Add next month whole
    const nextCurrentDate = new Date(currentDate);
    dates2months = [...dates];
    while (nextCurrentDate.getMonth() === startDate.getMonth()+1) {
      dates2months.push({
        obj: new Date(nextCurrentDate),
        string: nextCurrentDate.toDateString(),
        events: !data.loading ? addEvents(data.rows, nextCurrentDate) : [],
        potentialEvents: potential ? addEvents(potential, nextCurrentDate) : [],
        holiday: holidays
          ? holidays.includes(new Date(nextCurrentDate).getDate())
          : false,
      });
      nextCurrentDate.setDate(nextCurrentDate.getDate() + 1);
    }
  }

  const currentPre = new Date(startDate);

  let preLimit = 0;
  if (startDate.getDay() === 0) {
    preLimit = 6;
  } else {
    preLimit = startDate.getDay() - 1;
  }
  for (let i = 0; i < preLimit; i++) {
    currentPre.setDate(currentPre.getDate() - 1);
    preDates.push({
      obj: new Date(currentPre),
      events: !data.loading ? addEvents(data.rows, currentPre) : [],
      potentialEvents: potential ? addEvents(potential, currentPre) : [],
    });
  }

  const currentPost = new Date(currentDate);

  let postLimit = 0;
  if (endDate.getDay() === 0) {
    postLimit = 0;
  } else {
    postLimit = 7 - endDate.getDay();
  }

  for (let i = 0; i < postLimit; i++) {
    postDates.push({
      obj: new Date(currentPost),
      events: !data.loading ? addEvents(data.rows, currentPost) : [],
      potentialEvents: potential ? addEvents(potential, currentPost) : [],
    });
    currentPost.setDate(currentPost.getDate() + 1);
  }

  return {
    month: startDate.toLocaleDateString("pl-PL", { month: "long" }),
    year: startDate.getFullYear(),
    dates: dates,
    range: [rangeStart, rangeEnd],
    monthStart: rangeStart.toLocaleDateString("pl-PL", { month: "short" }),
    monthEnd: rangeEnd.toLocaleDateString("pl-PL", { month: "short" }),
    preDates: preDates.reverse(),
    postDates: postDates,
    dates2months: dates2months,
  };
}
