import { useState, createContext, useContext } from "react";
import { Link } from "react-router-dom";
import Menu from "../common/Menu";
import { UserContext } from "../contexts/UserContext";
import HeaderButton from "./HeaderButton";

export const HeaderContext = createContext({ activate: null, active: "" } as {
  activate: any;
  active: string;
});

export default function ErrorHeader() {
  const [activeButton, setActiveButton] = useState("register");
  const user = useContext(UserContext);

  const menuCatalogs =
  // ONLY ADMIN
    user.role === 0
      ? [
          {
            link: "#",
            id: "listy-danych",
            icon: "gg-play-list-check",
            text: "Listy danych",
            sub: [
              {
                link: "/errors/problems",
                id: "problems",
                text: "Rodzaje problemów",
                icon: "gg-play-list-remove",
              },
              {
                link: "/errors/sales-documents",
                id: "sales",
                icon: "gg-copy",
                text: "Dokumenty sprzedaży",
              },
              {
                link: "/errors/reporting-forms",
                id: "reporting",
                icon: "gg-comment",
                text: "Formy zgłoszenia",
              },
              {
                link: "/errors/what-is-corrected",
                id: "corrected",
                icon: "gg-format-separator",
                text: "Co podlega korekcie",
              },
              {
                link: "/errors/correction-signed",
                id: "signed",
                icon: "gg-support",
                text: "Opcje korekty",
              },
              {
                link: "/errors/events",
                id: "events",
                icon: "gg-smile-mouth-open",
                text: "Imprezy",
              },
            ],
          },
          {
            link: "#",
            id: "kartoteki",
            text: "Kartoteki",
            icon: "gg-ereader",
            sub: [
              {
                link: "/errors/employees",
                id: "employees",
                icon: "gg-profile",
                text: "Pracownicy",
              },
              {
                link: "/errors/employee-groups",
                id: "employee-groups",
                icon: "gg-profile",
                text: "Grupy pracowników",
              },
              {
                link: "/errors/venues",
                id: "venues",
                icon: "gg-display-flex",
                text: "Sale",
              },
            ],
          },
          
        ]
      : [];

  return (
    <HeaderContext.Provider
      value={{ activate: setActiveButton, active: activeButton }}
    >
      <header className="mainHeader">
        <div className="errorMenu">
          <HeaderButton
            link="/errors/"
            id="errors"
            icon="gg-read"
            text="Rejestr błędów"
          />
          <HeaderButton
            link="/errors/payment-form-change/"
            icon="gg-credit-card"
            id="payment-form-change"
            text="Zmiana formy płatności"
          />
          <HeaderButton
            link="/errors/transfer-receipts/"
            icon="gg-euro"
            id="transfer-receipts"
            text="Paragony przelewowe"
          />
          <Menu style={{ display: "inline-block" }} data={[...menuCatalogs]} />
        </div>
      </header>
    </HeaderContext.Provider>
  );
}
