import compareStrings from "../functions/compareStrings";
import { flatOptions, looseCompare } from "../functions/tools";
import parse from 'html-react-parser';


export default function ChangeMultiCell({
  cell,
  columns,
  options,
}: {
  cell: any;
  columns: any;
  options: any;
}) {
  const cellSchema = columns?.[cell?.name];
  const optionsList =
    cellSchema?.optionsList && Array.isArray(cellSchema?.optionsList)
      ? cellSchema?.optionsList
      : cellSchema?.optionsList
      ? options?.[cellSchema?.optionsList]?.rows
      : undefined;
  //options?.[cellSchema?.optionsList];

  const cellV1 =
    optionsList && Array.isArray(optionsList)
      ? flatOptions(optionsList)?.find((option: any) => option?.id == cell?.v1)
          ?.name
      : cell?.v1;
  const cellV2 =
    optionsList && Array.isArray(optionsList)
      ? flatOptions(optionsList)?.find((option: any) => option?.id == cell?.v2)
          ?.name
      : cell?.v2;

  let textareaCompare:any = <></>;
  if (cellSchema?.element === "textarea") {
    textareaCompare = compareStrings(cell?.v1, cell?.v2);
  }

  return cellSchema?.element === "textarea" && cell?.v1 && cell?.v2  ? (
    <>{parse(textareaCompare)}</>
  ) : !looseCompare(cell?.v1, cell?.v2) ? (
    <div>
      <span
        style={
          !cell?.v2
            ? {
                fontWeight: "bold",
                color: "brown",
                textDecoration: "line-through",
              }
            : {
                display: "inline-block",
                background: "#f1ffb5",
                padding: "1px 5px",
                textDecoration: "line-through",
                textDecorationColor: "rgba(0,0,0,.3)",
                // textDecorationStyle:"wavy",
                borderRadius: "10px",
              }
        }
      >
        {cellV1}
      </span>{" "}
      {cell?.v1 !== undefined && cell?.v1 !== "" && cell?.v2 ? "> " : ""}
      {cell?.v2 ? (
        <span
          style={{
            display: "inline-block",
            background: "lightgreen",
            padding: "1px 5px",
            fontWeight: "bold",
            borderRadius: "10px",
          }}
        >
          {cellV2}
        </span>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <>
      {cellV1 ? (
        cellV1
      ) : (
        <span
          style={{
            display: "inline-block",
            // background: "whitesmoke",
            color: "grey",
            // padding: "1px 4px",
            // borderRadius: "10px",
            fontSize: "8px",
            fontFamily: "monospace",
          }}
        >
          -/-
        </span>
      )}
    </>
  );
}
