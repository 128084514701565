import useEventViewItems from "./useEventViewItems";

export default function EventQuickView({
  event,
  date,
}: {
  event: any;
  date: Date;
}) {
  const view = useEventViewItems(event, date);
  // console.log(date, view);
  return view ? (
    <div
      key={event.id}
      className="event-summary"
      title={
        (view.values.company || view.values.personOrContact) +
        "\r\n" +
        view.values.venues
      }
    >
      <div>
        {event.event_string}: {view.jsx.guest_number}{" "}
        <span style={{ fontSize: "9px" }}>os</span>.
      </div>
      <div>
        {view.values.timeStart} - {view.values.timeEnd}
      </div>
    </div>
  ) : (
    <></>
  );
}
