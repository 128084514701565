import { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import insertGeneric from "../dataFetch/insertGeneric";

export default function RequestList({
  requests,
  setSelectedPerson,
  setUpdate,
  setSmallUpdate,
  setSelectedEmployeeData,
  employees,
}: {
  requests: any;
  setSelectedPerson: Function;
  setUpdate: Function;
  setSmallUpdate: Function;
  setSelectedEmployeeData: Function;
  employees: any;
}) {
  const { name: userFullName, userID } = useContext(UserContext);
  return (
    <div className="requestList">
      {requests.map((r: any, index: number) => {
        const dateset = r.dateset.split(",");
        return (
          <div
            key={index}
            className={
              "requestItem" +
              (r.cancelled === 1 ? " cancelled" : "") +
              (r.approved === 1 ? " approved" : "") +
              (r.approved === 2 ? " rejected" : "")
            }
          >
            <div>
              <div
                onClick={() => {
                  setSelectedPerson(r.person);
                  const selPerson = employees.find(
                    (e: any) => e.cardname === r.person
                  );
                  if (selPerson) setSelectedEmployeeData(selPerson);
                  setUpdate((prev: number) => prev + 1);
                  setSmallUpdate((prev: number) => prev + 1);
                }}
                className="personName"
              >
                {r.person}
              </div>
              <div className="requestDates">
                <span>
                  {dateset[0] !== dateset.at(-1) &&
                  new Date(dateset[0]).getMonth() ===
                    new Date(dateset.at(-1)).getMonth()
                    ? new Date(dateset[0]).toLocaleDateString("pl-PL", {
                        day: "numeric",
                      })
                    : new Date(dateset[0]).toLocaleDateString("pl-PL", {
                        year: "numeric",
                        day: "numeric",
                        month: "long",
                      })}
                </span>
                {dateset[0] !== dateset.at(-1) ? (
                  <>
                    {" "}
                    -{" "}
                    <span>
                      {new Date(dateset.at(-1)).toLocaleDateString("pl-PL", {
                        year: "numeric",
                        day: "numeric",
                        month: "long",
                      })}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="requestForType">{r.typeshort}</div>
              <div className="requestApproval">
                {r.cancelled === 1
                  ? "PROŚBA O WYCOFANIE"
                  : r.approved === 1
                  ? "zatwierdzony"
                  : r.approved === 2
                  ? "odrzucony"
                  : "oczekujący"}
              </div>
            </div>
            <div className="requestDateTime">
              <div style={{ fontSize: "80%" }}>Przesłany:</div>
              <div>
                <span>
                  {new Date(r.sent).toLocaleDateString("pl-PL", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </span>
                {", "}
                <span>
                  {new Date(r.sent).toLocaleTimeString("pl-PL", {
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </span>
              </div>
              <div>
                <span>
                  {new Date(r.sent).toLocaleDateString("pl-PL", {
                    weekday: "long",
                  })}
                </span>
              </div>
            </div>
            <div className="requestDateTime">
              {r.approved === 1 ? (
                <>
                  <div style={{ fontSize: "11px" }}>
                    Akceptacja: <strong>{r.approvedPerson}</strong>
                  </div>
                  <div>
                    <span>
                      {new Date(r.approvedDate).toLocaleDateString("pl-PL", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                    </span>{", "}
                    <span>
                      {new Date(r.approvedDate).toLocaleTimeString("pl-PL", {
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </span>
                  </div>
                  <div>
                    {new Date(r.approvedDate).toLocaleDateString("pl-PL", {
                      weekday: "long",
                    })}
                  </div>
                </>
              ) : (
                <></>
              )}{" "}
            </div>
            <div className="requestButtons">
              {r.cancelled === 1 ? (
                <button
                  className="button revert"
                  onClick={() => {
                    insertGeneric("holiday-requests", {
                      active: 0,
                      id: r.id,
                    });
                    insertGeneric("holidays", {
                      multiple: true,
                      approval: true,
                      params: dateset.map((p: any) => ({
                        date: p,
                        type: r.type,
                        employeeID: r.employeeID,
                        status: 0,
                        approved: 0,
                        personApproved: userID,
                      })),
                    });
                    setUpdate((prev: number) => prev + 1);
                  }}
                >
                  Cofnij
                </button>
              ) : r.approved === 1 ? (
                <button
                  className="button revert"
                  onClick={() => {
                    insertGeneric("holiday-requests", {
                      approved: 0,
                      approvedPerson: userFullName,
                      id: r.id,
                    });
                    insertGeneric("holidays", {
                      multiple: true,
                      approval: true,
                      params: dateset.map((p: any) => ({
                        date: p,
                        type: r.type,
                        employeeID: r.employeeID,
                        status: 0,
                        approved: 0,
                        personApproved: userID,
                      })),
                    });
                    setUpdate((prev: number) => prev + 1);
                  }}
                >
                  Cofnij
                </button>
              ) : (
                <>
                  <button
                    className="button approve"
                    onClick={async () => {
                      insertGeneric("holiday-requests", {
                        approved: 1,
                        approvedPerson: userFullName,
                        id: r.id,
                      });
                     insertGeneric("holidays", {
                        multiple: true,
                        approval: true,
                        params: dateset.map((p: any) => ({
                          date: p,
                          type: r.type,
                          employeeID: r.employeeID,
                          status: 1,
                          approved: 1,
                          personApproved: userID,
                        })),
                      });
                       setUpdate((prev: number) => prev + 1);
                    }}
                  >
                    Zatwierdź
                  </button>
                  {r.approved !== 2 ? (
                    <button
                      className="button reject"
                      onClick={() => {
                        insertGeneric("holiday-requests", {
                          approved: 2,
                          approvedPerson: userFullName,
                          id: r.id,
                        });
                        insertGeneric("holidays", {
                          multiple: true,
                          approval: true,
                          params: dateset.map((p: any) => ({
                            date: p,
                            type: r.type,
                            employeeID: r.employeeID,
                            status: 0,
                            approved: 2,
                            personApproved: userID,
                          })),
                        });
                        setUpdate((prev: number) => prev + 1);
                      }}
                    >
                      Odrzuć
                    </button>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
            <div className="requestComment">{r.comment}</div>
          </div>
        );
      })}
    </div>
  );
}
