import { URL } from "../paths";

export default async function setResolved(id: string, resolved: string, name: string) {
  const response = await fetch(`${URL}backend/inserts/set-resolved.php`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      db: name,
      resolved: resolved === "0" ? "1" : "0",
      id: id
    }),
  });
  return await response.json();
}