import { useState } from "react";

export default function RequestCategory({
  requestList,
  name,
  count,
  defaultShow
}: {
  requestList: JSX.Element;
  name: string;
  count:number;
  defaultShow: boolean;
}) {
  const [show, setShow] = useState(defaultShow);
  return (
    <div className="requestCategory">
      <div className="requestCategoryHeader" onClick={() => setShow((prev: boolean) => !prev)}>
        {show ? "-" : "+"} {name} ({count})
      </div>
      <div style={{ display: show ? "block" : "none" }}>
        {requestList}
      </div>
    </div>
  );
}
