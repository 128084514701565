import insertGeneric from "../../../dataFetch/insertGeneric";
import Form from "../../../forms_new/Form";
import { registerSchema } from "../schemaType";

export function schema(data: any, functions: any): registerSchema {
  return {
    name: "employees",
    filters: [
      {
        column: "location",
        name: "Dział",
        element: "select",
        optionList: [
          {
            id: "0",
            name: "hotel",
          },
          { id: "1", name: "biuro" },
        ],
      },
      {
        column: "group",
        name: "Grupa",
        element: "select",
        getOptionsFrom: "employeeGroups",
      },
      {
        column: "name",
        name: "Imię i/lub nazwisko",
      },
    ],
    form: (
      <Form
        name="employees"
        className="form-clear"
        showFunction={functions?.showFunction}
        data={data}
        submit={async (payload: any) => {
          await insertGeneric("employees", payload);
          await functions?.setUpdates((prev: number) => prev + 1);
          functions?.showFunction(false);
        }}
        // defaultSubTab="main"
        schema={{
          main: {
            name: "Edycja danych pracownika",
            content: [
              [
                {
                  name: "id",
                  style: {
                    display: "none",
                  },
                  disabled: true,
                },
                {
                  name: "first_name",
                  label: "Imię",
                  element: "input",
                  format: "half-1",
                  required: "Uzupełnij imię",
                },
                {
                  name: "last_name",
                  label: "Nazwisko",
                  element: "input",
                  format: "half-2",
                  required: "Uzupełnij nazwisko",
                },
                {
                  name: "group",
                  label: "Grupa",
                  element: "select",
                  getOptionsFrom: "employeeGroups",
                  format: "half-1",
                  required: "Uzupełnij grupę",
                },
                {
                  name: "initials",
                  label: "Inicjały",
                  element: "input",
                  format: "quarter-3",
                  required: "Uzupełnij inicjały",
                },
                {
                  name: "sex",
                  label: "Płeć",
                  element: "select",
                  format: "quarter-4",
                  optionsList: [
                    { id: 0, name: "kobieta", active: true },
                    { id: 1, name: "mężczyzna", active: true },
                  ],
                  required: "Uzupełnij płeć",
                },
              ],
              [
                {
                  name: "cardname",
                  label: "Nazwa karty w rejestratorze",
                  element: "input",
                  required: "Uzupełnij nazwę karty",
                },
                {
                  name: "location",
                  label: "Dział",
                  element: "select",
                  format: "half-1",
                  required: "Uzupełnij dział",
                  optionsList: [
                    {
                      id: "0",
                      name: "hotel",
                    },
                    {
                      id: "1",
                      name: "biuro",
                    },
                  ],
                },
                {
                  name: "holiday_relevant",
                  label: "Urlop",
                  element: "select",
                  required: "Uzupełnij uprawnienie do urlopu",
                  optionsList: [
                    {
                      id: 0,
                      name: "nie",
                    },
                    {
                      id: 1,
                      name: "tak",
                    },
                  ],
                  format: "quarter-4",
                },
              ],
            ],
          },
        }}
      />
    ),
  };
}
