import { flatOptions } from "../../functions/tools";
import { schedule_item_type, special_options } from "../definitions/common";
import MealItem from "./MealItem";

export default function Meals({
  meals,
  scheduleItem,
  event,
}: {
  meals: any;
  scheduleItem: any;
  event: any;
}) {
  const relevantMeals = meals?.filter(
    (m: any) => m.schedule_guid === scheduleItem.guid
  );
  const snacksList: any = [];
  const mealsList: any = [];
  const drinksList: any = [];

  relevantMeals.forEach((meal: any) => {
    if (meal.parent_field_name === "meals") {
      mealsList.push(meal);
    } else if (meal.parent_field_name === "snacks") {
      snacksList.push(meal);
    } else if (meal.parent_field_name === "drinks") {
      drinksList.push(meal);
    }
  });

  return (
    <div className={"meals-all" + (snacksList?.length || drinksList?.length ? " two-col" : "")}>
      {snacksList?.length ? (
        <div className="snacks">
          <div className="heading">Przekąski</div>
          <div className="content">
            {snacksList?.map((item: any) => (
              <MealItem item={item} event={event} />
            ))}
          </div>
          <div style={{ marginTop: "10px" }} className="heading">
            Napoje
          </div>
          <div className="content">
            {drinksList?.map((item: any) => (
              <MealItem item={item} event={event} />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      {mealsList?.length ? (
        <div className="meals">
          <div className="heading">Posiłki</div>
          <div className="content">
            {mealsList?.map((item: any) => (
              <MealItem item={item} event={event} />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
