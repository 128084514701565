import BooleanSymbol from "../viewElements/BooleanSymbol";

export default function transferReceiptsView(item: any) {
  const columns = [
    {
      ref: "id",
      name: "Lp.",
      jsx: <>{item.id}</>,
      style: { width: "40px" },
    },
    {
      ref: "payer_name",
      name: "Imię i nazwisko wpłacającego",
      jsx: (
        <>
          {item.payer_first_name} {item.payer_last_name}
        </>
      ),
      style: { width: "340px", fontWeight: "bold" },
    },
    {
      ref: "payment_date",
      name: "Data wpłaty",
      jsx: <>{item.payment_date}</>,
    },
    {
      ref: "amount",
      name: "Kwota",
      jsx: <>{item.amount}</>,
    },
    {
      ref: "service_type",
      name: "Rodzaj imprezy",
      jsx: <>{item.service_name}</>,
    },
    {
      ref: "event_date",
      name: "Data imprezy",
      jsx: <>{item.service_performance_date}</>,
    },
    {
      ref: "receipt_number",
      name: "Nr paragonu",
      jsx: <>{item.receipt_number}</>,
    },
    {
      ref: "receipt_included",
      name: "Paragon załączony",
      jsx: (
        <>
          <BooleanSymbol value={item.attached} />
        </>
      ),
    },
    {
      ref: "status",
      name: "Status",
      jsx: <><BooleanSymbol value={item.status}/></>,
    },
  ];

  return {
    columns: columns,
    classRef: item.status === "1" ? "closed" : "open",
  };
}
