import { useEffect, useState } from "react";
import { URL } from "../paths";

export default function useLogged(update: number) {
  //const [loggedIn, setLoggedIn] = useState(false);
  const [userData, setUserData] = useState({
    accept: false,
    user: undefined,
    userID: undefined,
    userType: undefined,
    firstName: undefined,
    lastName: undefined,
    type: undefined,
    loading: true,
    permissions: undefined,
    auth: undefined,
    employeeID: undefined
  });

  async function check() {
    const response = await fetch(URL + "backend/login/check.php", {
      credentials: "include",
    });
     
    const result = await response.json();
    //setLoggedIn(result.accept);
    setUserData({loading:false, ...result});
  }

  useEffect(() => {
    check();
    const repeat = setInterval(() => check(), 12000);
    return () => clearInterval(repeat);
  }, [update]);

  
  return userData;
}
