import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { objectOrJSONparse } from "../functions/tools";
import RegistersTab from "./RegistersTab";
import genericIcon from "../../images/icons/generic-data.svg";

export default function RegistersTabs({
  sections,
  selected,
  setSelected,
  collapse,
  setCollapse,
}: {
  sections: any;
  selected: string | Boolean;
  setSelected: Function;
  collapse: Boolean;
  setCollapse: Function;
}) {
  const [openTab, setOpenTab] = useState();

  const [mobileShow, setMobileShow] = useState(false);
  const { auth: authAll } = useContext(UserContext);
  const auth = objectOrJSONparse(authAll?.["registers"]);

  const begin = !selected;

  useEffect(() => {
    if (openTab) {
      setCollapse(false);
    }
  }, [openTab]);

  return (
    <aside>
      <div className={"registers-menu" + (mobileShow ? " show-mobile" : "")}>
        <div
          className="mobile-collapse-button"
          onClick={() => setMobileShow((prev: Boolean) => !prev)}
        >
          ☰ REJESTRY DANYCH
        </div>
        <div
          className="collapse-button"
          style={{ minHeight: "22px" }}
          onClick={() => setCollapse((prev: Boolean) => !prev)}
        >
          <span className="section-text heading">
            <span className="side-arrow">◂</span> REJESTRY DANYCH
          </span>
          {collapse && !begin ? (
            <span
              className="side-arrow"
              // onClick={() => setCollapse((prev: Boolean) => !prev)}
            >
              ▸
            </span>
          ) : (
            <></>
          )}
        </div>
        {sections?.map((section: any) =>
          section?.sub ? (
            <>
              <div
                className={
                  "section" +
                  (openTab === section?.name || begin ? " section-open" : "")
                }
                onClick={() =>
                  setOpenTab(
                    (prev: any) => openTab !== section?.name && section?.name
                  )
                }
              >
                <div className="with-icon">
                  <img
                    src={section.icon || genericIcon}
                    title={section?.text}
                  />
                  <span className="section-text">{section.text}</span>
                </div>
                {/* ) : (
                   <span className="section-text">{section.text}</span>
                 )} */}

                <span>{openTab === section?.name ? <>▴</> : <>▾</>}</span>
              </div>
              {openTab === section?.name || begin ? (
                <div className="sub">
                  <div
                    className="residual-heading"
                    onClick={() =>
                      setOpenTab(
                        (prev: any) =>
                          openTab !== section?.name && section?.name
                      )
                    }
                  >
                    {/* <div>{"<"}</div> */}
                    <img src={section.icon} title={section?.text} />
                    <div>{section?.text}</div>
                  </div>
                  {section?.sub?.map((sub: any) => (
                    <RegistersTab
                      section={sub}
                      auth={auth?.[sub?.name]}
                      selected={selected}
                      setSelected={setSelected}
                      setMobileShow={setMobileShow}
                    />
                  ))}
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <RegistersTab
              section={section}
              auth={auth?.[section?.name]}
              selected={selected}
              setSelected={setSelected}
              setMobileShow={setMobileShow}
            />
          )
        )}
      </div>
    </aside>
  );
}
