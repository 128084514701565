import { useContext, useEffect, useState } from "react";
import { ViewContext } from "../contexts/ViewContext";
import eventDataToWord from "./eventDataToWord";
import useGeneric from "../dataFetch/useGeneric";
import useEventViewItems from "./useEventViewItems";
import { objectOrJSONparse } from "../functions/tools";
import prettyDate, { prettyDateRange } from "../functions/prettyDate";
import Meals from "./agendaView/Meals";
import ScheduleItem from "./agendaView/ScheduleItem";
import dateIcon from "../../images/icons/date.png";
import customerIcon from "../../images/icons/person.svg";
import DateItem from "./agendaView/DateItem";
import CalendarTVevent from "./CalendarTVevent";
import { isSameDay } from "../schedules/ScheduleTable";
import { key_info_types } from "./definitions/common";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import noJob from "../../images/icons/lazy.png";
import { setVersion, useVersion } from "../useVersion";
import editIcon from "../../images/icons/pencil.png";
import openEditor from "./openEditor";
import { CalendarContext } from "../contexts/CalendarContext";
import AgendaMultiView from "./AgendaMultiView";
import QRCode from "react-qr-code";
import EventQR from "./EventQR";

export default function AgendaView({
  id,
  date,
  auth,
}: {
  id?: number;
  date?: Date;
  auth?: any;
}) {
  const location = useLocation();
  const { modal, setModal } = useContext(ViewContext);
  const calendar = useContext(CalendarContext);

  const { id: urlIDandDate } = useParams();

  const urlID = urlIDandDate?.replace(/(.*)_.*/, "$1");
  const urlDate = urlIDandDate?.replace(/.*_(.*)/, "$1");
  console.log(urlIDandDate, urlID);
  const cID = Number(urlID) || id;

  const [multiView, setMultiView] = useState(false);

  const versioning = useVersion(1);
  const [finishedUpdate, setFinishedUpdate] = useState(false);

  async function doUpdate() {
    if (versioning && versioning?.doUpdate) {
      const result = await setVersion(1, versioning.current_version);
      if (result?.response?.response === "OK") {
        setFinishedUpdate(true);
        setTimeout(() => window.location.reload(), 5000); // 5s
      }
    }
  }

  const { setCurrentPage } = useContext(ViewContext);
  const [selectedID, setSelectedID] = useState(cID ? Number(cID) : undefined);
  const [updates, setUpdates] = useState(0);
  const [selectedDate, setSelectedDate] = useState(
    (urlDate && new Date(urlDate).toISOString().slice(0, 10)) ||
      (date
        ? new Date(date).toISOString().slice(0, 10)
        : new Date().toISOString().slice(0, 10))
  );

  const fetchEventList = useGeneric({
    name: "event-calendar-basic",
    limit: 100,
    query: {
      date: {
        value: selectedDate,
        db: "d",
      },
    },
    reload: true,
    secondsToReload: 120,
  });
  const fetchEvent = useGeneric({
    name: selectedID ? "event-calendar" : "",
    limit: 1,
    query: {
      id: selectedID,
    },
    update: selectedID,
    reload: true,
    secondsToReload: 45,
  });
  const eventList = !fetchEventList.loading && fetchEventList?.rows;
  const event = eventList && !fetchEvent.loading && fetchEvent?.rows?.[0];

  useEffect(() => {
    const defID =
      urlID ||
      (eventList && eventList?.find((e: any) => e.id === selectedID)?.id);
    if (!id || updates !== 0) {
      setSelectedID(defID || Number(eventList?.[0]?.id));
    }
  }, [JSON.stringify(eventList), updates]);

  // DATA TRANSLATE

  const { dates, schedules, meals, keyInfo, comments } = {
    dates: objectOrJSONparse(event.dates),
    schedules: objectOrJSONparse(event.schedules),
    meals: objectOrJSONparse(event.meals),
    keyInfo: objectOrJSONparse(event.key_info),
    comments: objectOrJSONparse(event.comments),
  };
  const datesSorted =
    dates &&
    dates?.sort(
      (a: any, b: any) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  const todayData =
    datesSorted &&
    datesSorted?.find((i: any) => isSameDay(i.date, selectedDate));
  const view = {
    dateRange: dates ? (
      <>
        {prettyDateRange(datesSorted.at(0).date, datesSorted.at(-1).date, {
          year: "numeric",
        })}
      </>
    ) : (
      <></>
    ),
    eventType: event.event_string,
    eventColor: event.color,
    customer: event.company_name
      ? event.company_name
      : event.first_name + " " + event.last_name,
  };
  setCurrentPage("agendaView");
  return multiView ? (
    <AgendaMultiView show={setMultiView} />
  ) : (
    <div className="agenda-view">
      {/* <h1>AGENDA</h1> */}

      <div className="event-choice">
        <div onClick={() => setMultiView(true)}>
          <img
            style={{
              width: "3vw",
              padding: "4px 12px",
              marginRight: "1vw",
              background: "rgb(230,230,230)",
              cursor: "pointer",
            }}
            src={dateIcon}
          />
        </div>
        <div className="event-choice-heading">
          <div
            className="controls"
            onClick={() => {
              setSelectedDate((prev: any) => {
                const n = new Date(prev);
                n.setDate(n.getDate() - 1);
                return n.toISOString().slice(0, 10);
              });
              setUpdates((prev: number) => prev + 1);
            }}
          >
            {"<"}
          </div>
          <div
            className="selected-date"
            onDoubleClick={() => {
              setSelectedDate(new Date().toISOString().slice(0, 10));
              setUpdates((prev: number) => prev + 1);
            }}
          >
            {
              new Date(selectedDate).toLocaleDateString("pl-PL", {
                month: "2-digit",
                day: "numeric",
                weekday: "short",
              }) /* {prettyDate(selectedDate, "", true)} */
            }
          </div>
          <div
            className="controls"
            onClick={() => {
              setSelectedDate((prev: any) => {
                const n = new Date(prev);
                n.setDate(n.getDate() + 1);
                return n.toISOString().slice(0, 10);
              });
              setUpdates((prev: number) => prev + 1);
            }}
          >
            {">"}
          </div>
        </div>
        <div className="event-choice-content">
          {eventList &&
            eventList?.map((item: any) => {
              const customer = item.company_name
                ? item.company_name
                : item.first_name + " " + item.last_name;
              return (
                <button
                  className={selectedID === item.id ? "active" : ""}
                  onClick={() => {
                    setSelectedID(item.id);
                  }}
                >
                  <div className="event-type">
                    {item.event_string && item.event_string.length > 12
                      ? item.event_string.slice(0, 12) + "..."
                      : item.event_string}
                  </div>
                  <div className="event-customer">
                    {customer?.length > 25
                      ? customer.slice(0, 25) + "..."
                      : customer}
                  </div>
                </button>
              );
            })}
        </div>
      </div>
      {event && selectedID ? (
        <div className="agenda-main-layout">
          <div className="heading">
            <div className="event-type" style={{ background: view.eventColor }}>
              {auth?.agenda === 2 ? (
                <div
                  className="edit-comments"
                  onClick={() => {
                    console.log(date, modal, calendar);
                    const m = { modal: modal, setModal: setModal };
                    openEditor(
                      event,
                      selectedDate,
                      { modal: m, calendar: calendar },
                      auth
                    );
                  }}
                >
                  <img src={editIcon} />
                </div>
              ) : (
                <></>
              )}
              {view.eventType}
            </div>
            <div className="dates">
              <img src={dateIcon} />
              <div>{view.dateRange}</div>
            </div>
            <div className="customer">
              <img src={customerIcon} />
              <div>{view.customer}</div>
            </div>
            {todayData ? (
              <div className="numbers">
                {/* <div className="numbers-heading">Dziś</div> */}
                <div className="numbers-content">
                  <div>
                    <div>Dorośli:</div>{" "}
                    <div>{todayData._guest_number_adult}</div>
                  </div>
                  <div
                    className={
                      !todayData._guest_number_child_0_3 ||
                      !todayData._guest_number_child_4_12
                        ? "inactive"
                        : ""
                    }
                  >
                    <div>Dzieci:</div>{" "}
                    <div className="multi">
                      <div>
                        <div>{todayData._guest_number_child_4_12}</div>
                        <div className="multi-cat">4-12</div>
                      </div>
                      <div>
                        <div>{todayData._guest_number_child_0_3}</div>
                        <div className="multi-cat">0-3</div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={!todayData._guest_number_diets ? "inactive" : ""}
                  >
                    <div>w tym diety:</div>{" "}
                    <div>{todayData._guest_number_diets}</div>
                  </div>
                </div>
                <div className="detailed-info">
                  <div>
                    <EventQR event={event} />
                  </div>
                  <div className="key-info">
                    {keyInfo?.map((k: any) => (
                      <div>
                        <div className="key-info-category">
                          {
                            key_info_types.find((t: any) => t.id == k.category)
                              ?.name
                          }
                        </div>
                        <div className="key-info-content">{k.content}</div>
                      </div>
                    ))}
                  </div>
                  {comments && comments.length ? (
                    <div className="comments">
                      <div className="comments-heading">
                        UWAGI ({comments.length})
                      </div>
                      <div className="comments-content">
                        {comments?.map((item: any) => (
                          <div>
                            <div className="comment-text">{item.content}</div>
                            <div className="comment-details">
                              <div>{item.date}</div>
                              <div>{item.user}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* <div className="main"></div> */}
          <div className="schedule">
            {datesSorted &&
              datesSorted
                ?.filter((d: any) => isSameDay(d?.date, selectedDate))
                ?.map((d: any) => (
                  <DateItem
                    key={d?.id + d?.date}
                    d={d}
                    event={event}
                    schedules={schedules}
                    meals={meals}
                    auth={auth}
                  />
                ))}
          </div>
          {/* <div style={{ padding: "20px", fontSize: "12px" }}>
          {" "}
          {JSON.stringify(event)}
        </div> */}
        </div>
      ) : (
        <div className="no-events">
          <div>
            <div>
              <img src={noJob} />
            </div>
            <div>brak imprez</div>
          </div>
        </div>
      )}
    </div>
  );
}
