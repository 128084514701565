import { time } from "console";
import minutesToString from "../functions/minutesToJSX";

function isToday(date: Date) {
  return (
    new Date(date).getDate() === new Date().getDate() &&
    new Date(date).getMonth() === new Date().getMonth() &&
    new Date(date).getFullYear() === new Date().getFullYear()
  );
}

function calcMinuteDiff(date1: any, date2: any) {
  date1.setSeconds(0);
  date2.setSeconds(0);
  return (date1 - date2) / 60 / 1000;
}

export default function preAdjust(data: any, month: number, year: number) {
  if (data.loading) return [];

  const dataAdjusted = data.rows.map((item: any) => ({
    ...item,
    entries: JSON.parse("[" + item.entries.replace("\\") + "]")
      .map((item: any) => ({
        ...item,
        date: new Date(item.timestring),
      }))
      .filter((item: any, index: number, array: any) =>
        array[index + 1]
          ? array[index + 1].timestamp - item.timestamp > 180
          : true
      ),
  }));

  const dataByPerson: any = [];

  dataAdjusted.forEach((item: any) => {
    if (item.cardperson in dataByPerson) {
      dataByPerson[item.cardperson].rows.push(item);
    } else {
      dataByPerson[item.cardperson] = { rows: [], totalMinutes: 0 };
      dataByPerson[item.cardperson].rows.push(item);
    }
  });

  //console.log(dataByPerson);
  for (const person in dataByPerson) {
    let totalMinutes = 0;
    let totalMinutesCustom = 0;
    let totalMinutesRemote = 0;
    let totalMinutesOther = 0;

    dataByPerson[person].rows.forEach(
      (item: any, index: number, arr: any[]) => {
        const itemOne = { ...item, entries: item.entries.slice(0, 2) };
        const itemTwo = { ...item, entries: item.entries.slice(2, 4) };
        let minutes = 0;
        let minutesRemote = 0;
        let minutesOther = 0;

        const firstType = item.entries[0].type;
        const lastType = item.entries.at(-1).type;

        switch (item.entries.length) {
          case 2:
          case 3:
            minutes =
              firstType === 0 && item.entries[1].type === 1
                ? calcMinuteDiff(item.entries[1].date, item.entries[0].date)
                : item.entries[2]
                ? calcMinuteDiff(item.entries[2].date, item.entries[1].date)
                : 0;
            minutesRemote =
              firstType === 2 && item.entries[1].type === 3
                ? calcMinuteDiff(item.entries[1].date, item.entries[0].date)
                : item.entries[2] && item.entries[2] === 2
                ? calcMinuteDiff(item.entries[2].date, item.entries[1].date)
                : 0;
            minutesOther = firstType === 4 && item.entries[1].type === 5
              ? calcMinuteDiff(item.entries[1].date, item.entries[0].date)
              : item.entries[2] && item.entries[2] === 4
              ? calcMinuteDiff(item.entries[2].date, item.entries[1].date)
              : 0;
            break;
          case 4:
            minutes =
              (itemOne.entries[0].type === 0 && itemOne.entries[1].type === 1
                ? calcMinuteDiff(
                    itemOne.entries[1].date,
                    itemOne.entries[0].date
                  )
                : 0) +
              (itemTwo.entries[0].type === 0 && itemTwo.entries[1].type === 1
                ? calcMinuteDiff(
                    itemTwo.entries[1].date,
                    itemTwo.entries[0].date
                  )
                : 0);
            minutesRemote =
              (itemOne.entries[0].type === 2 && itemOne.entries[1].type === 3
                ? calcMinuteDiff(
                    itemOne.entries[1].date,
                    itemOne.entries[0].date
                  )
                : 0) +
              (itemTwo.entries[0].type === 2 && itemTwo.entries[1].type === 3
                ? calcMinuteDiff(
                    itemTwo.entries[1].date,
                    itemTwo.entries[0].date
                  )
                : 0);
              minutesOther =
              (itemOne.entries[0].type === 4 && itemOne.entries[1].type === 5
                ? calcMinuteDiff(
                    itemOne.entries[1].date,
                    itemOne.entries[0].date
                  )
                : 0) +
              (itemTwo.entries[0].type === 4 && itemTwo.entries[1].type === 5
                ? calcMinuteDiff(
                    itemTwo.entries[1].date,
                    itemTwo.entries[0].date
                  )
                : 0);
            //if (minutesRemote > 0) console.log(minutes, minutesRemote);
            break;
        }

        const nextFirstType = arr[index - 1]
          ? arr[index - 1].entries[0].type
          : false;
        const prevLastType = arr[index + 1]
          ? arr[index + 1].entries.at(-1).type
          : false;
        if (lastType === 0 && nextFirstType === 1) {
          //item.entries.push(false);

          const nextStart = arr[index - 1].entries.find(
            (e: any) => e.type === 1 || e.type === 3
          );

          const thisEnd = item.entries.find(
            (e: any) => e.type === 0 || e.type === 2
          );
          //console.log(nextStart, thisEnd);
          minutes = calcMinuteDiff(nextStart?.date, thisEnd?.date);
        }
        /*
        if (firstType === 1 && prevLastType === 0) {
          //item.entries.push(false);

          const prevLast = arr[index + 1].entries.at(-1);

          const thisStart = item.entries[0];
          //console.log(nextStart, thisEnd);
          minutes = calcMinuteDiff(thisStart?.date, prevLast?.date);
        }     */

        // FOR RECEPTION DESK MAX 12 HOURS WORK CUT-OFF
        if (item.employees_group === 1) {
          item.realMinutes = minutes;
          //minutes = minutes > 12 * 60 ? 12 * 60 : minutes;
          item.customMinutes = minutes > 12 * 60 ? 12 * 60 : minutes;
        }

        item.minutes = minutes;
        item.minutesRemote = minutesRemote;
        item.minutesOther = minutesOther;
        //console.log(item.date, calendarParams);
        const monthCorrected = new Date(year, month).getMonth();
        const yearCorrected = new Date(year, month).getFullYear();
        if (
          item.entries[0].date &&
          item.entries[0].date.getMonth() === monthCorrected &&
          item.entries[0].date.getFullYear() === yearCorrected
        ) {
          totalMinutes += minutes + minutesRemote + minutesOther;
          totalMinutesCustom += item.customMinutes + minutesRemote + minutesOther;
          totalMinutesRemote += minutesRemote;
          totalMinutesOther += minutesOther;
        }
      }
    );

    dataByPerson[person].totalMinutes = totalMinutes;
    dataByPerson[person].totalMinutesRemote = totalMinutesRemote;
    dataByPerson[person].totalMinutesCustom = totalMinutesCustom;
    dataByPerson[person].totalMinutesOther = totalMinutesOther;
    dataByPerson[person].totalTimeJSX = (
      <>
        {totalMinutes / 60 > 0 ? Math.floor(totalMinutes / 60) + " h" : ""}{" "}
        {Math.floor(totalMinutes % 60)
          ? Math.floor(totalMinutes % 60) + " min"
          : ""}
      </>
    );
  }

  return {
    //totalMinutes: 0, //totalMinutes,
    dataByPerson: dataByPerson,
    rows: dataAdjusted.map((item: any, index: number, arr: any[]) => {
      const firstType = item.entries[0].type;
      const lastType = item.entries.at(-1).type;
      //const nextFirstType = arr[index - 1];

      let realTimeBetween = minutesToString(
        item.realMinutes,
        isToday(item.entries[0].date) || firstType === 1 || firstType === 3 || firstType === 5
      );
      let extraTime = minutesToString(
        item.realMinutes - item.customMinutes,
        isToday(item.entries[0].date) || firstType === 1 || firstType === 3 || firstType === 5
      );

      let timeBetween = item.minutes || item.minutesRemote || item.minutesOther ? (
        <>
          {item.minutes ? <div>
            {minutesToString(
              (item.customMinutes ? item.customMinutes : item.minutes),
              item.minutesRemote ||
                isToday(item.entries[0].date) ||
                firstType === 1 ||
                firstType === 3
            )}
            {item.realMinutes > item.customMinutes ? (
              <span
                style={{
                  fontSize: "80%",
                  color: "rgb(140,140,140)",
                }}
              >
                {" "}
                ({"+"} {extraTime})
              </span>
            ) : (
              <></>
            )}
          </div> : <></>}
          {item.minutesRemote ? (
            <div className="remoteSum">
              {minutesToString(
                item.minutesRemote,
                isToday(item.entries[0].date) ||
                  firstType === 1 ||
                  firstType === 3
              )}{" "}
              <span className="remote-label">ZDALNIE</span>
            </div>
          ) : (
            <></>
          )}
          {item.minutesOther ? (
            <div className="otherSum">
              {minutesToString(
                item.minutesOther,
                isToday(item.entries[0].date) ||
                  firstType === 5
              )}{" "}
              <span className="other-label">INNE</span>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : null;

      /* if (lastType === 0 && nextFirstType === 1) {
        //item.entries.push(false);
        timeBetween = <> ↘ {minutesToString(item.minutes)}</>;
      }*/
      return {
        person: item.cardperson,
        detailed: {
          ...item,
        }, //diff: diff,
        timeBetween: timeBetween,
        minutes: item.minutes,
        minutesRemote: item.minutesRemote,
        realMinutes: item.realMinutes,
        customMinutes: item.customMinutes,
        realTimeBetween: realTimeBetween,
        extraTime: extraTime,
        first: item.first_timestring,
        entries: item.entries,
        lastType: lastType,
        //nextFirstType: nextFirstType,
      };
    }),
  };
}
