import {
  createRef,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Loading from "../common/Loading";
import { EntryContext } from "../contexts/EntryContext";
import { UserContext } from "../contexts/UserContext";
import { ViewContext } from "../contexts/ViewContext";
import insertGeneric from "../dataFetch/insertGeneric";
import sendIcon from "../../images/icons/send.svg";
import useGeneric from "../dataFetch/useGeneric";
import DateDisplay from "./viewElements/DateDisplay";

import ChatItem from "./ChatItem";

function desktopNotification(content: any, debug: any) {
  const options = {
    body: content,
    icon: undefined,
  };

  //MUST MAKE RULES FOR NOTIFICATIONS
  //console.log("!", options, debug);
  const notification = new Notification("Nowa wiadomość", options);
}

export default function Chat({
  query,
}: {
  query: { table: string; table_id: number; details?: any };
}) {
  const [currentComment, setCurrentComment] = useState("");
  const [commentUpdate, setCommentUpdate] = useState(0);
  const [commentDisabled, setCommentDisabled] = useState(false);
  const [prevQuery, setPrevQuery] = useState(
    () => query.table + query.table_id
  );

  const discussion = useGeneric({
    name: "discussion",
    limit: 700,
    query: {
      table: query.table,
      table_id: query.table_id,
      // details: query?.details,
    },
    update: commentUpdate,
  }) as {
    rows: {
      content: string;
      author: number;
      table: string;
      table_id: number;
    }[];
    count: number;
    prevCount?: number;
    loading?: boolean;
  };

  const user = useContext(UserContext);
  // const current = useContext(EntryContext);
  const view = useContext(ViewContext);
  const chatWindowRef = createRef<HTMLInputElement>();

  useEffect(() => {
    const reload = setInterval(
      () => setCommentUpdate((prev: number) => prev + 1),
      6000
    );

    return () => clearInterval(reload);
  }, []);

  useEffect(() => {
    if (chatWindowRef.current) {
      if (discussion.count !== discussion.prevCount || commentUpdate === 0) {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }
      if (discussion.count !== discussion.prevCount && commentUpdate !== 0) {
        if (
          !discussion.loading &&
          discussion.rows[0].author !== user.userID &&
          prevQuery === query.table + query.table_id
        ) {
          desktopNotification(discussion.rows[0]?.content, discussion);
        }
      }
    }
    return () => setPrevQuery(query.table + query.table_id);
  }, [
    commentUpdate,
    discussion.loading,
    discussion.count,
    discussion.prevCount,
    chatWindowRef.current,
  ]);

  function sendChat(e: any) {
    e.preventDefault();

    //  CHECK WHICH USERS ARE ACTIVE AND DO NOT SEND E-MAIL NOTIFICATIONS TO THEM
    const onlineIDs = user?.onlineUsers
      ? user?.onlineUsers.map((user: any) => user?.userID)
      : "";

    // who is involved in conversation?
    const involveds: any = []; /*Array.from(
      new Set(
        ...(discussion?.rows
          ? discussion?.rows.map((comment: any) => comment?.author)
          : [])
      )
    );*/

    if (currentComment.length > 0) {
      setCommentDisabled(true);
      insertGeneric("discussion", {
        author: user.userID,
        content: currentComment,
        table: query.table,
        table_id: query.table_id,
        details: query.details,
        onlineIDs: {
          online: onlineIDs,
          involved: involveds,
          thisUser: user.userID,
        },
      }).then((result) => {
        setCurrentComment("");
        setCommentUpdate((prev: number) => prev + 1);
        setCommentDisabled(false);

        view.setModal({
          identity: "notification",
          show: true,
          heading: "Powiadomienie",
          content: <div style={{ padding: "20px" }}>Wysłano e-mail...</div>,
        });
        setTimeout(
          () => view.setModal((prev: any) => ({ ...prev, show: false })),
          2500
        );
      });
    }
    //current.setUpdate((prev: number) => prev + 1);
  }

  return (
    <div className="chatWrap">
      <div className="onlineUsers">
        <div>Dostępni teraz:</div>
        {Array.isArray(user.onlineUsers) ? (
          user.onlineUsers?.map((onlineUser: any) => (
            <div
              key={onlineUser.id}
              className={
                "onlineUser" + (onlineUser.focus === "0" ? " noFocus" : "")
              }
            >
              {onlineUser.first_name} {onlineUser.last_name}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
      <div className="chatWindow" ref={chatWindowRef}>
        {!discussion.loading ? (
          discussion.rows
            .slice(0)
            .reverse()
            .map((comment: any) => (
              <ChatItem
                user={user}
                comment={comment}
                setUpdates={setCommentUpdate}
              />
            ))
        ) : (
          <>Sprawdzanie wiadomości...</>
        )}
      </div>
      <div className="chat-message-create">
        <textarea
          className="chatInputArea"
          placeholder="Napisz wiadomość..."
          value={currentComment}
          onChange={(e) => setCurrentComment(e.target.value)}
          disabled={commentDisabled}
        ></textarea>

        <button
          style={{ opacity: currentComment.length ? "1" : ".18" }}
          className="button chatSend"
          onClick={(e) =>
            currentComment.length && !commentDisabled ? sendChat(e) : undefined
          }
        >
          <img width={40} src={sendIcon} />
        </button>
      </div>
    </div>
  );
}
