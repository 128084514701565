import { objectOrJSONparse } from "../../../functions/tools";
import BooleanSymbol from "../../../tables/viewElements/BooleanSymbol";

export function row(row?: any): any[] {
  const registers = row?.registers && objectOrJSONparse(row?.registers);
  const modules = row?.modules && objectOrJSONparse(row?.modules);
  return [
    {
      source: "user_id",
      name: "Id",
      value: <>{row?.id}</>,
      style: { width: "40px" },
    },
    {
      source: "username",
      name: "Nazwa",
      value: <>{row?.username}</>,
      style: { width: "100px" },
    },
    {
        source:"modules",
        name:"Moduły",
        value: modules && (
            <>
              {Object.keys(modules)?.map((key: string) => (
                <div style={{ display: "flex" }}>
                  <div>{key}</div>
                  <div style={{ paddingLeft: "6px" }}>{modules?.[key]}</div>
                </div>
              ))}
            </>
          ),
    },
    {
      source: "registers",
      name: "Rejestry",
      value: registers && (
        <>
          {Object.keys(registers)?.map((key: string) => (
            <div style={{ display: "flex" }}>
              <div>{key}</div>
              <div style={{ paddingLeft: "6px" }}>{registers?.[key]}</div>
            </div>
          ))}
        </>
      ),
    },
  ];
}
