import {
  StyleSheet,
  Text,
  View,
  Document,
  Page,
  Image,
  PDFViewer,
  Font,
  PDFDownloadLink,
  Link,
  BlobProvider,
  pdf,
} from "@react-pdf/renderer";

import krdImage from "../../images/krd_short_2.png";
import agitLogo from "../../images/logo_print.png";
import downloadIcon from "../../images/icons/pdf.svg";
import mailIcon from "../../images/icons/message.svg";
import historyIcon from "../../images/icons/restore.svg";
import QRCode from "qrcode";
import prettyNumber from "../functions/prettyNumber";
import prettyMoney from "../functions/prettyMoney";
import { URL, URLProd } from "../paths";
import trailingZero from "../functions/trailingZero";
import moneyWords from "../functions/moneyWords";
import { groupBy, groupByMulti } from "../functions/tools";
import useGeneric from "../dataFetch/useGeneric";
import InvoiceTable from "./Print/InvoiceTable";
import tuChceIcon from "../../images/tu_chce_sie_wracac.png";
import Loading from "../common/Loading";
import InvoiceSend from "./InvoiceSend";
import invoiceTypes from "./Print/invoiceTypes";
import { useEffect, useState } from "react";
import { usePaymentQRvalue } from "./PaymentQR";
import fillLog from "../dataFetch/fillLog";
import InvoiceLog from "./InvoiceLog";

export function QRCodeImage(props: { url: string; style?: any }) {
  const urlPromise = QRCode.toDataURL(props.url);
  return <Image style={[styles.qrImage, props.style || {}]} src={urlPromise} />;
}

Font.register({
  family: "Lato",
  fonts: [
    {
      src: URL + "backend/fonts/Lato/Lato-Light.ttf",
      fontWeight: 300,
    },
    {
      src: URL + "backend/fonts/Lato/Lato-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: URL + "backend/fonts/Lato/Lato-Bold.ttf",
      fontWeight: 600,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    // backgroundColor: "#E4E4E4",
    fontFamily: "Lato",
    padding: 40,
  },
  section: {
    // margin: 30,
    // padding: 10,
    flexGrow: 1,
  },
  horizontal: {
    // flexDirection:"column",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  horizontalLeft: {
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  qrImage: {
    width: "3cm", //"2.75cm",
    height: "3cm", //"2.75cm",
    padding: 0,
  },
  small: {
    fontSize: "9px",
  },
  footer: {
    position: "absolute",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 1.5cm",
    fontSize: 7.5,
    bottom: 40,
    left: 0,
    right: 0,
    alignItems: "flex-end",
    // textAlign: "center",
    // color: "grey",
  },
  table: {
    borderTop: "1px solid black",
    borderRight: "1px solid black",
    // borderBottom: "1px solid black",
    textAlign: "center",
    // width:500
  },
  tableCell: {
    borderLeft: "1px solid black",
    padding: "1 2",
    borderBottom: "1px solid black",
  },
});

export default function InvoicePrint({
  item,
  itemGroups,
  invoice,
  userID,
}: {
  item: invoiceTypes[];
  itemGroups: invoiceTypes[];
  invoice: invoiceTypes;
  userID?: number;
}) {
  const qr = usePaymentQRvalue(
    invoice?.ValueBWal,
    invoice?.document_name_full as string
  );
  // console.log("reload-print");

  const [viewPDF, setViewPDF] = useState(false);
  const fetchLocale = useGeneric({ name: "lsi_locale" });

  const [mode, setMode] = useState<"print" | "mail" | "history">("print");

  const [blob, setBlob] = useState<any>();
  const locale = !fetchLocale?.loading && fetchLocale?.rows?.[0];

  const fetchPaymentLink = useGeneric({
    name: invoice?.SCID /*&& !invoice?.DataWplaty*/ ? "p24" : "",
    query: {
      amount: Number(invoice?.ValueBWal),
      title: invoice?.document_name_full,
      customer: invoice?.pl_firma,
      scid: invoice?.SCID,
      customer_email: false,
    },
    reload: false,
  });

  const paymentLink = !fetchPaymentLink?.loading && fetchPaymentLink?.rows?.url;

  // console.log(paymentLink);

  const cW = [30, 240, 50, 50, 60, 100, 100, 100];

  // console.log(itemGroups);

  let taxGroups = groupByMulti(item, (i: invoiceTypes) => [i?.VAT]);
  // console.log(taxGroups);

  taxGroups = taxGroups
    ?.map((g: any) => {
      const sum_gross = g
        ?.map((n: any) => Number(n?.item_gross))
        ?.reduce((a: number, b: number) => a + b, 0);
      const sum_net = sum_gross / (1 + Number(g?.[0]?.VAT) / 100);
      return {
        // sum_net: g
        //   ?.map((n: any) => Number(n?.item_net))
        //   ?.reduce((a: number, b: number) => a + b, 0),
        sum_net: sum_net,
        sum_gross: sum_gross,
        sum_tax: sum_gross - sum_net,
        // sum_tax_2: g
        //   ?.map((n: any) => Number(n?.item_gross) - Number(n?.item_net))
        //   ?.reduce((a: number, b: number) => a + b, 0),
        VAT: g?.[0]?.VAT,
      };
    })
    ?.flat();

  const grossFinal: any = prettyMoney(invoice?.ValueB, {
    text: true,
    round: 2,
  });

  const invoiceNumberFile = `FV-${invoice.DocNo}-${trailingZero(
    invoice.month,
    2
  )}-${invoice.year}-H`;
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={[styles.horizontal, { fontSize: "10pt" }]}>
            <View
              style={[
                styles.horizontal,
                {
                  paddingBottom: ".5cm",
                  paddingRight: ".5cm",
                  borderBottom: "1px solid rgb(200,200,200)",
                  borderRight: "1px solid rgb(200,200,200)",
                  borderRadius: "5px",
                },
              ]}
            >
              <Image
                src={agitLogo}
                style={{ marginRight: ".5cm", width: "4.2cm", height: "1.6cm" }}
              />
              <View>
                <Text>{locale?.Opis1}</Text>
                <Text>{locale?.Opis2}</Text>
                <Text>{locale?.Opis3}</Text>
                <Text>{locale?.Opis4}</Text>
              </View>
            </View>
            <View style={{ width: 140, fontSize: "10pt" }}>
              <View style={styles.horizontal}>
                <Text>Data dostawy:</Text>
                <Text>{invoice?.Data?.slice(0, 10)}</Text>
              </View>
              <View style={styles.horizontal}>
                <Text>Data wystawienia:</Text>
                <Text>{invoice?.data_modyfikacji.slice(0, 10)}</Text>
              </View>
            </View>
          </View>
          <Text
            style={{
              fontSize: "16pt",
              fontWeight: "heavy",
              textAlign: "center",
              marginTop: "1cm",
            }}
          >
            {invoice?.type == 3 ? <>Korekta faktury nr</> : <>Faktura nr</>}{" "}
            {invoice?.do_paragonu == 1 ? "" : "FV/"}
            {invoice.DocNo}/{trailingZero(invoice.month, 2)}/{invoice.year}/H
          </Text>
          {invoice?.type == 3 ? (
            <Text
              style={{
                fontSize: "15pt",
                fontWeight: "heavy",
                textAlign: "center",
              }}
            >
              do faktury nr {invoice?.cDocNo}/{trailingZero(invoice?.cMonth)}/
              {invoice?.cYear}/H z dnia{" "}
              {new Date(invoice?.cData)?.toLocaleDateString("pl-PL", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </Text>
          ) : (
            <></>
          )}
          <View
            style={[
              styles.horizontal,
              {
                marginTop: "1cm",
                lineHeight: 1.6,
                alignItems: "center",
              },
            ]}
          >
            <View style={styles.small}>
              <View style={styles.horizontalLeft}>
                <Text style={{ width: 80, fontWeight: "bold" }}>
                  Sprzedawca:
                </Text>
                <Text>{locale?.nazwa1}</Text>
              </View>
              <View style={styles.horizontalLeft}>
                <Text style={{ width: 80 }}></Text>
                <Text>
                  {locale?.ulica?.replace(/ \/$/, "/")}
                  {locale?.nr_bloku}, {locale?.kod_pocztowy}{" "}
                  {locale?.miejscowosc}
                </Text>
              </View>
              {/* <View style={styles.horizontalLeft}>
                <Text style={{ width: 80 }}></Text>
                <Text>
                  {locale?.kod_pocztowy} {locale?.miejscowosc}
                </Text>
              </View> */}
              <View style={styles.horizontalLeft}>
                <Text style={{ width: 80, fontWeight: "bold" }}>Konto:</Text>
                <Text>
                  {locale?.nr_konta} {locale?.nazwa_banku}
                </Text>
              </View>
            </View>
            <View style={[styles.small, { width: "34%" }]}>
              <View style={styles.horizontalLeft}>
                <Text style={{ width: 32, fontWeight: "bold" }}>NIP:</Text>
                <Text>{locale?.nip?.replace(/-/g, "")}</Text>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.horizontal,
              {
                marginTop: ".6cm",
                lineHeight: 1.5,
                alignItems: "center",
              },
            ]}
          >
            <View style={styles.small}>
              <View style={styles.horizontalLeft}>
                <Text style={{ width: 80, fontWeight: "bold" }}>Nabywca:</Text>
                <Text>{invoice.ko_firma}</Text>
              </View>
              <View style={styles.horizontalLeft}>
                <Text style={{ width: 80 }}></Text>
                <Text>{invoice.ko_adres}</Text>
              </View>
              <View style={styles.horizontalLeft}>
                <Text style={{ width: 80 }}></Text>
                <Text>{invoice.ko_miasto}</Text>
              </View>
              <View style={[styles.horizontalLeft, { marginTop: ".25cm" }]}>
                <Text style={{ width: 80, fontWeight: "bold" }}>Płatność:</Text>
                <Text>
                  {prettyMoney(Math.abs(invoice.ValueBWal), { text: true })} (
                  {invoice.SymbolWaluty}) do dnia{" "}
                  {invoice.PayTerm?.slice(0, 10)} {invoice.typ_platnosci}
                </Text>
              </View>
              {invoice?.do_paragonu == 1 ? (
                <View style={styles.horizontalLeft}>
                  <Text style={{ width: 80, fontWeight: "bold" }}>
                    Paragony:
                  </Text>
                  <Text>
                    {invoice?.receipt_number
                      ? invoice?.receipt_number +
                        " (" +
                        invoice.receipt_date?.slice(0, 10) +
                        ")"
                      : "nie udało się pobrać numeru"}
                  </Text>
                </View>
              ) : (
                <></>
              )}
            </View>
            <View style={[styles.small, { width: "34%" }]}>
              <View style={styles.horizontalLeft}>
                <Text style={{ width: 32, fontWeight: "bold" }}>NIP:</Text>
                <Text>{invoice.ko_nip}</Text>
              </View>
            </View>
          </View>

          <InvoiceTable
            styles={styles}
            cW={cW}
            invoice={invoice}
            itemGroups={itemGroups}
          />

          <View
            style={[
              styles.table,
              {
                borderTop: "none",
                fontSize: "8px",
                flexDirection: "row",
                // justifyContent: "flex-end",
                marginTop: 10,
                fontWeight: "bold",
              },
            ]}
          >
            <View
              style={[
                styles.tableCell,
                {
                  border: "none",
                  width: cW[0] + cW[1] + cW[2] + cW[3],
                },
              ]}
            ></View>
            <View
              style={[styles.tableCell, { border: "none", width: cW[4] }]}
            ></View>
            <View
              style={[
                styles.tableCell,
                { borderTop: "1px solid black", width: cW[5] },
              ]}
            >
              <Text>Wartość</Text>
              <Text>sprzedaży netto</Text>
            </View>
            <View
              style={[
                styles.tableCell,
                { padding: 0, borderTop: "1px solid black", width: cW[6] },
              ]}
            >
              <Text>Podatek VAT</Text>
              <View
                style={[
                  styles.table,
                  { padding: 0, borderRight: "none", flexDirection: "row" },
                ]}
              >
                <View
                  style={{
                    padding: "2 1",
                    borderRight: "1px solid black",
                    width: 30,
                  }}
                >
                  <Text>%</Text>
                </View>
                <View style={{ padding: "2 1", width: 70 }}>
                  <Text>Kwota</Text>
                </View>
              </View>
            </View>
            <View
              style={[
                styles.tableCell,
                { borderTop: "1px solid black", width: cW[7] },
              ]}
            >
              <Text>Wartość</Text>
              <Text>sprzedaży brutto</Text>
            </View>
          </View>
          <View
            style={[
              styles.table,
              {
                borderTop: "none",
                fontSize: "8px",
                flexDirection: "row",
              },
            ]}
          >
            <View
              style={[
                styles.tableCell,
                {
                  border: "none",
                  width: cW[0] + cW[1] + cW[2] + cW[3],
                },
              ]}
            ></View>
            <View style={[styles.tableCell, { width: cW[4], border: "none" }]}>
              <Text>Razem</Text>
            </View>
            <View
              style={[styles.tableCell, { textAlign: "right", width: cW[5] }]}
            >
              <Text>
                {prettyMoney(
                  taxGroups
                    ?.map((t: any) => Number(t?.sum_net))
                    ?.reduce((a: number, b: number) => a + b, 0),
                  // invoice?.ValueN
                  { round: 2, text: true }
                )}
              </Text>
            </View>
            <View
              style={[
                styles.tableCell,
                { padding: 0, flexDirection: "row", width: cW[6] },
              ]}
            >
              <View
                style={{
                  padding: "2 1",
                  borderRight: "1px solid black",
                  width: 30,
                }}
              >
                <Text>--</Text>
              </View>
              <View style={{ padding: "2 1", width: 70, textAlign: "right" }}>
                <Text>
                  {prettyMoney(
                    taxGroups
                      ?.map(
                        (t: any) => Number(t?.sum_gross) - Number(t?.sum_net)
                      )
                      .reduce((a: number, b: number) => a + b, 0),
                    //invoice?.ValueB - invoice?.ValueN,
                    {
                      round: 2,
                      text: true,
                    }
                  )}
                </Text>
              </View>
            </View>

            <View
              style={[styles.tableCell, { textAlign: "right", width: cW[7] }]}
            >
              <Text>
                {prettyMoney(invoice?.ValueB, { round: 2, text: true })}
              </Text>
            </View>
          </View>
          {taxGroups
            ?.sort((a: any, b: any) => Number(b?.VAT) - Number(a?.VAT))
            .map((t: any, index: number) => (
              <View
                style={[
                  styles.table,
                  {
                    borderTop: "none",
                    fontSize: "8px",
                    flexDirection: "row",
                  },
                ]}
              >
                <View
                  style={[
                    styles.tableCell,
                    {
                      border: "none",
                      width: cW[0] + cW[1] + cW[2] + cW[3],
                    },
                  ]}
                ></View>
                <View
                  style={[styles.tableCell, { width: cW[4], border: "none" }]}
                >
                  <Text>{index === 0 ? "w tym" : ""}</Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    { textAlign: "right", width: cW[5] },
                  ]}
                >
                  <Text>
                    {prettyMoney(t?.sum_net, { round: 2, text: true })}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableCell,
                    { padding: 0, flexDirection: "row", width: cW[6] },
                  ]}
                >
                  <View
                    style={{
                      padding: "2 1",
                      borderRight: "1px solid black",
                      width: 30,
                    }}
                  >
                    <Text>{parseInt(t?.VAT)}</Text>
                  </View>
                  <View
                    style={{ textAlign: "right", padding: "2 1", width: 70 }}
                  >
                    <Text>
                      {prettyMoney(t?.sum_tax, {
                        round: 2,
                        text: true,
                      })}
                    </Text>
                  </View>
                </View>

                <View
                  style={[
                    styles.tableCell,
                    { textAlign: "right", width: cW[7] },
                  ]}
                >
                  <Text>
                    {prettyMoney(t?.sum_gross, { round: 2, text: true })}
                  </Text>
                </View>
              </View>
            ))}
          <View style={styles.small}>
            {invoice?.type == 3 ? (
              <View
                style={[
                  styles.horizontalLeft,
                  {
                    justifyContent: "flex-start",
                    marginBottom: ".25cm",
                    width: "80%",
                  },
                ]}
              >
                <Text style={{ width: 85, fontWeight: "bold" }}>
                  Przyczyna korekty:
                </Text>
                <Text>{invoice?.PrzyczynaKorekty}</Text>
              </View>
            ) : (
              <></>
            )}
            <View
              style={[
                styles.horizontalLeft,
                {
                  justifyContent: "flex-start",
                  marginBottom: ".25cm",
                  width: "80%",
                },
              ]}
            >
              <Text style={{ width: 85, fontWeight: "bold" }}>Uwagi:</Text>
              <Text>{invoice?.Uwagi}</Text>
            </View>
            <View
              style={[
                styles.horizontalLeft,
                {
                  justifyContent: "flex-start",

                  width: "80%",
                },
              ]}
            >
              <Text style={{ width: 85 }}>
                Wartość ({invoice?.SymbolWaluty}):
              </Text>
              <Text style={{ fontWeight: "bold" }}>{grossFinal}</Text>
            </View>
            <View
              style={[
                styles.horizontal,
                { justifyContent: "flex-start", width: "80%" },
              ]}
            >
              <Text style={{ width: 85 }}>
                Słownie ({invoice?.SymbolWaluty}):
              </Text>
              <Text style={{ textAlign: "left", fontWeight: "bold" }}>
                {moneyWords(grossFinal)}{" "}
                {Number(grossFinal?.replace(/.*,(.*)/, "$1"))}/100
              </Text>
            </View>
            <View
              style={[
                styles.horizontalLeft,
                {
                  justifyContent: "flex-start",
                  marginTop: ".25cm",
                  width: "80%",
                },
              ]}
            >
              <Text style={{ width: 85 }}>
                Do zapłaty ({invoice?.SymbolWaluty}):
              </Text>
              <Text style={{ fontWeight: "bold" }}>{grossFinal}</Text>
            </View>
            <View
              style={[
                styles.horizontal,
                { justifyContent: "flex-start", width: "80%" },
              ]}
            >
              <Text style={{ width: 85 }}>
                Słownie ({invoice?.SymbolWaluty}):
              </Text>
              <Text style={{ textAlign: "left", fontWeight: "bold" }}>
                {moneyWords(grossFinal)}{" "}
                {Number(grossFinal?.replace(/.*,(.*)/, "$1"))}/100
              </Text>
            </View>
          </View>
          <View
            style={[
              styles.small,
              {
                marginTop: "1cm",
                width: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
              },
            ]}
          >
            <View></View>
            {/* <View style={{ textAlign: "center", width: "40%" }}>
              <Text style={{ marginBottom: "1cm" }}>Odebrał:</Text>
              <Text>
                ......................................................................
              </Text>
              <Text>Podpis osoby upoważnionej</Text>
              <Text>do odbioru faktury</Text>
            </View> */}
            <View style={{ textAlign: "center", width: "40%" }}>
              <Text style={{ marginBottom: ".5cm" }}>Wystawił:</Text>
              <Text style={{ marginBottom: ".25cm" }}>
                {invoice?.imie_uzytkownika} {invoice?.nazwisko_uzytkownika}
              </Text>
              {/* <Text>Podpis osoby upoważnionej</Text>
              <Text>do wystawienia faktury</Text> */}
            </View>
          </View>

          <View
            wrap={false}
            style={[
              styles.small,
              {
                // width: "5cm",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                // borderBottom: "0.5px dashed rgb(180,180,180)",
                // borderRadius: "3px",
                marginTop: ".75cm",

                // marginTop: "-1cm",
                padding: ".1cm",
              },
            ]}
          >
            {Number(invoice?.ValueB) > 0 && invoice?.Rozliczone != 1 ? (
              <Link href={paymentLink ? paymentLink : undefined}>
                <View
                  style={{
                    margin: "0 1.2cm",
                    padding: "2mm 4mm",
                    borderRadius: "4px",
                    marginTop: "-1cm",
                    border: "1px solid lightgrey",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      marginTop: ".1cm",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    {paymentLink ? (
                      <QRCodeImage url={paymentLink} />
                    ) : qr ? (
                      <QRCodeImage url={qr} />
                    ) : (
                      <></>
                    )}
                  </View>
                  {paymentLink ? (
                    <View
                      style={[
                        styles.small,
                        {
                          justifyContent: "center",
                        },
                      ]}
                    >
                      <Text
                        style={{
                          padding: "2mm 0mm",
                          color: "blue",
                          textDecoration: "none",
                          fontSize: "10pt",
                        }}
                      >
                        Szybka płatność online
                      </Text>
                    </View>
                  ) : (
                    <View style={{ justifyContent: "center" }}>
                      <Text>Użyj kodu QR i opłać</Text>
                      <Text>w aplikacji bankowej</Text>
                    </View>
                  )}
                </View>
              </Link>
            ) : (
              <></>
            )}
            {Number(invoice?.ValueB) > 0 && invoice?.Rozliczone != 1 ? (
              <View>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <Image
                    src={krdImage}
                    style={{
                      marginTop: "-1cm",
                      width: "7cm",
                      padding: "0.15cm",
                    }}
                  />
                </View>
              </View>
            ) : (
              <></>
            )}
          </View>
        </View>
        <View style={styles.footer} fixed>
          <Text>{new Date().toLocaleString()} | AGIT_sys</Text>

          <Text
            style={{ color: "rgb(40,40,40)", fontWeight: 300 }}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
          ></Text>
          <Image
            src={tuChceIcon}
            style={{
              // marginRight: "2.75cm",
              marginBottom: "-.45cm",
              width: "4.5cm",
            }}
          />
        </View>
      </Page>
    </Document>
  );

  async function getBlob() {
    const b = await pdf(<MyDocument />).toBlob();
    setBlob(b);
  }

  useEffect(() => {
    if (
      !fetchPaymentLink?.loading &&
      !fetchLocale?.loading &&
      item &&
      invoice &&
      locale
    ) {
      getBlob();
    }
  }, [fetchPaymentLink?.loading, fetchLocale?.loading]);

  return (
    item &&
    invoice &&
    locale && (
      <div
        style={{
          // display: "flex",
          borderLeft: "1px solid grey",
          // marginTop: "10px",
          width: "100%",
        }}
      >
        <div style={{ display: "flex" }}>
          {" "}
          <div
            style={{ width: "100%" }}
            onClick={() =>
              fillLog({
                user: userID,
                section: "lsi-sales-invoices",
                event: "save-pdf",
                item_id: invoice?.SCID,
              })
            }
          >
            <PDFDownloadLink
              document={<MyDocument />}
              fileName={invoiceNumberFile + ".pdf"}
              className="normal-button-2"
            >
              <img src={downloadIcon} /> Pobierz PDF
            </PDFDownloadLink>
          </div>
          <button onClick={() => setMode("mail")} className="normal-button-2">
            <img src={mailIcon} />
            <div>Wyślij e-mailem</div>
          </button>
          <button
            onClick={() => setMode("history")}
            className="normal-button-2"
          >
            <img src={historyIcon} />
            <div>Historia aktywności</div>
          </button>
        </div>

        <InvoiceSend
          invoice={invoice}
          setMode={setMode}
          pdf={blob}
          paymentLink={paymentLink}
          style={mode !== "mail" ? { display: "none" } : {}}
          userID={userID}
        />
        {mode === "history" ? (
          <InvoiceLog invoice={invoice} setMode={setMode} />
        ) : mode === "print" ? (
          <div
            style={{
              width: "100%",
              position: "relative",
              height: "calc(100svh - 93px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {viewPDF ? (
              <>
                <div className="loading back">
                  <div>Otwieranie podglądu...</div>
                </div>
                <PDFViewer width={"100%"} height="100%">
                  <MyDocument />
                </PDFViewer>
              </>
            ) : (
              <div
                className="normal-button huge appear"
                onClick={() => setViewPDF(true)}
              >
                Podgląd PDF
              </div>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  );
}
