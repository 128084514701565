export default function EmployeeButtons({
  employees,
  selectedEmployees,
  setSelectedEmployees,
}: {
  employees: any;
  selectedEmployees: any;
  setSelectedEmployees: Function;
}) {
  return <div className="employeeGroup">
    <div className="employeeGroupTitle" onClick={() => {
        setSelectedEmployees((prev:any) => {
            const fillEmployees = employees.filter((e:any) => !prev.map((p:any)=>p.cardname).includes(e.cardname));
            if (employees.length === selectedEmployees.filter((e:any) => e.group_name === employees[0].group_name).length) {
                return prev.filter((p:any) => p.group_name !== employees[0].group_name);
            }
            else {
                return [...prev, ...fillEmployees];
            }    
        });
    }}>
        {employees[0].group_name}
    </div>
    {employees
      .sort((a: any, b: any) => a.last_name.localeCompare(b.last_name))
      .filter((e: any) => e.location === "hotel")
      .map((e: any) => {
        const added = selectedEmployees.find((s: any) => s.id === e.id);
        return (
          <button
            className={added ? "added" : ""}
            onClick={() => {
              if (added) {
                setSelectedEmployees((prev: any) =>
                  prev.filter((f: any) => f.id !== e.id)
                );
              } else {
                setSelectedEmployees((prev: any) => [...prev, e]);
              }
            }}
          >
            {e.first_name} {e.last_name}{" "}
            {added ? <span className="tick">✓</span> : <></>}
          </button>
        );
      })}
  </div>;
}
