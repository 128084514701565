import AddPotentialEvent from "./AddPotentialEvent";
import addDraftIcon from "../../images/icons/add_draft.svg";

export default function AddPotentialButton({
  setModal,
  auth,
  date,
  calendar,
  short,
}: {
  setModal: Function;
  auth: any;
  date: any;
  calendar: any;
  short?: Boolean;
}) {
  return (
    <div
      className="add-event-list"
      onClick={() => {
        // setModal({
        //   show: true,
        //   heading: "Szybkie dodawanie",
        //   content: <>funkcja w przygotowaniu</>,
        // });
        setModal({
          show: true,
          heading: "Szybkie dodawanie / niepotwierdzone wydarzenie",
          style: { width: "750px" },
          content: (
            <AddPotentialEvent
              auth={auth}
              event={undefined}
              date={date}
              calendar={calendar}
              setModal={setModal}
            />
          ),
        });
      }}
      title={"Dodaj wydarzenie"}
    >
      {short ? <img src={addDraftIcon}/> : <>+ WSTĘPNIE</>}
    </div>
  );
}
