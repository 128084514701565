import { useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import { URL } from "../paths";

export default function TemplateBindingEditor({
  schedule,
  set,
  templates,
  setUpdate
}: {
  schedule: any;
  set: Function;
  templates: any;
  setUpdate: Function;
}) {
  

  async function modifySchedule(id: number) {
    let newIDs;
    const thisTemplate = templates.find(
      (t: any) => Number(t.id) === Number(id)
    );

    const currentIDs = thisTemplate.scheduleIDs
      ? thisTemplate.scheduleIDs.split(",")
      : [];
    console.log(currentIDs);
    if (thisTemplate && currentIDs) {
      const isPresent = currentIDs.find(
        (t: any) => Number(t) === Number(schedule.id)
      );
      if (isPresent) {
        newIDs = currentIDs.filter(
          (t: any) => Number(t) !== Number(schedule.id)
        );
      } else {
        newIDs = [...currentIDs, schedule.id];
      }

      const response = await fetch(
        URL + "/backend/schedules/edit-template-binding.php",
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            id: id,
            scheduleIDs: newIDs.join(","),
          }),
        }
      );
      const result = await response.json();
      setUpdate((prev:number) => prev + 1);
      return result;
    }
  }

  return (
    <div className="templateEditor">
      <button style={{ padding: "10px 20px" }} onClick={() => set(false)}>
        Powrót
      </button>
      <div>
        {schedule.name} {schedule.id}
      </div>

      {templates ? (
        <div>
          {templates.map((template: any) => (
            <div className={"templateRow" + (template.scheduleIDs?.split(",")?.find((s:any) => Number(s) === Number(schedule.id)) ? " active" :"")}>
            
              <div
                className="selector"
                onClick={() => modifySchedule(Number(template.id))}
              >
                ✓
              </div>
              <div>{template.name}</div>
              <div>{template.time1.slice(0, 5)}</div>
              <div>{template.time2.slice(0, 5)}</div>
              <div
                style={{
                  height: "40px",
                  background: template.color,
                }}
              ></div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
