import { useState } from "react";
import { formField } from "../formContentType";

export default function MultiAdder({ schema }: { schema: formField }) {
  const [editorOpen, setEditorOpen] = useState(false);

  return (
    <div
      className="multi-add-editor-wrapper"
      style={!editorOpen ? { padding: 0 } : undefined}
    >
      <button type="button" onClick={() => setEditorOpen((prev) => !prev)}>
        {!editorOpen ? <>Dodaj zestaw danych</> : <>{"<"}</>}
      </button>
      {!editorOpen ? <div style={{ fontSize: "9px" }}>
        W tym polu będzie można wybrać gotowe zestawy danych - np. potrawy,
        napoje, typowe pola, albo dodać własne
      </div> : <></>}
      {editorOpen ? (
        <div className="multi-add-editor">
          <div>
            <label>Wybierz rodzaj</label>
            <select>
              <option value="0">Pole z opisem</option>
              <option value="1">Wykaz pozycji</option>
              <option value="1">Lista liczbowa</option>
              <option value="2">Harmonogram</option>
            </select>
            <input type="text" placeholder={"Nazwa pola"} />
          </div>

          <button type="button">Dodaj</button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
