import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { UserContext } from "./contexts/UserContext";
import { ViewContext } from "./contexts/ViewContext";
import insertGeneric from "./dataFetch/insertGeneric";
import useGeneric from "./dataFetch/useGeneric";
import FieldAndLabel from "./forms/FieldAndLabel";

export default function Users() {
  const [update, setUpdate] = useState(0);
  const users = useGeneric({ name: "userList", limit: 100, update: update });
  const loggedIn = useContext(UserContext);
  const viewContext = useContext(ViewContext);
  const sysComponents = [
    {
      name: "Register",
      label: "Rejestracja użytkowników",
    },
    { name: "Users", label: "Edycja użytkowników" },
    { name: "Attendance", label: "Czas pracy" },
    { name: "ErrorRegister", label: "Rejestry księgowe" },
    { name: "Calendar", label: "Kalendarz" },
    { name: "Procedures", label: "Procedury" },
    { name: "Tasks", label: "Zadania" },
    { name: "Chat", label: "Czaty" },
  ];
  const permissionOptions = [
    {
      id: 0,
      name: "ukryty",
      active: "1",
    },
    {
      id: 1,
      name: "podgląd ograniczony",
      active: "1",
    },
    {
      id: 2,
      name: "podgląd rozszerzony",
      active: "1",
    },
    { id: 3, name: "podgląd pełny", active: "1" },
    { id: 4, name: "edycja ograniczona", active: "1" },
    { id: 5, name: "edycja rozszerzona", active: "1" },
    { id: 6, name: "edycja pełna", active: "1" },
    { id: 7, name: "programista", active: "1" },
  ];

  return (
    <div className="userListWrapper">
      <h1>Zarządzanie użytkownikami systemu</h1>
      <div className="userList">
        {!users.loading ? (
          <>
            <div className="user" style={{ fontWeight: "bold" }}>
              <div>ID</div>
              <div>Nazwa</div>
              <div>Imię</div>
              <div>Nazwisko</div>
              <div>Działania</div>
            </div>
            {users.rows.map((user: any) => (
              <div
                key={user.userID}
                className={
                  "user" + (user.blocked === 1 ? " inactive" : " active")
                }
              >
                <div>{user.userID}</div>
                <div>{user.username}</div>
                <div>{user.first_name}</div>
                <div>{user.last_name}</div>
                <div>
                  {user.userID === loggedIn.userID ? (
                    <></>
                  ) : user.blocked !== 1 ? (
                    <button
                      className={
                        "button big" + (user.type === 0 ? " inactive" : "")
                      }
                      onClick={() => {
                        if (
                          user.type !== 0 &&
                          window.confirm(
                            "Czy na pewno chcesz zablokować użytkownika " +
                              user.username +
                              "?"
                          )
                        ) {
                          insertGeneric("modify-user", {
                            blocked: 1,
                            userID: user.userID,
                          });
                          setUpdate((prev: number) => prev + 1);
                        }
                      }}
                    >
                      Blokuj
                    </button>
                  ) : (
                    <button
                      className="button big"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Czy na pewno chcesz odblokować użytkownika " +
                              user.username +
                              "?"
                          )
                        ) {
                          insertGeneric("modify-user", {
                            blocked: 0,
                            userID: user.userID,
                          });
                          setUpdate((prev: number) => prev + 1);
                        }
                      }}
                    >
                      Odblokuj
                    </button>
                  )}
                  {user.type !== 0 || loggedIn.userID === 1 ? (
                    <button
                      className="button big"
                      onClick={() =>
                        viewContext.setModal({
                          show: true,
                          heading:
                            "Edycja uprawnień: " +
                            user.first_name +
                            " " +
                            user.last_name,
                          content: (
                            <div className="editor">
                              <h4 style={{ margin: "0" }}>{user.username}</h4>
                              <div>
                                <Formik
                                  initialValues={{
                                    Register: user.permissions[0],
                                    Users: user.permissions[1],
                                    Attendance: user.permissions[2],
                                    ErrorRegister: user.permissions[3],
                                    Calendar: user.permissions[4],
                                    Procedures: user.permissions[5],
                                    Tasks: user.permissions[6],
                                    Chat: user.permissions[7],
                                  }}
                                  onSubmit={(v) => {
                                    const vString = Object.values(v).join("");
                                    insertGeneric("permissions", {
                                      p: vString,
                                      userID: user.userID,
                                    });
                                    viewContext.setModal((prev: any) => ({
                                      ...prev,
                                      show: false,
                                    }));
                                    setUpdate((prev: number) => prev + 1);
                                  }}
                                >
                                  <Form>
                                    <div
                                      style={{
                                        display: "grid",
                                        gridGap: "20px",
                                        gridTemplateColumns: "1fr 1fr",
                                      }}
                                    >
                                      {sysComponents.map((c: any) => {
                                        if (
                                          user.type === 0 &&
                                          c.name === "Users"
                                        ) {
                                          <></>
                                        } else
                                          return (
                                            <FieldAndLabel
                                              name={c.name}
                                              as="select"
                                              label={c.label}
                                              optionData={permissionOptions}
                                            />
                                          );
                                      })}
                                      <button className="button">Zmień</button>
                                    </div>
                                  </Form>
                                </Formik>
                              </div>
                            </div>
                          ),
                        })
                      }
                    >
                      Uprawnienia
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
