import { isToday, timeDiffJSX, timeDiffString } from "../views/viewFunctions";

export default function DateDisplay({
  dateString,
  format = "date",
  options = {
    singleLine: false,
    weekDay: undefined,
    month: "long",
  },
}: {
  dateString: string;
  format?: "date" | "datetime";
  options?: {
    singleLine?: boolean;
    weekDay?: "long" | "short" | "narrow" | undefined;
    month?: "long" | "short" | "narrow" | "numeric" | "2-digit" | undefined;
  };
}) {
  const date = new Date(dateString);
  const now = new Date();
  if (format === "datetime") {
    return (
      <>
        {dateString !== "0000-00-00 00:00:00" ? (
          <>
            <span className="date">
              {isToday(date) ? (
                <strong>Dzisiaj</strong>
              ) : (
                date
                  .toLocaleDateString("pl-PL", {
                    day: "numeric",
                    weekday: options?.weekDay ? options.weekDay : undefined,
                    month: options?.month ? options.month : "long",
                    year: "numeric",
                  })
                  .replace(" " + new Date().getFullYear(), "")
              )}
            </span>{" "}
            <span
              style={options?.singleLine ? { display: "inline" } : {}}
              className="time"
            >
              {options?.singleLine ? ", " : ""}
              {isToday(date)
                ? timeDiffJSX(now.getTime() - date.getTime())
                : date.toLocaleTimeString("pl-PL", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
            </span>
          </>
        ) : (
          <>-</>
        )}
      </>
    );
  } else if (format === "date") {
    return (
      <span>
        {isToday(date) ? (
          <strong>Dzisiaj</strong>
        ) : dateString !== "0000-00-00" && dateString !== null ? (
          date.toLocaleDateString("pl-PL", {
            day: "numeric",
            month: options?.month ? options.month : "2-digit",
            year: "numeric",
          })
        ) : (
          "brak daty"
        )}
      </span>
    );
  } else {
    return <>-</>;
  }
}
