import BooleanSymbol from "../../../tables/viewElements/BooleanSymbol";

export function row(row?: any): any[] {
  return [
    {
      source: "id",
      name: "Lp.",
      value: <>{row?.id}</>,
      style: { width: "40px" },
    },
    {
      source: "payer_last_name",
      name: "Imię i nazwisko wpłacającego",
      value: (
        <>
          {row?.payer_first_name} {row?.payer_last_name}
        </>
      ),
      style: { width: "340px", fontWeight: "bold" },
    },
    {
      source: "payment_date",
      name: "Data wpłaty",
      value: <>{row?.payment_date}</>,
    },
    {
      source: "amount",
      name: "Kwota",
      value: <>{row?.amount}</>,
    },
    {
      source: "service_name",
      name: "Rodzaj imprezy",
      value: <>{row?.service_name}</>,
    },
    {
      source: "service_performance_date",
      name: "Data imprezy",
      value: <>{row?.service_performance_date}</>,
    },
    {
      source: "receipt_number",
      name: "Nr paragonu",
      value: <>{row?.receipt_number}</>,
    },
    {
      source: "attached",
      name: "Paragon załączony",
      value: (
        <>
          <BooleanSymbol value={row?.attached} />
        </>
      ),
    },
    {
      source: "status",
      name: "Status",
      value: (
        <>
          <BooleanSymbol value={row?.status} />
        </>
      ),
    },
  ];
}
