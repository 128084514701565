import BooleanSymbol from "../viewElements/BooleanSymbol";
import { viewTypes } from "./types/viewTypes";

export default function employeeGroupsView(item: any): { columns: viewTypes } {
  const columns = item
    ? [
        {
          ref: "id",
          name: "Lp.",
          jsx: <>{item.id}</>,
          style: { width: "40px" },
        },
        {
            ref: "location",
            name: "Lokalizacja",
            style: { width:"100px", fontWeight:"bold" as const },
            jsx: <>{item.location}</>,
          },
        {
          ref: "name",
          name: "Nazwa",
          style: { textAlign: "left" as const },
          jsx: <>{item.name}</>,
        },
       
        {
          ref: "active",
          name: "Aktywne",
          style: { width: "50px", cursor: "pointer" },
          jsx: <BooleanSymbol value={item.active} />,
        },
      ]
    : [
        {
          ref: "nope",
          name: "Brak danych",
          jsx: <>Brak danych</>,
        },
      ];

  return { columns: columns };
}
