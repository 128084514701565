import { parseJsonSourceFileConfigFileContent } from "typescript";
import { URL } from "../paths";

export default async function setActive(id: string, active: string, name: string) {
  const response = await fetch(`${URL}backend/inserts/set-active.php`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      db: name,
      active: parseInt(active) === 0 ? "1" : "0",
      id: id
    }),
  });
  return  await response.json();
}
