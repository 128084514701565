import { holidaysISO } from "../calendar/easter";

export function arePrevHolidays(date: Date, space: number | undefined) {
    if (space === undefined) return;
    const d1 = new Date(date);
    const holidays = holidaysISO(d1.getFullYear());
    
    let lastHoliday = false;
    if (space > 5) return false;
    for (let i = 1; i < space; i++) {
      if (i === 1 || lastHoliday === true) {
        d1.setDate(date.getDate() - i);
        lastHoliday =
          d1.getDay() === 6 ||
          d1.getDay() === 0 ||
          holidays.includes(d1.toISOString().slice(0, 10));
        //console.log(lastHoliday,d1.toLocaleDateString(), d1.getDay())
      }
    }
    //console.log(lastHoliday);
    return lastHoliday;
  }
  
  /**
   * This function checks whether the day before is also a specified day off
   * @param {*} dates
   * @param {*} separator the name of a property of the object featuring a date, which breaks groups when not the same
   * @returns
   */
  export function aggregateDates(dates: any[], separator?: string) {
    // create an empty erray to place the aggregated dates into
    const aggregates: any = [];
  
    // loop through the dates, which crucially feature additional information, to sort out the groups
    // the actual dates - year-month-day are strings in the property 'date'
    dates.forEach((item, index, arr) => {
      const prev = arr[index - 1];
      const next = arr[index + 1];
  
      const prevDate = prev?.date ? new Date(prev.date) : undefined;
      const nextDate = next?.date ? new Date(next.date) : undefined;
  
      const currDate = new Date(item.date);
      const dayAfter = new Date(currDate);
      dayAfter.setDate(currDate.getDate() + 1);
      const dayBefore = new Date(currDate);
      dayBefore.setDate(currDate.getDate() - 1);
  
      const dates = {
        prev: prev ? new Date(prev.date).toLocaleDateString() : undefined,
        curr: new Date(item.date).toLocaleDateString(),
        next: next ? new Date(next.date).toLocaleDateString() : undefined,
        space: prev && prevDate ? (currDate.getTime() - prevDate.getTime()) / 60 / 60 / 24 / 1000 : undefined,
      };
      //console.log(dates);
  
      const separateHere = separator && prev && prev[separator] !== item[separator];
      //console.log(separateHere ? "sep-" + item.date : item.date + "-no-sep");
      
      // create new group
      if (separateHere) {
        aggregates.push([item]);
      }
      // continue group
      else if (dates.space === 1 || arePrevHolidays(currDate, dates.space)) {
        aggregates.at(-1).push(item);
      } 
      // create new group
      else {
        aggregates.push([item]);
      }
    });
    return aggregates;
  }
  