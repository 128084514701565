export function row(row?: any): any[] {
  return [
    {
      name: "ID",
      source: "id",
      value: row?.id,
      className: "id",
    },
    {
      name: "Inicjały",
      source: "initials",
      value: row?.initials?.toUpperCase(),
      style: {
        width: "40px",
        fontSize: "10px",
      },
    },
    {
      name: "Imię",
      source: "first_name",
      value: row?.first_name,
      style: {
        width: "15%",
      },
      cardValueStyle: {
        color: "#0c6193",
        fontFamily:"monospace"
      },
    },
    {
      name: "Nazwisko",
      source: "last_name",
      value: row?.last_name,
      style: {
        width: "20%",
      },
      cardValueStyle: {
        color: "#0c6193",
        fontFamily:"monospace"
      },
    },
    {
      name: "Grupa",
      source: "group_name",
      value: row?.group_name,
    },
    {
      name: "Dział",
      source: "location_string",
      value: row?.location_string,
    },
  ];
}
