import React, { useEffect } from 'react';
import preval from 'preval.macro';

export const buildTimeString = preval`module.exports = new Date().toLocaleString();`;

export default function BuildTime() {
  // generating a constant string during buildtime and store in a constant variable
  // this value will not change once CRA is bundled
  const buildTimestamp = preval`module.exports = new Date().toLocaleString();`;
  return (
      <div>Wersja {buildTimestamp}</div>
    )
}