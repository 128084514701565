import { useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import { aggregateDates } from "../functions/dates";
import HolidayReportList from "./holidayReports/HolidayReportList";
import HolidayReportTable from "./holidayReports/HolidayReportTable";
import SelectHolidayType from "./SelectHolidayType";

export default function HolidayReports({
  location,
  date,
  setDate,
  holidayTypes,
  sumType,
  setSumType,
}: {
  location: string;
  date: any;
  setDate: Function;
  holidayTypes: any;
  sumType: number;
  setSumType: Function;
}) {
  const [viewType, setViewType] = useState<"table" | "list" | "monthList">(
    "table"
  );
  const fetched = useGeneric({
    name: "holidayReportByPerson",
    limit: 0,
    query: { year: date.year },
    reload: true,
  });

  const loading = fetched.loading;
  const data = !loading ? fetched.rows : undefined;
  const selectedData =
    data &&
    data.filter(
      (d: any) =>
        (sumType === 0 ? true : d.type === sumType) &&
        (location === "hotel" ? d.location === 0 : d.location === 1)
    );

      //console.log(selectedData);


  function groupBy(list: any, keyGetter: Function) {
    const map = new Map();
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  const byPerson = selectedData && groupBy(selectedData, (gr: any) => gr.employeeID);

  const byPersonArray: any = [];
  byPerson?.forEach((item: any) =>
    byPersonArray.push({
      id: item[0].id,
      first_name: item[0].first_name,
      last_name: item[0].last_name,
      location: item[0].location,
      groups: item.flat().map((group: any) => ({
        type: group.type,
        dates: group.dates.split(","),
        month: group.month - 1,
        year: group.year,
        name: group.name,
        shortName: group.short_name,
        abbr: group.abbr,
        count: group.count,
      })),
    })
  );
//  console.log(byPersonArray);

  return (
    <div className="reportsWrapper">
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-start",
        }}
      >
        <div>
          <button
            className={"button" + (viewType === "table" ? " active" : "")}
            onClick={() => setViewType("table")}
          >
            Zestawienie
          </button>
          <button
          style={{ marginRight: "20px" }}
            className={"button" + (viewType === "list" ? " active" : "")}
            onClick={() => setViewType("list")}
          >
            Wykaz miesięczny
          </button>
         {/* <button
            style={{ marginRight: "10px" }}
            className={"button" + (viewType === "monthList" ? " active" : "")}
            onClick={() => setViewType("monthList")}
          >
            Wykaz roczny
      </button>*/}
        </div>
        <SelectHolidayType
          all={true}
          def={0}
          setSumType={setSumType}
          holidayTypes={holidayTypes}
        />
      </div>
      {viewType === "table" ? (
        <HolidayReportTable
          byPersonArray={byPersonArray}
          aggregateDates={aggregateDates}
        />
      ) : (
        <HolidayReportList
          byPersonArray={byPersonArray}
          aggregateDates={aggregateDates}
          viewType={viewType}
          date={date}
          setDate={setDate}
        />
      )}
    </div>
  );
}
