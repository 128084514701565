import Collection from "./Collection/Collection";

export default function DM_Employees() {
  return (
    <Collection
      source="employeesGeneric"
      cells={[
        { column: "id", head: "Lp.", view: (v: any) => <strong>{v}</strong> },
        {
          column: "name",
          head: <>Imię i nazwisko</>,
          view: (v: any, vs: any) => (
            <>
              <span style={{ fontStyle: "italic" }}>{v}</span>
            </>
          ),
        },
        { column: "initials", head: "Inicjały" },
        {
          column: "sex",
          head: "Płeć",
          view: (v: any) => <>{v === 0 ? "kobieta" : "mężczyzna"}</>,
        },
        // { name: "year", head: "Rok" },
      ]}
    />
  );
}
