import { useContext, useEffect, useState } from "react";
import { ViewContext } from "./contexts/ViewContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useGeneric from "./dataFetch/useGeneric";
import minutesToJSX from "./functions/minutesToJSX";
import Value from "./employeeFiles/Value";
import Contract from "./employeeFiles/Contract";
import HolidaySummary from "./employeeFiles/HolidaySummary";
import Medicals from "./employeeFiles/Medicals";
import Bhp from "./employeeFiles/Bhp";
import dropDownIcon from "../images/icons/dropdown.svg";
import Attendance from "./Attendance";
import AttReport from "./employeeFiles/AttReport";
import MedicalsReport from "./employeeFiles/MedicalsReport";
import ContractDetails from "./employeeFiles/ContractDetails";
import BhpReport from "./employeeFiles/BhpReport";
import Sanitary from "./employeeFiles/Sanitary";
import SanitaryDetails from "./employeeFiles/SanitaryDetails";
import EmployeeCards from "./EmployeeCards";

export default function EmployeeFiles() {
  const { setCurrentPage, modal, setModal } = useContext(ViewContext);
  setCurrentPage("employee-files");

  const browseLocation = useLocation();
  const navigate = useNavigate();
  const pathURL = browseLocation.pathname.split("/").at(-2);
  const employeeURL = browseLocation.pathname.split("/").at(-1);

  const [location, setLocation] = useState(
    pathURL === "biuro" ? "biuro" : "hotel"
  );

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [filters, setFilters] = useState<"editor" | "table">();

  const [subView, setSubView] = useState("contract");

  const [selectedEmployee, setSelectedEmployee] = useState(Number(employeeURL));

  // useEffect(() => {
  //    setSelectedEmployee(Number(employeeURL));
  // }, [employeeURL]);

  const [updates, setUpdates] = useState(0);

  const fetchEmployees = useGeneric({
    name: "employeesAdvanced",
    limit: 500,
    query: {
      location: pathURL === "biuro" ? 1 : 0,
      active: 1,
    },
    update: updates,
  });

  const employees = !fetchEmployees.loading && fetchEmployees?.rows;
  const employee =
    employees && employees?.find((e: any) => e.id === selectedEmployee);

  return (
    <div className="standardWrap employeeFiles" key={employee?.id}>
      <header>
        <div className="main-header-field">
          <h1
            style={{
              background:
                "linear-gradient(168deg, rgb(29, 153, 141), rgb(157, 34, 255)) text",
              fontSize: "25px",
              // backgroundClip: "text",
              color: "transparent",
              padding: "5px 10px",
              textShadow: "1px 1px 1px rgba(0,0,0,.13)",
            }}
          >
            Kadry
          </h1>

          <div className="filters">
            <Link
              to="../employee-files-summaries"
              style={{ padding: "4px" }}
              unstable_viewTransition
            >
              <div
                className={"filter" + (filters === "table" ? "active" : "")}
                onClick={() => setFilters("table")}
              >
                Tabela terminów
              </div>{" "}
            </Link>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <Link to={"./hotel/"} unstable_viewTransition>
            <button
              className={
                "button huge hotel" + (location === "hotel" ? " active" : "")
              }
              onClick={() => {
                //   navigate("/employee-files/hotel");
                setLocation("hotel");
              }}
            >
              HOTEL
            </button>
          </Link>
          <Link to={"./biuro/"} unstable_viewTransition>
            <button
              className={
                "button huge biuro" + (location === "biuro" ? " active" : "")
              }
              onClick={() => {
                //   navigate("/employee-files/biuro");
                setLocation("biuro");
              }}
            >
              BIURO
            </button>
          </Link>
        </div>
        <div></div>
      </header>
      <main className={selectedEmployee ? "" : "full"}>
        <aside className={mobileMenuOpen ? "mobile-menu-open" : ""}>
          <div
            className="mobile-menu-button"
            onClick={() => setMobileMenuOpen((prev: Boolean) => !prev)}
          >
            <img src={dropDownIcon} />
          </div>
          <EmployeeCards
            employees={employees}
            selectedEmployee={selectedEmployee}
            setSelectedEmployee={setSelectedEmployee}
            setMobileMenuOpen={setMobileMenuOpen}
            location={location}
          />
        </aside>
        {selectedEmployee ? (
          <>
            <section>
              {employee ? (
                <div className="basic-employee-info">
                  <div className="employee-head">
                    <div className="employee-name">{employee?.name}</div>
                  </div>
                  <div className="employee-details">
                    <div className="employee-group">
                      <div className="labeled-value">
                        <label>Grupa</label>
                        <div>{employee?.group_name}</div>
                      </div>
                    </div>
                    <div className="details">
                      <Contract
                        employee={employee}
                        subView={subView}
                        setSubView={setSubView}
                        updates={updates}
                      />
                      <HolidaySummary
                        employee={employee}
                        subView={subView}
                        setSubView={setSubView}
                      />
                      <Medicals
                        employee={employee}
                        subView={subView}
                        setSubView={setSubView}
                        updates={updates}
                        setUpdates={setUpdates}
                      />
                      <Bhp
                        employee={employee}
                        subView={subView}
                        setSubView={setSubView}
                        updates={updates}
                        setUpdates={setUpdates}
                      />
                      <Sanitary
                        employee={employee}
                        subView={subView}
                        setSubView={setSubView}
                        updates={updates}
                        setUpdates={setUpdates}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{ padding: "10px", fontSize: "17px", color: "grey" }}
                >
                  Nie wybrano pracownika
                </div>
              )}
            </section>
            <section>
              {employee &&
                employee.id &&
                (subView === "holidays" ? (
                  <AttReport
                    id={employee.id}
                    holiday={employee.holiday_relevant}
                    employee={employee}
                    updates={updates}
                    setUpdates={setUpdates}
                  />
                ) : subView === "medicals" ? (
                  <MedicalsReport
                    employee={employee}
                    updates={updates}
                    setUpdates={setUpdates}
                  />
                ) : subView === "bhp" ? (
                  <BhpReport
                    employee={employee}
                    updates={updates}
                    setUpdates={setUpdates}
                  />
                ) : subView === "contract" ? (
                  <ContractDetails
                    key={employee?.id}
                    employee={employee}
                    updates={updates}
                    setUpdates={setUpdates}
                  />
                ) : subView === "sanitary" ? (
                  <SanitaryDetails
                    employee={employee}
                    updates={updates}
                    setUpdates={setUpdates}
                  />
                ) : (
                  <></>
                ))}
            </section>
          </>
        ) : (
          <></>
        )}
      </main>
    </div>
  );
}
