import { useEffect, useState } from "react";
import { flatOptions } from "../../functions/tools";
import {
  meal_type,
  schedule_item_type,
  special_options,
} from "../definitions/common";
import insertGeneric from "../../dataFetch/insertGeneric";

export default function MealItem({ item, event }: { item: any; event: any }) {
  const [highlighted, setHighlighted] = useState(item?.ready);
  const category = flatOptions(meal_type).find(
    (n: any) => n.id === item.category
  )?.name;
  const special = flatOptions(special_options).find(
    (n: any) => n.id === item.special
  )?.name;

  useEffect(() => {
    setHighlighted(item?.ready);
  }, [item?.ready])

  function setReady() {
    const send = insertGeneric("meal-highlights", {
      meal_id: item?.id,
      event_id: event?.id,
      ready: !highlighted
    });
    console.log(send);
    setHighlighted((prev: Boolean) => !prev);
  }

  return (
    <div
      className={highlighted ? "highlight" : ""}
      onClick={setReady}
      
    >
      <div className="category">
        {item.time && item.time !== "00:00:00" ? (
          <span className="time">{item.time?.slice(0, 5)}</span>
        ) : (
          <></>
        )}
        {category}
        {special ? <span className="special">{special}</span> : <></>}
        {item.quantity ? (
          <span className="quantity">{item.quantity} os.</span>
        ) : (
          <></>
        )}
      </div>
      <div>{item.name}</div>
    </div>
  );
}
