import { useContext, useEffect, useRef, useState } from "react";
import { CalendarContext } from "../contexts/CalendarContext";
import { ViewContext } from "../contexts/ViewContext";
import insertGeneric from "../dataFetch/insertGeneric";
import Event from "./Event";
import { eventForm } from "./addEventFormUpdated";
import nothingIcon from "../../images/icons/nothing.svg";
import Form from "../forms_new/Form";
import { calendarEntry } from "./calendarEntry";
import LocalHistory from "../forms_new/Elements/LocalHistory";
import EventInList from "./EventInList";
import { isToday } from "../functions/prettyDate";
import EventHistory from "./EventHistory";
import openEditor from "./openEditor";
import EventInTable from "./EventInTable";
import { addEventFormPotential } from "./addEventFormPotential";
import TableDay from "./TableDay";
import AddPotentialButton from "./AddPotentialButton";
import addEventIcon from "../../images/icons/add_full.svg";
import { objectOrJSONparse } from "../functions/tools";

export default function Day({
  date,
  type,
  list,
  expand,
}: {
  date: {
    obj: Date;
    events: any;
    potentialEvents?: any;
    dw?: string;
    holiday?: boolean;
  };
  type?: string;
  list?: Boolean;
  expand?: Boolean;
}) {
  const calendar = useContext(CalendarContext);
  const { setModal } = useContext(ViewContext);
  const { auth } = calendar;
  const day = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (day.current && day.current.id) {
      const id = day.current.id.replace("d", "");
      if (new Date().getDate() === Number(id)) {
        day?.current?.scrollIntoView({
          //behavior: "instant",
          inline: "nearest",
          block: "start",
        });
      }
    }
  }, [calendar.mode]);

  const modal = useContext(ViewContext);
  date.obj.setHours(10);
  return calendar.mode === "table" ? (
    <TableDay ref={day} date={date} auth={auth} calendar={calendar} />
  ) : calendar.mode === "list" ? (
    <div
      ref={day}
      id={"d" + date.obj.getDate()}
      className={
        "day" +
        (!date.events.length ? " empty-day" : "") +
        (isToday(date?.obj) ? " today" : "")
      }
    >
      <div className="day-info">
        <div className="day-info-date">
          <div className="day-info-weekday">
            {date.obj.toLocaleDateString("pl-PL", { weekday: "long" })}
          </div>
          <div>
            {date.obj.toLocaleDateString("pl-PL", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </div>
        </div>
        {auth?.add ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <AddPotentialButton
              setModal={setModal}
              auth={auth}
              date={date}
              calendar={calendar}
            />
            <div
              className="add"
              onClick={() =>
                openEditor(
                  undefined,
                  date,
                  { modal: modal, calendar: calendar },
                  auth
                )
              }
              title={"Dodaj wydarzenie"}
            >
              + <span className="long-text-desktop">NOWA IMPREZA</span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="events">
        <div className="currentEvents">
          {date.events.length ? (
            date.events.map((i: any) => (
              <EventInList
                key={i?.id + "_" + date?.obj?.toISOString()?.slice(0, 10)}
                event={i}
                editor={openEditor}
                list={true}
                date={date.obj}
              />
            ))
          ) : (
            <></>
          )}
          {date.potentialEvents.length ? (
            date.potentialEvents.map((i: any, index: number) => {
              const dates = objectOrJSONparse(i?.dates);
              console.log(dates, date);
              const whichDay =
                dates && dates.length > 1
                  ? dates
                      .map((d: any) => d.date)
                      .indexOf(date?.obj?.toISOString().slice(0, 10)) + 1
                  : undefined;
              return (
                <EventInList
                  key={i?.id + "_" + date?.obj?.toISOString()?.slice(0, 10)}
                  event={i}
                  editor={openEditor}
                  list={true}
                  date={date.obj}
                  // index={index}
                  potential={true}
                />
                // <tr className="potential-event">
                //   <td>%</td>
                //   <td>{i.event_type_string}</td>
                //   <td>{i.venue_string}</td>
                //   <td colSpan={2} style={{ background: "transparent" }}>
                //     <div style={{textTransform:"uppercase", fontSize:"11px", color:"grey", background:"white"}}>robocze</div>
                //   </td>
                //   <td style={{ textAlign: "left", padding: "0 5px" }}>
                //     {i?.is_company
                //       ? i?.company_name
                //       : i?.first_name + " " + i?.last_name}
                //   </td>
                //   <td colSpan={2}>{i?.comment}</td>
                //   <td>{whichDay}</td>
                //   <td></td>
                //   <td></td>
                // </tr>
              );
            })
          ) : (
            <></>
          )}
        </div>

        {!date?.events?.length && !date?.potentialEvents?.length ? (
          <div
            style={{
              padding: "18px 30px",
              margin: "0 auto",
              justifyContent: "flex-start",
              opacity: ".75",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img style={{ width: "24px", opacity: ".5" }} src={nothingIcon} />
            <div
              style={{
                marginLeft: "15px",
                fontSize: "13px",
                fontWeight: "100",
              }}
            >
              brak imprez
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <div
      className={
        "day" +
        (type === "other" ? " otherMonth" : "") +
        (calendar.mode === "week" ? " weekView" : "") +
        (date.holiday ? " stateHoliday" : "")
      }
      style={{
        height: expand || calendar.mode === "week" ? "fit-content" : "99px",
      }}
    >
      <div
        className="dayData"
        style={{ fontWeight: date.events.length ? "700" : "inherit" }}
      >
        {calendar.mode === "week" ? (
          <></>
        ) : (
          <>
            <div
              style={{ cursor: "pointer" }}
              className={
                "calendar-day-number" + (isToday(date.obj) ? " today" : "")
              }
              onClick={() => {
                modal.setModal({
                  show: true,
                  heading: date.obj.toLocaleDateString("pl-PL", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  }),
                  content: (
                    <div className="calendarSingle">
                      <div className="calendar">
                        <div className="day">
                          <div className="events">
                            <div className="currentEvents">
                              {date.events.map((i: any) => (
                                <Event
                                  key={i?.id}
                                  event={i}
                                  editor={openEditor}
                                  date={date?.obj}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ),
                });
              }}
            >
              {date.obj.toLocaleDateString("pl-PL", { day: "numeric" })}
              <div className="mobile">{date.obj.toLocaleDateString("pl-PL", { weekday: "short" })}</div>
            </div>
            <div className="eventDots">
              {date.events.map((dot: any, index: number) => (
                <div key={index} style={{ color: dot.color }}>
                  •
                </div>
              ))}
            </div>
          </>
        )}
        {auth?.add ? (
          <>
            <div
              className="add"
              onClick={() =>
                openEditor(
                  undefined,
                  date,
                  { modal: modal, calendar: calendar },
                  auth
                )
              }
              title={"Dodaj wydarzenie"}
            >
              +
            </div>
            <AddPotentialButton
              setModal={setModal}
              auth={auth}
              date={date}
              calendar={calendar}
              short={true}
            />
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="events">
        <div className="currentEvents">
          {date.events.map((i: any) => (
            <Event key={i?.id} event={i} editor={openEditor} date={date?.obj} />
          ))}
          {date?.potentialEvents.map((i: any) => (
            <Event key={i?.id} event={i} editor={openEditor} date={date?.obj} potential={true}/>
          ))}
        </div>
      </div>
    </div>
  );
}
