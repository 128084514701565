import { useContext } from "react";
import Options from "./Options";
import { RegisterViewContext } from "./View";
import { ItemContext } from "./ViewItem";

export default function ViewCardItem() {
  const { orderColumn } = useContext(RegisterViewContext);
  const { schema, row, subRow, setSubRow } = useContext(ItemContext);
  return (
    <div className="card">
      <Options row={row} type="cards" />
      <div
        className={
          "card-inner " +
          schema?.find((c: any) => c?.rowClassName)?.rowClassName
        }
      >
        {schema?.map((cell: any) => (
          <div
            className={cell?.className}
            style={{
              opacity: row?.active ? 1 : 0.6,
              ...cell?.cardStyle,
              background:
                cell?.source &&
                orderColumn === cell?.source &&
                "rgba(255, 255, 157,.3)",
            }}
          >
            <div
              className={
                "value-pair" + (cell?.className ? " " + cell?.className : "")
              }
            >
              <div className="column">{cell?.name}</div>
              <div style={cell?.cardValueStyle} className="value">
                {typeof cell?.value === "function"
                  ? cell?.value(row)
                  : cell?.value}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
