import useGeneric from "./dataFetch/useGeneric";
import prettyDate from "./functions/prettyDate";

export default function SessionsLSI() {
  const fetchSessions = useGeneric({
    name: "lsi_sessions",
    reload: true,
    secondsToReload: 15,
  });
  const sessions = !fetchSessions?.loading && fetchSessions?.rows;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {sessions ? (
        <table className="table-layout high">
          <thead>
            <th>Aplikacja</th>
            <th>Użytkownik</th>
            <th>Początek sesji</th>
            <th>Koniec sesji</th>
            <th>Brak połączenia</th>
          </thead>
          <tbody>
            {sessions?.map((s: any) => {
              const notAnyMore =
                new Date(s.data_do).getTime() > new Date(2000, 1, 1).getTime();

              return (
                <tr
                  style={
                    notAnyMore
                      ? { opacity: ".5" }
                      : { background: s.POS ? "#c8ffa9" : "#daffd2" }
                  }
                >
                  <td style={{ width: "150px" }}>
                    {s.POS ? s.POS : s.nazwa ? <>{s.nazwa}</> : <>-</>}
                  </td>
                  <td>
                    {s.imie} {s.nazwisko}
                  </td>
                  <td>
                    {new Date(s.data_od) > new Date(2000, 1, 1) &&
                      prettyDate(s.data_od)}
                  </td>
                  <td>{notAnyMore && prettyDate(s.data_do)}</td>
                  <td>{s.FlgBrakPolaczenia ? "X" : ""}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <></>
      )}
    </div>
  );
}
