export default function HolidayReportTable({
  byPersonArray,
  aggregateDates
}: {
  byPersonArray: any[];
  aggregateDates: any;
}) {
  return (
    <table className="data-table holiday-report">
      <thead>
        <tr>
          <th style={{ textAlign: "left" }}>Nazwisko i imię</th>
          <th style={{ width: "8px", padding: 0 }}>Suma</th>
          <th>Sty</th>
          <th>Lut</th>
          <th>Mar</th>
          <th>Kwi</th>
          <th>Maj</th>
          <th>Cze</th>
          <th>Lip</th>
          <th>Sie</th>
          <th>Wrz</th>
          <th>Paź</th>
          <th>Lis</th>
          <th>Gru</th>
        </tr>
      </thead>
      <tbody>
        {byPersonArray?.map((item: any) => {
          const months: any[] = [
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
          ];
          item.groups.forEach((group: any) =>
            group.dates.forEach((date: any) => {
              months[new Date(date).getMonth()].push({
                type: group.type,
                typename: group.name,
                typeshort: group.shortName,
                date: date,
                count: group.count,
              });
            })
          );
          return (
            <tr>
              <>
                <td className="name">
                  {item.last_name}, {item.first_name}
                </td>
                <td
                  className="count tooltip"
                  /*title={item.groups
                .map((i: any) => i.name + ": " + i.count)
                .join("\r\n")}*/
                >
                  <div className="top" style={{ padding: 0 }}>
                    <div className="listy" style={{ overflow: "hidden" }}>
                      <div>
                        {item.first_name} {item.last_name}
                      </div>
                      {item.groups.map((i: any) => (
                        <div
                          style={{
                            display: "flex",
                            padding: "4px",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>{i.name}</span> <strong>{i.count}</strong>
                        </div>
                      ))}
                    </div>
                    <i></i>
                  </div>
                  {item.groups
                    .map((i: any) => i.count)
                    .reduce((a: number, b: number) => a + b)}
                </td>
                {months.map((dates: any) => {
                  return (
                    <td style={{ padding: 0 }}>
                      <div className="inCell">
                        <div className="tableRanges">
                          {dates &&
                            aggregateDates(dates).map((dateRange: any) => {
                              const localeParams: Intl.DateTimeFormatOptions = {
                                day: "numeric",
                              };
                              const typename = dateRange[0].typename;
                              const type = dateRange[0].type;
                              const date1 = dateRange[0].date;
                              const date2 = dateRange.at(-1).date;
                              const rangeJSX =
                                date1 === date2 ? (
                                  <>
                                    {new Date(date1).toLocaleDateString(
                                      "pl-PL",
                                      localeParams
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {new Date(date1).toLocaleDateString(
                                      "pl-PL",
                                      localeParams
                                    )}
                                    -
                                    {new Date(date2).toLocaleDateString(
                                      "pl-PL",
                                      localeParams
                                    )}
                                  </>
                                );

                              return (
                                <div
                                  className={"holRange tooltip" + " u" + type}
                                >
                                  {rangeJSX}
                                  <div
                                    className="top"
                                    style={{
                                      padding: "2px 5px",
                                      borderRadius: "0",
                                      fontSize: "10px",
                                      maxHeight: "70px",
                                      overflowY: "auto",
                                      textAlign: "left",
                                    }}
                                  >
                                    <h4
                                      style={{
                                        margin: 0,
                                        fontSize: "10px",
                                      }}
                                    >
                                      {typename + " (" + dateRange.length + ")"}
                                    </h4>
                                    <div>
                                      {dateRange.map((d: any) => (
                                        <div>
                                          {new Date(d.date).toLocaleDateString(
                                            "pl-PL",
                                            {
                                              day: "numeric",
                                              month: "long",
                                            }
                                          )}
                                          ,{" "}
                                          {new Date(d.date).toLocaleDateString(
                                            "pl-PL",
                                            {
                                              weekday: "short",
                                            }
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        {dates.length > 0 ? (
                          <div className="monthlyTotal">
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((i: number) => {
                              const typeGroup = dates.filter(
                                (f: any) => f.type === i
                              );
                              return typeGroup.length !== 0 ? (
                                <div
                                  className={"sumCell u" + i}
                                  title={typeGroup[0].typename}
                                >
                                  {typeGroup.length}
                                </div>
                              ) : (
                                <></>
                              );
                            })}
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </td>
                  );
                })}
              </>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
