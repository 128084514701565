import { useContext, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormDataContext } from "./Form";
import { ViewContext } from "../contexts/ViewContext";
import save from "./Actions/save";
import ChangeRow from "./ChangeRow";
import { getFromSchema, getSubKeys } from "./useCompare";
import useSuperFlatSchema from "../calendar/useSuperFlatSchema";

export default function ConfirmChanges({
  methods,
  custom,
  originalValues,
  submit,
  sideEffects,
  setApiError,
  options,
  tempID
}: {
  methods: any;
  custom: any;
  originalValues: any;
  submit?: Function;
  sideEffects: any;
  setApiError: Function;
  options?: any;
  tempID?: any;
}) {
  const { modal2, setModal2 } = useContext(ViewContext);

  const {
    handleSubmit,
    formState: { errors, touchedFields },
    setFocus,
    watch,
    setValue,
    getValues,
  } = methods;

  const { touchedMultiAdds, flatSchema, data, customData } = custom;

  const [reasonValue, setReasonValue] = useState("");

  Object.keys(data).forEach((i: any) => {
    if (customData?.[i]?.shouldSubmit === false) {
      data[i] = "";
    }
  });

  const superFlat = useSuperFlatSchema(flatSchema);
  // console.log(superFlat);

  const originalInit = getFromSchema(originalValues, superFlat);
  const proposedInit = getFromSchema(getValues(), superFlat);
  // console.log(originalInit);

  const { original, proposed, superFlatSchema } = getSubKeys(
    originalInit,
    proposedInit,
    superFlat,
    ["%"]
  );

 

   const originalVal = originalValues;
  const proposedVal = getValues();

  // console.log(
  //   "schema",
  //   superFlatSchema,
  //   "original",
  //   originalVal,
  //   "proposed",
  //   proposedVal
  // );

  fillSchemaWithSub();

  function fillSchemaWithSub() {
    const subNames = Object.keys(proposedVal).filter((key: any) => 
     key.match(/.*@.*@/));

    subNames.forEach((subName:string) => {
      const baseName = subName.match(/(.*)@(.*)@/)?.[1];
      const baseSchema = superFlatSchema.find((s:any) => s.name === baseName);
      superFlatSchema.push({...baseSchema, name: subName});
    })
  }
  // function getSubBack(data:any) {
  //   const newData = {...data};
  //   const subNames = Object.keys(newData).filter((key: any) => 
  //   key.match(/.*@.*@/));
  //   const bases:any = subNames.map((s:any) => s.match(/(.*)@(.*)@/)?.[1]);
  //   subNames.forEach((key:any) => {
  //     const baseName = key.match(/(.*)@(.*)@/)?.[1];
      
  //     if (baseName) {
  //       if (newData?.[baseName] && Array.isArray(newData[baseName])) {
  //         newData[baseName]?.push(JSON.parse(newData[key]));
  //       }
  //       else {
  //         newData[baseName] = JSON.parse(newData[key])
  //       }
  //     }
     
  //   });
  //   bases.forEach((key:string) => {
  //     if (key && newData[key] && Array.isArray(newData[key])) {
  //     newData[key] = JSON.stringify(newData?.[key]?.flat());
  //     }
  //   })
  //   console.log(newData);
  //   return newData;
    
  // }

  return (
    <div className="form-change-wrapper">
      <div style={{ maxHeight: "350px", overflowY: "auto" }}>
        <table className="data-table form-changes">
          <thead>
            <tr>
              <th style={{ width: "60px" }}>Pole</th>
              <th>Zmiany</th>
            </tr>
          </thead>
          <tbody>
            {superFlatSchema.map((prop: any) =>
              original && proposed ? (
                <ChangeRow
                  key={prop?.name}
                  flatSchema={superFlatSchema}
                  prop={prop?.name}
                  original={originalVal}
                  proposed={proposedVal}
                  options={options}
                />
              ) : (
                <></>
              )
            )}
          </tbody>
        </table>
      </div>
      <div className="form-change-reason-wrap">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            save(
              { ...data, reason: reasonValue },
              submit,
              sideEffects,
              setApiError,
              tempID
            );
            setModal2((prev: any) => ({
              ...prev,
              show: false,
            }));
          }}
        >
          <div style={{ marginBottom: "0px" }}>
            <label htmlFor="reason" style={{ fontSize: "15px" }}>
              Podaj przyczynę zmiany
            </label>
          </div>
          <textarea
            value={reasonValue}
            required={true}
            onChange={(e: any) => {
              setReasonValue(e.target.value);
            }}
            id="reason"
            className="form-change-reason"
          />
          <button
            type="submit"
            className="normal-button"
            style={{
              padding: "15px 45px",
            }}
          >
            Potwierdź
          </button>
        </form>
      </div>
    </div>
  );
}
