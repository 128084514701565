import { useState } from "react";
import { URL } from "../paths";

export default function AddEmployee({
    scheduleID,
  categories,
  employees,
  setUpdate
}: {
  scheduleID: number;
  categories: any;
  employees: any;
  setUpdate: Function;
}) {
  const [category, setCategory] = useState("");

  const [selectedEmployee, setSelectedEmployee] = useState("");

  async function insertEmployee() {
    if (!selectedEmployee) { return }
    const response = await fetch(URL + "backend/schedules/add-employee.php", {
        method: "POST",
        credentials:"include",
        body: JSON.stringify({
            employeeID: Number(selectedEmployee) ,
            id: scheduleID
        })
    });
    const result = await response.json();
    setUpdate((prev:number)=> prev+1);
    return result;
  }

  return (
    <div className="editor">
      <form>
        <div className="field">
          <label>Wybierz grupę pracowników</label>
          <select
            onChange={(e) => {
              setCategory(e.target.value);
            }}
          >
            <option></option>
            {categories &&
              categories.map((c: any) => <option value={c}>{c}</option>)}
          </select>
        </div>
        <div className="field">
          <label>Wybierz pracownika</label>
          <select onChange={(e) => setSelectedEmployee(e.target.value)}>
            <option></option>
            {employees &&
              employees.filter((e:any) => category ? e.group_name === category : true ).map((e: any) => (
                <option value={e.id} >{e.first_name} {e.last_name}</option>
              ))}
          </select>
        </div>
        <button className="button" onClick={(e) => {
            e.preventDefault();
            insertEmployee();
            }}>Dodaj</button>
      </form>
    </div>
  );
}
