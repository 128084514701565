import { useContext, useState } from "react";
import useGeneric from "../dataFetch/useGeneric";
import FoundItems from "./FoundItems";
import { CalendarContext } from "../contexts/CalendarContext";
import Info from "../common/Info";
import HamsterLoading from "../common/HamsterLoading";
import Loading from "../common/Loading";

export default function Search() {
  const [query, setQuery] = useState({});
  const [update, setUpdate] = useState(0);

  const { formData } = useContext(CalendarContext);

  const found = useGeneric({
    name: Object.keys(query).length ? "search-event-calendar" : "",
    limit: 100,
    query: query,
    update: update,
  });

  const foundItems = !found.loading && found.rows;

  function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();
    const formData = new FormData(e.target as any);
    const data = {
      event_type: formData.get("event_type"),
      venue: {
        value: formData.get("venue"),
        db: "sch",
      },
      active:
        formData.get("active") === "" ? undefined : formData.get("active"),
      settled:
        formData.get("settled") === "" ? undefined : formData.get("settled"),
      year: formData.get("year")
        ? {
            value: formData.get("year"),
            column: "d.date",
            yearQuery: true,
          }
        : undefined,
      // month: {
      //   value: formData.get("month"),

      // }
      customer: formData.get("customer"),
      // guest_number: formData.get("guest_number"),
    };
    setQuery(data);
    setUpdate((prev: number) => prev + 1);
  }

  const thisYear = new Date().getFullYear();
  return (
    <div className="calendar-search">
      <div className="search-box">
        <form onSubmit={handleSubmit}>
          <div className="calendar-search-group">
            <div>
              <label htmlFor="customer">Klient</label>
              <input id="customer" name="customer" type="search" />
            </div>
            <div>
              <label htmlFor="event_type">Typ imprezy</label>
              <select id="event_type" name="event_type">
                <option value=""></option>
                {formData &&
                  formData.multiple &&
                  formData.multiple.events &&
                  formData.multiple.events?.rows?.map((o: any) => (
                    <option value={o.id}>{o.name}</option>
                  ))}
              </select>
            </div>
            <div>
              <label htmlFor="venue">Sala</label>
              <select
                id="venue"
                name="venue"
                style={{ width: "100%", boxSizing: "border-box" }}
              >
                <option value=""></option>
                {formData &&
                  formData.multiple &&
                  formData.multiple.venues &&
                  formData.multiple.venues?.rows?.map((o: any) => (
                    <option value={o.id}>{o.name}</option>
                  ))}
              </select>
            </div>
            <div>
              <label htmlFor="year">Rok</label>
              <select id="year" name="year">
                <option value=""></option>
                {[
                  thisYear - 1,
                  thisYear,
                  thisYear + 1,
                  thisYear + 2,
                  thisYear + 3,
                  thisYear + 4,
                  thisYear + 5,
                ].map((year: number) => (
                  <option value={year}>{year}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="settled">Rozliczone</label>
              <select id="settled" name="settled">
                <option value=""></option>

                <option value="0">nie</option>
                <option value="1">tak</option>
              </select>
            </div>
            <div>
              <label htmlFor="active">Status</label>
              <select id="active" name="active" defaultValue={"1"}>
                <option value="">wszystkie</option>
                <option value="1">aktywne</option>
                <option value="0">usunięte</option>
              </select>
            </div>
          </div>
          {/* <div>
            <label htmlFor="guest_number">Liczba gości</label>
            <input id="guest_number" name="guest_number" type="nubmer" />
          </div> */}
          <div
            style={{
              display: "grid",
              gridGap: "0 10px",
              gridTemplateColumns: "max-content 1fr",
            }}
          >
            <button type="submit" style={{ width: "120px" }}>
              Szukaj
            </button>
            <button type="reset">✖</button>
          </div>
        </form>
      </div>
      {!Object.keys(query)?.length ? (
        <div style={{ color: "rgb(40,40,40)", padding: "50px" }}>
          ▶ Najpierw podaj kryteria wyszukiwania.
        </div>
      ) : Object.keys(query)?.length && found.loading ? (
        <Loading />
      ) : foundItems && foundItems.length ? (
        <FoundItems items={foundItems} setUpdate={setUpdate} />
      ) : (
        <div style={{ color: "rgb(40,40,40)", padding: "50px" }}>
          ▶ Nie znaleziono imprez lub zastosowano zbyt szerokie kryteria
        </div>
      )}
    </div>
  );
}
