import { formContent } from "../forms_new/formContentType";
import prettyDate from "../functions/prettyDate";
import { datesSince } from "../functions/timeAgo";
import { createGUID, inflect, objectOrJSONparse } from "../functions/tools";
import { timeDiffString } from "../tables/views/viewFunctions";

export default function contractSchema(
  editData: any,
  employee: any
): formContent {
  const fileInfo = {
    module: "contracts",
    moduleID: employee?.id,
    subModule: "B",
    category: "mandate_documents",
    subID: 0, // relevant contract id
    subDB: "employee_contracts",
  };
  return [
    [
      {
        name: "guid",
        style: { display: "none" },
        initialValue: editData?.guid ? editData.guid : createGUID(11),
      },
      {
        name: "job_title",
        label: "Nazwa stanowiska pracy",
        required: "Podaj nazwę stanowiska pracy",
        format: "half-1",
      },
      {
        name: "contract_indefinite",
        label: "Termin obowiązywania",
        format: "half-2",
        element: "select",
        optionsList: [
          {
            id: 0,
            name: "czas określony",
          },
          {
            id: 1,
            name: "czas nieokreślony",
          },
        ],
        required: "Podaj termin obowiązywania",
      },
      {
        name: "contract_type",
        label: "Rodzaj umowy",
        format: "half-1",
        element: "select",
        getOptionsFrom: "contract_types",
        required: "Podaj rodzaj umowy",
      },

      {
        name: "contract_full_time_equivalent",
        format: "quarter-3",
        label: "Wymiar etatu",
        initialValue: editData?.contract_full_time_equivalent
          ? editData.contract_full_time_equivalent
          : 1,
        inputType: "number",
        step: 0.5,
        required: "Podaj wymiar etatu",
      },
      {
        name: "gross_monthly_pay",
        label: "Wyn. brutto (zł)",
        format: "quarter-4",
        inputType: "number",
        style: { width: "100%" },
      },

      {
        name: "contract_start_date",
        label: "Data rozpoczęcia umowy",
        inputType: "date",
        format: "half-1",
        required: "Podaj datę rozpoczęcia umowy",
      },
      {
        name: "contract_end_date",
        label: "Data zakończenia umowy",
        format: "half-2",
        inputType: "date",
        visibleIf: (v: any) => v.contract_indefinite != 1,
        required: "Podaj datę zakończenia umowy",
      },
      {
        name: "info",
        visibleIf: (v: any) => v.contract_type == 2,
        description: (
          <div
            style={{ fontSize: "15px", marginTop: "8px", marginBottom: "8px" }}
          >
            Dane do umowy zlecenie
          </div>
        ),
      },
      {
        name: "birthday",
        visibleIf: (v: any) => v.contract_type == 2,
        label: "Data urodzenia",
        format: "half-1",
        inputType: "date",
        min: {
          value: "1940-01-01",
        },
        max: {
          value: "2020-01-01",
        },
      },
      {
        name: "age-info",
        visibleIf: (v: any) => v.contract_type == 2,
        format: "half-2",
        description: "",
        from: {
          values: ["birthday"],
          transform: (values: any, flatSchema: any, customData: any) => {
            const birthday = values?.birthday && new Date(values?.birthday);
            const now = new Date();
            if (birthday && now?.getTime() - birthday?.getTime() > 0) {
              const since = datesSince(birthday, now);
              const y26 = new Date(birthday);
              y26.setFullYear(y26.getFullYear() + 26);

              return (
                <>
                  <div
                    style={{
                      paddingTop: "15px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        padding: "2px 5px",
                        background: "rgb(235,235,235)",
                        width: "max-content",
                        fontSize: "12px",
                      }}
                    >
                      Wiek
                    </div>
                    {since?.jsx ? <>{since.jsx}</> : <></>}
                  </div>
                  <div
                    style={{
                      fontSize: "70%",
                      background: "rgb(230,230,230)",
                      padding: "1px 8px",
                      marginBottom:"5px"
                    }}
                  >
                    {" "}
                    {datesSince(new Date(), y26)?.text ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>26 lat</span>{" "}
                        <span>za {datesSince(new Date(), y26)?.text}</span>
                      </div>
                    ) : (
                      <span style={{ fontWeight: "bold" }}>
                        Ukończone 26 lat
                      </span>
                    )}
                  </div>
                </>
              );
            }
          },
        },
      },

      {
        name: "student_id_file",
        visibleIf: (v: any) => v.contract_type == 2,
        label: "Legitymacja studencka",
        format: "half-1",
        // inputType: "file",
        element: "file",
        files: objectOrJSONparse(editData?.files)?.filter(
          (d: any) => d.field === "student_id_file"
        ),
        fileInfo,
      },
      {
        name: "student_id_until",
        visibleIf: (v: any) => v.contract_type == 2,
        label: "Termin ważności",
        format: "half-2",
        inputType: "date",
      },
      {
        name: "student_status_file",
        visibleIf: (v: any) => v.contract_type == 2,
        label: "Zaświadczenie z uczelni",
        format: "half-1",
        element: "file",
        files: objectOrJSONparse(editData?.files)?.filter(
          (d: any) => d.field === "student_status_file"
        ),
        fileInfo,
      },
      {
        name: "student_status_file_until",
        visibleIf: (v: any) => v.contract_type == 2,
        label: "Status studenta do",
        format: "half-2",
        inputType: "date",
      },
      {
        name: "questionnaire_file",
        visibleIf: (v: any) => v.contract_type == 2 || v.contract_type == 3,
        label: "Kwestionariusz do umowy zlec.",
        format: "half-1",
        element: "file",
        files: objectOrJSONparse(editData?.files)?.filter(
          (d: any) => d.field === "questionnaire_file"
        ),
        fileInfo,
      },
      {
        name: "sanepid_book_file",
        visibleIf: (v: any) => v.contract_type == 2 || v.contract_type == 3,
        label: "książeczka sanepidowa",
        format: "half-2",
        element: "file",
        files: objectOrJSONparse(editData?.files)?.filter(
          (d: any) => d.field === "sanepid_book_file"
        ),
        fileInfo,
      },
      {
        name: "contract_file",
        visibleIf: (v: any) => v.contract_type == 2 || v.contract_type == 3,
        label: "umowa zlecenie",
        format: "half-1",
        element: "file",
        files: objectOrJSONparse(editData?.files)?.filter(
          (d: any) => d.field === "contract_file"
        ),
        fileInfo: {
          ...fileInfo,
          category: "contracts",
        },
      },
      {
        name: "other_file",
        visibleIf: (v: any) => v.contract_type == 2 || v.contract_type == 3,
        label: "inny dokument",
        format: "half-2",
        element: "file",
        files: objectOrJSONparse(editData?.files)?.filter(
          (d: any) => d.field === "other_file"
        ),
        fileInfo,
      },
    ],
  ];
}
