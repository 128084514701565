import { createContext, useContext, useState } from "react";
import ScheduleRow from "./ScheduleRow";
import { ScheduleContext } from "../Schedules";
import useGeneric from "../dataFetch/useGeneric";
import { URL } from "../paths";
import ScheduleColumnEvents from "./ScheduleColumnEvents";
import { ViewContext } from "../contexts/ViewContext";
import AddEmployee from "./AddEmployee";

export function isSameDay(d1: any, d2: any) {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export default function ScheduleTable({
  employees,
  employeeIDs,
  otherEmployees,
  specials,
  categories,
  defaultHours,
  days,
  id,
  templateEdit,
  update,
  setUpdate,
  selectedDay,
}: {
  employees: any[];
  employeeIDs: any;
  otherEmployees: any[];
  specials: any;
  categories: any;
  defaultHours: any[];
  days: any;
  id: any;
  templateEdit: boolean;
  update: number;
  setUpdate: Function;
  selectedDay: number;
}) {
  const { modal, setModal } = useContext(ViewContext);
  const [editSpecials, setEditSpecials] = useState(false);
  const [targetOrder, setTargetOrder] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [dragged, setDragged] = useState(false);
  const [viewEvents, setViewEvents] = useState(false);
  const data = useGeneric({
    name: "scheduleData",
    query: { scheduleID: id },
    limit: 100000,
    update: update,
    secondsToReload: 12,
    reload: true,
  });
  const hourData = !data.loading ? data.rows : [];

  const fetchCalendarData = useGeneric({
    name: "event-calendar",
    limit: 100,
    update: update,
    secondsToReload: 12,
    reload: true,
  });

  const calendarData = !fetchCalendarData.loading
    ? fetchCalendarData.rows
    : undefined;

  async function reorderEmployees(employee: any, scheduleID: any) {
    const newIDs = employeeIDs
      .split(",")
      .filter((id: any) => Number(id) !== Number(dragged));

    newIDs.splice(targetOrder, 0, String(dragged));
    console.log(employeeIDs.split(","), newIDs, targetOrder);

    //splice(2, 0, "Lene");
    const response = await fetch(
      URL + "/backend/schedules/edit-employees.php",
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          id: scheduleID,
          employeeIDs: newIDs.join(","),
        }),
      }
    );
    const result = await response.json();
    setUpdate((prev: number) => prev + 1);

    setDragOver(false);
    return result;
  }

  async function addEntry(params: any, template?: boolean) {
    console.log(params);
    const response = await fetch(
      URL +
        "backend/schedules/" +
        (template
          ? "insert-schedule-defaults.php"
          : "insert-schedule-data.php"),
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(params),
      }
    );
    const result = await response.json();
    await setUpdate((prev: number) => prev + 1);
    return result;
  }

  let employeeRows = employees;
  if (employees && employees.length) {
    const empIDarray = employeeIDs.split(",");
    //console.log(empIDarray)
    employeeRows = employees.map((e: any) => ({
      ...e,
      order: empIDarray.indexOf(String(e.id)) + 1,
    }));
  }

  return !data.loading && calendarData ? (
    <ScheduleContext.Provider
      value={{
        employees: employees,
        employeeIDs: employeeIDs,
        specials:specials ? specials.filter((s:any) => s.scheduleIDs.split(",").includes(String(id))) : [],
        scheduleID: id,
        defaultHours: defaultHours,
        days: days,
        addEntry: addEntry,
        update: update,
        setUpdate: setUpdate,
        templateEdit: templateEdit,
        calendarData: calendarData,
        editSpecials: editSpecials,
        setEditSpecials: setEditSpecials,
        selectedDay:selectedDay,
      }}
    >
      <table className={"scheduleTable" + (templateEdit ? " editor" : "")}>
        <thead>
          <tr>
            <th>Pracownik</th>
            {days.map((d: Date, nthDay: number) => {
              const dateString = d
                .toLocaleDateString("pl-PL", {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                })
                .split(" ");
              const events = calendarData.filter((item: any) =>
                isSameDay(item.date, d)
              ).length;
              return templateEdit ? (
                <th style={{ textAlign: "center" }}>
                  {dateString[0].replace(",", "")}
                </th>
              ) : (
                <th
                  className={
                    "weekday" + (isSameDay(d, new Date()) ? " today" : "") 
                    + (selectedDay === nthDay ? " selectedDay" : "")
                  }
                >
                  <div style={{ fontWeight: "normal" }}>
                    {dateString[1]} {dateString[2]}
                  </div>
                  <div>
                    {dateString[0].replace(",", "")}{" "}
                    {events ? (
                      <span
                        className="events"
                        onClick={() => setViewEvents((prev: boolean) => !prev)}
                      >
                        {events}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr className="eventsData">
            <td></td>
            {days.map((d: Date, nthDay: number) => {
              const selected = nthDay === selectedDay;
              const events = calendarData.filter((item: any) =>
                isSameDay(item.date, d)
              )
              return events ? (
                <ScheduleColumnEvents
                  events={events}
                  view={viewEvents}
                  setView={setViewEvents}
                  selectedDay={selected}
                />
              ) : (
                <></>
              );
            })}
          </tr>
          {employeeRows
            .sort((a: any, b: any) => a.order - b.order)
            .map((employee: any) => (
              <ScheduleRow
                employee={employee}
                reorderEmployees={reorderEmployees}
                data={hourData.filter((i: any) => i.employeeID === employee.id)}
                setTargetOrder={setTargetOrder}
                targetOrder={targetOrder}
                dragOver={dragOver}
                setDragOver={setDragOver}
                dragged={dragged}
                setDragged={setDragged}
              />
            ))}
          {templateEdit ? (
            <tr>
              <td>
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0 24px",
                    boxSizing: "border-box",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setModal({
                      show: true,
                      heading: "Dodaj osobę",
                      content: (
                        <AddEmployee
                          categories={categories}
                          employees={otherEmployees}
                          scheduleID={id}
                          setUpdate={setUpdate}
                        />
                      ),
                    })
                  }
                >
                  <div
                    style={{
                      fontSize: "24px",
                      fontWeight: "700",
                      marginRight: "10px",
                    }}
                  >
                    +
                  </div>
                  <div>dodaj osobę</div>
                </div>
              </td>
            </tr>
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </ScheduleContext.Provider>
  ) : (
    <></>
  );
}
