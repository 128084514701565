import CellWrap from "./CellWrap";
import ItemWrap from "./ItemWrap";
import { cell, cells } from "./types";

export default function CollectionItem({
  item,
  cells,
}: {
  item: any;
  cells: cells;
}) {
  return item ? (
    <ItemWrap>
      {cells?.map((cell: cell) => {
        const value =
          typeof cell === "string" ? (
            <>{item?.[cell]}</>
          ) : cell?.view ? (
            <>{cell?.view(item?.[cell?.column], item)}</>
          ) : (
            <>{item?.[cell?.column]}</>
          );
        return <CellWrap>{value}</CellWrap>;
      })}
    </ItemWrap>
  ) : (
    <></>
  );
}
