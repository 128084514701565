import editIcon from "../../images/icons/pencil.png";
import listIcon from "../../images/icons/eye.svg";
import { useState } from "react";
import Value from "./Value";
import { inflect } from "../functions/tools";

export default function HolidaySummary({
  employee,
  subView,
  setSubView,
}: {
  employee: any;
  subView: string;
  setSubView: Function;
}) {
  const [editing, setEditing] = useState(false);

  const [formValues, setFormValues] = useState({});
  return (
    <div className="division">
      <div className="heading">
        <h2>Urlop</h2>
        <div className="options">
          {/* <button onClick={() => setEditing(true)}>
            <img src={editIcon} />
          </button> */}
          <button
            className={
              "more-details" + (subView === "holidays" ? " active" : "")
            }
            onClick={() => setSubView("holidays")}
          >
            <img src={listIcon} />
          </button>
        </div>
      </div>
      {employee.holiday_relevant ? (
        <>
          <Value
            label="Roczny wymiar urlopu wypoczynkowego"
            name="annual_holiday_pool"
            isEditing={editing}
            editor="number"
            data={
              employee.annual_holiday_pool1 +
              " dni " +
              (employee.annual_holiday_pool2
                ? " + " + employee.annual_holiday_pool2 + " dni"
                : "")
            }
          />
          <Value
            label="Zapis początkowy"
            name="employee.initial_holiday_pool"
            isEditing={editing}
            editor="number"
            data={
              employee.holiday_pool_start_date ? (
                <>
                  <div>
                    {inflect(employee.initial_holiday_pool, [
                      "dni",
                      "dzień",
                      "dni",
                      "dni",
                    ])}{" "}
                    od {employee?.holiday_pool_start_date}
                  </div>
                  {employee?.annual_holiday_pool2_add_from ? (
                    <div>
                      Dłuższy urlop od:
                      {employee?.annual_holiday_pool2_add_from}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )
            }
          />
          <Value
            label="Dobowy wymiar czasu pracy"
            name="employee.base_hours_per_day"
            isEditing={editing}
            editor="number"
            data={
              (employee.base_hours_per_day ? employee.base_hours_per_day : 8) +
              " godz."
            }
          />
        </>
      ) : (
        <div className="info">Brak danych urlopowych</div>
      )}
    </div>
  );
}
