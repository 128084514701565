import { useContext } from "react";
import { HybridContext } from "./HybridField";
import { FormikContext } from "formik";

export default function HybridResults() {
  const { foundRows, resultElement, setResultsWindow, resultUpdate, setHovered } = useContext(HybridContext);
  const { setFieldValue } = useContext(FormikContext);

  function update(item: any) {
    resultUpdate(item).forEach((pair: any[]) => {
      //console.log(pair[0], pair[1]);
      setFieldValue(pair[0], pair[1]);
    });
    setResultsWindow(false);
    setHovered(false);
  }

  return (
    <div className="hybrid-search-results">
      {foundRows.length ? (
        foundRows.map((item: any) => (
          <div className="hybrid-search-result" onClick={() => {
            update(item);
            }}>
            {resultElement(item)}
          </div>
        ))
      ) : (
        <div style={{padding:"3px 5px"}}>Nie znaleziono</div>
      )}
    </div>
  );
}
