import { Formik, Form, Field } from "formik";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { createContext } from "react";
import Loading from "../common/Loading";
import useGeneric from "../dataFetch/useGeneric";
import FieldAndLabel from "../forms/FieldAndLabel";
import { URL } from "../paths";
import { UserContext } from "../contexts/UserContext";
import StateButton from "./buttons/StateButton";
import setActive from "../dataFetch/setActive";

import FileUpload from "../common/FileUpload";


export const FormContext = createContext({ disabled: false });



export default function EditErrorEntry({ setUpdate }: { setUpdate: any }) {
  const { role: userRole } = useContext(UserContext);
  const pathParams = useParams();
  const current = useGeneric({
    name: "showError",
    limit: 1,
    query: { id: pathParams.id ? pathParams.id : false },
  });
  const currentData: any = current.rows[0];
  const modify = !current.loading && currentData ? true : false;
  const active = modify && currentData.active == "1" ? true : false;
  
  const [added, setAdded] = useState(0);
  const navigate = useNavigate();

  async function addDB(values: any, query: "add" | "update") {
    const add = await fetch(URL + "backend/modify-error-table.php", {
      method: "POST",
      body: JSON.stringify({
        query: query,
        data: values,
      }),
      credentials: "include",
    });
    const result = await add.json();
    console.log(result);
    setAdded((prev: number) => prev + 1);
  }

  useEffect(() => {
    if (added > 0) {
      setUpdate((prev: number) => prev + 1);
      navigate("..");
    }
  }, [added]);

  const options = useGeneric({
    name: [
      "employeesGeneric",
      "problemTypes",
      "whatIsCorrected",
      "reportingFormTypes",
      "salesDocumentTypes",
    ],
    limit: 100,
    active: true,
  });
  

  const optionsData = !options.loading ? options.multiple : {
    employeesGeneric: {rows: []},
    problemTypes: {rows: []},
    whatIsCorrected: {rows: []},
    reportingFormTypes: {rows: []},
    salesDocumentTypes: {rows: []}
  };
  //console.log(options);

  return (
    <div className="editor">
      <FormContext.Provider
        value={{ disabled: userRole !== 0 && modify ? true : false }}
      >
        <Formik
          initialValues={{
            id: currentData ? currentData.id : null,
            entry_number: currentData ? currentData.entry_number : "",
            entry_year: currentData ? currentData.entry_year : "",
            problem_type: currentData ? currentData.problem_type : "",
            sales_document_type: currentData
              ? currentData.sales_document_type
              : "",
            filenames: currentData ? currentData.filenames : "",
            is_original_receipt_present: currentData
              ? currentData.is_original_receipt_present === "0"
                ? false
                : true
              : false,
            lsi_document_number: currentData
              ? currentData.lsi_document_number
              : "",
            date_of_sale_from_sales_document: currentData
              ? currentData.date_of_sale_from_sales_document
              : "",
            purchaser_number_on_document: currentData
              ? currentData.purchaser_number_on_document
              : "",
            guest: currentData ? currentData.guest : "",
            booking_number: currentData ? currentData.booking_number : "",
            gross_amount_original_document: currentData
              ? currentData.gross_amount_original_document
              : "",
            what_is_corrected: currentData ? currentData.what_is_corrected : "",
            previous_value: currentData ? currentData.previous_value : "",
            should_be: currentData ? currentData.should_be : "",
            gross_value_as_corrected: currentData
              ? currentData.gross_value_as_corrected
              : "",
            correction_reason: currentData ? currentData.correction_reason : "",
            reporting_form: currentData ? currentData.reporting_form : "",
            date_of_discovery: currentData ? currentData.date_of_discovery : "",
            date_reported: currentData ? currentData.date_reported : "",
            employee_involved_id: currentData
              ? currentData.employee_involved_id
              : "",
            approved_solution: currentData ? currentData.approved_solution : "",
            created_by: currentData ? currentData.created_by : "",
          }}
          enableReinitialize={true}
          onSubmit={(values) =>

            addDB(
              {
                ...values,
                is_original_receipt_present: values.is_original_receipt_present
                  ? "1"
                  : "0",
              },
              modify ? "update" : "add"
            )
          }
        >
          {(props) => (
            <Form>
              <h1 className="headingBar">
                <Link
                  title="Powrót do tabeli"
                  style={{
                    padding: "3px 12px",
                    marginRight: "10px",
                    borderRight: "1px solid black",
                  }}
                  className="link"
                  to="/errors"
                >
                  ◀
                </Link>
                {!modify ? (
                  <>Nowy wpis</>
                ) : (
                  <>
                    Edycja wpisu nr {props.values.entry_number} /{" "}
                    {props.values.entry_year}
                    <div
                      style={{ display: "flex", width:"100%", justifyContent:"space-around", marginLeft: "12px" }}
                    >
                      
                      <button
                        className="button"
                        onClick={(e) => {
                          e.preventDefault();
                          if (active) {
                          setActive(props.values.id, "1", "errorRegister");
                          }
                          else {
                            setActive(props.values.id, "0", "errorRegister");
                          }
                          setAdded((prev:number) => prev+1);
                        }}
                      >
                        {active ? <>Archiwizuj</> : <>Przywróć do aktualnych</>}
                      </button>
                      <button className="button">Akceptuję</button>
                      <button className="button">Uwagi</button>
                    </div>
                  </>
                )}
              </h1>
              <div className="columns">
                <div>
                  <div className="grid-2">
                    <FieldAndLabel
                      as="select"
                      name="problem_type"
                      optionData={optionsData.problemTypes.rows}
                      label="Rodzaj problemu"
                    />
                    <FieldAndLabel
                      as="select"
                      name="sales_document_type"
                      optionData={optionsData.salesDocumentTypes.rows}
                      label="Rodzaj dokumentu sprzedaży"
                    />
                  </div>
                  <FieldAndLabel
                    type="checkbox"
                    name="is_original_receipt_present"
                    label="Czy dostępny oryginał"
                    description={
                      <>
                        W przypadku PARAGONU - oryginał paragonu fiskalnego MUSI
                        BYĆ OBECNY i dopięty do rozliczenia <br />
                        JEŚLI ORYGINAŁ JEST - zaznacz
                      </>
                    }
                  />
                  <div className="grid-2">
                    <FieldAndLabel
                      name="lsi_document_number"
                      label="Nr dokumentu LSI"
                    />
                    <FieldAndLabel
                      type="date"
                      name="date_of_sale_from_sales_document"
                      label="Data sprzedaży z dokumentu LSI"
                    />
                  </div>
                  <FieldAndLabel
                    name="purchaser_number_on_document"
                    label="Nazwa nabywcy na dokumencie"
                  />
                  <div className="grid-2">
                    <FieldAndLabel name="guest" label="Gość" />
                    <FieldAndLabel
                      name="booking_number"
                      label="Numer rezerwacji"
                    />
                  </div>
                  <FieldAndLabel
                    as="select"
                    name="what_is_corrected"
                    optionData={optionsData.whatIsCorrected.rows}
                    label="Co podlega korekcie?"
                  />
                  <FileUpload />
                  
                </div>
                <div>
                  <div className="grid-2 mob">
                    <FieldAndLabel name="previous_value" label="Było" />
                    <FieldAndLabel name="should_be" label="Powinno być" />
                  </div>
                  <div className="grid-2 mob">
                    <FieldAndLabel
                      name="gross_amount_original_document"
                      label="Kwota brutto pierwotnego dokumentu"
                    />
                    <FieldAndLabel
                      name="gross_value_as_corrected"
                      label="Łącznie prawidłowa kwota brutto po korekcie"
                    />
                  </div>
                  <div className="grid-2">
                    <div>
                      <FieldAndLabel
                        as="select"
                        name="reporting_form"
                        optionData={optionsData.reportingFormTypes.rows}
                        label="Forma zgłoszenia problemu"
                      />
                      <FieldAndLabel
                        as="select"
                        name="employee_involved_id"
                        optionData={optionsData.employeesGeneric.rows.map(
                          (item: any) => ({
                            name: item.first_name + " " + item.last_name,
                            ...item,
                          })
                        )}
                        label="Pracownik związany z problemem"
                      />
                      <FieldAndLabel
                        name="date_of_discovery"
                        type="date"
                        label={
                          <>
                            Data zgłoszenia przez Gościa albo zauważenia
                            problemu przez Pracownika
                          </>
                        }
                      />
                    </div>
                    <FieldAndLabel
                      as="textarea"
                      name="correction_reason"
                      label="Przyczyna korekty / Dokłady opis sytuacji"
                      fieldStyle={{ height: "196px" }}
                    />
                  </div>
                  <FieldAndLabel
                    as="textarea"
                    name="approved_solution"
                    label={modify ? "Rozwiązanie" : "Propozycja rozwiązania"}
                  />
                </div>
                {/*<div></div>*/}
              </div>

              {userRole !== 0 && modify ? (
                <></>
              ) : (
                <button type="submit" className="button">
                  {modify ? "Edytuj" : "Wyślij"}
                </button>
              )}
            </Form>
          )}
        </Formik>
      </FormContext.Provider>
    </div>
  );
}
