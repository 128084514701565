export default function minutesToJSX(minNum: number, warning: boolean) {
  const hours = Math.floor(minNum / 60);
  const minutes = Math.round(minNum - hours * 60);
  return minNum ? (
    <>{(hours ? hours + " h" : "") + (minutes ? " " + minutes + " min" : "")}</>
  ) : warning ? (
    <span style={{ fontSize: "16px", fontWeight: "bold", color: "brown" }}>
      ?
    </span>
  ) : (
    <></>
  );
}

export function minutesToString(minNum: number, warning: boolean) {
  const hours = Math.floor(minNum / 60);
  const minutes = Math.round(minNum - hours * 60);
  return minNum
    ? (hours ? hours + " h" : "") + (minutes ? " " + minutes + " min" : "")
    : warning
    ? "?"
    : "";
}

export function minutesToShortJSX(minNum: number, warning: boolean) {
  const hours = Math.floor(minNum / 60);
  const minutes = Math.round(minNum - hours * 60);
  return minNum ? (
    <>
      <span>{hours ? hours : "0"}</span>
      {hours ? <></> : <></>}
      <span style={{ lineHeight:"70%", paddingBottom:"2px", fontSize: "9px", color: "grey" }}>
        {minutes ? (minutes < 10 ? "0" + minutes : minutes) : "00"}
      </span>
    </>
  ) : warning ? (
    "?"
  ) : (
    ""
  );
}
