import FieldAndLabel from "./FieldAndLabel";
import { formContent } from "./formContent";

export default function  RenderFieldAndLabel({
    data,
    options,
    optionsList = undefined,
  }: {
    data: formContent;
    options: any;
    optionsList?: { id: number; name: string; active: boolean }[] | undefined;
  }) {
    if (data.jsx)
      return (
        <div className="field">
          <label style={{ display: "block" }} htmlFor={data.name}>
            {data.label}
          </label>
          {data.jsx}
        </div>
      );
    return (
      <FieldAndLabel
        {...data}
        as={data.element}
        type={data.inputType ? data.inputType : "text"}
        optionData={
          optionsList
            ? optionsList
            : !options.loading && data.getOptionsFrom
            ? options.multiple[data.getOptionsFrom].rows
            : []
        }
        description={data.description ? data.description : undefined}
        format={data.format ? data.format : undefined}
        name={data.name}
        disabled={data.disabled}
        label={data.label ? data.label : undefined}
        autoFocus={data.autoFocus ? data.autoFocus : false}
        visibleIf={data.visibleIf ? data.visibleIf : undefined}
      />
    );
  }
