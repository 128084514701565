import { useContext } from "react";
import { ViewContext } from "../contexts/ViewContext";
import useGeneric from "../dataFetch/useGeneric";
import prettyDate from "../functions/prettyDate";
import prettyNumber from "../functions/prettyNumber";

export default function ReceiptDetails({ id }: { id: number | string }) {
  const { setModal2 } = useContext(ViewContext);
  const fetchItem = useGeneric({
    name: "lsi_receipt_query",
    query: {
      id: id,
    },
    limit: 200,
  });

  const item = !fetchItem.loading ? fetchItem.rows : undefined;
  const i = item && item[0] ? item[0] : undefined;

  return (
    i && (
      <div className="receipt-view-wrapper">
        <h2>
          PAR/{i.docNo}/{i.year}
        </h2>
        <div className="receipt-view-props">
          <div>
            <div>Data dzienna</div>
            <div>{new Date(i.data_dzienna).toLocaleDateString()}</div>
          </div>
          <div>
            <div>Podmiot</div>
            <div>{i.ko_firma}</div>
          </div>
          <div>
            <div>POS</div>
            <div>{i.pos}</div>
          </div>
          <div>
            <div>Kwota rozliczenia</div>
            <div>{prettyNumber(i._KwRozliczenia)} zł</div>
          </div>
        </div>
      </div>
    )
  );
}
