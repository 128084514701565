import { Styles, Text, View } from "@react-pdf/renderer";
import InvoiceTableHead from "./InvoiceTableHead";
import InvoiceTableBody from "./InvoiceTableBody";
import invoiceTypes from "./invoiceTypes";
import useGeneric from "../../dataFetch/useGeneric";
import { groupInvoiceItems } from "../LSIinvoiceView";
import prettyNumber from "../../functions/prettyNumber";
import prettyMoney from "../../functions/prettyMoney";

export default function InvoiceTable({
  styles,
  cW,
  invoice,
  itemGroups,
}: {
  styles: Styles;
  cW: any;
  invoice: invoiceTypes;
  itemGroups: any[];
}) {
  const f = useGeneric({
    name:
      invoice?.type == 3 && invoice?.KorygowanaSCID ? "lsi_invoice_query" : "",
    query: {
      scid: invoice?.KorygowanaSCID,
    },
    groupBy: (v: any) => (v?.SCID ? v.SCID : v.id),
  });

  const initialInvoice = !f?.loading && f?.rows?.[0];

  //   console.log(initialInvoice);
  const initialItems = groupInvoiceItems(initialInvoice);

  const inv = itemGroups?.[0];

  return (
    <>
      {invoice?.type == 3 ? (
        <View>
          <Text
            style={[styles?.small, { fontSize: "8pt", fontWeight: "bold" }]}
          >
            przed korektą
          </Text>
        </View>
      ) : (
        <></>
      )}
      <InvoiceTableHead styles={styles} cW={cW} />
      {invoice?.type == 3 && initialInvoice ? (
        <>
          <InvoiceTableBody
            styles={styles}
            cW={cW}
            invoice={invoice}
            itemGroups={initialItems}
          />
          <View>
            <Text
              style={[styles?.small, { fontSize: "8pt", fontWeight: "bold" }]}
            >
              po korekcie
            </Text>
          </View>
        </>
      ) : (
        <></>
      )}
      <InvoiceTableBody
        styles={styles}
        cW={cW}
        invoice={invoice}
        itemGroups={
          invoice?.type == 3
            ? itemGroups
                ?.filter((i: invoiceTypes) => i?.amount > 0)
                ?.map((i: invoiceTypes) => ({
                  ...i,
                  item_gross: i?.item_cor_gross,
                  sum_net:
                    Number(i?.sum_cor_net) != 0
                      ? Math.abs(Number(i?.sum_net) - Number(i?.sum_cor_net))
                      : i?.sum_net,
                  sum_gross:
                    Number(i?.sum_cor_gross) != 0
                      ? Math.abs(
                          Number(i?.sum_cor_gross) - Number(i?.sum_gross)
                        )
                      : i?.sum_gross,
                }))
            : itemGroups
        }
      />
      {invoice?.type == 3 ? (
        <View
          style={[
            styles.table,
            {
              borderTop: "none",
              fontSize: "8px",
              flexDirection: "row",
            },
          ]}
        >
          <View
            style={[
              styles.tableCell,
              {
                border: "none",
                width: cW[0],
              },
            ]}
          ></View>
          <View
            style={[
              styles.tableCell,
              {
                width: cW[1] + cW[2] + cW[3] + cW[4],
                border: "none",
                flexDirection: "row",
                justifyContent: "flex-end",
                paddingRight: "4mm",
              },
            ]}
          >
            <Text>Tytuł korekty: wg powyższej specyfikacji</Text>
          </View>
          <View
            style={[
              styles.tableCell,
              { textAlign: "right", fontWeight: "bold", width: cW[5] },
            ]}
          >
            <Text>
              -
              {prettyMoney(
                Number(initialItems?.[0]?.sum_net) - Number(inv?.sum_cor_net),
                { round: 2, text: true }
              )}
            </Text>
          </View>
          <View
            style={[
              styles.tableCell,
              { padding: 0, flexDirection: "row", width: cW[6] },
            ]}
          >
            <View
              style={{
                padding: "2 1",
                borderRight: "1px solid black",
                fontWeight: "bold",
                width: 30,
              }}
            >
              <Text>--</Text>
            </View>
            <View
              style={{
                padding: "2 1",
                width: 70,
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              <Text>
                -
                {prettyMoney(
                  Number(initialItems?.[0]?.sum_gross) -
                    Number(initialItems?.[0]?.sum_net) -
                    (Number(inv?.sum_cor_gross) - Number(inv?.sum_cor_net)),
                  {
                    text: true,
                    round: 2,
                  }
                )}
              </Text>
            </View>
          </View>

          <View
            style={[
              styles.tableCell,
              { textAlign: "right", fontWeight: "bold", width: cW[7] },
            ]}
          >
            <Text>
              -
              {prettyMoney(
                Number(initialItems?.[0]?.sum_gross) -
                  Number(inv?.sum_cor_gross),
                { round: 2, text: true }
              )}
            </Text>
          </View>
        </View>
      ) : (
        <></>
      )}
    </>
  );
}
