export default function MultiShow({
  data,
  schema,
}: {
  data: any;
  schema: any;
}) {
  const dataObj = JSON.parse(data);
  const columnObject = schema?.multiAddProps?.columns;
  // console.log(columnObject)
  const columns = columnObject && Object.keys(columnObject)
    .filter((key: string) => columnObject?.[key].name)
    .map((key: string) => columnObject?.[key].name);

  return dataObj && dataObj.length ? (
    <div>
      <table className="data-table mini">
        <thead>
          <tr>
            {columns?.map((name: any) => (
              <th>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataObj?.map((row: any) => {
            if (row.id) delete row.id;
            if (row.guid) delete row.guid;
            return (
              <tr>
                {row ? (
                  Object.keys(row).map((name: string) => <td>{row?.[name]}</td>)
                ) : (
                  <></>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    <></>
  );
}
