import { useContext, useEffect } from "react";
import insertGeneric from "../dataFetch/insertGeneric";
import { UserContext } from "../contexts/UserContext";
import useGeneric from "../dataFetch/useGeneric";
import VoucherDateUntil from "./VoucherDateUntil";
import Loading from "../common/Loading";

export default function VoucherGroup({
  items,
  group,
  setGroups,
  groups,
  index,
}: {
  items: any;
  group: any;
  setGroups: Function;
  groups: any;
  index: number;
}) {
  const getLastNumber = useGeneric({
    name: "voucher-register",
    query: {
      amount: group?.amount,
      //   type: group?.type,
      paid: group?.paid,
    },
    limit: 1,
    order: { by: "number", dir: "DESC" },
  });

  const getEmployeeOptions = useGeneric({
    name: "employeesGeneric",
    count: false,
    query: {
      active: true,
      location: 0,
      group: { includes: [1, 7] },
    },
  });
  const getManagement = useGeneric({
    name: "management",
    count: false,
    order: {
      by: "id",
      dir: "asc",
    },
  });
  const employeeOptions =
    !getEmployeeOptions?.loading && getEmployeeOptions?.rows;

  const management = !getManagement?.loading && getManagement?.rows;

  const lastNumber =
    !getLastNumber?.loading && getLastNumber?.rows?.[0]?.number;
  //   console.log(lastNumber);

  const { userID } = useContext(UserContext);
  //   console.log(user);
  useEffect(() => {
    const group_id =
      String(group?.amount) +
      String(group?.first) +
      "0" +
      Number(
        String(Number(group?.paid)) +
          "0" +
          String(Math.round(Math.random() * 100000))
      );

    if (group?.amount && group?.copies) {
      setIt(group_id, "group_id");
    }
  }, [group.copies]);

  function setIt(value: any, prop: string, number?: Boolean) {
    setGroups((prev: any) => {
      const n = prev?.map((x: any) => x);
      n[index] = {
        ...prev[index],
        [prop]: number ? Number(value) : value,
      };
      return n;
    });
  }
  function lastTwoDigits(n: number) {
    return String(n)
      ?.split("")
      ?.reverse()
      ?.map((f: any, index: any) => {
        return index > 1 ? <>{f}</> : <strong>{f}</strong>;
      })
      .reverse();
  }

  function removal() {
    setGroups((prev: any) => {
      const n = prev?.map((x: any) => x);
      n[index] = null;
      return n;
    });
  }

  useEffect(() => {
    let nextNumber = 1;
    if (lastNumber) {
      nextNumber = Number(lastNumber) + 1;
    }

    setIt(nextNumber, "first", true);
  }, [getLastNumber.loading]);

  //   useEffect(() => {
  //     const similar = groups.filter(
  //       (g: any) =>
  //         g?.amount === group?.amount &&
  //         g?.type === group?.type &&
  //         g?.group_id !== group?.group_id
  //     );
  //     console.log(groups, similar);

  //     if (similar && similar.length) {
  //       const nextNumber = similar?.at(-1)?.first + 1;
  //       setIt(nextNumber, "first", true);
  //     }
  //   }, [JSON.stringify(groups.map((i: any) => i?.copies))]);

  return (
    <div className="voucher-group">
      {getLastNumber.loading ? <Loading overlay={true} /> : <></>}

      <div
        style={{
          textTransform: "uppercase",
          fontSize: "9px",
          fontWeight: "bold",
        }}
      >
        Grupa voucherów
      </div>
      <div className="input-box" style={{ maxWidth: "90%" }}>
        <label>Typ</label>
        <select
          style={{ maxWidth: "260px" }}
          onChange={(e) => {
            setIt(e.target.value, "type", true);
          }}
          value={Number(group?.type)}
        >
          <option value={0}>Kwotowy - wszystkie usługi</option>
          <option value={1}>Kwotowy - spa</option>
          <option value={2}>Imienny- wszystkie usługi</option>
          <option value={99}>puste miejsce</option>
        </select>
      </div>
      {group?.type === 99 ? (
        <>
          <div className="input-box">
            <label>Liczba sztuk</label>
            <input
              onChange={(e) => {
                setIt(e.target.value, "copies", true);
              }}
              min={0}
              max={3}
              type={"number"}
              value={Number(group?.copies)}
            />
          </div>
          <div className="voucher-group-sides">
            <div className="remove-group" onClick={removal}>
              ✕
            </div>
          </div>
        </>
      ) : (
        <>
          {group?.type === 2 ? (
            <div className="input-box" style={{ maxWidth: "90%" }}>
              <label>Nazwisko</label>
              <input
                style={{ maxWidth: "260px" }}
                onChange={(e) => {
                  setIt(e.target.value, "for_name");
                }}
                value={group?.for_name}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="voucher-group-sides">
            <div className="remove-group" onClick={removal}>
              ✕
            </div>
            <div className="voucher-group-edit">
              <div className="input-box">
                <label>Kwota</label>
                <select
                  onChange={(e) => {
                    setIt(e.target.value, "amount", true);
                  }}
                  value={Number(group?.amount)}
                >
                  <option value={undefined}></option>
                  <option value={50}>50 zł</option>
                  <option value={100}>100 zł</option>
                  <option value={150}>150 zł</option>
                  <option value={200}>200 zł</option>
                </select>
              </div>
              <div className="input-box">
                <label>Rodzaj</label>
                <select
                  onChange={(e) => {
                    setIt(e.target.value, "paid", true);
                  }}
                  value={Number(group?.paid)}
                >
                  <option value={1}>PŁATNY</option>
                  <option value={0}>GRATIS</option>
                </select>
              </div>
              <div
                className="input-box"
                style={getLastNumber.loading ? { visibility: "hidden" } : {}}
              >
                <label>Numer od</label>
                <input
                  onChange={(e) => {
                    setIt(e.target.value, "first", true);
                  }}
                  //   style={{opacity:".6"}}
                  disabled={true}
                  min={getLastNumber}
                  max={100000}
                  type={"number"}
                  value={Number(group?.first)}
                />
              </div>
              <div className="input-box">
                <label>Liczba sztuk</label>
                <input
                  onChange={(e) => {
                    setIt(e.target.value, "copies", true);
                  }}
                  min={0}
                  max={100}
                  type={"number"}
                  value={Number(group?.copies)}
                />
              </div>
              <VoucherDateUntil group={group} setIt={setIt} />
            </div>
            <div
              className="voucher-group-info appear"
              style={getLastNumber.loading ? { visibility: "hidden" } : {}}
            >
              <div className="voucher-range">
                {group?.copies === 1 ? (
                  <span>{group?.first}</span>
                ) : (
                  <>
                    <span>{lastTwoDigits(group?.first)}</span> ⇣{" "}
                    <span>
                      {lastTwoDigits(group?.first + (group?.copies - 1))}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="">
            <div
              style={{
                textTransform: "uppercase",
                fontSize: "9px",
                fontWeight: "bold",
                marginTop: "5px",
              }}
            >
              Metadane
            </div>
            <div className="input-box">
              <label>Zamówił(a)</label>
              <select
                value={group?.request_user_id}
                style={{ maxWidth: "90%" }}
                onChange={(e) => {
                  setIt(e.target.value, "request_user_id");
                  setIt(e.target.selectedOptions[0].text, "request_user");
                }}
              >
                <option value={0} />
                {management &&
                  management?.map((e: any) => (
                    <option value={e.id}>
                      {e.first_name} {e.last_name}
                    </option>
                  ))}
                {employeeOptions &&
                  employeeOptions?.map((e: any) => (
                    <option value={e.id}>
                      {e.first_name} {e.last_name}
                    </option>
                  ))}
                {/* <option value={0}>Agnieszka Rydz</option> */}
              </select>
            </div>
            <div className="input-box" style={{ display: "none" }}>
              <label>Klient</label>
              <input
                value={group?.ordering_client}
                style={{ maxWidth: "90%" }}
                onChange={(e) => setIt(e.target.value, "ordering_client")}
              />
            </div>
            <div className="input-box">
              <label>Komentarz</label>
              <textarea
                value={group?.comment}
                style={{ maxWidth: "90%" }}
                onChange={(e) => setIt(e.target.value, "comment")}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
