import insertGeneric from "../dataFetch/insertGeneric";
import VoucherList from "./VoucherList";

export default function VoucherAddFinalize({
  setFinalize,
  setUpdate,
  auth,
  items,
  userID,
  groups,
  setGroups,
}: {
  setFinalize: Function;
  setUpdate: Function;
  auth: any;
  items: any;
  groups: any;
  userID: any;
  setGroups: Function;
}) {
  return (
    <div className="voucher-finalize voucher-register">
      <button className="normal-button" onClick={() => setFinalize(false)}>
        Powrót
      </button>
      <div className="voucher-lists">
        <VoucherList
          setUpdate={setUpdate}
          auth={auth}
          items={items}
          heading={"Przegląd"}
        />
      </div>
      <div>
        <button
          onClick={async () => {
            insertGeneric("set-voucher-register", {
              print_user_id: userID,
            });
            //clear working memory
            await insertGeneric("set-voucher-memory", {
              print_user_id: userID,
            });
            console.log({
              vouchers: groups
            });
            await setUpdate((prev:number) => prev+1);
            await setGroups([]);
          }}
          className="normal-button"
          style={{ marginTop: "12px" }}
        >
          Dodaj do rejestru jako wydrukowane
        </button>
      </div>

      <div
        style={{
          fontSize: "10px",
          fontFamily: "monospace",
          maxWidth: "600px",
        }}
      >
        {JSON.stringify(items)}
      </div>
    </div>
  );
}
