import { useContext, useState } from "react";
import Loading from "../common/Loading";
import useGeneric from "../dataFetch/useGeneric";
import { objectOrJSONparse } from "../functions/tools";
import Value from "./Value";
import EditHolidayCore from "./EditHolidayCore";
import { ViewContext } from "../contexts/ViewContext";

export default function AttReport({
  id,
  holiday,
  employee,
  updates,
  setUpdates,
}: {
  id: number;
  holiday: boolean;
  employee: any;
  updates: number;
  setUpdates: Function;
}) {
  const { modal, setModal } = useContext(ViewContext);
  const [year, setYear] = useState(new Date().getFullYear());

  const holidayTypes = useGeneric({
    name: "holidayTypes",
    limit: 2000,
  })?.rows;

  const holidayCount = useGeneric({
    name: "holidayCountView",
    limit: 2000,
    query: {
      id: id,
    },
    showLoadingOnManualUpdate: true,
  });

  const f = useGeneric({
    name: "annualAttendanceReport",
    limit: 2,
    query: {
      employeeID: id,
      year: year,
    },
    update: year,

    // abort: true
    // showLoadingOnManualUpdate: true,
  });
  const data = !f.loading && f.rows?.[0];

  const holidayNumbers = data && objectOrJSONparse(data.holiday_types);

  return (
    <div className="emp-report">
      <div className="heading">
        <h2 className="bigger">Podstawowe parametry urlopu</h2>
        <div
          className="add-files-toggle"
          onClick={() =>
            setModal({
              show: true,
              heading: "Dodaj umowę",
              content: (
                <EditHolidayCore
                  employee={employee}
                  setUpdates={setUpdates}
                  setModal={setModal}
                  editData={employee}
                />
              ),
            })
          }
        >
          EDYTUJ
        </div>
      </div>
      {employee?.annual_holiday_pool1 ? (
        <div className="holiday-core">
          <Value
            label="Pula początkowa"
            data={
              <>
                {employee?.initial_holiday_pool} (od{" "}
                {employee?.holiday_pool_start_date})
              </>
            }
            isEditing={false}
            editor={false}
          />
          <Value
            label="Wymiar roczny"
            data={<>{employee?.annual_holiday_pool1} dni</>}
            isEditing={false}
            editor={false}
          />
          {employee?.annual_holiday_pool2 ? (
            <Value
              label="+"
              data={
                <>
                  {employee?.annual_holiday_pool2} (od{" "}
                  {employee?.annual_holiday_pool2_add_from})
                </>
              }
              isEditing={false}
              editor={false}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div style={{ paddingBlock: "10px" }}>
          Nie określono uprawnień do urlopu
        </div>
      )}
      <h2 className="bigger">
        <span
          className={"switch-year" + (year <= 2023 ? " disabled" : "")}
          onClick={() =>
            setYear((prev: number) => (prev > 2023 ? prev - 1 : prev))
          }
        >
          {"<"}
        </span>
        {year}
        <span
          className={
            "switch-year" + (year > new Date().getFullYear() ? " disabled" : "")
          }
          onClick={() =>
            setYear((prev: number) =>
              prev <= new Date().getFullYear() ? prev + 1 : prev
            )
          }
        >
          {">"}
        </span>
      </h2>
      {f.loading ? (
        <Loading little={true} />
      ) : !data ? (
        <div className="info">Brak danych</div>
      ) : (
        <div>
          <Value
            label="Godziny przepracowane"
            data={data.work_hours}
            isEditing={false}
            editor={false}
          />
          {holiday ? (
            <>
              <Value
                label="Godziny urlopu"
                data={data.holiday_hours}
                isEditing={false}
                editor={false}
              />
              <Value
                label="Suma godzin"
                data={data.all_hours}
                isEditing={false}
                editor={false}
              />
            </>
          ) : (
            <></>
          )}
          {holiday ? (
            <div>
              <h2>Dni wolne wg rodzaju</h2>
              {holidayTypes &&
                holidayTypes.length &&
                holidayTypes?.map((item: any, index: number) => (
                  <Value
                    label={item.name}
                    data={holidayNumbers?.[index]}
                    isEditing={false}
                    editor={false}
                  />
                ))}
            </div>
          ) : (
            <div className="info">Urlop nie dotyczy</div>
          )}
        </div>
      )}

      <div>
        <h2>Pozostały urlop wypoczynkowy do końca roku</h2>
        <Value
          label="Liczba dni"
          data={
            holiday && !holidayCount.loading
              ? holidayCount?.rows?.find((h: any) => h.type === 1)
                  ?.remaining_holiday
              : holidayCount.loading
              ? null
              : null
          }
          isEditing={false}
          editor={false}
        />
      </div>
    </div>
  );
}
