import { useFormContext } from "react-hook-form";
import { formField } from "../formContentType";
import { createContext, useContext, useEffect, useState } from "react";
import magnifierIcon from "../../../images/icons/magnifier.svg";
import useGeneric from "../../dataFetch/useGeneric";
import HybridResults from "./HybridElements/HybridResults";
import { FormDataContext } from "../Form";
import { groupBy } from "../../functions/tools";

export const HybridContext = createContext<any>({
  resultElement: false,
  resultUpdate: false,
  foundRows: [],
  setSubData: undefined,
});

export default function HybridField({
  schema,
  subData,
  setSubData,
}: {
  schema: formField;
  subData?: any;
  setSubData?: Function;
}) {
  const {
    name,
    inputType,
    required,
    db,
    query,
    resultElement,
    resultUpdate,
    resultUpdateSub,
    minLength,
    maxLength,
    min,
    max,
    validate,
    pattern,
    groupBy,
  } = schema;

  const {
    register,
    getValues,
    formState: { errors, touchedFields },
  } = useFormContext();

  const [inputText, setInputText] = useState(() => getValues(name));
  const [dbQuery, setDBQuery] = useState("");

  const { customFieldData, setCustomFieldData } = useContext(FormDataContext);

  const [resultsWindow, setResultsWindow] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [focus, setFocus] = useState(false);

  //console.log(name, dbQuery && (validate ? validate(dbQuery) === true : true) && db ? db : "");

  const dbName = typeof db === "function" ? db(subData) : db;

  const fetchDB = useGeneric({
    name:
      dbQuery && dbName && (validate ? validate(dbQuery) === true : true)
        ? dbName
        : "",
    limit: 20,
    offset: 0,
    query: query && query(dbQuery, subData),
    update: dbQuery,
    onlyOnFocus: true,
    groupBy: Array.isArray(groupBy) ? groupBy[0] : groupBy,
  });

  const foundRows = !fetchDB?.loading ? Object(fetchDB.rows) : undefined;

  let hoverTimeout: any;
  let unhoverTimeout: any;

  useEffect(() => {
    // if (touchedFields[name] || setSubData) {
    const wait = setTimeout(() => {
      setDBQuery(inputText);
      setResultsWindow(true);
    }, 700);
    return () => clearTimeout(wait);
    // }
  }, [inputText]);

  return inputType === "hidden" ? (
    <></>
  ) : (
    <HybridContext.Provider
      value={{
        name: name,
        resultElement: resultElement,
        resultUpdate: resultUpdate,
        resultUpdateSub: resultUpdateSub,
        foundRows: foundRows,
        setResultsWindow: setResultsWindow,
        setSubData: setSubData,
        groupBy: groupBy,
        setHovered: setHovered,
        hoverTimeout: hoverTimeout,
      }}
    >
      {" "}
      {!schema?.readOnly ? (
        <div
          className={"hybrid-search-wrap-outer" + (hovered ? " hovered" : "")}
          onMouseLeave={() => {
            hoverTimeout = setTimeout(
              () => {
                setResultsWindow(false);
                setHovered(false);
              },
              !focus ? 0 : 2000
            );
          }}
          onMouseOver={() => {
            setHovered(true);
            clearTimeout(hoverTimeout);
          }}
        >
          <div className="hybrid-search-wrap">
            <input
              className={errors?.[name] ? "error" : ""}
              id={name}
              title={errors[name]?.message?.toString()}
              maxLength={maxLength?.value}
              autoComplete="off"
              onChange={(e: any) => {
                if (setSubData) {
                  setInputText(e.target.value);
                  setHovered(true);
                  clearTimeout(hoverTimeout);
                }
              }}
              onFocus={(e: any) => setFocus(true)}
              onBlur={(e: any) => {
                setTimeout(() => {
                  // setFocus(false);
                  setResultsWindow(false);
                  setHovered(false);
                }, 300);
              }}
              {...(setSubData
                ? undefined
                : register(name, {
                    required: required,
                    minLength: minLength,
                    maxLength: maxLength,
                    min: min,
                    max: max,
                    onChange: (e: any) => {
                      setInputText(e.target.value);
                    },
                    validate: validate,
                    pattern: pattern,
                  }))}
            />
            <div
              className="search-button"
              onClick={() => {
                setDBQuery(inputText);
                setResultsWindow(true);
                setTimeout(() => {
                  setHovered(true);
                  setFocus(true);
                }, 100);
              }}
            >
              <img src={magnifierIcon} />
            </div>
          </div>
          {foundRows && resultsWindow ? (
            <div
              onMouseOver={() => {
                setHovered(true);
                clearTimeout(hoverTimeout);
              }}
            >
              <HybridResults />
            </div>
          ) : resultsWindow && fetchDB.loading && inputText ? (
            <div
              className="hybrid-search-results"
              style={{ padding: "3px 10px" }}
            >
              Wyszukiwanie...
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div style={{ color: "grey" }}>-</div>
      )}
    </HybridContext.Provider>
  );
}
