import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useGeneric from "../dataFetch/useGeneric";
import RequestCategory from "./RequestCategory";
import RequestList from "./RequestList";

export default function HolidayRequests({
  update,
  setUpdate,
  setSmallUpdate,
  selectedPerson,
  setSelectedPerson,
  employees,
  setSelectedEmployeeData,
  seeRequest,
}: {
  update: number;
  setUpdate: Function;
  setSmallUpdate: Function;
  selectedPerson: any;
  setSelectedPerson: Function;
  employees: any;
  setSelectedEmployeeData: Function;
  seeRequest: any;
}) {
  const requestsRaw = useGeneric({
    name: "holidayRequests",
    limit: 9999999,
    query: {
      person: selectedPerson ? selectedPerson : undefined,
      link: seeRequest,
    },
    reload: true,
    update: selectedPerson + update,
  });
  const requests = requestsRaw.loading ? undefined : requestsRaw.rows;
  const approved = requests
    ? requests.filter((r: any) => r.approved === 1)
    : undefined;
  const notActedOn = requests
    ? requests.filter((r: any) => r.approved === 0)
    : undefined;
  const rejected = requests
    ? requests.filter((r: any) => r.approved === 2)
    : undefined;
  return (
    <div className="holidayRequests">
      <div>
        <div className="requestPanel">
          {seeRequest ? (
            <Link to=".">
              <button className="button">Powrót</button>
            </Link>
          ) : (
            <>
              <button
                className={"button" + (!selectedPerson ? " active" : "")}
                onClick={() => {
                  setSelectedPerson(null);
                  //setUpdate((prev: number) => prev + 1);
                }}
              >
                Wszystkie
              </button>
              {selectedPerson ? (
                <button
                  className="button active"
                  onClick={() => {
                    setSelectedPerson((prev: any) => (prev ? prev : undefined));
                  }}
                >
                  {selectedPerson}
                </button>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        {!requests || (!requests.length && !requests.loading) ? (
          <>{selectedPerson ? <>Brak wniosków: {selectedPerson}</> : <></>}</>
        ) : (
          <div>
            <RequestCategory
              name="oczekujące"
              count={notActedOn.length}
              defaultShow={true}
              requestList={
                <RequestList
                  requests={notActedOn}
                  setSelectedPerson={setSelectedPerson}
                  setUpdate={setUpdate}
                  setSmallUpdate={setSmallUpdate}
                  setSelectedEmployeeData={setSelectedEmployeeData}
                  employees={employees}
                />
              }
            />
            <div>
              <RequestCategory
                name="zatwierdzone"
                count={approved.length}
                defaultShow={false}
                requestList={
                  <RequestList
                    requests={approved}
                    setSelectedPerson={setSelectedPerson}
                    setUpdate={setUpdate}
                    setSmallUpdate={setSmallUpdate}
                    setSelectedEmployeeData={setSelectedEmployeeData}
                    employees={employees}
                  />
                }
              />
            </div>
            <div>
              <RequestCategory
                name="odrzucone"
                count={rejected.length}
                defaultShow={false}
                requestList={
                  <RequestList
                    requests={rejected}
                    setSelectedPerson={setSelectedPerson}
                    setUpdate={setUpdate}
                    setSmallUpdate={setSmallUpdate}
                    setSelectedEmployeeData={setSelectedEmployeeData}
                    employees={employees}
                  />
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
