import BooleanSymbol from "../../../tables/viewElements/BooleanSymbol";

export function row(row?: any): any[] {
  // const f = row?.[0];
  return [
    // {
    //   source: "cid",
    //   name: "Id",

    //   value: <>{f?.cid}</>,
    //   style: { width: "40px" },
    // },
    {
      source: "indeks",
      name: "Indeks",
      value: <>{row?.indeks}</>,
      style: { width: "50px", fontSize: "8px", color: "grey" },
    },
    {
      source: "nazwa",
      name: "Nazwa",
      value: <>{row?.nazwa}</>,
    },
    {
      source: "ilosc_jednostek",
      name: "Stan",
      value: (
        <>
          {row?.ilosc_jednostek ? Math.round(row?.ilosc_jednostek) : 0}
          {/* <div>{row?.map((r:any) => r?.stan).join(", ")}</div> */}
        </>
      ),
      style: { width: "50px" },
    },
    // {
    //   source: "id",
    //   name: "Lp.",
    //   value: <>{row?.id}</>,
    //   style: { width: "40px" },
    // },
    // {
    //   source: "type",
    //   name: "Rodzaj",
    //   value: <>{row?.type === "0" ? "gratis" : "płatny"}</>,
    // },
  ];
}
