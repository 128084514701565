export function diffPart(
  data: any,
  {
    parent,
    splitBy,
    subProp,
    number,
  }: { parent: string; splitBy: string; subProp: string; number?: Boolean }
) {
  const full = data?.[parent];
  const parentArr = JSON.parse(data?.[parent]).flat().flat().flat();
  const splitByParent: any = {};
  parentArr.forEach((s: any) => {
    const result = number ? Number(s?.[subProp]) : s?.[subProp];

    if (splitByParent[s?.[splitBy]]) {
      splitByParent[s?.[splitBy]].push(result);
    } else {
      splitByParent[s?.[splitBy]] = result;
    }
  });
  // console.log('guest_no', dated);
  return splitByParent;
}

export function colorShade(col: string, amt: number) {
  if (!col) return "transparent";
  col = col?.replace(/^#/, "");
  if (col?.length === 3)
    col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  let [r, g, b]:any = col?.match(/.{2}/g);
  [r, g, b] = [
    parseInt(r, 16) + amt,
    parseInt(g, 16) + amt,
    parseInt(b, 16) + amt,
  ];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? "0" : "") + r;
  const gg = (g.length < 2 ? "0" : "") + g;
  const bb = (b.length < 2 ? "0" : "") + b;

  return `#${rr}${gg}${bb}`;
}
export function addAlpha(color: string, opacity:number) {
  // coerce values so ti is between 0 and 1.
  var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}