import Person from "../../../common/Person";
import prettyDate from "../../../functions/prettyDate";
import prettyNumber from "../../../functions/prettyNumber";
import BooleanSymbol from "../../../tables/viewElements/BooleanSymbol";

export function row(row?: any): any[] {
  return [
    {
      source: "nrid",
      name: "Id",
      value: <>{row?.nrid}</>,
      className: "id",
    },
    {
      source: "numer_rezerwacji",
      name: "Nr rezerwacji",
      value: <>{row?.numer_rezerwacji}</>,
      style: { fontSize: "9px", width: "80px" },
    },
    {
      source: "gosc",
      name: "Gość",
      style: { width: "450px" },
      value: (
        <div>
          <div
            style={{ fontWeight: row?.numer_rezygnacji ? "normal" : "bold" }}
          >
            <Person name={row?.gosc} />
        </div>
          {row?.numer_rezygnacji ? (
            <></>
          ) : (
            <div style={{ fontSize: "9px", textWrap: "balance" }}>
              {row?.uwaga}
            </div>
          )}
          {row?.numer_rezygnacji ? (
            <div>✖ REZYGNACJA: {row?.numer_rezygnacji}</div>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      source: "numer",
      name: "Nr pokoju",
      value: <span title={row?.grupa}>{row?.numer}</span>,
    },
    {
      source: "ilosc_osob",
      name: "Liczba osób",
      value: <>{row?.ilosc_osob}</>,
    },
    {
      source: "data_od",
      name: "Przyjazd",
      value: (
        <>
          <div>{new Date(row?.data_od).toLocaleDateString()}</div>
          <div style={{ fontSize: "9px" }}>
            {new Date(row?.godzina_od).toLocaleTimeString("pl-PL", {
              hour: "numeric",
              minute: "2-digit",
            })}
          </div>
        </>
      ),
      rowClassName: row?.numer_rezygnacji
        ? "cancelled"
        : row?.checkinowana == "1"
        ? "checked-in"
        : row?.checkinowana == "2"
        ? "checked-out"
        : "",
    },
    {
      source: "data_do",
      name: "Wyjazd",
      value: (
        <>
          <div>{new Date(row?.data_do).toLocaleDateString()}</div>
          <div style={{ fontSize: "9px" }}>
            {new Date(row?.godzina_do).toLocaleTimeString("pl-PL", {
              hour: "numeric",
              minute: "2-digit",
            })}
          </div>
        </>
      ),
    },
    {
      source: "nazwa_zrodla",
      name: "Źródło",
      value: <>{row?.nazwa_zrodla}</>,
      style: { fontSize: "9px", width: "120px" },
    },
  ];
}
