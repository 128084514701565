import FileUploadListItem from "./FileUploadListItem";

export const categoryDictionary:any = {
  "mandate_documents": "Dokumenty do umowy zlecenia",
  "contracts": "Umowy"
}

export default function FileUploadList({
  module,
  subModule,
  uploadedFiles,
  setUpdates,
}: {
  module: string;
  subModule: string;
  uploadedFiles: any;
  setUpdates: Function;
}) {
  const data =
    uploadedFiles &&
    uploadedFiles.length &&
    uploadedFiles?.filter((item: any) => item.sub_module === subModule);

  const categories: any = {};
  if (data) {
    data?.forEach((item: any) => {
      if (item?.category) {
        if (categories[item.category]) {
          categories[item.category].push(item);
        } else {
          categories[item.category] = [item];
        }
      }
      else {
        if (categories["no_cat"]) {
          categories["no_cat"].push(item);
        } else {
          categories["no_cat"] = [item];
        }
      }
    });
  }

  return uploadedFiles ? (
    <div className="files-list">
      {categories && Object.keys(categories).length ? (
        Object.keys(categories)?.map((key: string) => (
          <div>
            <h2>{categoryDictionary?.[key]}</h2>
            {categories[key].map((file: any) => (
              <FileUploadListItem
                key={file.id}
                module={module}
                subModule={subModule}
                file={file}
                setUpdates={setUpdates}
              />
            ))}
          </div>
        ))
      ) : data && data.length ? (
        data?.map((file: any) => (
          <FileUploadListItem
            key={file.id}
            module={module}
            subModule={subModule}
            file={file}
            setUpdates={setUpdates}
          />
        ))
      ) : (
        <div className="info mini">Brak dokumentów</div>
      )}
    </div>
  ) : (
    <></>
  );
}
