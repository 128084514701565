import { useState } from "react";
import { URL } from "../paths";
import NewScheduleTable from "./NewScheduleTable";
import EmployeeButtons from "./EmployeeButtons";

export default function NewSchedule({
  employees,
  categories,
  setUpdate,
}: {
  employees: any;
  categories: any;
  setUpdate: Function;
}) {
  const [selectedEmployees, setSelectedEmployees] = useState<any[]>([]);

  const [name, setName] = useState("");
  const [ready, setReady] = useState(false);

  async function add() {
    const response: any = await fetch(
      URL + "backend/schedules/insert-schedule.php",
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          name: name,
          location: 0,
          category: 0,
          employeeIDs: selectedEmployees.map((item: any) => item.id).join(","),
          description: "",
        }),
      }
    );
    const result = await response.json();
    setUpdate((prev:any) => prev+1);
    return result;
  }


  return ready ? (
    <div className="message">
      <div>
        Utworzyłeś grafik <span className="msg-name">{name}</span>{" "}
      </div>
    </div>
  ) : (
    <div className="schedule">
      <div className="mainInputs">
        <input
          type="text"
          value={name}
          className="bigInput"
          placeholder="Podaj nazwę grafiku"
          onChange={(e) => setName(e.target.value)}
        />
        <div className="accept">
          <button
            className={selectedEmployees.length && name ? "active" : ""}
            onClick={() => {
              if (selectedEmployees.length && name) {
                add();
                setReady(true);
              } else {
                window.alert("Najpierw podaj nazwę i wybierz pracowników");
              }
            }}
          >
            Dodaj
          </button>
        </div>
      </div>
      <div className="employees">
        <h1>Wybierz pracowników</h1>
        <div className="employeeSelector">
          {categories && categories.length ? (
            categories.map((c: any) => (
              <EmployeeButtons
                key={c}
                employees={employees.filter((e: any) => e.group_name === c)}
                selectedEmployees={selectedEmployees}
                setSelectedEmployees={setSelectedEmployees}
              />
            ))
          ) : (
            <></>
          )}
        </div>
        <div className="selectedEmployees">
          <NewScheduleTable employees={selectedEmployees} />
        </div>
      </div>
    </div>
  );
}
