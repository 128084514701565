import { useContext, useEffect, useRef, useState } from "react";
import Day from "./Day";
import openEditor from "./openEditor";
import { CalendarContext } from "../contexts/CalendarContext";
import { ViewContext } from "../contexts/ViewContext";
import Search from "./Search";
import useEventViewItems from "./useEventViewItems";
import EventQuickView from "./EventQuickView";
import { inflect, objectOrJSONparse } from "../functions/tools";
import useGeneric from "../dataFetch/useGeneric";
import Loading from "../common/Loading";
import { colorShade } from "./definitions/functions";
import CalendarReportEvent from "./CalendarReportEvent";
import generateCalendarData from "./generateCalendarData";
import useEmblaCarousel from "embla-carousel-react";
import useCheckMobileScreen from "../useCheckMobileScreen";
import Info from "../common/Info";
import { isSameDay } from "../schedules/ScheduleTable";
import { useToJpeg, useToPng, useToSvg } from "@hugocxl/react-to-image";

interface dateObj {
  obj: Date;
  events: any;
  holiday?: any;
}

export default function CalendarReport({ cal }: { cal: any }) {
  const calendar = useContext(CalendarContext);
  const { modal, setModal, setTemporary } = useContext(ViewContext);

  const [conversionTime, setConversionTime] = useState(false);

  const today = new Date();
  const weekday = today.getDay();
  const toLastMonday = 0 - weekday;

  const lastMonday = new Date(today);
  lastMonday.setDate(lastMonday.getDate() + toLastMonday);

  const fortnightFromLastMonday = new Date(lastMonday);
  fortnightFromLastMonday.setDate(fortnightFromLastMonday.getDate() + 14);

  // const { loading, rows } = useGeneric({
  //   name: "calendar-report",
  //   query: {
  //     first_date: lastMonday?.toISOString()?.slice(0, 10),
  //     last_date: fortnightFromLastMonday?.toISOString()?.slice(0, 10),
  //   },
  // });

  // const report = !loading && rows;

  function countEvents(dates: any) {
    return dates
      .map((d: any) => d?.events.length)
      .reduce((a: number, b: number) => a + b, 0);
  }

  const currentDate = new Date(lastMonday);
  const dates: any = [];
  for (let i = 0; i < 15; i++) {
    dates.push(currentDate?.toISOString()?.slice(0, 10));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  const datesWithEvents = cal?.dates2months?.filter((d: any) =>
    dates?.find((i: any) => i === d?.obj?.toISOString()?.slice(0, 10))
  );

  const todayIndex =
    dates?.findIndex((i: any) => isSameDay(new Date(i), new Date())) - 1;
  console.log(cal);

  const dateNow = dates?.at(todayIndex + 1);

  const [state, convertToImage, convRef] = useToJpeg<HTMLDivElement>({
    quality: 0.85,
    onSuccess: (data) => {
      const link = document.createElement("a");
      link.download = `imprezy (${dates?.at(todayIndex + 1)}).jpg`;
      link.href = data;
      link.click();
      setTemporary(false);
      setConversionTime(false);
    },
  });
  const text: any = useRef();

  const [emblaRef] = useEmblaCarousel({ loop: true, startIndex: todayIndex });

  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    if (conversionTime) {
      convertToImage();
    }
  }, [conversionTime])

  return false ? (
    <Loading />
  ) : (
    <div className={"calendar-report-view" + (conversionTime ? " forJPG" : "")}>
      {/* <button
        className="normal-button report-copy"
        onClick={async () => {
          try {
            await navigator.clipboard.write(text?.current);
          } catch (error: any) {
            console.error(error.message);
          }
        }}
      >
        Kopiuj
      </button> */}
      <div style={{ display: "flex" }}>
        <button
          onClick={async () => {
            setConversionTime(true);
            setTemporary(true);
          }}
          className="normal-button report-copy"
        >
          Zapisz jako obrazek
        </button>
        {conversionTime ? (
          <div style={{ padding: "5px 10px" }}>Zapisywanie...</div>
        ) : (
          <></>
        )}
      </div>
      <div ref={convRef} className="image-convert-wrapper">
        <div ref={text} className="slider-horizontal-mobile">
          <div className="embla" ref={isMobile ? emblaRef : undefined}>
            <div className="event-report-days embla__container">
              {datesWithEvents?.map((d: any, index: number) => {
                const events = d?.events; //report?.filter((e: any) => e.date === d);
                return (
                  <div
                    className={
                      "embla__slide event-report-day-container" +
                      (index < todayIndex ? " past" : "")
                    }
                  >
                    <div
                      className="event-report-date"
                      style={{
                        // fontWeight: "bold",
                        padding: "5px",
                        // marginTop: !isMobile && index > 0 ? "22px" : "8px",
                        fontWeight: [0, 6].includes(new Date(d?.obj)?.getDay())
                          ? "bold"
                          : "normal",
                      }}
                    >
                      <div>{new Date(d?.obj).toLocaleDateString()}</div>
                      <div style={{ textTransform: "uppercase" }}>
                        {new Date(d?.obj).toLocaleDateString("pl-PL", {
                          weekday: "long",
                        })}{" "}
                        <span style={{ color: "grey" }}>
                          ({events?.length})
                        </span>
                      </div>
                    </div>
                    {/* <div style={{ display: "flex" }}> */}
                    <div className="event-report-day">
                      {!events?.length ? (
                        <div style={{ color: "grey", padding: "15px" }}>
                          Brak imprez
                        </div>
                      ) : (
                        events
                          ?.sort((a: any, b: any) => {
                            const s1 = objectOrJSONparse(a?.schedules);
                            const s2 = objectOrJSONparse(b?.schedules);
                            const t1 = new Date(
                              `2000-01-01 ${s1?.[0]?.time_from}`
                            );
                            const t2 = new Date(
                              `2000-01-01 ${s2?.[0]?.time_from}`
                            );
                            return t1.getTime() - t2.getTime();
                          })
                          ?.map((e: any) => (
                            <CalendarReportEvent
                              e={e}
                              date={d?.obj}
                              isMobile={isMobile}
                            />
                          ))
                      )}
                    </div>
                  </div>
                );
              })}{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
