import insertGeneric from "./dataFetch/insertGeneric";
import useGeneric from "./dataFetch/useGeneric";
import { browserName } from "react-device-detect";

export async function setVersion(userID: Number, version: string) {
  return insertGeneric("set-version", {
    userID: userID,
    agent: browserName,
    current_version: version,
  });
}

export function useVersion(userID: Number | undefined) {
  const getUpdate = useGeneric({
    name: userID ? "get_version" : "",
    // limit: 3,
    query: {
      userID: userID,
      agent: browserName,
    },
    reload: true,
    secondsToReload: 300
  });
  let updateData = !getUpdate.loading && getUpdate.rows;
  if (updateData && updateData.length) {
    updateData.doUpdate =
      new Date(updateData[0]?.current_version)?.getTime() >
      new Date(updateData[0]?.last_version)?.getTime();
  } else if (updateData.length === 0) {
    updateData.doUpdate = true;
  }

  return updateData;
}
