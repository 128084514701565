import Loading from "./components/common/Loading";
import useGeneric from "./components/dataFetch/useGeneric";
import prettyNumber from "./components/functions/prettyNumber";

export default function Stats() {
  const { rows, loading } = useGeneric({
    name: "lsi_stats",
    reload: true,
    secondsToReload: 300
  });

  const occupiedNow = prettyNumber(rows[0]?.v1, { round: 1 });
  const freeNow = prettyNumber(rows[0]?.v2, { round: 1 });

  const statsAll = [
    [rows[0]?.v1, rows[0]?.v2],
    [rows[1]?.v1, rows[1]?.v2],
    [rows[2]?.v1, rows[2]?.v2],
    [rows[3]?.v1, rows[3]?.v2],
  ];

  const boxStyle = {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "4px",
  };
  const captions = ["dziś", "7 dni", "30 dni", "kon. roku"];

  return !loading ? (
    <div className="room-stats-short tooltip">
      <div style={boxStyle} title={"Zajęte pokoje z przyjazdami"}>
        <span className="color-box" style={{ background: "brown" }}></span>
        {occupiedNow}%
      </div>

      <div style={boxStyle} title={"Wolne pokoje do końca dnia"}>
        <span className="color-box" style={{ background: "#37d937" }}></span>
        {freeNow}%
      </div>
      <div
        className="top"
        style={{
          height: "145px",
          width: "250px",
          padding: "2px 4px",
          top: "170px",
          left: "50px",
          textAlign: "center",
          zIndex: 10,
        }}
      >
        <div className="room-stats-main">
          {statsAll?.map((s: any, index: number) => (
            <div>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "9px",
                  textTransform: "uppercase",
                }}
              >
                {captions?.[index]}
              </div>
              <div style={{ fontWeight: "bold" }}>
                {prettyNumber(s?.[0], { round: 1 })} %
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100px",
                  margin: "0 4px",
                  boxShadow: "0 0 3px rgba(0,0,0,.3)",
                  overflow: "hidden",
                  borderRadius: "4px",
                  border: "3px solid white",
                }}
              >
                <div
                  title="wolne"
                  style={{
                    boxShadow: "0px 0 5px rgba(0,0,0,.3) inset",
                    background: "#37d937",
                    flexBasis: s?.[1] + "px",
                  }}
                ></div>
                <div
                  title="zajęte"
                  style={{
                    background: "brown",
                    // boxShadow: "0px -2px 3px rgba(0,0,0,.3)",
                    flexBasis: s?.[0] + "px",
                  }}
                ></div>
              </div>
              {/* <div>{prettyNumber(s?.[1], { round: 1 })}</div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <Loading little={true} />
  );
}
