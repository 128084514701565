import { useContext } from "react";
import { cell, displayStyles } from "./types";
import { CollectionContext } from "./CollectionContext";

export default function CollectionWrap({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const { displayStyle, cells } = useContext(CollectionContext);
  return displayStyle === "table" ? (
    <table className="data-table midi">
      {displayStyle === "table" ? (
        <thead>
          <tr>
            {cells?.map((cell: cell) => (
              <th>
                {typeof cell === "string" ? cell : cell?.head || cell?.column}
              </th>
            ))}
          </tr>
        </thead>
      ) : (
        <></>
      )}
      <tbody>{children}</tbody>
    </table>
  ) : (
    <div>{children}</div>
  );
}
